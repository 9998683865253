import * as React from "react";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import RootLayout from "./pages/Root";
import "react-toastify/dist/ReactToastify.css";
import Registration from "./pages/Authentication/Registration/Registration";
import Home from "./pages/Home/Home";
import Login from "./pages/Authentication/Login/Login";
import { ToastContainer } from "react-toastify";
import "./style/global.scss";
import Enterpayment from "./pages/Enterpayment/Enterpayment";
import AddBalance from "./pages/Balance/AddBalance";
import ProtectedRoutes from "./components/layout/ProtectedRoutes";
import Profile from "./pages/Profile/Profile";
import EditProfile from "./pages/EditProfile/EditProfile";
import TransactionsList from "./pages/TransactionsList/TransactionsList";
import TransactionDetails from "./pages/TransactionDetails/TransactionDetails";
import Error404 from "./pages/Error/Error404";
import Settings from "./pages/Settings/Settings";
import RecipientsPage from "./pages/Recipients/RecipientsPage";
import Admin from "./pages/Admin/Admin";
import RequireAdmin from "./components/shared/RequireAdmin";
import Users from "./pages/Admin/Users/Users";
import User from "./pages/Admin/Users/User";
import Recipients from "./pages/Admin/Recipients/Recipients";
import InvestmentSingle from "./pages/Admin/Investments/InvestmentSingle";
import ParticipationSingle from "./pages/Admin/Investments/ParticipationSingle";
import Investments from "./pages/Admin/Investments/Investments";
import Participations from "./pages/Admin/Investments/Participations";
import PrivacyPolicy from "./pages/Landing/pages/PrivacyPolicy";
import Copyright from "./pages/Landing/pages/Copyright";
import Declaration from "./pages/Landing/pages/Declaration";
import BalanceRequestList from "./pages/Balance/BalanceRequestList";
import BalanceRequestDetailPage from "./pages/Balance/BalanceRequestDetailPage";
import CryptoRequestDetailPage from "./pages/Balance/CryptoRequestDetailPage";
import BalanceRequestListAdmin from "./pages/Admin/Balance/BalanceRequestListAdmin";
import RecipientDetails from "./pages/RecipientDetails/RecipientDetails";
import EditRecipient from "./pages/EditRecipient/EditRecipient";
import AccountTransfer from "./pages/AccountTransfer/AccountTransfer";
import CookiePolicy from "./pages/Landing/pages/CookiePolicy";
import ForgetPassword from "./pages/Authentication/ForgetPassword/ForgetPassword";
import Chat from "./pages/Chat/Chat";
import Provider from "./components/Providers/Provider";
import ChatAdmin from "./pages/Chat/ChatAdmin";
import EditBusiness from "./pages/EditBusiness/EditBusiness";
import NotificationsPage from "./pages/NotificationsList/NotificationPage";
import AddUser from "./pages/Admin/AddUser/AddUser";
import LandingPage from "./pages/Landing/pages/LandingPage";
import BalanceDetails from "./pages/BalanceDetails/BalanceDetails";
import AddBranch from "./pages/Admin/Branches/AddBranch";
import EditBranch from "./pages/Admin/Branches/EditBranch";
import BranchesList from "./pages/Admin/Branches/BranchesList";
import BranchDetail from "./pages/Admin/Branches/BranchDetails";
import EditLocalDetails from "./pages/Admin/Branches/EditLocalDetails";
import AddBankDetails from "./pages/Admin/Branches/AddBankDetails";
import EditInternationalDetails from "./pages/Admin/Branches/EditInternationalDetails";
import AccountDetails from "./pages/AccountDetails/AccountDetails";
import VerificationFiles from "./pages/Admin/Users/UserDetails/Verification";
import Balance from "./pages/Admin/Users/UserDetails/Balance";
import AccountRequests from "./pages/Admin/Users/UserDetails/Requests";
import VerificationCodes from "./pages/Admin/Users/UserDetails/Codes";
import Transactions from "./pages/Admin/Users/UserDetails/Transactions";
import ContactUs from "./pages/Landing/pages/ContactUs";
import Faq from "./pages/Landing/pages/Faq";
import AboutUs from "./pages/Landing/pages/AboutUs";
import Solutions from "./pages/Landing/pages/Solutions";
import Security from "./pages/Landing/pages/Security";
import WeHire from "./pages/Landing/pages/WeHire";
import MediaPress from "./pages/Landing/pages/MediaPress";
import SafetyOfFunds from "./pages/Landing/pages/SafetyOfFunds";
import Regulation from "./pages/Landing/pages/Regulation";
import DownloadApp from "./pages/Landing/pages/DownloadApp";
import Complains from "./pages/Landing/pages/Complains";
import InstantPayments from "./pages/Landing/pages/InstantPayments";
import Cryptos from "./pages/Landing/pages/Cryptos";
import MezzanineCapital from "./pages/Landing/pages/MezzanineCapital";
import IbanAccounts from "./pages/Landing/pages/IbanAccounts";
import Fees from "./pages/Landing/pages/Fees";
import Individual from "./pages/Landing/pages/Individual";
import ContactAccountTeam from "./pages/Landing/pages/ContactAccountTeam";
import Business from "./pages/Landing/pages/Business";
import MultiCurrency from "./pages/Landing/pages/MultiCurrency";
import OpenAccount from "./pages/Landing/pages/OpenAccount";
import VisaCard from "./pages/Landing/pages/VisaCard";
import AMLPolicy from "./pages/Landing/pages/AmlPolicy";
import OnlineNewsroom from "./pages/Landing/pages/OnlineNewsRoom";
import ClientSecurity from "./pages/Landing/pages/ClientSecurity";
import License from "./pages/Landing/pages/License";
import CompilenceDesk from "./pages/Landing/pages/CompilenceDesk";
import InHouseWorkshop from "./pages/Landing/pages/InHouseWorkShop";
import HowWeSupport from "./pages/Landing/pages/HowWeSupport";
import InstantTransfers from "./pages/Landing/pages/InstantTransfers";
import CryptoFiatCurrencies from "./pages/Landing/pages/CryptoFiatCurrencies";
import AppInterface from "./pages/Landing/pages/AppInterface";
import AccountFunding from "./pages/Landing/pages/AccountFunding";
import VictorumGroup from "./pages/Landing/pages/VictorumGroup";
import VictorumMarket from "./pages/Landing/pages/VictorumMarket";
import SingleNews from "./pages/Landing/pages/SingleNews";
import Banking from "./pages/Banking/Banking";
import EditOnBoardingDetails from "./pages/EditOnBoardingDetails/EditOnBoardingDetails";
import CreateTransaction from "./pages/CreateTransaction/CreateTransaction";
import Notifications from "./pages/Admin/Users/UserDetails/Notifications";

const router = createBrowserRouter([
  {
    path: "/",
    element: <RootLayout />,
    errorElement: <Error404 />,
    children: [
      {
        index: true,
        element: <LandingPage />,
      },
      {
        path: "/login",
        element: <Login />,
      },
      {
        path: "/registration",
        element: <Registration />,
      },
      {
        path: "/privacy-policy",
        element: <PrivacyPolicy />,
      },
      {
        path: "/forget-password",
        element: <ForgetPassword />,
      },
      {
        path: "/cookie-policy",
        element: <CookiePolicy />,
      },
      {
        path: "/copyright",
        element: <Copyright />,
      },
      {
        path: "/contact-us",
        element: <ContactUs />,
      },
      {
        path: "/faq",
        element: <Faq />,
      },
      {
        path: "/declaration",
        element: <Declaration />,
      },
      {
        path: "/about-us",
        element: <AboutUs />,
      },
      {
        path: "/solutions",
        element: <Solutions />,
      },
      {
        path: "/news/:newsId",
        element: <SingleNews />,
      },
      {
        path: "/security",
        element: <Security />,
      },
      {
        path: "/we-hire",
        element: <WeHire />,
      },
      {
        path: "/media-press",
        element: <MediaPress />,
      },
      {
        path: "/victorum-group",
        element: <VictorumGroup />,
      },
      {
        path: "/safety-of-funds",
        element: <SafetyOfFunds />,
      },
      {
        path: "/regulation",
        element: <Regulation />,
      },
      {
        path: "/download-app",
        element: <DownloadApp />,
      },
      {
        path: "/complains",
        element: <Complains />,
      },
      {
        path: "/payments",
        element: <InstantPayments />,
      },
      {
        path: "/cryptos",
        element: <Cryptos />,
      },
      {
        path: "/mezzanine-capital",
        element: <MezzanineCapital />,
      },
      {
        path: "/iban-accounts",
        element: <IbanAccounts />,
      },
      {
        path: "fees",
        element: <Fees />,
      },
      {
        path: "/cookies-policy",
        element: <CookiePolicy />,
      },
      {
        path: "/individual",
        element: <Individual />,
      },
      {
        path: "/business",
        element: <Business />,
      },
      {
        path: "/multi-currency",
        element: <MultiCurrency />,
      },
      {
        path: "/open-account",
        element: <OpenAccount />,
      },
      {
        path: "/contact-account-team",
        element: <ContactAccountTeam />,
      },
      {
        path: "/visa-card",
        element: <VisaCard />,
      },
      {
        path: "/aml-policy",
        element: <AMLPolicy />,
      },
      {
        path: "/online-news-room",
        element: <OnlineNewsroom />,
      },
      {
        path: "/client-security",
        element: <ClientSecurity />,
      },
      {
        path: "/license",
        element: <License />,
      },
      {
        path: "/compliance-desk",
        element: <CompilenceDesk />,
      },
      {
        path: "/in-house-workshops",
        element: <InHouseWorkshop />,
      },
      {
        path: "/how-we-support-you",
        element: <HowWeSupport />,
      },
      {
        path: "/instant-transfers",
        element: <InstantTransfers />,
      },
      {
        path: "/crypto-fiat-currencies",
        element: <CryptoFiatCurrencies />,
      },
      {
        path: "/victorum-market",
        element: <VictorumMarket />,
      },
      {
        path: "/reliable-app-interface",
        element: <AppInterface />,
      },
      {
        path: "/easy-account-funding",
        element: <AccountFunding />,
      },
      {
        element: <ProtectedRoutes />,
        children: [
          {
            path: "/create-transaction",
            element: <CreateTransaction />,
          },
          {
            path: "/add-balance",
            element: <AddBalance />,
          },
          {
            path: "/account-transfer",
            element: <AccountTransfer />,
          },
          {
            path: "/account-details",
            element: <AccountDetails />,
          },
          {
            path: "/banking",
            element: <Banking />,
          },
          {
            path: "/account-details/:balanceId",
            element: <BalanceDetails />,
          },
          {
            path: "/balance-list/:id",
            element: <BalanceRequestList />,
          },
          {
            path: "/balance-detail/:balanceRequestId",
            element: <BalanceRequestDetailPage />,
          },
          {
            path: "/crypto-detail/:cryptoRequestId",
            element: <CryptoRequestDetailPage />,
          },
          {
            path: "/home",
            element: <Home />,
          },
          {
            path: "/profile",
            element: <Profile />,
          },
          {
            path: "/admin/:id",
            element: (
              <RequireAdmin>
                <Admin />
              </RequireAdmin>
            ),
          },
          {
            path: "/admin",
            element: (
              <RequireAdmin>
                <Admin />
              </RequireAdmin>
            ),
          },
          {
            path: "/admin/users",
            element: (
              <RequireAdmin>
                <Users />
              </RequireAdmin>
            ),
          },
          {
            path: "/admin/add-user",
            element: (
              <RequireAdmin>
                <AddUser />
              </RequireAdmin>
            ),
          },
          {
            path: "/admin/branches",
            element: (
              <RequireAdmin>
                <BranchesList />
              </RequireAdmin>
            ),
          },
          {
            path: "/admin/add-branch",
            element: (
              <RequireAdmin>
                <AddBranch />
              </RequireAdmin>
            ),
          },
          {
            path: "/admin/add-user",
            element: (
              <RequireAdmin>
                <AddUser />
              </RequireAdmin>
            ),
          },
          {
            path: "/admin/recipients",
            element: (
              <RequireAdmin>
                <Recipients />
              </RequireAdmin>
            ),
          },
          {
            path: "/admin/users/:userId/account-information",
            element: (
              <RequireAdmin>
                <User />
              </RequireAdmin>
            ),
          },
          {
            path: "/admin/users/:userId/verification-files",
            element: (
              <RequireAdmin>
                <VerificationFiles />
              </RequireAdmin>
            ),
          },
          {
            path: "/admin/users/:userId/balance",
            element: (
              <RequireAdmin>
                <Balance />
              </RequireAdmin>
            ),
          },
          {
            path: "/admin/users/:userId/account-requests",
            element: (
              <RequireAdmin>
                <AccountRequests />
              </RequireAdmin>
            ),
          },
          {
            path: "/admin/users/:userId/verification-codes",
            element: (
              <RequireAdmin>
                <VerificationCodes />
              </RequireAdmin>
            ),
          },
          {
            path: "/admin/users/:userId/transactions",
            element: (
              <RequireAdmin>
                <Transactions />
              </RequireAdmin>
            ),
          },
          {
            path: "/admin/users/:userId/notifications",
            element: (
              <RequireAdmin>
                <Notifications />
              </RequireAdmin>
            ),
          },
          {
            path: "/admin/users/:userId/edit-profile",
            element: (
              <RequireAdmin>
                <EditProfile />
              </RequireAdmin>
            ),
          },
          {
            path: "/admin/users/:userId/edit-banking",
            element: (
              <RequireAdmin>
                <EditOnBoardingDetails />
              </RequireAdmin>
            ),
          },
          {
            path: "/admin/users/:userId/edit-business",
            element: (
              <RequireAdmin>
                <EditBusiness />
              </RequireAdmin>
            ),
          },
          {
            path: "/admin/edit-branch/:branchId",
            element: (
              <RequireAdmin>
                <EditBranch />
              </RequireAdmin>
            ),
          },
          {
            path: "/admin/edit-local-details/:branchId",
            element: (
              <RequireAdmin>
                <EditLocalDetails />
              </RequireAdmin>
            ),
          },
          {
            path: "/admin/edit-international-details/:branchId",
            element: (
              <RequireAdmin>
                <EditInternationalDetails />
              </RequireAdmin>
            ),
          },
          {
            path: "/admin/add-bank-details/:branchId",
            element: (
              <RequireAdmin>
                <AddBankDetails />
              </RequireAdmin>
            ),
          },
          {
            path: "/admin/edit-branch/:branchId",
            element: (
              <RequireAdmin>
                <EditBranch />
              </RequireAdmin>
            ),
          },
          {
            path: "/admin/branches/:branchId",
            element: (
              <RequireAdmin>
                <BranchDetail />
              </RequireAdmin>
            ),
          },
          {
            path: "/admin/participations",
            element: (
              <RequireAdmin>
                <Participations />
              </RequireAdmin>
            ),
          },
          {
            path: "/admin/participations/:participationId",
            element: (
              <RequireAdmin>
                <ParticipationSingle />
              </RequireAdmin>
            ),
          },
          {
            path: "/admin/investments",
            element: (
              <RequireAdmin>
                <Investments />
              </RequireAdmin>
            ),
          },
          {
            path: "/admin/balance-list/:id",
            element: (
              <RequireAdmin>
                <BalanceRequestListAdmin />
              </RequireAdmin>
            ),
          },
          {
            path: "/admin/investments/:investmentId",
            element: (
              <RequireAdmin>
                <InvestmentSingle />
              </RequireAdmin>
            ),
          },
          {
            path: "/admin/chat",
            element: (
              <RequireAdmin>
                <ChatAdmin />
              </RequireAdmin>
            ),
          },
          {
            path: "/settings",
            element: <Settings />,
          },
          {
            path: "/notifications",
            element: <NotificationsPage />,
          },
          {
            path: "/recipients",
            element: <RecipientsPage />,
          },
          {
            path: "/recipient-details/:recipientId",
            element: <RecipientDetails />,
          },
          {
            path: "/transactions-list",
            element: <TransactionsList />,
          },
          {
            path: "/transaction-details/:transactionId",
            element: <TransactionDetails />,
          },
          {
            path: "/edit-profile",
            element: <EditProfile />,
          },
          {
            path: "/edit-banking",
            element: <EditOnBoardingDetails />,
          },
          {
            path: "/edit-business",
            element: <EditBusiness />,
          },
          {
            path: "/edit-recipient/:recipientId",
            element: <EditRecipient />,
          },

          {
            path: "/enterpayment",
            element: <CreateTransaction />,
          },
          {
            path: "/transactions-create",
            element: <CreateTransaction />,
          },
          {
            path: "/chat",
            element: <Chat />,
          },
          {
            path: "/landing",
            element: <LandingPage />,
          },
        ],
      },
    ],
  },
]);

function App() {
  return (
    <React.Fragment>
      <Provider />
      <RouterProvider router={router} />
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
      />
    </React.Fragment>
  );
}

export default App;
