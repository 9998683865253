import React from "react";
import "./ImageWithText2.scss"; // Ensure this file is correctly linked

interface PaymentCardProps {
  backgroundImage: string;
  noiseImage?: string;
  title: string;
  text: string;
}

const ImageWithText2: React.FC<PaymentCardProps> = ({
  backgroundImage,
  noiseImage,
  title,
  text,
}) => {
  return (
    <div className="container-img2">
      <img
        src={backgroundImage}
        alt="VicPay"
        style={{
          width: "100%",
          borderRadius: "24px",
          maxHeight: "832px",
          minHeight: "230px",
        }}
      />
      <div className="bottom-centered-title2">{title}</div>
      <div className="bottom-centered-text2">{text}</div>
    </div>
  );
};

export default ImageWithText2;
