import api from "../interceptors/api";

const getRecipients = async (searchValue?: string | null) => {
  let url = `/recipients`;
  if (searchValue) {
    url += searchValue;
  }
  const response = await api.get(url);
  return response.data;
};
const getRecipientsAdmin = async (searchValue?: string | null) => {
  let url = `/admin/recipients`;
  if (searchValue) {
    url += searchValue;
  }
  const response = await api.get(url);
  return response.data;
};
const getRecipientsById = async (recipientId: string) => {
  const response = await api.get(`/recipients/${recipientId}`);
  return response.data;
};
const editRecipientsById = async (recipientId: string, data: any) => {
  const response = await api.patch(`/recipients/${recipientId}`, data);
  return response.data;
};
const deleteRecipient = async (recipientId: string) => {
  const response = await api.delete(`/recipients/${recipientId}`);
  return response.data;
};

const recipientsService = {
  getRecipients,
  getRecipientsAdmin,
  getRecipientsById,
  editRecipientsById,
  deleteRecipient,
};

export default recipientsService;
