import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import registerService from "./register_service";
import { storeUser } from "../persist/persist";
import { toast } from "react-toastify";

interface InitialStateRegister {
  isLoading: boolean;
  isError: boolean;
  isSuccess: boolean;
  token: string | null;
  message: string | null;
  isVerifified: boolean | null;
  isRegistered: boolean | null;
}

const initialStateRegister: InitialStateRegister = {
  isLoading: false,
  isError: false,
  isSuccess: false,
  token: null,
  message: null,
  isVerifified: null,
  isRegistered: null,
};

export const registerEmail = createAsyncThunk<any, any>(
  "register/register-email",
  async (data: any, thunkAPI) => {
    try {
      const response = await registerService.registerEmail(data);
      return response;
    } catch (error) {
      console.log("error", error);
      return thunkAPI.rejectWithValue("Failed to fetch issues.");
    }
  }
);

export const verifyEmail = createAsyncThunk<any, any>(
  "register/verify-email",
  async (data: any, thunkAPI) => {
    try {
      const response = await registerService.verifyEmail(data);
      if (response && !response.success && response.message) {
        toast.error(response.message);
      }
      return response;
    } catch (error) {
      console.log("error", error);
      return thunkAPI.rejectWithValue("Failed to fetch issues.");
    }
  }
);

export const registerUser = createAsyncThunk<any, any>(
  "register/register-user",
  async (data: any, thunkAPI) => {
    try {
      const response = await registerService.registerUser(data);
      if (response && response.access_token) {
        storeUser("victorumuser", JSON.stringify(response.user));
        storeUser("token", response.access_token);
      }
      return response;
    } catch (error: any) {
      console.log("error", error);
      if (error?.response?.data?.message) {
        toast.error(error?.response?.data?.message);
      }
      return thunkAPI.rejectWithValue("Failed to fetch issues.");
    }
  }
);

export const setToken = createAsyncThunk<any, any>(
  "register/set-token",
  async (data: string, thunkAPI) => {
    try {
      return data;
    } catch (error) {
      console.log("error", error);
      return thunkAPI.rejectWithValue("Failed to fetch issues.");
    }
  }
);

const registerSlice = createSlice({
  name: "register",
  initialState: initialStateRegister,
  reducers: {
    resetRegisterState(state) {
      state.isError = false;
      state.isSuccess = false;
      state.isLoading = false;
      state.message = null;
      state.isVerifified = null;
      state.isRegistered = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(registerEmail.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(registerEmail.fulfilled, (state, action) => {
        state.isError = false;
        // state.isSuccess = true;
        state.isLoading = false;
        state.message = action.payload.message;
        state.isVerifified = action.payload.success;
      })
      .addCase(registerEmail.rejected, (state) => {
        state.isLoading = false;
        state.isSuccess = false;
        state.isError = true;
      })

      .addCase(verifyEmail.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(verifyEmail.fulfilled, (state, action) => {
        state.isError = false;
        state.isSuccess = action.payload.success;
        state.isLoading = false;
        state.message = action.payload.message;
        state.token = action.payload.token;
      })
      .addCase(verifyEmail.rejected, (state) => {
        state.isLoading = false;
        state.isSuccess = false;
        state.isError = true;
      })

      .addCase(registerUser.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(registerUser.fulfilled, (state, action) => {
        state.isError = false;
        state.isRegistered = true;
        state.isLoading = false;
      })
      .addCase(registerUser.rejected, (state) => {
        state.isLoading = false;
        state.isSuccess = false;
        state.isError = true;
      })

      .addCase(setToken.fulfilled, (state, action) => {
        state.token = action.payload;
      });
  },
});

export const { resetRegisterState } = registerSlice.actions;

export default registerSlice.reducer;
