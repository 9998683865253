import React, { useState, useEffect } from "react";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Nav from "src/components/layout/nav/Nav";
import { Typography, Box } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import LoadingIndicator from "src/components/shared/LoadingIndicator";
import balanceServices from "src/store/balance/balance_services";
import PaymentInstructions from "./components/PaymentInstructions";
import { useAppSelector } from "src/store";
import PaymentRequestAdminActions from "./components/PaymentRequestAdminActions";
import i18next from "i18next";

const defaultTheme = createTheme();
const BalanceRequestDetailPage: React.FC = () => {
  const { user } = useAppSelector((state) => state.auth);
  const { balanceRequestId }: any = useParams();
  const [balanceRequestData, setBalanceRequestData] = useState<any>(null);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();

  const handleGetInvestment = async () => {
    setIsLoading(true);

    const response = await balanceServices.getBalanceRequestById(
      balanceRequestId
    );
    const { data } = response;
    setIsLoading(false);
    if (data) {
      setBalanceRequestData(data);
    }
  };

  const handleNavigate = () => {
    navigate(-1);
    //navigate("/transactions-list");
  };

  useEffect(() => {
    handleGetInvestment();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <Nav step={0} isStepperVisible={false} isProfileSetVisible={true} />
      <div className="content-container-profile">
        <ThemeProvider theme={defaultTheme}>
          <Container component="main">
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <div className="d-flex ai-center mb-3-rem">
                <p
                  className="back-button-icon circle-around-text-smaller helvetica mr-1-rem cp"
                  onClick={handleNavigate}
                >
                  <ArrowBackIcon className="muiIcons" />
                </p>
                <Typography
                  className="your-details-text d-flex jc-start pageTitleFontSize"
                  component="h1"
                  variant="h5"
                  sx={{
                    fontFamily: "Helvetica, sans-serif",
                    textAlign: "center",
                    fontWeight: "600",
                  }}
                >
                  {i18next.t("Payment Request - Balance")}
                </Typography>
              </div>
              <div>
                {isLoading ? (
                  <LoadingIndicator />
                ) : balanceRequestData ? (
                  <div>
                    {user &&
                      user.role &&
                      (user.role === "Admin" || user.role === "Employee") && (
                        <PaymentRequestAdminActions
                          user={user}
                          data={balanceRequestData}
                        />
                      )}
                    <PaymentInstructions data={balanceRequestData} />
                  </div>
                ) : (
                  <div>{i18next.t("No data found!")}</div>
                )}
              </div>
            </Box>
          </Container>
        </ThemeProvider>
      </div>
    </div>
  );
};

export default BalanceRequestDetailPage;
