import "./TransactionDetails.scss";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import Nav from "src/components/layout/nav/Nav";
import transactionsService from "src/store/transactions/transactions_service";
import TransactionDetail from "src/components/TransactionDetail/TransactionDetail";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Container from "@mui/material/Container";
import LoadingIndicator from "src/components/shared/LoadingIndicator";
import TransactionDetailBalance from "src/components/TransactionDetail/TransactionDetailBalance";
import TransactionDetailCustom from "src/components/TransactionDetail/TransactionDetailCustom";
import TransactionDetailCrypto from "src/components/TransactionDetail/TransactionDetailCrypto";
import TransactionDetailTransfer from "src/components/TransactionDetail/TransactionDetailTransfer";
import TransactionDetailTransferFee from "src/components/TransactionDetail/TransactionDetailTransferFee";

const defaultTheme = createTheme();

export default function TransactionDetails() {
  const { transactionId }: any = useParams();
  const [transactionDetails, setTransactionDetails] = useState<any | null>(
    null
  );
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const fetchTransactionDetails = async () => {
    setLoading(true);
    try {
      if (!transactionId) return;
      const res = await transactionsService.getTransactionById(transactionId);
      setTransactionDetails(res.data);
      setLoading(false);
    } catch (error: any) {
      setLoading(false);
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        setError(error.response.data.message);
      } else {
        let errorMsg = "An unknown error occurred";
        setError(errorMsg);
      }
    }
  };

  useEffect(() => {
    fetchTransactionDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (loading) {
    return (
      <div>
        <Nav step={0} isStepperVisible={false} isProfileSetVisible={true} />
        <div className="content-container-profile main-color">
          <ThemeProvider theme={defaultTheme}>
            <Container component="main">
              <LoadingIndicator />
            </Container>
          </ThemeProvider>
        </div>
      </div>
    );
  }

  if (!transactionDetails) {
    return <div>No transaction details available.</div>;
  }

  switch (transactionDetails.transaction_type) {
    case "balance_in":
    case "balance_in_credit_card":
    case "balance_in_crypto":
    case "balance_in_bank":
    case "balance_out":
      return (
        <TransactionDetailBalance
          errorPage={error}
          loadingPage={loading}
          transactionDetails={transactionDetails}
          transactionId={transactionId}
        />
      );

    case "m_transfer_out":
      return (
        <TransactionDetailCustom
          errorPage={error}
          loadingPage={loading}
          transactionDetails={transactionDetails}
          transactionId={transactionId}
        />
      );

    case "crypto_transfer_out":
      return (
        <TransactionDetailCrypto
          errorPage={error}
          loadingPage={loading}
          transactionDetails={transactionDetails}
          transactionId={transactionId}
        />
      );

    case "p_transfer_out":
    case "p_transfer_in":
      return (
        <TransactionDetailTransfer
          errorPage={error}
          loadingPage={loading}
          transactionDetails={transactionDetails}
          transactionId={transactionId}
        />
      );

    case "p_transfer_fee":
      return (
        <TransactionDetailTransferFee
          errorPage={error}
          loadingPage={loading}
          transactionDetails={transactionDetails}
          transactionId={transactionId}
        />
      );

    default:
      return (
        <TransactionDetail
          errorPage={error}
          loadingPage={loading}
          transactionDetails={transactionDetails}
          transactionId={transactionId}
        />
      );
  }
}
