import { Avatar, Box, IconButton, TextField, Typography } from "@mui/material";
import "../../../pages/Chat/Chat.scss";
import ConversationContainer from "./ConversationContainer";
import UploadFileOrImage from "../UploadFileOrImage/UploadFileOrImage";
import { useFormik } from "formik";
import * as Yup from "yup";
import api from "src/store/interceptors/api";
import { useEffect, useRef, useState } from "react";
import LoadingIndicator from "../LoadingIndicator";
import SmsOutlinedIcon from "@mui/icons-material/SmsOutlined";
import EmojiPicker from "../EmojiPicker/Emoji";
import SendIcon from "@mui/icons-material/Send";
import PDFModal from "../PdfModal/PdfModal";
import CloseIcon from "@mui/icons-material/Close";
import { debounce } from "lodash";
import { useNavigate } from "react-router-dom";
import i18next from "i18next";

export default function ChatContainer({
  user,
  selectedConversation,
  selectedConversationUser,
  conversations,
}: any) {
  const [conversationMessages, setConversationMessages] = useState<any[]>([]);
  const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
  const [loading, setLoading] = useState(false);
  const [selectedEmoji, setSelectedEmoji] = useState("");
  const [loadMore, setLoadMore] = useState(false);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);

  const chatContainerRef = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();

  // pdf start
  const [openModal, setOpenModal] = useState(false);
  // State to store the URL of the PDF to display
  const [pdfUrl, setPdfUrl] = useState("");

  // Function to handle opening the PDF Modal
  const handleOpenModal = (fileUrl: any) => {
    setPdfUrl(fileUrl); // Set the URL of the PDF to display
    setOpenModal(true); // Open the modal
  };

  // Function to handle closing the PDF Modal
  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleRemoveFile = () => {
    setSelectedFiles([]); // Clear the selected file
  };

  const renderFileName = (selectedFiles: any, handleOpenModal: any) => {
    const fileExists = selectedFiles.length > 0;
    const fileType = fileExists ? selectedFiles[0].type : "";
    const fileName = fileExists ? selectedFiles[0].name : "";

    const isPdf = fileType === "application/pdf";
    const isImage = fileType.startsWith("image/");

    const fileStyle: any = {
      textAlign: "center",
      fontSize: "10px",
      textDecoration: isPdf ? "underline" : "none",
      color: isPdf ? "#007BFF" : "inherit",
      cursor: isPdf || isImage ? "pointer" : "default",
    };

    const handleClick = () => {
      if (isPdf) {
        const fileUrl = URL.createObjectURL(selectedFiles[0]);
        handleOpenModal(fileUrl); // For PDFs, open the modal with the file
      }
      // Add logic for image click if needed
    };

    // Display for image files
    if (isImage) {
      return (
        <div className="d-flex ai-center">
          <div className="borderChat">
            <img
              src={URL.createObjectURL(selectedFiles[0])}
              alt={fileName}
              style={{
                maxWidth: "50px",
                //  paddingBottom: "8px",
                // margin: "auto",
                objectFit: "cover",
              }}
            />
          </div>

          <div style={{ marginLeft: "4px" }}>
            <IconButton onClick={handleRemoveFile} aria-label="close">
              <CloseIcon
                sx={{
                  fontSize: "0.85rem",
                }}
              />
            </IconButton>
          </div>
        </div>
      );
    }

    // Display for PDF and other files
    return (
      fileName.length > 0 && (
        <div className="d-flex ai-center">
          <div className="borderChat cp" onClick={handleClick}>
            <p style={fileStyle}>
              {fileExists
                ? `${fileName.substring(0, 9)}${
                    fileName.length > 9 ? "..." : ""
                  }`
                : ""}
            </p>
          </div>
          <div>
            <IconButton onClick={handleRemoveFile} aria-label="close">
              <CloseIcon
                sx={{
                  // height: 20,
                  fontSize: "0.85rem",
                }}
              />
            </IconButton>
          </div>
        </div>
      )
    );
  };

  // pdf end

  const handleKeyPress = (e: any) => {
    if (e.key === "Enter") {
      e.preventDefault();
      formik.handleSubmit();
    }
  };

  const handleEmojiSelect = (emoji: any) => {
    const updatedMessage = `${formik.values.message} ${emoji.native}`;
    formik.setFieldValue("message", updatedMessage);
    setSelectedEmoji(emoji.native);
  };

  const appendMessages = async (msg: any) => {
    const oldMsg = [...conversationMessages];
    const newMsg = [msg, ...oldMsg];
    setConversationMessages(newMsg);
    resetFileInput();
  };

  const handleFileSelect = (files: FileList | null) => {
    if (files && files.length > 0) {
      setSelectedFiles([files[0]]);
      const fileNameContainer = document.getElementById("selectedFileName");
      if (fileNameContainer) {
        fileNameContainer.innerText = files[0].name;
      }
    } else {
      setSelectedFiles([]);
      const fileNameContainer = document.getElementById("selectedFileName");
      if (fileNameContainer) {
        fileNameContainer.innerText = "";
      }
    }
  };
  const resetFileInput = () => {
    const fileNameContainer = document.getElementById("selectedFileName");
    if (fileNameContainer) {
      fileNameContainer.innerText = "";
    }
    setSelectedFiles([]);
  };
  const getConversationMessages = async (page = 1) => {
    if (selectedConversation !== null) {
      setLoadMore(true);
      // setLoading(true);
      let urlConversationMessages = `/messages?page=${page}&exact_search[chat_conversation_id]=${selectedConversation}&per_page=20`;

      try {
        const response = await api.get(urlConversationMessages);
        if (response && response.data && response.data.data) {
          if (page === 1) {
            setConversationMessages(response.data.data);
          } else {
            setConversationMessages((prevData) => [
              ...prevData,
              ...response.data.data,
            ]);
          }
          setPage(response.data.meta.current_page);
          setTotalPages(response.data.meta.last_page);
        } else if (response.status === 404) {
          setConversationMessages([]);
        }
        setLoadMore(false);
        setLoading(false);
      } catch (err) {
        console.error("Error getting conversation messages: ", err);
        setConversationMessages([]);
        setLoadMore(false);
        setLoading(false);
      }
    }
  };
  const handleGetMoreMessages = async () => {
    if (totalPages && page < totalPages) {
      const container = chatContainerRef.current;
      const currentScrollTop = container?.scrollTop || 0;
      const currentHeight = container?.scrollHeight || 0;

      await getConversationMessages(page + 1);

      if (container) {
        const newHeight = container.scrollHeight;
        const heightDiff = newHeight - currentHeight;
        container.scrollTop = currentScrollTop + heightDiff;
      }
    }
  };

  const handleNavigateUserProfile = () => {
    navigate(`/admin/users/${selectedConversationUser.id}/account-information`);
  };

  const getDisplayName = (user: any, selectedConversationUser: any) => {
    if (!user) return "";

    if (user.role !== "Admin" && user.role !== "Employee") {
      return "Admin";
    }

    if (selectedConversationUser) {
      if (
        selectedConversationUser.account_type === "business" &&
        selectedConversationUser.company_name
      ) {
        return selectedConversationUser.company_name;
      }

      return `${selectedConversationUser.first_name ?? ""} ${
        selectedConversationUser.last_name ?? ""
      }`;
    }

    return "";
  };

  const formik = useFormik({
    initialValues: {
      message: "",
    },
    onSubmit: async (data) => {
      try {
        if (selectedFiles.length === 0 && data.message.trim() === "") {
          return;
        }
        const formData = new FormData();
        if (data.message) {
          formData.append("message", data.message);
        }
        formData.append("chat_conversation_id", selectedConversation);
        if (selectedFiles && selectedFiles.length > 0) {
          for (let i = 0; i < selectedFiles.length; i++) {
            formData.append(`attachments[${i}]`, selectedFiles[i]);
          }
        }
        const response = await api.post("/messages", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });

        if (response.status === 201 || response.status === 200) {
          formik.resetForm();
          setSelectedFiles([]);
          if (response && response.data && response.data.data) {
            appendMessages(response.data.data);
          }
        }
      } catch (error) {
        console.error("Error:", error);
      }
    },
    validationSchema: Yup.object({
      // message: Yup.string().required("Message is required"),
    }),
  });
  useEffect(() => {
    getConversationMessages();
  }, [selectedConversation, selectedConversationUser]);

  return (
    <Box className="convoMain">
      {loading ? (
        <Box
          sx={{
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <LoadingIndicator />
        </Box>
      ) : !conversationMessages || conversationMessages.length > 0 ? (
        <>
          <Box className="chatHead">
            <Box className="chatHeadAvatarName">
              <Box>
                <Avatar
                  onClick={handleNavigateUserProfile}
                  src={selectedConversationUser?.image?.file_url ?? null}
                  alt={selectedConversationUser?.first_name}
                  sx={{
                    width: "40px",
                    height: "40px",
                    objectFit: "cover",
                    cursor: "pointer",
                  }}
                ></Avatar>
              </Box>
              <Box className="nameAndOnlineBox">
                <Box>
                  <Typography
                    className="headName"
                    onClick={handleNavigateUserProfile}
                    sx={{ cursor: "pointer", width: "max-content" }}
                  >
                    {getDisplayName(user, selectedConversationUser)}
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>
          <ConversationContainer
            getConversationMessages={getConversationMessages}
            conversations={conversations}
            user={user}
            conversationMessages={conversationMessages}
            selectedConversationUser={selectedConversationUser}
            chatContainerRef={chatContainerRef}
            handleScroll={handleGetMoreMessages}
            loadMore={loadMore}
          />
          <Box className="chatBoxAndSendMessageBox">
            <Box className="chatBoxMain">
              <Box className="chatBox"></Box>
            </Box>
            <Box className="sendMessageBox">
              <Box sx={{ width: "100%" }}>
                <TextField
                  name="message"
                  variant="standard"
                  placeholder="Type a message"
                  sx={{ width: "100%" }}
                  value={formik.values.message}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  onKeyDown={handleKeyPress}
                />
              </Box>
              <div>
                {/* Call the renderFileName function and pass in the selectedFiles and handleOpenModal */}
                {renderFileName(selectedFiles, handleOpenModal)}

                {/* PDFModal component */}
                <PDFModal
                  open={openModal}
                  handleClose={handleCloseModal}
                  fileUrl={pdfUrl}
                />
              </div>

              <Box>
                <div>
                  <EmojiPicker onSelect={handleEmojiSelect} />
                </div>
              </Box>
              <Box>
                <UploadFileOrImage onFileSelect={handleFileSelect} />
              </Box>
              <Box
                style={{ marginTop: "6px", cursor: "pointer" }}
                onClick={(e) => {
                  e.preventDefault();
                  formik.handleSubmit();
                }}
              >
                <SendIcon
                  fontSize="large"
                  style={{ color: "#2A5182", fontSize: "1.4rem" }}
                />
              </Box>
            </Box>
          </Box>
        </>
      ) : (
        <Box className="noConversationSelectedBox">
          <Typography>
            <>
              <SmsOutlinedIcon sx={{ width: "100%" }} />
              <Typography textAlign={"center"}>
                {i18next.t(
                  "Please click on a chat in Inbox to display the conversation"
                )}
              </Typography>
            </>
          </Typography>
        </Box>
      )}
    </Box>
  );
}
