import { useTranslation } from "react-i18next";
import { Select, MenuItem, FormControl } from "@mui/material";
import { ReactComponent as DeutchIcon } from "../../../assets/images/deLang.svg";
import { ReactComponent as USAIcon } from "../../../assets/images/usaLang.svg";
import "./LanguageSelector.scss";

const LanguageSelector = ({ onChange }: any) => {
  const { i18n } = useTranslation();

  const handleLanguageChange = (event: any) => {
    const selectedLanguage = event.target.value;
    i18n.changeLanguage(selectedLanguage);
    localStorage.setItem("appLanguage", selectedLanguage);
    if (onChange) {
      onChange(selectedLanguage);
    }
    window.location.reload();
  };

  return (
    <FormControl variant="outlined" size="small">
      <Select
        value={i18n.language}
        onChange={handleLanguageChange}
        sx={{
          height: "25px",
          border: "none",
          "& .MuiSelect-select.MuiSelect-outlined.MuiInputBase-input.MuiOutlinedInput-input.MuiInputBase-inputSizeSmall.css-jedpe8-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input":
            {
              height: "25px",
              paddingLeft: "0",
              display: "flex",
              alignItems: "center",
            },
          "& .MuiOutlinedInput-notchedOutline": {
            border: "none",
          },
          "&:hover .MuiOutlinedInput-notchedOutline": {
            border: "none",
          },
          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
            border: "none",
          },
        }}
        renderValue={(value) => (
          <div className="iconLanguageAndText">
            {value === "en-US" ? (
              <>
                <USAIcon className="iconLanguage" />
                {i18n.t("English")}
              </>
            ) : value === "de-DE" ? (
              <>
                <DeutchIcon className="iconLanguage" />
                {i18n.t("German")}
              </>
            ) : null}
          </div>
        )}
      >
        <MenuItem value="en-US">
          <div className="iconLanguageAndText">
            <USAIcon className="iconLanguage" />
            {i18n.t("English")}
          </div>
        </MenuItem>
        <MenuItem value="de-DE">
          <div className="iconLanguageAndText">
            <DeutchIcon className="iconLanguage" />
            {i18n.t("German")}
          </div>
        </MenuItem>
      </Select>
    </FormControl>
  );
};

export default LanguageSelector;
