import React from "react";
import { Typography } from "@mui/material";

interface ErrorMessageProps {
  style?: React.CSSProperties;
  message: string;
}

const ErrorMessage: React.FC<ErrorMessageProps> = ({ style, message }) => {
  return (
    <Typography
      className="registration0875RemFontSize ta-start"
      variant="body2"
      color="error"
      style={style}
    >
      {message}
    </Typography>
  );
};

export default ErrorMessage;
