import { useEffect, useRef, useState } from "react";
import { Form, Formik } from "formik";
import profileService from "src/store/profile/profile_service";
import { Box, Button, Typography } from "@mui/material";
import FilePreview from "../shared/FilePreview";
import ScannFaceAndIdModal from "./ScannFaceAndIdModal";
import CameraAccessModal from "../shared/CameraAccessModal/CameraAccessModal";
import i18next from "i18next";

interface Props {
  onStepChange: (value: number) => void;
  userToken?: string | null;
  accountType: string;
}

const KYCStep = ({ onStepChange, userToken, accountType }: Props) => {
  // Local state to manage file selections and modal visibility
  const [selectedFiles, setSelectedFiles] = useState<any>({
    frontID: null,
    idBack: null,
    passport: null,
    personalPhoto: null,
    aml: null,
    companyRegistration: null,
    businessLicence: null,
    businessAddress: null,
    sof: null,
    ubo: null,
    tin: null,
    scannedFacePhoto: null,
    scannedIdPhoto: null,
    scannedCompletePhoto: null,
  });

  const [openCamModal, setOpenCamModal] = useState(false);
  const [hasCamera, setHasCamera] = useState<boolean | null>(null);
  const [showCameraAccessModal, setShowCameraAccessModal] = useState(false);

  const idFrontPageRef = useRef<HTMLInputElement>(null);
  const idBackPageRef = useRef<HTMLInputElement>(null);
  const passportRef = useRef<HTMLInputElement>(null);
  const personalPhotoRef = useRef<HTMLInputElement>(null);
  const amlRef = useRef<HTMLInputElement>(null);
  const companyRegistrationRef = useRef<HTMLInputElement>(null);
  const businessLicenceRef = useRef<HTMLInputElement>(null);
  const businessAddressRef = useRef<HTMLInputElement>(null);
  const sofRef = useRef<HTMLInputElement>(null);
  const uboRef = useRef<HTMLInputElement>(null);
  const tinRef = useRef<HTMLInputElement>(null);

  // State variables to track if the required files are uploaded
  const [scannedFacePhotoUploaded, setScannedFacePhotoUploaded] =
    useState(false);
  const [scannedIdPhotoUploaded, setScannedIdPhotoUploaded] = useState(false);
  const [scannedCompleteUploaded, setScannedCompleteUploaded] = useState(false);
  const [isPersonalPhotoUploaded, setIsPersonalPhotoUploaded] = useState(false);
  const [isFrontIDUploaded, setIsFrontIDUploaded] = useState(false);
  const [isBackIDUploaded, setIsBackIDUploaded] = useState(false);
  const [isPassportUploaded, setIsPassportUploaded] = useState(false);
  const [isAmlUploaded, setIsAmlUploaded] = useState(false);
  const [isCompanyRegistrationUploaded, setIsCompanyRegistrationUploaded] =
    useState(false);
  const [isBusinessLicenceUploaded, setIsBusinessLicenceUploaded] =
    useState(false);
  const [isBusinessAddressUploaded, setIsBusinessAddressUploaded] =
    useState(false);
  const [isSofUploaded, setIsSofUploaded] = useState(false);
  const [isUboUploaded, setIsUboUploaded] = useState(false);
  const [isTinUploaded, setIsTinUploaded] = useState(false);

  const handleCloseModal = () => {
    setOpenCamModal(false);
  };
  const handleOpenCameraAccessModal = () => {
    setShowCameraAccessModal(true);
  };
  const handleCloseCameraAccessModal = () => {
    setShowCameraAccessModal(false);
  };
  const checkCameraPermission = async () => {
    try {
      await navigator.mediaDevices.getUserMedia({ video: true, audio: false });
      setOpenCamModal(true);
    } catch (error) {
      handleOpenCameraAccessModal();
    }
  };

  const handleFileClick = (fileType: string) => {
    if (fileType === "scannedPhoto") {
      checkCameraPermission();
    }
    if (fileType === "idFront") {
      idFrontPageRef.current?.click();
    }
    if (fileType === "idBack") {
      idBackPageRef.current?.click();
    }
    if (fileType === "idPassport") {
      passportRef.current?.click();
    }
    if (fileType === "idPersonal") {
      personalPhotoRef.current?.click();
    }
    if (fileType === "aml") {
      amlRef.current?.click();
    }
    if (fileType === "companyRegistration") {
      companyRegistrationRef.current?.click();
    }
    if (fileType === "businessLicence") {
      businessLicenceRef.current?.click();
    }
    if (fileType === "businessAddress") {
      businessAddressRef.current?.click();
    }
    if (fileType === "sof") {
      sofRef.current?.click();
    }
    if (fileType === "ubo") {
      uboRef.current?.click();
    }
    if (fileType === "tin") {
      tinRef.current?.click();
    }
  };

  const handleFileUploadToBackend = async (fileType: any, file: any) => {
    switch (fileType) {
      case "frontID":
        return await profileService.registrationKYCUpload(
          userToken,
          file,
          "id_card_front_id"
        );
      case "idBack":
        return await profileService.registrationKYCUpload(
          userToken,
          file,
          "id_card_back_id"
        );
      case "passport":
        return await profileService.registrationKYCUpload(
          userToken,
          file,
          "passport_id"
        );
      case "personalPhoto":
        return await profileService.registrationKYCUpload(
          userToken,
          file,
          "personal_photo_id"
        );
      case "aml":
        return await profileService.registrationKYCUpload(
          userToken,
          file,
          "business_aml_id"
        );
      case "companyRegistration":
        return await profileService.registrationKYCUpload(
          userToken,
          file,
          "business_company_registration_id"
        );
      case "businessLicence":
        return await profileService.registrationKYCUpload(
          userToken,
          file,
          "business_licence_id"
        );
      case "businessAddress":
        return await profileService.registrationKYCUpload(
          userToken,
          file,
          "business_address_id"
        );
      case "sof":
        return await profileService.registrationKYCUpload(
          userToken,
          file,
          "business_sof_id"
        );
      case "ubo":
        return await profileService.registrationKYCUpload(
          userToken,
          file,
          "business_ubo_id"
        );
      case "tin":
        return await profileService.registrationKYCUpload(
          userToken,
          file,
          "business_tin_id"
        );

      case "scannedCompletePhoto":
        return await profileService.registrationKYCUpload(
          userToken,
          file,
          "scanned_complete_photo"
        );

      case "scannedFacePhoto":
        return await profileService.registrationKYCUpload(
          userToken,
          file,
          "scanned_face_photo"
        );

      case "scannedIdPhoto":
        return await profileService.registrationKYCUpload(
          userToken,
          file,
          "scanned_id_photo"
        );
      default:
        console.error("Invalid file type");
        return null;
    }
  };

  const handleSubmit = async () => {
    let allUploadsSuccessful = true;

    const uploadFiles = async (fileType: any, file: any) => {
      try {
        const res = await handleFileUploadToBackend(fileType, file);
        return res;
      } catch (error) {
        console.error(`Failed to upload ${fileType}:`, error);
        allUploadsSuccessful = false;
        return null;
      }
    };

    let fileTypes = [
      "frontID",
      "idBack",
      "passport",
      "personalPhoto",
      "aml",
      "companyRegistration",
      "businessLicence",
      "businessAddress",
      "sof",
      "ubo",
      "tin",
      "scannedFacePhoto",
      "scannedIdPhoto",
      "scannedCompletePhoto",
    ];

    fileTypes.forEach(async (keyItem) => {
      if (selectedFiles[keyItem]) {
        try {
          const res = await uploadFiles(keyItem, selectedFiles[keyItem]);
        } catch (error) {
          allUploadsSuccessful = false;
        }
      }
    });

    if (allUploadsSuccessful) {
      onStepChange(6);
    }
  };

  const handleFileUpload = (
    event: React.ChangeEvent<HTMLInputElement>,
    fileType: string
  ) => {
    if (event.target.files && event.target.files.length > 0) {
      if (event.target.files && event.target.files.length > 0) {
        const file = event.target.files[0];
        setSelectedFiles((prev: any) => ({ ...prev, [fileType]: file }));
      }

      switch (fileType) {
        case "personalPhoto":
          setIsPersonalPhotoUploaded(true);
          break;
        case "frontID":
          setIsFrontIDUploaded(true);
          break;
        case "idBack":
          setIsBackIDUploaded(true);
          break;
        case "passport":
          setIsPassportUploaded(true);
          break;
        case "aml":
          setIsAmlUploaded(true);
          break;
        case "companyRegistration":
          setIsCompanyRegistrationUploaded(true);
          break;
        case "businessLicence":
          setIsBusinessLicenceUploaded(true);
          break;
        case "businessAddress":
          setIsBusinessAddressUploaded(true);
          break;
        case "sof":
          setIsSofUploaded(true);
          break;
        case "ubo":
          setIsUboUploaded(true);
          break;
        case "tin":
          setIsTinUploaded(true);
          break;
        default:
          break;
      }
    }
  };

  const handleUploadScannedFile = (
    facePhoto: any,
    idPhoto: any,
    completePhoto: any
  ) => {
    if (facePhoto && idPhoto && completePhoto) {
      setSelectedFiles((prev: any) => ({
        ...prev,
        ["scannedFacePhoto"]: facePhoto,
        ["scannedIdPhoto"]: idPhoto,
        ["scannedCompletePhoto"]: completePhoto,
      }));
      handleCloseModal();
      setScannedCompleteUploaded(true);
      setScannedFacePhotoUploaded(true);
      setScannedIdPhotoUploaded(true);
    }
  };

  const checkIfSubmitEnabled = () => {
    const isSubmitEnabled: boolean =
      isPersonalPhotoUploaded &&
      ((isFrontIDUploaded && isBackIDUploaded) || isPassportUploaded);

    if (accountType === "business") {
      const isSubmitEnabledBusiness: boolean =
        isAmlUploaded &&
        isCompanyRegistrationUploaded &&
        isBusinessLicenceUploaded &&
        isTinUploaded;
      if (isSubmitEnabled && isSubmitEnabledBusiness) return true;
      else return false;
    }
    return isSubmitEnabled;
  };

  useEffect(() => {
    if (!navigator.mediaDevices?.enumerateDevices) {
      setHasCamera(false);
    } else {
      navigator.mediaDevices
        .enumerateDevices()
        .then(function (devices) {
          const cameraDetected = devices.some(function (device) {
            return device.kind === "videoinput";
          });
          setHasCamera(cameraDetected);
        })
        .catch(function (err) {
          setHasCamera(false);
        });
    }
  }, []);

  return (
    <Formik
      initialValues={{
        idFrontImg: "",
        idBackImg: null,
        passport: null,
        personalPhoto: null,
        aml: null,
        companyRegistration: null,
        businessLicence: null,
        businessAddress: null,
        sof: null,
        ubo: null,
        tin: null,
      }}
      onSubmit={handleSubmit}
    >
      <Form style={{ marginTop: "16px", width: "100%" }}>
        {accountType === "business" && (
          <Box>
            <Box sx={{ marginTop: 2, marginBottom: 2 }}>
              <Typography className="loginTitleFontSize">
                {i18next.t("Business verification")}
              </Typography>
            </Box>
            <Box className="personal-box-kyc mb-big">
              <div className="ids-box kyc-business-box">
                <div className="single-box-card">
                  <Typography
                    className="mb-1rem  d-flex jc-start registration0875RemFontSize"
                    component="h1"
                    variant="h5"
                    onClick={() => handleFileClick("aml")}
                    sx={{
                      fontFamily: "Helvetica, sans-serif",
                      textDecoration: "underline",
                      cursor: "pointer",
                      color: "#454745",
                    }}
                  >
                    {i18next.t("Add AML Document")}
                  </Typography>
                  <Typography className="mb-1rem  d-flex jc-start kyc-tooltip-info kyc-tooltip-info-required">
                    {i18next.t(
                      "AML (Anti-Money Laundering) Compliance (*required)"
                    )}
                  </Typography>

                  <input
                    type="file"
                    ref={amlRef}
                    accept=".png, .jpeg, .jpg, .pdf"
                    style={{ display: "none" }}
                    onChange={(e) => handleFileUpload(e, "aml")}
                  />

                  {/* File preview start */}
                  {selectedFiles.aml ? (
                    <FilePreview
                      src={URL.createObjectURL(selectedFiles.aml)}
                      width="90%"
                      alt="AML document"
                      file={selectedFiles.aml}
                    />
                  ) : (
                    <Typography
                      className="mb-1rem  d-flex jc-start w-100 registration0875RemFontSize"
                      component="h1"
                      variant="h5"
                      sx={{
                        fontFamily: "Helvetica, sans-serif",
                        fontWeight: 400,
                        color: "#454745",
                      }}
                    >
                      {i18next.t("No AML Document")}
                    </Typography>
                  )}
                  {/* File Preview End */}
                </div>
              </div>
              <div className="ids-box kyc-business-box">
                {/* id back start */}
                <div className="single-box-card">
                  <Typography
                    className="mb-1rem  d-flex jc-start registration0875RemFontSize"
                    component="h1"
                    variant="h5"
                    onClick={() => handleFileClick("companyRegistration")}
                    sx={{
                      fontFamily: "Helvetica, sans-serif",
                      textDecoration: "underline",
                      cursor: "pointer",
                      color: "#454745",
                    }}
                  >
                    {i18next.t("Add company registration document")}
                  </Typography>
                  <Typography className="mb-1rem  d-flex jc-start kyc-tooltip-info kyc-tooltip-info-required">
                    {i18next.t("Company registration details (*required)")}
                  </Typography>
                  <input
                    type="file"
                    ref={companyRegistrationRef}
                    accept=".png, .jpeg, .jpg, .pdf"
                    style={{ display: "none" }}
                    onChange={(e) => handleFileUpload(e, "companyRegistration")}
                  />

                  {selectedFiles.companyRegistration ? (
                    <FilePreview
                      src={URL.createObjectURL(
                        selectedFiles.companyRegistration
                      )}
                      width="90%"
                      alt="Company registration"
                      file={selectedFiles.companyRegistration}
                    />
                  ) : (
                    <Typography
                      className="mb-1rem  d-flex jc-start registration0875RemFontSize"
                      component="h1"
                      variant="h5"
                      sx={{
                        fontFamily: "Helvetica, sans-serif",
                        fontWeight: 400,
                        color: "#454745",
                      }}
                    >
                      {i18next.t("No company registration document")}
                    </Typography>
                  )}
                </div>
                {/* id back end */}
              </div>

              {/* passport start */}

              <div className="ids-box kyc-business-box">
                <div className="single-box-card">
                  <Typography
                    className="mb-1rem  d-flex jc-start registration0875RemFontSize"
                    component="h1"
                    variant="h5"
                    onClick={() => handleFileClick("businessLicence")}
                    sx={{
                      fontFamily: "Helvetica, sans-serif",
                      textDecoration: "underline",
                      cursor: "pointer",
                      color: "#454745",
                      marginTop: "16px",
                    }}
                  >
                    {i18next.t("Add business licence document")}
                  </Typography>
                  <Typography className="mb-1rem  d-flex jc-start kyc-tooltip-info kyc-tooltip-info-required">
                    {i18next.t("Business licenses (*required)")}
                  </Typography>
                  <input
                    type="file"
                    ref={businessLicenceRef}
                    accept=".png, .jpeg, .jpg, .pdf"
                    style={{ display: "none" }}
                    onChange={(e) => handleFileUpload(e, "businessLicence")}
                  />

                  {selectedFiles.businessLicence ? (
                    <FilePreview
                      src={URL.createObjectURL(selectedFiles.businessLicence)}
                      width="90%"
                      alt="Business licence"
                      file={selectedFiles.businessLicence}
                    />
                  ) : (
                    <Typography
                      className="mb-1rem  d-flex jc-start registration0875RemFontSize"
                      component="h1"
                      variant="h5"
                      sx={{
                        fontFamily: "Helvetica, sans-serif",
                        fontWeight: 400,
                        color: "#454745",
                      }}
                    >
                      {i18next.t("No business licence document")}
                    </Typography>
                  )}
                </div>
              </div>
              <div className="ids-box kyc-business-box">
                {/* passport end */}

                {/* personal start */}
                <div className="single-box-card">
                  <Typography
                    className="mb-1rem  d-flex jc-start registration0875RemFontSize"
                    component="h1"
                    variant="h5"
                    onClick={() => handleFileClick("businessAddress")}
                    sx={{
                      fontFamily: "Helvetica, sans-serif",
                      textDecoration: "underline",
                      cursor: "pointer",
                      color: "#454745",
                      marginTop: "16px",
                    }}
                  >
                    {i18next.t("Add address document")}
                  </Typography>
                  <Typography className="mb-1rem  d-flex jc-start kyc-tooltip-info">
                    {i18next.t("Address Verification document")}
                  </Typography>
                  <input
                    type="file"
                    ref={businessAddressRef}
                    accept=".png, .jpeg, .jpg, .pdf"
                    style={{ display: "none" }}
                    onChange={(e) => handleFileUpload(e, "businessAddress")}
                  />

                  {selectedFiles.businessAddress ? (
                    <div style={{ marginBottom: "24px" }}>
                      <FilePreview
                        src={URL.createObjectURL(selectedFiles.businessAddress)}
                        width="90%"
                        alt="Business address"
                        file={selectedFiles.businessAddress}
                      />
                    </div>
                  ) : (
                    <Typography
                      className="mb-1rem  d-flex jc-start registration0875RemFontSize"
                      component="h1"
                      variant="h5"
                      sx={{
                        fontFamily: "Helvetica, sans-serif",
                        fontWeight: 400,
                        color: "#454745",
                      }}
                    >
                      {i18next.t("No address document")}
                    </Typography>
                  )}
                </div>
                {/* personal end */}
              </div>

              <div className="ids-box kyc-business-box">
                {/* personal start */}
                <div className="single-box-card">
                  <Typography
                    className="mb-1rem  d-flex jc-start registration0875RemFontSize"
                    component="h1"
                    variant="h5"
                    onClick={() => handleFileClick("sof")}
                    sx={{
                      fontFamily: "Helvetica, sans-serif",
                      textDecoration: "underline",
                      cursor: "pointer",
                      color: "#454745",
                      marginTop: "16px",
                    }}
                  >
                    {i18next.t("Add SOF document")}
                  </Typography>
                  <Typography className="mb-1rem  d-flex jc-start kyc-tooltip-info">
                    {i18next.t("Source of Funds (SOF) document")}
                  </Typography>
                  <input
                    type="file"
                    ref={sofRef}
                    accept=".png, .jpeg, .jpg, .pdf"
                    style={{ display: "none" }}
                    onChange={(e) => handleFileUpload(e, "sof")}
                  />

                  {selectedFiles.sof ? (
                    <div style={{ marginBottom: "24px" }}>
                      <FilePreview
                        src={URL.createObjectURL(selectedFiles.sof)}
                        width="90%"
                        alt="SOF"
                        file={selectedFiles.sof}
                      />
                    </div>
                  ) : (
                    <Typography
                      className="mb-1rem  d-flex jc-start registration0875RemFontSize"
                      component="h1"
                      variant="h5"
                      sx={{
                        fontFamily: "Helvetica, sans-serif",
                        fontWeight: 400,
                        color: "#454745",
                      }}
                    >
                      {i18next.t("No SOF document")}
                    </Typography>
                  )}
                </div>
                {/* personal end */}
              </div>
              <div className="ids-box kyc-business-box">
                {/* personal start */}
                <div className="single-box-card">
                  <Typography
                    className="mb-1rem  d-flex jc-start registration0875RemFontSize"
                    component="h1"
                    variant="h5"
                    onClick={() => handleFileClick("ubo")}
                    sx={{
                      fontFamily: "Helvetica, sans-serif",
                      textDecoration: "underline",
                      cursor: "pointer",
                      color: "#454745",
                      marginTop: "16px",
                    }}
                  >
                    {i18next.t("Add UBO document")}
                  </Typography>
                  <Typography className="mb-1rem  d-flex jc-start kyc-tooltip-info">
                    {i18next.t("Ultimate Beneficial Ownership (UBO) document")}
                  </Typography>
                  <input
                    type="file"
                    ref={uboRef}
                    accept=".png, .jpeg, .jpg, .pdf"
                    style={{ display: "none" }}
                    onChange={(e) => handleFileUpload(e, "ubo")}
                  />

                  {selectedFiles.ubo ? (
                    <div style={{ marginBottom: "24px" }}>
                      <FilePreview
                        src={URL.createObjectURL(selectedFiles.ubo)}
                        width="90%"
                        alt="UBO"
                        file={selectedFiles.ubo}
                      />
                    </div>
                  ) : (
                    <Typography
                      className="mb-1rem  d-flex jc-start registration0875RemFontSize"
                      component="h1"
                      variant="h5"
                      sx={{
                        fontFamily: "Helvetica, sans-serif",
                        fontWeight: 400,
                        color: "#454745",
                      }}
                    >
                      {i18next.t("No UBO document")}
                    </Typography>
                  )}
                </div>
                {/* personal end */}
              </div>
              <div className="ids-box kyc-business-box">
                {/* personal start */}
                <div className="single-box-card">
                  <Typography
                    className="mb-1rem  d-flex jc-start registration0875RemFontSize"
                    component="h1"
                    variant="h5"
                    onClick={() => handleFileClick("tin")}
                    sx={{
                      fontFamily: "Helvetica, sans-serif",
                      textDecoration: "underline",
                      cursor: "pointer",
                      color: "#454745",
                      marginTop: "16px",
                    }}
                  >
                    {i18next.t("Add TIN document")}
                  </Typography>
                  <Typography className="mb-1rem  d-flex jc-start kyc-tooltip-info kyc-tooltip-info-required">
                    {i18next.t("Tax Identification Number (TIN) (*required)")}
                  </Typography>
                  <input
                    type="file"
                    ref={tinRef}
                    accept=".png, .jpeg, .jpg, .pdf"
                    style={{ display: "none" }}
                    onChange={(e) => handleFileUpload(e, "tin")}
                  />

                  {selectedFiles.tin ? (
                    <div style={{ marginBottom: "24px" }}>
                      <FilePreview
                        src={URL.createObjectURL(selectedFiles.tin)}
                        width="90%"
                        alt="TIN"
                        file={selectedFiles.tin}
                      />
                    </div>
                  ) : (
                    <Typography
                      className="mb-1rem  d-flex jc-start registration0875RemFontSize"
                      component="h1"
                      variant="h5"
                      sx={{
                        fontFamily: "Helvetica, sans-serif",
                        fontWeight: 400,
                        color: "#454745",
                      }}
                    >
                      {i18next.t("No TIN document")}
                    </Typography>
                  )}
                </div>
                {/* personal end */}
              </div>
            </Box>
          </Box>
        )}
        <Box sx={{ marginTop: 2, marginBottom: 2 }}>
          <Typography className="loginTitleFontSize">
            {i18next.t("Personal verification")}
          </Typography>
        </Box>
        <Box className="personal-box-kyc mb-big">
          <div className="ids-box kyc-business-box">
            <div className="single-box-card">
              <Typography
                className="mb-1rem  d-flex jc-start registration0875RemFontSize"
                component="h1"
                variant="h5"
                onClick={() => handleFileClick("idFront")}
                sx={{
                  fontFamily: "Helvetica, sans-serif",
                  textDecoration: "underline",
                  cursor: "pointer",
                  color: "#454745",
                  marginTop: "16px",
                }}
              >
                {i18next.t("Add ID Card Front Page Image")}
              </Typography>

              <input
                type="file"
                ref={idFrontPageRef}
                accept=".png, .jpeg, .jpg, .pdf"
                style={{ display: "none" }}
                onChange={(e) => handleFileUpload(e, "frontID")}
              />

              {/* File preview start */}
              {selectedFiles.frontID ? (
                <FilePreview
                  src={URL.createObjectURL(selectedFiles.frontID)}
                  width="90%"
                  alt="ID Card Back Page"
                  file={selectedFiles.frontID}
                />
              ) : (
                <Typography
                  className="mb-1rem  d-flex jc-start registration0875RemFontSize"
                  component="h1"
                  variant="h5"
                  sx={{
                    fontFamily: "Helvetica, sans-serif",
                    fontWeight: 400,
                    color: "#454745",
                  }}
                >
                  {i18next.t("No ID Card Back Page Image")}
                </Typography>
              )}
              {/* File Preview End */}
            </div>
          </div>

          {/* id back start */}
          <div className="ids-box kyc-business-box">
            <div className="single-box-card">
              <Typography
                className="mb-1rem  d-flex jc-start registration0875RemFontSize"
                component="h1"
                variant="h5"
                onClick={() => handleFileClick("idBack")}
                sx={{
                  fontFamily: "Helvetica, sans-serif",
                  textDecoration: "underline",
                  cursor: "pointer",
                  color: "#454745",
                  marginTop: "16px",
                }}
              >
                {i18next.t("Add ID Card Back Page Image")}
              </Typography>
              <input
                type="file"
                ref={idBackPageRef}
                accept=".png, .jpeg, .jpg, .pdf"
                style={{ display: "none" }}
                onChange={(e) => handleFileUpload(e, "idBack")}
              />

              {selectedFiles.idBack ? (
                <FilePreview
                  src={URL.createObjectURL(selectedFiles.idBack)}
                  width="90%"
                  alt="ID Card Back Page"
                  file={selectedFiles.idBack}
                />
              ) : (
                <Typography
                  className="mb-1rem  d-flex jc-start registration0875RemFontSize"
                  component="h1"
                  variant="h5"
                  sx={{
                    fontFamily: "Helvetica, sans-serif",
                    fontWeight: 400,
                    color: "#454745",
                  }}
                >
                  {i18next.t("No ID Card Front Page Image")}
                </Typography>
              )}
            </div>
          </div>
          {/* id back end */}

          {/* passport start */}
          <div className="ids-box kyc-business-box">
            <div className="single-box-card">
              <Typography
                className="mb-1rem  d-flex jc-start registration0875RemFontSize"
                component="h1"
                variant="h5"
                onClick={() => handleFileClick("idPassport")}
                sx={{
                  fontFamily: "Helvetica, sans-serif",
                  textDecoration: "underline",
                  cursor: "pointer",
                  color: "#454745",
                  marginTop: "16px",
                }}
              >
                {i18next.t("Add Passport Main Page")}
              </Typography>
              <input
                type="file"
                ref={passportRef}
                accept=".png, .jpeg, .jpg, .pdf"
                style={{ display: "none" }}
                onChange={(e) => handleFileUpload(e, "passport")}
              />

              {selectedFiles.passport ? (
                <FilePreview
                  src={URL.createObjectURL(selectedFiles.passport)}
                  width="90%"
                  alt="ID Card Back Page"
                  file={selectedFiles.passport}
                />
              ) : (
                <Typography
                  className="mb-1rem  d-flex jc-start registration0875RemFontSize"
                  component="h1"
                  variant="h5"
                  sx={{
                    fontFamily: "Helvetica, sans-serif",
                    fontWeight: 400,
                    color: "#454745",
                  }}
                >
                  {i18next.t("No Passport Main Page Image")}
                </Typography>
              )}
            </div>
          </div>
          {/* passport end */}

          {/* personal start */}
          <div className="ids-box kyc-business-box">
            <div className="single-box-card">
              <Typography
                className="mb-1rem  d-flex jc-start registration0875RemFontSize"
                component="h1"
                variant="h5"
                onClick={() => handleFileClick("idPersonal")}
                sx={{
                  fontFamily: "Helvetica, sans-serif",
                  textDecoration: "underline",
                  cursor: "pointer",
                  color: "#454745",
                  marginTop: "16px",
                }}
              >
                {i18next.t("Add Personal Image")}
              </Typography>
              <input
                type="file"
                ref={personalPhotoRef}
                accept=".png, .jpeg, .jpg, .pdf"
                style={{ display: "none" }}
                onChange={(e) => handleFileUpload(e, "personalPhoto")}
              />

              {selectedFiles.personalPhoto ? (
                <div style={{ marginBottom: "24px" }}>
                  <FilePreview
                    src={URL.createObjectURL(selectedFiles.personalPhoto)}
                    width="90%"
                    alt="ID Card Back Page"
                    file={selectedFiles.personalPhoto}
                  />
                </div>
              ) : (
                <Typography
                  className="mb-1rem  d-flex jc-start registration0875RemFontSize"
                  component="h1"
                  variant="h5"
                  sx={{
                    fontFamily: "Helvetica, sans-serif",
                    fontWeight: 400,
                    color: "#454745",
                  }}
                >
                  {i18next.t("No Personal Photo")}
                </Typography>
              )}
            </div>
          </div>
          {/* personal end */}
        </Box>
        {/* Scann start */}
        {hasCamera && (
          <Box>
            <Box sx={{ marginTop: 2, marginBottom: 2 }}>
              <Typography className="loginTitleFontSize">
                {i18next.t("Personal scan verification")}
              </Typography>
            </Box>
            <Box className="personal-box-kyc mb-big">
              <div className="ids-box kyc-business-box">
                <div className="single-box-card">
                  <Typography
                    className="mb-1rem  d-flex jc-start registration0875RemFontSize"
                    component="h1"
                    variant="h5"
                    onClick={() => handleFileClick("scannedPhoto")}
                    sx={{
                      fontFamily: "Helvetica, sans-serif",
                      textDecoration: "underline",
                      cursor: "pointer",
                      color: "#454745",
                      marginTop: "16px",
                    }}
                  >
                    {i18next.t("Add scan face photo and id card photo")}
                  </Typography>

                  {/* File preview start */}
                  {selectedFiles.scannedFacePhoto &&
                  selectedFiles.scannedIdPhoto ? (
                    <Box>
                      <Typography
                        className="mb-1rem  d-flex jc-start registration0875RemFontSize"
                        component="h1"
                        variant="h5"
                        sx={{
                          fontFamily: "Helvetica, sans-serif",
                          textDecoration: "underline",
                          cursor: "pointer",
                          color: "#454745",
                          marginTop: "16px",
                        }}
                      >
                        {i18next.t("Face scan")}
                      </Typography>
                      <FilePreview
                        src={URL.createObjectURL(
                          selectedFiles.scannedFacePhoto
                        )}
                        width="90%"
                        alt="Scanned face photo"
                        file={selectedFiles.scannedFacePhoto}
                      />
                      <Typography
                        className="mb-1rem  d-flex jc-start registration0875RemFontSize"
                        component="h1"
                        variant="h5"
                        sx={{
                          fontFamily: "Helvetica, sans-serif",
                          textDecoration: "underline",
                          cursor: "pointer",
                          color: "#454745",
                          marginTop: "16px",
                        }}
                      >
                        {i18next.t("ID Scan")}
                      </Typography>

                      <FilePreview
                        src={URL.createObjectURL(selectedFiles.scannedIdPhoto)}
                        width="90%"
                        alt="Scanned id card photo"
                        file={selectedFiles.scannedIdPhoto}
                      />

                      <Typography
                        className="mb-1rem  d-flex jc-start registration0875RemFontSize"
                        component="h1"
                        variant="h5"
                        sx={{
                          fontFamily: "Helvetica, sans-serif",
                          textDecoration: "underline",
                          cursor: "pointer",
                          color: "#454745",
                          marginTop: "16px",
                        }}
                      >
                        {i18next.t("Complete scan")}
                      </Typography>

                      <FilePreview
                        src={URL.createObjectURL(
                          selectedFiles.scannedCompletePhoto
                        )}
                        width="90%"
                        alt="Scanned id card photo"
                        file={selectedFiles.scannedCompletePhoto}
                      />
                    </Box>
                  ) : (
                    <Typography
                      className="mb-1rem  d-flex jc-start registration0875RemFontSize"
                      component="h1"
                      variant="h5"
                      sx={{
                        fontFamily: "Helvetica, sans-serif",
                        fontWeight: 400,
                        color: "#454745",
                      }}
                    >
                      {i18next.t("No scanned face photo or id card")}
                    </Typography>
                  )}
                  {/* File Preview End */}
                </div>
              </div>
            </Box>
          </Box>
        )}
        {/* Scann end */}
        {openCamModal && (
          <ScannFaceAndIdModal
            open={openCamModal}
            handleClose={handleCloseModal}
            handleUploadScannedFile={handleUploadScannedFile}
          />
        )}{" "}
        <CameraAccessModal
          open={showCameraAccessModal}
          handleClose={handleCloseCameraAccessModal}
        />
        <Button
          onClick={handleSubmit}
          fullWidth
          disabled={!checkIfSubmitEnabled()}
          variant="contained"
          className="btnsLoginHeight oneRemFontSize"
          sx={{
            color: "white",
            backgroundColor: "#2A5182",
            borderRadius: "24px",
            textTransform: "none",
            marginTop: "0.5rem",
            fontFamily: "Helvetica, sans-serif",
          }}
        >
          {i18next.t("Submit")}
        </Button>
      </Form>
    </Formik>
  );
};

export default KYCStep;
