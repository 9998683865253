import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "src/store";
import { BUSINESS_ACCOUNT } from "../shared/constants";
import moment from "moment";
import {
  registerUser,
  resetRegisterState,
} from "src/store/register/register_slice";
import * as Yup from "yup";
import { toast } from "react-toastify";
import DatePickerInputFiled from "../shared/DatePickerInputFiled";
import ContinueBackButtons from "../shared/ContinueBackButtons";
import CountrySelect from "../shared/CountrySelect/CountrySelect";
import PhoneCountrySelect from "../shared/PhoneCountrySelect/PhoneCountrySelect";
import i18next from "i18next";
import CustomInput from "../shared/CustomInput/CustomInput";
import { Box } from "@mui/material";

interface Props {
  setStep: (value: number, formikValues: any) => void;
  step: number;
}

function BussinesAccountForm({ setStep, step }: Props) {
  const [formStep, setFormStep] = useState(1);
  const { isLoading, token, isRegistered, isError } = useAppSelector(
    (state) => state.register
  );
  const dispatch = useAppDispatch();

  const formik = useFormik({
    initialValues: {
      first_name: "",
      last_name: "",
      birthday: null,
      name: "",
      registration_number: "",
      incorporation_date: null,
      country: "",
      website: "",
      number_of_employees: undefined,
      phone: "",
      country_code: "",
      address: "",
      city: "",
      postal_code: "",
    },
    onSubmit: (values: any) => {
      if (formStep === 5) {
        if (values.number_of_employees === "") {
          values.number_of_employees = 0;
        }
        values.account_type = BUSINESS_ACCOUNT;
        if (typeof values.incorporation_date === "string") {
          values.incorporation_date = values.incorporation_date || null;
        } else {
          values.incorporation_date = moment(
            values.incorporation_date.$d
          ).format("YYYY-MM-DD");
        }
        if (typeof values.birthday === "string") {
          values.birthday = values.birthday || null;
        } else {
          values.birthday = moment(values.birthday.$d).format("YYYY-MM-DD");
        }
        values.phone = values.phone.toString();
        values.postal_code = values.postal_code.toString();
        dispatch(registerUser({ values, token }));
      }
    },
    validateOnChange: false,
    validateOnBlur: false,
    validationSchema: Yup.object({
      name: Yup.string().required(i18next.t("Name is require field.")),
      registration_number: Yup.string().required(
        i18next.t("Registration number is require field.")
      ),
      incorporation_date: Yup.string().required(
        i18next.t("Incorporation date is require field.")
      ),
      country: Yup.string().required(i18next.t("Country is required field.")),
      phone: Yup.string().required(
        i18next.t("Phone Number is required field.")
      ),
      address: Yup.string().required(i18next.t("Address is require field.")),
      city: Yup.string().required(i18next.t("City is require field.")),
      postal_code: Yup.string().required(
        i18next.t("Postal Code is require field.")
      ),
      first_name: Yup.string().required(
        i18next.t("First name is require filed.")
      ),
      last_name: Yup.string().required(
        i18next.t("Last name is require filed.")
      ),
      birthday: Yup.string().required(i18next.t("Birthday is require filed.")),
    }),
  });

  const handleCountrySelect = (value: any) => {
    formik.setFieldValue("country", value);
  };

  const handlePhoneCountryCodeSelect = (value: any) => {
    formik.setFieldValue("country_code", value);
  };

  useEffect(() => {
    if (isRegistered) {
      toast.success(i18next.t("Creating account - SUCCESS!"));
      setStep(step + 1, { ...formik.values, token: token });
      dispatch(resetRegisterState());
    }
  }, [isRegistered]);

  return (
    <>
      {formStep === 1 && (
        <Box className="initial-screen d-flex ai-center jc-center fx-column ta-center content-container-home">
          <h3
            className="fs-1-625 fw-400 loginTitleFontSize"
            style={{ fontFamily: "Helvetica, sans-serif", fontWeight: 400 }}
          >
            {i18next.t("Personal Details")}
          </h3>
          <h3
            className="font-size-14 mt-16 fw-300 registration0875RemFontSize"
            style={{
              fontFamily: "Helvetica, sans-serif",
              textAlign: "start",
              marginBottom: "17px",
            }}
          >
            {i18next.t(
              "Please add your personal details, so we can create you secure and worldwide accepted payment gate!"
            )}
          </h3>

          <Box className="mt-16 w-100">
            <CustomInput
              id="first_name"
              placeHolder={i18next.t("First name")}
              value={formik.values.first_name}
              onChangeText={(value) =>
                formik.setFieldValue("first_name", value)
              }
              isTouched={!!formik.touched.first_name || formik.submitCount > 0}
              errors={formik.errors.first_name}
              nextId="last_name"
              isRequired={true}
            />
          </Box>

          <Box className="mt-16 w-100">
            <CustomInput
              id="last_name"
              placeHolder={i18next.t("Last name")}
              value={formik.values.last_name}
              onChangeText={(value) => formik.setFieldValue("last_name", value)}
              isTouched={!!formik.touched.last_name || formik.submitCount > 0}
              errors={formik.errors.last_name}
              isRequired={true}
            />
          </Box>

          <Box className="mt-16 w-100">
            <DatePickerInputFiled
              required={true}
              isTouched={formik.touched.birthday}
              errors={formik.errors.birthday}
              value={formik.values.birthday}
              onChange={formik.setFieldValue}
              name="birthday"
              label={i18next.t("Birthday")}
            />
          </Box>

          <ContinueBackButtons
            errors={["first_name", "last_name", "birthday"]}
            setStep={setFormStep}
            step={formStep}
            formik={formik}
            lastStep={5}
          />
        </Box>
      )}

      {formStep === 2 && (
        <Box className="initial-screen d-flex ai-center jc-center fx-column ta-center content-container-home">
          <h3
            className="fs-1-625 fw-400 loginTitleFontSize"
            style={{ fontFamily: "Helvetica, sans-serif", fontWeight: 400 }}
          >
            {i18next.t("Bussiness information")}
          </h3>
          <h3
            className="font-size-14 mt-16 fw-300 registration0875RemFontSize"
            style={{
              fontFamily: "Helvetica, sans-serif",
              textAlign: "start",
              marginBottom: "17px",
            }}
          >
            {i18next.t(
              "Please add bussines information so you can create your account."
            )}
          </h3>

          <Box className="mt-16 w-100">
            <CustomInput
              id="name"
              placeHolder={i18next.t("Bussines name")}
              value={formik.values.name}
              onChangeText={(value) => formik.setFieldValue("name", value)}
              isTouched={!!formik.touched.name || formik.submitCount > 0}
              errors={formik.errors.name}
              nextId="registration_number"
              isRequired={true}
            />
          </Box>

          <Box className="mt-16 w-100">
            <CustomInput
              id="registration_number"
              placeHolder={i18next.t("Registration number")}
              value={formik.values.registration_number}
              onChangeText={(value) =>
                formik.setFieldValue("registration_number", value)
              }
              isTouched={
                !!formik.touched.registration_number || formik.submitCount > 0
              }
              errors={formik.errors.registration_number}
              isRequired={true}
            />
          </Box>

          <Box className="mt-16 w-100">
            <DatePickerInputFiled
              required={true}
              isTouched={formik.touched.incorporation_date}
              errors={formik.errors.incorporation_date}
              value={formik.values.incorporation_date}
              onChange={formik.setFieldValue}
              name="incorporation_date"
              label={i18next.t("Incorporation date")}
            />
          </Box>

          <ContinueBackButtons
            errors={["name", "registration_number", "incorporation_date"]}
            setStep={setFormStep}
            step={formStep}
            formik={formik}
            lastStep={5}
          />
        </Box>
      )}

      {formStep === 3 && (
        <Box className="initial-screen d-flex ai-center jc-center fx-column ta-center content-container-home">
          <h3
            className="fs-1-625 fw-400 loginTitleFontSize"
            style={{ fontFamily: "Helvetica, sans-serif", fontWeight: 400 }}
          >
            {i18next.t("Address Details")}
          </h3>
          <h3
            className="font-size-14 mt-16 fw-300 registration0875RemFontSize"
            style={{
              fontFamily: "Helvetica, sans-serif",
              marginBottom: "17px",
              textAlign: "start",
            }}
          >
            {i18next.t(
              "We are almost done! Now please add your address details, so your account can be finalized!"
            )}
          </h3>

          <Box
            className="country-code-container-personal mt-16"
            style={{ width: "100%" }}
          >
            <CountrySelect
              value={formik.values.country}
              isTouched={formik.touched.country}
              errors={formik.errors.country}
              onSelect={handleCountrySelect}
            />
          </Box>

          <Box className="mt-16 w-100">
            <CustomInput
              id="address"
              placeHolder={i18next.t("Address")}
              value={formik.values.address}
              onChangeText={(value) => formik.setFieldValue("address", value)}
              isTouched={!!formik.touched.address || formik.submitCount > 0}
              errors={formik.errors.address}
              nextId="city"
              isRequired={true}
            />
          </Box>

          <Box className="mt-16 w-100">
            <CustomInput
              id="city"
              placeHolder={i18next.t("City")}
              value={formik.values.city}
              onChangeText={(value) => formik.setFieldValue("city", value)}
              isTouched={!!formik.touched.city || formik.submitCount > 0}
              errors={formik.errors.city}
              nextId="postal_code"
              isRequired={true}
            />
          </Box>

          <Box className="mt-16 w-100">
            <CustomInput
              id="postal_code"
              placeHolder={i18next.t("Postal Code")}
              value={formik.values.postal_code}
              onChangeText={(value) =>
                formik.setFieldValue("postal_code", value)
              }
              isTouched={!!formik.touched.postal_code || formik.submitCount > 0}
              errors={formik.errors.postal_code}
              isRequired={true}
            />
          </Box>

          <ContinueBackButtons
            errors={["address", "country", "city", "postal_code"]}
            setStep={setFormStep}
            step={formStep}
            formik={formik}
            lastStep={5}
          />
        </Box>
      )}

      {formStep === 4 && (
        <Box className="initial-screen d-flex ai-center jc-center fx-column ta-center content-container-home">
          <h3
            className="fs-1-625 fw-400 loginTitleFontSize"
            style={{ fontFamily: "Helvetica, sans-serif", fontWeight: 400 }}
          >
            {i18next.t("Company information")}
          </h3>
          <h3
            className="font-size-14 mt-16 fw-300 registration0875RemFontSize"
            style={{
              fontFamily: "Helvetica, sans-serif",
              marginBottom: "17px",
            }}
          >
            {i18next.t(
              "Please add company information so you can create your account."
            )}
          </h3>

          <Box className="mt-16 w-100">
            <CustomInput
              id="number_of_employees"
              placeHolder={i18next.t("Number of employees")}
              value={formik.values.number_of_employees}
              onChangeText={(value) =>
                formik.setFieldValue("number_of_employees", value)
              }
              isTouched={
                !!formik.touched.number_of_employees || formik.submitCount > 0
              }
              errors={formik.errors.number_of_employees}
              type="number"
              nextId="website"
            />
          </Box>

          <Box className="mt-16 w-100">
            <CustomInput
              id="website"
              placeHolder={i18next.t("Website link")}
              value={formik.values.website}
              onChangeText={(value) => formik.setFieldValue("website", value)}
              isTouched={!!formik.touched.website || formik.submitCount > 0}
              errors={formik.errors.website}
            />
          </Box>

          <ContinueBackButtons
            errors={["website", "number_of_employees"]}
            setStep={setFormStep}
            step={formStep}
            formik={formik}
            lastStep={5}
          />
        </Box>
      )}

      {formStep === 5 && (
        <Box className="initial-screen d-flex ai-center jc-center fx-column ta-center content-container-home">
          {/* <h3
            className="fs-1-625 fw-400"
            style={{ fontFamily: "Helvetica, sans-serif", fontWeight: 400 }}
          >
            Phone information
          </h3> */}
          <h3
            className="fs-1-625 fw-400 loginTitleFontSize"
            style={{
              fontFamily: "Helvetica, sans-serif",
              fontWeight: 400,
              marginBottom: "18px",
              textAlign: "start",
            }}
          >
            {i18next.t(
              "Now let´s make your account safe by adding your mobile number!"
            )}
          </h3>

          <Box className="w-100 d-flex flex-row jusifyContentSpaceBetween">
            <Box className="mt-16 w-30">
              <PhoneCountrySelect
                value={formik.values.country_code}
                onSelect={handlePhoneCountryCodeSelect}
              />
            </Box>

            <Box className="mt-16 w-70M">
              <CustomInput
                id="phone"
                placeHolder={i18next.t("Phone Number")}
                value={formik.values.phone}
                onChangeText={(value) => formik.setFieldValue("phone", value)}
                isTouched={!!formik.touched.phone || formik.submitCount > 0}
                errors={formik.errors.phone}
                isRequired={true}
                type="number"
              />
            </Box>
          </Box>

          <ContinueBackButtons
            errors={["phone"]}
            setStep={setFormStep}
            step={formStep}
            formik={formik}
            lastStep={5}
          />
        </Box>
      )}
    </>
  );
}

export default BussinesAccountForm;
