import React from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Box, Typography } from "@mui/material";
import HeaderLanding from "../components/HeaderLanding/HeaderLanding";
import { useAppSelector } from "src/store";
import FooterLanding from "../components/FooterLanding/FooterLanding";
import "./LandingPagesGlobal.scss";
import TitleBackBox from "../components/TitleBackBox/TitleBackBox";
import HeadlineVvidgetSmall from "../components/HeadlineVvidget/HeadlineVvidgetSmall";
import LogoTextVvidget from "../components/LogoTextVvidget/LogoTextVvidget";
import ImageWithText from "../components/ImageWithText/ImageWithText";
import CryptoImg from "../../../assets/images/instantPaymentsImg.jpg";
import i18next from "i18next";

const defaultTheme = createTheme();
const MezzanineCapital: React.FC = () => {
  const { user } = useAppSelector((state) => state.auth);

  const navigateToPartner = (url: string, event: any) => {
    event.preventDefault();
    window.open(url, "_blank");
  };

  return (
    <div>
      <HeaderLanding user={user} />
      <div className="margintTopPagesMainCont">
        <ThemeProvider theme={defaultTheme}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Box className="titleHeadlineSmallBG">
              <Box className="landingPagesMainBox">
                <Box>
                  <TitleBackBox title={i18next.t("Mezzanine capital")} />
                </Box>
                <Box>
                  <HeadlineVvidgetSmall
                    headline={i18next.t(
                      "Partnerships and Corporate Finance services on the highest level"
                    )}
                    subtitle={""}
                    smallText={i18next.t("check our partner")}
                    buttonText={i18next.t("Our partner")}
                    onClickFunction={(event: any) =>
                      navigateToPartner(
                        "https://www.victorum-capital.com/kunden/#beteiligung",
                        event
                      )
                    }
                  />
                </Box>
              </Box>
            </Box>
            <Box className="landingPagesMainBox">
              <Box>
                <Typography
                  variant="h3"
                  sx={{
                    marginBottom: "1rem",
                  }}
                  className="headlineMiniLandingPages"
                >
                  {i18next.t("VicPay's Focus and Service Differentiation")}
                </Typography>
                <Typography
                  variant="body1"
                  paragraph
                  className="textsLandingPage"
                >
                  {i18next.t(
                    "We regularly receive enquiries as to whether VicPay not only opens accounts, but also offers loans and financing for companies."
                  )}
                </Typography>
                <Typography
                  variant="body1"
                  paragraph
                  className="textsLandingPage"
                >
                  {i18next.t("Our answer is quite simple: No.")}
                </Typography>
                <Typography
                  variant="body1"
                  paragraph
                  className="textsLandingPage"
                >
                  {i18next.t(
                    "No, VicPay does not offer mezzanine capital solutions or other or corporate finance services. This area is covered by"
                  )}{" "}
                  <span
                    className="linksHover"
                    onClick={(event) =>
                      navigateToPartner(
                        "https://www.victorum-capital.com/",
                        event
                      )
                    }
                  >
                    {i18next.t("Victorum Capital")}
                  </span>{" "}
                  {i18next.t("Inc, Vancouver - another company from the")}{" "}
                  <span
                    className="linksHover"
                    onClick={(event) =>
                      navigateToPartner(
                        "https://www.victorum-group.com/",
                        event
                      )
                    }
                  >
                    {i18next.t("Victorum Group")}
                  </span>
                  !
                </Typography>
              </Box>
              <Box>
                <LogoTextVvidget
                  text={
                    <>
                      {i18next.t(
                        "The experts at Victorum Capital offer established small and medium-sized companies advice on strengthening equity, direct investments and buyout transactions."
                      )}
                      <br />
                      {i18next.t("Fast, reliable and experienced.")}
                    </>
                  }
                />
              </Box>
              <Box>
                <ImageWithText
                  backgroundImage={CryptoImg}
                  noiseImage="/img/payments-noise.png"
                  title={i18next.t("Responding to Urgency")}
                  text={i18next.t(
                    "Situations in which external capital is needed in a company often arise quickly and unexpectedly."
                  )}
                />
              </Box>
              <Box>
                <Typography
                  variant="h3"
                  sx={{ marginBottom: "1rem" }}
                  className="headlineMiniLandingPages"
                >
                  {i18next.t(
                    "With uncomplicated mezzanine solutions and direct investments,"
                  )}
                  <br />
                  {i18next.t(
                    "Victorum Capital and its globally active partners create an alternative to banks that are often slow and complicated to act."
                  )}
                </Typography>
                <Typography
                  variant="body1"
                  paragraph
                  className="textsLandingPage"
                  style={{ marginTop: "2rem" }}
                >
                  <span className="textsLandingsmallNoFontSize">
                    {i18next.t("The focus is on")}
                  </span>
                </Typography>
                <Typography
                  variant="body1"
                  paragraph
                  className="textsLandingPage"
                  style={{ marginTop: "0.5rem" }}
                >
                  <span className="textsLandingsmallNoFontSize">
                    {i18next.t("Corporate Banking")}
                    <br />
                    {i18next.t("M&A Consulting")}
                    <br />
                    {i18next.t("Corporate Financing")}
                    <br />
                    {i18next.t("Direct Investments")}
                    <br />
                    {i18next.t("Succession Planning")}
                    <br />
                    {i18next.t("Expansion")}
                    <br />
                    {i18next.t("Buy-Outs")}
                  </span>
                </Typography>
              </Box>
              <Box sx={{ marginTop: "2rem" }}>
                <Typography
                  variant="h3"
                  sx={{
                    marginBottom: "1rem",
                  }}
                  className="headlineMiniLandingPages"
                >
                  {" "}
                  {i18next.t(
                    "Explore Flexible and Innovative Financing with VicPay Services Inc."
                  )}
                </Typography>
                <Typography
                  variant="body1"
                  paragraph
                  className="textsLandingPage"
                >
                  {i18next.t(
                    "The VicPay Services Inc. network offers entrepreneurs flexible, innovative and bank-independent forms of financing - just"
                  )}{" "}
                  <span
                    className="linksHover"
                    onClick={(event) =>
                      navigateToPartner(
                        "https://www.victorum-capital.com/",
                        event
                      )
                    }
                  >
                    {i18next.t("click here")}
                  </span>{" "}
                  {i18next.t(
                    "to go to the VicPay Services Inc. website and find out more!"
                  )}
                </Typography>
              </Box>
            </Box>
          </Box>
        </ThemeProvider>
      </div>
      <Box sx={{ marginTop: "4rem" }}>
        <FooterLanding />
      </Box>
    </div>
  );
};

export default MezzanineCapital;
