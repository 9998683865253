import { Avatar, Box, LinearProgress, Typography } from "@mui/material";
import "../../../pages/Chat/Chat.scss";
import moment from "moment";
import { useEffect, useState } from "react";
import PDFModal from "../PdfModal/PdfModal";
import { debounce } from "lodash";

export default function ConversationContainerUser({
  user,
  conversations,
  conversationMessagesUser,
  chatContainerRef,
  handleScroll,
  loadMore,
}: {
  user: any;
  conversations: any;
  conversationMessagesUser: any;
  chatContainerRef: React.RefObject<HTMLDivElement>;
  loadMore: boolean;
  handleScroll: () => void;
}) {
  const formatTimestamp = (timestamp: string) => {
    const createdAtMoment = moment(timestamp);
    const now = moment();

    const diffInMinutes = now.diff(createdAtMoment, "minutes");
    const diffInHours = now.diff(createdAtMoment, "hours");

    if (diffInMinutes < 1) {
      return "Just now";
    } else if (diffInMinutes < 60) {
      return `${diffInMinutes} minutes ago`;
    } else if (diffInHours < 24) {
      return `${diffInHours} hours ago`;
    } else {
      return createdAtMoment.format("MMM D, YYYY [at] h:mm A");
    }
  };

  // pdf start
  const [openModal, setOpenModal] = useState(false);
  const [selectedPdf, setSelectedPdf] = useState("");

  const handleOpenModal = (pdfUrl: any) => {
    setSelectedPdf(pdfUrl);
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };
  // pdf end

  const renderAttachment = (attachment: any) => {
    const fileExtension = attachment.file_name.split(".").pop()?.toLowerCase();
    const isImage =
      attachment.mime.startsWith("image/") ||
      fileExtension === "jpg" ||
      fileExtension === "jpeg" ||
      fileExtension === "png" ||
      fileExtension === "gif" ||
      fileExtension === "webp";

    if (isImage) {
      return (
        <img
          key={attachment.id}
          src={attachment.file_url}
          alt={attachment.file_name}
          style={{ maxWidth: "100%", maxHeight: "200px" }}
        />
      );
    } else if (fileExtension === "pdf") {
      return (
        <div key={attachment.id}>
          <Typography>{attachment.original_name}</Typography>
          <button
            style={{
              background: "none",
              border: "none",
              padding: 0,
              color: "#007bff",
              textDecoration: "underline",
              cursor: "pointer",
              fontSize: "0.65rem",
            }}
            onClick={() => handleOpenModal(attachment.file_url)}
            type="button"
          >
            View PDF attachment
          </button>
          <PDFModal
            open={openModal}
            handleClose={handleCloseModal}
            fileUrl={selectedPdf}
          />
        </div>
      );
    } else {
      return (
        <div>
          <Typography>{attachment.original_name}</Typography>
          <button
            style={{
              background: "none",
              border: "none",
              padding: 0,
              color: "#007bff",
              textDecoration: "underline",
              cursor: "pointer",
              fontSize: "0.65rem",
            }}
            key={attachment.id}
            onClick={() => window.open(attachment.file_url, "_blank")}
            type="button"
          >
            View document
          </button>
        </div>
      );
    }
  };

  useEffect(() => {
    scrollToBottom();
  }, [conversationMessagesUser, chatContainerRef]);

  const scrollToBottom = () => {
    if (chatContainerRef.current) {
      const { scrollTop, scrollHeight } = chatContainerRef.current;

      chatContainerRef.current.scrollTop = scrollHeight;
    }
  };
  useEffect(() => {
    const handleContainerScroll = debounce(() => {
      const container = chatContainerRef.current;

      if (container) {
        const scrollTop = container.scrollTop;
        const isNearTop = scrollTop <= 50;
        const currentLoadMore = loadMore;

        if (isNearTop && !currentLoadMore) {
          handleScroll();
        }
      }
    }, 100);

    const currentRef = chatContainerRef.current;

    if (currentRef) {
      currentRef.addEventListener("scroll", handleContainerScroll);
      return () => {
        currentRef.removeEventListener("scroll", handleContainerScroll);
      };
    }
  }, [chatContainerRef, handleScroll, loadMore]);

  return (
    <>
      {loadMore && <LinearProgress />}
      <Box className="convoBox" ref={chatContainerRef}>
        {conversationMessagesUser.length > 0
          ? conversationMessagesUser
              .slice()
              .reverse()
              .map((message: any) => (
                <Box
                  key={"message" + message.id}
                  className={
                    message.user_id === user?.id ? "chatUsMain" : "chatThemMain"
                  }
                >
                  {message.user_id !== user?.id && (
                    <Avatar
                      sx={{
                        width: "32px",
                        height: "32px",
                        marginRight: "16px",
                      }}
                    ></Avatar>
                  )}
                  <Box
                    className={
                      message.user_id === user?.id
                        ? "lastTimeAndMessageUs"
                        : "lastTimeAndMessageThem"
                    }
                  >
                    <Box>
                      {" "}
                      <Typography
                        className={
                          message.user_id === user?.id
                            ? "lastTimeConvoUs"
                            : "lastTimeConvoThem"
                        }
                      >
                        {formatTimestamp(message.created_at)}
                      </Typography>
                    </Box>
                    <Box
                      className={
                        message.user_id === user?.id
                          ? "chatUsBox"
                          : "chatThemBox"
                      }
                    >
                      <Typography
                        className={
                          message.user_id === user?.id
                            ? "chatUsText"
                            : "chatThemText"
                        }
                      >
                        {message.message}
                      </Typography>
                      <Box
                        className={
                          message.user_id === user?.id
                            ? "attachmentUsText"
                            : "attachmentThemText"
                        }
                      >
                        {message.attachments &&
                          message.attachments.length > 0 &&
                          message.attachments.map((attachment: any) =>
                            renderAttachment(attachment)
                          )}
                      </Box>
                    </Box>
                  </Box>
                </Box>
              ))
          : null}
      </Box>
    </>
  );
}
