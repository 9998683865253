import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import "./Users.scss";
import Nav from "src/components/layout/nav/Nav";
import {
  Box,
  Typography,
  TextField,
  InputAdornment,
  Checkbox,
  Select,
  MenuItem,
  SelectChangeEvent,
} from "@mui/material";
import { useEffect, useState } from "react";
import SearchIcon from "@mui/icons-material/Search";
import LoadingIndicator from "src/components/shared/LoadingIndicator";
import UserList from "src/components/Users/UserList";
import usersService from "src/store/users/users_services";
import Pagination from "@mui/material/Pagination";
const defaultTheme = createTheme();

export default function Users() {
  const [usersData, setUsersData] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  const [page, setPage] = useState(1);
  const [perPage] = useState(10);
  const [totalPages, setTotalPages] = useState(1);
  const [total, setTotal] = useState(null);

  const [checkRequests, setCheckRequests] = useState<any>(false);
  const [checkApproved, setCheckApproved] = useState<any>(false);
  const [searchValue, setSearchValue] = useState("");
  const [accountType, setAccountType] = useState<any>("all");

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(event.target.value);
  };

  const handleCheckRequests = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCheckRequests(event.target.checked);
  };

  const handleCheckApproved = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCheckApproved(event.target.checked);
  };

  const handleChangeAccountType = (event: SelectChangeEvent) => {
    setAccountType(event.target.value as string);
  };

  const handleGetUsers = async () => {
    try {
      setIsLoading(true);
      let urlFilter: string = "";

      if (searchValue) {
        urlFilter = `?page=${page}&per_page=${perPage}&search[role]=${searchValue}&search[companies.name]=${searchValue}&search[first_name]=${searchValue}&search[last_name]=${searchValue}&search[email]=${searchValue}&sort[verification_approved]=DESC&sort[first_name]=ASC`;
      } else {
        urlFilter = `?page=${page}&per_page=${perPage}&sort[verification_approved]=DESC&sort[first_name]=ASC`;
      }

      let filters = [];
      let searchParam = "filter_search";
      if (checkRequests && checkApproved) {
        searchParam = "filter_search";
        filters.push("&search_condition=or");
      } else {
        filters.push("&search_condition=and");
      }

      let filt = "";
      if (checkRequests) {
        filters.push("&" + searchParam + "[verification_requested]=1");
        if (checkApproved)
          filters.push("&" + searchParam + "[verification_approved]=1");
        else filters.push("&" + searchParam + "[verification_approved]=0");
      }
      if (checkApproved) {
        filt = "&" + searchParam + "[verification_approved]=1";
        if (!filters.includes(filt)) filters.push(filt);
      }
      if (accountType !== "all") {
        filt = "&" + searchParam + "[account_type]=" + accountType;
        if (!filters.includes(filt)) filters.push(filt);
      }
      urlFilter += filters.join("");

      const response = await usersService.getUsers(urlFilter);
      const { data, meta } = response;
      setIsLoading(false);
      if (data) {
        setUsersData(data);
      }
      if (meta) {
        setTotalPages(meta?.last_page || 1);
        setTotal(meta?.total || 0);
      }
    } catch (error) {
      console.log("error getting user");
    }
  };

  useEffect(() => {
    handleGetUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchValue, checkRequests, checkApproved, page, accountType]);

  return (
    <div>
      <Nav step={0} isStepperVisible={false} isProfileSetVisible={true} />
      <div className="main-admin-container">
        <div className="content-container-profile main-color">
          <ThemeProvider theme={defaultTheme}>
            <Container component="main">
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <div className="d-flex ai-center mb-3-rem">
                  <Typography
                    className="your-details-text d-flex jc-start pageTitleFontSize"
                    component="h1"
                    variant="h5"
                    sx={{
                      fontFamily: "Helvetica, sans-serif",
                      textAlign: "center",
                      fontWeight: "600",
                    }}
                  >
                    Users
                  </Typography>
                </div>

                <div>
                  <Box>
                    <TextField
                      placeholder="Search user by name"
                      style={{ width: "100%" }}
                      value={searchValue}
                      onChange={handleSearchChange}
                      InputProps={{
                        autoComplete: "new-password",
                        startAdornment: (
                          <InputAdornment position="start">
                            <SearchIcon className="searchIcon" />
                          </InputAdornment>
                        ),
                        sx: {
                          borderRadius: "40px",
                          height: "48px",
                          fontSize: "16px",
                          "&:-internal-autofill-selected": {
                            backgroundColor: "transparent !important",
                          },
                        },
                      }}
                    ></TextField>
                  </Box>

                  <Box
                    className="d-flex ai-center"
                    sx={{
                      flexWrap: "wrap",
                      marginTop: {
                        lg: "0",
                        md: "0",
                        sm: "0",
                        xs: "15px",
                      },
                    }}
                  >
                    <div className="d-flex ai-center">
                      <Checkbox
                        checked={checkRequests}
                        onChange={handleCheckRequests}
                      />
                      <Typography
                        component="span"
                        variant="h5"
                        className="registration0875RemFontSize"
                        sx={{
                          fontFamily: "Helvetica, sans-serif",
                          textAlign: "center",
                          fontWeight: 300,
                        }}
                      >
                        Filter verification requests
                      </Typography>
                    </div>
                    <div className="d-flex ai-center">
                      <Checkbox
                        checked={checkApproved}
                        onChange={handleCheckApproved}
                      />
                      <Typography
                        component="span"
                        variant="h5"
                        className="registration0875RemFontSize"
                        sx={{
                          fontFamily: "Helvetica, sans-serif",
                          textAlign: "center",
                          fontWeight: 300,
                        }}
                      >
                        Filter approved accounts
                      </Typography>
                    </div>
                    <div className="d-flex ai-center ml-8">
                      <Select
                        sx={{
                          height: "30px",
                          borderRadius: "16px",
                          backgroundColor: "rgb(244, 246, 248)",
                          fontSize: "14px",
                          color: "#2A5182",
                          border: "none",
                          "&:focus": {
                            outline: "none",
                            borderRadius: "8px",
                          },
                          "& .MuiOutlinedInput-notchedOutline": {
                            border: "none",
                          },
                          ".MuiSelect-select": {
                            borderRadius: "8px",
                          },
                        }}
                        value={accountType}
                        onChange={handleChangeAccountType}
                      >
                        <MenuItem
                          value={"all"}
                          sx={{ color: "#2A5182", fontSize: "14px" }}
                        >
                          All
                        </MenuItem>
                        <MenuItem
                          sx={{ color: "#2A5182", fontSize: "14px" }}
                          value={"personal"}
                        >
                          Personal
                        </MenuItem>
                        <MenuItem
                          sx={{ color: "#2A5182", fontSize: "14px" }}
                          value={"business"}
                        >
                          Business
                        </MenuItem>
                      </Select>
                      <Typography
                        component="span"
                        variant="h5"
                        className="registration0875RemFontSize"
                        sx={{
                          fontFamily: "Helvetica, sans-serif",
                          textAlign: "center",
                          fontWeight: 300,
                          marginLeft: "0.5rem",
                        }}
                      >
                        Account type
                      </Typography>
                    </div>
                  </Box>
                </div>
                <div>
                  {isLoading ? (
                    <LoadingIndicator />
                  ) : (
                    <div>
                      <UserList data={usersData} total={total} />
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          marginTop: "2rem",
                        }}
                      >
                        <Pagination
                          count={totalPages}
                          page={page}
                          onChange={(event, value) => setPage(value)}
                          color="primary"
                        />
                      </Box>
                    </div>
                  )}
                </div>
              </Box>
            </Container>
          </ThemeProvider>
        </div>
      </div>
    </div>
  );
}
