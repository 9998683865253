import { Box, InputAdornment, TextField, Typography } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import TransactionSection from "./TransactionsSection/TransactionsSection";
import { useNavigate } from "react-router-dom";
import TransactionFiltersDrawer from "../drawer/TransactionFiltersDrawer";
import { useState } from "react";
import SearchIcon from "@mui/icons-material/Search";
import "./TransactionList.scss";
import i18next from "src/i18n";

type Anchor = "top" | "left" | "bottom" | "right";

function TransactionList({
  data,
  redirectPage,
  hideNavigation,
  style,
  hideFilters,
  handleSearchChange,
  searchValue,
  applyFilters,
  printPdf,
  handleClearFilters,
}: any) {
  const [isDrawerOpen, setIsDrawerOpen] = useState<Record<Anchor, boolean>>({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const navigate = useNavigate();

  const handleNavigate = () => {
    navigate(redirectPage ? "/" + redirectPage : "/home");
  };

  const openDrawer = (anchor: Anchor) => {
    setIsDrawerOpen((prevDrawerState) => ({
      ...prevDrawerState,
      [anchor]: true,
    }));
  };

  const closeDrawer = (anchor: Anchor) => {
    setIsDrawerOpen((prevDrawerState) => ({
      ...prevDrawerState,
      [anchor]: false,
    }));
  };
  

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
      }}
      style={style ?? {}}
    >
      <div className="d-flex ai-center mb-3-rem">
        <Box className="titleAndFilters">
          <Box className="transactionTitleAndIcon">
            {!hideNavigation && (
              <p
                className="back-button-icon circle-around-text-smaller helvetica mr-1-rem cp"
                onClick={handleNavigate}
              >
                <ArrowBackIcon className="muiIcons" />
              </p>
            )}

            <Typography
              className="your-details-text d-flex jc-start pageTitleFontSize"
              component="h1"
              variant="h5"
              sx={{
                fontFamily: "Helvetica, sans-serif",
                fontWeight: "600",
                textAlign: "start",
              }}
            >
              {i18next.t("Transactions")}
            </Typography>
          </Box>
          {!hideFilters && (
            <Box className="searchAndFilterBtn">
              <TextField
                placeholder={i18next.t("Search")}
                style={{ width: "100%" }}
                value={searchValue}
                onChange={handleSearchChange}
                className="searchTransactionsField"
                InputProps={{
                  autoComplete: "new-password",
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon className="searchIcon" />
                    </InputAdornment>
                  ),
                  sx: {
                    borderRadius: "38px",
                    height: "38px",
                    fontSize: "16px",
                    "&:-internal-autofill-selected": {
                      backgroundColor: "transparent !important",
                    },
                  },
                }}
              ></TextField>
              <Box width={"100%"}>
                <TransactionFiltersDrawer
                  isDrawerOpen={isDrawerOpen}
                  openDrawer={openDrawer}
                  closeDrawer={closeDrawer}
                  applyFilters={applyFilters}
                  printPdf={printPdf}
                  handleClearFilters={handleClearFilters}
                  searchValue={searchValue}
                />
              </Box>
            </Box>
          )}
        </Box>
      </div>

      <div>
        {data && data.length === 0 ? (
          <Typography
            className="your-details-text d-flex jc-start underline pageTitleFontSize"
            component="h1"
            variant="h5"
            sx={{
              fontFamily: "Helvetica, sans-serif",
              fontWeight: "300",
              textAlign: "start",
              marginBottom: "2rem",
            }}
          >
            {i18next.t("No transactions to display!")}
          </Typography>
        ) : (
          data.map((section: any, index: any) => (
            <TransactionSection
              redirectPage={redirectPage}
              key={index}
              listOfTransactionItems={section.listOfTransactions}
              sectionName={section.name}
            />
          ))
        )}
      </div>
    </Box>
  );
}

export default TransactionList;
