import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import Nav from "src/components/layout/nav/Nav";
import {
  Autocomplete,
  FormControl,
  FormHelperText,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import CountrySelect from "src/components/shared/CountrySelect/CountrySelect";
import { FormikErrors, useFormik } from "formik";
import * as Yup from "yup";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import api from "src/store/interceptors/api";
import { toast } from "react-toastify";
import { useEffect, useState } from "react";
import "./Branches.scss";

const defaultTheme = createTheme();

interface DynamicField {
  key: string;
  value: string;
}

export default function AddBranch() {
  const [currencies, setCurrencies] = useState<any>(null);
  const [currency, setCurrency] = useState<any>(null);
  const [dynamicFields, setDynamicFields] = useState<DynamicField[]>([]);

  const navigate = useNavigate();

  const handleNavigate = () => {
    navigate(`/admin/branches`);
  };

  const formik = useFormik({
    initialValues: {
      currency_id: 1,
      name: "",
      country: "",
      city: "",
      address: "",
      note: "",
      bank_details: [{ type: "", bank_detail_meta: [] }],
    },
    onSubmit: async (values: any) => {
      const bankDetails = values.bank_details.map((detail: any) => ({
        type: detail.type,
        bank_detail_meta: [
          ...detail.bank_detail_meta,
          ...dynamicFields.filter(
            (field) => field.key !== "" || field.value !== ""
          ),
        ],
      }));

      let updatedValues = {
        ...values,
        bank_details: bankDetails,
      };

      if (values.note.trim() === "") {
        const { note, ...rest } = updatedValues;
        updatedValues = rest;
      }

      try {
        await api.post(`/branches`, updatedValues);
        toast.success("Successfully created a branch");
        setTimeout(() => {
          navigate(-1);
        }, 1000);
      } catch (error) {
        toast.error("Something went wrong");
      }
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Branch name is a required field."),
      country: Yup.string().required("Country is a required field."),
      city: Yup.string().required("City is a required field."),
      address: Yup.string().required("Address is a required field."),
      bank_details: Yup.array().of(
        Yup.object().shape({
          type: Yup.string().required(
            "Payment method type is a required field."
          ),
        })
      ),
    }),
  });

  const handleTypeChange = (event: SelectChangeEvent) => {
    const selectedType = event.target.value;
    formik.setFieldValue("bank_details", [
      { type: selectedType, bank_detail_meta: dynamicFields },
    ]);
  };

  const handleCountrySelect = (value: any) => {
    formik.setFieldValue("country", value);
  };

  const getInitialData = async () => {
    await api
      .get("/profile/balances")
      .then((response: any) => {
        setCurrencies(response.data);
        setCurrency(response.data && response.data[0]);
      })
      .catch((error) => {
        console.error(error.message);
      });
  };

  const handleCurrencyChange = (event: any, newValue: any) => {
    setCurrency(newValue);
    formik.setFieldValue("currency_id", newValue.id);
  };

  useEffect(() => {
    getInitialData();
  }, []);

  return (
    <>
      {
        <div>
          <Nav step={0} isStepperVisible={false} />
          <div className="content-container">
            <ThemeProvider theme={defaultTheme}>
              <Container component="main">
                <Box
                  component="form"
                  onSubmit={formik.handleSubmit}
                  noValidate
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <div className="d-flex ai-center mb-1-rem">
                    <p
                      className="back-button-icon circle-around-text-smaller helvetica mr-1-rem cp"
                      onClick={handleNavigate}
                    >
                      <ArrowBackIcon className="muiIcons" />
                    </p>
                    <Typography
                      className="ta-center pageTitleFontSize"
                      component="h1"
                      variant="h5"
                      sx={{
                        fontFamily: "Helvetica, sans-serif",
                        fontWeight: "600",
                        color: "#0e0f0c",
                      }}
                    >
                      Create a new branch
                    </Typography>
                  </div>

                  <Typography
                    component="h5"
                    className="registration0875RemFontSize"
                    variant="h5"
                    sx={{
                      fontFamily: "Helvetica, sans-serif",
                      fontWeight: "300",
                      color: "#454745",
                    }}
                  >
                    You can create a new branch by filling in the form below.
                  </Typography>

                  <Box sx={{ mt: "4rem" }}>
                    <div className="label-and-field mb-1rem">
                      <Box>
                        <Typography className="selectCurrencyLabel">
                          Select currency
                        </Typography>
                      </Box>
                      <Autocomplete
                        id="currency-select"
                        sx={{
                          width: "100%",
                          borderRadius: "10px",
                        }}
                        options={currencies}
                        value={currency}
                        getOptionLabel={(option) => option.currency}
                        onChange={handleCurrencyChange}
                        renderOption={(props, option) => (
                          <Box
                            component="li"
                            {...props}
                            className="d-flex ai-center mt-1-rem cp"
                          >
                            <p
                              className="normal-text"
                              style={{ marginLeft: 10 }}
                            >
                              {option.currency}
                            </p>
                          </Box>
                        )}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            InputProps={{
                              ...params.InputProps,
                              sx: {
                                borderRadius: "10px",
                                height: "48px",
                                fontSize: "16px",
                                "&:-internal-autofill-selected": {
                                  backgroundColor: "transparent !important",
                                },
                                "@media (max-width:600px)": {
                                  fontSize: "14px !important",
                                },
                              },
                            }}
                          />
                        )}
                        clearIcon={null}
                      />
                    </div>

                    <div className="label-and-field mb-1rem">
                      <Typography
                        className="d-flex jc-start registration0875RemFontSize"
                        component="h1"
                        variant="h5"
                        sx={{
                          fontFamily: "Helvetica, sans-serif",
                          fontWeight: "300",
                          color: "#454745",
                          marginBottom: "-8px",
                        }}
                      >
                        Branch name
                      </Typography>

                      <TextField
                        margin="normal"
                        fullWidth
                        placeholder="Name"
                        name="name"
                        autoFocus
                        value={formik.values.name}
                        onChange={formik.handleChange}
                        InputProps={{
                          sx: {
                            borderRadius: "10px",
                            height: "48px",
                            fontSize: "16px",
                            "&:-internal-autofill-selected": {
                              backgroundColor: "transparent !important",
                            },
                            "@media (max-width:600px)": {
                              fontSize: "14px !important",
                            },
                          },
                        }}
                      />
                      {formik.touched.name && formik.errors.name && (
                        <Box className="form-error-message">
                          <Typography className="requiredErrorText">
                            {formik.errors.name.toString()}
                          </Typography>
                        </Box>
                      )}
                    </div>

                    <div className="label-and-field mb-1rem">
                      <CountrySelect
                        value={formik.values.country}
                        isTouched={formik.touched.country}
                        errors={formik.errors.country}
                        onSelect={handleCountrySelect}
                        showTitle={true}
                        showLabel={false}
                      />
                    </div>

                    <div className="label-and-field mb-1rem">
                      <Typography
                        className="d-flex jc-start registration0875RemFontSize"
                        component="h1"
                        variant="h5"
                        sx={{
                          fontFamily: "Helvetica, sans-serif",
                          fontWeight: "300",
                          color: "#454745",
                          marginBottom: "-8px",
                        }}
                      >
                        City
                      </Typography>

                      <TextField
                        margin="normal"
                        required
                        fullWidth
                        id="city"
                        placeholder="City"
                        name="city"
                        value={formik.values.city}
                        onChange={formik.handleChange}
                        InputProps={{
                          sx: {
                            borderRadius: "10px",
                            height: "48px",
                            fontSize: "16px",
                            "&:-internal-autofill-selected": {
                              backgroundColor: "transparent !important",
                            },
                            "@media (max-width:600px)": {
                              fontSize: "14px !important",
                            },
                          },
                        }}
                      />
                      {formik.touched.city && formik.errors.city && (
                        <Box className="form-error-message">
                          <Typography className="requiredErrorText">
                            {formik.errors.city.toString()}
                          </Typography>
                        </Box>
                      )}
                    </div>

                    <div className="label-and-field mb-1rem">
                      <Typography
                        className="d-flex jc-start registration0875RemFontSize"
                        component="h1"
                        variant="h5"
                        sx={{
                          fontFamily: "Helvetica, sans-serif",
                          fontWeight: "300",
                          color: "#454745",
                          marginBottom: "-8px",
                        }}
                      >
                        Address
                      </Typography>

                      <TextField
                        margin="normal"
                        required
                        fullWidth
                        id="address"
                        placeholder="address"
                        name="address"
                        value={formik.values.address}
                        onChange={formik.handleChange}
                        InputProps={{
                          sx: {
                            borderRadius: "10px",
                            height: "48px",
                            fontSize: "16px",
                            "&:-internal-autofill-selected": {
                              backgroundColor: "transparent !important",
                            },
                            "@media (max-width:600px)": {
                              fontSize: "14px !important",
                            },
                          },
                        }}
                      />
                      {formik.touched.address && formik.errors.address && (
                        <Box className="form-error-message">
                          <Typography className="requiredErrorText">
                            {formik.errors.address.toString()}
                          </Typography>
                        </Box>
                      )}
                    </div>
                    <div className="label-and-field mb-1rem">
                      <Typography
                        className="d-flex jc-start registration0875RemFontSize"
                        component="h1"
                        variant="h5"
                        sx={{
                          fontFamily: "Helvetica, sans-serif",
                          fontWeight: "300",
                          color: "#454745",
                          marginBottom: "-8px",
                        }}
                      >
                        Note
                      </Typography>
                      <TextField
                        InputProps={{
                          rows: 4,
                          multiline: true,
                          inputComponent: "textarea",
                          style: {
                            borderRadius: "10px",
                            fontFamily: "Helvetica, sans-serif",
                            fontSize: "0.9rem",
                            width: "100%",
                            resize: "none",
                            paddingTop: "1rem",
                            paddingLeft: "1rem",
                          },
                          sx: {
                            fontSize: "16px",
                            "&:-internal-autofill-selected": {
                              backgroundColor: "transparent !important",
                            },
                            "@media (max-width:600px)": {
                              fontSize: "14px !important",
                            },
                          },
                        }}
                        name="note"
                        id="note"
                        value={formik.values.note}
                        onChange={formik.handleChange}
                        aria-label="maximum height"
                        placeholder="Write a note..."
                        style={{
                          fontFamily: "Helvetica, sans-serif",
                          fontSize: "0.9rem",
                          width: "100%",
                          resize: "none",
                          paddingTop: "1rem",
                          borderRadius: "10px",
                        }}
                      />
                    </div>
                    <div className="label-and-field mb-1rem">
                      <Typography
                        className="ta-start registration1375RemFontSize"
                        component="h1"
                        variant="h5"
                        sx={{
                          fontFamily: "Helvetica, sans-serif",
                          fontWeight: "500",
                          color: "#0e0f0c",
                        }}
                      >
                        Payment method
                      </Typography>
                    </div>
                    <div className="label-and-field mb-1rem">
                      <Typography
                        className="d-flex jc-start registration0875RemFontSize"
                        component="h1"
                        variant="h5"
                        sx={{
                          fontFamily: "Helvetica, sans-serif",
                          fontWeight: "300",
                          color: "#454745",
                          marginBottom: "8px",
                        }}
                      >
                        Type
                      </Typography>

                      <Select
                        id="type"
                        name="type"
                        placeholder="Select type"
                        value={
                          (
                            formik.values.bank_details[0] as
                              | { type: string }
                              | undefined
                          )?.type || ""
                        }
                        fullWidth
                        onChange={handleTypeChange}
                        sx={{
                          borderRadius: "10px",
                          height: "48px",
                          fontSize: "16px",
                          "&:-internal-autofill-selected": {
                            backgroundColor: "transparent !important",
                          },
                          "@media (max-width:600px)": {
                            fontSize: "14px !important",
                          },
                        }}
                      >
                        <MenuItem value={"Local"}>Local</MenuItem>
                        <MenuItem value={"International"}>
                          International
                        </MenuItem>
                      </Select>
                      {formik.touched.bank_details &&
                        Array.isArray(formik.errors.bank_details) &&
                        formik.errors.bank_details.length > 0 && (
                          <FormControl error={true}>
                            <FormHelperText>
                              {formik.errors.bank_details.map(
                                (
                                  error: string | FormikErrors<any>,
                                  index: number
                                ) => (
                                  <div key={index}>
                                    {typeof error === "string"
                                      ? error
                                      : Object.values(error).map(
                                          (errorMessage, i) => (
                                            <div key={i}>
                                              {String(errorMessage)}
                                            </div>
                                          )
                                        )}
                                  </div>
                                )
                              )}
                            </FormHelperText>
                          </FormControl>
                        )}
                    </div>
                  </Box>

                  <Button
                    fullWidth
                    variant="contained"
                    type="submit"
                    className="btnsLoginHeight oneRemFontSize"
                    sx={{
                      color: "white",
                      backgroundColor: "#2A5182",
                      borderRadius: "24px",
                      textTransform: "none",
                      marginTop: "2rem",
                      fontFamily: "Helvetica, sans-serif",
                      marginBottom: "4rem",
                    }}
                  >
                    Save
                  </Button>
                </Box>
              </Container>
            </ThemeProvider>
          </div>
        </div>
      }
    </>
  );
}
