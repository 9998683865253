import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import "./Home.scss";
import { useEffect, useState } from "react";
import Nav from "src/components/layout/nav/Nav";
import { Box, Grid, Typography } from "@mui/material";
import { useAppSelector, useAppDispatch } from "src/store";
import BalanceCard from "src/components/shared/BalanceCard/BalanceCard";
import api from "src/store/interceptors/api";
import Tabs, { tabsClasses } from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import AccountBox from "src/components/shared/AccountBox/AccountBox";
import AddCurrencyModal from "src/components/shared/AddCurrencyModal/AddCurrencyModal";
import ChartBox from "src/components/shared/ChartBox/ChartBox";
import CurrencyCard from "src/components/shared/CurrencyBox/CurrencyBox";
import { logout } from "src/store/login/login_slice";
import { useNavigate } from "react-router-dom";
import Terms from "src/components/TermsAndCondition/Terms";
import { updateUserProfile } from "src/store/profile/profile_slice";
import ChartBar from "src/components/shared/ChartBar/ChartBar";
import { barOptions } from "src/utils/helpers";
import SlideAnimation from "../../components/shared/SlideAnimation/SlideAnimation";
import VideoParlax from "src/components/shared/VideoParallax/VideoParallax";
import CreatePINModal from "src/components/shared/InactivityTimeout/PINModal/CreatePINModal";
import { toast } from "react-toastify";
import { getUser, storeUser } from "src/store/persist/persist";
import i18next from "src/i18n";

const defaultTheme = createTheme();
const defaultCurrencies = ["EUR"];

const prepareGraphBalanceData = (data: any) => {
  let result: any = {};

  if (data.expenses && data.expenses.data) {
    data.expenses.data.forEach((item: any) => {
      if (item.name && item.amount && item.amount > 0) {
        if (!result[item.name]) result[item.name] = {};
        if (!result[item.name].expense) result[item.name].expense = 0;
        result[item.name].expense = item.amount;
      }
    });
  }

  if (data.income && data.income.data) {
    data.income.data.forEach((item: any) => {
      if (item.name && item.amount && item.amount > 0) {
        if (!result[item.name]) result[item.name] = {};
        if (!result[item.name].income) result[item.name].income = 0;
        result[item.name].income = item.amount;
      }
    });
  }

  if (data.pending && data.pending.data) {
    data.pending.data.forEach((item: any) => {
      if (item.name && item.amount && item.amount > 0) {
        if (!result[item.name]) result[item.name] = {};
        if (!result[item.name].pending) result[item.name].pending = 0;
        result[item.name].pending = item.amount;
      }
    });
  }

  if (result) {
    const response: any = [];
    Object.entries(result).forEach(([key, item]: [string, any]) => {
      let income = item.income ?? 0;
      let expense = item.expense ?? 0;
      let pending = item.pending ?? 0;
      let sum = parseFloat(income) + parseFloat(expense) + parseFloat(pending);

      response.push({
        name: key,
        income: income,
        expense: expense,
        pending: pending,
        amt: sum,
      });
    });

    return response;
  }

  return null;
};

const barOptionsForBalance = barOptions("balance");
export default function Home() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { user } = useAppSelector((state) => state.auth);
  const [openAgreement, setOpenAgreement] = useState<boolean>(false);
  const [openCreatePIN, setOpenCreatePIN] = useState<boolean>(false);
  const [balance, setBalance] = useState<any>(null);
  const [chartData, setChartData] = useState<any>(null);
  const [chartData2, setChartData2] = useState<any>(null);

  const getChartData = async () => {
    await api
      .get("/balance-statistics")
      .then((response: any) => {
        if (response) {
          if (response && response.data) {
            const data = response.data;
            setChartData(data);
            const graph = prepareGraphBalanceData(data);
            setChartData2(graph);
          }
        }
      })
      .catch((error) => {
        console.error(error.message);
      });
  };

  const registerPin = async (pin: string) => {
    try {
      await api.post(
        "/auth/register-pin",
        {
          pin: pin,
          pin_confirm: pin,
        },
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      let user = getUser();
      if (user) {
        if (!user.login_with_pin) {
          user.login_with_pin = true;
          storeUser("victorumuser", JSON.stringify(user));
        }
      }

      navigate("/home");
      toast.success(i18next.t("PIN code is created!"));
      setOpenCreatePIN(false);
    } catch (error) {
      toast.error(i18next.t("Something went wrong"));
    }
  };

  const getMe = async () => {
    try {
      await api
        .get("/me")
        .then((response: any) => {
          if (response) {
            if (response && response.data && response.data.data) {
              if (!response.data.data.login_with_pin) {
                // navigate("/pin-setup");
                setOpenCreatePIN(true);
              } else {
                if (response.data.data.terms_accepted === 0) {
                  setOpenAgreement(true);
                }
              }
            }

            if (
              response &&
              response.data &&
              response.data.data &&
              response.data.data.role === "Employee"
            ) {
              navigate("/admin");
            }
          }
        })
        .catch((error) => {
          dispatch(logout());
          navigate("/login");
        });
    } catch (error) {
      console.log(error);
    }
  };

  const getInitialData = async () => {
    try {
      await api
        .get("/user-balances")
        .then((response: any) => {
          if (response && response.data && response.data.data) {
            const resData = response.data.data;
            const balanceCurrencies = response.data.data
              ? response.data.data.map((item: any) => item.currency.code)
              : [];

            const missingCurrencies = defaultCurrencies.filter(
              (code) => !balanceCurrencies.includes(code)
            );

            missingCurrencies.forEach((item) => {
              resData.push({
                id: 0,
                balance: "0.00",
                user_id: user && user.id ? user.id : 0,
                currency: {
                  id: 0,
                  code: item,
                  name: item,
                  symbol: null,
                  status: 1,
                  exchange_rate: null,
                  decimal_places: null,
                  created_at: "2024-01-09T08:49:39.000000Z",
                  updated_at: "2024-01-09T08:49:39.000000Z",
                },
              });
            });

            setBalance(resData);
          }
        })
        .catch((error) => {
          console.error(error.message);
        });
    } catch (exception) {
      console.log(exception);
    }
  };

  const handleUpdateCurrency = () => {
    getInitialData();
  };

  const handleTermsAccepted = (termsChecked: any) => {
    if (termsChecked) {
      if (user && user.id) {
        let valuesForBe: any = {};
        valuesForBe.terms_accepted = true;
        valuesForBe.id = user.id ?? null;
        dispatch(updateUserProfile(valuesForBe));
        setTimeout(() => {
          setOpenAgreement(false);
        }, 1500);
      }
    }
  };

  const getUserDisplayName = (user: any) => {
    if (!user) return "";

    if (user.account_type === "business" && user.company_name) {
      return user.company_name;
    }

    if (user.first_name && user.last_name) {
      return `${user.first_name} ${user.last_name}`;
    }

    return "";
  };

  useEffect(() => {
    if (user && user.id) {
      getInitialData();
      getMe();
      getChartData();
    } else {
      dispatch(logout());
      navigate("/login");
    }
  }, []);

  return (
    <div>
      <Nav step={0} isStepperVisible={false} isProfileSetVisible={true} />
      <div className="main-admin-container" style={{ marginTop: "-4rem" }}>
        <div className="content-container-home main-color mainContentContainerHome">
          <ThemeProvider theme={defaultTheme}>
            <Container component="main" className="mainContentContainerHome">
              <Box className="overlayHomePageBG">
                <VideoParlax />
                <Box
                  className="contentHomePageMaxWidth"
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <Box className="WelcomeAndBalanceMain">
                    <Box className="WelcomeAndyourBalanceTexts">
                      <Typography
                        className="mb-1rem welcome-text d-flex jc-start"
                        component="h1"
                        variant="h5"
                        sx={{
                          fontFamily: "Mabry Pro",
                          fontSize: "2.2rem",
                          textAlign: "center",
                          paddingTop: "2.0rem",
                          display: "flex",
                          justifyContent: "flex-start",
                        }}
                      >
                        {i18next.t("Welcome")} {getUserDisplayName(user)}
                      </Typography>
                      <Terms
                        hideButton={true}
                        onChecked={handleTermsAccepted}
                        type="customer-agreement-personal"
                        open={openAgreement}
                      />
                    </Box>
                  </Box>

                  {user && user.role && user.role !== "Employee" && (
                    <Grid container spacing={2} sx={{ paddingTop: 5 }}>
                      {/* Left Side */}
                      {/* <Grid item xs={12} md={4}>
                        <Box>{user && <BalanceCard user={user} />}</Box>
                      </Grid> */}
                      <Grid item xs={12} md={12}>
                        {balance && balance.length > 0 ? (
                          <Box>
                            <Tabs
                              value={0}
                              TabIndicatorProps={{
                                style: { display: "none" },
                              }}
                              variant="scrollable"
                              scrollButtons
                              aria-label="visible arrows tabs example"
                              sx={{
                                padding: 0,
                                [`& .${tabsClasses.scrollButtons}`]: {
                                  "&.Mui-disabled": {
                                    opacity: 0.3,
                                    backgroundColor: "#eaeaea",
                                    color: "#9e9e9e",
                                  },
                                  backgroundColor: "#2A5182",
                                  opacity: 1,
                                  color: "#fff",
                                },
                              }}
                              TabScrollButtonProps={{
                                style: {
                                  width: "32px",
                                  height: "32px",
                                  borderRadius: "50%",
                                  margin: "auto 10px auto 10px",
                                },
                              }}
                            >
                              {balance.map(
                                (balanceItem: any, balanceKey: any) => (
                                  <Tab
                                    sx={{
                                      padding: 0.75,
                                    }}
                                    key={"balance" + balanceKey}
                                    disableRipple={true}
                                    label={
                                      <AccountBox
                                        bgColorSpecific="#fff"
                                        currencyIcon={
                                          balanceItem?.currency?.code
                                        }
                                        title={
                                          balanceItem?.currency?.code ?? ""
                                        }
                                        content={balanceItem?.balance ?? "0,00"}
                                        balanceItem={balanceItem}
                                        pendingBalance={
                                          balanceItem?.pending ?? 0
                                        }
                                        user={user}
                                        screen={"home"}
                                        navigate={navigate}
                                      />
                                    }
                                  />
                                )
                              )}
                            </Tabs>
                          </Box>
                        ) : (
                          <Typography
                            component="h5"
                            variant="h5"
                            className="welcome-text d-flex jc-start"
                            sx={{
                              fontFamily: "Inter, sans-serif",
                              fontSize: "1.2rem",
                              textAlign: "left",
                            }}
                          >
                            {i18next.t(
                              "Please ensure that your account is funded to enjoy our services. If you have any questions or concerns, feel free to contact our support team for assistance."
                            )}
                          </Typography>
                        )}
                        <Box
                          sx={{
                            textAlign: "right",
                            marginTop: 2,
                            marginBottom: 2,
                          }}
                        >
                          <AddCurrencyModal
                            userBalance={balance}
                            updateList={handleUpdateCurrency}
                          />
                        </Box>
                      </Grid>
                    </Grid>
                  )}
                </Box>

                {user && user.role && user.role !== "Employee" && (
                  <div className="charts-contianer contentHomePageMaxWidth">
                    <ChartBox
                      title={i18next.t("Income") ?? ""}
                      data={
                        chartData && chartData.income ? chartData.income : null
                      }
                      content={
                        chartData?.income?.percentage
                          ? `${
                              chartData?.income?.percentage
                                ? chartData.income.percentage
                                : i18next.t("than last month")
                            }`
                          : i18next.t("Last 30 days")
                      }
                      // bgColor="rgba(91, 228, 155, 0.2)"
                      bgColor="#f1f9ae"
                      color="rgb(0, 55, 59)"
                      // chartData={chartData}
                      chartData={chartData?.income?.data}
                      iconName="up"
                      cost={chartData?.income?.amount}
                      currency={"EUR"}
                    />
                    <ChartBox
                      title={i18next.t("Expenses") ?? ""}
                      data={
                        chartData && chartData.expenses
                          ? chartData.expenses
                          : null
                      }
                      content={
                        chartData?.expenses?.percentage
                          ? `${
                              chartData?.expenses?.percentage
                                ? chartData.expenses.percentage
                                : i18next.t("than last month")
                            }`
                          : i18next.t("Last 30 days")
                      }
                      // bgColor="rgba(255, 214, 102, 0.2)"
                      bgColor="#f3d389"
                      color="rgb(85, 45, 0)"
                      // chartData={chartData}
                      chartData={chartData?.expenses?.data}
                      iconName="down"
                      cost={chartData?.expenses?.amount}
                      currency={"EUR"}
                    />
                    <Box
                      sx={{
                        marginBottom: "0",
                        "@media (max-width: 600px)": {
                          marginBottom: "4rem",
                        },
                      }}
                    >
                      <ChartBox
                        title={i18next.t("Pending transactions") ?? ""}
                        data={
                          chartData && chartData.pending
                            ? chartData.pending
                            : null
                        }
                        content={
                          chartData?.pending?.percentage
                            ? `${
                                chartData?.pending?.percentage
                                  ? chartData.pending.percentage
                                  : i18next.t("than last month")
                              }`
                            : i18next.t("Last 30 days")
                        }
                        bgColor="rgba(217, 232, 252)"
                        color="rgb(0, 45, 79)"
                        // chartData={chartData}
                        chartData={chartData?.pending?.data}
                        iconName="money"
                        cost={chartData?.pending?.amount}
                        currency={"EUR"}
                      />
                    </Box>
                  </div>
                )}
                {/* chart end */}
              </Box>
              <Box className="polygonMainBox">
                <Box className="leftCssPolygon"></Box>
                <Box className="rightCssPolygon"></Box>
                <Box className="rightCssPolygon2"></Box>
                <Box className="rightCssPolygon3"></Box>
              </Box>

              {user && user.role && user.role !== "Employee" && chartData2 && (
                <div className="bar-year-container contentHomePageMaxWidth">
                  <SlideAnimation threshold={300}>
                    <ChartBar
                      data={chartData2}
                      keyBars={barOptionsForBalance}
                      title={i18next.t("Balance Statistics")}
                      rightTitle={i18next.t("30 days")}
                    />
                  </SlideAnimation>
                </div>
              )}

              <Box className="contentHomePageMaxWidth">
                <SlideAnimation threshold={600}>
                  <CurrencyCard />
                </SlideAnimation>
              </Box>

              <SlideAnimation threshold={900}>
                <Box className="box-wrap-info mt-2rem ta-start contentHomePageMaxWidth bottomTexts">
                  <Typography className="mb-0-5rem homeSmallText">
                    {i18next.t(
                      "Legal Entity Identifiers (LEI) are required for all companies that regularly conduct financial transactions, including the purchase of stocks, bonds and other securities.In general, financial institutions such as banks, investment and insurance companies, credit unions and brokerage firms must have an LEI code. For example, investment firms and their clients in the EU must have an LEI code. The EU authorities are required to refuse transactions between investment firms and clients unless both parties have an LEI code. Victorum Capital Inc. licensed: LEI: 984500DEU7B9A3CD6A07 ISIN: CA92642D1024"
                    )}
                  </Typography>
                  <Box className="bottomLastTwoText">
                    <Typography className="mb-0-5rem homeSmallText">
                      {i18next.t(
                        "Victorum Capital Services Limited  partnered with Triple A Licensed as a Major Payment Institution (MPI) by MAS, the Monetary Authority of Singapore. License number: PS20200525."
                      )}
                    </Typography>
                    
                  </Box>
                </Box>
              </SlideAnimation>
            </Container>
          </ThemeProvider>
        </div>
      </div>
      <CreatePINModal onClose={registerPin} showModal={openCreatePIN} />
    </div>
  );
}
