import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import "./AccountDetails.scss";
import { useState, useEffect } from "react";
import Tabs, { tabsClasses } from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Nav from "src/components/layout/nav/Nav";
import { Box, Button, Typography } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate, useParams } from "react-router-dom";
import api from "src/store/interceptors/api";
import AccountBox from "src/components/shared/AccountBox/AccountBox";
import { formatAccountNumber } from "src/utils/helpers";
import LoadingIndicator from "src/components/shared/LoadingIndicator";
import i18next from "i18next";

const defaultTheme = createTheme();
const AccountDetails = () => {
  const { balanceId }: any = useParams();
  const [user, setUser] = useState<any>(null);
  const [loading, setLoading] = useState(false);
  const [balanceItem, setBalanceItem] = useState<any>(null);

  const navigate = useNavigate();

  const getMe = async () => {
    setLoading(true);
    try {
      await api
        .get("/me")
        .then((response: any) => {
          if (response) {
            if (response && response.data && response.data.data) {
              setUser(response.data.data);
            }
          }
        })
        .catch((error) => {});
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  const handleNavigate = () => {
    navigate("/home");
  };

  const handleRedirect = (id: any) => {
    navigate("/account-details/" + id);
  };

  const handleRedirectToUser = () => {
    if (user && (user.role === "Admin" || user.role === "Employee")) {
      navigate("/admin/users/" + user.id + "/account-requests");
    }
  };

  useEffect(() => {
    getMe();
  }, []);

  return (
    <Box>
      <Nav step={0} isStepperVisible={false} isProfileSetVisible={true} />
      <ThemeProvider theme={defaultTheme}>
        <Container component="main">
          {loading ? (
            <Box className="settingsMain">
              <LoadingIndicator />
            </Box>
          ) : (
            <Box className="settingsMain" sx={{ paddingBottom: 30 }}>
              {user && (
                <Box sx={{ flex: 1 }}>
                  <Typography
                    className="mb-1rem  d-flex jc-start FontSize1275"
                    component="h1"
                    variant="h5"
                    onClick={() => handleRedirectToUser()}
                    sx={{
                      justifyContent: "flex-end",
                      fontFamily: "Helvetica, sans-serif",
                      cursor: "pointer",
                      fontWeight: 600,
                      color: "#000000",
                    }}
                  >
                    {i18next.t("Account number")}:{" "}
                    {user && user.id ? formatAccountNumber(user.id) : ""}
                  </Typography>
                </Box>
              )}
              <div className="account-details-header">
                <div className="d-flex ai-center mb-3-rem">
                  <p
                    className="back-button-icon circle-around-text-smaller helvetica mr-1-rem cp"
                    onClick={handleNavigate}
                  >
                    <ArrowBackIcon className="muiIcons" />
                  </p>

                  <Typography
                    className="your-details-text d-flex jc-start pageTitleFontSize"
                    component="h1"
                    variant="h5"
                    sx={{
                      fontFamily: "Helvetica, sans-serif",
                      fontWeight: "600",
                      textAlign: "start",
                    }}
                  >
                    {i18next.t("Account details")}
                  </Typography>
                </div>
                <Button
                  onClick={() => {
                    navigate("/banking");
                  }}
                  className="walletBtn"
                >
                  {i18next.t("Onboarding")}
                </Button>
              </div>

              <Box>
                {user && user.balances && user.balances.length > 0 ? (
                  <Box style={{ marginTop: 20 }}>
                    <Tabs
                      value={0}
                      TabIndicatorProps={{
                        style: { display: "none" },
                      }}
                      variant="scrollable"
                      scrollButtons
                      aria-label="visible arrows tabs example"
                      sx={{
                        padding: 0,
                        [`& .${tabsClasses.scrollButtons}`]: {
                          "&.Mui-disabled": {
                            opacity: 0.3,
                            backgroundColor: "#eaeaea",
                            color: "#9e9e9e",
                          },
                          backgroundColor: "#2A5182",
                          opacity: 1,
                          color: "#fff",
                        },
                      }}
                      TabScrollButtonProps={{
                        style: {
                          width: "32px",
                          height: "32px",
                          borderRadius: "50%",
                          margin: "auto 10px auto 10px",
                        },
                      }}
                    >
                      {user &&
                        user.balances &&
                        user.balances.map(
                          (balanceItem: any, balanceKey: any) => (
                            <Tab
                              sx={{
                                padding: 0.75,
                              }}
                              key={"balance" + balanceKey}
                              disableRipple={true}
                              label={
                                <AccountBox
                                  user={user}
                                  currencyIcon={balanceItem?.currency?.code}
                                  title={balanceItem?.currency?.code ?? ""}
                                  content={balanceItem?.balance ?? "0,00"}
                                  pendingBalance={balanceItem?.pending ?? 0}
                                  onClick={() => handleRedirect(balanceItem.id)}
                                />
                              }
                            />
                          )
                        )}
                    </Tabs>
                  </Box>
                ) : (
                  <Box style={{ marginTop: 20 }}>
                    <Typography
                      component="h5"
                      variant="h5"
                      className="welcome-text d-flex jc-start FontSize12"
                      sx={{
                        fontFamily: "Helvetica, sans-serif",
                        textAlign: "left",
                        color: "#000",
                      }}
                    >
                      {i18next.t("No balance")}
                    </Typography>
                  </Box>
                )}
              </Box>
            </Box>
          )}
        </Container>
      </ThemeProvider>
    </Box>
  );
};

export default AccountDetails;
