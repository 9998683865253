import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import "./Settings.scss";
import { useState, useEffect } from "react";
import Nav from "src/components/layout/nav/Nav";
import { Box, Typography } from "@mui/material";
import Button from "@mui/material/Button";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate } from "react-router-dom";
import WalletConnect from "src/components/shared/WalletConnect";
import Terms from "src/components/TermsAndCondition/Terms";
import { toast } from "react-toastify";
import api from "src/store/interceptors/api";
import CachedIcon from "@mui/icons-material/Cached";
import AreYouSureModal from "src/components/shared/AreYouSureModal/AreYouSureModal";
import ChangePassword from "src/components/ChangePassword/ChangePassword";
import LoadingIndicator from "src/components/shared/LoadingIndicator";
import i18next from "src/i18n";
import LanguageSelector from "src/components/shared/LanguageSelector/LanguageSelector";

const defaultTheme = createTheme();
const getAccountType = (type: string) => {
  if (type === "business") return "Business";
  return "Personal";
};
const getNewAccountType = (type: string) => {
  if (type === "business") return "Personal";
  return "Business";
};

const Settings = () => {
  const [user, setUser] = useState<any>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isAccountSwitchModalOpen, setAccountSwitchModalOpen] = useState(false);

  const handleAccountSwitchModal = () => {
    setAccountSwitchModalOpen(true);
  };

  const handleAccountSwitch = async (type: string) => {
    try {
      await api.post(`/account-switch/${type.toLowerCase()}`);
      setAccountSwitchModalOpen(false);
      toast.success(
        i18next.t("Account type changed to") + " " + i18next.t(type)
      );
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    } catch (error: any) {
      toast.error(
        i18next.t(
          "Error switching your account type, please contact VicPay support!"
        )
      );
    }
  };

  const navigate = useNavigate();

  const getMe = async () => {
    setIsLoading(true);
    try {
      await api
        .get("/me")
        .then((response: any) => {
          if (response) {
            if (response && response.data && response.data.data) {
              setUser(response.data.data);
            }
          }
        })
        .catch((error) => {});
    } catch (error) {
      console.log(error);
    }
    setIsLoading(false);
  };
  const handleNavigate = () => {
    navigate("/home");
  };

  useEffect(() => {
    getMe();
  }, []);
  return (
    <Box>
      <Nav step={0} isStepperVisible={false} isProfileSetVisible={true} />
      <ThemeProvider theme={defaultTheme}>
        <Container component="main">
          <Box className="settingsMain" sx={{ paddingBottom: 30 }}>
            {isLoading ? (
              <LoadingIndicator />
            ) : (
              <>
                <div className="d-flex ai-center mb-3-rem">
                  <p
                    className="back-button-icon circle-around-text-smaller helvetica mr-1-rem cp"
                    onClick={handleNavigate}
                  >
                    <ArrowBackIcon className="muiIcons" />
                  </p>

                  <Typography
                    className="your-details-text d-flex jc-start pageTitleFontSize"
                    component="h1"
                    variant="h5"
                    sx={{
                      fontFamily: "Helvetica, sans-serif",
                      fontWeight: "600",
                      textAlign: "start",
                    }}
                  >
                    {i18next.t("Settings")}
                  </Typography>
                </div>
                <Box sx={{ mt: 2 }}>
                  {user &&
                    user.role &&
                    user.role !== "Employee" &&
                    user.account_type && (
                      <Box className="personal-box changePassConnectWallet">
                        <Box className="changeYourAccType">
                          <Typography className="changeYourPasswordText pageTextFontSize">
                            {i18next.t("Change your account type")}
                          </Typography>
                          <Box>
                            <Typography
                              className="mb-1rem  d-flex jc-start registration0875RemFontSize"
                              component="h1"
                              variant="h5"
                              sx={{
                                fontFamily: "Helvetica, sans-serif",
                                fontWeight: ".875rem",
                                color: "#454745",
                              }}
                            >
                              {i18next.t("Account type")}:{" "}
                              {user &&
                                user.account_type &&
                                i18next.t(getAccountType(user.account_type))}
                            </Typography>
                            <Button
                              className="walletBtn"
                              onClick={handleAccountSwitchModal}
                              startIcon={<CachedIcon />}
                            >
                              {i18next.t("Switch to")}{" "}
                              {user &&
                                user.account_type &&
                                getNewAccountType(user.account_type)}
                            </Button>
                          </Box>
                        </Box>
                        <Box className="connectYourWallet">
                          <Typography className="changeYourPasswordText pageTextFontSize">
                            {i18next.t("Connect your wallet")}
                          </Typography>
                          <Box>
                            <div className="d-flex jusifyContentSpaceBetween ai-center ">
                              <WalletConnect user={user} />
                            </div>
                          </Box>
                        </Box>
                      </Box>
                    )}
                </Box>
                <Box sx={{ mt: 2 }}>
                  <Box className="personal-box changePassConnectWallet">
                    <Box className="readBox">
                      <Typography className="changeYourPasswordText ">
                        {i18next.t("Read Terms and conditions")}
                      </Typography>
                      <Terms
                        settingsButton={true}
                        margin={0}
                        hideCheckbox={true}
                        buttonSettings={true}
                        onChecked={() => console.log("Checked")}
                        type="customer-agreement-personal"
                      />
                    </Box>
                    <Box className="connectYourWallet">
                      <Typography className="changeYourPasswordText pageTextFontSize">
                        {i18next.t("Change language")}
                      </Typography>
                      <Box>
                        <div className="d-flex jusifyContentSpaceBetween ai-center ">
                          <LanguageSelector />
                        </div>
                      </Box>
                    </Box>
                  </Box>
                </Box>

                <ChangePassword />
              </>
            )}
          </Box>
          {user && user.account_type && (
            <AreYouSureModal
              open={isAccountSwitchModalOpen}
              handleClose={() => setAccountSwitchModalOpen(false)}
              handleYes={() =>
                handleAccountSwitch(getNewAccountType(user.account_type))
              }
              title={
                i18next.t("Are you sure you want to switch your profile to") +
                " " +
                getNewAccountType(user.account_type) +
                " " +
                i18next.t("type") +
                "?" +
                (getNewAccountType(user.account_type) === "Business"
                  ? " " +
                    i18next.t("Note that your profile will become unverified.")
                  : "")
              }
            />
          )}
        </Container>
      </ThemeProvider>
    </Box>
  );
};

export default Settings;
