import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Select,
  MenuItem,
  Grid,
  FormControl,
  InputLabel,
} from "@mui/material";
import CAD from "../../../assets/images/cadIcon.svg";
import USD from "../../../assets/images/usdIcon.svg";
import AUD from "../../../assets/images/audIcon.svg";
import GBP from "../../../assets/images/gbpIcon.svg";
import NGN from "../../../assets/images/ngnIcon.svg";
import EUR from "../../../assets/images/eurIcon.svg";
import TRY from "../../../assets/images/tryIcon.svg";
import RUB from "../../../assets/images/rubIcon.svg";
import INR from "../../../assets/images/inrIcon.svg";
import CNY from "../../../assets/images/cnyIcon.svg";
import i18next from "src/i18n";
import ArrowCircleDownOutlinedIcon from "@mui/icons-material/ArrowCircleDownOutlined";
import ArrowCircleUpOutlinedIcon from "@mui/icons-material/ArrowCircleUpOutlined";
import "./CurrencyBox.scss";

// Props type for CurrencyCard component
interface CurrencyCardProps {
  filter?: any;
}

const getCurrencyFlag = (currencyCode: any) => {
  if (currencyCode === "TRY") {
    return (
      <img style={{ width: "100%", maxWidth: 25 }} src={TRY} alt="TRY Flag" />
    );
  } else if (currencyCode === "EUR") {
    return (
      <img style={{ width: "100%", maxWidth: 25 }} src={EUR} alt="EUR Flag" />
    );
  } else if (currencyCode === "USD") {
    return (
      <img style={{ width: "100%", maxWidth: 25 }} src={USD} alt="USD Flag" />
    );
  } else if (currencyCode === "NGN") {
    return (
      <img style={{ width: "100%", maxWidth: 25 }} src={NGN} alt="NGN Flag" />
    );
  } else if (currencyCode === "AUD") {
    return (
      <img style={{ width: "100%", maxWidth: 25 }} src={AUD} alt="AUD Flag" />
    );
  } else if (currencyCode === "GBP") {
    return (
      <img style={{ width: "100%", maxWidth: 25 }} src={GBP} alt="GBP Flag" />
    );
  } else if (currencyCode === "CAD") {
    return (
      <img style={{ width: "100%", maxWidth: 25 }} src={CAD} alt="CAD Flag" />
    );
  } else if (currencyCode === "RUB") {
    return (
      <img
        src={RUB}
        alt="RUB Flag"
        style={{ width: "3rem", maxWidth: 25, height: "3rem" }}
      />
    );
  } else if (currencyCode === "INR") {
    return (
      <img
        src={INR}
        alt="INR Flag"
        style={{ width: "3.3rem", height: "3.3rem", maxWidth: 25 }}
      />
    );
  } else if (currencyCode === "CNY") {
    return (
      <img src={CNY} alt="CNY Flag" style={{ width: "3rem", height: "3rem" }} />
    );
  } else {
    return "";
  }
};

const currencyList = ["EUR", "USD", "GBP", "CAD", "TRY"];
const CurrencyCard: React.FC<CurrencyCardProps> = ({ filter }) => {
  const [baseList, setBaseList] = useState<any>([
    "USD",
    "GBP",
    "CAD",
    "NGN",
    "TRY",
    "EUR",
  ]);
  const [base, setBase] = useState("EUR");

  const [firstTable, setFirstTable] = useState<any>(null);

  const handleBaseChange = (event: any) => {
    const newBase = event.target.value as string;
    const newBaseList = currencyList.filter(
      (currency: any) => currency !== newBase
    );

    setBase(newBase);
    setBaseList([...newBaseList]);
  };

  const fetchData = async () => {
    const fromDate = new Date();
    fromDate.setDate(fromDate.getDate() - 3);
    const toDate = new Date();

    const formattedFromDate = fromDate.toISOString().split("T")[0];
    const formattedToDate = toDate.toISOString().split("T")[0];

    const strList = baseList.join(",");
    const apiUrl = `https://api.frankfurter.app/${formattedFromDate}..${formattedToDate}?from=${base}&to=${strList}`;

    try {
      const cachedDataString = localStorage.getItem(
        "currencych" + strList.replace(",", "")
      );

      let blnCache = false;
      let data = null;
      if (cachedDataString) {
        const cachedData = JSON.parse(cachedDataString);
        const currentTime = new Date().getTime();
        const cacheTimestamp = cachedData.timestamp;

        // If the cached data is less than 5 hours old, use it
        if (currentTime - cacheTimestamp < 5 * 60 * 60 * 1000) {
          if (cachedData.data) {
            data = cachedData.data;
            blnCache = true;
          }
        }
      }

      if (!data) {
        const response = await fetch(apiUrl);
        data = await response.json();
      }
      if (data && data.rates) {
        const jsonData = data;
        const resultArray: any = [];
        const currentDate = jsonData.end_date;
        const previousDate = jsonData.start_date;
        const currencies = Object.keys(jsonData.rates[currentDate]);
        currencies.forEach((currency) => {
          const currentRate = jsonData.rates[currentDate][currency];
          const previousDayRate = jsonData.rates[previousDate][currency];
          let percentageDifference = 0;
          if (
            currentRate !== undefined &&
            previousDayRate !== undefined &&
            previousDayRate > 0
          )
            percentageDifference =
              ((currentRate - previousDayRate) / previousDayRate) * 100;

          resultArray.push({
            currency: currency,
            current_rate: currentRate,
            previous_rate: previousDayRate,
            percentage: percentageDifference,
          });
        });

        if (resultArray) {
          resultArray.sort((a: any, b: any) => {
            return (
              currencyList.indexOf(a.currency) -
              currencyList.indexOf(b.currency)
            );
          });
          if (resultArray && resultArray.length > 0) {
            setFirstTable(resultArray);
          }

          if (!blnCache) {
            const cacheData = {
              data: data,
              timestamp: new Date().getTime(),
            };
            localStorage.setItem(
              "currencych" + strList.replace(",", ""),
              JSON.stringify(cacheData)
            );
          }
        }
      }
    } catch (error) {
      console.error("Error fetching currency data:", error);
    }
  };
  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [base, baseList]);

  const renderBox = (rates: any) => (
    <Box
      sx={{
        padding: "1rem",
        border: "1px solid #ddd",
        width: "100%",
        borderRadius: "8px",
        marginBottom: "1rem",
      }}
    >
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: "repeat(4, 1fr)",
          borderBottom: "1px solid #ddd",
          paddingBottom: "0.5rem",
          marginBottom: "1rem",
          color: "#2a5182;",
        }}
      >
        <Typography
          variant="subtitle1"
          sx={{ fontWeight: "bold", fontFamily: "Inter, sans-serif" }}
        >
          {i18next.t("CURRENCY")}
        </Typography>
        <Typography
          variant="subtitle1"
          sx={{ fontWeight: "bold", fontFamily: "Inter, sans-serif" }}
        >
          {i18next.t("LAST")}
        </Typography>
        <Typography
          variant="subtitle1"
          sx={{ fontWeight: "bold", fontFamily: "Inter, sans-serif" }}
        >
          {i18next.t("CHG")}
        </Typography>
        <Typography
          variant="subtitle1"
          sx={{ fontWeight: "bold", fontFamily: "Inter, sans-serif" }}
        >
          {i18next.t("CHG")} %
        </Typography>
      </Box>
      {rates &&
        rates.map((item: any, itemKey: number) => {
          return (
            <Box
              key={item.currency + "itemKey" + itemKey}
              className="currencyExchangeRatesItemList"
            >
              <Box
                sx={{ display: "flex", gap: 1, alignItems: "center" }}
                justifyContent={"left"}
                alignItems={"left"}
              >
                {getCurrencyFlag(item.currency)}{" "}
                <Typography
                  variant="body1"
                  className="currencyExchangeRatesItemFFamily"
                >
                  {item.currency}
                </Typography>
              </Box>
              <Typography
                variant="body1"
                className="currencyExchangeRatesItemFFamily"
              >
                {item.current_rate.toFixed(5)}
              </Typography>
              <Typography
                variant="body1"
                className="currencyExchangeRatesItemFFamily"
              >
                {item.previous_rate.toFixed(5)}
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  color: item.percentage < 0 ? "red" : "green",
                  display: "flex",
                  gap: "5px",
                  alignItems: "center",
                }}
                className="currencyExchangeRatesItemFFamily"
              >
                {item.percentage < 0 ? (
                  <ArrowCircleDownOutlinedIcon
                    sx={{ color: "red", width: "22px" }}
                  />
                ) : (
                  <ArrowCircleUpOutlinedIcon
                    sx={{ color: "green", width: "22px" }}
                  />
                )}{" "}
                {item.percentage.toFixed(2)}%
              </Typography>
            </Box>
          );
        })}
    </Box>
  );

  return (
    <Box>
      <Box sx={{ width: "100%", paddingTop: "50px", paddingBottom: 1 }}>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={6}>
            <Typography variant="subtitle1" className="oneRemFontSize">
              {i18next.t("Currency Exchange rates")}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Box className="customInputContainer">
              <Box className="customInputSubContainer">
                <FormControl fullWidth>
                  <InputLabel
                    sx={{
                      color: "#0e0e0e",
                      "&.Mui-focused": {
                        color: "#2a5182 !important",
                      },
                      "@media(max-width:768px)": {
                        fontSize: "14px !important",
                      },
                      "@media(max-width:500px)": {
                        fontSize: "12px !important",
                      },
                    }}
                  >
                    {i18next.t("Base Currency")}
                  </InputLabel>
                  <Select
                    fullWidth
                    label={i18next.t("Base Currency")}
                    value={base}
                    onChange={handleBaseChange}
                    sx={{
                      minWidth: "120px",
                      height: "55px",
                      "& .MuiOutlinedInput-notchedOutline": {
                        border: "none !important",
                      },
                    }}
                    inputProps={{
                      classes: {
                        notchedOutline: "customInputNoBorder",
                        input: "customInputpadding",
                      },
                      sx: {
                        "input:-webkit-autofill, input:-webkit-autofill:hover, input:-webkit-autofill:focus, input:-webkit-autofill:active":
                          {
                            borderRadius: "16px !important",
                            "-webkit-text-fill-color": "inherit",
                            "-webkit-box-shadow":
                              "0 0 0px 1000px #e0e3e7 inset !important",
                            backgroundColor: "#e0e3e7 !important",
                          },
                      },
                    }}
                    MenuProps={{
                      anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "left",
                      },
                      transformOrigin: {
                        vertical: "top",
                        horizontal: "left",
                      },
                    }}
                  >
                    {currencyList.map((currency: any) => (
                      <MenuItem key={"base" + currency} value={currency}>
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                          {getCurrencyFlag(currency)}{" "}
                          <Typography sx={{ paddingLeft: 2 }} variant="body1">
                            {currency}
                          </Typography>
                        </Box>
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Box className="currencies-container">{renderBox(firstTable)}</Box>
    </Box>
  );
};

export default CurrencyCard;
