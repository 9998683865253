import { Box } from "@mui/material";
import "./LandingPage.scss";
import FooterLanding from "../components/FooterLanding/FooterLanding";
import HeaderLanding from "../components/HeaderLanding/HeaderLanding";
import { useEffect, useRef, useState } from "react";
import { useAppSelector } from "src/store";
import WelcomeVicPay from "../components/LandingSections/WelcomeVicPay";
import OurVision from "../components/LandingSections/OurVision";
import WhyVicPay from "../components/LandingSections/WhyVicPay";
import GetCreditCard from "../components/LandingSections/GetCreditCard";
//import Participation from "./components/LandingSections/Participation";
import FAQS from "../components/LandingSections/FAQS";
import ContactUs from "../components/LandingSections/ContactUs";

export default function LandingPage() {
  const { user } = useAppSelector((state) => state.auth);
  const [showOverlay, setShowOverlay] = useState(true);

  const landingContainerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const overlayElement = document.querySelector(".transition-element");

    const handleAnimationEnd = () => {
      setShowOverlay(false);
    };

    overlayElement?.addEventListener("animationend", handleAnimationEnd);

    return () => {
      overlayElement?.removeEventListener("animationend", handleAnimationEnd);
    };
  }, []);

  return (
    <Box>
      <HeaderLanding user={user} />
      <Box className="landingMainContainer" ref={landingContainerRef}>
        <Box className="landingContainer">
          <WelcomeVicPay user={user} />
          <OurVision />
          <WhyVicPay />
          <GetCreditCard />
          <FAQS />
          <ContactUs />
        </Box>
        {showOverlay && <div className="transition-element"></div>}
      </Box>
      <FooterLanding />
    </Box>
  );
}
