import { Outlet } from "react-router-dom";
import ScrollToTop from "src/components/ScrollToTop";
import Wrapper from "src/components/layout/Wrapper";
import CookieModal from "src/components/manageCookiesCard/manageCookiesCard";

function RootLayout() {
  return (
    <>
      <Wrapper>
        <CookieModal />
        <ScrollToTop />
        <Outlet />
      </Wrapper>
    </>
  );
}

export default RootLayout;
