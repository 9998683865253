export const storeUser = (key: string, data: any) => {
  localStorage.setItem(key,data);
};
export const getUser = () => {
  return JSON.parse(localStorage.getItem("victorumuser") || "null");
};

export const getToken = () => {
  return localStorage.getItem("token") || null;
};
export const removeUser = (key: string) => {
  localStorage.removeItem(key);
};
