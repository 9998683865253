import { Box, Button, Container, Typography, createTheme } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import recipientsService from "src/store/recipients/recipients_services";
import { ThemeProvider } from "@emotion/react";
import Nav from "src/components/layout/nav/Nav";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import LoadingIndicator from "src/components/shared/LoadingIndicator";
import RecipientMetaDetails from "src/components/RecipientsList/RecipientMetaDetails";
import "./RecipientDetails.scss";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import RecipientDetailsBasic from "src/components/RecipientsList/RecipientDetailsBasic";
import AreYouSureModal from "src/components/shared/AreYouSureModal/AreYouSureModal";
import { toast } from "react-toastify";
import i18next from "i18next";

const defaultTheme = createTheme();

function RecipientDetail() {
  const { recipientId }: any = useParams();
  const [recipientDetails, setRecipientDetails] = useState<any>(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);

  const navigate = useNavigate();

  const handleDeleteRecipientModal = () => {
    setDeleteModalOpen(true);
  };
  const handleDeleteRecipient = async () => {
    try {
      const response = await recipientsService.deleteRecipient(recipientId);
      const message = response.message;
      setDeleteModalOpen(false);
      navigate(-1);
      toast.success(message);
    } catch (error: any) {
      toast.error((error as Error)?.message);
    }
  };
  const handleNavigate = () => {
    navigate(-1);
  };

  const handleEditRecipientClick = (event: any) => {
    navigate(`/edit-recipient/${recipientId}`);
  };

  const fetchRecipientDetails = async () => {
    setLoading(true);
    try {
      if (!recipientId) return;
      setLoading(true);
      const res = await recipientsService.getRecipientsById(recipientId);
      setRecipientDetails(res.data);
      setLoading(false);
    } catch (error: any) {
      setLoading(false);
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        setError(error.response.data.message);
      } else {
        let errorMsg = "An unknown error occurred";
        setError(errorMsg);
      }
    }
  };

  useEffect(() => {
    fetchRecipientDetails();
  }, []);

  return (
    <div>
      <Nav step={0} isStepperVisible={false} isProfileSetVisible={true} />
      <div className="main-admin-container">
        <div className="content-container-profile main-color">
          <ThemeProvider theme={defaultTheme}>
            <Container component="main">
              {loading ? (
                <LoadingIndicator />
              ) : (
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <Box className="recentBox">
                    <div className="d-flex ai-center mb-3-rem">
                      <p
                        className="back-button-icon circle-around-text-smaller helvetica mr-1-rem cp"
                        onClick={handleNavigate}
                      >
                        <ArrowBackIcon className="muiIcons" />
                      </p>

                      <Typography
                        className="your-details-text d-flex jc-start pageTitleFontSize"
                        component="h1"
                        variant="h5"
                        sx={{
                          fontFamily: "Helvetica, sans-serif",
                          fontWeight: "600",
                          textAlign: "start",
                        }}
                      >
                        {i18next.t("Recipient details")}
                      </Typography>
                    </div>
                    <Box>
                      <Box sx={{ mt: 2 }}>
                        <div className="d-flex jusifyContentSpaceBetween ai-center mb-1rem recipientsInfoTextBtnsBox registration1375RemFontSize">
                          <Typography
                            className="d-flex jc-start infoTextMobile registration1375RemFontSize"
                            component="h1"
                            variant="h5"
                            sx={{
                              fontFamily: "Helvetica, sans-serif",
                              // fontSize: "1.375rem",
                              fontWeight: "600",
                            }}
                          >
                            {i18next.t("Recipient's information")}
                          </Typography>
                          <Box className="editDeleteBtnsBox">
                            <Button
                              variant="contained"
                              startIcon={
                                <DeleteOutlineOutlinedIcon className="recipientsBtnsIcon" />
                              }
                              className="deleteRecipientBtn"
                              onClick={handleDeleteRecipientModal}
                              sx={{
                                height: {
                                  xs: "38px !important",
                                  sm: "48px !important",
                                },
                              }}
                            >
                              {i18next.t("Delete recipient")}
                            </Button>
                            <Button
                              variant="contained"
                              onClick={handleEditRecipientClick}
                              startIcon={
                                <EditOutlinedIcon className="recipientsBtnsIcon" />
                              }
                              className="editRecipientBtn"
                              sx={{
                                height: {
                                  xs: "38px !important",
                                  sm: "48px !important",
                                },
                              }}
                            >
                              {i18next.t("Edit recipient")}
                            </Button>
                          </Box>
                        </div>
                        {recipientDetails &&
                        recipientDetails.meta &&
                        recipientDetails.meta !== null ? (
                          <div className="personal-box">
                            <div className="infoGrid">
                              {recipientDetails &&
                                recipientDetails.meta &&
                                recipientDetails.meta.details && (
                                  <RecipientMetaDetails
                                    recipientDetails={recipientDetails}
                                  />
                                )}
                            </div>
                          </div>
                        ) : (
                          <div className="personal-box">
                            <div className="infoGrid">
                              <RecipientDetailsBasic
                                recipientDetails={recipientDetails}
                              />
                            </div>
                          </div>
                        )}
                      </Box>
                    </Box>
                  </Box>
                </Box>
              )}
            </Container>
            <AreYouSureModal
              open={isDeleteModalOpen}
              handleClose={() => setDeleteModalOpen(false)}
              handleYes={handleDeleteRecipient}
              title={i18next.t("Are you sure you want to delete this Recipient")}
            />
          </ThemeProvider>
        </div>
      </div>
    </div>
  );
}

export default RecipientDetail;
