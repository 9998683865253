import { styled, useTheme } from "@mui/material/styles";
import Chart, { useChart } from "src/components/chart";

import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import { FormControl, MenuItem, Select } from "@mui/material";

const CHART_HEIGHT = 400;

const LEGEND_HEIGHT = 72;

const StyledChart = styled(Chart)(({ theme }) => ({
  height: CHART_HEIGHT,
  "& .apexcharts-canvas, .apexcharts-inner, svg, foreignObject": {
    height: `100% !important`,
  },
  "& .apexcharts-legend": {
    height: LEGEND_HEIGHT,
    borderTop: `dashed 1px ${theme.palette.divider}`,
    top: `calc(${CHART_HEIGHT - LEGEND_HEIGHT}px) !important`,
  },
}));

interface ChartPieProps {
  chart?: object;
  subheader?: string;
  title?: string;
  colors?: any;
}

export default function ChartPie({
  title,
  subheader,
  chart,
  initialThreeBoxesPeriod,
  handleThreeBoxesChange,
  ...other
}: any) {
  const theme = useTheme();

  const { colors, series, options } = chart;

  const chartSeries = series.map((i: any) => i.value);

  const chartOptions = useChart({
    chart: {
      sparkline: {
        enabled: true,
      },
    },
    colors,
    labels: series.map((i: any) => i.label),
    stroke: {
      colors: [theme.palette.background.paper],
    },
    legend: {
      floating: true,
      position: "bottom",
      horizontalAlign: "center",
    },
    dataLabels: {
      enabled: true,
      dropShadow: {
        enabled: false,
      },
    },
    tooltip: {
      fillSeriesColor: false,
      y: {
        formatter: (value: number) => value,
        title: {
          formatter: (seriesName: string) => `${seriesName}`,
        },
      },
    },
    plotOptions: {
      pie: {
        donut: {
          labels: {
            show: false,
          },
        },
      },
    },
    ...options,
  });

  return (
    <Card {...other}>
      <CardHeader
        title={title}
        subheader={subheader}
        titleTypographyProps={{
          fontFamily: "Helvetica, sans-serif",
          fontSize: "16px",
          fontWeight: 600,
          color: "rgb(33, 43, 54)",
        }}
        action={
          <FormControl size="small">
            <Select
              sx={{
                height: "30px",
                borderRadius: "16px",
                backgroundColor: "rgb(244, 246, 248)",
                fontSize: "14px",
                color: "#2A5182",
                border: "none",
                "&:focus": {
                  outline: "none",
                  borderRadius: "8px",
                },
                "& .MuiOutlinedInput-notchedOutline": {
                  border: "none",
                },
                ".MuiSelect-select": {
                  borderRadius: "8px",
                },
              }}
              value={initialThreeBoxesPeriod}
              onChange={handleThreeBoxesChange}
            >
              <MenuItem
                sx={{ color: "#2A5182", fontSize: "14px" }}
                value={"today"}
              >
                Today
              </MenuItem>
              <MenuItem
                sx={{ color: "#2A5182", fontSize: "14px" }}
                value={"last7Days"}
              >
                Last 7 Days
              </MenuItem>
              <MenuItem
                sx={{ color: "#2A5182", fontSize: "14px" }}
                value={"last30Days"}
              >
                Last 30 Days
              </MenuItem>
              <MenuItem
                sx={{ color: "#2A5182", fontSize: "14px" }}
                value={"last12Months"}
              >
                Last 12 Months
              </MenuItem>
              <MenuItem
                sx={{ color: "#2A5182", fontSize: "14px" }}
                value={"lastTwoYears"}
              >
                Last Two Years
              </MenuItem>
            </Select>
          </FormControl>
        }
        sx={{
          mb: 5,
        }}
      />
      <StyledChart
        dir="ltr"
        type="pie"
        series={chartSeries}
        options={chartOptions}
        width="100%"
        height={280}
      />
    </Card>
  );
}
