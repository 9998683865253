import React, { useState, useEffect } from "react";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import "./BalanceRequestList.scss";
import Nav from "src/components/layout/nav/Nav";
import { Typography, Box } from "@mui/material";
import api from "src/store/interceptors/api";
import { useAppSelector } from "src/store";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useLocation, useNavigate } from "react-router-dom";
import LoadingIndicator from "src/components/shared/LoadingIndicator";
import BalanceRequestListItem from "src/pages/Balance/components/BalanceRequestListItem";
import CryptoRequestListItem from "./components/CryptoRequestListItem";
import i18next from "i18next";

const defaultTheme = createTheme();
const BalanceRequestList: React.FC = () => {
  const { user } = useAppSelector((state) => state.auth);
  const navigate = useNavigate();
  const [balanceRequestData, setBalanceRequestData] = useState<any[]>([]);
  const [cryptoRequestData, setCryptoRequestData] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const location = useLocation();

  // tabs start
  const [selectedTab, setSelectedTab] = useState("PaymentRequestsBalance");

  const handleTabClick = (tabName: string) => {
    setSelectedTab(tabName);
    setIsLoading(true);

    if (tabName === "Crypto") {
      navigate("/balance-list/crypto");
    } else if (tabName === "PaymentRequestsBalance") {
      navigate("/balance-list/request-payments");
    }
  };

  useEffect(() => {
    // Check if the current URL is for the 'crypto' tab
    if (location.pathname.includes("/balance-list/crypto")) {
      setSelectedTab("Crypto");
    }
    // Check if the current URL is for the 'payment requests balance' tab
    else if (location.pathname.includes("/balance-list/request-payments")) {
      setSelectedTab("PaymentRequestsBalance");
    }
  }, [location]);
  // tabs end

  const handleNavigate = () => {
    navigate("/home");
  };

  const getBalanceRequests = async () => {
    setIsLoading(true);
    const userId = user && user.id ? user.id : null;
    let strUrl = "";
    if (userId) {
      strUrl = "?exact_search[user_id]=" + userId;
    }
    const response = await api.get(`payment-requests` + strUrl);

    setIsLoading(false);
    if (response && response.data && response.data.data) {
      setBalanceRequestData(response.data.data);
    }
  };

  const getCryptoRequests = async () => {
    setIsLoading(true);
    const userId = user && user.id ? user.id : null;
    let strUrl = "";
    if (userId) {
      strUrl = "?exact_search[user_id]=" + userId;
    }
    const response = await api.get(`crypto-payment` + strUrl);

    setIsLoading(false);
    if (response && response.data && response.data.data) {
      setCryptoRequestData(response.data.data);
    }
  };

  // useEffect for tab switching
  useEffect(() => {
    if (selectedTab === "PaymentRequestsBalance") {
      getBalanceRequests();
    } else if (selectedTab === "Crypto") {
      getCryptoRequests();
    }
    // This effect runs whenever selectedTab changes.
  }, [selectedTab]);

  return (
    <div>
      <Nav step={0} isStepperVisible={false} isProfileSetVisible={true} />
      <div className="content-container-profile">
        <ThemeProvider theme={defaultTheme}>
          <Container component="main">
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <div className="d-flex ai-center mb-3-rem">
                <p
                  className="back-button-icon circle-around-text-smaller helvetica mr-1-rem cp"
                  onClick={handleNavigate}
                >
                  <ArrowBackIcon className="muiIcons" />
                </p>

                <div
                  className="d-flex ai-center"
                  style={{ marginBottom: "", marginLeft: "12px" }}
                >
                  <Typography
                    className="your-details-text d-flex jc-start"
                    component="h1"
                    variant="h5"
                    sx={{
                      fontFamily: "Helvetica, sans-serif",
                      fontSize: {
                        xs: "14px",
                        sm: "18px",
                      },
                      textAlign: "center",
                      fontWeight: "600",
                      marginRight: "2rem",
                      color:
                        selectedTab === "PaymentRequestsBalance"
                          ? "#2A5182"
                          : "inherit",
                      cursor: "pointer",
                      backgroundColor:
                        selectedTab === "PaymentRequestsBalance"
                          ? "#16330014;"
                          : "none",
                      "&:hover": {
                        backgroundColor: "rgb(244, 246, 248)",
                      },
                      padding: "0.6rem",
                      borderRadius: "16px",
                    }}
                    onClick={() => handleTabClick("PaymentRequestsBalance")}
                  >
                    {i18next.t("Payment Requests - Balance")}
                  </Typography>

                  <Typography
                    className="your-details-text d-flex jc-start"
                    component="h1"
                    variant="h5"
                    sx={{
                      fontFamily: "Helvetica, sans-serif",
                      fontSize: {
                        xs: "14px",
                        sm: "18px",
                      },
                      textAlign: "center",
                      fontWeight: "600",
                      color: selectedTab === "Crypto" ? "#2A5182" : "inherit",
                      cursor: "pointer",
                      backgroundColor:
                        selectedTab === "Crypto" ? "#16330014;" : "none",
                      padding: "0.6rem",
                      borderRadius: "16px",
                      "&:hover": {
                        backgroundColor: "rgb(244, 246, 248)",
                      },
                    }}
                    onClick={() => handleTabClick("Crypto")}
                  >
                    {i18next.t("Crypto Requests")}
                  </Typography>
                </div>
              </div>

              {/* <div
                className="d-flex ai-center"
                style={{ marginBottom: "32px", marginLeft: "12px" }}
              >
                <Typography
                  className="your-details-text d-flex jc-start"
                  component="h1"
                  variant="h5"
                  sx={{
                    fontFamily: "Helvetica, sans-serif",
                    fontSize: {
                      xs: "12px",
                      sm: "1rem",
                    },
                    textAlign: "center",
                    fontWeight: "600",
                    marginRight: "2rem",
                    color:
                      selectedTab === "PaymentRequestsBalance"
                        ? "#2A5182"
                        : "inherit",
                    cursor: "pointer",
                    backgroundColor:
                      selectedTab === "PaymentRequestsBalance"
                        ? "#16330014;"
                        : "none",
                    "&:hover": {
                      backgroundColor: "rgb(244, 246, 248)",
                    },
                    padding: "0.6rem",
                    borderRadius: "16px",
                  }}
                  onClick={() => handleTabClick("PaymentRequestsBalance")}
                >
                  Payment Requests - Balance
                </Typography>

                <Typography
                  className="your-details-text d-flex jc-start"
                  component="h1"
                  variant="h5"
                  sx={{
                    fontFamily: "Helvetica, sans-serif",
                    fontSize: {
                      xs: "12px",
                      sm: "1rem",
                    },
                    textAlign: "center",
                    fontWeight: "600",
                    color: selectedTab === "Crypto" ? "#2A5182" : "inherit",
                    cursor: "pointer",
                    backgroundColor:
                      selectedTab === "Crypto" ? "#16330014;" : "none",
                    padding: "0.6rem",
                    borderRadius: "16px",
                    "&:hover": {
                      backgroundColor: "rgb(244, 246, 248)",
                    },
                  }}
                  onClick={() => handleTabClick("Crypto")}
                >
                  Crypto Requests
                </Typography>
              </div> */}

              {isLoading && <LoadingIndicator />}

              {!isLoading && selectedTab === "PaymentRequestsBalance" && (
                <BalanceRequestListItem user={user} data={balanceRequestData} />
              )}

              {!isLoading && selectedTab === "Crypto" && (
                <CryptoRequestListItem user={user} data={cryptoRequestData} />
              )}
            </Box>
          </Container>
        </ThemeProvider>
      </div>
    </div>
  );
};

export default BalanceRequestList;
