import { useFormik } from "formik";
import {
  Button,
  TextField,
  Autocomplete,
  Box,
  InputAdornment,
} from "@mui/material";
import * as Yup from "yup";
import React, { useEffect, useState } from "react";
import api from "src/store/interceptors/api";
import i18next from "i18next";

interface MoneyAddFormProps {
  onSubmit: (amount: number, currency: any) => void;
  initialSelectedCurrency: any;
}

const MoneyAddForm: React.FC<MoneyAddFormProps> = ({
  onSubmit,
  initialSelectedCurrency,
}) => {
  const [currencies, setCurrencies] = useState<any>(null);
  const [currency, setCurrency] = useState(
    currencies && currencies[0] ? currencies[0] : null
  );

  const getInitialData = async () => {
    await api
      .get("/profile/balances")
      .then((response: any) => {
        setCurrencies(response.data);
        setCurrency(
          response && response.data && response.data[0]
            ? response.data[0]
            : null
        );
      })
      .catch((error) => {
        console.error(error.message);
      });
  };

  const handleAmountChange = (event: any) => {
    const { value } = event.target;
    if (/^[0-9]*\.?[0-9]*$/.test(value)) {
      formik.handleChange(event);
    }
  };

  const handleCurrencyChange = (event: any, newValue: any) => {
    setCurrency(newValue);
  };

  useEffect(() => {
    getInitialData();
  }, []);
  useEffect(() => {
    if (currencies && currencies.length > 0) {
      const selectedCurrency = currencies.find(
        (option: any) => option.currency === initialSelectedCurrency
      );

      // Check if initialSelectedCurrency is a valid option, if not, use the first option
      setCurrency(selectedCurrency || currencies[0]);
    }
  }, [currencies, initialSelectedCurrency]);

  const formik = useFormik({
    initialValues: {
      amount: "",
    },
    validationSchema: Yup.object({
      amount: Yup.number()
        .required("Amount is required")
        .min(0, "Amount must be greater than or equal to 0"),
    }),
    onSubmit: (values) => {
      onSubmit(parseFloat(values.amount), currency);
    },
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <div className="mt-16 w-100">
        <TextField
          style={{ width: "100%" }}
          type="text"
          name="amount"
          placeholder={i18next.t("Enter amount")}
          value={formik.values.amount}
          onChange={handleAmountChange}
          variant="outlined"
          sx={{
            "& .MuiOutlinedInput-root": {
              paddingRight: "0px!important",
            },
          }}
          InputProps={{
            sx: {
              borderRadius: "10px",
              minHeight: "48px",
              "@media (max-width:600px)": {
                fontSize: "14px",
              },
            },
            endAdornment: (
              <InputAdornment position="end">
                <Autocomplete
                  id="currency-select"
                  sx={{
                    width: "11rem",
                    borderRadius: "10px",
                    "& .MuiOutlinedInput-root": {
                      border: "none", // Removes the border
                    },
                    "& .Mui-focused .MuiOutlinedInput-root": {
                      border: "none", // Removes the border when the field is focused
                    },
                    "& .MuiOutlinedInput-notchedOutline": {
                      border: "none", // Removes the notched outline
                    },
                  }}
                  options={currencies}
                  value={currency}
                  aria-placeholder="Select"
                  getOptionLabel={(option) => option.currency}
                  onChange={handleCurrencyChange}
                  renderOption={(props, option) => (
                    <Box
                      component="li"
                      {...props}
                      className="d-flex ai-center mt-1-rem cp"
                    >
                      <p className="normal-text" style={{ marginLeft: 10 }}>
                        {option.currency}
                      </p>
                    </Box>
                  )}
                  renderInput={(params) => <TextField {...params} />}
                  clearIcon={null}
                />
              </InputAdornment>
            ),
          }}
        />
      </div>
      <div className="d-flex ai-center jusifyContentSpaceBetween mt-8">
        <Button
          type="submit"
          fullWidth
          variant="contained"
          className="btnsLoginHeight oneRemFontSize"
          sx={{
            color: "white",
            backgroundColor: "#2A5182",
            borderRadius: "24px",
            textTransform: "none",
            marginTop: "0.5rem",
            marginBottom: "1rem",
            fontFamily: "Helvetica, sans-serif",
          }}
          disabled={!currency || formik.values.amount === "" ? true : false}
          onClick={(e: any) => formik.handleSubmit(e)}
        >
          {i18next.t("Continue")}
        </Button>
      </div>
    </form>
  );
};

export default MoneyAddForm;
