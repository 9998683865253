import { Box, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useFormik, FormikProvider } from "formik";
import * as Yup from "yup";
import UserDetailsTabs from "../../UserDetailsTabs/UserDetailsTabs";
import UserNotificationSection from "./UserNotificationSection";
import i18next from "i18next";
import api from "src/store/interceptors/api";
import { toast } from "react-toastify";

function UserNotifications({ data }: any) {
  const [user, setUser] = useState(data);

  const userId = user.id;

  const sendEmailFormik = useFormik({
    initialValues: {
      apiKey: "",
      checkbox: false,
    },
    validationSchema: Yup.object({
      apiKey: Yup.string().required(i18next.t("Required")),
      checkbox: Yup.boolean().oneOf([true], i18next.t("Checkbox is required")),
    }),
    onSubmit: async (values) => {
      try {
        const payload = {
          provider_3s_key: values.apiKey,
          verification_3s_approved: values.checkbox,
        };
        const response = await api.put(
          `/admin/send-welcome-email/${userId}`,
          payload
        );

        if (response.data.success) {
          toast.success(response.data.message);
        } else {
          toast.error(response.data.message);
        }
      } catch (error) {
        toast.error("An unexpected error occurred.");
        console.error("API call failed", error);
      }
    },
    validateOnChange: false,
    validateOnBlur: false,
  });

  const handleSendApiContract = async () => {
    try {
      const response = await api.get(`/admin/send-api-key-contract/${userId}`);

      if (response.data.success) {
        toast.success(response.data.message);
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error("An unexpected error occurred.");
      console.error("API call failed", error);
    }
  };

  useEffect(() => {
    setUser(data);
  }, [data]);

  return (
    <Box className="recentBox">
      {!user ? (
        <Typography className="noDataText">No user found</Typography>
      ) : (
        <Box>
          <UserDetailsTabs user={user} />
          <FormikProvider value={sendEmailFormik}>
            <Box sx={{ mt: 2, border: "1px solid #ddd", borderRadius: "24px" }}>
              {user.welcome_email_sent === null && (
                <UserNotificationSection
                  sectionTitle="Send welcome email"
                  checkboxName="checkbox"
                  checkboxLabel="
                    Verification approved"
                  textFieldName="apiKey"
                  textFieldLabel="API Key"
                  buttonLabel="Send welcome email"
                  onButtonClick={sendEmailFormik.handleSubmit}
                />
              )}
              <UserNotificationSection
                sectionTitle="Send API contract"
                buttonLabel="Send API contract"
                onButtonClick={handleSendApiContract}
              />
            </Box>
          </FormikProvider>
        </Box>
      )}
    </Box>
  );
}

export default UserNotifications;
