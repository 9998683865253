import api from "../interceptors/api";

const getInvestments = async (searchValue?: string | null) => {
  let url = `/investment-requests`
  if(searchValue){
    url += searchValue
  }
  const response = await api.get(url);
  return response.data;
};
const getInvestmentsById = async (investmentId: string) => {
  const response = await api.get(`/investment-requests/${investmentId}`);
  return response.data;
};

const getParticipations = async (searchValue?: string | null) => {
  let url = `/participation-requests`
  if(searchValue){
    url += searchValue
  }
  const response = await api.get(url);
  return response.data;
};
const getParticipationsById = async (investmentId: string) => {
  const response = await api.get(`/participation-requests/${investmentId}`);
  return response.data;
};

const investmentsServices = {
  getInvestments,
  getInvestmentsById,
  getParticipations,
  getParticipationsById
};

export default investmentsServices;
