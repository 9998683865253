import React from "react";
import "../CountrySelect/CountrySelect.scss";
import { Autocomplete, Box, TextField } from "@mui/material";
import { COUNTRIES } from "../constants";
import i18next from "i18next";

interface Props {
  onSelect: any;
  value: string;
}
function PhoneCountrySelect({ onSelect, value }: Props) {
  const [selectedCountry, setSelectedCountry] = React.useState<any | null>(
    null
  );

  return (
    <Box className="customInputContainer">
      <Box className="customInputSubContainer">
        <Autocomplete
          id="country-select-demo"
          sx={{
            width: "100%",
            borderRadius: "16x",
          }}
          options={COUNTRIES}
          autoHighlight
          value={selectedCountry}
          // getOptionLabel={(option) => `${option.phone}`}
          getOptionLabel={(option) => `${option.code} +${option.phone}`}
          onChange={(event, newValue) => {
            if (newValue) setSelectedCountry(newValue);
            onSelect(newValue ? newValue.phone : "");
          }} // Pass the selected value to the parent
          renderOption={(props, option) => (
            <Box
              component="li"
              sx={{ "& > img": { mr: 2, flexShrink: 0, borderRadius: "10px" } }}
              {...props}
            >
              {option.code} +{option.phone} {option.label}
            </Box>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              placeholder={i18next.t("Choose Country")}
              value={value}
              autoComplete="off"
              inputProps={{
                ...params.inputProps,
                borderRadius: "16px",
              }}
              sx={{
                "& .MuiAutocomplete-input": {
                  padding: "0px 4px 0px 5px !important",
                },
                "& .MuiAutocomplete-inputRoot": {
                  paddingLeft: "16px !important",
                  paddingTop: "16px !important",
                  paddingBottom: "16px !important",
                  borderRadius: "16px",
                  height: "55px",
                  "& fieldset": {
                    border: "none",
                  },
                  "&:hover fieldset": {
                    border: "none",
                  },
                  "&.Mui-focused fieldset": {
                    border: "none",
                  },
                },
                "@media (max-width: 600px)": {
                  "& .MuiAutocomplete-inputRoot": {
                    fontSize: "16px",
                  },
                },
              }}
            />
          )}
        />
      </Box>
    </Box>
  );
}

export default PhoneCountrySelect;
