import React from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Box, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import HeaderLanding from "../components/HeaderLanding/HeaderLanding";
import { useAppSelector } from "src/store";
import FooterLanding from "../components/FooterLanding/FooterLanding";
import "./LandingPagesGlobal.scss";
import TitleBackBox from "../components/TitleBackBox/TitleBackBox";
import HeadlineVvidgetSmall from "../components/HeadlineVvidget/HeadlineVvidgetSmall";
import i18next from "i18next";

const defaultTheme = createTheme();

const CompilenceDesk: React.FC = () => {
  const { user } = useAppSelector((state) => state.auth);

  const navigate = useNavigate();

  const navigateToContactUs = (event: any) => {
    navigate("/contact-us");
  };

  return (
    <div>
      <HeaderLanding user={user} />
      <div className="margintTopPagesMainCont">
        <ThemeProvider theme={defaultTheme}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Box className="titleHeadlineSmallBG">
              <Box className="landingPagesMainBox">
                <Box>
                  <TitleBackBox title={i18next.t("Compliance desk")} />
                </Box>
                <Box>
                  <HeadlineVvidgetSmall
                    headline={i18next.t("Integrity is the key to success")}
                    subtitle={""}
                    smallText={i18next.t("Contact our support team")}
                    buttonText={i18next.t("Contact Us")}
                    onClickFunction={navigateToContactUs}
                  />
                </Box>
              </Box>
            </Box>
            <Box className="landingPagesMainBox">
              <Box>
                <Typography
                  variant="h3"
                  paragraph
                  className="headlineMiniLandingPages"
                >
                  <strong>
                    {i18next.t("Maximizing Responsibility in Business Operations")}
                  </strong>
                </Typography>
                <Typography
                  variant="body1"
                  paragraph
                  className="textsLandingPage"
                >
                  {i18next.t("Our aim is not only to do good business that benefits customers and VicPay, but also to maximise responsibility in business life")}
                </Typography>
                <Typography
                  variant="body1"
                  paragraph
                  className="textsLandingPage"
                >
                  {" "}
                  {i18next.t("This includes not only dealing with employees, resources and customers, but also responsibility towards legislators and regulatory authorities")}
                </Typography>
              </Box>
              <Box>
                <Typography
                  variant="h3"
                  paragraph
                  className="headlineMiniLandingPages"
                >
                  <strong>{i18next.t("Embracing Regulatory Requirements")}</strong>
                </Typography>
                <Typography
                  variant="body1"
                  paragraph
                  className="textsLandingPage"
                >
                  {i18next.t("‘We at VicPay do not see the growing regulatory requirements as a hurdle, but as a necessary and correct consequence of circumstances that make such requirements necessary!’ said Osbert Döhl, CEO of VicPay Inc. in January 2023")}
                </Typography>
                <Typography
                  variant="body1"
                  paragraph
                  className="textsLandingPage"
                >
                  {" "}
                  {i18next.t("‘The world is changing, political unrest, armed conflicts and the growing global threat of terrorism are threatening our society. The financial industry in particular can help to ensure that customers and their financial flows are better controlled and high-risk transactions can be ruled out through stricter regulations and maximum vigilance")}
                </Typography>
              </Box>
              <Box>
                <Typography
                  variant="h3"
                  paragraph
                  className="headlineMiniLandingPages"
                >
                  <strong>{i18next.t("Ensuring Trust and Integrity")}</strong>
                </Typography>
                <Typography
                  variant="body1"
                  paragraph
                  className="textsLandingPage"
                >
                  {i18next.t("We always ensure that our financial reports comply with the national and international accounting standards required by our regulator FINTRAC")}
                </Typography>
                <Typography
                  variant="body1"
                  paragraph
                  className="textsLandingPage"
                >
                  {" "}
                  {i18next.t("In this way, we maintain the trust and integrity of VicPay and maximise the security of our customers and partners")}
                </Typography>
              </Box>
              <Box sx={{ marginTop: "2rem" }}>
                <Typography
                  variant="h3"
                  sx={{
                    marginBottom: "1rem",
                  }}
                  className="headlineMiniLandingPages"
                >
                  {" "}
                  {i18next.t("Contact Us Anytime")}
                </Typography>
                <Typography
                  variant="body1"
                  paragraph
                  className="textsLandingPage"
                >
                  {i18next.t("In case of any questions regarding our Compliance Policy we strongly advise you to get in touch with our dedicated compliance desk. Please dial +1 604-260-0738 or drop a message to")}{" "}
                  <a href="mailto:info@vicpayments.com" className="linksHover">
                    info@vicpayments.com
                  </a>
                </Typography>
              </Box>
            </Box>
          </Box>
        </ThemeProvider>
      </div>
      <Box sx={{ marginTop: "4rem" }}>
        <FooterLanding />
      </Box>
    </div>
  );
};

export default CompilenceDesk;
