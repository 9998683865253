import React from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Box, Typography } from "@mui/material";
import HeaderLanding from "../components/HeaderLanding/HeaderLanding";
import { useAppSelector } from "src/store";
import FooterLanding from "../components/FooterLanding/FooterLanding";
import IconTextWidget2 from "../components/IconTextWidget2/IconTextWidget2";
import currencyIcon from "../../../assets/images/currencies.png";
import merchantIcon from "../../../assets/images/merchant.png";
import users from "../../../assets/images/users.png";
import icon24 from "../../../assets/images/24.png";
import languages from "../../../assets/images/languages.png";
import paymentsImage from "../../../assets/images/paymentsImage.png";
import { useNavigate } from "react-router-dom";
import i18next from "i18next";
import HeadlineVvidgetSmall2 from "../components/HeadlineVvidget/HeadlineVvidgetSmall2";
import "./LandingPagesGlobal2.scss";
import ImageWithText2 from "../components/ImageWithText/ImageWithText2";
import LogoTextVvidget2 from "../components/LogoTextVvidget/LogoTextVvidget2";
import ImagesWithText from "../components/ImagesWithText/ImagesWithText";

const offerContent = [
  {
    title: i18next.t("Modern Solutions for Modern Companies"),
    text: i18next.t(
      "The needs of companies in the 21st century have changed drastically, just as the demands and expectations of customers in traditional point of sale and online retail have changed drastically"
    ),
  },
  {
    title: i18next.t("Adaptive Services"),
    text: i18next.t(
      "Unfortunately, however, very few traditional banks, brokers, and financial service providers have adapted to these changes and adapted their services accordingly"
    ),
  },
  {
    title: i18next.t("Streamlined Support"),
    text: i18next.t(
      "Our support and our tools for corporate clients are easy to start or stop using, do not include setup fees, further obligations, and are simple, secure, and cost-efficient"
    ),
  },
  {
    title: i18next.t("Security & Reliability"),
    text: i18next.t(
      "We offer, together with our partners, a fully regulated and secure account environment, almost 100% server uptime, and dedicated and experienced account managers, available 24/7 for our business clients"
    ),
  },
  {
    title: i18next.t("Efficiency Redefined"),
    text: i18next.t(
      "Let's talk about cutting costs by automating your crypto and fiat payments, integration and processing of crypto and credit card payments, international transactions, mass payments through API, cheap currency conversion - call us on +1 604-260-0738 and let's get started"
    ),
  },
];

const defaultTheme = createTheme();
const Business: React.FC = () => {
  const { user } = useAppSelector((state) => state.auth);
  const navigate = useNavigate();
  const navigateToRegistration = (event: any) => {
    navigate("/registration");
  };

  return (
    <div>
      <HeaderLanding user={user} />
      <div className="landingMainContainer">
        <ThemeProvider theme={defaultTheme}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
            }}
          >
            <Box className="titleHeadlineSmallBG">
              <Box className="landingPagesMainBox">
                <Box>
                  <Typography className="globalHeading">
                    {i18next.t("Business")}
                  </Typography>
                </Box>
                <Box>
                  <HeadlineVvidgetSmall2
                    headline={i18next.t(
                      "Customizable business solutions on the highest level"
                    )}
                    subtitle={i18next.t(
                      "With us, it's easy to send and receive payments, send payouts, and easily manage your funds"
                    )}
                    smallText={i18next.t("Join customers all over the world")}
                    buttonText={i18next.t("Create a free account")}
                    onClickFunction={navigateToRegistration}
                  />
                </Box>
              </Box>
            </Box>
            <Box className="">
              <div className="icon-text-widgets-container">
                <IconTextWidget2
                  iconSrc={currencyIcon}
                  title={i18next.t("Accounts in USD, EUR and other currencies")}
                  text={i18next.t("Accounts in USD, EUR and other currencies")}
                />
                <IconTextWidget2
                  iconSrc={merchantIcon}
                  title={i18next.t(
                    "Local and international deposits and withdrawals"
                  )}
                  text={i18next.t(
                    "Local and international deposits and withdrawals"
                  )}
                />
                <IconTextWidget2
                  iconSrc={languages}
                  title={i18next.t("Multilingual Support")}
                  text=""
                />
              </div>
              <div className="icon-text-widgets-container2">
                <IconTextWidget2
                  iconSrc={users}
                  title={i18next.t("Robust multi-tier account security")}
                  text={i18next.t(
                    "Robust multi-tier account security with 2FA"
                  )}
                />
                <IconTextWidget2
                  iconSrc={icon24}
                  title={i18next.t("First response in 1 hour or faster")}
                  text={i18next.t("First response in 1 hour or faster")}
                />
              </div>

              <Box className="">
                <LogoTextVvidget2
                  text={i18next.t(
                    "We offer you IBAN accounts, up to 18 currencies for your account balance, local and international payments, wire bank transfers, instant payments, cryptocurrencies, and a VISA card for your cash spendings"
                  )}
                />
              </Box>

              <Box sx={{marginTop: 5, marginBottom: 5}}>
                <ImageWithText2
                  backgroundImage={paymentsImage}
                  noiseImage="/img/payments-noise.png"
                  title={i18next.t("Flexibility & Innovation")}
                  text={i18next.t(
                    "We, on the other hand, are flexible, fast, regulated, and recognize the needs of companies and consumers"
                  )}
                />
              </Box>

              <Box>
                <ImagesWithText />
              </Box>
              <Box className="boxColumnContainer">
                {offerContent.map((item: any, key: any) => {
                  const extraClass = (key % 2 === 0)
                  ? "boxColumnItemLeft"
                  : "boxColumnItemRight";

                  let bgSet = "gradientBgSet1";
                  if(key === 0){
                    bgSet = "gradientBgSet1"
                  }else if(key === 1){
                    bgSet = "gradientBgSet2"
                  }else if(key === 2){
                    bgSet = "gradientBgSet3"
                  }else if(key === 3){
                    bgSet = "gradientBgSet1"
                  }else if(key === 4){
                    bgSet = "gradientBgSet2"
                  }else{
                    bgSet = "gradientBgSet3";
                  }

                  return (
                    <Box
                      className={
                        "boxColumnItem gradientBgSet " + extraClass + " " + bgSet
                      }
                    >
                      <Box>
                        <Typography className="textsLandingPageHeading">
                          {item.title}
                        </Typography>
                        <Typography
                          paragraph
                          className="boxColumnContainerText"
                          sx={{ color: "#fff !important" }}
                        >
                          {item.text}
                        </Typography>
                      </Box>
                    </Box>
                  );
                })}
              </Box>
            </Box>
          </Box>
        </ThemeProvider>
      </div>
      <Box sx={{ marginTop: "4rem" }}>
        <FooterLanding />
      </Box>
    </div>
  );
};

export default Business;
