import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { useState } from "react";
import Nav from "src/components/layout/nav/Nav";
import AddIcon from "@mui/icons-material/Add";
import transactionsService from "src/store/transactions/transactions_service";

import {
  Box,
  Button,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import LoadingIndicator from "src/components/shared/LoadingIndicator";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import BalanceAdminActions from "./BalanceAdminActions";
import { useAppSelector } from "src/store";
import i18next from "i18next";
const defaultTheme = createTheme();

interface Props {
  transactionDetails: any;
  transactionId: any;
  errorPage: any;
  loadingPage: boolean;
}

const renderIcon = (status: string, type: string) => {
  const iconType = transactionsService.getProperIconBasedOnStatusBalance(
    status,
    type
  );
  switch (iconType) {
    case "up":
      return (
        <ArrowUpwardIcon style={{ color: "#0e0f0c" }} className="muiIcons" />
      );
    case "plus":
      return <AddIcon style={{ color: "#0e0f0c" }} className="muiIcons" />;

    case "credit-card":
      return (
        <CreditCardIcon style={{ color: "#0e0f0c" }} className="muiIcons" />
      );
    case "bank":
      return (
        <AccountBalanceIcon style={{ color: "#0e0f0c" }} className="muiIcons" />
      );

    // case "plus":
    //    displayText = `To your ${item?.meta?.targetCurrency} balance`;
    //    return <AddIcon style={{ color: "#0e0f0c" }} />;
    default:
      return <ArrowUpwardIcon style={{ color: "#0e0f0c" }} />;
  }
};

const renderDisplayText = (item: any, status: string, type: string) => {
  let displayText = "Balance";
  const iconType = transactionsService.getProperIconBasedOnStatusBalance(
    status,
    type
  );
  switch (iconType) {
    case "up":
      displayText =
        item &&
        item.user &&
        item.user.company_name &&
        item.user.account_type === "business"
          ? item.user.company_name
          : item && item.user && item.user.first_name && item.user.last_name
          ? item.user.first_name + " " + item.user.last_name
          : "Balance";
      return displayText;
    case "plus":
      displayText =
        item &&
        item.user &&
        item.user.company_name &&
        item.user.account_type === "business"
          ? item.user.company_name
          : item && item.user && item.user.first_name && item.user.last_name
          ? item.user.first_name + " " + item.user.last_name
          : "Balance";
      return displayText;
    default:
      return displayText;
  }
};
function TransactionDetailTransferFee({
  transactionDetails,
  transactionId,
  loadingPage,
  errorPage,
}: Props) {
  const { user } = useAppSelector((state) => state.auth);
  const [loading, setLoading] = useState(loadingPage);
  const [error, setError] = useState(errorPage);
  const navigate = useNavigate();
  const [isDownloading, setIsDownloading] = useState(false);

  const handleDownload = () => {
    transactionsService.downloadTransactionInvoice(
      transactionId,
      () => setIsDownloading(true),
      () => setIsDownloading(false)
    );
  };

  const handleNavigate = () => {
    navigate(-1);
    //navigate("/transactions-list");
  };

  const initialSelectedTab =
    transactionsService.translateStatus(transactionDetails?.status) ===
    "Cancelled"
      ? "Details"
      : "Updates";
  const [selectedTab, setSelectedTab] = useState(initialSelectedTab);

  const handleClick = (tabName: string) => {
    setSelectedTab(tabName);
  };

  const textStyle = (tabName: string) => ({
    fontFamily: "Helvetica, sans-serif",
    fontSize: "1rem",
    fontWeight: selectedTab === tabName ? "600" : "400",
    color: "#454745",
    marginBottom: "0.5rem",
    marginRight: tabName === "Updates" ? "3rem" : "0",
    textDecoration: selectedTab === tabName ? "underline" : "none",
    cursor: "pointer",
  });

  const detailsDisplay = (item: any) => {
    if (item.transaction_type && item.transaction_type === "balance_in") {
      let itemStatus = i18next.t("Balance received from");
      if (item.status === "blocked") {
        itemStatus = i18next.t("Blocked balance received from");
      }

      if (item && item.user_from && item.user_from.company_name) {
        itemStatus += " " + item.user_from.company_name;
      } else {
        if (
          item &&
          item.user_from &&
          item.user_from.first_name &&
          item.user_from.last_name
        ) {
          itemStatus +=
            " " + item.user_from.first_name + " " + item.user_from.last_name;
        }
      }
      return itemStatus;
    } else if (
      item.transaction_type &&
      item.transaction_type === "balance_in_credit_card"
    ) {
      let itemStatus = i18next.t("Balance added");
      return itemStatus;
    } else if (
      item.transaction_type &&
      item.transaction_type === "balance_in_bank"
    ) {
      let itemStatus = i18next.t("Balance added");
      return itemStatus;
    } else if (
      item.transaction_type &&
      item.transaction_type === "balance_out"
    ) {
      let itemStatus = i18next.t("Balance sent to");
      if (item && item.user_to && item.user_to.company_name) {
        itemStatus += " " + item.user_to.company_name;
      } else {
        if (
          item &&
          item.user_to &&
          item.user_to.first_name &&
          item.user_to.last_name
        ) {
          itemStatus +=
            " " + item.user_to.first_name + " " + item.user_to.last_name;
        }
      }
      return itemStatus;
    }
    return item.status;
  };

  return (
    <div>
      <Nav step={0} isStepperVisible={false} isProfileSetVisible={true} />
      <div className="content-container-profile main-color">
        <ThemeProvider theme={defaultTheme}>
          <Container component="main">
            {loading ? (
              <LoadingIndicator />
            ) : error && error !== "" ? (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  marginBottom: "4rem",
                }}
              >
                <Typography
                  className="your-details-text d-flex jc-start pageTitleFontSize"
                  component="h1"
                  variant="h5"
                  sx={{
                    fontFamily: "Helvetica, sans-serif",
                    fontWeight: "600",
                    textAlign: "start",
                    marginBottom: "2rem",
                  }}
                >
                  {error}
                </Typography>
              </Box>
            ) : (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  marginBottom: "4rem",
                }}
              >
                <div className="d-flex ai-center mb-3-rem">
                  <p
                    className="back-button-icon circle-around-text-smaller helvetica mr-1-rem cp"
                    onClick={handleNavigate}
                  >
                    <ArrowBackIcon className="muiIcons" />
                  </p>

                  <Typography
                    className="your-details-text d-flex jc-start pageTitleFontSize"
                    component="h1"
                    variant="h5"
                    sx={{
                      fontFamily: "Helvetica, sans-serif",
                      fontWeight: "600",
                      textAlign: "start",
                    }}
                  >
                    {i18next.t("Transaction details")}
                  </Typography>
                </div>

                {user &&
                  user.role &&
                  (user.role === "Admin" || user.role === "Employee") &&
                  transactionDetails.transaction_type !==
                    "balance_in_crypto" && (
                    <BalanceAdminActions
                      loadingPage={loading}
                      transactionDetails={transactionDetails}
                      transactionId={transactionId}
                    />
                  )}
                <Box sx={{ mt: 2 }}>
                  <div className="personal-box">
                    <div className="transaction-item-details">
                      <div className="d-flex ai-center jc-center">
                        <p className="back-button-icon circle-around-text-smaller helvetica mr-1-rem">
                          {/* <ArrowUpwardIcon style={{ color: "#163300" }} /> */}
                          {renderIcon(
                            transactionDetails?.status,
                            transactionDetails?.transaction_type
                          )}{" "}
                        </p>
                        <div>
                          <Typography
                            component="h1"
                            className="oneRemFontSize"
                            variant="h5"
                            sx={{
                              fontFamily: "Helvetica, sans-serif",
                              fontWeight: "600",
                              color: "#454745",
                              marginBottom: "0.5rem",
                            }}
                          >
                            {renderDisplayText(
                              transactionDetails,
                              transactionDetails?.status,
                              transactionDetails?.transaction_type
                            )}
                          </Typography>
                          <Typography
                            component="h1"
                            variant="h5"
                            className="registration0875RemFontSize"
                            sx={{
                              fontFamily: "Helvetica, sans-serif",
                              fontWeight: "300",
                              color: "#454745",
                            }}
                          >
                            {i18next.t(
                              transactionsService.translateStatus(
                                transactionDetails?.status
                              )
                            )}
                          </Typography>
                        </div>
                      </div>
                      <div className="ta-end">
                        <Typography
                          component="h1"
                          variant="h5"
                          className="oneRemFontSize"
                          sx={{
                            fontFamily: "Helvetica, sans-serif",
                            fontWeight: "600",
                            color: "#454745",
                            marginBottom: "0.5rem",
                          }}
                        >
                          {transactionsService.formatNumber(
                            transactionDetails?.amount
                          )}{" "}
                          {transactionDetails?.currency?.code}
                        </Typography>
                      </div>
                    </div>
                    <div className="horizontal-line-list mb-8"></div>
                    <div className="small-nav-box">
                      {initialSelectedTab !== "Details" && (
                        <Typography
                          className="oneRemFontSize"
                          component="h1"
                          variant="h5"
                          sx={textStyle("Updates")}
                          onClick={() => handleClick("Updates")}
                        >
                          {i18next.t("Updates")}
                        </Typography>
                      )}

                      <Typography
                        className="oneRemFontSize"
                        component="h1"
                        variant="h5"
                        sx={textStyle("Details")}
                        onClick={() => handleClick("Details")}
                      >
                        {i18next.t("Details")}
                      </Typography>
                    </div>

                    {selectedTab === "Updates" && (
                      // updates content
                      <div className="updates-content">
                        <div className="horizontal-line-list mb-8"></div>
                        <div className="small-nav-box pb-2-rem">
                          <Typography
                            component="h1"
                            variant="h5"
                            className="registration0875RemFontSize"
                            sx={{
                              fontFamily: "Helvetica, sans-serif",
                              fontWeight: "300",
                              color: "#454745",
                              marginRight: "6rem",
                            }}
                          >
                            {i18next.t("Transaction number")}
                          </Typography>
                          <Typography
                            component="h1"
                            variant="h5"
                            className="registration0875RemFontSize"
                            sx={{
                              fontFamily: "Helvetica, sans-serif",
                              fontWeight: "300",
                              color: "#454745",
                            }}
                          >
                            #B{transactionDetails?.id}
                          </Typography>
                        </div>
                        {(transactionDetails?.transaction_reference_number ||
                          transactionDetails?.meta?.reference) && (
                          <div
                            className="small-nav-box-trans"
                            style={{ flexWrap: "wrap" }}
                          >
                            <Typography
                              component="h1"
                              className="registration0875RemFontSize"
                              variant="h5"
                              sx={{
                                fontFamily: "Helvetica, sans-serif",
                                fontWeight: "300",
                                color: "#454745",
                                marginRight: "10rem",
                              }}
                            >
                              {i18next.t("Reference")}
                            </Typography>
                            <Typography
                              component="h1"
                              variant="h5"
                              className="registration0875RemFontSize"
                              sx={{
                                fontFamily: "Helvetica, sans-serif",
                                fontWeight: "300",
                                color: "#454745",
                              }}
                            >
                              {transactionDetails?.transaction_reference_number ||
                                transactionDetails?.meta?.reference}
                            </Typography>
                          </div>
                        )}

                        <div className="horizontal-line-list mb-8"></div>
                        <h3
                          className="registration0875RemFontSize"
                          style={{
                            fontFamily: "Helvetica, sans-serif",
                            fontWeight: "300",
                            color: "#454745",
                            marginTop: "1rem",
                          }}
                        >
                          {i18next.t("Amount")}
                        </h3>
                        <TextField
                          variant="outlined"
                          value={
                            transactionDetails && transactionDetails.amount
                              ? transactionsService.formatNumber(
                                  transactionDetails.amount
                                )
                              : 0
                          }
                          inputProps={{
                            readOnly: true,
                          }}
                          InputProps={{
                            sx: {
                              borderRadius: "10px",
                              height: "48px",
                              marginBottom: "1.5rem",
                              "@media (max-width: 600px)": {
                                fontSize: "14px",
                              },
                            },
                            endAdornment: (
                              <InputAdornment position="end">
                                <div className="d-flex ai-center">
                                  <span
                                    style={{
                                      marginRight: "1.5rem",
                                      fontSize: "1rem",
                                    }}
                                  >
                                    {transactionDetails?.currency?.code || ""}
                                  </span>
                                </div>
                              </InputAdornment>
                            ),
                          }}
                          sx={{
                            width: "100%",
                            borderRadius: "10px",
                            marginTop: "8px",
                            "& .MuiOutlinedInput-root": {
                              paddingRight: 0,
                            },
                            "& .MuiInputAdornment-root": {
                              backgroundColor: "transparent",
                              pointerEvents: "none",
                            },
                          }}
                        />
                        <Button
                          onClick={handleDownload}
                          fullWidth
                          variant="contained"
                          className="btnsLoginHeight oneRemFontSize"
                          sx={{
                            color: "white",
                            backgroundColor: "#2A5182",
                            borderRadius: "24px",
                            textTransform: "none",
                            marginTop: "0.5rem",
                            marginBottom: "1rem",
                            fontFamily: "Helvetica, sans-serif",
                          }}
                          disabled={isDownloading}
                        >
                          {i18next.t("Print PDF")}
                        </Button>
                      </div>
                    )}
                    {selectedTab === "Details" && (
                      //   details content
                      <div className="details-content">
                        <div className="horizontal-line-list"></div>
                        <div className="pb-2-rem trans-bank-container">
                          <div className="transaction-details-small-container">
                            <Typography
                              component="h1"
                              variant="h5"
                              className="pageTextFontSize"
                              sx={{
                                fontFamily: "Helvetica, sans-serif",
                                fontWeight: "600",
                                color: "#454745",
                                marginBottom: "1rem",
                              }}
                            >
                              {i18next.t("Transaction details")}
                            </Typography>

                            <div className="d-flex ai-center jusifyContentSpaceBetween mb-0-5rem">
                              <Typography
                                component="h1"
                                className="registration0875RemFontSize"
                                variant="h5"
                                sx={{
                                  fontFamily: "Helvetica, sans-serif",
                                  fontWeight: "300",
                                  color: "#454745",
                                }}
                              >
                                {i18next.t("Amount")}
                              </Typography>
                              <Typography
                                component="h1"
                                variant="h5"
                                className="registration0875RemFontSize"
                                sx={{
                                  fontFamily: "Helvetica, sans-serif",
                                  fontWeight: "300",
                                  color: "#454745",
                                }}
                              >
                                {transactionDetails?.amount &&
                                parseFloat(transactionDetails.amount) > 0
                                  ? transactionsService.formatNumber(
                                      transactionDetails.amount
                                    )
                                  : transactionsService.formatNumber(
                                      transactionDetails?.amount
                                    )}{" "}
                                {transactionDetails?.currency?.code}
                              </Typography>
                            </div>

                            {/* converted end block */}
                            <div className="line-dashed"></div>

                            <div className="d-flex ai-center jusifyContentSpaceBetween">
                              <Typography
                                component="h1"
                                variant="h5"
                                className="registration0875RemFontSize"
                                sx={{
                                  fontFamily: "Helvetica, sans-serif",
                                  fontWeight: "300",
                                  color: "#454745",
                                }}
                              >
                                {i18next.t("Transaction number")}
                              </Typography>
                              <Typography
                                component="h1"
                                className="registration0875RemFontSize"
                                variant="h5"
                                sx={{
                                  fontFamily: "Helvetica, sans-serif",
                                  fontWeight: "300",
                                  color: "#454745",
                                }}
                              >
                                #B{transactionDetails?.id}
                              </Typography>
                            </div>

                            {(transactionDetails?.transaction_reference_number ||
                              transactionDetails?.meta?.reference) && (
                              <div style={{ flexWrap: "wrap" }}>
                                <div className="line-dashed"></div>
                                <div className="d-flex ai-center jusifyContentSpaceBetween">
                                  <Typography
                                    component="h1"
                                    variant="h5"
                                    className="registration0875RemFontSize"
                                    sx={{
                                      fontFamily: "Helvetica, sans-serif",
                                      fontWeight: "300",
                                      color: "#454745",
                                    }}
                                  >
                                    {i18next.t("Reference")}
                                  </Typography>
                                  <Typography
                                    component="h1"
                                    variant="h5"
                                    className="registration0875RemFontSize"
                                    sx={{
                                      fontFamily: "Helvetica, sans-serif",
                                      fontWeight: "300",
                                      color: "#454745",
                                    }}
                                  >
                                    {transactionDetails?.transaction_reference_number ||
                                      transactionDetails?.meta?.reference}
                                  </Typography>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </Box>
              </Box>
            )}
          </Container>
        </ThemeProvider>
      </div>
    </div>
  );
}

export default TransactionDetailTransferFee;
