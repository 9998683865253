import React from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import "./FiltersDrawer.scss";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {
  Avatar,
  Button,
  Checkbox,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import LoadingIndicator from "../shared/LoadingIndicator";
import i18next from "src/i18n";

type Anchor = "top" | "left" | "bottom" | "right";

interface FiltersDrawerProps {
  isDrawerOpen: Record<Anchor, boolean>;
  openDrawer: (anchor: Anchor) => void;
  closeDrawer: (anchor: Anchor) => void;
  data?: any[];
  searchValue: string;
  handleSearchChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  page: number;
  totalPages: number;
  loading: boolean;
  handleLoadMore: () => void;
  selectedFilters: any;
  handleSelectedFilters: (filters: any) => void;
  handleCheckbox: (
    event: React.ChangeEvent<HTMLInputElement>,
    object: any,
    type: string
  ) => void;
}

const RecipientsFiltersDrawer: React.FC<FiltersDrawerProps> = ({
  isDrawerOpen,
  openDrawer,
  closeDrawer,
  data,
  searchValue,
  handleSearchChange,
  page,
  totalPages,
  loading,
  handleLoadMore,
  selectedFilters,
  handleSelectedFilters,
  handleCheckbox,
}) => {
  const list = (anchor: Anchor) => {
    return (
      <Box className="mainDrawer">
        <Box style={{ marginTop: "80px", marginBottom: "90px" }}>
          <Box className="drawerHeader">
            <Box className="chooseRecipient">
              <Box
                style={{ cursor: "pointer" }}
                onClick={() => closeDrawer(anchor)}
              >
                <ArrowBackIcon className="searchIconTransactionFilters" />
              </Box>
              <Typography className="filtersText">
                {i18next.t("Choose a recipient")}
              </Typography>
            </Box>
          </Box>
          <Box className="drawerBodyRecipients">
            <TextField
              placeholder={i18next.t("Search")}
              style={{ width: "100%" }}
              value={searchValue}
              onChange={handleSearchChange}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon className="searchIconTransactionFilters" />
                  </InputAdornment>
                ),
                sx: {
                  borderRadius: "10px",
                  height: "48px",
                  fontSize: "16px",
                  "&:-internal-autofill-selected": {
                    backgroundColor: "transparent !important",
                  },
                },
              }}
            ></TextField>
            {loading ? (
              <LoadingIndicator />
            ) : (
              <>
                {data && data.length > 0 ? (
                  <Typography className="subBodiesRecipientsDrawer">
                    {" "}
                    {i18next.t("Recipients")}
                  </Typography>
                ) : (
                  <Typography className="subBodiesRecipientsDrawer">
                    {" "}
                    {i18next.t("Search results")}
                  </Typography>
                )}
                <Box>
                  {data && data.length > 0 ? (
                    data.map((recipient, key) => {
                      const checked =
                        selectedFilters &&
                        selectedFilters.recipients &&
                        selectedFilters.recipients.users &&
                        selectedFilters.recipients.users.includes(recipient.id)
                          ? true
                          : false;

                      return (
                        <Box key={"Recipient" + key}>
                          <Box className="selectableBoxesHover">
                            <Box className="imageDirectionsAndRadio">
                              <Box className="imageDirectionAndText">
                                <Box className="imageDirectionBG">
                                  <Avatar
                                    style={{
                                      width: "48px",
                                      height: "48px",
                                      backgroundColor: "#E5E8E2",
                                    }}
                                  >
                                    {" "}
                                    {recipient.account_holder && (
                                      <>
                                        <span className="spanAvatar">
                                          {recipient.account_holder
                                            .split(" ")
                                            .map((namePart: any, index: any) =>
                                              index < 2
                                                ? namePart
                                                    .charAt(0)
                                                    .toUpperCase()
                                                : ""
                                            )
                                            .join("")}
                                        </span>
                                      </>
                                    )}
                                  </Avatar>
                                </Box>
                                <Box className="nameAndEndingBox">
                                  <Typography className="nameText">
                                    {recipient.account_holder}
                                  </Typography>
                                  <Box className="endingText">
                                    {recipient.iban &&
                                      recipient.type === "iban" && (
                                        <Typography className="allRecipientEndingIn">
                                          {recipient.currency}{" "}
                                          {i18next.t("account ending in")}{" "}
                                          {recipient.iban.slice(-4)}
                                        </Typography>
                                      )}

                                    {recipient.account_number && (
                                      <Typography className="allRecipientEndingIn">
                                        {recipient.currency}{" "}
                                        {i18next.t("account ending in")}{" "}
                                        {recipient.account_number.slice(-4)}
                                      </Typography>
                                    )}
                                  </Box>
                                </Box>
                              </Box>
                              <Checkbox
                                checked={checked}
                                onChange={(event) => {
                                  handleCheckbox(event, recipient, "recipient");
                                }}
                                sx={{
                                  "& .MuiSvgIcon-root": {
                                    fontSize: 22,
                                    color: "#2a5182",
                                  },
                                }}
                              />
                            </Box>
                          </Box>
                        </Box>
                      );
                    })
                  ) : (
                    <Typography className="noRecipientsText">
                      {i18next.t("None of your recipients match")} "
                      {searchValue}".
                    </Typography>
                  )}
                </Box>
              </>
            )}
            {page < totalPages && !loading && (
              <Box>
                <Button
                  className="loadMoreRecipientsBtn"
                  onClick={handleLoadMore}
                >
                  {i18next.t("Load More")}
                </Button>
              </Box>
            )}
          </Box>
        </Box>
      </Box>
    );
  };

  return (
    <div>
      <Typography
        className="subBodiesViewAll"
        onClick={() => openDrawer("right")}
      >
        {i18next.t("View all")}
      </Typography>
      <Drawer
        anchor="right"
        open={isDrawerOpen["right"]}
        onClose={() => closeDrawer("right")}
        sx={{
          "& .MuiPaper-root": {
            boxShadow: "none",
          },
          "& .MuiBackdrop-root": {
            display: "none",
          },
        }}
      >
        {list("right")}
      </Drawer>
    </div>
  );
};

export default RecipientsFiltersDrawer;
