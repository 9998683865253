import React from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Typography, Box } from "@mui/material";
import { useNavigate } from "react-router-dom";
import HeaderLanding from "../components/HeaderLanding/HeaderLanding";
import { useAppSelector } from "src/store";
import FooterLanding from "../components/FooterLanding/FooterLanding";
import HeadlineVvidgetSmall from "../components/HeadlineVvidget/HeadlineVvidgetSmall";
import TitleBackBox from "../components/TitleBackBox/TitleBackBox";
import i18next from "i18next";

const defaultTheme = createTheme();

const OnlineNewsroom: React.FC = () => {
  const { user } = useAppSelector((state) => state.auth);

  const navigate = useNavigate();

  const handleNavigateContact = () => {
    navigate("/contact-us");
  };
  const sendEmail = (email: any) => {
    const mailtoUrl = `mailto:${email}`;
    window.location.href = mailtoUrl;
  };
  const handleOpenNewWindow = (route: any) => {
    window.open(route, "_blank", "noopener,noreferrer");
  };

  return (
    <div>
      <HeaderLanding user={user} />
      <div className="margintTopPagesMainCont">
        <ThemeProvider theme={defaultTheme}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Box className="titleHeadlineSmallBG">
              <Box className="landingPagesMainBox">
                <Box>
                  <TitleBackBox title={i18next.t("Legal Information")} />
                </Box>
                <Box>
                  <HeadlineVvidgetSmall
                    headline={i18next.t(
                      "All relevant information regarding our company on a glance!"
                    )}
                    subtitle={""}
                    smallText={i18next.t("In case of any questions")}
                    buttonText={i18next.t("Contact us")}
                    onClickFunction={handleNavigateContact}
                  />
                </Box>
              </Box>
            </Box>
            <Box className="landingPagesMainBox">
              <Typography
                variant="h3"
                sx={{ marginBottom: "1rem" }}
                className="headlineMiniLandingPages"
              >
                {" "}
                {i18next.t(
                  "This website, the app, social media accounts and online systems are operated by"
                )}
              </Typography>
              <Typography
                variant="body1"
                paragraph
                className="textsLandingPage"
                style={{ marginTop: "1rem" }}
              >
                <span className="textsLandingsmallNoFontSize">
                  Victorum Capital Services Limited
                  <br />
                  6 Tokavaig, Alavik Lodge
                  <br />
                  IV44 8QL, Teangue
                  <br />
                  Great Britain
                </span>
              </Typography>
              <Typography
                variant="body1"
                paragraph
                className="textsLandingPage"
              >
                <span className="textsLandingsmallNoFontSize">
                  {i18next.t("CEO")}
                </span>{" "}
                Osbert Döhl
              </Typography>
              <Typography
                variant="body1"
                paragraph
                className="textsLandingPage"
              >
                <span className="textsLandingsmallNoFontSize">
                  {i18next.t("Registered in Scotland, United Kingdom")}
                </span>
              </Typography>
              <Typography
                variant="body1"
                paragraph
                className="textsLandingPage"
              >
                <span className="textsLandingsmallNoFontSize">
                  {i18next.t("Company number")}
                </span>{" "}
                SC745907
              </Typography>
              <Typography
                variant="body1"
                paragraph
                className="textsLandingPage"
                style={{ marginBottom: "2rem" }}
              >
                <span className="textsLandingsmallNoFontSize">
                  {i18next.t("You can reach our company via")}
                </span>
              </Typography>{" "}
              <Typography
                variant="body1"
                paragraph
                className="textsLandingPage"
              >
                <span className="textsLandingsmallNoFontSize">
                  {i18next.t("Phone")}:
                </span>{" "}
                +1 604-260-0738
              </Typography>
              <Typography
                variant="body1"
                paragraph
                className="textsLandingPage"
                style={{ marginBottom: "2rem" }}
              >
                <span className="textsLandingsmallNoFontSize">
                  {i18next.t("Email")}:
                </span>{" "}
                <span
                  onClick={() => sendEmail("info@vicpayments.com")}
                  style={{
                    textDecoration: "underline",
                    cursor: "pointer",
                  }}
                  className="linksHover"
                >
                  info@vicpayments.com
                </span>
              </Typography>
              <Typography
                variant="body1"
                paragraph
                className="textsLandingPage"
              >
                <span className="textsLandingsmallNoFontSize">
                  {i18next.t("Our company is regulated by")}
                </span>
              </Typography>{" "}
              <Typography
                variant="body1"
                paragraph
                className="textsLandingPage"
              >
                <span className="textsLandingsmallNoFontSize">
                  {i18next.t("FINTRAC Canada")}
                  <br />
                  {i18next.t("112 Donald Trump Street")}
                  <br />
                  {i18next.t("Vancouver, BC V6B 0B6")}
                  <br />
                  <span
                    className="linksHover"
                    onClick={() => {
                      handleOpenNewWindow("https://fintrac-canafe.canada.ca/");
                    }}
                  >
                    www.fintrac.ca
                  </span>
                </span>
              </Typography>
              {/* <Typography
                variant="body1"
                paragraph
                className="textsLandingPage"
              >
                <span className="textsLandingsmallNoFontSize">
                  {i18next.t("Our registration number is")}
                </span>
              </Typography>
              <Typography
                variant="body1"
                paragraph
                className="textsLandingPage"
                style={{ marginBottom: "2rem" }}
              >
                MSB M23153297
              </Typography> */}
              <Typography
                variant="body1"
                paragraph
                className="textsLandingPage"
              >
                <span className="textsLandingsmallNoFontSize">
                  {i18next.t("Our status")}
                </span>
              </Typography>
              <Typography
                variant="body1"
                paragraph
                className="textsLandingPage"
                style={{ marginBottom: "2rem" }}
              >
                {i18next.t(
                  "Registered provider of money service business (MSB), regulated by the FINTRAC"
                )}{" "}
                <span
                  style={{ marginLeft: "5px" }}
                  className="linksHover"
                  onClick={() => {
                    handleOpenNewWindow("https://fintrac-canafe.canada.ca/");
                  }}
                >
                  www.fintrac.ca
                </span>
              </Typography>
              {/* <Typography
                  variant="body1"
                  paragraph
                  className="textsLandingPage"
                >
                  For journalists and press services, we recommend to contact
                  our dedicated media & press desk - simply{" "}
                  <span
                    style={{ marginLeft: "5px" }}
                    className="linksHover"
                    onClick={() => navigate("/media-press")}
                  >
                    click here
                  </span>{" "}
                  for press photos, interview material and much more or dial 001
                  987897897897897 to get in touch with our media & press
                  department!
                </Typography> */}
            </Box>
          </Box>
        </ThemeProvider>
      </div>
      <Box sx={{ marginTop: "4rem" }}>
        <FooterLanding />
      </Box>
    </div>
  );
};

export default OnlineNewsroom;
