import { ethers } from "ethers";
import { toast } from "react-toastify";
import "./AmountStep.scss";
import {
  Autocomplete,
  Box,
  Button,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import "./ReviewStep.scss";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import bitcoin from "bitcoinjs-lib";
import { response } from "express";
import api from "src/store/interceptors/api";
import networkConstant from "src/constants/network.constant";
import i18next from "i18next";
import BackNextBtns from "../shared/BackNextBtns/BackNextBtns";

interface Props {
  setStep: (value: number) => void;
  step: number;
  currencies: any;
  setQuest: (value: any) => void;
  navigate: ReturnType<typeof useNavigate>;
}

function Crypto({ currencies, step, setStep, setQuest, navigate }: Props) {
  // USDT contract address and ABI test
  const usdtContractAddress = "0x55d398326f99059fF775485246999027B3197955";

  const usdtContractABI = [
    {
      inputs: [],
      payable: false,
      stateMutability: "nonpayable",
      type: "constructor",
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: true,
          internalType: "address",
          name: "owner",
          type: "address",
        },
        {
          indexed: true,
          internalType: "address",
          name: "spender",
          type: "address",
        },
        {
          indexed: false,
          internalType: "uint256",
          name: "value",
          type: "uint256",
        },
      ],
      name: "Approval",
      type: "event",
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: true,
          internalType: "address",
          name: "previousOwner",
          type: "address",
        },
        {
          indexed: true,
          internalType: "address",
          name: "newOwner",
          type: "address",
        },
      ],
      name: "OwnershipTransferred",
      type: "event",
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: true,
          internalType: "address",
          name: "from",
          type: "address",
        },
        { indexed: true, internalType: "address", name: "to", type: "address" },
        {
          indexed: false,
          internalType: "uint256",
          name: "value",
          type: "uint256",
        },
      ],
      name: "Transfer",
      type: "event",
    },
    {
      constant: true,
      inputs: [],
      name: "_decimals",
      outputs: [{ internalType: "uint8", name: "", type: "uint8" }],
      payable: false,
      stateMutability: "view",
      type: "function",
    },
    {
      constant: true,
      inputs: [],
      name: "_name",
      outputs: [{ internalType: "string", name: "", type: "string" }],
      payable: false,
      stateMutability: "view",
      type: "function",
    },
    {
      constant: true,
      inputs: [],
      name: "_symbol",
      outputs: [{ internalType: "string", name: "", type: "string" }],
      payable: false,
      stateMutability: "view",
      type: "function",
    },
    {
      constant: true,
      inputs: [
        { internalType: "address", name: "owner", type: "address" },
        { internalType: "address", name: "spender", type: "address" },
      ],
      name: "allowance",
      outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
      payable: false,
      stateMutability: "view",
      type: "function",
    },
    {
      constant: false,
      inputs: [
        { internalType: "address", name: "spender", type: "address" },
        { internalType: "uint256", name: "amount", type: "uint256" },
      ],
      name: "approve",
      outputs: [{ internalType: "bool", name: "", type: "bool" }],
      payable: false,
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      constant: true,
      inputs: [{ internalType: "address", name: "account", type: "address" }],
      name: "balanceOf",
      outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
      payable: false,
      stateMutability: "view",
      type: "function",
    },
    {
      constant: false,
      inputs: [{ internalType: "uint256", name: "amount", type: "uint256" }],
      name: "burn",
      outputs: [{ internalType: "bool", name: "", type: "bool" }],
      payable: false,
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      constant: true,
      inputs: [],
      name: "decimals",
      outputs: [{ internalType: "uint8", name: "", type: "uint8" }],
      payable: false,
      stateMutability: "view",
      type: "function",
    },
    {
      constant: false,
      inputs: [
        { internalType: "address", name: "spender", type: "address" },
        { internalType: "uint256", name: "subtractedValue", type: "uint256" },
      ],
      name: "decreaseAllowance",
      outputs: [{ internalType: "bool", name: "", type: "bool" }],
      payable: false,
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      constant: true,
      inputs: [],
      name: "getOwner",
      outputs: [{ internalType: "address", name: "", type: "address" }],
      payable: false,
      stateMutability: "view",
      type: "function",
    },
    {
      constant: false,
      inputs: [
        { internalType: "address", name: "spender", type: "address" },
        { internalType: "uint256", name: "addedValue", type: "uint256" },
      ],
      name: "increaseAllowance",
      outputs: [{ internalType: "bool", name: "", type: "bool" }],
      payable: false,
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      constant: false,
      inputs: [{ internalType: "uint256", name: "amount", type: "uint256" }],
      name: "mint",
      outputs: [{ internalType: "bool", name: "", type: "bool" }],
      payable: false,
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      constant: true,
      inputs: [],
      name: "name",
      outputs: [{ internalType: "string", name: "", type: "string" }],
      payable: false,
      stateMutability: "view",
      type: "function",
    },
    {
      constant: true,
      inputs: [],
      name: "owner",
      outputs: [{ internalType: "address", name: "", type: "address" }],
      payable: false,
      stateMutability: "view",
      type: "function",
    },
    {
      constant: false,
      inputs: [],
      name: "renounceOwnership",
      outputs: [],
      payable: false,
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      constant: true,
      inputs: [],
      name: "symbol",
      outputs: [{ internalType: "string", name: "", type: "string" }],
      payable: false,
      stateMutability: "view",
      type: "function",
    },
    {
      constant: true,
      inputs: [],
      name: "totalSupply",
      outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
      payable: false,
      stateMutability: "view",
      type: "function",
    },
    {
      constant: false,
      inputs: [
        { internalType: "address", name: "recipient", type: "address" },
        { internalType: "uint256", name: "amount", type: "uint256" },
      ],
      name: "transfer",
      outputs: [{ internalType: "bool", name: "", type: "bool" }],
      payable: false,
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      constant: false,
      inputs: [
        { internalType: "address", name: "sender", type: "address" },
        { internalType: "address", name: "recipient", type: "address" },
        { internalType: "uint256", name: "amount", type: "uint256" },
      ],
      name: "transferFrom",
      outputs: [{ internalType: "bool", name: "", type: "bool" }],
      payable: false,
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      constant: false,
      inputs: [{ internalType: "address", name: "newOwner", type: "address" }],
      name: "transferOwnership",
      outputs: [],
      payable: false,
      stateMutability: "nonpayable",
      type: "function",
    },
  ];
  const requiredChainId = networkConstant.networkIdHex.ethMainnet;

  // Function to handle BTC transactions
  const sendBTC = async (address: any, amount: any) => {
    // Create BTC transaction using bitcoinjs-lib
    // Sign and broadcast the transaction
  };

  // Function to handle ETH/USDT transactions
  const sendETHOrUSDT = async (address: any, amount: any, isUSDT = false) => {
    let chainId = requiredChainId;

    if (isUSDT) {
      chainId = networkConstant.networkIdHex.bscMainnet;
    }

    if (!window.ethereum) {
      toast.info(i18next.t("No crypto wallet found. Please install it."));
      return;
    }

    await window.ethereum.request({ method: "eth_requestAccounts" });
    await window.ethereum.request({
      method: "wallet_switchEthereumChain",
      params: [{ chainId: chainId }],
    });

    const provider = new ethers.BrowserProvider(window.ethereum);

    const signer = await provider.getSigner();
    let balance = null;
    ethers.getAddress(address);

    // Load USDT contract
    const usdtContract = new ethers.Contract(
      usdtContractAddress,
      usdtContractABI,
      signer
    );

    if (isUSDT) {
      balance = await usdtContract.balanceOf(signer);
      amount = ethers.parseUnits(amount, 18); // 6 is the decimal precision of USDT
    } else {
      balance = await provider.getBalance(signer);
      amount = ethers.parseEther(amount);
    }
    //console.log(balance,amount);
    if (balance > amount) {
    } else {
      toast.error(i18next.t("Insufficient funds!"));
      return;
    }

    let tx;
    if (isUSDT) {
      tx = await usdtContract.transfer(signer, address, amount);
    } else {
      // console.log('amount',amount);
      tx = await signer.sendTransaction({
        to: address,
        value: amount,
      });
    }

    tx.wait();

    if (tx) {
      const data = {
        blockchain_transaction: tx,
        currency: cryptoCurrencyToSend,
        amount: amount.toString(),
      };

      await api
        .post("/create-crypto-transaction", data)
        .then((res: any) => {
          toast.success(i18next.t("Transaction completed"));
          setTimeout(() => {
            navigate("/transactions-list");
          }, 2000);
        })
        .catch((error: any) => {
          toast.error(i18next.t("Something went wrong"));
        });
    }
  };

  const cryptoCurrencies: any = ["BTC", "ETH", "USDT"];
  const [cryptoCurrencyToSend, setCryptoCurrencyToSend] = useState(
    cryptoCurrencies[0]
  );
  const [cryptoAmount, setCryptoAmount] = useState("");
  const handleCryptoAmountChange = (event: any) => {
    setCryptoAmountError({ error: false, errorMessage: "" });
    setCryptoAmount(event.target.value);
  };

  const [addressError, setAddressError] = useState({
    error: false,
    errorMessage: "",
  });

  const [cryptoAmountError, setCryptoAmountError] = useState({
    error: false,
    errorMessage: "",
  });

  const startPayment = async ({
    cryptoAmount,
    addr,
  }: {
    cryptoAmount: any;
    addr: any;
  }) => {
    try {
      if (cryptoCurrencyToSend === "BTC") {
        await sendBTC(addr, cryptoAmount);
      } else if (cryptoCurrencyToSend === "ETH") {
        await sendETHOrUSDT(addr, cryptoAmount);
      } else if (cryptoCurrencyToSend === "USDT") {
        await sendETHOrUSDT(addr, cryptoAmount, true);
      } else {
        toast.error(i18next.t("Crypto currency not supported!"));
        return;
      }
    } catch (err: any) {
      if (err.code === "INSUFFICIENT_FUNDS") {
        toast.error(i18next.t("Insufficient funds!"));
      } else if (err.code === "INVALID_ARGUMENT") {
        toast.error(i18next.t("Invalid wallet address!"));
      } else if (err.code === "ACTION_REJECTED") {
        toast.error(i18next.t("User has rejected the transaction!"));
      } else if (err.code === 4001) {
        toast.error(i18next.t("User has rejected the request!"));
      } else {
        toast.error(
          i18next.t("An unexpected error occurred! Please try again later.")
        );
      }
      return;
    }
  };

  const handleCryptoCurrencyChange = (event: any, value: any) => {
    setCryptoCurrencyToSend(value);
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    const data = new FormData(e.target);

    if (!data.get("addr")) {
      setAddressError({
        error: true,
        errorMessage: i18next.t("Recipient address is required."),
      });
      return;
    } else {
      setAddressError({ error: false, errorMessage: "" });
    }

    if (!data.get("cryptoAmount")) {
      setCryptoAmountError({
        error: true,
        errorMessage: i18next.t("Amount is required."),
      });
      return;
    } else {
      setCryptoAmountError({ error: false, errorMessage: "" });
    }

    await startPayment({
      cryptoAmount: data.get("cryptoAmount"),
      addr: data.get("addr"),
    });
  };

  return (
    <form className="m-4" onSubmit={handleSubmit}>
      <div className="reviewFooter">
        <Typography className="labelsInternational">
          {i18next.t("Wallet address of the recipient")}
        </Typography>
        <TextField
          placeholder={i18next.t("Recipient Address")}
          name="addr"
          style={{ width: "100%" }}
          type="text"
          variant="outlined"
          helperText={
            addressError.error ? (
              <span style={{ color: "red" }}>{addressError.errorMessage}</span>
            ) : (
              ""
            )
          }
          onChange={(e) => {
            if (e.target.value) {
              setAddressError({ error: false, errorMessage: "" });
            }
          }}
          sx={{
            "& .MuiOutlinedInput-root": {
              paddingRight: "0px!important",
            },
          }}
          InputProps={{
            sx: {
              borderRadius: "10px",
              minHeight: "48px",
              "@media(max-width: 600px)": {
                fontSize: "14px",
              },
            },
          }}
        />

        {/* crypto new start */}
        <Typography className="labelsInternational">
          {i18next.t("Crypto amount to send")}
        </Typography>
        <TextField
          style={{ width: "100%" }}
          type="text"
          name="cryptoAmount"
          value={cryptoAmount}
          onChange={handleCryptoAmountChange}
          helperText={
            cryptoAmountError.error ? (
              <span style={{ color: "red" }}>
                {cryptoAmountError.errorMessage}
              </span>
            ) : (
              ""
            )
          }
          variant="outlined"
          sx={{
            "& .MuiOutlinedInput-root": {
              paddingRight: "0px!important",
            },
          }}
          InputProps={{
            sx: {
              borderRadius: "10px",
              minHeight: "48px",
              "@media(max-width: 600px)": {
                fontSize: "14px",
              },
            },
            endAdornment: (
              <InputAdornment position="end">
                <Autocomplete
                  id="currency-select"
                  sx={{
                    width: "9rem",
                    borderRadius: "10px",
                    "& .MuiOutlinedInput-root": {
                      border: "none",
                    },
                    "& .Mui-focused .MuiOutlinedInput-root": {
                      border: "none",
                    },
                    "& .MuiOutlinedInput-notchedOutline": {
                      border: "none",
                    },
                  }}
                  options={cryptoCurrencies}
                  value={cryptoCurrencyToSend}
                  onChange={handleCryptoCurrencyChange}
                  renderOption={(props: any, option: any) => (
                    <Box
                      component="li"
                      {...props}
                      className="d-flex ai-center mt-1-rem cp"
                    >
                      <p className="normal-text" style={{ marginLeft: 10 }}>
                        {option}
                      </p>
                    </Box>
                  )}
                  renderInput={(params) => <TextField {...params} />}
                  clearIcon={null}
                />
              </InputAdornment>
            ),
          }}
        />
        {/* crypt new end */}

        <div className="d-flex w-100 ai-center jusifyContentSpaceBetween mt-8">
          <BackNextBtns
            backText={i18next.t("Cancel")}
            nextText={i18next.t("Send")}
            // isNextDisabled={}
            backType="button"
            nextType="submit"
            onBackClick={() => navigate(-1)}
            // onNextClick={(e: any) => formik.handleSubmit(e)}
          />
        </div>
      </div>
    </form>
  );
}

export default Crypto;
