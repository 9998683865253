import { useEffect } from "react";
import {
  hooks,
  walletConnectV2,
} from "src/services/Connectors/walletConnectV2";
import { URI_AVAILABLE } from "@web3-react/walletconnect-v2";
import { Button, Typography } from "@mui/material";
import CurrencyBitcoinIcon from "@mui/icons-material/CurrencyBitcoin";
import { updateUserProfile } from "src/store/profile/profile_slice";
import { useAppDispatch } from "src/store";
import i18next from "src/i18n";

/*
const WALLETCONNECT_DEEPLINK_CHOICE = "WALLETCONNECT_DEEPLINK_CHOICE";
const WALLETCONNECT_SESSION = "wc@2:client:0.3//session";
const WEB3_CONNECT_CACHED_PROVIDER = "WEB3_CONNECT_CACHED_PROVIDER";

function clearWeb3ConnectorCache() {
  window.localStorage.removeItem(WALLETCONNECT_DEEPLINK_CHOICE);
  window.localStorage.removeItem(WALLETCONNECT_SESSION);
  window.localStorage.removeItem(WEB3_CONNECT_CACHED_PROVIDER);
  window.localStorage.removeItem("binance-http://localhost:3000");
  window.localStorage.removeItem("ethereum-http://localhost:3000");
  window.localStorage.removeItem("WCM_VERSION");
  window.localStorage.removeItem("trust:cache:timestamp");
  window.localStorage.removeItem("isWhitelist");
  window.localStorage.removeItem("wc@2:ethereum_provider:");
}
*/

const { useAccounts, useIsActive } = hooks;

function WalletConnect({ user }: any) {
  const dispatch = useAppDispatch();
  const accounts = useAccounts();
  const isActive = useIsActive();

  const handleInit = async () => {
    await walletConnectV2.connectEagerly().catch((error) => {
      // console.debug("Failed to connect eagerly to walletconnect", error);
    });
    await walletConnectV2.events.on(URI_AVAILABLE, (uri: string) => {
      // console.log(`uri: ${uri}`);
    });
  };

  const updateUser = async (account: any) => {
    if (user && user.id) {
      let valuesForBe: any = {};
      valuesForBe.public_address = account;
      valuesForBe.id = user.id ?? null;
      dispatch(updateUserProfile(valuesForBe));
    }
  };

  const checkUser = async (account: any) => {
    if (user) {
      if (!user.public_address || account !== user.public_address) {
        updateUser(account);
      }
    }
  };
  const connectWallet = async () => {
    try {
      await walletConnectV2.resetState();
      await walletConnectV2.activate();
    } catch (exception) {}
  };

  useEffect(() => {
    handleInit();
  }, []);

  useEffect(() => {
    if (accounts && accounts.length > 0) {
      checkUser(accounts[0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accounts, isActive]);

  return (
    <div>
      {accounts && accounts.length > 0 ? (
        <div>
          <Typography
            component="h1"
            variant="h5"
            sx={{
              fontFamily: "Helvetica, sans-serif",
              fontSize: "0.875rem",
              fontWeight: ".875rem",
              color: "#454745",
            }}
          >
           {i18next.t("Wallet connected")} {accounts[0]}
          </Typography>
        </div>
      ) : (
        <Button className="walletBtn" onClick={() => connectWallet()}>
          <CurrencyBitcoinIcon />
          {i18next.t("Connect Wallet")}
        </Button>
      )}
    </div>
  );
}

export default WalletConnect;
