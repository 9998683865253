import {
  Avatar,
  Box,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";
import "../../../pages/Chat/Chat.scss";
import SearchIcon from "@mui/icons-material/Search";
import { SingleUserChatContainer } from "./SingleUserChatContainer";
import LoadingIndicator from "../LoadingIndicator";
import i18next from "i18next";

interface UsersChatContainerProps {
  handleSearchChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  searchValue: String | null;
  conversations: any;
  formatTimestamp: (timestamp: string) => string;
  onUserItemClick: (conversationId: any, user: any) => void;
  searchLoading: boolean;
  loadMore: boolean;
  user: any;
  selectedConversation: any;
  selectedConversationUser: any;
  handleScroll: () => void;
}

export const UsersChatContainer: React.FC<UsersChatContainerProps> = ({
  handleSearchChange,
  searchValue,
  loadMore,
  conversations,
  formatTimestamp,
  onUserItemClick,
  searchLoading,
  user,
  selectedConversation,
  selectedConversationUser,
  handleScroll,
}) => {
  return (
    <Box className="usersMain">
      <Box className="usersMainBox">
        <Box className="usersHeadMain">
          {user && user.image !== null ? (
            <Avatar
              src={user?.image?.file_url}
              alt={`${user.first_name} ${user.last_name}`}
              sx={{ width: "48px", height: "48px", objectFit: "cover" }}
            ></Avatar>
          ) : (
            <Avatar sx={{ width: "48px", height: "48px" }}></Avatar>
          )}
          <Typography className="myUsername">Admin</Typography>
        </Box>
        <Box>
          <TextField
            type="text"
            autoComplete="new-password"
            placeholder={i18next.t("Search")}
            name="chatSearch"
            className="searchField"
            onChange={handleSearchChange}
            value={searchValue}
            autoFocus={false}
            InputProps={{
              autoComplete: "new-password",
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon className="searchIconTransactionFilters" />
                </InputAdornment>
              ),
              sx: {
                borderRadius: "8px",
                height: "38px",
                fontSize: "16px",
                "&:-internal-autofill-selected": {
                  backgroundColor: "transparent !important",
                },
              },
            }}
          ></TextField>
        </Box>
        {searchLoading ? (
          <LoadingIndicator />
        ) : (
          <>
            {conversations.length === 0 ? (
              <Box className="noSearchResultsFoundBox">
                <Typography>{i18next.t("No search results found")}</Typography>
              </Box>
            ) : (
              <SingleUserChatContainer
                handleScroll={handleScroll}
                conversations={conversations}
                formatTimestamp={formatTimestamp}
                onUserItemClick={onUserItemClick}
                selectedConversationId={selectedConversation}
                selectedConversationUser={selectedConversationUser}
                loadMore={loadMore}
              />
            )}
          </>
        )}
      </Box>
    </Box>
  );
};
export default UsersChatContainer;
