import { Typography, Box } from "@mui/material";

const TextNumbersVvidget = ({ number, title, text }: any) => {
  return (
    <>
      <Box>
        <Box sx={{ display: "flex", gap: "2rem" }}>
          <Typography
            variant="h3"
            sx={{
              marginBottom: "1rem",
            }}
            className="headlineMiniLandingPages"
          >
            {number}
          </Typography>
          <Typography
            variant="h3"
            sx={{
              marginBottom: "1rem",
            }}
            className="headlineMiniLandingPages"
          >
            {title}
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "8px",
            marginBottom: "2rem",
          }}
        >
          <Typography variant="body1" paragraph className="textsLandingPage">
            {text}
          </Typography>
        </Box>
      </Box>
    </>
  );
};

export default TextNumbersVvidget;
