import React from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Typography, Box, Divider } from "@mui/material";
import HeaderLanding from "../components/HeaderLanding/HeaderLanding";
import { useAppSelector } from "src/store";
import FooterLanding from "../components/FooterLanding/FooterLanding";
import TitleBackBox from "../components/TitleBackBox/TitleBackBox";
import HeadlineVvidgetSmall from "../components/HeadlineVvidget/HeadlineVvidgetSmall";
import { useNavigate } from "react-router-dom";
import ImageWithText from "../components/ImageWithText/ImageWithText";
import paymentsImage from "../../../assets/images/instantPaymentsImg.jpg";
import TitleTextVvidget from "../components/TitleTextVvidget/TitleTextVvidget";
import i18next from "i18next";

const offerContent = [
  {
    title: i18next.t("Your safety as first priority"),
    text: i18next.t(
      "We take data security extremely seriously. Your vicpayments.com account has more protection tools than most similar platforms."
    ),
  },
  {
    title: i18next.t("Receive Payments from anywhere"),
    text: i18next.t(
      "Global payment solution, with the ability to receive payments across every major currency into a single IBAN. Real-time payment rails and FX capabilities ensure swift, secure transactions wherever you are receiving them from."
    ),
  },
  {
    title: i18next.t("Unlock real-time payment rails"),
    text: i18next.t(
      "Make payments faster than ever Never wait for a payment to reach your account again, with our access to real-time payment rails across Europe."
    ),
  },
  {
    title: i18next.t("Harness every major currency"),
    text:
      i18next.t("All in one place") +
      " " +
      i18next.t(
        "With global payment rails, regulatory coverage and multi-currency accounts, you can now receive more payments from more places"
      ),
  },
  {
    title: i18next.t("Unlock real-time payment rails"),
    text: i18next.t(
      "Make payments faster than ever Never wait for a payment to reach your account again, with our access to real-time payment rails across Europe."
    ),
  },
];

const defaultTheme = createTheme();
const InstantPayments: React.FC = () => {
  const { user } = useAppSelector((state) => state.auth);
  const navigate = useNavigate();
  const navigateToPages = (event: any, route: any) => {
    navigate(route);
  };

  return (
    <div>
      <HeaderLanding user={user} />
      <div className="margintTopPagesMainCont">
        <ThemeProvider theme={defaultTheme}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              minHeight: "400px",
            }}
          >
            <Box className="titleHeadlineSmallBG">
              <Box className="landingPagesMainBox">
                <Box>
                  <TitleBackBox title={i18next.t("Payments")} />
                </Box>
                <Box>
                  <HeadlineVvidgetSmall
                    headline={i18next.t(
                      "Are you ready for the next financial revolution?"
                    )}
                    subtitle={i18next.t(
                      "Seamlessly Connect, Transact, and Secure Your Funds Globally with vicpayments.com"
                    )}
                    smallText={i18next.t("Join customers all over the world")}
                    buttonText={i18next.t("Create a free account")}
                    onClickFunction={(event: any) =>
                      navigateToPages(event, "/registration")
                    }
                  />
                </Box>
              </Box>
            </Box>
            <Box className="landingPagesMainBox">
              <Box sx={{ marginTop: "0rem" }}>
                <ImageWithText
                  backgroundImage={paymentsImage}
                  noiseImage="/img/payments-noise.png"
                  title={i18next.t("Send payments worldwide")}
                  text={i18next.t(
                    "Get paid by employers or affiliate programs instantly and spend anywhere in the world with our debit card (availability depending on region). Instantly distribute funds in your freelancer team."
                  )}
                />
              </Box>
              <Box>
                <TitleTextVvidget
                  title={i18next.t("Instant internal transfers")}
                  text={i18next.t(
                    "USD, EUR and other currencies. Load and withdraw via a credit or debit card, bank transfers, local transfers, digital currencies and more."
                  )}
                />
              </Box>
              <Divider
                sx={{
                  background: "#fff",
                  marginTop: "10rem",
                  marginBottom: "2rem",
                }}
              />
              <Box>
                <Typography
                  variant="h3"
                  sx={{
                    marginBottom: "1rem",
                    textAlign: "center",
                  }}
                  className="headlineLandingPages"
                >
                  {" "}
                  {i18next.t("Cards to spend worldwide!")}
                </Typography>
                <Typography
                  variant="body1"
                  sx={{
                    textAlign: "center",
                  }}
                  paragraph
                  className="textsLandingPage"
                >
                  {i18next.t(
                    "Get an instantly loaded plastic or virtual card for hassle-free worldwide ATM and POS payments"
                  )}
                </Typography>
                <Box className="getYourCardImagePaymentsPage"></Box>
              </Box>


              <Box sx={{ marginTop: "3rem" }} className="boxColumnContainer">
                {offerContent.map((item: any, key: any) => {
                  const extraClass =
                    key % 2 === 0 ? "boxColumnItemLeft" : "boxColumnItemRight";

                  let bgSet = "gradientBgSet1";
                  if (key === 0) {
                    bgSet = "gradientBgSet1";
                  } else if (key === 1) {
                    bgSet = "gradientBgSet2";
                  } else if (key === 2) {
                    bgSet = "gradientBgSet3";
                  } else if (key === 3) {
                    bgSet = "gradientBgSet1";
                  } else if (key === 4) {
                    bgSet = "gradientBgSet2";
                  } else {
                    bgSet = "gradientBgSet3";
                  }

                  return (
                    <Box
                      className={
                        "boxColumnItem gradientBgSet " +
                        extraClass +
                        " " +
                        bgSet
                      }
                    >
                      <Box>
                        <Typography className="textsLandingPageHeading">
                          {item.title}
                        </Typography>
                        <Typography
                          paragraph
                          className="boxColumnContainerText"
                          sx={{ color: "#fff !important" }}
                        >
                          {item.text}
                        </Typography>
                      </Box>
                    </Box>
                  );
                })}
              </Box>
            </Box>
          </Box>
        </ThemeProvider>
      </div>
      <Box sx={{ marginTop: "4rem" }}>
        <FooterLanding />
      </Box>
    </div>
  );
};

export default InstantPayments;
