import { useEffect, useState } from "react";
import {
  Button,
  Box,
  Select,
  MenuItem,
  Typography,
  FormControl,
  InputLabel,
  Grid,
} from "@mui/material";
import api from "src/store/interceptors/api";
import ConfirmationDialog from "src/components/shared/ConfirmationDialog/ConfirmationDialog";
import i18next from "i18next";
const statusOptions = [
  { name: "blocked", label: "Blocked" },
  { name: "completed", label: "Completed / Unblocked" },
];
function getStatus(status: string) {
  const obj = statusOptions.find((option) => option.name === status);
  if (obj) return obj;
  const response = { name: status, label: status };
  return response;
}
const formatDate = (sectionName: string) => {
  // Check if sectionName is a valid date
  const date = new Date(sectionName);
  if (!isNaN(date.getTime())) {
    // It's a valid date, format it
    const currentLanguage = i18next.language || "en-US";
    return new Intl.DateTimeFormat(currentLanguage, {
      month: "long",
      day: "numeric",
      minute: "2-digit",
      hour: "2-digit",
    }).format(date);
  } else {
    // Not a valid date, return as is
    return sectionName;
  }
};
interface Props {
  transactionDetails: any;
  transactionId: any;
  loadingPage: boolean;
}
function BalanceAdminActions({
  transactionDetails,
  transactionId,
  loadingPage,
}: Props) {
  const [transactionStatus, setTransactionStatus] = useState();
  const [currentStatus, setCurrentStatus] = useState<any>(null);

  useEffect(() => {
    const status = getStatus(transactionDetails.status);
    setCurrentStatus(status);
  }, [transactionDetails]);
  // conf dialog start
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const handleDialogClose = () => {
    setIsDialogOpen(false);
  };

  const handleDialogConfirm = async (e: any) => {
    setIsDialogOpen(false);
    try {
      const payload = {
        status: transactionStatus,
      };
      const res = await api.post(
        `/transaction-balance-action/${transactionDetails.id}`,
        payload
      );
      if (res) {
        window.location.reload();
      } else {
        alert(i18next.t("Please try again!"));
      }
    } catch (error) {}
    //handleRemoveFileClick(e);
  };

  const handleStatusChange = async () => {
    if (
      transactionStatus &&
      currentStatus &&
      currentStatus.name &&
      transactionDetails &&
      transactionDetails.id &&
      transactionDetails.status &&
      transactionDetails.status !== transactionStatus
    ) {
      if (currentStatus.name === "completed") {
        alert(i18next.t("Transaction was completed, status can't be changed!"));
      } else {
        setIsDialogOpen(true);
      }
    } else {
      alert(i18next.t("Please check the status!"));
    }
  };

  const handleTransactionStatusChange = (event: any) => {
    setTransactionStatus(event.target.value);
  };

  return (
    <div>
      <ConfirmationDialog
        open={isDialogOpen}
        handleClose={handleDialogClose}
        handleConfirm={handleDialogConfirm}
        content={{
          title: i18next.t("Sure you'd like to change the status?"),
          body: i18next.t("Note that this action cannot be undone."),
          cancelText: i18next.t("Cancel"),
          confirmText: i18next.t("Change"),
        }}
        className="custom-dialog"
      />
      <Box
        sx={{
          marginBottom: "4rem",
        }}
      >
        <Box sx={{ mt: 2 }}>
          <div className="personal-box">
            <div className="transaction-item-details">
              <div className="d-flex ai-center jc-center">
                <Typography
                  className="your-details-text d-flex jc-start FontSize12"
                  component="h1"
                  variant="h5"
                  sx={{
                    fontFamily: "Helvetica, sans-serif",
                    fontWeight: "400",
                    textAlign: "start",
                  }}
                >
                  {i18next.t("Admin actions")}
                </Typography>
              </div>
            </div>
            <Box sx={{ padding: "16px" }}>
              <Box
                columnGap={1}
                sx={{ display: "flex", justifyContent: "flex-start" }}
              >
                <Typography
                  className="transaction-detail-label oneRemFontSize"
                  sx={{
                    fontWeight: "400",
                    textAlign: "start",
                  }}
                >
                  {i18next.t("Created at")}
                </Typography>
                <Typography
                  className="transaction-detail-value oneRemFontSize"
                  sx={{
                    fontWeight: "400",
                    textAlign: "start",
                  }}
                >
                  {transactionDetails && transactionDetails.created_at
                    ? formatDate(transactionDetails.created_at)
                    : ""}
                </Typography>
              </Box>

              <Box
                columnGap={1}
                sx={{ display: "flex", justifyContent: "flex-start" }}
              >
                <Typography
                  className="transaction-detail-label oneRemFontSize"
                  sx={{
                    fontWeight: "400",
                    textAlign: "start",
                  }}
                >
                  {i18next.t("Updated at")}
                </Typography>
                <Typography
                  className="transaction-detail-value oneRemFontSize"
                  sx={{
                    fontWeight: "400",
                    textAlign: "start",
                  }}
                >
                  {transactionDetails && transactionDetails.updated_at
                    ? formatDate(transactionDetails.updated_at)
                    : ""}
                </Typography>
              </Box>

              <Box
                columnGap={1}
                sx={{ display: "flex", justifyContent: "flex-start" }}
              >
                <Typography
                  className="transaction-detail-label oneRemFontSize"
                  sx={{
                    fontWeight: "400",
                    textAlign: "start",
                  }}
                >
                  {i18next.t("Current status")}
                </Typography>
                <Typography
                  className="transaction-detail-value oneRemFontSize"
                  sx={{
                    fontWeight: "400",
                    textAlign: "start",
                  }}
                >
                  {i18next.t(
                    currentStatus && currentStatus.label
                      ? currentStatus.label
                      : ""
                  )}
                </Typography>
              </Box>

              {transactionDetails &&
                transactionDetails.balance_reduced &&
                transactionDetails.balance_reduced === 1 && (
                  <Box
                    columnGap={1}
                    sx={{ display: "flex", justifyContent: "flex-start" }}
                  >
                    <Typography
                      className="transaction-detail-label oneRemFontSize"
                      sx={{
                        fontWeight: "400",
                        textAlign: "start",
                      }}
                    >
                      {i18next.t("Balance added")}:
                    </Typography>
                    <Typography
                      className="transaction-detail-value oneRemFontSize"
                      sx={{
                        fontWeight: "400",
                        textAlign: "start",
                      }}
                    >
                      {i18next.t("Yes")}
                    </Typography>
                  </Box>
                )}
            </Box>

            <Grid
              container
              spacing={2}
              sx={{ padding: 1 }}
              justifyItems={"center"}
              alignItems={"center"}
              justifyContent={"center"}
            >
              <Grid item xs={6} md={8}>
                <FormControl fullWidth>
                  <InputLabel>{i18next.t("Status")}</InputLabel>
                  <Select
                    fullWidth
                    label={i18next.t("Status")}
                    defaultValue={transactionStatus}
                    value={transactionStatus}
                    onChange={handleTransactionStatusChange}
                    sx={{
                      borderRadius: "10px",
                      height: "48px",
                      "& .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input":
                        {
                          padding: "16.5px 14px 16.5px 14px",
                        },
                      "@media (max-width: 600px)": {
                        height: "42px",
                      },
                    }}
                    MenuProps={{
                      anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "left",
                      },
                      transformOrigin: {
                        vertical: "top",
                        horizontal: "left",
                      },
                    }}
                  >
                    {statusOptions.map(
                      (statusItem: any, statusItemIndex: any) => {
                        return (
                          <MenuItem
                            key={"status" + statusItem.name + statusItemIndex}
                            selected={
                              statusItem.name === transactionDetails.status
                            }
                            value={statusItem.name}
                          >
                            <Box sx={{ display: "flex", alignItems: "center" }}>
                              {i18next.t(statusItem.label)}
                            </Box>
                          </MenuItem>
                        );
                      }
                    )}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={6} md={4}>
                <Button
                  fullWidth
                  onClick={handleStatusChange}
                  variant="text"
                  disabled={currentStatus && currentStatus === "completed"}
                  className="actionButton oneRemFontSize btnsLoginHeight"
                >
                  {i18next.t("Change status")}
                </Button>
              </Grid>
            </Grid>
          </div>
        </Box>
      </Box>
    </div>
  );
}

export default BalanceAdminActions;
