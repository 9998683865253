import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import "./Chat.scss";
import Nav from "src/components/layout/nav/Nav";
import { Box, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useEffect, useState } from "react";
import { useAppSelector } from "src/store";
import api from "src/store/interceptors/api";
import LoadingIndicator from "src/components/shared/LoadingIndicator";
import ChatContainerUser from "src/components/shared/Chat/ChatContainerUser";
import i18next from "i18next";

const defaultTheme = createTheme();

export default function Chat() {
  const { user } = useAppSelector((state) => state.auth);
  const navigate = useNavigate();

  const [conversations, setConversations] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);

  const getAllConversations = async () => {
    let urlConversations = null;
    setLoading(true);
    urlConversations = `/conversations`;
    try {
      const response = await api.get(urlConversations);
      if (response && response.data && response.data.data) {
        setConversations(response.data.data);
        setLoading(false);
      }
    } catch (err) {
      console.error("Error get convos: ", err);
      setLoading(false);
    }
  };

  const handleNavigate = () => {
    navigate("/home");
  };

  useEffect(() => {
    getAllConversations();
  }, []);

  return (
    <div>
      <Nav step={0} isStepperVisible={false} isProfileSetVisible={true} />
      <div className="content-container-profile">
        <ThemeProvider theme={defaultTheme}>
          <Container component="main">
            <Box className="transactionTitleAndIcon">
              <p
                className="back-button-icon circle-around-text-smaller helvetica mr-1-rem cp"
                onClick={handleNavigate}
              >
                <ArrowBackIcon className="muiIcons"/>
              </p>

              <Typography
                className="your-details-text d-flex jc-start pageTitleFontSize"
                component="h1"
                variant="h5"
                sx={{
                  fontFamily: "Helvetica, sans-serif",
                  fontWeight: "600",
                  textAlign: "start",
                }}
              >
                {i18next.t("Support")}
              </Typography>
            </Box>
            {loading ? (
              <LoadingIndicator />
            ) : (
              <Box className="ChatMain">
                <ChatContainerUser user={user} conversations={conversations} />
              </Box>
            )}
          </Container>
        </ThemeProvider>
      </div>
    </div>
  );
}
