import accountPersonalImage from "src/assets/images/house-medium@2x.webp";
import accountBusinessImage from "src/assets/images/briefcase-medium@2x.webp";
import { BUSINESS_ACCOUNT, PERSONAL_ACCOUNT } from "../shared/constants";
import i18next from "i18next";
import { Box } from "@mui/material";

interface Props {
  handleSelectingAccountType: (value: string) => void;
}

function FourthStep({ handleSelectingAccountType }: Props) {
  return (
    <Box className="initial-screen d-flex ai-center jc-center fx-column ta-center">
      <h3
        className="fs-1-625 fw-400 loginTitleFontSize"
        style={{
          fontFamily: "Helvetica, sans-serif",
          fontWeight: 400,
          textAlign: "start",
        }}
      >
        {i18next.t("What kind of account would you like to open today?")}
      </h3>
      <h3
        className="font-size-14 mt-16 fw-300 registration0875RemFontSize"
        style={{ fontFamily: "Helvetica, sans-serif" }}
      >
        {i18next.t("You can add another account later on, too.")}
      </h3>

      <Box className="boxes-container mt-2-5rem">
        <Box
          className="box-account"
          onClick={() => handleSelectingAccountType(PERSONAL_ACCOUNT)}
        >
          <Box className="box-account-content-image">
            <img src={accountPersonalImage} alt="r" width="100" />
          </Box>
          <Box className="box-account-content">
            <h3
              className="fs-1-375 fw-400 box-account-content-heading registration1375RemFontSize"
              style={{
                fontFamily: "Helvetica, sans-serif",
                fontWeight: 400,
              }}
            >
              {i18next.t("Personal account")}
            </h3>
            <p
              className="font-size-14 box-account-content-description fw-300"
              style={{
                fontFamily: "Helvetica, sans-serif",
                lineHeight: "1.5",
              }}
            >
              {i18next.t("Send, spend, and receive around the world for less.")}
            </p>
          </Box>
        </Box>
        <Box
          className="box-account ml-1-5rem"
          style={{ margin: "auto" }}
          onClick={() => handleSelectingAccountType(BUSINESS_ACCOUNT)}
        >
          <Box className="box-account-content-image">
            <img src={accountBusinessImage} alt="r" width="100" />
          </Box>

          <Box className="box-account-content">
            <h3
              className="fs-1-375 fw-400 box-account-content-heading registration1375RemFontSize"
              style={{
                fontFamily: "Helvetica, sans-serif",
                fontWeight: 400,
              }}
            >
              {i18next.t("Business account")}
            </h3>
            <p
              className="font-size-14 box-account-content-description fw-300"
              style={{
                fontFamily: "Helvetica, sans-serif",
                lineHeight: "1.5",
              }}
            >
              {i18next.t("Do business or freelance work internationally.")}
            </p>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default FourthStep;
