import * as React from "react";
import Box from "@mui/material/Box";
import "./Nav.scss";
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import imageUrl from "src/assets/images/logoPay.png";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import {
  Avatar,
  Button,
  IconButton,
  LinearProgress,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material";
import { logout } from "src/store/login/login_slice";
import { useAppDispatch, useAppSelector } from "src/store";
import UserIcon from "src/assets/images/user.png";
import HamMenu from "./HamMenu";
import { capitalizeWords } from "src/utils/helpers";
import NotificationBadge from "src/components/shared/NotificationBadge/NotificationBadge";
import AdminPanelSettingsOutlinedIcon from "@mui/icons-material/AdminPanelSettingsOutlined";
import { ReactComponent as BranchesIcon } from "../../../assets/images/branch.svg";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import PeopleIcon from "@mui/icons-material/People";
import LogoutOutlinedIcon from "@mui/icons-material/LogoutOutlined";
import ChatBadge from "src/components/shared/Chat/ChatBadge";
import QuestionAnswerOutlinedIcon from "@mui/icons-material/QuestionAnswerOutlined";
import MenuRoundedIcon from "@mui/icons-material/MenuRounded";
import i18next from "src/i18n";
import { getUser } from "src/store/persist/persist";

export default function HorizontalLinearStepper({
  step,
  isStepperVisible,
  isProfileSetVisible,
}: {
  step: number;
  isStepperVisible: any;
  isProfileSetVisible?: boolean;
}) {
  const { user } = useAppSelector((state) => state.auth);
  const { userProfile } = useAppSelector((state) => state.userProfile);
  const [userObject, setUserObject] = React.useState(user);
  const [progress, setprogress] = React.useState(0);
  const navigate = useNavigate();
  const location = useLocation();
  const handleRedirectToAdmin = () => {
    navigate("/admin");
    handleClose();
  };

  const handleRedirectToBranches = () => {
    navigate("/admin/branches");
    handleClose();
  };

  const handleRedirectToProfile = () => {
    navigate("/profile");
    handleClose();
  };

  const handleRedirectTo = (route: string) => {
    navigate("/" + route);
    handleClose();
  };

  const [isOpenMobile, setIsOpenMobile] = React.useState(false);

  // Profile menu
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const dispatch = useAppDispatch();

  const handleClickMobile = () => {
    setIsOpenMobile(!isOpenMobile);
  };
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const logoTargetUrl = () => {
    const specialPaths = [
      "/privacy-policy",
      "/declaration",
      "/copyright",
      "/login",
      "/registration",
      "/home",
    ];
    return specialPaths.includes(location.pathname) ? "/" : "/home";
  };

  const handleLogoutClick = () => {
    // Perform logout logic
    dispatch(logout());
    navigate("/");
    setIsOpenMobile(false);
  };

  const getUserDisplayName = (userObject: any) => {
    if (!userObject) return "";

    if (userObject.account_type === "business") {
      return userObject.company_name
        ? capitalizeWords(userObject.company_name)
        : `${capitalizeWords(userObject.first_name)} ${capitalizeWords(
            userObject.last_name
          )}`;
    } else {
      return `${capitalizeWords(userObject.first_name)} ${capitalizeWords(
        userObject.last_name
      )}`;
    }
  };

  useEffect(() => {
    setprogress(16.67 * step);
  }, [step]);

  // scroll start
  const [navClass, setNavClass] = React.useState("");

  const SCROLL_THRESHOLD_ADD = 80;
  const SCROLL_THRESHOLD_REMOVE = 10;
  let lastScrollY = 0;

  const handleScroll = () => {
    const currentScrollY = window.scrollY;

    if (currentScrollY >= SCROLL_THRESHOLD_ADD) {
      setNavClass("smaller-nav");
    } else if (currentScrollY <= SCROLL_THRESHOLD_REMOVE) {
      setNavClass("");
    }

    lastScrollY = currentScrollY;
  };
  window.addEventListener("scroll", handleScroll);
  // scroll end

  useEffect(() => {
    setUserObject(getUser());
  }, [userProfile]);

  return !isOpenMobile ? (
    <div
      className={`nav-container ${navClass} mb-big ${
        isStepperVisible ? "isStepper" : ""
      }`}
    >
      <Box
        sx={{
          maxWidth: "82.5rem",
          display: "flex",
          width: "100%",
          flexDirection: "row",
          justifyContent: "space-between",
          margin: "0 auto",
        }}
      >
        {isProfileSetVisible === false || isProfileSetVisible === undefined ? (
          <></>
        ) : (
          <Box className="menuIconBox">
            <IconButton
              className="profile-containerr-mobile"
              id="basic-button"
              onClick={handleClickMobile}
            >
              <div className="image-container">
                <MenuRoundedIcon className="headerIconsSize" />
              </div>
            </IconButton>
          </Box>
        )}
        <div className="logo-box" onClick={() => navigate(logoTargetUrl())}>
          <img src={imageUrl} alt="r" className="logoWidth" />
        </div>
        {isStepperVisible ? (
          <Box sx={{ width: "34rem", marginTop: "2rem" }}>
            <Box sx={{ width: "100%" }}>
              <LinearProgress variant="determinate" value={progress} />
              <ul className="progress-bar-list">
                <li className={`${step === 0 ? "bold-progress-bar" : ""}`}>
                  <Typography>{i18next.t("Email")}</Typography>
                </li>

                <li className={`${step === 1 ? "bold-progress-bar" : ""}`}>
                  <Typography>{i18next.t("Confirmation")}</Typography>
                </li>

                <li className={`${step === 2 ? "bold-progress-bar" : ""}`}>
                  <Typography>{i18next.t("Verify Confirmation")}</Typography>
                </li>

                <li className={`${step === 3 ? "bold-progress-bar" : ""}`}>
                  <Typography>{i18next.t("Account Type")}</Typography>
                </li>

                <li
                  className={`${
                    step === 4 || step === 5 ? "bold-progress-bar" : ""
                  }`}
                >
                  <Typography>{i18next.t("Personal Details")}</Typography>
                </li>
                <li className={`${step === 6 ? "bold-progress-bar" : ""}`}>
                  <Typography>{i18next.t("Password")}</Typography>
                </li>
              </ul>
            </Box>
          </Box>
        ) : null}

        {isProfileSetVisible === false || isProfileSetVisible === undefined ? (
          // <ClearIcon className="logo-box x-icon" height="24" width="24" />
          <></>
        ) : (
          <>
            <div className="d-flex ai-center">
              <div className="not-hide marginRightHeaderIcons">
                <ChatBadge user={user} />
              </div>
              <div className="not-hide marginRightHeaderIcons">
                <NotificationBadge user={user} />
              </div>
              <Button
                className="profile-container"
                id="basic-button-container"
                aria-controls={open ? "basic-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
                onClick={handleClick}
                sx={{ textTransform: "none" }}
              >
                {/* <div className="profile-image">
                  <img
                    src={user?.image?.file_url ? user.image.file_url: UserIcon}
                    alt="Profile"
                    className="circular-image"
                  />
                </div> */}
                <Avatar
                  src={
                    userObject?.image?.file_url
                      ? userObject?.image.file_url
                      : UserIcon
                  }
                  alt={getUserDisplayName(userObject)}
                  sx={{ width: 48, height: 48 }} // Adjust size as needed
                />

                {userObject && (
                  <p
                    className="very-small-text blue-text ml-8 user-name"
                    style={{
                      fontFamily: "Helvetica, sans-serif",
                      fontWeight: 100,
                    }}
                  >
                    {getUserDisplayName(userObject)}
                  </p>
                )}
                {open ? (
                  <KeyboardArrowUpIcon sx={{ color: "#2a5182;" }} />
                ) : (
                  <KeyboardArrowDownIcon sx={{ color: "#2a5182;" }} />
                )}
              </Button>

              <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                  "aria-labelledby": "basic-button",
                }}
              >
                {(user?.role === "Admin" || user?.role === "Employee") && (
                  <MenuItem
                    className="menuItems"
                    onClick={handleRedirectToAdmin}
                  >
                    <AdminPanelSettingsOutlinedIcon /> {i18next.t("Admin")}
                  </MenuItem>
                )}
                {user?.role === "Admin" && (
                  <MenuItem
                    className="menuItems"
                    onClick={handleRedirectToBranches}
                  >
                    <BranchesIcon className="branchesIconHover" />{" "}
                    {i18next.t("Branches")}
                  </MenuItem>
                )}
                <MenuItem
                  className="menuItems"
                  onClick={handleRedirectToProfile}
                >
                  <PersonOutlinedIcon />
                  {i18next.t("Profile")}
                </MenuItem>

                {user?.role !== "Employee" && (
                  <MenuItem
                    className="menuItems"
                    onClick={() => handleRedirectTo("banking")}
                  >
                    <AccountBalanceIcon />
                    {i18next.t("Banking")}
                  </MenuItem>
                )}
                {/* {user?.role !== "Employee" && (
                  <MenuItem
                    className="menuItems"
                    onClick={() => handleRedirectTo("account-details")}
                  >
                    <AccountBalanceIcon />
                    {i18next.t("Account details")}
                  </MenuItem>
                )} */}

                {user?.role !== "Employee" && (
                  <MenuItem
                    className="menuItems"
                    onClick={() => handleRedirectTo("recipients")}
                  >
                    <PeopleIcon />
                    {i18next.t("Recipients")}
                  </MenuItem>
                )}

                {/* {user?.role !== "Employee" && (
                  <MenuItem
                    className="menuItems"
                    onClick={() =>
                      handleRedirectTo(
                        "home?action=add-currency&t=" + Date.now()
                      )
                    }
                  >
                    <CreditCardIcon />
                    {i18next.t("Add currency")}
                  </MenuItem>
                )} */}

                <MenuItem
                  className="menuItems"
                  onClick={() => handleRedirectTo("settings")}
                >
                  <SettingsOutlinedIcon />
                  {i18next.t("Settings")}
                </MenuItem>

                <MenuItem
                  className="menuItems"
                  onClick={() =>
                    handleRedirectTo(
                      user?.role === "Admin" || user?.role === "Employee"
                        ? "admin/chat"
                        : "chat"
                    )
                  }
                >
                  <QuestionAnswerOutlinedIcon />
                  {i18next.t("Support")}
                </MenuItem>

                <MenuItem className="menuItems" onClick={handleLogoutClick}>
                  <LogoutOutlinedIcon />
                  {i18next.t("Logout")}
                </MenuItem>
              </Menu>
            </div>
          </>
        )}
      </Box>
    </div>
  ) : (
    <HamMenu
      user={user}
      userObject={userObject}
      getUserDisplayName={getUserDisplayName}
      profile={handleRedirectToProfile}
      redirect={(path: string) => handleRedirectTo(path)}
      logout={handleLogoutClick}
      admin={handleRedirectToAdmin}
      branches={handleRedirectToBranches}
      isOpen={isOpenMobile}
      closeMenu={handleClickMobile}
    />
  );
}
