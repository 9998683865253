import * as React from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import "./EnterpaymentNav.scss";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import imageUrl from "src/assets/images/logoPay.png";
import i18next from "i18next";

const steps = ["Currency", "Recipient", "Amount", "Review", "Pay"];

export default function HorizontalLinearStepper({
  step,
  isStepperVisible,
  isProfileSetVisible,
}: {
  step: number;
  isStepperVisible: any;
  isProfileSetVisible?: boolean;
}) {
  const navigate = useNavigate();

  useEffect(() => {
    // setIsStepperVisibleVar(isStepperVisible);
    setActiveStep(step);
  }, [step, isStepperVisible]);

  const [activeStep, setActiveStep] = React.useState(step);
  const [skipped, setSkipped] = React.useState(new Set<number>());

  const isStepSkipped = (step: number) => {
    return skipped.has(step);
  };

  function redirectToHome() {
    navigate("/home");
  }

  return (
    <>
      <div className="nav-container mb-big enterpaymentMainHeaderBox">
        <div className="logo-box" onClick={redirectToHome}>
          <img src={imageUrl} alt="r" className="logoEnterpaymentHeader" />
        </div>

        <Box sx={{ width: "30rem" }}>
          {isStepperVisible ? (
            <Stepper activeStep={activeStep}>
              {steps.map((label, index) => {
                const stepProps: { completed?: boolean } = {};
                const labelProps: {
                  optional?: React.ReactNode;
                } = {};
                if (isStepSkipped(index)) {
                  stepProps.completed = false;
                }
                return (
                  <Step key={label} {...stepProps}>
                    <StepLabel {...labelProps}>
                      {" "}
                      <span className="stepperText">{i18next.t(label)}</span>
                    </StepLabel>
                  </Step>
                );
              })}
            </Stepper>
          ) : null}
        </Box>
      </div>
    </>
  );
}
