import React, { useState, useEffect } from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import "./manageCookiesCard.scss";
import { Switch, Typography } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";

const CookieModal = () => {
  const [open, setOpen] = useState(false);
  const [isManagingCookies, setIsManagingCookies] = useState(false);
  const navigate = useNavigate();

  const [checkedCookie, setCheckedCookie] = React.useState(false);

  const handleSwitchCookieChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setCheckedCookie(event.target.checked);
  };

  useEffect(() => {
    const cookieConsent = Cookies.get("cookieBannerNewClosed");
    if (cookieConsent !== "true") {
      setOpen(true);
    } else {
      const analyticsConsent = Cookies.get(
        "isAnalyticsTargetingCookiesEnabled"
      );
      setCheckedCookie(analyticsConsent === "true");
    }
  }, []);

  const goBack = () => {
    setIsManagingCookies(false);
  };

  const redirectToCookiesPolicy = () => {
    navigate("/cokies-policy");
  };

  const handleAccept = () => {
    Cookies.set("cookieBannerNewClosed", "true");
    Cookies.set("isAnalyticsTargetingCookiesEnabled", "true");
    setOpen(false);
  };

  const saveCookieSettings = () => {
    Cookies.set("cookieBannerNewClosed", "true");
    Cookies.set("isAnalyticsTargetingCookiesEnabled", checkedCookie.toString());
    setOpen(false);
  };

  const handleDecline = () => {
    Cookies.set("cookieBannerNewClosed", "true");
    Cookies.set("isAnalyticsTargetingCookiesEnabled", "false");
    setOpen(false);
  };

  const handleManageCookies = () => {
    setIsManagingCookies(true);
  };

  const dialogSx = {
    "& .MuiDialog-paper": {
      position: "fixed",
      bottom: 95,
      right: 32,
      maxWidth: "400px",
      borderRadius: "15px",
      margin: 0,
      maxHeight: isManagingCookies ? "500px" : "500px",
      backgroundColor: "rgb(241, 242, 244)",
      "@media screen and (max-width: 450px)": {
        right: "initial",
      },
      "@media screen and (min-width: 1400px)": {
        maxWidth: "420px",
      },
    },
  };

  return (
    <Dialog
      open={open}
      aria-labelledby="cookie-dialog-title"
      sx={dialogSx}
      className="custom-dialog-scroll"
      disableScrollLock={true}
      BackdropProps={{
        style: {
          backgroundColor: "rgba(0, 0, 0, 0.4)",
        },
      }}
    >
      {!isManagingCookies ? (
        <>
          <DialogTitle id="cookie-dialog-title">
            <Typography
              className="oneRemFontSize"
              component="h1"
              variant="h5"
              sx={{
                fontFamily: "Helvetica, sans-serif",
                fontWeight: "500",
                color: "#191C1F",
              }}
            >
              Cookies give you a personalised experience
            </Typography>
          </DialogTitle>
          <DialogContent>
            <Typography
              component="h1"
              variant="h5"
              className="registration0875RemFontSize"
              sx={{
                fontFamily: "Helvetica, sans-serif",
                fontWeight: "300",
                color: "#191C1F",
              }}
            >
              We’re not talking about the crunchy, tasty kind. These cookies
              help us keep our website safe, give you a better experience and
              show more relevant ads. We won’t turn them on unless you accept.
              Want to know more or adjust your preferences?
            </Typography>
          </DialogContent>
          <div>
            <div className="allow-and-reject-buutons-continaer">
              <Button
                type="button"
                fullWidth
                variant="contained"
                className="btnsLoginHeight oneRemFontSize"
                sx={{
                  color: "white",
                  backgroundColor: "#191C1F",
                  borderRadius: "24px",
                  textTransform: "none",
                  fontFamily: "Helvetica, sans-serif",
                  "&:hover": {
                    backgroundColor: "#27262b",
                    color: "white",
                  },
                }}
                onClick={handleAccept}
              >
                Allow all cookies
              </Button>

              <Button
                type="button"
                fullWidth
                variant="contained"
                className="btnsLoginHeight oneRemFontSize"
                sx={{
                  color: "white",
                  backgroundColor: "#191C1F",
                  borderRadius: "24px",
                  textTransform: "none",
                  fontFamily: "Helvetica, sans-serif",
                  "&:hover": {
                    backgroundColor: "#27262b",
                    color: "white",
                  },
                }}
                onClick={handleDecline}
              >
                Reject all cookies
              </Button>
            </div>
            <div className="manage-cookies">
              <Button
                style={{ width: "87%", marginBottom: "24px" }}
                type="submit"
                className="btnsLoginHeight oneRemFontSize"
                variant="contained"
                sx={{
                  color: "#000000",
                  backgroundColor: "transparent",
                  borderRadius: "24px",
                  boxShadow: "none",
                  border: "2px solid #000000",
                  fontWeight: 600,
                  textTransform: "none",
                  fontFamily: "Helvetica, sans-serif",
                  "&:hover": {
                    backgroundColor: "transparent",
                  },
                }}
                onClick={handleManageCookies}
                disabled={false}
              >
                Manage cookies
              </Button>
            </div>
          </div>
        </>
      ) : (
        <>
          <DialogTitle id="cookie-dialog-title">
            <div className="d-flex ai-center">
              <ArrowBackIcon onClick={goBack} className="mr-8 cp" />
              <Typography
                component="h1"
                variant="h5"
                className="oneRemFontSize"
                sx={{
                  fontFamily: "Helvetica, sans-serif",
                  fontWeight: "500",
                  color: "#191C1F",
                }}
              >
                Cookie preferences
              </Typography>
            </div>
          </DialogTitle>
          <DialogContent>
            <Typography
              component="h1"
              variant="h5"
              className="registration0875RemFontSize"
              sx={{
                fontFamily: "Helvetica, sans-serif",
                fontWeight: "100",
                color: "#191C1F",
                marginBottom: "2.5rem",
              }}
            >
              We’re not talking about the crunchy, tasty kind. These cookies
              help us keep our website safe, give you a better experience and
              show more relevant ads. We won’t turn them on unless you accept.
              Want to know more or adjust your preferences?
            </Typography>

            <Typography
              component="h1"
              variant="h5"
              className="registration0875RemFontSize"
              sx={{
                fontFamily: "Helvetica, sans-serif",
                fontWeight: "600",
                color: "#191C1F",
                marginBottom: "12px",
              }}
            >
              Essential cookies
            </Typography>

            <Typography
              component="h1"
              variant="h5"
              className="registration0875RemFontSize"
              sx={{
                fontFamily: "Helvetica, sans-serif",
                fontWeight: "100",
                color: "#191C1F",
                marginBottom: "2.5rem",
              }}
            >
              Essential cookies help you to access the website and each of its
              web pages and sub domains, by enabling basic functions like cookie
              consent. They cannot be disabled.
            </Typography>

            <div
              className="d-flex ai-center jusifyContentSpaceBetween"
              style={{ marginBottom: "12px" }}
            >
              <Typography
                component="h1"
                variant="h5"
                className="registration0875RemFontSize"
                sx={{
                  fontFamily: "Helvetica, sans-serif",
                  fontWeight: "600",
                  color: "#191C1F",
                }}
              >
                Analytics and advertising cookies
              </Typography>

              <Switch
                checked={checkedCookie}
                onChange={handleSwitchCookieChange}
                inputProps={{ "aria-label": "controlled" }}
              />
            </div>

            <Typography
              component="h1"
              variant="h5"
              className="registration0875RemFontSize"
              sx={{
                fontFamily: "Helvetica, sans-serif",
                fontWeight: "100",
                color: "#191C1F",
              }}
            >
              These allow us to recognise and count the number of visitors to
              our website, and see how visitors browse our website, so we can
              improve it where necessary. These also allow us to see what pages
              and links you have visited so we can provide more relevant ads. We
              may share this information with other organisations, such as
              Google, Facebook and LinkedIn, for the same purpose.
            </Typography>

            <Typography
              onClick={redirectToCookiesPolicy}
              component="h1"
              variant="h5"
              className="cp registration0875RemFontSize"
              sx={{
                fontFamily: "Helvetica, sans-serif",
                fontWeight: "400",
                color: "#191C1F",
                marginBottom: "8px",
                marginTop: "2rem",
                textDecoration: "underline",
              }}
            >
              Read our full cookie policy
            </Typography>
            <div className="">
              <Button
                type="button"
                variant="contained"
                className="btnsLoginHeight oneRemFontSize"
                sx={{
                  color: "white",
                  backgroundColor: "#191C1F",
                  borderRadius: "24px",
                  textTransform: "none",
                  fontFamily: "Helvetica, sans-serif",
                  marginTop: "1.5rem",
                  "&:hover": {
                    backgroundColor: "#27262b",
                    color: "white",
                  },
                }}
                onClick={saveCookieSettings}
              >
                Save cookie settings
              </Button>
            </div>
          </DialogContent>
        </>
      )}
    </Dialog>
  );
};

export default CookieModal;
