import { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { useNavigate, useParams } from "react-router-dom";
import Nav from "src/components/layout/nav/Nav";
import * as Yup from "yup";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import recipientsService from "src/store/recipients/recipients_services";
import { useAppSelector } from "src/store";
import LoadingIndicator from "src/components/shared/LoadingIndicator";
import api from "src/store/interceptors/api";
import DynamicForm from "./Components/DynamicForm";
import i18next from "i18next";

const defaultTheme = createTheme();

const createValidation = (key: string) => {
  let currentObject: any = {};
  if (key.includes(".")) {
    const keys = key.split(".");
    keys.forEach((keyItem: any, keyIndex: any) => {
      if (!currentObject[keyItem]) {
        currentObject[keyItem] =
          keyIndex === keys.length - 1
            ? ""
            : Yup.string().required(i18next.t("This field is required"));
      }
      currentObject = currentObject[keyItem];
    });
  } else {
    currentObject[key] = Yup.string().required(
      i18next.t("This field is required")
    );
  }
  return currentObject;
};

export default function EditRecipient() {
  const [methods, setMethods] = useState<any>();
  const { successCRUD } = useAppSelector((state) => state.recipient);
  const { recipientId }: any = useParams();
  const [recipientDetails, setRecipientDetails] = useState<any>(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const navigate = useNavigate();

  const redirectToList = () => {
    navigate("/recipients");
  };

  const handleNavigate = () => {
    navigate(-1);
  };

  const handleGetRequirements = async (
    currency: any,
    accountTypeDb: any,
    accountDataDb: any
  ) => {
    try {
      if (currency) {
        let url = "/account-requirements?sourceCurrency=";

        url += currency;
        url += "&targetCurrency=" + currency;
        url += "&sourceAmount=" + 1;
        url += "&targetAmount=" + 1;
        if (accountDataDb.id) url += "&recipientId=" + accountDataDb.id;

        const res = await api.get(url);
        if (res && res.data) {
          const resMethods = res.data;

          let aryMethods: any = [];

          resMethods.forEach((resItem: any) => {
            if (
              resItem.title !== "Email" &&
              resItem.fields &&
              resItem.type === accountTypeDb
            ) {
              let tmpFormFields: any = {};
              let aryValidation: any = {};

              resItem.fields.forEach((resField: any) => {
                if (resField.group) {
                  resField.group.forEach((groupItem: any) => {
                    if (groupItem.key) {
                      // Check if the key includes dots (nested field)
                      if (groupItem.key.includes(".")) {
                        //console.log('groupItem', groupItem.key);
                        const keys = groupItem.key.split(".");
                        if (
                          keys &&
                          keys[0] &&
                          keys[1] &&
                          accountDataDb?.meta?.details[keys[0]] &&
                          accountDataDb?.meta?.details[keys[0]][keys[1]]
                        ) {
                          if (!tmpFormFields[keys[0]])
                            tmpFormFields[keys[0]] = {};
                          if (!tmpFormFields[keys[0]][keys[1]])
                            tmpFormFields[keys[0]][keys[1]] = {};

                          tmpFormFields[keys[0]][keys[1]] =
                            accountDataDb.meta.details[keys[0]][keys[1]];
                        }
                      } else {
                        let valueAdd = "";
                        if (accountDataDb?.meta[groupItem.key])
                          valueAdd = accountDataDb?.meta[groupItem.key];
                        else if (accountDataDb?.meta?.details[groupItem.key]) {
                          valueAdd =
                            accountDataDb?.meta?.details[groupItem.key];
                        }
                        if (!groupItem.key.includes("country")) {
                          tmpFormFields[groupItem.key] = valueAdd;
                        } else {
                          tmpFormFields[groupItem.key] = valueAdd;
                        }
                      }

                      if (
                        groupItem.valuesAllowed &&
                        groupItem.valuesAllowed.length > 0
                      ) {
                        if (!groupItem.key.includes("country")) {
                          if (
                            groupItem.valuesAllowed[0] &&
                            groupItem.valuesAllowed[0].key
                          ) {
                            tmpFormFields[groupItem.key] =
                              groupItem.valuesAllowed[0].key;
                          }
                        }
                      }
                    }

                    if (
                      groupItem.key &&
                      groupItem.validationRegexp &&
                      groupItem.required
                    ) {
                      const validationSchema = createValidation(groupItem.key);
                      aryValidation = { ...aryValidation, ...validationSchema };
                    } else {
                      if (groupItem.required && groupItem.key) {
                        const validationSchema = createValidation(
                          groupItem.key
                        );
                        aryValidation = {
                          ...aryValidation,
                          ...validationSchema,
                        };
                      }
                    }
                  });
                }
              });

              setMethods({
                ...resItem,
                fieldsForm: tmpFormFields,
                validation: aryValidation,
              });
            }
          });
        }

        //
        // setMethods(res)
      }
    } catch (error) {
      console.error("Error updating notes: ", error);
    }
  };
  const fetchRecipientDetails = async () => {
    try {
      if (!recipientId) return;
      setLoading(true);
      const res = await recipientsService.getRecipientsById(recipientId);
      setRecipientDetails(res.data);

      if (res && res.data && res.data.currency)
        handleGetRequirements(res.data.currency, res.data.type, res.data);

      setLoading(false);
    } catch (error: any) {
      setLoading(false);
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        setError(error.response.data.message);
      } else {
        let errorMsg = "An unknown error occurred";
        setError(errorMsg);
      }
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      await fetchRecipientDetails();
    };

    fetchData();
  }, [recipientId]);

  return (
    <>
      <div>
        <Nav step={0} isStepperVisible={false} />
        <div className="content-container">
          <ThemeProvider theme={defaultTheme}>
            <Container component="main">
              {loading ? (
                <LoadingIndicator />
              ) : (
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <div className="d-flex ai-center">
                    <p
                      className="back-button-icon circle-around-text-smaller helvetica mr-1-rem cp"
                      onClick={handleNavigate}
                    >
                      <ArrowBackIcon className="muiIcons" />
                    </p>
                    <Typography
                      className="ta-center pageTitleFontSize"
                      component="h1"
                      variant="h5"
                      sx={{
                        fontFamily: "Helvetica, sans-serif",
                        fontWeight: "600",
                        color: "#0e0f0c",
                        maxWidth: "500px",
                        whiteSpace: "nowrap",
                        textOverflow: "ellipsis",
                        overflow: "hidden",
                      }}
                    >
                      {i18next.t("Edit")}{" "}
                      {recipientDetails?.account_holder ?? "recipient's"}{" "}
                      {i18next.t("details")}
                    </Typography>
                  </div>

                  <Box sx={{ mt: "2rem" }}>
                    {methods && recipientDetails && (
                      <DynamicForm
                        methodItem={methods}
                        handleNavigate={redirectToList}
                        recipientDetails={recipientDetails}
                      />
                    )}
                  </Box>
                </Box>
              )}
            </Container>
          </ThemeProvider>
        </div>
      </div>
    </>
  );
}
