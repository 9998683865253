import { Box, Divider, Typography } from "@mui/material";
import SingleUserItem from "./SingleUserItem";
import "../UserList.scss";

interface Props {
  data: any;
  redirectPage?: any;
  hideNavigation?: any;
  onSpecificSelectUser: (userId: number) => void;
  style?: any;
  type?: any;
  selectedUsers?: number[];
  total?: any;
  hideAddButton?: boolean;
}

function UserListBulkMessages({
  data,
  type,
  onSpecificSelectUser,
  selectedUsers,
}: Props) {
  return (
    <Box className="recentBox">
      {data && data.length === 0 ? (
        <Typography className="noDataText">
          {type && type === "accountTransfer" ? "" : "No users found"}
        </Typography>
      ) : (
        <Box>
          <div className="d-flex ai-center gap-24">
            <Typography className="recentText">List of users</Typography>
          </div>
          <Divider style={{ marginBottom: 8 }} />
          <Box className="allRecipientMain">
            {data.map((item: any, index: number) => (
              <SingleUserItem
                key={"usercard" + index}
                data={item}
                type={type}
                index={index}
                isSelected={selectedUsers?.includes(item.id)}
                onSpecificSelectUser={onSpecificSelectUser}
              />
            ))}
          </Box>
        </Box>
      )}
    </Box>
  );
}

export default UserListBulkMessages;
