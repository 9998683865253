import * as React from "react";
import { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import "./EditProfile.scss";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import Nav from "src/components/layout/nav/Nav";
import { useAppDispatch, useAppSelector } from "src/store";
import {
  MenuItem,
  Select,
  SelectChangeEvent,
  useMediaQuery,
} from "@mui/material";
import CountrySelect from "src/components/shared/CountrySelect/CountrySelect";
import { useFormik } from "formik";
import * as Yup from "yup";
import { updateUserProfile } from "src/store/profile/profile_slice";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import api from "src/store/interceptors/api";
import { toast } from "react-toastify";
import i18next from "i18next";
const defaultTheme = createTheme();

export default function EditPtofile() {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const { userId } = useParams();
  const [userState, setUserState] = useState<any>(null);
  const smallScreen = useMediaQuery("(max-width: 500px)");

  const { user } = useAppSelector((state) => state.auth);

  const getUserProfile = async () => {
    try {
      const response = await api.get(`me`);
      if (response && response.data && response.data.data) {
        setUserState(response.data.data);
      }
      if (
        response &&
        response.data &&
        response.data.data &&
        response.data.data.role === "Employee"
      ) {
        navigate("/admin");
      }
    } catch (exception) {}
  };

  useEffect(() => {
    if (location.state && location.state.user) {
      setUserState(location.state.user);
    } else {
      getUserProfile();
    }
  }, [location.state]);

  const formatBirthday = (
    dobDay: string,
    dobMonth: string,
    dobYear: string
  ) => {
    if (!dobDay || !dobMonth || !dobYear) {
      return null;
    }
    const paddedDay = dobDay.padStart(2, "0");
    const paddedMonth = dobMonth.padStart(2, "0");
    const birthday = `${dobYear}-${paddedMonth}-${paddedDay}`;
    return birthday;
  };

  const handleNavigate = () => {
    if (location.state && location.state.user) {
      // Extracting userId from the URL
      navigate(-1);
    } else {
      navigate("/profile");
    }
  };

  const convertDateToISOFormat = (dateString: any) => {
    const months: any = {
      Jan: "01",
      Feb: "02",
      Mar: "03",
      Apr: "04",
      May: "05",
      Jun: "06",
      Jul: "07",
      Aug: "08",
      Sep: "09",
      Oct: "10",
      Nov: "11",
      Dec: "12",
    };

    const parts = dateString.split("-");

    if (parts.length === 3) {
      const year = parts[0];
      const month = months[parts[1]];
      const day = parts[2].padStart(2, "0");

      if (!month) {
        return null; // or throw an error
      }

      return `${year}-${month}-${day}`;
    } else {
      return null; // or throw an error
    }
  };

  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      dobDay: "",
      dobMonth: "",
      dobYear: "",
      phoneNumber: "",
      country: "",
      homeAddress: "",
      city: "",
      postalCode: "",
      email: "",
    },
    onSubmit: async (values: any) => {
      const formattedBirthday = formatBirthday(
        values.dobDay.toString(),
        month.toString(),
        values.dobYear.toString()
      );
      const convertedDate = convertDateToISOFormat(formattedBirthday);

      let valuesForBe: any = {};
      valuesForBe.first_name = values.firstName.toString();
      valuesForBe.last_name = values.lastName.toString();
      valuesForBe.birthday = convertedDate;
      valuesForBe.phone = values.phoneNumber.toString();
      valuesForBe.country = values.country.toString();
      valuesForBe.address = values.homeAddress.toString();
      valuesForBe.city = values.city.toString();
      valuesForBe.postal_code = values.postalCode.toString();
      valuesForBe.id = userState?.id ?? null;
      valuesForBe.email = values.email.toString();
      if (location?.state?.user) {
        // this means that we are admin and that we are updating another user profile
        await api
          .put(`/users/${valuesForBe.id}`, valuesForBe)
          .then((res: any) => {
            toast.success(i18next.t("Successfully updated personal details"));
            setTimeout(() => {
              navigate(-1);
            }, 1000);
          })
          .catch((error: any) => {
            toast.error(i18next.t("Something went wrong"));
            console.log(error);
          });
        return;
      } else {
        // this means that we are updating our own profile
        dispatch(updateUserProfile(valuesForBe));
        setTimeout(() => {
          navigate(-1);
        }, 1300);
      }
    },
    validationSchema: Yup.object({
      firstName: Yup.string().required(
        i18next.t("First name is a required field")
      ),
      lastName: Yup.string().required(
        i18next.t("Last name is a required field")
      ),
      dobDay: Yup.string().required(i18next.t("Birthday day required field")),
      // dobMonth: Yup.string().required("Birthday month is a required field."),
      dobYear: Yup.string().required(
        i18next.t("Birthday year is a required field")
      ),
      phoneNumber: Yup.string().required(
        i18next.t("Phone number is a required field")
      ),
      country: Yup.string().required(i18next.t("Country is a required field")),
      homeAddress: Yup.string().required(
        i18next.t("Home address is a required field")
      ),
      city: Yup.string().required(i18next.t("City is a required field")),
      postalCode: Yup.string().required(
        i18next.t("Postal Code is a required field")
      ),
      email: Yup.string()
        .email(i18next.t("Invalid email address"))
        .required(i18next.t("Email is a required field")),
    }),
  });

  useEffect(() => {
    if (userState) {
      const monthNames = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ];

      const birthday = new Date(userState.birthday);
      const day = birthday.getDate();
      const monthString = monthNames[birthday.getMonth()]; // Get the corresponding month name
      setMonth(monthString);
      const year = birthday.getFullYear();

      formik.setFieldValue("firstName", userState.first_name);
      formik.setFieldValue("lastName", userState.last_name);
      formik.setFieldValue("dobDay", day.toString());
      // formik.setFieldValue("dobMonth", 'Jan');
      formik.setFieldValue("dobYear", year.toString());
      formik.setFieldValue("phoneNumber", userState.phone);
      formik.setFieldValue("country", userState.country ?? "");
      formik.setFieldValue("homeAddress", userState.address);
      formik.setFieldValue("city", userState.city);
      formik.setFieldValue("postalCode", userState.postal_code);
      formik.setFieldValue("email", userState.email);
    }
  }, [userState, formik.setFieldValue]);

  const handleCountrySelect = (value: any) => {
    formik.setFieldValue("country", value);
  };

  const [month, setMonth] = React.useState("");
  const handleChange = (event: SelectChangeEvent) => {
    setMonth(event.target.value as string);
  };

  return (
    <>
      {
        <div>
          <Nav step={0} isStepperVisible={false} />
          <div className="content-container">
            <ThemeProvider theme={defaultTheme}>
              <Container component="main">
                <Box
                  component="form"
                  onSubmit={formik.handleSubmit}
                  noValidate
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <div className="d-flex ai-center mb-1-rem">
                    <p
                      className="back-button-icon circle-around-text-smaller helvetica mr-1-rem cp"
                      onClick={handleNavigate}
                    >
                      <ArrowBackIcon className="muiIcons" />
                    </p>
                    <Typography
                      className="ta-center pageTitleFontSize"
                      component="h1"
                      variant="h5"
                      sx={{
                        fontFamily: "Helvetica, sans-serif",
                        fontWeight: "600",
                        color: "#0e0f0c",
                      }}
                    >
                      {!location?.state?.user
                        ? i18next.t("Edit your details")
                        : `${i18next.t("Edit")} 
                        ${userState?.first_name}
                         ${i18next.t("s personal details")}`}
                    </Typography>
                  </div>

                  <Typography
                    component="h5"
                    variant="h5"
                    className="registration0875RemFontSize"
                    sx={{
                      fontFamily: "Helvetica, sans-serif",
                      fontWeight: "300",
                      color: "#454745",
                    }}
                  >
                    {i18next.t(
                      "After you’ve made a transfer or been verified, you can’t edit some of the fields"
                    )}
                  </Typography>

                  <Box sx={{ mt: "4rem" }}>
                    <div className="label-and-field mb-1rem">
                      <Typography
                        className="d-flex jc-start registration0875RemFontSize"
                        component="h1"
                        variant="h5"
                        sx={{
                          fontFamily: "Helvetica, sans-serif",
                          fontWeight: "300",
                          color: "#454745",
                          marginBottom: "8px",
                        }}
                      >
                        {i18next.t("Full legal first and middle names")}
                      </Typography>
                      <Box className="customInputContainer">
                        <Box className="customInputSubContainer">
                          <TextField
                            margin="normal"
                            disabled={
                              user?.role !== "Admin" &&
                              user?.role !== "Employee"
                                ? true
                                : false
                            }
                            fullWidth
                            placeholder={i18next.t("First and middle names")}
                            name="firstName"
                            autoFocus
                            value={formik.values.firstName}
                            onChange={formik.handleChange}
                            InputProps={{
                              classes: {
                                notchedOutline: "customInputNoBorder",
                                input: "customInputpadding",
                              },
                            }}
                            sx={{
                              marginTop: "0",
                              marginBottom: "0",
                              height: "55px",
                              "input:-webkit-autofill, input:-webkit-autofill:hover, input:-webkit-autofill:focus, input:-webkit-autofill:active":
                                {
                                  borderRadius: "16px !important",
                                  "-webkit-text-fill-color": "inherit",
                                  "-webkit-box-shadow":
                                    "0 0 0px 1000px #e0e3e7 inset !important",
                                  backgroundColor: "#e0e3e7 !important",
                                },
                            }}
                          />
                        </Box>
                      </Box>
                      {formik.touched.firstName && formik.errors.firstName && (
                        <Box
                          className="form-error-message"
                          style={{ fontFamily: "Helvetica, sans-serif" }}
                        >
                          {formik.errors.firstName.toString()}
                        </Box>
                      )}
                    </div>

                    <div className="label-and-field mb-1rem">
                      <Typography
                        className="d-flex jc-start registration0875RemFontSize"
                        component="h1"
                        variant="h5"
                        sx={{
                          fontFamily: "Helvetica, sans-serif",
                          fontWeight: "300",
                          color: "#454745",
                          marginBottom: "8px",
                        }}
                      >
                        {i18next.t("Full legal last name(s)")}
                      </Typography>
                      <Box className="customInputContainer">
                        <Box className="customInputSubContainer">
                          <TextField
                            margin="normal"
                            disabled={
                              user?.role !== "Admin" &&
                              user?.role !== "Employee"
                                ? true
                                : false
                            }
                            required
                            fullWidth
                            id="lastName"
                            placeholder={i18next.t("Full legal last name(s)")}
                            name="lastName"
                            value={formik.values.lastName}
                            onChange={formik.handleChange}
                            InputProps={{
                              classes: {
                                notchedOutline: "customInputNoBorder",
                                input: "customInputpadding",
                              },
                            }}
                            sx={{
                              marginTop: "0",
                              marginBottom: "0",
                              height: "55px",
                              "input:-webkit-autofill, input:-webkit-autofill:hover, input:-webkit-autofill:focus, input:-webkit-autofill:active":
                                {
                                  borderRadius: "16px !important",
                                  "-webkit-text-fill-color": "inherit",
                                  "-webkit-box-shadow":
                                    "0 0 0px 1000px #e0e3e7 inset !important",
                                  backgroundColor: "#e0e3e7 !important",
                                },
                            }}
                          />
                        </Box>
                      </Box>
                      {formik.touched.lastName && formik.errors.lastName && (
                        <Box
                          className="form-error-message"
                          style={{ fontFamily: "Helvetica, sans-serif" }}
                        >
                          {formik.errors.lastName.toString()}
                        </Box>
                      )}
                    </div>

                    {/* email start */}
                    <div className="label-and-field mb-1rem">
                      <Typography
                        className="d-flex jc-start registration0875RemFontSize"
                        component="h1"
                        variant="h5"
                        sx={{
                          fontFamily: "Helvetica, sans-serif",
                          fontWeight: "300",
                          color: "#454745",
                          marginBottom: "8px",
                        }}
                      >
                        {i18next.t("Email")}
                      </Typography>
                      <Box className="customInputContainer">
                        <Box className="customInputSubContainer">
                          <TextField
                            margin="normal"
                            disabled={
                              user?.role !== "Admin" &&
                              user?.role !== "Employee"
                                ? true
                                : false
                            }
                            required
                            fullWidth
                            id="email"
                            placeholder={i18next.t("Enter email address")}
                            name="email"
                            value={formik.values.email}
                            onChange={formik.handleChange}
                            InputProps={{
                              classes: {
                                notchedOutline: "customInputNoBorder",
                                input: "customInputpadding",
                              },
                            }}
                            sx={{
                              marginTop: "0",
                              marginBottom: "0",
                              height: "55px",
                              "input:-webkit-autofill, input:-webkit-autofill:hover, input:-webkit-autofill:focus, input:-webkit-autofill:active":
                                {
                                  borderRadius: "16px !important",
                                  "-webkit-text-fill-color": "inherit",
                                  "-webkit-box-shadow":
                                    "0 0 0px 1000px #e0e3e7 inset !important",
                                  backgroundColor: "#e0e3e7 !important",
                                },
                            }}
                          />
                        </Box>
                      </Box>
                      {formik.touched.email && formik.errors.email && (
                        <Box
                          className="form-error-message"
                          style={{ fontFamily: "Helvetica, sans-serif" }}
                        >
                          {formik.errors.email.toString()}
                        </Box>
                      )}
                    </div>
                    {/* email end */}

                    <div className="label-and-field mb-1rem">
                      <Typography
                        className="d-flex jc-start registration0875RemFontSize"
                        component="h1"
                        variant="h5"
                        sx={{
                          fontFamily: "Helvetica, sans-serif",
                          fontWeight: "300",
                          color: "#454745",
                          marginBottom: "8px",
                        }}
                      >
                        {i18next.t("Date of birth")}
                      </Typography>
                      <div className="d-flex jusifyContentSpaceBetween dateOfBirthMain">
                        <Box
                          className="customInputContainerEditProfile"
                          sx={{
                            width: smallScreen ? "100%" : "6.4rem",
                          }}
                        >
                          <Box className="customInputSubContainer">
                            <TextField
                              margin="normal"
                              required
                              disabled={
                                user?.role !== "Admin" &&
                                user?.role !== "Employee"
                                  ? true
                                  : false
                              }
                              id="dobDay"
                              type="number"
                              placeholder={i18next.t("DD")}
                              name="dobDay"
                              value={formik.values.dobDay}
                              onChange={formik.handleChange}
                              InputProps={{
                                classes: {
                                  notchedOutline: "customInputNoBorder",
                                  input: "customInputpadding",
                                },
                              }}
                              sx={{
                                marginTop: "0",
                                marginBottom: "0",
                                height: "55px",
                                width: smallScreen ? "100%" : "6.4rem",
                                "input:-webkit-autofill, input:-webkit-autofill:hover, input:-webkit-autofill:focus, input:-webkit-autofill:active":
                                  {
                                    borderRadius: "16px !important",
                                    "-webkit-text-fill-color": "inherit",
                                    "-webkit-box-shadow":
                                      "0 0 0px 1000px #e0e3e7 inset !important",
                                    backgroundColor: "#e0e3e7 !important",
                                  },
                              }}
                            />
                          </Box>
                        </Box>
                        <Box
                          className="customInputContainerEditProfile"
                          sx={{
                            width: smallScreen ? "100%" : "9rem",
                          }}
                        >
                          <Box className="customInputSubContainer">
                            <Select
                              id="dobMonth"
                              name="dobMonth"
                              value={month}
                              disabled={
                                user?.role !== "Admin" &&
                                user?.role !== "Employee"
                                  ? true
                                  : false
                              }
                              sx={{
                                "& .MuiOutlinedInput-notchedOutline": {
                                  border: "none !important",
                                },
                                width: smallScreen ? "100%" : "9rem",
                              }}
                              onChange={handleChange}
                              inputProps={{
                                classes: {
                                  notchedOutline: "customInputNoBorder",
                                  input: "customInputpadding",
                                },
                                sx: {
                                  width: smallScreen ? "100%" : "9rem",
                                  "input:-webkit-autofill, input:-webkit-autofill:hover, input:-webkit-autofill:focus, input:-webkit-autofill:active":
                                    {
                                      borderRadius: "16px !important",
                                      "-webkit-text-fill-color": "inherit",
                                      "-webkit-box-shadow":
                                        "0 0 0px 1000px #e0e3e7 inset !important",
                                      backgroundColor: "#e0e3e7 !important",
                                    },
                                },
                              }}
                            >
                              <MenuItem value={"Jan"}>
                                {i18next.t("Jan")}
                              </MenuItem>
                              <MenuItem value={"Feb"}>
                                {i18next.t("Feb")}
                              </MenuItem>
                              <MenuItem value={"Mar"}>
                                {i18next.t("Mar")}
                              </MenuItem>
                              <MenuItem value={"Apr"}>
                                {i18next.t("Apr")}
                              </MenuItem>
                              <MenuItem value={"May"}>
                                {i18next.t("May")}
                              </MenuItem>
                              <MenuItem value={"Jun"}>
                                {i18next.t("Jun")}
                              </MenuItem>
                              <MenuItem value={"Jul"}>
                                {i18next.t("Jul")}
                              </MenuItem>
                              <MenuItem value={"Aug"}>
                                {i18next.t("Aug")}
                              </MenuItem>
                              <MenuItem value={"Sep"}>
                                {i18next.t("Sep")}
                              </MenuItem>
                              <MenuItem value={"Oct"}>
                                {i18next.t("Oct")}
                              </MenuItem>
                              <MenuItem value={"Nov"}>
                                {i18next.t("Nov")}
                              </MenuItem>
                              <MenuItem value={"Dec"}>
                                {i18next.t("Dec")}
                              </MenuItem>
                            </Select>
                          </Box>
                        </Box>
                        <Box className="customInputContainerEditProfile">
                          <Box className="customInputSubContainer">
                            <TextField
                              margin="normal"
                              disabled={
                                user?.role !== "Admin" &&
                                user?.role !== "Employee"
                                  ? true
                                  : false
                              }
                              required
                              type="number"
                              id="dobYear"
                              placeholder={i18next.t("YYYY")}
                              name="dobYear"
                              value={formik.values.dobYear}
                              onChange={formik.handleChange}
                              InputProps={{
                                classes: {
                                  notchedOutline: "customInputNoBorder",
                                  input: "customInputpadding",
                                },
                              }}
                              sx={{
                                marginTop: "0",
                                marginBottom: "0",
                                height: "55px",
                                "input:-webkit-autofill, input:-webkit-autofill:hover, input:-webkit-autofill:focus, input:-webkit-autofill:active":
                                  {
                                    borderRadius: "16px !important",
                                    "-webkit-text-fill-color": "inherit",
                                    "-webkit-box-shadow":
                                      "0 0 0px 1000px #e0e3e7 inset !important",
                                    backgroundColor: "#e0e3e7 !important",
                                  },
                              }}
                            />
                          </Box>
                        </Box>
                      </div>
                      {formik.touched.dobDay && formik.errors.dobDay && (
                        <Box
                          className="form-error-message"
                          style={{ fontFamily: "Helvetica, sans-serif" }}
                        >
                          {formik.errors.dobDay.toString()}
                        </Box>
                      )}

                      {formik.touched.dobYear &&
                        formik.errors.dobYear &&
                        !formik.errors.dobDay && (
                          <Box
                            className="form-error-message"
                            style={{ fontFamily: "Helvetica, sans-serif" }}
                          >
                            {formik.errors.dobYear.toString()}
                          </Box>
                        )}
                      {formik.touched.dobDay &&
                        !formik.errors.dobYear &&
                        !formik.errors.dobDay &&
                        !month && (
                          <Box
                            className="form-error-message"
                            style={{ fontFamily: "Helvetica, sans-serif" }}
                          >
                            {i18next.t("Birthday month required field")}
                          </Box>
                        )}
                    </div>

                    <div className="label-and-field mb-1rem">
                      <Typography
                        className="d-flex jc-start registration0875RemFontSize"
                        component="h1"
                        variant="h5"
                        sx={{
                          fontFamily: "Helvetica, sans-serif",
                          fontWeight: "300",
                          color: "#454745",
                          marginBottom: "8px",
                        }}
                      >
                        {i18next.t("Mobile phone number")}
                      </Typography>
                      <Box className="customInputContainer">
                        <Box className="customInputSubContainer">
                          <TextField
                            margin="normal"
                            required
                            fullWidth
                            id="phoneNumber"
                            placeholder={i18next.t("Enter phone number")}
                            name="phoneNumber"
                            type="number"
                            value={formik.values.phoneNumber}
                            onChange={formik.handleChange}
                            sx={{
                              marginTop: "0",
                              marginBottom: "0",
                              "input:-webkit-autofill, input:-webkit-autofill:hover, input:-webkit-autofill:focus, input:-webkit-autofill:active":
                                {
                                  borderRadius: "16px !important",
                                  "-webkit-text-fill-color": "inherit",
                                  "-webkit-box-shadow":
                                    "0 0 0px 1000px #e0e3e7 inset !important",
                                  backgroundColor: "#e0e3e7 !important",
                                },
                            }}
                            InputProps={{
                              classes: {
                                notchedOutline: "customInputNoBorder",
                                input: "customInputpadding",
                              },
                            }}
                          />
                        </Box>
                      </Box>
                      {formik.touched.phoneNumber &&
                        formik.errors.phoneNumber && (
                          <Box
                            className="form-error-message"
                            style={{ fontFamily: "Helvetica, sans-serif" }}
                          >
                            {formik.errors.phoneNumber.toString()}
                          </Box>
                        )}
                    </div>

                    <div className="label-and-field mb-1rem">
                      <CountrySelect
                        value={formik.values.country}
                        isTouched={formik.touched.country}
                        errors={formik.errors.country}
                        onSelect={handleCountrySelect}
                        disabled={
                          user?.role !== "Admin" && user?.role !== "Employee"
                            ? true
                            : false
                        }
                      />
                    </div>

                    <div className="label-and-field mb-1rem">
                      <Typography
                        className="d-flex jc-start registration0875RemFontSize"
                        component="h1"
                        variant="h5"
                        sx={{
                          fontFamily: "Helvetica, sans-serif",
                          fontWeight: "300",
                          color: "#454745",
                          marginBottom: "8px",
                        }}
                      >
                        {i18next.t("Home address")}
                      </Typography>

                      <Box className="customInputContainer">
                        <Box className="customInputSubContainer">
                          <TextField
                            margin="normal"
                            required
                            fullWidth
                            id="homeAddress"
                            placeholder={i18next.t("Street name")}
                            disabled={
                              user?.role !== "Admin" &&
                              user?.role !== "Employee"
                                ? true
                                : false
                            }
                            name="homeAddress"
                            value={formik.values.homeAddress}
                            onChange={formik.handleChange}
                            sx={{
                              marginTop: "0",
                              marginBottom: "0",
                              "input:-webkit-autofill, input:-webkit-autofill:hover, input:-webkit-autofill:focus, input:-webkit-autofill:active":
                                {
                                  borderRadius: "16px !important",
                                  "-webkit-text-fill-color": "inherit",
                                  "-webkit-box-shadow":
                                    "0 0 0px 1000px #e0e3e7 inset !important",
                                  backgroundColor: "#e0e3e7 !important",
                                },
                            }}
                            InputProps={{
                              classes: {
                                notchedOutline: "customInputNoBorder",
                                input: "customInputpadding",
                              },
                            }}
                          />
                        </Box>
                      </Box>
                      {formik.touched.homeAddress &&
                        formik.errors.homeAddress && (
                          <Box
                            className="form-error-message"
                            style={{ fontFamily: "Helvetica, sans-serif" }}
                          >
                            {formik.errors.homeAddress.toString()}
                          </Box>
                        )}
                    </div>

                    <div className="label-and-field mb-1rem">
                      <Typography
                        className="d-flex jc-start registration0875RemFontSize"
                        component="h1"
                        variant="h5"
                        sx={{
                          fontFamily: "Helvetica, sans-serif",
                          fontWeight: "300",
                          color: "#454745",
                          marginBottom: "8px",
                        }}
                      >
                        {i18next.t("City")}
                      </Typography>

                      <Box className="customInputContainer">
                        <Box className="customInputSubContainer">
                          <TextField
                            margin="normal"
                            required
                            fullWidth
                            id="city"
                            placeholder={i18next.t("City")}
                            disabled={
                              user?.role !== "Admin" &&
                              user?.role !== "Employee"
                                ? true
                                : false
                            }
                            name="city"
                            value={formik.values.city}
                            onChange={formik.handleChange}
                            sx={{
                              marginTop: "0",
                              marginBottom: "0",
                              "input:-webkit-autofill, input:-webkit-autofill:hover, input:-webkit-autofill:focus, input:-webkit-autofill:active":
                                {
                                  borderRadius: "16px !important",
                                  "-webkit-text-fill-color": "inherit",
                                  "-webkit-box-shadow":
                                    "0 0 0px 1000px #e0e3e7 inset !important",
                                  backgroundColor: "#e0e3e7 !important",
                                },
                            }}
                            InputProps={{
                              classes: {
                                notchedOutline: "customInputNoBorder",
                                input: "customInputpadding",
                              },
                            }}
                          />
                        </Box>
                      </Box>
                      {formik.touched.city && formik.errors.city && (
                        <Box
                          className="form-error-message"
                          style={{ fontFamily: "Helvetica, sans-serif" }}
                        >
                          {formik.errors.city.toString()}
                        </Box>
                      )}
                    </div>

                    <div className="label-and-field">
                      <Typography
                        className="d-flex jc-start registration0875RemFontSize"
                        component="h1"
                        variant="h5"
                        sx={{
                          fontFamily: "Helvetica, sans-serif",
                          fontWeight: "300",
                          color: "#454745",
                          marginBottom: "8px",
                        }}
                      >
                        {i18next.t("Postal Code")}
                      </Typography>

                      <Box className="customInputContainer">
                        <Box className="customInputSubContainer">
                          <TextField
                            margin="normal"
                            required
                            fullWidth
                            id="postalCode"
                            placeholder={i18next.t("Postal Code")}
                            name="postalCode"
                            disabled={
                              user?.role !== "Admin" &&
                              user?.role !== "Employee"
                                ? true
                                : false
                            }
                            value={formik.values.postalCode}
                            onChange={formik.handleChange}
                            sx={{
                              marginTop: "0",
                              marginBottom: "0",
                              "input:-webkit-autofill, input:-webkit-autofill:hover, input:-webkit-autofill:focus, input:-webkit-autofill:active":
                                {
                                  borderRadius: "16px !important",
                                  "-webkit-text-fill-color": "inherit",
                                  "-webkit-box-shadow":
                                    "0 0 0px 1000px #e0e3e7 inset !important",
                                  backgroundColor: "#e0e3e7 !important",
                                },
                            }}
                            InputProps={{
                              classes: {
                                notchedOutline: "customInputNoBorder",
                                input: "customInputpadding",
                              },
                            }}
                          />
                        </Box>
                      </Box>
                    </div>
                    {formik.touched.postalCode && formik.errors.postalCode && (
                      <Box
                        className="form-error-message"
                        style={{ fontFamily: "Helvetica, sans-serif" }}
                      >
                        {formik.errors.postalCode.toString()}
                      </Box>
                    )}
                  </Box>

                  <Button
                    fullWidth
                    variant="contained"
                    type="submit"
                    className="oneRemFontSize btnsLoginHeight"
                    sx={{
                      color: "white",
                      backgroundColor: "#2A5182",
                      borderRadius: "24px",
                      textTransform: "none",
                      marginTop: "2rem",
                      fontFamily: "Helvetica, sans-serif",
                      marginBottom: "4rem",
                    }}
                  >
                    {i18next.t("Save")}
                  </Button>
                </Box>
              </Container>
            </ThemeProvider>
          </div>
        </div>
      }
    </>
  );
}
