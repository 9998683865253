import React from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Box } from "@mui/material";
import { useNavigate } from "react-router-dom";
import HeaderLanding from "../components/HeaderLanding/HeaderLanding";
import { useAppSelector } from "src/store";
import FooterLanding from "../components/FooterLanding/FooterLanding";
import "./LandingPagesGlobal.scss";
import IconTextWidget from "../components/IconTextWidget/IconTextWidget";
import currencyIcon from "../../../assets/images/currencies.png";
import merchantIcon from "../../../assets/images/merchant.png";
import users from "../../../assets/images/users.png";
import icon24 from "../../../assets/images/24.png";
import languages from "../../../assets/images/languages.png";
import TitleBackBox from "../components/TitleBackBox/TitleBackBox";
import HeadlineVvidgetSmall from "../components/HeadlineVvidget/HeadlineVvidgetSmall";
import i18next from "i18next";

const defaultTheme = createTheme();
const OpenAccount: React.FC = () => {
  const { user } = useAppSelector((state) => state.auth);

  const navigate = useNavigate();

  const navigateToRegistration = (event: any) => {
    navigate("/registration");
  };

  return (
    <div>
      <HeaderLanding user={user} />
      <div className="margintTopPagesMainCont">
        <ThemeProvider theme={defaultTheme}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Box className="titleHeadlineSmallBG">
              <Box className="landingPagesMainBox">
                <Box>
                  <TitleBackBox title={i18next.t("Open Account")} />
                </Box>
                <Box>
                  <HeadlineVvidgetSmall
                    headline={i18next.t(
                      "No matter if individual and business account"
                    )}
                    subtitle={i18next.t(
                      "VicPay is the partner for anyone who has had enough of slow branch banks and high fees!"
                    )}
                    smallText={i18next.t("Join customers all over the world")}
                    buttonText={i18next.t("Create a free account")}
                    onClickFunction={navigateToRegistration}
                  />
                </Box>
              </Box>
            </Box>
            <Box className="landingPagesMainBox">
              <div className="icon-text-widgets-container">
                <IconTextWidget
                  iconSrc={currencyIcon}
                  title={i18next.t("Accounts in USD, EUR and other currencies")}
                  text={i18next.t("Accounts in USD, EUR and other currencies")}
                />
                <IconTextWidget
                  iconSrc={merchantIcon}
                  title={i18next.t(
                    "Local and international deposits and withdrawals"
                  )}
                  text={i18next.t(
                    "Local and international deposits and withdrawals"
                  )}
                />
                <IconTextWidget
                  iconSrc={languages}
                  title={i18next.t("Multilingual Support")}
                  text=""
                />
                <IconTextWidget
                  iconSrc={users}
                  title={i18next.t("Robust multi-tier account security")}
                  text={i18next.t(
                    "Robust multi-tier account security with 2FA"
                  )}
                />
                <IconTextWidget
                  iconSrc={icon24}
                  title={i18next.t("First response in 1 hour or faster")}
                  text={i18next.t("First response in 1 hour or faster")}
                />
              </div>
            </Box>
          </Box>
        </ThemeProvider>
      </div>
      <Box sx={{ marginTop: "4rem" }}>
        <FooterLanding />
      </Box>
    </div>
  );
};

export default OpenAccount;
