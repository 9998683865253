import { Box } from "@mui/material";
import { useNavigate } from "react-router-dom";
import CustomTabs from "../shared/CustomTabs/CustomTabs";

function UserDetailsTabs({ user }: any) {
  const navigate = useNavigate();

  const handleNavigate = (url: string) => {
    navigate(url);
  };

  return (
    <Box sx={{ marginTop: "32px", marginBottom: "32px" }}>
      <div
        className="d-flex ai-center"
        style={{ display: "flex", flexWrap: "wrap", rowGap: "1rem" }}
      >
        <CustomTabs
          text="Account"
          url={`/admin/users/${user.id}/account-information`}
          onClick={() =>
            handleNavigate(`/admin/users/${user.id}/account-information`)
          }
        />
        <CustomTabs
          text="Verification"
          url={`/admin/users/${user.id}/verification-files`}
          onClick={() =>
            handleNavigate(`/admin/users/${user.id}/verification-files`)
          }
        />
        <CustomTabs
          text="Balance"
          url={`/admin/users/${user.id}/balance`}
          onClick={() => handleNavigate(`/admin/users/${user.id}/balance`)}
        />
        <CustomTabs
          text="Requests"
          url={`/admin/users/${user.id}/account-requests`}
          onClick={() =>
            handleNavigate(`/admin/users/${user.id}/account-requests`)
          }
        />
        <CustomTabs
          text="Codes"
          url={`/admin/users/${user.id}/verification-codes`}
          onClick={() =>
            handleNavigate(`/admin/users/${user.id}/verification-codes`)
          }
        />
        <CustomTabs
          text="Transactions"
          url={`/admin/users/${user.id}/transactions`}
          onClick={() => handleNavigate(`/admin/users/${user.id}/transactions`)}
        />
        <CustomTabs
          text="Notifications"
          url={`/admin/users/${user.id}/notifications`}
          onClick={() => handleNavigate(`/admin/users/${user.id}/notifications`)}
        />
      </div>
    </Box>
  );
}

export default UserDetailsTabs;
