import { useEffect, useState } from "react";
import {
  Button,
  Box,
  Select,
  MenuItem,
  Typography,
  FormControl,
  InputLabel,
  Grid,
} from "@mui/material";
import api from "src/store/interceptors/api";
import ConfirmationDialog from "src/components/shared/ConfirmationDialog/ConfirmationDialog";
import transactionsService from "src/store/transactions/transactions_service";
import { formatAccountStatus } from "src/utils/helpers";

const statusOptions = [
  { name: "created", label: "Created" },
  { name: "transaction_created", label: "Created transaction" },
  { name: "target_reached", label: "Target reached " },
];
function getStatus(status: string) {
  const obj = statusOptions.find((option) => option.name === status);
  if (obj) return obj;
  const response = { name: status, label: status };
  return response;
}

interface Props {
  data: any;
  user: any;
}
function AccountStatusAdminActions({ data, user }: Props) {
  const [loading, setLoading] = useState<boolean>(false);
  const [accountStatus, setAccountStatus] = useState<string>(
    user?.account_status
  );
  const [currentStatus, setCurrentStatus] = useState<any>(null);
  useEffect(() => {
    const status = getStatus(data.status);
    setCurrentStatus(status);
  }, [data]);
  // conf dialog start
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const handleStatusChange = async () => {
    setIsDialogOpen(true);
  };

  const handleDialogClose = () => {
    setIsDialogOpen(false);
  };

  const handleDialogConfirm = async (e: any) => {
    setIsDialogOpen(false);
    try {
      setLoading(true);
      const payload = {
        account_status: accountStatus,
      };
      const res = await api.put(`/users/${user.id}`, payload);
      setLoading(false);
      if (res) {
        window.location.reload();
      } else {
        alert("Please try again!");
      }
    } catch (error) {
      setLoading(false);
      console.error("Error canceling the transfer:", error);
    }
    //handleRemoveFileClick(e);
  };

  const handleAccountStatusChange = (event: any) => {
    setAccountStatus(event.target.value);
  };

  return (
    <div>
      <ConfirmationDialog
        open={isDialogOpen}
        handleClose={handleDialogClose}
        handleConfirm={handleDialogConfirm}
        content={{
          title: "Sure you'd like to change the account status?",
          body: "Note that this action will reflect user status.",
          cancelText: "Cancel",
          confirmText: "Change",
        }}
        className="custom-dialog"
      />
      <Box
        sx={{
          marginBottom: "4rem",
        }}
      >
        <Box sx={{ mt: 2 }}>
          <div className="personal-box">
            <div className="transaction-item-details">
              <div className="d-flex ai-center jc-center">
                <Typography
                  className="your-details-text d-flex jc-start FontSize12"
                  component="h1"
                  variant="h5"
                  sx={{
                    fontFamily: "Helvetica, sans-serif",
                    fontWeight: "400",
                    textAlign: "start",
                  }}
                >
                  Account status
                </Typography>
              </div>
            </div>
            <Box sx={{ padding: "16px" }}>
              <Box
                columnGap={1}
                sx={{ display: "flex", justifyContent: "flex-start" }}
              >
                <Typography
                  className="transaction-detail-label oneRemFontSize"
                  sx={{
                    fontWeight: "400",
                    textAlign: "start",
                  }}
                >
                  Account status:
                </Typography>
                <Typography
                  className="transaction-detail-value oneRemFontSize"
                  sx={{
                    fontWeight: "400",
                    textAlign: "start",
                  }}
                >
                  {data && data.status ? formatAccountStatus(data.status) : ""}
                </Typography>
              </Box>

              <Box
                columnGap={1}
                sx={{ display: "flex", justifyContent: "flex-start" }}
              >
                <Typography
                  className="transaction-detail-label oneRemFontSize"
                  sx={{
                    fontWeight: "400",
                    textAlign: "start",
                  }}
                >
                  Number of transactions:
                </Typography>
                <Typography
                  className="transaction-detail-value oneRemFontSize"
                  sx={{
                    fontWeight: "400",
                    textAlign: "start",
                  }}
                >
                  {user && user.transactions_created
                    ? user.transactions_created
                    : 0}
                </Typography>
              </Box>

              <Box
                columnGap={1}
                sx={{ display: "flex", justifyContent: "flex-start" }}
              >
                <Typography
                  className="transaction-detail-label oneRemFontSize"
                  sx={{
                    fontWeight: "400",
                    textAlign: "start",
                  }}
                >
                  Total amount:
                </Typography>
                <Typography
                  className="transaction-detail-value oneRemFontSize"
                  sx={{
                    fontWeight: "400",
                    textAlign: "start",
                  }}
                >
                  {user && user.total_amount_sent
                    ? transactionsService.formatNumber(user.total_amount_sent)
                    : 0}
                </Typography>
              </Box>

              <Box
                columnGap={1}
                sx={{ display: "flex", justifyContent: "flex-start" }}
              >
                <Typography
                  className="transaction-detail-label oneRemFontSize"
                  sx={{
                    fontWeight: "400",
                    textAlign: "start",
                  }}
                >
                  Current account status:
                </Typography>
                <Typography
                  className="transaction-detail-value oneRemFontSize"
                  sx={{
                    fontWeight: "400",
                    textAlign: "start",
                  }}
                >
                  {currentStatus && currentStatus.label
                    ? currentStatus.label
                    : ""}
                </Typography>
              </Box>
            </Box>

            <Grid
              container
              spacing={2}
              sx={{ padding: 1 }}
              justifyItems={"center"}
              alignItems={"center"}
              justifyContent={"center"}
            >
              <Grid item xs={6} md={8}>
                <FormControl fullWidth>
                  <InputLabel>Status</InputLabel>
                  <Select
                    fullWidth
                    label="Status"
                    defaultValue={accountStatus}
                    value={accountStatus}
                    onChange={handleAccountStatusChange}
                    sx={{
                      borderRadius: "10px",
                      height: "48px",
                      "& .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input":
                        {
                          padding: "16.5px 14px 16.5px 14px",
                        },
                      "@media (max-width: 600px)": {
                        height: "42px",
                      },
                    }}
                    MenuProps={{
                      anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "left",
                      },
                      transformOrigin: {
                        vertical: "top",
                        horizontal: "left",
                      },
                    }}
                  >
                    {statusOptions.map(
                      (statusItem: any, statusItemIndex: any) => {
                        //    console.log(data.status, statusItem.name)
                        return (
                          <MenuItem
                            key={"status" + statusItem.name + statusItemIndex}
                            selected={statusItem.name === data.status}
                            value={statusItem.name}
                          >
                            <Box sx={{ display: "flex", alignItems: "center" }}>
                              {statusItem.label}
                            </Box>
                          </MenuItem>
                        );
                      }
                    )}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={6} md={4}>
                <Button
                  fullWidth
                  onClick={handleStatusChange}
                  variant="text"
                  className="actionButton btnsLoginHeight"
                  disabled={
                    !accountStatus || accountStatus === user.account_status
                  }
                >
                  Change account status
                </Button>
              </Grid>
            </Grid>
          </div>
        </Box>
      </Box>
    </div>
  );
}

export default AccountStatusAdminActions;
