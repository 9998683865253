import React from "react";
import { Modal, Box, Typography, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: { xs: "100%", md: "50%" }, // 100% width on xs screens, 50% otherwise
  height: { xs: "100%", md: "70%" }, // 100% height on xs screens, 70% otherwise
  bgcolor: "background.paper",
  borderRadius: { xs: 0, md: 4 },
  boxShadow: 24,
  p: { xs: 4, md: 4 }, // Padding of 1 on xs screens, 4 otherwise
};

const PDFModal = ({ open, handleClose, fileUrl }: any) => {
  return (
    <Modal
      open={open}
      onClose={handleClose}
      sx={{ "& .MuiBackdrop-root": { backgroundColor: "transparent" } }}
    >
      <Box sx={style}>
        {/* Position the close button at the top right corner of the modal */}
        <IconButton
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 20, // Adjust spacing from the right edge as needed
            top: 8, // Adjust spacing from the top edge as needed
            color: "common.black", // Change the color if needed
            // bottom: 0,
            // paddingBottom: 2px,
            height: 40,
          }}
          aria-label="close"
        >
          <CloseIcon />
        </IconButton>
        <Box sx={{ mt: 2, width: "100%", height: "90%", overflow: "hidden" }}>
          <iframe
            src={fileUrl}
            title="PDF preview"
            style={{
              width: "100%",
              height: "100%",
              border: "none",
            }}
          ></iframe>
        </Box>
      </Box>
    </Modal>
  );
};

export default PDFModal;
