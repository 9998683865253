import * as React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import "./AddUser.scss";
import { useNavigate } from "react-router-dom";
import Nav from "src/components/layout/nav/Nav";
import {
  MenuItem,
  Select,
  SelectChangeEvent,
  useMediaQuery,
} from "@mui/material";
import CountrySelect from "src/components/shared/CountrySelect/CountrySelect";
import { useFormik } from "formik";
import * as Yup from "yup";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import api from "src/store/interceptors/api";
import { toast } from "react-toastify";
import { useUser } from "src/hooks/useUser";
import { useEffect, useState } from "react";

const defaultTheme = createTheme();

export default function AddUser() {
  const navigate = useNavigate();
  const user = useUser();
  const smallScreen = useMediaQuery("(max-width: 500px)");

  const formatBirthday = (
    dobDay: string,
    dobMonth: string,
    dobYear: string
  ) => {
    if (!dobDay || !dobMonth || !dobYear) {
      return null;
    }
    const paddedDay = dobDay.padStart(2, "0");
    const paddedMonth = dobMonth.padStart(2, "0");
    const birthday = `${dobYear}-${paddedMonth}-${paddedDay}`;
    return birthday;
  };

  const handleNavigate = () => {
    navigate(`/admin/users/`);
  };

  const handleRoleChange = (event: SelectChangeEvent) => {
    formik.setFieldValue("role", event.target.value);
  };

  const convertDateToISOFormat = (dateString: any) => {
    const months: any = {
      Jan: "01",
      Feb: "02",
      Mar: "03",
      Apr: "04",
      May: "05",
      Jun: "06",
      Jul: "07",
      Aug: "08",
      Sep: "09",
      Oct: "10",
      Nov: "11",
      Dec: "12",
    };

    const parts = dateString.split("-");

    if (parts.length === 3) {
      const year = parts[0];
      const month = months[parts[1]];
      const day = parts[2].padStart(2, "0");

      if (!month) {
        return null; // or throw an error
      }

      return `${year}-${month}-${day}`;
    } else {
      return null; // or throw an error
    }
  };

  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      role: "Employee",
      dobDay: "",
      dobMonth: "",
      dobYear: "",
      phoneNumber: "",
      country: "",
      homeAddress: "",
      city: "",
      postalCode: "",
      password: "",
      email: "",
    },
    onSubmit: async (values: any) => {
      if (user?.role !== "Admin") {
        toast.error("You are not authorized to perform this action");
        return;
      }
      const formattedBirthday = formatBirthday(
        values.dobDay.toString(),
        month.toString(),
        values.dobYear.toString()
      );
      const convertedDate = convertDateToISOFormat(formattedBirthday);

      let valuesForBe: any = {};
      valuesForBe.first_name = values.firstName.toString();
      valuesForBe.last_name = values.lastName.toString();
      valuesForBe.birthday = convertedDate;
      valuesForBe.phone = values.phoneNumber.toString();
      valuesForBe.country = values.country.toString();
      valuesForBe.address = values.homeAddress.toString();
      valuesForBe.city = values.city.toString();
      valuesForBe.postal_code = values.postalCode.toString();
      valuesForBe.role = values.role.toString();
      valuesForBe.password = values.password.toString();
      valuesForBe.email = values.email.toString();

      // Make the POST request
      try {
        const response = await api.post(`/users`, valuesForBe);
        // Assuming you're using react-toastify for toast messages
        toast.success("Successfully created user");
        // Redirect after success
        setTimeout(() => {
          navigate(-1);
        }, 1000);
      } catch (error) {
        toast.error("Something went wrong");
      }
    },
    validationSchema: Yup.object({
      firstName: Yup.string().required("First name is a required field."),
      lastName: Yup.string().required("Last name is a required field."),
      dobDay: Yup.string().required("Birthday day required field."),
      // dobMonth: Yup.string().required("Birthday month is a required field."),
      dobYear: Yup.string().required("Birthday year is a required field."),
      phoneNumber: Yup.string().required("Phone number is a required field."),
      country: Yup.string().required("Country is a required field."),
      homeAddress: Yup.string().required("Home address is a required field."),
      city: Yup.string().required("City is a required field."),
      postalCode: Yup.string().required("Postal Code is a required field."),
      password: Yup.string().required("Password is a required field."),
      email: Yup.string()
        .email("Invalid email address")
        .required("Email is a required field."),
    }),
  });

  const handleCountrySelect = (value: any) => {
    formik.setFieldValue("country", value);
  };

  const [month, setMonth] = useState("");
  const handleChange = (event: SelectChangeEvent) => {
    setMonth(event.target.value as string);
  };

  useEffect(() => {
    if (user?.role === "Employee") {
      navigate("/admin");
    }
  }, [user, navigate]);

  return (
    <>
      {
        <div>
          <Nav step={0} isStepperVisible={false} />
          <div className="content-container">
            <ThemeProvider theme={defaultTheme}>
              <Container component="main">
                <Box
                  component="form"
                  onSubmit={formik.handleSubmit}
                  noValidate
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <div className="d-flex ai-center mb-1-rem">
                    <p
                      className="back-button-icon circle-around-text-smaller helvetica mr-1-rem cp"
                      onClick={handleNavigate}
                    >
                      <ArrowBackIcon className="muiIcons" />
                    </p>
                    <Typography
                      className="ta-center pageTitleFontSize"
                      component="h1"
                      variant="h5"
                      sx={{
                        fontFamily: "Helvetica, sans-serif",
                        fontWeight: "600",
                        color: "#0e0f0c",
                      }}
                    >
                      Create a new user
                    </Typography>
                  </div>

                  <Typography
                    component="h5"
                    variant="h5"
                    className="registration0875RemFontSize"
                    sx={{
                      fontFamily: "Helvetica, sans-serif",
                      fontWeight: "300",
                      color: "#454745",
                    }}
                  >
                    You can create a new user by filling in the form below.
                  </Typography>

                  <Box sx={{ mt: "4rem" }}>
                    <div className="label-and-field mb-1rem">
                      <Typography
                        className="d-flex jc-start registration0875RemFontSize"
                        component="h1"
                        variant="h5"
                        sx={{
                          fontFamily: "Helvetica, sans-serif",
                          fontWeight: "300",
                          color: "#454745",
                          marginBottom: "-8px",
                        }}
                      >
                        First and middle names
                      </Typography>

                      <TextField
                        margin="normal"
                        // disabled={
                        //   user?.role !== "Admin" && user?.role !== "Employee"
                        //     ? true
                        //     : false
                        // }
                        fullWidth
                        placeholder="First and middle names"
                        name="firstName"
                        autoFocus
                        value={formik.values.firstName}
                        onChange={formik.handleChange}
                        InputProps={{
                          sx: {
                            borderRadius: "10px",
                            height: "48px",
                            fontSize: "16px",
                            "&:-internal-autofill-selected": {
                              backgroundColor: "transparent !important",
                            },
                            "@media (max-width:600px)": {
                              fontSize: "14px !important",
                            },
                          },
                        }}
                      />
                      {formik.touched.firstName && formik.errors.firstName && (
                        <Box
                          className="form-error-message"
                          style={{ fontFamily: "Helvetica, sans-serif" }}
                        >
                          {formik.errors.firstName.toString()}
                        </Box>
                      )}
                    </div>

                    <div className="label-and-field mb-1rem">
                      <Typography
                        className="d-flex jc-start registration0875RemFontSize"
                        component="h1"
                        variant="h5"
                        sx={{
                          fontFamily: "Helvetica, sans-serif",
                          fontWeight: "300",
                          color: "#454745",
                          marginBottom: "-8px",
                        }}
                      >
                        Last name(s)
                      </Typography>

                      <TextField
                        margin="normal"
                        // disabled={
                        //   user?.role !== "Admin" && user?.role !== "Employee"
                        //     ? true
                        //     : false
                        // }
                        required
                        fullWidth
                        id="lastName"
                        placeholder="Full legal last name(s)"
                        name="lastName"
                        value={formik.values.lastName}
                        onChange={formik.handleChange}
                        InputProps={{
                          sx: {
                            borderRadius: "10px",
                            height: "48px",
                            fontSize: "16px",
                            "&:-internal-autofill-selected": {
                              backgroundColor: "transparent !important",
                            },
                            "@media (max-width:600px)": {
                              fontSize: "14px !important",
                            },
                          },
                        }}
                      />
                      {formik.touched.lastName && formik.errors.lastName && (
                        <Box
                          className="form-error-message"
                          style={{ fontFamily: "Helvetica, sans-serif" }}
                        >
                          {formik.errors.lastName.toString()}
                        </Box>
                      )}
                    </div>

                    <div className="label-and-field mb-1rem">
                      <Typography
                        className="d-flex jc-start registration0875RemFontSize"
                        component="h1"
                        variant="h5"
                        sx={{
                          fontFamily: "Helvetica, sans-serif",
                          fontWeight: "300",
                          color: "#454745",
                          marginBottom: "8px",
                        }}
                      >
                        Role
                      </Typography>

                      <Select
                        id="role"
                        name="role"
                        value={formik.values.role}
                        fullWidth
                        //   disabled={
                        //     user?.role !== "Admin" && user?.role !== "Employee"
                        //       ? true
                        //       : false
                        //   }
                        onChange={handleRoleChange}
                        sx={{
                          // width: "9rem",
                          borderRadius: "10px",
                          // marginTop: "17px",
                          height: "48px",
                          fontSize: "16px",
                          "&:-internal-autofill-selected": {
                            backgroundColor: "transparent !important",
                          },
                          "@media (max-width:600px)": {
                            fontSize: "14px !important",
                          },
                        }}
                      >
                        <MenuItem value={"Employee"}>Employee</MenuItem>
                        <MenuItem value={"Admin"}>Admin</MenuItem>
                      </Select>
                    </div>

                    <div className="label-and-field mb-1rem">
                      <Typography
                        className="d-flex jc-start registration0875RemFontSize"
                        component="h1"
                        variant="h5"
                        sx={{
                          fontFamily: "Helvetica, sans-serif",
                          fontWeight: "300",
                          color: "#454745",
                          marginBottom: "-8px",
                        }}
                      >
                        Date of birth
                      </Typography>
                      <div className="d-flex jusifyContentSpaceBetween dateOfBirthMain">
                        <TextField
                          margin="normal"
                          required
                          //   disabled={
                          //     user?.role !== "Admin" && user?.role !== "Employee"
                          //       ? true
                          //       : false
                          //   }
                          id="dobDay"
                          type="number"
                          placeholder="DD"
                          name="dobDay"
                          value={formik.values.dobDay}
                          onChange={formik.handleChange}
                          InputProps={{
                            sx: {
                              borderRadius: "10px",
                              width: smallScreen ? "100%" : "6.4rem",
                              height: "48px",
                              fontSize: "16px",
                              "&:-internal-autofill-selected": {
                                backgroundColor: "transparent !important",
                              },
                              "@media (max-width:600px)": {
                                fontSize: "14px !important",
                              },
                            },
                          }}
                        />
                        <Select
                          id="dobMonth"
                          name="dobMonth"
                          value={month}
                          //   disabled={
                          //     user?.role !== "Admin" && user?.role !== "Employee"
                          //       ? true
                          //       : false
                          //   }
                          onChange={handleChange}
                          sx={{
                            width: smallScreen ? "100%" : "9rem",
                            height: "48px",
                            fontSize: "16px",
                            "&:-internal-autofill-selected": {
                              backgroundColor: "transparent !important",
                            },
                            "@media (max-width:600px)": {
                              fontSize: "14px !important",
                            },
                            borderRadius: "10px",
                            marginTop: "17px",
                          }}
                        >
                          <MenuItem value={"Jan"}>Jan</MenuItem>
                          <MenuItem value={"Feb"}>Feb</MenuItem>
                          <MenuItem value={"Mar"}>Mar</MenuItem>
                          <MenuItem value={"Apr"}>Apr</MenuItem>
                          <MenuItem value={"May"}>May</MenuItem>
                          <MenuItem value={"Jun"}>Jun</MenuItem>
                          <MenuItem value={"Jul"}>Jul</MenuItem>
                          <MenuItem value={"Aug"}>Aug</MenuItem>
                          <MenuItem value={"Sep"}>Sep</MenuItem>
                          <MenuItem value={"Oct"}>Oct</MenuItem>
                          <MenuItem value={"Nov"}>Nov</MenuItem>
                          <MenuItem value={"Dec"}>Dec</MenuItem>
                        </Select>
                        <TextField
                          margin="normal"
                          //   disabled={
                          //     user?.role !== "Admin" && user?.role !== "Employee"
                          //       ? true
                          //       : false
                          //   }
                          required
                          type="number"
                          id="dobYear"
                          placeholder="YYYY"
                          name="dobYear"
                          value={formik.values.dobYear}
                          onChange={formik.handleChange}
                          InputProps={{
                            sx: {
                              borderRadius: "10px",
                              height: "48px",
                              fontSize: "16px",
                              "&:-internal-autofill-selected": {
                                backgroundColor: "transparent !important",
                              },
                              "@media (max-width:600px)": {
                                fontSize: "14px !important",
                              },
                            },
                          }}
                        />
                      </div>
                      {formik.touched.dobDay && formik.errors.dobDay && (
                        <Box
                          className="form-error-message"
                          style={{ fontFamily: "Helvetica, sans-serif" }}
                        >
                          {formik.errors.dobDay.toString()}
                        </Box>
                      )}

                      {formik.touched.dobYear &&
                        formik.errors.dobYear &&
                        !formik.errors.dobDay && (
                          <Box
                            className="form-error-message"
                            style={{ fontFamily: "Helvetica, sans-serif" }}
                          >
                            {formik.errors.dobYear.toString()}
                          </Box>
                        )}
                      {formik.touched.dobDay &&
                        !formik.errors.dobYear &&
                        !formik.errors.dobDay &&
                        !month && (
                          <Box
                            className="form-error-message"
                            style={{ fontFamily: "Helvetica, sans-serif" }}
                          >
                            Birthday month required field.
                          </Box>
                        )}
                    </div>

                    <div className="label-and-field mb-1rem">
                      <Typography
                        className="d-flex jc-start registration0875RemFontSize"
                        component="h1"
                        variant="h5"
                        sx={{
                          fontFamily: "Helvetica, sans-serif",
                          fontWeight: "300",
                          color: "#454745",
                          marginBottom: "-8px",
                        }}
                      >
                        Mobile phone number
                      </Typography>

                      <TextField
                        margin="normal"
                        required
                        fullWidth
                        id="phoneNumber"
                        placeholder="Enter phone number"
                        name="phoneNumber"
                        type="number"
                        value={formik.values.phoneNumber}
                        onChange={formik.handleChange}
                        InputProps={{
                          sx: {
                            borderRadius: "10px",
                            height: "48px",
                            fontSize: "16px",
                            "&:-internal-autofill-selected": {
                              backgroundColor: "transparent !important",
                            },
                            "@media (max-width:600px)": {
                              fontSize: "14px !important",
                            },
                          },
                        }}
                      />
                      {formik.touched.phoneNumber &&
                        formik.errors.phoneNumber && (
                          <Box
                            className="form-error-message"
                            style={{ fontFamily: "Helvetica, sans-serif" }}
                          >
                            {formik.errors.phoneNumber.toString()}
                          </Box>
                        )}
                    </div>

                    <div className="label-and-field mb-1rem">
                      <CountrySelect
                        value={formik.values.country}
                        isTouched={formik.touched.country}
                        errors={formik.errors.country}
                        onSelect={handleCountrySelect}
                        // disabled={
                        //   user?.role !== "Admin" && user?.role !== "Employee"
                        //     ? true
                        //     : false
                        // }
                      />
                    </div>

                    <div className="label-and-field mb-1rem">
                      <Typography
                        className="d-flex jc-start registration0875RemFontSize"
                        component="h1"
                        variant="h5"
                        sx={{
                          fontFamily: "Helvetica, sans-serif",
                          fontWeight: "300",
                          color: "#454745",
                          marginBottom: "-8px",
                        }}
                      >
                        Home address
                      </Typography>

                      <TextField
                        margin="normal"
                        required
                        fullWidth
                        id="homeAddress"
                        placeholder="Street name"
                        // disabled={
                        //   user?.role !== "Admin" && user?.role !== "Employee"
                        //     ? true
                        //     : false
                        // }
                        name="homeAddress"
                        value={formik.values.homeAddress}
                        onChange={formik.handleChange}
                        InputProps={{
                          sx: {
                            borderRadius: "10px",
                            height: "48px",
                            fontSize: "16px",
                            "&:-internal-autofill-selected": {
                              backgroundColor: "transparent !important",
                            },
                            "@media (max-width:600px)": {
                              fontSize: "14px !important",
                            },
                          },
                        }}
                      />
                      {formik.touched.homeAddress &&
                        formik.errors.homeAddress && (
                          <Box
                            className="form-error-message"
                            style={{ fontFamily: "Helvetica, sans-serif" }}
                          >
                            {formik.errors.homeAddress.toString()}
                          </Box>
                        )}
                    </div>

                    <div className="label-and-field mb-1rem">
                      <Typography
                        className="d-flex jc-start registration0875RemFontSize"
                        component="h1"
                        variant="h5"
                        sx={{
                          fontFamily: "Helvetica, sans-serif",
                          fontWeight: "300",
                          color: "#454745",
                          marginBottom: "-8px",
                        }}
                      >
                        City
                      </Typography>

                      <TextField
                        margin="normal"
                        required
                        fullWidth
                        id="city"
                        placeholder="City"
                        // disabled={
                        //   user?.role !== "Admin" && user?.role !== "Employee"
                        //     ? true
                        //     : false
                        // }
                        name="city"
                        value={formik.values.city}
                        onChange={formik.handleChange}
                        InputProps={{
                          sx: {
                            borderRadius: "10px",
                            height: "48px",
                            fontSize: "16px",
                            "&:-internal-autofill-selected": {
                              backgroundColor: "transparent !important",
                            },
                            "@media (max-width:600px)": {
                              fontSize: "14px !important",
                            },
                          },
                        }}
                      />
                      {formik.touched.city && formik.errors.city && (
                        <Box
                          className="form-error-message"
                          style={{ fontFamily: "Helvetica, sans-serif" }}
                        >
                          {formik.errors.city.toString()}
                        </Box>
                      )}
                    </div>

                    <div className="label-and-field mb-1rem">
                      <Typography
                        className="d-flex jc-start registration0875RemFontSize"
                        component="h1"
                        variant="h5"
                        sx={{
                          fontFamily: "Helvetica, sans-serif",
                          fontWeight: "300",
                          color: "#454745",
                          marginBottom: "-8px",
                        }}
                      >
                        Postal Code
                      </Typography>

                      <TextField
                        margin="normal"
                        required
                        fullWidth
                        id="postalCode"
                        placeholder="Postal Code"
                        name="postalCode"
                        // disabled={
                        //   user?.role !== "Admin" && user?.role !== "Employee"
                        //     ? true
                        //     : false
                        // }
                        value={formik.values.postalCode}
                        onChange={formik.handleChange}
                        InputProps={{
                          sx: {
                            borderRadius: "10px",
                            height: "48px",
                            fontSize: "16px",
                            "&:-internal-autofill-selected": {
                              backgroundColor: "transparent !important",
                            },
                            "@media (max-width:600px)": {
                              fontSize: "14px !important",
                            },
                          },
                        }}
                      />
                      {formik.touched.postalCode &&
                        formik.errors.postalCode && (
                          <Box
                            className="form-error-message"
                            style={{ fontFamily: "Helvetica, sans-serif" }}
                          >
                            {formik.errors.postalCode.toString()}
                          </Box>
                        )}
                    </div>
                  </Box>

                  <div className="label-and-field mb-1rem">
                    <Typography
                      className="d-flex jc-start registration0875RemFontSize"
                      component="h1"
                      variant="h5"
                      sx={{
                        fontFamily: "Helvetica, sans-serif",
                        fontWeight: "300",
                        color: "#454745",
                        marginBottom: "-8px",
                      }}
                    >
                      Email
                    </Typography>

                    <TextField
                      margin="normal"
                      required
                      fullWidth
                      id="email"
                      placeholder="Email"
                      name="email"
                      type="email"
                      value={formik.values.email}
                      onChange={formik.handleChange}
                      InputProps={{
                        sx: {
                          borderRadius: "10px",
                          height: "48px",
                          fontSize: "16px",
                          "&:-internal-autofill-selected": {
                            backgroundColor: "transparent !important",
                          },
                          "@media (max-width:600px)": {
                            fontSize: "14px !important",
                          },
                        },
                      }}
                    />
                    {formik.touched.email && formik.errors.email && (
                      <Box
                        className="form-error-message"
                        style={{ fontFamily: "Helvetica, sans-serif" }}
                      >
                        {formik.errors.email.toString()}
                      </Box>
                    )}
                  </div>

                  <div className="label-and-field mb-1rem">
                    <Typography
                      className="d-flex jc-start registration0875RemFontSize"
                      component="h1"
                      variant="h5"
                      sx={{
                        fontFamily: "Helvetica, sans-serif",
                        fontWeight: "300",
                        color: "#454745",
                        marginBottom: "-8px",
                      }}
                    >
                      Password
                    </Typography>

                    <TextField
                      margin="normal"
                      required
                      fullWidth
                      id="password"
                      placeholder="Password"
                      name="password"
                      type="password"
                      value={formik.values.password}
                      onChange={formik.handleChange}
                      InputProps={{
                        sx: {
                          borderRadius: "10px",
                          height: "48px",
                          fontSize: "16px",
                          "&:-internal-autofill-selected": {
                            backgroundColor: "transparent !important",
                          },
                          "@media (max-width:600px)": {
                            fontSize: "14px !important",
                          },
                        },
                      }}
                    />
                    {formik.touched.password && formik.errors.password && (
                      <Box
                        className="form-error-message"
                        style={{ fontFamily: "Helvetica, sans-serif" }}
                      >
                        {formik.errors.password.toString()}
                      </Box>
                    )}
                  </div>

                  <Button
                    fullWidth
                    variant="contained"
                    type="submit"
                    className="btnsLoginHeight oneRemFontSize"
                    sx={{
                      color: "white",
                      backgroundColor: "#2A5182",
                      borderRadius: "24px",
                      textTransform: "none",
                      marginTop: "2rem",
                      fontFamily: "Helvetica, sans-serif",
                      marginBottom: "4rem",
                    }}
                  >
                    Save
                  </Button>
                </Box>
              </Container>
            </ThemeProvider>
          </div>
        </div>
      }
    </>
  );
}
