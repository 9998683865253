import {
  Button,
  TextField,
  Typography,
  Select,
  InputLabel,
  MenuItem,
} from "@mui/material";
import { useFormik } from "formik";
import api from "src/store/interceptors/api";
import { toast } from "react-toastify";
import i18next from "i18next";

interface Props {
  handleNavigate: () => void;
  recipientDetails: any;
  methodItem?: any;
}

function isObjectWithProperty(
  obj: any,
  prop: string
): obj is { [key: string]: any } {
  return obj && typeof obj === "object" && prop in obj;
}

function DynamicForm({ recipientDetails, methodItem, handleNavigate }: Props) {
  const formik = useFormik({
    enableReinitialize: false,
    initialValues: {
      ...methodItem.fieldsForm,
      accountHolderName: recipientDetails?.meta?.accountHolderName ?? "",
      type: methodItem.type,
      profile: process.env.REACT_APP_WISE_PROFILE_ID,
      ownedByCustomer: true,
      legalType: recipientDetails?.meta?.details?.legalType ?? "",
    },
    onSubmit: async (values: any) => {
      const tmpValues = { ...values };
      let blnContinue: boolean = true;

      let accountHolderNameFormated = "";
      if (values.accountHolderName) {
        accountHolderNameFormated = values.accountHolderName;
      } else {
        if (values.fullName) {
          accountHolderNameFormated = values.fullName;
        }
      }

      const {
        accountHolderName,
        currency,
        type,
        ownedByCustomer,
        profile,
        ...detailsData
      } = tmpValues;

      const payload = {
        currency: currency
          ? currency
          : recipientDetails?.currency
          ? recipientDetails.currency
          : null,
        currencySource: currency
          ? currency
          : recipientDetails.currency_source
          ? recipientDetails.currency_source
          : null,
        profile: parseFloat(profile),
        legalEntityType: "PERSON",
        accountHolderName: accountHolderName,
        type: type,
        ownedByCustomer: ownedByCustomer,
        details: { ...detailsData },
      };

      if (!blnContinue) {
        alert(i18next.t("Please fill the payment details!"));
        return true;
      }
      await api
        .patch(`/recipients/${recipientDetails.id}`, payload)
        .then((res) => {
          if (res.data?.errors) {
            const apiErrors = res.data.errors.reduce((acc: any, error: any) => {
              acc[error.path] = error.message;
              return acc;
            }, {});

            formik.setErrors(apiErrors);

            const numberOfKeys = Object.keys(apiErrors).length;
            if (numberOfKeys === 1) {
              const valuesArray = Object.values(apiErrors);
              const firstValue = valuesArray[0];
              toast.error(firstValue as string);
            }
          } else {
            handleNavigate();
          }
        })
        .catch((error: any) => {
          console.log("account err", error);
        });
    },
    validate: (values) => {
      const errors: any = {};
      Object.keys(values).forEach((key) => {
        if (!values[key]) {
          errors[key] = i18next.t("This field is required");
        }
      });

      if (values && values.address) {
        if (!errors.address) {
          errors.address = {};
        }

        if (!values?.address?.city) {
          errors.address.city = i18next.t("City is required");
        }
        if (!values?.address?.country) {
          errors.address.country = i18next.t("Country is required");
        }
        if (!values?.address?.firstLine) {
          errors.address.firstLine = i18next.t("First line is required");
        }
        if (!values?.address?.postCode) {
          errors.address.postCode = i18next.t("Post code is required");
        }

        if (errors.address) {
          if (
            Object.values(errors.address).length === 0 &&
            errors.address.constructor === Object
          ) {
            delete errors.address;
          }
        }
      }

      return errors;
    },
    /*
    validationSchema: Yup.object(
      methodItem && methodItem.validation ? methodItem.validation : {}
    ),
    */
  });

  return (
    <div>
      <div
        style={{ paddingTop: 5, paddingBottom: 5 }}
        key={"fieldKeyaaccountHolderName"}
      >
        <Typography className="labelsLocalIban">
          {i18next.t("Account Holder Name")}
        </Typography>
        <TextField
          style={{ width: "100%" }}
          type="text"
          placeholder={""}
          error={
            formik.touched.accountHolderName &&
            formik.errors.accountHolderName !== undefined
          }
          id={"accountHolderName"}
          name={"accountHolderName"}
          value={formik.values["accountHolderName"]}
          onChange={formik.handleChange}
          variant="outlined"
          sx={{
            "& .MuiOutlinedInput-root": {
              paddingRight: "0px!important",
            },
          }}
          InputProps={{
            sx: {
              borderRadius: "10px",
              height: "48px",
              "@media (max-width: 600px)": {
                fontSize: "14px",
                height: "42px",
              },
            },
          }}
        />

        {formik.touched.accountHolderName &&
          formik.errors.accountHolderName && (
            <Typography className="amountErrorForm" style={{ marginTop: 8 }}>
              {formik.errors.accountHolderName!.toString()}
            </Typography>
          )}
      </div>
      {methodItem &&
        methodItem.fields &&
        methodItem.fields.map((groupItem: any, groupKey: number) => {
          return (
            <div key={"groupkey" + groupKey + groupItem.key}>
              {groupItem &&
                groupItem.group &&
                groupItem.group.length > 0 &&
                groupItem.group.map((fieldItem: any, fieldKey: string) => {
                  const fieldName: string = fieldItem.key;
                  if (
                    fieldItem.type &&
                    (fieldItem.type === "select" || fieldItem.type === "radio")
                  ) {
                    if (fieldName.includes(".")) {
                      return (
                        <div
                          style={{ paddingTop: 5, paddingBottom: 5 }}
                          key={"fieldKey" + fieldKey}
                        >
                          <InputLabel
                            id={fieldItem.key + "label"}
                            className="oneRemFontSize"
                            style={{
                              marginBottom: "8px",
                              marginTop: "8px",
                              color: "#000000",
                            }}
                          >
                            {i18next.t(fieldItem.name)}
                          </InputLabel>
                          <Select
                            labelId={fieldName + "label"}
                            id={fieldName}
                            name={fieldName}
                            style={{ width: "100%" }}
                            error={
                              formik.touched[fieldName.split(".")[0]] &&
                              (formik.errors[fieldName.split(".")[0]] as any)?.[
                                fieldName.split(".")[1]
                              ]
                            }
                            sx={{
                              borderRadius: "10px",
                              height: "48px",
                              "& .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input":
                                {
                                  padding: "16.5px 14px 16.5px 14px",
                                },
                              "@media (max-width: 600px)": {
                                height: "42px",
                                fontSize: "14px",
                              },
                            }}
                            defaultValue={
                              formik.initialValues[fieldName.split(".")[0]] &&
                              (
                                formik.initialValues[
                                  fieldName.split(".")[0]
                                ] as any
                              )?.[fieldName.split(".")[1]]
                            }
                            label={fieldItem.name}
                            onChange={formik.handleChange}
                          >
                            {fieldItem.valuesAllowed &&
                              fieldItem.valuesAllowed.length > 0 &&
                              fieldItem.valuesAllowed.map(
                                (menuItem: any, menuIndex: any) => (
                                  <MenuItem
                                    key={"country" + fieldName + menuIndex}
                                    value={menuItem.key}
                                  >
                                    {menuItem.name}
                                  </MenuItem>
                                )
                              )}
                          </Select>

                          {formik.touched[fieldName] &&
                            formik.errors[fieldName] && (
                              <Typography
                                className="amountErrorForm"
                                style={{ marginTop: 8 }}
                              >
                                {formik.errors[fieldName]!.toString()}
                              </Typography>
                            )}
                        </div>
                      );
                    }
                    return (
                      <div
                        style={{ paddingTop: 5, paddingBottom: 5 }}
                        key={"fieldKey" + fieldKey}
                      >
                        <InputLabel
                          id={fieldItem.key + "label"}
                          className="oneRemFontSize"
                          style={{
                            marginBottom: "8px",
                            marginTop: "8px",
                            color: "#000000",
                          }}
                        >
                          {i18next.t(fieldItem.name)}
                        </InputLabel>
                        <Select
                          labelId={fieldName + "label"}
                          id={fieldName}
                          name={fieldName}
                          sx={{
                            borderRadius: "10px",
                            height: "48px",
                            "& .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input":
                              {
                                padding: "16.5px 14px 16.5px 14px",
                              },
                            "@media (max-width: 600px)": {
                              height: "42px",
                              fontSize: "14px",
                            },
                          }}
                          style={{ width: "100%" }}
                          error={
                            formik.touched[fieldName.split(".")[0]] &&
                            (formik.errors[fieldName.split(".")[0]] as any)?.[
                              fieldName.split(".")[1]
                            ]
                          }
                          defaultValue={
                            formik.initialValues &&
                            formik.initialValues[fieldName]
                              ? formik.initialValues[fieldName]
                              : null
                          }
                          label={fieldItem.name}
                          onChange={formik.handleChange}
                        >
                          {fieldItem.valuesAllowed &&
                            fieldItem.valuesAllowed.length > 0 &&
                            fieldItem.valuesAllowed.map(
                              (menuItem: any, menuIndex: any) => (
                                <MenuItem
                                  key={"country" + fieldName + menuIndex}
                                  value={menuItem.key}
                                >
                                  {menuItem.name}
                                </MenuItem>
                              )
                            )}
                        </Select>

                        {formik.touched[fieldName] &&
                          formik.errors[fieldName] && (
                            <Typography
                              className="amountErrorForm"
                              style={{ marginTop: 8 }}
                            >
                              {formik.errors[fieldName]!.toString()}
                            </Typography>
                          )}
                      </div>
                    );
                  } else {
                    if (fieldName.includes(".")) {
                      return (
                        <div
                          style={{ paddingTop: 5, paddingBottom: 5 }}
                          key={"fieldKey" + fieldKey}
                        >
                          <Typography className="labelsLocalIban">
                            {i18next.t(fieldItem.name) ?? ""}
                          </Typography>
                          <TextField
                            style={{ width: "100%" }}
                            type="text"
                            placeholder={fieldItem.example ?? ""}
                            error={
                              formik.touched[fieldName.split(".")[0]] &&
                              (formik.errors[fieldName.split(".")[0]] as any)?.[
                                fieldName.split(".")[1]
                              ]
                            }
                            value={
                              formik.values[fieldName.split(".")[0]] &&
                              (formik.values[fieldName.split(".")[0]] as any)?.[
                                fieldName.split(".")[1]
                              ]
                            }
                            name={fieldName}
                            onChange={formik.handleChange}
                            variant="outlined"
                            sx={{
                              "& .MuiOutlinedInput-root": {
                                paddingRight: "0px!important",
                              },
                            }}
                            InputProps={{
                              sx: {
                                borderRadius: "10px",
                                height: "48px",
                                "@media (max-width: 600px)": {
                                  fontSize: "14px",
                                  height: "42px",
                                },
                              },
                            }}
                          />

                          {formik.touched[fieldName] &&
                            formik.errors[fieldName] && (
                              <Typography
                                className="amountErrorForm"
                                style={{ marginTop: 8 }}
                              >
                                {formik.errors[fieldName]!.toString()}
                              </Typography>
                            )}
                        </div>
                      );
                    } else {
                      return (
                        <div
                          style={{ paddingTop: 5, paddingBottom: 5 }}
                          key={"fieldKey" + fieldKey}
                        >
                          <Typography className="labelsLocalIban">
                            {i18next.t(fieldItem.name) ?? ""}
                          </Typography>
                          <TextField
                            style={{ width: "100%" }}
                            type="text"
                            placeholder={fieldItem.example ?? ""}
                            error={
                              formik.touched[fieldName.split(".")[0]] &&
                              (formik.errors[fieldName.split(".")[0]] as any)?.[
                                fieldName.split(".")[1]
                              ]
                            }
                            value={formik.values[fieldName]}
                            name={fieldName}
                            onChange={formik.handleChange}
                            variant="outlined"
                            sx={{
                              "& .MuiOutlinedInput-root": {
                                paddingRight: "0px!important",
                              },
                            }}
                            InputProps={{
                              sx: {
                                borderRadius: "10px",
                                height: "48px",
                                "@media (max-width: 600px)": {
                                  fontSize: "14px",
                                  height: "42px",
                                },
                              },
                            }}
                          />

                          {formik.touched[fieldName] &&
                            formik.errors[fieldName] && (
                              <Typography
                                className="amountErrorForm"
                                style={{ marginTop: 8 }}
                              >
                                {formik.errors[fieldName]!.toString()}
                              </Typography>
                            )}
                        </div>
                      );
                    }
                  }
                })}
            </div>
          );
        })}

      <div className="d-flex ai-center jusifyContentSpaceBetween mt-8">
        <Button
          type="submit"
          fullWidth
          variant="contained"
          className="continueBtnRecipient oneRemFontSize btnsLoginHeight"
          disabled={false}
          onClick={(e: any) => formik.handleSubmit(e)}
        >
          {i18next.t("Save")}
        </Button>
      </div>
    </div>
  );
}

export default DynamicForm;
