import { Box } from "@mui/material";
import loadingImage from "../../assets/images/loading.gif";
const LoadingIndicator = () => (
  <Box
    sx={{
      display: "flex",
      flexDirection: "column",
      marginBottom: "4rem",
      marginTop: "4rem",
    }}
  >
    <img src={loadingImage} width="97" alt="Loading" />
  </Box>
);

export default LoadingIndicator;
