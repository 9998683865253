import { Box, Radio, Typography } from "@mui/material";
import "./FiltersDrawer.scss";
import All from "../../assets/images/all.png";
import CheckIcon from "@mui/icons-material/Check";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import PendingIcon from "@mui/icons-material/Pending";
import BlockIcon from "@mui/icons-material/Block";
import AddIcon from "@mui/icons-material/Add";
import i18next from "src/i18n";

interface FiltersProps {
  selectedFilters: any;
  handleSelectedFilters: (filters: any) => void;
}
function StatusFilters({
  selectedFilters,
  handleSelectedFilters,
}: FiltersProps) {
  const handleToggleDirection = (status: string) => {
    const filters: any = { ...selectedFilters };
    if (!filters.status) filters.status = {};
    filters.status.selectedStatus = status;
    handleSelectedFilters(filters);
  };

  const selectedStatus = selectedFilters.status
    ? selectedFilters.status.selectedStatus
    : null;

  const radioStyles = {
    color: "#2a5182",
  };

  return (
    <Box>
      <Typography className="subBodies">{i18next.t("Status")}</Typography>
      <Box
        className="selectableBoxesHover"
        onClick={() => handleToggleDirection("All")}
      >
        <Box className="imageDirectionsAndRadio">
          <Box className="imageDirectionAndText">
            <Box className="imageDirectionBG">
              <img
                style={{ objectFit: "cover" }}
                alt="All"
                className="allImage"
                src={All}
              />
            </Box>
            <Typography className="directionText">
              {i18next.t("All")}
            </Typography>
          </Box>
          <Radio
            checked={selectedStatus === "All"}
            value="All"
            name="radio-buttons-direction"
            style={radioStyles}
            inputProps={{ "aria-label": "" }}
            sx={{
              "& .MuiSvgIcon-root": {
                fontSize: 24,
              },
            }}
          />
        </Box>
      </Box>
      <Box
        className="selectableBoxesHover"
        onClick={() => handleToggleDirection("transaction_payment_waiting")}
      >
        <Box className="imageDirectionsAndRadio">
          <Box className="imageDirectionAndText">
            <Box className="imageDirectionBG">
              <AddIcon className="searchIconTransactionFilters" />
            </Box>
            <Typography className="directionText">
              {i18next.t("Created")}
            </Typography>
          </Box>
          <Radio
            checked={selectedStatus === "transaction_payment_waiting"}
            value="transaction_payment_waiting"
            style={radioStyles}
            name="radio-buttons-direction"
            inputProps={{ "aria-label": "" }}
            sx={{
              "& .MuiSvgIcon-root": {
                fontSize: 24,
              },
            }}
          />
        </Box>
      </Box>
      <Box
        className="selectableBoxesHover"
        onClick={() => handleToggleDirection("transaction_payment_processing")}
      >
        <Box className="imageDirectionsAndRadio">
          <Box className="imageDirectionAndText">
            <Box className="imageDirectionBG">
              <PendingIcon className="searchIconTransactionFilters" />
            </Box>
            <Typography className="directionText">
              {i18next.t("In process")}
            </Typography>
          </Box>
          <Radio
            checked={selectedStatus === "transaction_payment_processing"}
            style={radioStyles}
            value="transaction_payment_processing"
            name="radio-buttons-direction"
            inputProps={{ "aria-label": "" }}
            sx={{
              "& .MuiSvgIcon-root": {
                fontSize: 24,
              },
            }}
          />
        </Box>
      </Box>
      <Box
        className="selectableBoxesHover"
        onClick={() => handleToggleDirection("blocked")}
      >
        <Box className="imageDirectionsAndRadio">
          <Box className="imageDirectionAndText">
            <Box className="imageDirectionBG">
              <RemoveCircleOutlineIcon className="searchIconTransactionFilters" />
            </Box>
            <Typography className="directionText">
              {i18next.t("Blocked")}
            </Typography>
          </Box>
          <Radio
            checked={selectedStatus === "blocked"}
            style={radioStyles}
            value="blocked"
            name="radio-buttons-direction"
            inputProps={{ "aria-label": "" }}
            sx={{
              "& .MuiSvgIcon-root": {
                fontSize: 24,
              },
            }}
          />
        </Box>
      </Box>
      <Box
        className="selectableBoxesHover"
        onClick={() => handleToggleDirection("completed")}
      >
        <Box className="imageDirectionsAndRadio">
          <Box className="imageDirectionAndText">
            <Box className="imageDirectionBG">
              <CheckIcon className="searchIconTransactionFilters" />
            </Box>
            <Typography className="directionText">
              {i18next.t("Completed")}
            </Typography>
          </Box>
          <Radio
            checked={selectedStatus === "completed"}
            value="completed"
            style={radioStyles}
            name="radio-buttons-direction"
            inputProps={{ "aria-label": "" }}
            sx={{
              "& .MuiSvgIcon-root": {
                fontSize: 24,
              },
            }}
          />
        </Box>
      </Box>
      <Box
        className="selectableBoxesHover"
        onClick={() => handleToggleDirection("transaction_rejected")}
      >
        <Box className="imageDirectionsAndRadio">
          <Box className="imageDirectionAndText">
            <Box className="imageDirectionBG">
              <BlockIcon className="searchIconTransactionFilters" />
            </Box>
            <Typography className="directionText">
              {i18next.t("Rejected")}
            </Typography>
          </Box>
          <Radio
            checked={selectedStatus === "transaction_rejected"}
            value="transaction_rejected"
            style={radioStyles}
            name="radio-buttons-direction"
            inputProps={{ "aria-label": "" }}
            sx={{
              "& .MuiSvgIcon-root": {
                fontSize: 24,
              },
            }}
          />
        </Box>
      </Box>
    </Box>
  );
}

export default StatusFilters;
