import React, { useState, useEffect } from "react";
import "./CreateTransaction.scss";
import EnterpaymentNav from "./components/EnterpaymentNav";
import AmountStep from "src/components/CreateTransaction/AmountStep";
import RecipentStep from "src/components/Enterpayment/RecipentStep";
import ReviewStep from "src/components/CreateTransaction/ReviewStep";
import PaymentStep from "src/components/Enterpayment/PaymentStep";
import OTPVerification from "src/components/shared/OTPVerification";
import { useNavigate } from "react-router-dom";
import api from "src/store/interceptors/api";
import { Box } from "@mui/material";
import CurrencyStep from "src/components/CreateTransaction/CurrencyStep";

const CreateTransaction: React.FC = () => {
  const navigate = useNavigate();
  const [step, setStep] = useState(0);
  const [transactionType, setTransactionType] = useState("International");
  const [quest, setQuest] = useState(null);
  const [accountData, setAccountData] = useState(null);
  const [transfer, setTransfer] = useState(null);

  const getMe = async () => {
    try {
      await api
        .get("/me")
        .then((response: any) => {
          if (response) {
            if (
              response &&
              response.data &&
              response.data.data &&
              response.data.data.verification_approved === 0
            ) {
              if (response.data.data.verification_requested === 1) {
                alert("Your account is in review!");
                navigate("/home");
              } else {
                alert("Please complete the banking onboarding!");
                navigate("/banking");
              }
            }

            if (
              response &&
              response.data &&
              response.data.data &&
              response.data.data.role &&
              response.data.data.role === "Employee"
            ) {
              navigate("/admin");
            }
          }
        })
        .catch((error) => {
          navigate("/home");
        });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getMe();
  }, []);

  const [handleRequested, setHandleRequested] = useState(false);
  const [isVerified, setIsVerified] = useState(false);
  const [uuid, setUuid] = useState(null);

  const handleRequestVerification = async (stepValue: any) => {
    if (!handleRequested) {
      setHandleRequested(true);
    }
    setStep(stepValue);
  };

  const handleVerification = (data: any) => {
    setIsVerified(true);
    setUuid(data.uuid);
    setHandleRequested(false);
  };
  return (
    <div>
      <EnterpaymentNav
        step={step}
        isStepperVisible={true}
        isProfileSetVisible={true}
      />

      {step === 0 && (
        <CurrencyStep
          setTransactionType={setTransactionType}
          setQuest={setQuest}
          step={step}
          navigate={navigate}
          setStep={setStep}
        />
      )}

      {step === 1 && (
        <RecipentStep
          transactionType={transactionType}
          setAccountData={setAccountData}
          quest={quest}
          step={step}
          setStep={setStep}
        />
      )}

      {step === 2 && (
        <AmountStep 
          setQuest={setQuest}
          transactionType={transactionType}
          step={step}
          navigate={navigate}
          setStep={setStep}
          accountData={accountData}
          quest={quest}
          handleRequestVerification={handleRequestVerification}
        />
      )}
      <Box sx={{ paddingLeft: "16px", paddingRight: "16px" }}>
        {!isVerified && handleRequested && (
          <OTPVerification onSubmit={handleVerification} />
        )}
      </Box>

      {isVerified && step === 3 && (
        <ReviewStep
          step={step}
          setStep={setStep}
          setTransfer={setTransfer}
          accountData={accountData}
          quest={quest}
          uuid={uuid}
        />
      )}

      {isVerified && step === 4 && <PaymentStep transfer={transfer} />}
    </div>
  );
};

export default CreateTransaction;
