import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Nav from "src/components/layout/nav/Nav";
import { Box, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import LoadingIndicator from "src/components/shared/LoadingIndicator";
import { useNavigate, useParams } from "react-router-dom";
import investmentsServices from "src/store/investments/investments_services";
import InvestmentDetail from "src/components/Investments/InvestmentDetail";

const defaultTheme = createTheme();

export default function InvestmentSingle() {
  const { investmentId }: any = useParams();
  const [investmentsData, setInvestmentsData] = useState<any>(null);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();

  const handleGetInvestment = async () => {
    setIsLoading(true);

    const response = await investmentsServices.getInvestmentsById(investmentId);
    const { data } = response;
    setIsLoading(false);
    if (data) {
      setInvestmentsData(data);
    }
  };

  const handleNavigate = () => {
    navigate(-1);
    //navigate("/transactions-list");
  };

  useEffect(() => {
    handleGetInvestment();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <Nav step={0} isStepperVisible={false} isProfileSetVisible={true} />
      <div className="main-admin-container">

        <div className="content-container-profile main-color">
          <ThemeProvider theme={defaultTheme}>
            <Container component="main">
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <div className="d-flex ai-center mb-3-rem">
                  <p
                    className="back-button-icon circle-around-text-smaller helvetica mr-1-rem cp"
                    onClick={handleNavigate}
                  >
                    <ArrowBackIcon className="muiIcons"/>
                  </p>
                  <Typography
                    className="your-details-text d-flex jc-start pageTitleFontSize"
                    component="h1"
                    variant="h5"
                    sx={{
                      fontFamily: "Helvetica, sans-serif",
                      textAlign: "center",
                      fontWeight: "600",
                    }}
                  >
                    Investment details
                  </Typography>
                </div>
                <div>
                  {isLoading ? (
                    <LoadingIndicator />
                  ) : investmentsData ? (
                    <div>
                      <InvestmentDetail handleGetInvestment={handleGetInvestment} fromPage="admin" data={investmentsData} />
                    </div>
                  ) : (
                    <div>No data found!</div>
                  )}
                </div>
              </Box>
            </Container>
          </ThemeProvider>
        </div>
      </div>
    </div>
  );
}
