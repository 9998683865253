import React from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Box, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import HeaderLanding from "../components/HeaderLanding/HeaderLanding";
import { useAppSelector } from "src/store";
import FooterLanding from "../components/FooterLanding/FooterLanding";
import "./LandingPagesGlobal.scss";
import TitleBackBox from "../components/TitleBackBox/TitleBackBox";
import HeadlineVvidgetSmall from "../components/HeadlineVvidget/HeadlineVvidgetSmall";
import i18next from "i18next";
const defaultTheme = createTheme();
const ClientSecurity: React.FC = () => {
  const { user } = useAppSelector((state) => state.auth);

  const navigate = useNavigate();

  const navigateToContactUs = (event: any) => {
    navigate("/contact-us");
  };

  return (
    <div>
      <HeaderLanding user={user} />
      <div className="margintTopPagesMainCont">
        <ThemeProvider theme={defaultTheme}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Box className="titleHeadlineSmallBG">
              <Box className="landingPagesMainBox">
                <Box>
                  <TitleBackBox title={i18next.t("Client Security")} />
                </Box>
                <Box>
                  <HeadlineVvidgetSmall
                    headline={i18next.t("Better safe than sorry")}
                    subtitle={""}
                    smallText={i18next.t("Contact ourt support team")}
                    buttonText={i18next.t("Contact Us")}
                    onClickFunction={navigateToContactUs}
                  />
                </Box>
              </Box>
            </Box>
            <Box className="landingPagesMainBox">
              <Box>
                <Typography
                  variant="h3"
                  paragraph
                  className="headlineMiniLandingPages"
                >
                  <strong>{i18next.t("Cybersecurity Preparedness")}</strong>
                </Typography>
                <Typography
                  variant="body1"
                  paragraph
                  className="textsLandingPage"
                >
                  {i18next.t("Cyber attacks on the systems of banks, financial service providers and their customers have been on the rise worldwide since 2015, but we at VicPay are well prepared")}
                </Typography>
              </Box>
              <Box>
                <Typography
                  variant="h3"
                  paragraph
                  className="headlineMiniLandingPages"
                >
                  <strong>{i18next.t("Modern Security Measures")}</strong>
                </Typography>
                <Typography
                  variant="body1"
                  paragraph
                  className="textsLandingPage"
                >
                  {i18next.t("With our modern security measures, you can conveniently carry out your payment transactions online from anywhere in the world and do not have to fear hacker attacks and their consequences")}
                </Typography>
              </Box>
              <Box>
                <Typography
                  variant="h3"
                  paragraph
                  className="headlineMiniLandingPages"
                >
                  <strong>{i18next.t("Fraud Prevention Education")}</strong>
                </Typography>
                <Typography
                  variant="body1"
                  paragraph
                  className="textsLandingPage"
                >
                  {i18next.t("We regularly inform you about how you can protect yourself against known fraud patterns and the latest approaches used by fraudsters. In this way, we educate you and minimise the risks to your digital payment transactions")}
                </Typography>
              </Box>
              <Box>
                <Typography
                  variant="h3"
                  paragraph
                  className="headlineMiniLandingPages"
                >
                  <strong>{i18next.t("Employee Training for Fraud Recognition")}</strong>
                </Typography>
                <Typography
                  variant="body1"
                  paragraph
                  className="textsLandingPage"
                >
                  {i18next.t("In addition, we regularly coach our employees to recognise fraud in good time and are constantly developing our security measures to protect our servers and systems against phishing and Trojans")}
                </Typography>
              </Box>
              <Box>
                <Typography
                  variant="h3"
                  paragraph
                  className="headlineMiniLandingPages"
                >
                  <strong>{i18next.t("Real-Time Monitoring for Fraud Prevention")}</strong>
                </Typography>
                <Typography
                  variant="body1"
                  paragraph
                  className="textsLandingPage"
                >
                  {i18next.t("We monitor our customers' online user behaviour and payment flows in real time, both manually and with the help of AI systems. This enables us to stop potentially fraudulent access or transactions and inform our customers accordingly")}
                </Typography>
              </Box>
              <Box>
                <Typography
                  variant="h3"
                  paragraph
                  className="headlineMiniLandingPages"
                >
                  <strong>{i18next.t("Securing Confidential Information")}</strong>
                </Typography>
                <Typography
                  variant="body1"
                  paragraph
                  className="textsLandingPage"
                >
                  {i18next.t("Confidential information, such as account statements or transaction confirmations, requires special protection against access and manipulation by third parties. We therefore protect our servers in particular to ensure secure e-mail communication with you")}
                </Typography>
              </Box>
              <Box>
                <Typography
                  variant="h3"
                  paragraph
                  className="headlineMiniLandingPages"
                >
                  <strong>{i18next.t("Compliance and Security")}</strong>
                </Typography>
                <Typography
                  variant="body1"
                  paragraph
                  className="textsLandingPage"
                >
                  {i18next.t("In case of doubt, our compliance and security employees are automatically notified so that dubious transactions can be recognised")}
                </Typography>
              </Box>
              <Box>
                <Typography
                  variant="h3"
                  paragraph
                  className="headlineMiniLandingPages"
                >
                  <strong>{i18next.t("Transparent Transaction Monitoring")}</strong>
                </Typography>
                <Typography
                  variant="body1"
                  paragraph
                  className="textsLandingPage"
                >
                  {i18next.t("In the case of unusually high contributions and transactions in certain jurisdictions, information about the transaction is provided during execution")}
                </Typography>
              </Box>
              <Box>
                <Typography
                  variant="h3"
                  paragraph
                  className="headlineMiniLandingPages"
                >
                  <strong>{i18next.t("Ensuring Account Security")}</strong>
                </Typography>
                <Typography
                  variant="body1"
                  paragraph
                  className="textsLandingPage"
                >
                  {i18next.t("As you can see, we do everything we can to ensure that your accounts are secure")}
                </Typography>
                <Typography
                  variant="body1"
                  paragraph
                  className="textsLandingPage"
                >
                  {i18next.t("If you would like to find out more about the security of your deposits, simply")}
                  {" "}
                  <span
                    className="linksHover"
                    onClick={() => navigate("/safety-of-funds")}
                  >
                    {i18next.t("click here")}
                  </span>{" "}
                  {i18next.t("or")}{" "}
                  <span
                    className="linksHover"
                    onClick={() => navigate("/registration")}
                  >
                    {i18next.t("click here")}
                  </span>{" "}
                  {i18next.t("to open an account with VicPay")}
                </Typography>
              </Box>
            </Box>
          </Box>
        </ThemeProvider>
      </div>
      <Box sx={{ marginTop: "4rem" }}>
        <FooterLanding />
      </Box>
    </div>
  );
};

export default ClientSecurity;
