import * as React from "react";
import { useEffect } from "react";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import "./ForgetPassword.scss";
import { useNavigate } from "react-router-dom";
import Nav from "src/components/layout/nav/Nav";
import { useAppSelector } from "src/store";
import imageUrlKey from "src/assets/images/key-small@2x.webp";
import OTPInput from "src/components/Registration/OTPInput";
import api from "src/store/interceptors/api";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import * as Yup from "yup";
import CustomInput from "src/components/shared/CustomInput/CustomInput";
import i18next from "i18next";

const defaultTheme = createTheme();

export default function ForgetPassword() {
  const navigate = useNavigate();
  const { isSuccess, token } = useAppSelector((state) => state.auth);
  const [isCodeWrong, setSsCodeWrong] = React.useState(false);
  const [isCodeSent, setIsCodeSent] = React.useState(false);

  const [inputValues, setInputValues] = React.useState({
    input1: "",
    input2: "",
    input3: "",
    input4: "",
    input5: "",
    input6: "",
  });

  const handleInputChange = (inputId: string, value: number) => {
    setSsCodeWrong(false);
    setInputValues((prevInputValues) => ({
      ...prevInputValues,
      [inputId]: value,
    }));
  };

  const isOtpLessThanSix = () => {
    const otpValue = Object.values(inputValues).join("");
    return otpValue.length < 6;
  };

  const imageStyles = {
    width: "150px",
    height: "150px",
  };

  const formikEmail = useFormik({
    initialValues: { email: "" },
    validationSchema: Yup.object({
      email: Yup.string()
        .email(i18next.t("Invalid email address"))
        .required(i18next.t("Email is required")),
    }),
    onSubmit: async (values) => {
      try {
        const response = await api.post("forgot-password-mobile", {
          email: values.email,
        });
        if (response.status === 200) {
          setIsCodeSent(true);
        } else {
          console.error("Failed to send password reset code:", response.status);
        }
      } catch (error) {
        console.error("Error sending password reset code:", error);
      }
    },
    validateOnChange: false,
    validateOnBlur: false,
  });

  const formikPassword = useFormik({
    initialValues: {
      password: "",
      confirmPassword: "",
    },
    validationSchema: Yup.object({
      password: Yup.string()
        .required(i18next.t("Password is required"))
        .min(8, i18next.t("Password must be at least 8 characters")),
      confirmPassword: Yup.string()
        .oneOf([Yup.ref("password")], i18next.t("Passwords must match"))
        .required(i18next.t("Confirm Password is required")),
    }),
    onSubmit: async (values) => {
      const { input1, input2, input3, input4, input5, input6 } = inputValues;
      const otp = `${input1}${input2}${input3}${input4}${input5}${input6}`;

      try {
        const data = {
          email: formikEmail.values.email,
          token: otp,
          password: values.password,
          password_confirmation: values.confirmPassword,
        };
        const response = await api.post("reset-password-mobile", data);

        if (response.status === 200) {
          navigate("/home");
          toast.success(i18next.t("Password reset successfully"));
        } else {
          setSsCodeWrong(true);
        }
      } catch (error: any) {
        if (error?.response?.data?.msg.includes("Invalid code provided")) {
          setSsCodeWrong(true);
        }
      }
    },
    validateOnChange: false,
    validateOnBlur: false,
  });

  const handleSubmit = (event: any) => {};
  useEffect(() => {
    if (isSuccess || token) {
      window.location.href = "/home";
    }
  }, [isSuccess, token]);

  return (
    <>
      {!token && (
        <Box>
          <Nav step={0} isStepperVisible={false} />
          <Box className="content-container">
            <ThemeProvider theme={defaultTheme}>
              <Container component="main">
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <Box className="ta-center">
                    <img src={imageUrlKey} alt="df" style={imageStyles} />
                  </Box>

                  <Typography
                    className="mb-1rem mt-1-1rem login-text ta-center loginTitleFontSize"
                    component="h1"
                    variant="h5"
                    style={{ marginTop: "2.1rem" }}
                  >
                    {i18next.t("Reset password")}
                  </Typography>

                  {/* isCodeSent */}
                  {isCodeSent ? (
                    <Typography
                      component="h1"
                      variant="h5"
                      className="registration0875RemFontSize"
                      sx={{
                        fontFamily: "Helvetica, sans-serif",
                        fontWeight: "300",
                        color: "#2A5182",
                        textAlign: "center",
                      }}
                    >
                      {i18next.t(
                        "Only fraudsters will tell you what password to use. Make sure your password is unique and only known to you. Also please enter valid code that is sent to your email"
                      )}
                    </Typography>
                  ) : (
                    <Typography
                      component="h1"
                      variant="h5"
                      className="registration0875RemFontSize"
                      sx={{
                        fontFamily: "Helvetica, sans-serif",
                        fontWeight: "300",
                        color: "#2A5182",
                        textAlign: "center",
                      }}
                    >
                      {i18next.t(
                        "Just enter the email address you registered with and we’ll send you a code to reset your password"
                      )}
                    </Typography>
                  )}

                  {isCodeSent ? (
                    <>
                      {/* Otp start */}
                      <Box
                        id="otp"
                        className="inputs d-flex flex-row justify-content-center mt-2 center-otp"
                      >
                        <OTPInput
                          id="input1"
                          value={inputValues.input1}
                          onValueChange={handleInputChange}
                          previousId={null}
                          handleSubmit={handleSubmit}
                          nextId="input2"
                        />
                        <OTPInput
                          id="input2"
                          value={inputValues.input2}
                          onValueChange={handleInputChange}
                          previousId="input1"
                          handleSubmit={handleSubmit}
                          nextId="input3"
                        />
                        <OTPInput
                          id="input3"
                          value={inputValues.input3}
                          onValueChange={handleInputChange}
                          previousId="input2"
                          handleSubmit={handleSubmit}
                          nextId="input4"
                        />
                        <OTPInput
                          id="input4"
                          value={inputValues.input4}
                          onValueChange={handleInputChange}
                          previousId="input3"
                          handleSubmit={handleSubmit}
                          nextId="input5"
                        />

                        <OTPInput
                          id="input5"
                          value={inputValues.input5}
                          onValueChange={handleInputChange}
                          previousId="input4"
                          handleSubmit={handleSubmit}
                          nextId="input6"
                        />

                        <OTPInput
                          id="input6"
                          value={inputValues.input6}
                          onValueChange={handleInputChange}
                          previousId="input5"
                          handleSubmit={handleSubmit}
                          nextId="input7"
                        />
                      </Box>

                      {isCodeWrong && (
                        <Typography
                          component="h1"
                          variant="h5"
                          sx={{
                            fontFamily: "Roboto, sans-serif",
                            fontSize: "12px",
                            color: "#d32f2f",
                            textAlign: "center",
                            marginTop: "8px",
                            fontWeight: "400",
                          }}
                        >
                          {i18next.t("Invalid code provided")}
                        </Typography>
                      )}
                      {/* Otp end */}
                      <Box sx={{ mt: 3 }}>
                        <CustomInput
                          id="password"
                          placeHolder={i18next.t("Password")}
                          value={formikPassword.values.password}
                          onChangeText={(value) =>
                            formikPassword.setFieldValue("password", value)
                          }
                          isTouched={
                            formikPassword.touched.password ||
                            formikPassword.submitCount > 0
                          }
                          errors={formikPassword.errors.password}
                          type="password"
                          isRequired
                          isPassword
                          nextId="confirmPassword"
                        />
                        <CustomInput
                          id="confirmPassword"
                          placeHolder={i18next.t("Confirm Password")}
                          value={formikPassword.values.confirmPassword}
                          onChangeText={(value) =>
                            formikPassword.setFieldValue(
                              "confirmPassword",
                              value
                            )
                          }
                          isTouched={
                            formikPassword.touched.confirmPassword ||
                            formikPassword.submitCount > 0
                          }
                          errors={formikPassword.errors.confirmPassword}
                          type="password"
                          isRequired
                          isPassword
                        />
                      </Box>

                      <Button
                        fullWidth
                        variant="contained"
                        onClick={() => {
                          formikPassword.handleSubmit();
                        }}
                        className="oneRemFontSize btnsLoginHeight"
                        sx={{
                          color: "white",
                          backgroundColor: "#2A5182",
                          borderRadius: "24px",
                          textTransform: "none",
                          marginTop: "1rem",
                          fontFamily: "Helvetica, sans-serif",
                        }}
                        disabled={
                          !formikPassword.values.password ||
                          !formikPassword.values.confirmPassword ||
                          isOtpLessThanSix()
                        }
                      >
                        {i18next.t("Reset password")}
                      </Button>
                    </>
                  ) : (
                    <Box
                      component="form"
                      onSubmit={formikEmail.handleSubmit}
                      noValidate
                      sx={{ mt: 4 }}
                    >
                      <CustomInput
                        id="email"
                        placeHolder={i18next.t("Enter Your Email Address")}
                        value={formikEmail.values.email}
                        onChangeText={(value) =>
                          formikEmail.setFieldValue("email", value)
                        }
                        isTouched={
                          formikEmail.touched.email ||
                          formikEmail.submitCount > 0
                        }
                        errors={formikEmail.errors.email}
                        type="text"
                        isRequired={true}
                      />

                      <Button
                        fullWidth
                        variant="contained"
                        onClick={() => formikEmail.handleSubmit()}
                        disabled={
                          !formikEmail.values.email ||
                          formikEmail.values.email.length < 2 ||
                          !formikEmail.values.email.includes("@")
                        }
                        className="btnsLoginHeight oneRemFontSize"
                        sx={{
                          color: "white",
                          backgroundColor: "#2A5182",
                          borderRadius: "24px",
                          textTransform: "none",
                          marginTop: "1rem",
                          fontFamily: "Helvetica, sans-serif",
                        }}
                      >
                        Request password reset
                      </Button>
                    </Box>
                  )}
                </Box>
              </Container>
            </ThemeProvider>
          </Box>
        </Box>
      )}
    </>
  );
}
