import React, { useState, useEffect, ReactNode } from "react";
import { useUser } from "src/hooks/useUser";
import LoginPINModal from "./PINModal/LoginPINModal";

interface InactivityTimeoutProps {
  children: ReactNode; // This allows any valid React node(s)
}

const InactivityTimeout: React.FC<InactivityTimeoutProps> = ({ children }) => {
  const user = useUser();

  const [showModal, setShowModal] = useState(() => {
    const isModalShown = localStorage.getItem("isModalShown");
    return isModalShown === "true";
  });

  useEffect(() => {
    let timeout: any;

    const handleActivity = () => {
      clearTimeout(timeout);
      timeout = setTimeout(() => {
        setShowModal(true);
      }, 60000);
    };
    window.addEventListener("mousemove", handleActivity);
    window.addEventListener("keydown", handleActivity);

    // Set initial timeout
    timeout = setTimeout(() => {
      setShowModal(true);
    }, 60000);

    return () => {
      clearTimeout(timeout);
      window.removeEventListener("mousemove", handleActivity);
      window.removeEventListener("keydown", handleActivity);
    };
  }, []);

  useEffect(() => {
    localStorage.setItem("isModalShown", showModal.toString());
  }, [showModal]);

  const handleCloseModal = () => {
    setShowModal(false);
    localStorage.setItem("isModalShown", "false");
  };

  return (
    <>
      {user && user.login_with_pin && <LoginPINModal
        user={user}
        onClose={handleCloseModal}
        showModal={showModal}
      />}

      {children}
    </>
  );
};

export default InactivityTimeout;
