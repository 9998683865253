import React from "react";
import { Avatar, Box, Checkbox, Typography } from "@mui/material";
import GppBadIcon from "@mui/icons-material/GppBad";
import VerifiedIcon from "@mui/icons-material/Verified";

interface SingleUserItemProps {
  data: any;
  index: number;
  isSelected?: boolean | undefined;
  type: string;
  onSpecificSelectUser: (userId: number) => void;
}

const SingleUserItem: React.FC<SingleUserItemProps> = ({
  data,
  index,
  isSelected,
  type,
  onSpecificSelectUser,
}) => {
  const handleCheckboxChange = () => {
    if (onSpecificSelectUser) {
      onSpecificSelectUser(data.id);
    }
  };

  const itemStyle = isSelected ? { backgroundColor: "#edefeb" } : {};

  const userName =
    data && data.company_name && data.account_type === "business"
      ? data.company_name
      : data.first_name && data.last_name
      ? data.first_name + " " + data.last_name
      : "";
  const email = data && data.email ? data.email : " - ";

  return (
    <label
      key={"recipient" + index}
      className="allRecipientSingle-acc"
      style={itemStyle}
    >
      <Box className="w-100">
        <Box className="allRecipientInfo">
          <Box className="avatarAndNameEndingIn">
            <Avatar
              style={{
                width: 48,
                height: 48,
                backgroundColor: "#E5E8E2",
              }}
            >
              {userName && (
                <>
                  <span className="spanAvatar">
                    {userName
                      .split(" ")
                      .map((namePart: any, index: number) =>
                        index < 2 ? namePart.charAt(0).toUpperCase() : ""
                      )
                      .join("")}
                  </span>
                </>
              )}
            </Avatar>
            <div className="d-flex ai-center">
              <Box sx={{ paddingRight: 2 }}>
                {data && data.verification_approved ? (
                  <VerifiedIcon style={{ color: "#00c943" }} />
                ) : (
                  <GppBadIcon style={{ color: "#c90014" }} />
                )}
              </Box>
              <Box className="nameAndEndingIn mr-1rem">
                <Typography className="allRecipientName">{userName}</Typography>
                <Typography className="allRecipientEndingIn">
                  {email}
                </Typography>
              </Box>
            </div>
          </Box>
          <Box>
            <Checkbox checked={isSelected} onChange={handleCheckboxChange} />
          </Box>
        </Box>
      </Box>
    </label>
  );
};

export default SingleUserItem;
