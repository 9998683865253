import { configureStore } from "@reduxjs/toolkit";

import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";

import registerReducer from "./register/register_slice";
import authReducer from "./login/login_slice";
import userProfileReducer from "./profile/profile_slice";
import transactionsReducer from "./transactions/transactions_slice";
import recipientReducer from "../store/recipients/recipients_slice";

const store = configureStore({
  reducer: {
    register: registerReducer,
    auth: authReducer,
    userProfile: userProfileReducer,
    transactions: transactionsReducer,
    recipient: recipientReducer,
  },
});

export default store;
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
