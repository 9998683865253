import { ThemeProvider } from "@emotion/react";
import { Typography, createTheme } from "@mui/material";
import { useState, useEffect } from "react";
import "./RecipientStep.scss";
import WhoAreYourSendingMoneyToStep from "./WhoAreYourSendingMoneyToStep";
import DynamicPaymentForm from "./DynamicPaymentForm";
import * as Yup from "yup";
import api from "src/store/interceptors/api";
import { useNavigate } from "react-router-dom";
import i18next from "i18next";
import DynamicPaymentForm3S from "./DynamicPaymentForm3S";
import { sortRecipientFields } from "src/utils/helpers";

interface Props {
  setStep: (value: number) => void;
  step: number;
  quest: any;
  transactionType: any;
  setAccountData: (values: any) => void;
}

const defaultTheme = createTheme();

const createValidation = (key: string) => {
  let currentObject: any = {};
  if (key.includes(".")) {
    const keys = key.split(".");
    keys.forEach((keyItem: any, keyIndex: any) => {
      if (!currentObject[keyItem]) {
        currentObject[keyItem] =
          keyIndex === keys.length - 1
            ? ""
            : Yup.string().required(i18next.t("This field is required"));
      }
      currentObject = currentObject[keyItem];
    });
  } else {
    currentObject[key] = Yup.string().required(
      i18next.t("This field is required")
    );
  }
  return currentObject;
};

function RecipentStep({
  step,
  setStep,
  quest,
  transactionType,
  setAccountData,
}: Props) {
  //pocetak

  const navigate = useNavigate();

  const handleNavigateProfile = () => {
    navigate("/profile");
  };

  const [methods, setMethods] = useState<any>();

  const [userApproved, setUserApproved] = useState(false);
  const handleGetRequirements = async () => {
    try {
      if (quest) {
        let url = "/account-requirements?sourceCurrency=";
        if (quest.sourceCurrency) {
          url += quest.sourceCurrency;
        }
        if (quest.targetCurrency) {
          url += "&targetCurrency=" + quest.targetCurrency;
        }
        if (quest.formated.sourceAmount) {
          url += "&sourceAmount=" + quest.formated.sourceAmount;
        }
        if (quest.formated.targetAmount) {
          url += "&targetAmount=" + quest.formated.targetAmount;
        }

        const res = await api.get(url);
        if (res && res.data) {
          const resMethods = res.data;

          let aryMethods: any = [];

          resMethods.forEach((resItem: any) => {
            if (resItem.title !== "Email" && resItem.fields) {
              let tmpFormFields: any = {};
              let aryValidation: any = {};

              // console.log(resItem, resItem.fields);
              if (
                resItem.provider &&
                resItem.provider === "3S" &&
                resItem.fields
              ) {
                const methodFields = sortRecipientFields(resItem.fields);
                resItem.fields = [...methodFields];

                methodFields.forEach((groupItem: any) => {
                  //  console.log("resfield", groupItem);
                  if (
                    groupItem.id &&
                    groupItem.required &&
                    (groupItem.required === "required" ||
                      groupItem.id === "account-number")
                  ) {
                    // Check if the key includes dots (nested field)
                    if (!groupItem.name.includes("country")) {
                      tmpFormFields[groupItem.id] = "";
                    } else {
                      tmpFormFields[groupItem.id] = "";
                    }

                    const validationSchema = createValidation(groupItem.id);
                    aryValidation = {
                      ...aryValidation,
                      ...validationSchema,
                    };
                  }
                });
              } else {
                resItem.fields.forEach((resField: any) => {
                  if (resField.group) {
                    resField.group.forEach((groupItem: any) => {
                      if (groupItem.key) {
                        // Check if the key includes dots (nested field)
                        if (groupItem.key.includes(".")) {
                          const keys = groupItem.key.split(".");
                          let currentObject = tmpFormFields;

                          keys.forEach((key: any, index: any) => {
                            if (!currentObject[key]) {
                              currentObject[key] =
                                index === keys.length - 1 ? "" : {};
                            }
                            currentObject = currentObject[key];
                          });
                        } else {
                          if (!groupItem.key.includes("country")) {
                            tmpFormFields[groupItem.key] = "";
                          } else {
                            tmpFormFields[groupItem.key] = "";
                          }
                        }

                        if (
                          groupItem.valuesAllowed &&
                          groupItem.valuesAllowed.length > 0
                        ) {
                          if (!groupItem.key.includes("country")) {
                            if (
                              groupItem.valuesAllowed[0] &&
                              groupItem.valuesAllowed[0].key
                            ) {
                              tmpFormFields[groupItem.key] =
                                groupItem.valuesAllowed[0].key;
                            }
                          }
                        }
                      }

                      if (
                        groupItem.key &&
                        groupItem.validationRegexp &&
                        groupItem.required
                      ) {
                        const validationSchema = createValidation(
                          groupItem.key
                        );
                        aryValidation = {
                          ...aryValidation,
                          ...validationSchema,
                        };
                      } else {
                        if (groupItem.required && groupItem.key) {
                          const validationSchema = createValidation(
                            groupItem.key
                          );
                          aryValidation = {
                            ...aryValidation,
                            ...validationSchema,
                          };
                        }
                      }
                    });
                  }
                });
              }

              aryMethods.push({
                ...resItem,
                fieldsForm: tmpFormFields,
                validation: aryValidation,
              });
            }
          });

        //  console.log(aryMethods);

          setMethods(aryMethods);
        }

        //
        // setMethods(res)
      }
    } catch (error) {
      console.error("Error updating notes: ", error);
    }
  };

  const getUserProfile = async () => {
    try {
      const response = await api.get(`me`);
      if (response && response.data && response.data.data) {
        const user = response.data.data;
        if (!user.verification_approved) {
          alert(
            i18next.t(
              "Please complete your profile and submit request for verification. After Your account is approved you will be able to make transactions."
            )
          );
          handleNavigateProfile();
        } else {
          setUserApproved(true);
        }
      }
    } catch (exception) {}
  };

  useEffect(() => {
    getUserProfile();
    handleGetRequirements();
  }, []);
  //kraj
  const [selectedMethodItem, setSelectedMethodItem] = useState<any>(null);
  const [selected, setSelected] = useState(
    transactionType === "Same Currency" ? "Local" : "IBAN"
  );
  const [subStep, setSubStep] = useState(0);

  const handleSelect = (option: any) => {
    setSelected(option.title);
    setSelectedMethodItem(option);
  };

  const goToNextStep = () => {
    setSubStep(1);
  };

  useEffect(() => {
    if (methods && methods.length > 0) {
      handleSelect(methods[0]);
    }
  }, [methods]);

  return (
    <ThemeProvider theme={defaultTheme}>
      {userApproved && subStep === 0 && (
        <>
          <WhoAreYourSendingMoneyToStep
            goToNextStep={goToNextStep}
            setAccountData={setAccountData}
            quest={quest}
            step={step}
            setStep={setStep}
          />
        </>
      )}

      {userApproved && subStep === 1 && (
        <>
          <div className="recipientMain">
            <div className="switch-selectorRecipient mb-1-5rem">
              {methods &&
                methods.length > 0 &&
                methods.map((methodItem: any, methodKey: any) => {
                  return (
                    <div
                      key={"methodItem" + methodKey}
                      className={`optionRecipient ${
                        selected === methodItem.title ? "active" : ""
                      }`}
                      onClick={() => handleSelect(methodItem)}
                    >
                      <Typography>{i18next.t(methodItem.title)}</Typography>
                    </div>
                  );
                })}
            </div>

            {methods &&
              methods.length > 0 &&
              methods.map((methodItem: any, methodKey: any) => {
                if (
                  methods &&
                  selectedMethodItem &&
                  selectedMethodItem.title === methodItem.title &&
                  selectedMethodItem.fieldsForm &&
                  selected === methodItem.title
                ) {
                  if (methodItem.provider && methodItem.provider === "3S") {
                    return (
                      <div key={methodItem.title + methodKey}>
                        <DynamicPaymentForm3S
                          methodItem={methodItem}
                          setAccountData={setAccountData}
                          quest={quest}
                          step={step}
                          setSubStep={setSubStep}
                          setStep={setStep}
                        />
                      </div>
                    );
                  }
                  return (
                    <div key={methodItem.title + methodKey}>
                      <DynamicPaymentForm
                        methodItem={methodItem}
                        setAccountData={setAccountData}
                        quest={quest}
                        step={step}
                        setSubStep={setSubStep}
                        setStep={setStep}
                      />
                    </div>
                  );
                }
              })}
          </div>
        </>
      )}
    </ThemeProvider>
  );
}

export default RecipentStep;
