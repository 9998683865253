import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { useEffect, useState } from "react";
import LoadingIndicator from "src/components/shared/LoadingIndicator";
import RecipientList from "src/components/RecipientsList/RecipientList";
import recipientsService from "src/store/recipients/recipients_services";
import {
  Box,
  InputAdornment,
  Pagination,
  TextField,
  Typography,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import Nav from "src/components/layout/nav/Nav";

const defaultTheme = createTheme();

export default function RecipientsAdminList() {
  const [savedUser, setSavedUser] = useState<any[]>([]);
  const [page, setPage] = useState(1);
  const [perPage] = useState(10);
  const [totalPages, setTotalPages] = useState(1);
  const [total, setTotal] = useState(null);
  const [searchValue, setSearchValue] = useState("");
  const [loading, setLoading] = useState(false);

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(event.target.value);
    setPage(1);
  };

  const handleGetAdminRecipients = async () => {
    setLoading(true);
    let urlFilter = null;
    if (searchValue) {
      urlFilter = `?page=${page}&per_page=${perPage}&search[account_holder]=${searchValue}`;
    } else {
      urlFilter = `?page=${page}&per_page=${perPage}`;
    }
    const response = await recipientsService.getRecipientsAdmin(urlFilter);
    const { data, meta } = response;
    setLoading(false);
    if (data) {
      setSavedUser(data);
    }
    if (meta) {
      setTotalPages(meta?.last_page || 1);
      setTotal(meta?.total || 0);
    }
  };

  useEffect(() => {
    handleGetAdminRecipients();
  }, [searchValue, page]);

  return (
    <div>
      <Nav step={0} isStepperVisible={false} isProfileSetVisible={true} />
      <div className="main-admin-container">
        <div className="content-container-profile main-color">
          <ThemeProvider theme={defaultTheme}>
            <Container component="main">
              <Typography
                className="your-details-text d-flex jc-start pageTitleFontSize"
                component="h1"
                variant="h5"
                sx={{
                  fontFamily: "Helvetica, sans-serif",
                  fontWeight: "600",
                  textAlign: "start",
                }}
              >
                Recipients
              </Typography>
              <Box sx={{ marginTop: "32px" }}>
                <TextField
                  placeholder="Search recipient by name"
                  style={{ width: "100%" }}
                  value={searchValue}
                  onChange={handleSearchChange}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon className="searchIcon" />
                      </InputAdornment>
                    ),
                    sx: {
                      borderRadius: "40px",
                      height: "48px",
                      fontSize: "16px",
                      "&:-internal-autofill-selected": {
                        backgroundColor: "transparent !important",
                      },
                    },
                  }}
                ></TextField>
              </Box>

              {loading ? (
                <LoadingIndicator />
              ) : (
                <Box>
                  <RecipientList
                    data={savedUser}
                    hideNavigation={true}
                    loading={loading}
                  />
                  {total && total > perPage ? (
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        marginTop: "2rem",
                      }}
                    >
                      <Pagination
                        count={totalPages}
                        page={page}
                        onChange={(event, value) => setPage(value)}
                        color="primary"
                      />
                    </Box>
                  ) : null}
                </Box>
              )}
            </Container>
          </ThemeProvider>
        </div>
      </div>
    </div>
  );
}
