import { useEffect, useState } from "react";
import IconButton from "@mui/material/IconButton";
import Badge from "@mui/material/Badge";
import { useNavigate } from "react-router-dom";
import api from "src/store/interceptors/api";
import { ReactComponent as ChatIcon } from "../../../assets/images/chat.svg";

import "../../../pages/Chat/Chat.scss";

export default function ChatBadge(user: any) {
  const navigate = useNavigate();

  const [badgeContent, setBadgeContent] = useState(0);

  const handleGetConversation = async () => {
    try {
      const res = await api.get("/conversations");
      const chatBadge = res && res.data && res.data.data[0];

      if (chatBadge) {
        setBadgeContent(chatBadge.unread_messages);
      } else {
        setBadgeContent(0);
      }
    } catch (error) {
      console.log(error, "error getting unread messages");
    }
  };

  const handleNavigate = () => {
    navigate((user?.user.role === "Admin" || user?.user.role === "Employee") ? "/admin/chat" : "/chat");
  };

  useEffect(() => {
    handleGetConversation();
  }, []);

  return (
    <>
      <IconButton aria-label="some-label" onClick={handleNavigate}>
        <Badge
          badgeContent={badgeContent}
          color="secondary"
          sx={{
            "& .MuiBadge-badge": {
              backgroundColor: "rgba(255, 0, 0, 1)",
              fontSize: "12px",
            },
            "@media (max-width:768px)": {
              "& .MuiBadge-badge": {
                minWidth: "16px",
                height: "16px",
                fontSize: "10px",
                fontWeight: "400",
                padding: "0 6px",
              },
            },
            "@media (max-width:500px)": {
              "& .MuiBadge-badge": {
                minWidth: "14px",
                height: "14px",
                fontSize: "8px",
                fontWeight: "400",
                padding: "0 6px",
              },
            },
            "@media (max-width:400px)": {
              "& .MuiBadge-badge": {
                minWidth: "12px",
                height: "12px",
                fontSize: "8px",
                fontWeight: "400",
                padding: "0 4px",
              },
            },
          }}
        >
          <ChatIcon
            className="chatIcon"
          />
        </Badge>
      </IconButton>
    </>
  );
}
