import { useFormik } from "formik";
import { useState, useCallback } from "react";
import * as Yup from "yup";
import {
  Checkbox,
  FormControlLabel,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import api from "src/store/interceptors/api";
import debounce from "lodash/debounce";
import "./AmountStep.scss";
import i18next from "i18next";
import BackNextBtns from "../shared/BackNextBtns/BackNextBtns";
import { useNavigate } from "react-router-dom";
import { vicPayCalculation } from "src/utils/helpers";

interface Props {
  setStep: (value: number) => void;
  step: number;
  currencies: any;
  quest: any;
  transactionType: any;
  accountData: any;
  setQuest: (value: any) => void;
  handleRequestVerification: (value: any) => void;
  navigate: ReturnType<typeof useNavigate>;
}

function Calculation({
  accountData,
  currencies,
  step,
  quest,
  transactionType,
  setStep,
  handleRequestVerification,
  setQuest,
  navigate,
}: Props) {
  const [quoteUserId, setQuoteUserId] = useState(0);
  const [rate, setRate] = useState(0);
  const [fee, setFee] = useState(0);
  const [totalConvert, setTotalConvert] = useState(0);
  const [totalCheck, setTotalCheck] = useState(0);
  const [vicPaySupport, setVicPaySupport] = useState(false);
  const [showVicPaySupport, setShowVicPaySupport] = useState(false);
  const [canContinue, setCanContinue] = useState(false);
  const [errorMsg, setErrorMsg] = useState<string | null>(null);
  const [timeDifference, setTimeDifference] = useState(0);

  const handleCheckbox = (event: React.ChangeEvent<HTMLInputElement>) => {
    const securityChecked = !vicPaySupport;
    setVicPaySupport(securityChecked);
  };

  const formik = useFormik({
    initialValues: {
      senderAmount: "",
      recipientAmount: "",
    },
    onSubmit: async (values: any) => {
      const res = await api.get("/user-balances");

      if (Number(formik.values.recipientAmount) <= 0) {
        setErrorMsg(i18next.t("Recipient amount must be greater than 0"));
        return;
      }

      if (
        quest?.sourceCurrency === "NGN" &&
        Number(formik?.values?.senderAmount) < 17000
      ) {
        setErrorMsg(i18next.t("The minimum amount you can send is 17000 NGN"));
        setFee(0);
        setTotalConvert(0);
        setRate(0);
        formik.setFieldValue("recipientAmount", "0");
        return;
      }

      setErrorMsg(null);
      let blnProceed = false;
      if (res) {
        const data = res.data?.data ?? null;
        if (data) {
          const filtered = data.filter(
            (obj: any) => obj.currency.code === quest.sourceCurrency
          );
          if (filtered.length > 0) {
            let balanceAmount = parseFloat(filtered[0].balance);
            if (balanceAmount >= totalCheck) {
              blnProceed = true;
              setCanContinue(true);
            } else {
              blnProceed = false;
              setCanContinue(false);
              setErrorMsg(
                i18next.t("Not enough money on your balance, Your balance is") +
                  " " +
                  quest.sourceCurrency +
                  " " +
                  balanceAmount
              );
            }
          } else {
            setErrorMsg(
              i18next.t("Not enough money on your balance") +
                " " +
                quest.sourceCurrency
            );
          }
        } else {
          setErrorMsg(
            i18next.t("Not enough money on your balance") +
              " " +
              quest.sourceCurrency
          );
        }
      }
      if (blnProceed) handleRequestVerification(step + 1);
    },
    validationSchema: Yup.object({
      senderAmount: Yup.string().required(
        i18next.t("Sender amount is required.")
      ),
      recipientAmount: Yup.string().required(
        i18next.t("Recepient amount is required.")
      ),
    }),
  });

  const trasferQuetes = async (
    senderCurrencyProp: string,
    recipentCurrencyProp: string,
    senderAmount: any
  ) => {
    let payload: any = {
      sourceAmount: parseFloat(senderAmount),
      sourceCurrency: senderCurrencyProp,
      targetCurrency: recipentCurrencyProp,
      preferredPayIn: "BALANCE",
      guaranteedTargetAmount: false,
      type: "SPOT",
      payInId: null,
      payInMethod: "BALANCE",
      paymentType: accountData ?? null,
    };

    if (quoteUserId && quoteUserId > 0) {
      payload.user = quoteUserId;
    }

    await api
      .post("/quotes", payload)
      .then((response: any) => {
        response = response.data;

        let resFee = 0;
        let resSourceAmount = 0;
        let resTargetAmount = 0;
        let deliveryTime = null;
        if (response && response.user) {
          setQuoteUserId(response.user);
        }

        if (response.paymentOptions && response.paymentOptions.length > 0) {
          let responsePayment = response.paymentOptions.filter(
            (item: any) =>
              item.payIn === "BALANCE" &&
              item.payInProduct === "BALANCE" &&
              item.payOut === "BANK_TRANSFER"
          );
          if (responsePayment) {
            if (responsePayment.length > 0)
              responsePayment = responsePayment[0];
            if (responsePayment.fee && responsePayment.fee.total) {
              resFee = responsePayment.fee.total;
              setFee(resFee);
            }
            if (responsePayment.sourceAmount)
              resSourceAmount = responsePayment.sourceAmount;
            if (responsePayment.targetAmount)
              resTargetAmount = responsePayment.targetAmount;
            if (responsePayment.estimatedDelivery)
              deliveryTime = responsePayment.estimatedDelivery;
          }
        } else {
          setFee(resFee);
        }
        //setFee(response.fee);
        setRate(response.rate);
        setTotalConvert(response.sourceAmount - resFee);
        setTotalCheck(response.sourceAmount);
        formik.setFieldValue("recipientAmount", resTargetAmount);
        if (deliveryTime) {
          const startTime = new Date(response.createdTime).getTime();
          const endTime = new Date(deliveryTime).getTime();
          const timeDifference = Math.abs(endTime - startTime);
          const hours = Math.floor(timeDifference / (1000 * 60 * 60));
          setTimeDifference(hours);
        }

        setCanContinue(true);
        setQuest({
          ...response,
          formated: {
            fee: resFee,
            sourceAmount: resSourceAmount,
            targetAmount: resTargetAmount,
            sourceCurrency: senderCurrencyProp,
            targetCurrency: recipentCurrencyProp,
            deliveryTime: deliveryTime,
            createdTime: response.createdTime,
          },
        });
      })
      .catch((error) => {
        //console.log('failed');
        setCanContinue(false);
        //console.error(error.message);
      });
  };

  const debouncedTransferQuetes = useCallback(
    debounce((value) => {
      trasferQuetes(quest.sourceCurrency, quest.targetCurrency, value);
    }, 1000), // Adjust the delay time (in milliseconds) as needed
    [quest.sourceCurrency, quest.targetCurrency]
  );

  const isValidNumericInput = (value: any) => {
    return /^[0-9]*(\.[0-9]*)?$/.test(value);
  };

  const handleDebouncedSenderAmountChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const value = event.target.value;
    if (isValidNumericInput(value)) {
      if (value.endsWith(".")) {
        const inputAmount = parseFloat(value);
        if (isNaN(inputAmount)) {
          setErrorMsg(i18next.t("Please enter a valid amount."));
          formik.setFieldValue("senderAmount", "0");
          formik.setFieldValue("recipientAmount", "0");
        } else {
          formik.setFieldValue("senderAmount", value);
          debouncedTransferQuetes(inputAmount.toString());
        }
      } else {
        const inputAmount = parseFloat(value);
        if (!isNaN(inputAmount) && inputAmount > 0) {
          setErrorMsg(null);
          formik.setFieldValue("senderAmount", inputAmount.toString());
          if (
            quest.sourceCurrency === "NGN" &&
            parseFloat(event.target.value) < 17000
          ) {
            setFee(0);
            setTotalConvert(0);
            setRate(0);
            formik.setFieldValue("recipientAmount", "0");
            return;
          }
          debouncedTransferQuetes(inputAmount.toString());
        } else {
          setErrorMsg(i18next.t("Please enter a valid amount greater than 0."));
          formik.setFieldValue("senderAmount", "0");
          formik.setFieldValue("recipientAmount", "0");
        }
      }
    } else if (!value.endsWith(",")) {
      setErrorMsg(i18next.t("Please enter a valid amount."));
    }
  };

  return (
    <>
      <div>
        <Typography className="labelsInternational">
          {i18next.t("You send exactly")} {quest.sourceCurrency}
        </Typography>
        <TextField
          style={{ width: "100%" }}
          type="text"
          name="senderAmount"
          error={
            formik.touched.senderAmount &&
            formik.errors.senderAmount !== undefined
          }
          value={formik.values.senderAmount}
          onChange={handleDebouncedSenderAmountChange}
          variant="outlined"
          sx={{
            "& .MuiOutlinedInput-root": {
              paddingRight: "0px!important",
            },
          }}
          InputProps={{
            sx: {
              borderRadius: "10px",
              minHeight: "48px",
              "@media(max-width: 600px)": {
                fontSize: "14px",
              },
            },
          }}
        />
        {formik.touched.senderAmount && formik.errors.senderAmount && (
          <Typography className="amountErrorForm" style={{ marginTop: 8 }}>
            {formik.errors.senderAmount.toString()}
          </Typography>
        )}
        {formik.values.senderAmount &&
          parseFloat(formik.values.senderAmount) > 0 && (
            <div className="fee-info fee-info-text mt-16 mb-1rem helvetica">
              <div className="d-flex ai-center jusifyContentSpaceBetween">
                <div className="d-flex ai-center">
                  <Typography className="minEqX">-</Typography>
                  <Typography
                    className="amountsInfoTexts elipsesMaxWidth"
                    style={{ marginRight: 3 }}
                  >
                    <strong>{fee}</strong>
                  </Typography>{" "}
                  <Typography className="amountsInfoTexts">
                    <strong>{quest.sourceCurrency}</strong>
                  </Typography>
                </div>
                <div className="d-flex ai-center">
                  <Typography className="transferInfoTexts">
                    {i18next.t("Balance transfer fee")}
                  </Typography>
                </div>
              </div>

              <div className="d-flex ai-center jusifyContentSpaceBetween mt-12">
                <div className="d-flex ai-center">
                  <Typography className="minEqX" style={{ paddingTop: 2 }}>
                    =
                  </Typography>
                  <Typography
                    className="amountsInfoTexts elipsesMaxWidth"
                    style={{ marginRight: 3 }}
                  >
                    <strong>{totalConvert}</strong>
                  </Typography>
                  <Typography className="amountsInfoTexts">
                    <strong>{quest.sourceCurrency}</strong>
                  </Typography>
                </div>
                <Typography className="transferInfoTexts">
                  {i18next.t("Total amount we'll convert")}
                </Typography>
              </div>

              {quest.sourceCurrency !== quest.targetCurrency && (
                <div className="d-flex ai-center jusifyContentSpaceBetween mt-12">
                  <div className="d-flex ai-center">
                    <Typography className="minEqX">x</Typography>
                    <Typography className="amountsInfoTexts underline elipsesMaxWidth">
                      <strong>{rate}</strong>
                    </Typography>
                  </div>
                </div>
              )}
            </div>
          )}

        <Grid container spacing={5} sx={{ padding: 1 }}>
          <Grid item xs={8}>
            <Typography className="labelsInternational">
              {i18next.t("Recipient gets")} {quest.targetCurrency}
            </Typography>
          </Grid>
        </Grid>
        <TextField
          style={{ width: "100%", marginBottom: "8px" }}
          type="number"
          name="recipientAmount"
          disabled={true}
          value={formik.values.recipientAmount}
          onChange={formik.handleChange}
          variant="outlined"
          sx={{
            "& .MuiOutlinedInput-root": {
              paddingRight: "0px!important",
            },
          }}
          InputProps={{
            sx: {
              borderRadius: "10px",
              minHeight: "48px",
              "@media(max-width: 600px)": {
                fontSize: "14px",
              },
            },
          }}
        />

        {showVicPaySupport && (
          <FormControlLabel
            control={
              <Checkbox onChange={handleCheckbox} checked={vicPaySupport} />
            }
            label="VicPay Security"
          />
        )}

        {showVicPaySupport &&
          vicPaySupport &&
          parseFloat(formik.values.senderAmount) > 0 && (
            <div className="fee-info fee-info-text mt-16 mb-1rem helvetica">
              <div className="d-flex ai-center jusifyContentSpaceBetween">
                <div className="d-flex ai-center">
                  <Typography className="minEqX">+</Typography>
                  <Typography
                    className="amountsInfoTexts elipsesMaxWidth"
                    style={{ marginRight: 3 }}
                  >
                    <strong>
                      {vicPayCalculation(
                        parseFloat(formik.values.senderAmount)
                      )}
                    </strong>
                  </Typography>{" "}
                  <Typography className="amountsInfoTexts">
                    <strong>{quest.sourceCurrency}</strong>
                  </Typography>
                </div>
                <div className="d-flex ai-center">
                  <Typography className="transferInfoTexts">
                    {i18next.t("Security transfer fee")}
                  </Typography>
                </div>
              </div>

              <div className="d-flex ai-center jusifyContentSpaceBetween mt-12">
                <div className="d-flex ai-center">
                  <Typography className="amountsInfoTexts underline">
                    New invoice will be generated with this amount to sender
                  </Typography>
                </div>
              </div>
            </div>
          )}

        {errorMsg && <Typography className="ErrorMsg">{errorMsg}</Typography>}
        <div className="d-flex ai-center jusifyContentSpaceBetween mt-8">
          <BackNextBtns
            backText={i18next.t("Back")}
            nextText={i18next.t("Continue")}
            isNextDisabled={!formik.values.senderAmount || errorMsg}
            backType="button"
            nextType="submit"
            onBackClick={() => {
              setStep(step - 1);
            }}
            onNextClick={(e: any) => formik.handleSubmit(e)}
          />
        </div>
      </div>
    </>
  );
}

export default Calculation;
