import { Modal, Box } from "@mui/material";
import SecondStep from "src/components/Registration/SecondStep";
import CloseIcon from "@mui/icons-material/Close";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: { xs: "100%", sm: 500 },
  bgcolor: "background.paper",
  //   border: "2px solid #000",
  borderRadius: 7,
  boxShadow: 24,
  p: 4,
};

function ConfirmEmailModal({
  open,
  handleClose,
  email,
  isEmailValid,
  password,
}: any) {
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <div
          style={{ textAlign: "right", cursor: "pointer" }}
          onClick={handleClose}
        >
          <CloseIcon />
        </div>
        <SecondStep
          email={email}
          password={password}
          isEmailValid={isEmailValid}
          isDataFromModal={true}
        />
      </Box>
    </Modal>
  );
}

export default ConfirmEmailModal;