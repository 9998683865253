import { Box, Typography } from "@mui/material";
import i18next from "i18next";
import React, { useState } from "react";
import Tabs, { tabsClasses } from "@mui/material/Tabs";
import { useNavigate, useParams } from "react-router-dom";
import Tab from "@mui/material/Tab";
import AccountBox from "src/components/shared/AccountBox/AccountBox";

const BalanceDisplay = ({ user }: any) => {
  const navigate = useNavigate();
  const handleNavigate = () => {
    navigate("/home");
  };

  const handleRedirect = (id: any) => {
    navigate("/account-details/" + id);
  };

  const handleRedirectToUser = () => {
    if (user && (user.role === "Admin" || user.role === "Employee")) {
      navigate("/admin/users/" + user.id + "/account-requests");
    }
  };
  return (
    <Box>
      {user && user.balances && user.balances.length > 0 ? (
        <Box style={{ marginTop: 20 }}>
          <Tabs
            value={0}
            TabIndicatorProps={{
              style: { display: "none" },
            }}
            variant="scrollable"
            scrollButtons
            aria-label="visible arrows tabs example"
            sx={{
              padding: 0,
              [`& .${tabsClasses.scrollButtons}`]: {
                "&.Mui-disabled": {
                  opacity: 0.3,
                  backgroundColor: "#eaeaea",
                  color: "#9e9e9e",
                },
                backgroundColor: "#2A5182",
                opacity: 1,
                color: "#fff",
              },
            }}
            TabScrollButtonProps={{
              style: {
                width: "32px",
                height: "32px",
                borderRadius: "50%",
                margin: "auto 10px auto 10px",
              },
            }}
          >
            {user &&
              user.balances &&
              user.balances.map((balanceItem: any, balanceKey: any) => (
                <Tab
                  sx={{
                    padding: 0.75,
                  }}
                  key={"balance" + balanceKey}
                  disableRipple={true}
                  label={
                    <AccountBox
                      currencyIcon={balanceItem?.currency?.code}
                      title={balanceItem?.currency?.code ?? ""}
                      content={balanceItem?.balance ?? "0,00"}
                      pendingBalance={balanceItem?.pending ?? 0}
                      user={user}
                      onClick={() => handleRedirect(balanceItem.id)}
                    />
                  }
                />
              ))}
          </Tabs>
        </Box>
      ) : (
        <Box style={{ marginTop: 20 }}>
          <Typography
            component="h5"
            variant="h5"
            className="welcome-text d-flex jc-start FontSize12"
            sx={{
              fontFamily: "Helvetica, sans-serif",
              textAlign: "left",
              color: "#000",
            }}
          >
            {i18next.t("No balance")}
          </Typography>
        </Box>
      )}
    </Box>
  );
};

export default BalanceDisplay;
