import { ThemeProvider, createTheme } from "@mui/material";
import { useState, useEffect } from "react";
import { toast } from "react-toastify";
import api from "src/store/interceptors/api";
import { useNavigate } from "react-router-dom";
import "./PaymentStep.scss";
import { Box, Typography } from "@mui/material";
import Container from "@mui/material/Container";
import LoadingIndicator from "../shared/LoadingIndicator";
import i18next from "i18next";
interface Props {
  transfer: any | null;
}

const theme = createTheme({
  components: {
    MuiInputBase: {
      styleOverrides: {
        input: {
          "&::placeholder": {
            fontSize: "18px",
            color: "#000000",
          },
        },
      },
    },
  },
});

function PaymentStep({ transfer }: Props) {
  const [isLoading, setIsLoading] = useState(true);
  const [errorMsg, setErrorMsg] = useState<string | null>(null);
  const navigate = useNavigate();

  const handlePayment = async () => {
    //console.log(transfer, 'transfer');
    let transferId = null;

    if (transfer && transfer.data && transfer.data.id)
      transferId = transfer.data.id;
    else {
      if (transfer && transfer.id) {
        transferId = transfer.id;
      }
    }
    if (transferId) {
      const data = {
        transferId: transferId,
      };
      await api
        .post("/transaction-fund-payment", data)
        .then((res: any) => {
          const response = res?.data ?? null;
          //console.log('response', response);
          if (response.errorCode) {
            if (response.errorCode === "balance.payment-option-unavailable") {
              toast.error(i18next.t("Transaction not sent"));
              setErrorMsg(
                i18next.t(
                  "Your balance is not enough or not approved, please check Your balance! CODE: 410"
                )
              );
            } else {
              toast.error(i18next.t("Transaction not sent"));
              setErrorMsg(
                i18next.t(
                  "Error processing transaction, please check your balance! CODE: 414"
                )
              );
            }
            setIsLoading(false);
          } else {
            if (response.status) {
              if (response.status === "COMPLETED") {
                toast.success(i18next.t("Transaction is in process"));
                setTimeout(() => {
                  navigate("/transactions-list");
                }, 2000);
              } else {
                if (response.status === "incoming_payment_waiting") {
                  //toast.success("Transaction is in review, CODE: 200");
                  toast.success(i18next.t("Transaction is in process"));
                  setTimeout(() => {
                    navigate("/transactions-list");
                  }, 2000);
                } else if (response.status === "outgoing_payment_sent") {
                  toast.success(i18next.t("Transaction is in process"));
                  setTimeout(() => {
                    navigate("/transactions-list");
                  }, 2000);
                } else {
                  //toast.success("Transaction is in process, CODE: 201");
                  toast.success(i18next.t("Transaction is in process"));
                  setTimeout(() => {
                    navigate("/transactions-list");
                  }, 2000);
                }
                //incoming_payment_waiting
                //outgoing_payment_sent
              }
            } else {
              /*
              toast.error("Something went wrong");
              setIsLoading(false);
              setErrorMsg("Error processing transaction, please try again! CODE: 411");
              */
              toast.success(i18next.t("Transaction is in process"));
              setTimeout(() => {
                navigate("/transactions-list");
              }, 2000);
            }
          }
        })
        .catch((error: any) => {
          toast.error(i18next.t("Something went wrong"));
          console.log(error);
          setIsLoading(false);
          setErrorMsg(
            i18next.t(
              "Error processing transaction, please try again!  CODE: 412"
            )
          );
        });
    } else {
      setErrorMsg(
        i18next.t("Transaction is not processed, please try again!  CODE: 413")
      );
    }
  };

  useEffect(() => {
    handlePayment();
  }, []);

  return (
    <div className="paymentMain">
      <ThemeProvider theme={theme}>
        <Container component="main">
          {isLoading ? (
            <LoadingIndicator />
          ) : (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                marginBottom: "4rem",
              }}
            >
              <Typography
                className="your-details-text d-flex jc-start"
                component="h1"
                variant="h5"
                sx={{
                  fontFamily: "Helvetica, sans-serif",
                  fontSize: "1.875rem",
                  fontWeight: "600",
                  textAlign: "start",
                  marginBottom: "2rem",
                }}
              >
                {errorMsg}
              </Typography>
            </Box>
          )}
        </Container>
      </ThemeProvider>
    </div>
  );
}

export default PaymentStep;
