import { Box, Divider, Typography } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import LoadingIndicator from "src/components/shared/LoadingIndicator";
import RecipientListItem from "./RecipientsListItem/RecipientsListItem";
import i18next from "i18next";

interface Props {
  hideNavigation: boolean;
  style?: React.CSSProperties;
  loading: boolean;
  data: any[];
}

function RecipientList({ data, hideNavigation, style, loading }: Props) {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
      }}
      style={style ?? {}}
    >
      {!hideNavigation && (
        <div className="d-flex ai-center mb-3-rem">
          <p className="back-button-icon circle-around-text-smaller helvetica mr-1-rem cp">
            <ArrowBackIcon className="muiIcons" />
          </p>
        </div>
      )}
      {loading ? (
        <LoadingIndicator />
      ) : (
        <Box className="recentBox">
          {data.length === 0 ? (
            <Typography className="noDataText">
              {i18next.t("No data")}
            </Typography>
          ) : (
            <Box>
              <Typography className="recentText">{i18next.t("All")}</Typography>
              <Divider style={{ marginBottom: 8 }} />
              <Box className="allRecipientMain">
                {data.map((item: any, index: number) => (
                  <RecipientListItem
                    item={item}
                    index={index}
                    key={`recipient-${index}`}
                  />
                ))}
              </Box>
            </Box>
          )}
        </Box>
      )}
    </Box>
  );
}

export default RecipientList;
