import { Box, TextField, Checkbox, Button, Typography } from "@mui/material";
import { useFormikContext } from "formik";
import SendIcon from "@mui/icons-material/Send";
import "./UserNotificationSection.scss";

interface FormValues {
  apiKey: string;
  checkbox: boolean;
}

interface UserNotificationSectionProps {
  sectionTitle: string;
  checkboxName?: keyof FormValues;
  checkboxLabel?: string;
  textFieldName?: keyof FormValues;
  textFieldLabel?: string;
  buttonLabel: string;
  onButtonClick: () => void;
}

const UserNotificationSection = ({
  sectionTitle,
  checkboxName,
  checkboxLabel,
  textFieldName,
  textFieldLabel,
  buttonLabel,
  onButtonClick,
}: UserNotificationSectionProps) => {
  const formikContext = useFormikContext<FormValues>();

  if (!formikContext) {
    throw new Error("Formik context is not available.");
  }

  const { values, handleChange, handleBlur, errors, touched } = formikContext;

  const getErrorMessage = (fieldName: keyof FormValues): string | undefined => {
    const error = errors[fieldName];
    if (touched[fieldName]) {
      if (typeof error === "string") {
        return error;
      } else if (Array.isArray(error)) {
        return (error as string[]).join(", ");
      } else if (typeof error === "object" && error !== null) {
        return Object.values(error).flat().join(", ");
      }
    }
    return undefined;
  };

  const isButtonDisabled = !!(
    (checkboxName && !values[checkboxName]) ||
    (textFieldName && !values[textFieldName])
  );

  return (
    <Box className="userSectionNotMain">
      <Box sx={{ width: "100%" }}>
        <Typography className="pageTextFontSize sectionTitle">
          {sectionTitle}
        </Typography>
        <Box className="userNotSectionFieldCheckAndBtnMain">
          {textFieldName && checkboxName && (
            <Box className="userNotSectionFieldCheckBox">
              {textFieldName && (
                <Box className="userSectionNotFieldW100">
                  <TextField
                    name={textFieldName}
                    label={textFieldLabel}
                    variant="outlined"
                    fullWidth
                    value={values[textFieldName] || ""}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={
                      touched[textFieldName] &&
                      Boolean(getErrorMessage(textFieldName))
                    }
                    helperText={getErrorMessage(textFieldName)}
                    InputProps={{
                      sx: {
                        borderRadius: "10px",
                        fontSize: "16px",
                        "&:-internal-autofill-selected": {
                          backgroundColor: "transparent !important",
                        },
                        "@media (max-width:600px)": {
                          fontSize: "14px !important",
                        },
                      },
                    }}
                  />
                </Box>
              )}
              {checkboxName !== undefined && (
                <Box className="checkBoxMain">
                  <Typography className="registration0875RemFontSize">
                    {checkboxLabel}
                  </Typography>
                  <Checkbox
                    size="small"
                    name={checkboxName}
                    checked={!!values[checkboxName]}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </Box>
              )}
            </Box>
          )}
          <Button
            variant="contained"
            className="oneRemFontSize btnsLoginHeight"
            sx={{
              color: "#fff",
              borderRadius: "24px",
              minWidth: "215px",
              height: "2rem",
              boxShadow: "none",
              cursor: "pointer",
              border: "1px solid #000000",
              fontWeight: 400,
              textTransform: "none",
              fontFamily: "Helvetica, sans-serif",
              "&:hover": {
                backgroundColor: "#2A5182",
                color: "white",
              },
            }}
            onClick={onButtonClick}
            disabled={isButtonDisabled}
          >
            {buttonLabel}
            <SendIcon style={{ paddingLeft: 5 }} className="muiIcons" />
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default UserNotificationSection;
