import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Nav from "src/components/layout/nav/Nav";
import { Box, Typography, TextField, InputAdornment } from "@mui/material";
import { useEffect, useState } from "react";
import SearchIcon from "@mui/icons-material/Search";
import LoadingIndicator from "src/components/shared/LoadingIndicator";
import investmentsServices from "src/store/investments/investments_services";
import InvestmentListItem from "src/components/Investments/InvestmentListItem";
import { useAppSelector } from "src/store";
import { useNavigate } from "react-router-dom";

const defaultTheme = createTheme();

export default function Investments() {
  const { user } = useAppSelector((state) => state.auth);
  const [investmentsData, setInvestmentsData] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  const [page, setPage] = useState(1);
  const [perPage] = useState(100);
  const [totalPages, setTotalPages] = useState(1);
  const navigate = useNavigate()
  
  const [searchValue, setSearchValue] = useState("");

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(event.target.value);
  };
  const handleTabClick = (tabName: string) => {
    if (tabName === "Participation") {
      navigate("/admin/participations");
    } else if (tabName === "Investment") {
      navigate("/admin/investments");
    }
  };

  const handleGetInvestments = async () => {
    setIsLoading(true);
    let urlFilter = null;
    if (searchValue) {
      urlFilter = `?page=${page}&per_page=${perPage}&search[amount]=${searchValue}&search[note]=${searchValue}`;
    }else{
      urlFilter = `?page=${page}&per_page=${perPage}`;

    }
    const response = await investmentsServices.getInvestments(urlFilter);
    const { data, meta } = response;
    setIsLoading(false);
    if (data) {
      setInvestmentsData(data);
    }
    if (meta) {
      setTotalPages(meta?.last_page || 1);
    }
  };

  useEffect(() => {
    handleGetInvestments();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchValue]);

  return (
    <div>
      <Nav step={0} isStepperVisible={false} isProfileSetVisible={true} />
      <div className="main-admin-container">

        <div className="content-container-profile main-color">
          <ThemeProvider theme={defaultTheme}>
            <Container component="main">
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <div>
                  <div
                    className="d-flex ai-center mb-3-rem"
                    style={{ marginBottom: "32px", marginLeft: "12px" }}
                  >
                    <Typography
                      className="your-details-text d-flex jc-start"
                      component="h1"
                      variant="h5"
                      sx={{
                        fontFamily: "Helvetica, sans-serif",
                        fontSize: {
                          xs: "12px",
                          sm: "1rem",
                        },
                        textAlign: "center",
                        fontWeight: "600",
                        marginRight: "2rem",
                        color: "#2A5182",
                        cursor: "pointer",
                        backgroundColor: "#16330014",
                        "&:hover": {
                          backgroundColor: "rgb(244, 246, 248)",
                        },
                        padding: "0.6rem",
                        borderRadius: "16px",
                      }}
                      onClick={() => handleTabClick("Investment")}
                    >
                      Investment
                    </Typography>

                    <Typography
                      className="your-details-text d-flex jc-start"
                      component="h1"
                      variant="h5"
                      sx={{
                        fontFamily: "Helvetica, sans-serif",
                        fontSize: {
                          xs: "12px",
                          sm: "1rem",
                        },
                        textAlign: "center",
                        fontWeight: "600",
                        color: "inherit",
                        cursor: "pointer",
                        backgroundColor: "none",
                        padding: "0.6rem",
                        borderRadius: "16px",
                        "&:hover": {
                          backgroundColor: "rgb(244, 246, 248)",
                        },
                      }}
                      onClick={() => handleTabClick("Participation")}
                    >
                      Participation
                    </Typography>
                  </div>
                </div>
                <div className="d-flex ai-center mb-3-rem">
                  <Typography
                    className="your-details-text d-flex jc-start"
                    component="h1"
                    variant="h5"
                    sx={{
                      fontFamily: "Helvetica, sans-serif",
                      fontSize: "1.875rem",
                      textAlign: "center",
                      fontWeight: "600",
                    }}
                  >
                    Investments
                  </Typography>
                </div>

                <div>
                  <Box style={{marginBottom:'1rem'}}>
                    <TextField
                      placeholder="Search by amount or note"
                      style={{ width: "100%" }}
                      value={searchValue}
                      onChange={handleSearchChange}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <SearchIcon className="searchIcon" />
                          </InputAdornment>
                        ),
                        sx: {
                          borderRadius: "40px",
                          height: "48px",
                          fontSize: "16px",
                          "&:-internal-autofill-selected": {
                            backgroundColor: "transparent !important",
                          },
                        },
                      }}
                    ></TextField>
                  </Box>
                </div>
                <div>
                  {isLoading ? (
                    <LoadingIndicator />
                  ) : (
                    <div>
                      <InvestmentListItem fromPage="admin" user={user} data={investmentsData} />
                    </div>
                  )}
                </div>
              </Box>
            </Container>
          </ThemeProvider>
        </div>
      </div>
    </div>
  );
}
