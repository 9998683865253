import api from "../interceptors/api";

const getBalanceRequests = async (searchValue?: string | null) => {
  let url = `/payment-requests`
  if(searchValue){
    url += searchValue
  }
  const response = await api.get(url);
  return response.data;
};
const getBalanceRequestById = async (requestId: string) => {
  const response = await api.get(`/payment-requests/${requestId}`);
  return response.data;
};

const getCryptoRequestById = async (requestId: string) => {
  const response = await api.get(`/crypto-payment/${requestId}`);
  return response.data;
};

const balanceServices = {
  getBalanceRequests,
  getBalanceRequestById,
  getCryptoRequestById
};

export default balanceServices;
