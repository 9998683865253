import { Typography } from "@mui/material";
import "../Terms.scss";
import { createTheme, ThemeProvider } from "@mui/material/styles";

const theme = createTheme({
  typography: {
    h1: {
      fontSize: "1.7rem", // Adjust the font size for h1
      fontWeight: "bold",
      marginBottom: "1rem",
    },
    h2: {
      fontSize: "1.5rem", // Adjust the font size for h2
      fontWeight: "bold",
      marginBottom: "1.5rem",
    },
    h3: {
      fontSize: "1.1rem", // Adjust the font size for h3
      fontWeight: "bold",
      marginBottom: "1rem",
    },
    body1: {
      fontSize: "0.9rem", // Adjust the font size for P
      fontWeight: "normal",
      lineHeight: 1.6,
      marginBottom: "1rem",
    },
  },
});

const TermsUK = () => {
  return (
    <ThemeProvider theme={theme}>
      <div className={"terms-content"}>
        <div>
          <Typography variant="h1">Customer Agreement (Personal)</Typography>

          <Typography variant="h2">1. How to read this Agreement</Typography>

          <Typography variant="body1">
            This Agreement contains <strong>29 sections</strong>. You may go
            directly to any section by selecting the appropriate link provided.
            The headings are for reference only. Some capitalised terms have
            specific definitions in section 3. Underlined words in this
            Agreement contain hyperlinks to further information.
          </Typography>

          <Typography variant="body1">
            For "Money Transfer" transactions, this Agreement applies
            individually to each transaction and is not a Framework Contract.
            Additionally, when paying for a Money Transfer transaction in a
            currency other than the Euro or other currency of an EEA country,
            you may be doing business with a different VCMTS entity on these
            same terms as listed here. In those cases, your money will be held
            in accordance with the regulations and licenses applicable to such
            entity, as described here.
          </Typography>

          <Typography variant="body1">
            Where you hold a Multi Currency Account with us (as defined in
            section 3 Glossary hereunder), this Agreement applies as a Framework
            contract to your Multi Currency Account and any payment services
            involving your Multi Currency Account.
          </Typography>

          <Typography variant="h2">
            2. Why you should read this Agreement
          </Typography>

          <Typography variant="h3">2.1 What this Agreement covers</Typography>

          <Typography variant="body1">
            These are the terms and conditions on which we provide our Services
            to you.
          </Typography>

          <Typography variant="h3">2.2 Why you should read them</Typography>

          <Typography variant="body1">
            Please read this Agreement carefully before you start to use our
            Services. This Agreement (always together with the documents
            referred to in it) tells you who we are, how we will provide the
            Services to you, how this Agreement may be changed or ended, what to
            do if there is a problem, and other important information. If you
            think that there is a mistake in this Agreement or require any
            changes, please contact us to discuss.
          </Typography>

          <Typography variant="h3">
            2.3 Other additional documents which apply to you
          </Typography>

          <Typography variant="body1">
            This Agreement refers to the following additional documents, which
            also apply to your use of our Services:
          </Typography>

          <Typography variant="body1" component="ul">
            <li>
              (a) Our Privacy Policy, which sets out the terms on which we
              process any personal data we collect about you, or that you
              provide to us. By using our Services, you consent to such
              processing and you promise that all data provided by you is
              accurate.
            </li>
            <li>
              (b) Our Cookie Policy, which sets out information about the
              “cookies” on our Website.
            </li>
            <li>
              (c) Our Acceptable Use Policy, which sets out the permitted uses
              and prohibited uses of our Services.
            </li>
            <li>
              (d) Our Frequently Asked Questions ("FAQ") which provides answers
              to common customer questions.
            </li>
            <li>
              (e) In order to receive some of our Services, you may be asked to
              agree to additional terms and conditions (including those referred
              to in section 29) which we will notify you about at the relevant
              time.
            </li>
          </Typography>

          <Typography variant="body1">
            <strong>2.4 Additional documents.</strong> For clarity, the
            additional documents and the parts of this Agreement which
            incorporate the additional documents are not Framework Contracts.
          </Typography>

          <Typography variant="body1">
            <strong>2.5 Future changes to this Agreement.</strong> All future
            changes set out in the Customer Updates section of our Website at
            the time you sign-up for our Services are incorporated into this
            Agreement. This Agreement may be changed from time to time as set
            out in section 26 of this Agreement.
          </Typography>

          <Typography variant="body1">
            <strong>2.6 You accept this Agreement.</strong> By visiting our
            Website and/or using our Services (including downloading and using
            our App, or via the API, a social media platform or other authorised
            third party), you confirm that you accept and agree to this
            Agreement (including the Customer Updates and the additional
            documents referred to above). If you do not agree, please do not use
            our Services.
          </Typography>

          <Typography variant="body1">
            <strong>2.7 Where to get a copy of this Agreement.</strong> You can
            always see the most current version of this Agreement on our
            Website.
          </Typography>

          <Typography variant="h2">3. Glossary</Typography>

          <Typography variant="body1">
            <strong>In this Agreement:</strong>
          </Typography>

          <Typography variant="body1">
            <strong>API</strong> means the application programming interface
            provided by VCMTS.
          </Typography>

          <Typography variant="body1">
            <strong>App</strong> means the mobile application software, the data
            supplied with the software and the associated media.
          </Typography>
          <Typography variant="h2">Book II Criminal Code</Typography>

          <Typography variant="body1">
            means the provisions on cybercrime in Book II of the Belgian Code of
            Criminal Law.
          </Typography>

          <Typography variant="h2">Book VII CEL</Typography>

          <Typography variant="body1">
            means the provisions on payment services in Book VII of the Belgian
            Code of Economic Law.
          </Typography>

          <Typography variant="h2">Business Account</Typography>

          <Typography variant="body1">
            is the account for business to use our Services, including any
            activities related to a Multi Currency account. You can have
            multiple Business Accounts in your VCMTS Account.
          </Typography>

          <Typography variant="h2">Business Day</Typography>

          <Typography variant="body1">
            means a day other than a Saturday, Sunday or a public holiday in the
            United Kingdom when financial institutions in Glasgow are open for
            business.
          </Typography>

          <Typography variant="h2">Currency Account</Typography>

          <Typography variant="body1">
            means the representation of your funds held in different currencies
            in your Multi Currency Account.
          </Typography>

          <Typography variant="h2">Framework Contract</Typography>

          <Typography variant="body1">
            means a contract for payment services which governs the future
            execution of individual and successive payment transactions and
            which may contain the obligation and conditions for setting up a
            payment account as defined in the Second Payment Services Directive
            (2015/2366, "PSD2") or any implementation of PSD2 in any EU or EEA
            Member State resp. similar legal directives in the United Kingdom.
          </Typography>

          <Typography variant="h2">Group Company</Typography>

          <Typography variant="body1">
            means any entity that directly or indirectly controls, is controlled
            by, or is under common control with another entity.
          </Typography>

          <Typography variant="h2">Multi Currency Account</Typography>

          <Typography variant="body1">
            means your individual payment account offered by VicPay Services Inc. that enables you to hold funds in different
            currencies, which may be represented in multiple Currency Accounts,
            and initiate payments from those Currency Accounts.
          </Typography>

          <Typography variant="h2">Personal Account</Typography>

          <Typography variant="body1">
            is the account that allows verified individuals to use our Services,
            including any activities related to a Multi Currency Account. Each
            individual can only have one Personal Account in their VCMTS
            Account.
          </Typography>

          <Typography variant="h2">PIL</Typography>

          <Typography variant="body1">
            means the UK Law on Payment Institutions and E-money Institutions in
            its actual version.
          </Typography>

          <Typography variant="h2">Services</Typography>

          <Typography variant="body1">
            means all products, services, content, features, technologies or
            functions offered by us and all related websites, applications
            (including the App), and services (including the Website and API).
          </Typography>

          <Typography variant="h2">Source Currency</Typography>

          <Typography variant="body1">
            means the currency which you hold and/or fund your payment order
            with.
          </Typography>

          <Typography variant="h2">Target Currency</Typography>

          <Typography variant="body1">
            means the currency which your recipient will receive.
          </Typography>

          <Typography variant="h2">VCMTS Account</Typography>

          <Typography variant="body1">
            is the umbrella term given to your Personal Account and all Business
            Accounts that you have registered with VCMTS under one email
            address.
          </Typography>

          <Typography variant="h2">Website</Typography>

          <Typography variant="body1">
            means any webpage, including but not limited to
            www.victorum-capital.com, where we provide the Services to you.
          </Typography>

          <Typography variant="h2">4. VCMTS App and API</Typography>

          <Typography variant="h3">
            4.1 App subject to this Agreement and the App store Rules.
          </Typography>

          <Typography variant="body1">
            We license the use of the App to you on the basis of this Agreement
            and subject to any rules and policies applied by any appstore
            provider or operator whose sites are located at App Store and Google
            Play. We do not sell the App to you. We remain the owners of the App
            at all times.
          </Typography>

          <Typography variant="h3">4.2 App updates.</Typography>

          <Typography variant="body1">
            From time to time updates to the App may be issued through App Store
            or Google Play. Depending on the update, you may not be able to use
            our Services via the App until you have downloaded the latest
            version of the App and accepted any new terms.
          </Typography>

          <Typography variant="h3">
            4.3 Your right to use the App and the API.
          </Typography>

          <Typography variant="body1">
            In consideration of you agreeing to abide by the terms of this
            Agreement, we grant you a non-transferable, non-exclusive licence to
            use the App on your device and the API subject to this Agreement. We
            reserve all other rights.
          </Typography>

          <Typography variant="h2">
            5. Who are we and how to contact us
          </Typography>

          <Typography variant="h3">5.1 Our company information.</Typography>

          <Typography variant="body1">
            <strong>VicPay Services Inc.</strong> is a company
            incorporated under the law in the State of British Columbia with company number
            790478002BC0001 ("VCMTS", "we", "us", or "our" as applicable).
          </Typography>

          <Typography variant="h3">5.2 Our Registered office.</Typography>

          <Typography variant="body1">
            Our registered office is Vic Pay Inc., 2102-58 Keefer Place Vancouver, BC V6B 0B6 Canada.
          </Typography>

          <Typography variant="h3">5.3 We are authorised by the …..</Typography>

          <Typography variant="body1">
            We are authorised as a payment institution by the ……
          </Typography>

          <Typography variant="h3">5.4 How to contact us.</Typography>

          <Typography variant="body1">
            You can contact us by email, web chat or telephone. Our contact
            details are provided on the "Contact" page of our Website.
          </Typography>
          <Typography variant="h2">6. Who can use our Services</Typography>

          <Typography variant="h3">
            6.1 You must be 18 years or over.
          </Typography>

          <Typography variant="body1">
            If you are an individual, you must be 18 years or older to use our
            Services, and by opening a VCMTS Account, you declare that you are
            18 years or older. We may ask you at any time to show proof of your
            age.
          </Typography>

          <Typography variant="h3">
            6.2 You must have authority to bind your business.
          </Typography>

          <Typography variant="body1">
            If you are not a consumer, you confirm that you have the authority
            to bind any business or entity on whose behalf you use our Services,
            and that business or entity accepts these terms.
          </Typography>

          <Typography variant="h3">
            6.3 Your use of the VCMTS Account must not violate any applicable
            laws.
          </Typography>

          <Typography variant="body1">
            You commit to us that your opening and/or using of a VCMTS Account
            do not violate any laws applicable to you. You take responsibility
            for any consequences of your breach of this section.
          </Typography>

          <Typography variant="h2">7. Your Multi Currency Account</Typography>

          <Typography variant="h3">
            7.1 About your Multi Currency Account
          </Typography>

          <Typography variant="body1">
            <strong>(a)</strong> Your Multi Currency Account allows you to hold,
            send or receive funds.
          </Typography>

          <Typography variant="body1">
            <strong>(b)</strong> The funds held on your Multi Currency Account
            do not expire other than when your account is closed; see section 19
            for more details.
          </Typography>

          <Typography variant="body1">
            <strong>(c)</strong> The funds held on your Multi Currency Account
            will not earn any interest.
          </Typography>

          <Typography variant="body1">
            <strong>(d)</strong> You may hold your funds in any currencies which
            we support from time to time.
          </Typography>

          <Typography variant="body1">
            <strong>(e)</strong> You may withdraw money from your Multi Currency
            Account at any time, subject to certain conditions; please see
            section 16 for more details.
          </Typography>

          <Typography variant="body1">
            <strong>(f)</strong> Certain limits may be placed on your Multi
            Currency Account depending on your country of residence,
            verification checks, or other legal considerations. Please contact
            us if you have any questions about these limits.
          </Typography>

          <Typography variant="body1">
            <strong>(g)</strong> The funds held on your Multi Currency Account
            belong to the person or legal entity registered as the VCMTS Account
            holder.
          </Typography>

          <Typography variant="body1">
            <strong>(h)</strong> Unless you have our consent in writing, you
            must not allow anyone to operate your Multi Currency Account on your
            behalf.
          </Typography>

          <Typography variant="h3">
            7.2 Your funds in the Multi Currency Account are treated in
            accordance with the law of the United Kingdom.
          </Typography>

          <Typography variant="h3">
            7.3 Your Multi Currency Account is a payment account and is not a
            bank (deposit) account.
          </Typography>

          <Typography variant="body1">
            You acknowledge that the UK Deposit Guarantee Scheme does not apply
            to your VCMTS Account. However, we follow the requirements under UK
            law designed to ensure the safety of funds held in payment accounts
            like your VCMTS Account. We would like to point out that our
            platform is a business model that requires a license in some cases.
            The relevant licenses have already been applied for and are
            currently undergoing final review. Please note that full legal
            feasibility will only be available after approval has been granted.
            As soon as this is the case, we will publish the relevant
            information without being asked.
          </Typography>

          <Typography variant="h2">8. Getting started</Typography>

          <Typography variant="h3">8.1 Open a VCMTS Account.</Typography>

          <Typography variant="body1">
            To start using our Services, you must open a VCMTS Account and
            provide your details as prompted.
          </Typography>

          <Typography variant="h3">
            8.2 Information must be accurate.
          </Typography>

          <Typography variant="body1">
            All information you provide to us must be complete, accurate, and
            truthful at all times. You must update this information whenever it
            changes. We cannot be responsible for any financial loss arising out
            of your failure to do so. We may ask you at any time to confirm the
            accuracy of your information and/or to provide additional supporting
            documents.
          </Typography>

          <Typography variant="h3">
            8.3 Transacting on your own account.
          </Typography>

          <Typography variant="body1">
            All activities under a VCMTS Account shall be deemed as activities
            carried out by the registered user. You shall only use the Services
            to transact on your own account and not on behalf of any other
            person or entity.
          </Typography>

          <Typography variant="h3">
            8.4 One account per person or entity.
          </Typography>

          <Typography variant="body1">
            You may only open one Personal Account and one or more Business
            Accounts. While each account might be linked to one VCMTS Account,
            each person or entity (each a “VCMTS Account holder”) is
            individually bound to the terms and conditions applicable to the
            address in their account as provided here. VCMTS may refuse the
            creation of duplicate accounts for the same user. Where duplicate
            accounts are detected, VicPay may close or merge these duplicate
            accounts at its sole discretion.
          </Typography>

          <Typography variant="h2">9. Getting to know you</Typography>

          <Typography variant="h3">
            9.1 We are required by law to carry out all necessary security and
            customer due diligence checks on you.
          </Typography>

          <Typography variant="body1">
            (including any parties involved in your transaction, for example,
            your recipient) to provide any Services to you. You agree to comply
            with any request from us for further information and provide such
            information in a format acceptable to us. In addition, you agree
            that we may make, directly or through any third party, any inquiries
            we consider necessary to validate the information you provided to
            us, including checking commercial databases or credit reports. You
            authorize us to obtain one or more of your credit reports, from time
            to time, to establish, update, or renew your VCMTS Account with us
            or in the event of a dispute relating to this Agreement and activity
            under your VCMTS Account.
          </Typography>
          <Typography variant="h2">10. Keep your VCMTS Account safe</Typography>

          <Typography variant="h3">
            10.1 Keep your VCMTS Account safe
          </Typography>

          <Typography variant="body1">
            <strong>(i) What to do.</strong> You must:
          </Typography>

          <Typography variant="body1">
            <strong>(a)</strong> Change your password regularly and ensure that
            it isn’t reused across other online accounts.
          </Typography>

          <Typography variant="body1">
            <strong>(b)</strong> Contact Customer Support if anyone asks for
            your VCMTS password.
          </Typography>

          <Typography variant="body1">
            <strong>(c)</strong> Always follow recommended password management
            practice, for example:{" "}
            <a
              href="https://support.google.com/accounts/answer/32040?hl=en"
              target="_blank"
              rel="noopener noreferrer"
            >
              https://support.google.com/accounts/answer/32040?hl=en
            </a>
            .
          </Typography>

          <Typography variant="body1">
            <strong>(d)</strong> Set up 2-step authentication where prompted
            (for further instructions please refer to our FAQ).
          </Typography>

          <Typography variant="body1">
            <strong>(e)</strong> Keep your e-mail account secure. You may reset
            your VCMTS Account password using your email address. Let Customer
            Support know immediately if your email address becomes compromised.
          </Typography>

          <Typography variant="body1">
            <strong>(ii) What NOT to do.</strong> You must NOT:
          </Typography>

          <Typography variant="body1">
            <strong>(a)</strong> Disclose your VCMTS Account password or your
            customer reference number. Keep them safe.
          </Typography>

          <Typography variant="body1">
            <strong>(b)</strong> Let anyone access your VCMTS Account or watch
            you accessing it.
          </Typography>

          <Typography variant="body1">
            <strong>(c)</strong> Use any functionality that allows your login
            details or passwords to be stored by the computer or browser you are
            using or to be cached or otherwise recorded.
          </Typography>

          <Typography variant="body1">
            <strong>(d)</strong> Do anything which may in any way avoid or
            compromise the 2-step authentication process.
          </Typography>

          <Typography variant="h3">
            10.2 Contact us if you suspect your VCMTS Account has been
            compromised.
          </Typography>

          <Typography variant="body1">
            If you suspect your VCMTS Account or other security credentials are
            stolen, lost, used without your authorization, or otherwise
            compromised, you must contact Customer Support immediately. You are
            also advised to change your password. Any undue delays in notifying
            us may affect the security of your VCMTS Account and also result in
            you being responsible for financial losses.
          </Typography>

          <Typography variant="h3">10.3 Authorizing third parties.</Typography>

          <Typography variant="body1">
            You may authorize third parties to access your VCMTS Account to
            provide their services to you, including authorizing them to
            initiate payments from your VCMTS Account. You acknowledge that if
            you authorize a third party to access your VCMTS Account, we may
            disclose certain information about your VCMTS Account to this third
            party. We are not responsible for any such third party’s use of your
            VCMTS Account or any information in your VCMTS Account. Granting
            permission to a third party does not relieve you of your
            responsibilities under this Agreement, including notifying us if
            your VCMTS Account has been compromised or if a transaction is
            incorrect or unauthorized.
          </Typography>

          <Typography variant="h2">
            11. Uploading money into your VCMTS Account
          </Typography>

          <Typography variant="h3">
            11.1 How to upload money into your Multi Currency Account.
          </Typography>

          <Typography variant="body1">
            To upload money, you need to log in to your VCMTS Account and follow
            the steps as they appear on the screen. We are not responsible for
            the money you have uploaded until we have received them. For
            clarity, in an upload transaction, we are the recipient of funds and
            not the payment services provider.
          </Typography>

          <Typography variant="h3">11.2 Payin Methods.</Typography>

          <Typography variant="body1">
            You may be presented with one or more methods of upload, for
            example, bank transfer, credit cards, or debit cards (in this
            Agreement, we will call these methods “Payin Methods”). The number
            of Payin Methods made available to you will depend on a number of
            factors including where you live and your verification status with
            us. Payin Methods are not part of our Services; they are services
            provided by third parties, for example, the card provider which
            issued you with your credit/debit card. We cannot guarantee the use
            of any particular Payin Method and may change or stop offering a
            Payin Method at any time without notice to you.
          </Typography>

          <Typography variant="h3">
            11.3 Payment instrument must be in your name.
          </Typography>

          <Typography variant="body1">
            Any payment instrument (for example, the credit card or debit card)
            you use with your chosen Payin Method must be in your name.
          </Typography>

          <Typography variant="h3">
            11.4 Chargebacks on your payment instrument.
          </Typography>

          <Typography variant="body1">
            If you selected a Paying Method that gives you chargeback rights
            (for example, in relation to your credit card, you may ask your card
            provider to reverse a transaction on your card), you promise that
            you will only exercise this chargeback right if:
          </Typography>

          <Typography variant="body1">
            <strong>(a)</strong> we have breached this Agreement; or
          </Typography>

          <Typography variant="body1">
            <strong>(b)</strong> there was an unauthorized use of your payment
            instrument.
          </Typography>
          <Typography variant="h2">
            11.4 Chargebacks on your payment instrument.
          </Typography>

          <Typography variant="body1">
            You promise that you will not exercise your chargeback right for
            reasons which we are not responsible, including a dispute with your
            recipient or if there are insufficient funds in your payment
            instrument. If we need to investigate or take any actions in
            connection with a chargeback raised by you, we may charge you for
            our costs in doing so and may deduct such amount from your Multi
            Currency Account.
          </Typography>

          <Typography variant="h3">
            11.5 Upload limits on your Multi Currency Account.
          </Typography>

          <Typography variant="body1">
            For legal and security reasons, we impose limits on how much you can
            upload into your Multi Currency Account.
          </Typography>

          <Typography variant="h3">
            11.6 When we will credit your Multi Currency Account.
          </Typography>

          <Typography variant="body1">
            We will credit your Multi Currency Account once we have received
            your money. For some Payin Methods such as credit or debit card, we
            will credit the money to your Multi Currency Account as soon as
            possible subject to our right of reversal. This means if the actual
            amount you intended to upload does not reach us within a reasonable
            time, we may deduct such amount from your Multi Currency Account. If
            you do not have enough money in your Multi Currency Account for this
            purpose, we can demand repayment from you using other methods.
          </Typography>

          <Typography variant="h2">12. Sending money</Typography>

          <Typography variant="h3">
            12.1 Setting up your payment order
          </Typography>

          <Typography variant="body1">
            You must set up your payment order from your VCMTS Account. Your
            order may either be:
          </Typography>

          <Typography variant="body1">
            <strong>(a)</strong> a "Fixed Source Order" which is a payment order
            where you indicate that you wish to send and convert a fixed amount
            of Source Currency to your recipient who will receive the converted
            amount in the Target Currency; or
          </Typography>

          <Typography variant="body1">
            <strong>(b)</strong> a "Fixed Target Order" which is a transfer
            where you indicate that you wish to send and convert a fixed amount
            of Target Currency to your recipient from the Source Currency you
            pay into VCMTS.
          </Typography>

          <Typography variant="body1">
            You can only set up a Fixed Target Order for certain Source
            Currencies, you can find a list of these Source Currencies on our
            FAQ.
          </Typography>

          <Typography variant="h3">
            12.2 Information you need to provide to set up a payment order.
          </Typography>

          <Typography variant="body1">
            To set up a payment order via your VCMTS Account, you need to
            provide certain information to us including (a) the full name of
            your recipient, (b) your recipient’s bank account details or their
            VCMTS Account details and (c) the amount to be transferred.
          </Typography>

          <Typography variant="h3">12.3 Payment order limits.</Typography>

          <Typography variant="body1">
            We may place limits on the amount you may send per transfer. For
            more information on the applicable limits, please visit our FAQ.
          </Typography>

          <Typography variant="h3">
            12.4 When is your payment order received.
          </Typography>

          <Typography variant="body1">
            If your payment order is received by us after 5 pm on a Business Day
            or not on a Business Day, your payment order will be deemed received
            on the following Business Day.
          </Typography>

          <Typography variant="h3">
            12.5 What happens after you have submitted your payment order.
          </Typography>

          <Typography variant="body1">
            Once we have received your payment order, we will display it under
            the Activity section of your VCMTS account. Each payment order is
            given a unique transaction number which you can find there. You
            should quote this number when communicating with us about a
            particular payment order.
          </Typography>

          <Typography variant="h3">
            12.6 You need to provide us with sufficient funds before we can
            process your payment order.
          </Typography>

          <Typography variant="body1">
            We will only process your payment order if we hold or have received
            sufficient cleared funds in your VCMTS Account. It is your
            responsibility to fund your payment order in a timely manner. We
            cannot be responsible for the time it takes for the money to be sent
            to us by your bank or payment service provider.
          </Typography>

          <Typography variant="h3">
            12.7 Verification checks may increase the time for processing your
            payment order.
          </Typography>

          <Typography variant="body1">
            We carry out verification checks, and these checks may increase the
            time it takes to process your payment order. We cannot be
            responsible for any delays as a result of carrying out those checks.
          </Typography>

          <Typography variant="h3">
            12.8 Completion time of your payment order.
          </Typography>

          <Typography variant="body1">
            The estimated completion time of your payment order is notified to
            you when you complete the setup of your payment order. You may also
            find further information about the completion time in the FAQ
            section of our Website, please refer to the applicable currencies in
            your payment order.
          </Typography>

          <Typography variant="h3">
            12.9 We will use reasonable efforts to ensure funds arrive at your
            recipient’s account within the notified timeframe.
          </Typography>

          <Typography variant="body1">
            We will use reasonable efforts to ensure that the funds arrive in
            the recipient’s bank account or payment account within the timelines
            notified to you or otherwise specified in our FAQ section. We do not
            have any control over the time it may take for the recipient’s bank
            or payment provider to credit and make available funds to the
            recipient.
          </Typography>

          <Typography variant="h3">
            12.10 Refusal of your payment order.
          </Typography>

          <Typography variant="body1">
            If we are unable to complete your payment order, we will let you
            know and, if possible, the reasons for the refusal and an
            explanation of how to correct any factual errors. However, we are
            not required to notify you if such notification would be unlawful.
          </Typography>

          <Typography variant="h3">
            12.11 You may cancel your payment order before your funds are
            converted.
          </Typography>

          <Typography variant="body1">
            You may cancel your payment order by following the instructions set
            out in our FAQ. You cannot cancel your payment order once your funds
            have been converted into the Target Currency you requested.
          </Typography>

          <Typography variant="h3">
            12.12 You must ensure the information you provide to us is correct.
          </Typography>

          <Typography variant="body1">
            You must make sure that the information you provide when setting up
            a payment order is accurate. If we have processed your order in
            accordance with the information you have provided to us, it will be
            considered correctly completed even if you have made a mistake.
          </Typography>
          <Typography variant="h3">
            12.13 What happens if you provide us with incorrect information.
          </Typography>

          <Typography variant="body1">
            If you provide incorrect information with your payment order, we
            will use reasonable efforts to recover the funds for you and may
            need to charge you a fee for that.
          </Typography>

          <Typography variant="h3">
            12.14 When will I be notified of my next scheduled transfer.
          </Typography>

          <Typography variant="body1">
            If you have scheduled a transfer in advance then we will notify you
            24 hours before your upcoming transfer, setting out the total fees
            and the estimated ‘live’ exchange rate for that transfer. By
            scheduling a transfer, you agree to VCMTS sending the funds using
            the live exchange rate at any time on the scheduled date. If you
            have opted in to receiving emails, we will send you a transfer
            receipt after successfully sending your scheduled transfer. For more
            information on scheduled transfers see our Help Centre.
          </Typography>

          <Typography variant="h2">13. Exchange Rates</Typography>

          <Typography variant="h3">
            13.1 The applicable exchange rate.
          </Typography>

          <Typography variant="body1">
            We will let you know the exchange rate:
          </Typography>

          <Typography variant="body1">
            <strong>(a)</strong> when you place your payment order, if it is a
            guaranteed rate payment order; or
          </Typography>

          <Typography variant="body1">
            <strong>(b)</strong> when we have received your payment, if it is a
            non-guaranteed rate payment order.
          </Typography>

          <Typography variant="h3">13.2 Exchange rate</Typography>

          <Typography variant="body1">
            <strong>(a)</strong> When we refer to an exchange rate in this
            Agreement, it means the exchange rate at the relevant time for the
            relevant currency pair (for example, GBP to EUR, USD to CAD) that is
            offered by VCMTS, which is typically provided by a reference rate
            provider. We may change our reference rate provider from time to
            time without notice to you.
          </Typography>

          <Typography variant="body1">
            <strong>(b)</strong> For some currencies, we do not use the
            mid-market exchange rate, including where we are required to use a
            different reference rate for the exchange rate for your currency
            pair. For example, for currency conversions to Nigeria (NGN), we are
            required to use the rate set by the Central Bank of Nigeria. For
            these currencies, we will notify you of the exchange rate offered by
            VCMTS when you place your payment order.
          </Typography>

          <Typography variant="h3">13.3 Guaranteed rates.</Typography>

          <Typography variant="body1">
            We will notify you of the guaranteed rate and the guaranteed time
            period (the "Guaranteed Period") when you create your Money
            Transfer. Guaranteed Periods are subject to the following
            conditions:
          </Typography>

          <Typography variant="body1">
            <strong>(a)</strong> The Guaranteed Period may be extended if your
            Money Transfer is created over the weekend or public holiday.
          </Typography>

          <Typography variant="body1">
            <strong>(b)</strong> We must receive sufficient funds from you
            during the Guaranteed Period to convert your money at the guaranteed
            rate. You will see when we have received your money by logging into
            your VCMTS Account.
          </Typography>

          <Typography variant="body1">
            <strong>(c)</strong> If we receive your funds after the Guaranteed
            Period, we will not be able to convert your money using the
            guaranteed rate, and your transfer will become a non-guaranteed rate
            transfer. Accordingly, we may convert your money at the applicable
            exchange rate at the time we receive your money, or we will email
            you and ask you if you want to proceed with your transfer at the new
            exchange rate.
          </Typography>

          <Typography variant="body1">
            <strong>(d)</strong> If the relevant exchange rate (as provided by
            our reference exchange rate provider) changes by 5% or more during
            the Guaranteed Period, we can, at our option, suspend your transfer
            for a reasonable period of time or cancel your transfer and refund
            the money to you.
          </Typography>

          <Typography variant="body1">
            <strong>(e)</strong> For certain currencies, including where you are
            sending from Japanese Yen (JPY), the guaranteed rate will only be
            offered after your address has been verified by us.
          </Typography>

          <Typography variant="body1">
            <strong>(f)</strong> We may change these guaranteed rate conditions
            or suspend the guaranteed rate feature at any time.
          </Typography>

          <Typography variant="h3">13.4 Auto Conversion.</Typography>

          <Typography variant="body1">
            For certain currencies, you may be able to set one or more
            conversion orders to be automatically executed once the exchange
            rate you pick is offered by our Services (an “auto conversion
            order”). There may be limits to the number of auto conversion orders
            you are able to set up, as well as the amounts of money you can
            schedule to convert. VCMTS does not guarantee that it will be able
            to execute your auto conversion order in all circumstances. The auto
            conversation feature is to set up a preauthorised remittance payment
            transaction and is not a forward, derivative, or other financial
            product or device. If you do not fund your preauthorised transfer,
            it will be canceled.
          </Typography>

          <Typography variant="h3">
            13.5 We are not a currency trading platform.
          </Typography>

          <Typography variant="body1">
            VCMTS is not a currency trading platform, and accordingly, you
            should not use our Services, including the VCMTS Account or the auto
            conversion order function for this purpose (including creating
            multiple auto conversion orders or a series of Money Transfers
            without the intention of completing them or for seeking to profit
            from FX trading). If we detect that you are using our Services for
            this purpose, we may, at our sole discretion, set a limit on the
            number of auto conversion orders you may create, cancel your orders,
            set a limit on the amount of money you can convert or transfer in
            one or more currencies or in the same currency, restrict your
            ability to use this or other features, or suspend or close your
            VCMTS Account and disgorge your gains.
          </Typography>

          <Typography variant="h2">14. Receiving money</Typography>
          <Typography variant="h3">
            14.1 You can receive money into your Multi Currency Account.
          </Typography>

          <Typography variant="body1">
            In certain currencies, we may provide you with local account details
            that you can use to send money directly to your Currency Account
            using Pay-In Methods we support from time to time or provide to
            third parties to send money to you (“Account Details”). See section
            11.2 for more details on Pay-In Methods. If you request Account
            Details, these are linked to your Multi Currency Account, which is a
            single payment account. Account Details, therefore, don't reflect
            separate payment accounts. They are only a way to make a Pay-in or
            receive money into your Multi Currency Account. Your Multi Currency
            Account is held solely by Vic Pay Inc.
          </Typography>

          <Typography variant="body1">
            We may carry out verification checks when you request Account
            Details in addition to the checks required to obtain a Multi
            Currency Account, and we may carry out further checks on an ad-hoc
            basis. We may change the Account Details given to you following the
            process set out in Section 26. You agree that VCMTS will not be held
            responsible for any activity involving Account Details that were
            changed in accordance with the terms of this Agreement, including
            where you do not provide updated Account Details to third parties.
          </Typography>

          <Typography variant="h3">
            14.2 The money received is shown in your Multi Currency Account.
          </Typography>

          <Typography variant="body1">
            Any money you receive into your Multi Currency Account will be
            recorded in the transaction history section of your Multi Currency
            Account. You should check the incoming funds in your Multi Currency
            Account against your own records regularly and let us know if there
            are any irregularities.
          </Typography>

          <Typography variant="h3">
            14.3 The money received may be subject to reversal.
          </Typography>

          <Typography variant="body1">
            You acknowledge that the money received in your Multi Currency
            Account ("Received Amount") may be subject to reversal, and you
            agree that we may deduct the Received Amount from your Multi
            Currency Account if it was reversed by the person who paid you the
            Received Amount or any relevant payment services provider.
          </Typography>

          <Typography variant="h3">
            14.4 Sending money using an email address.
          </Typography>

          <Typography variant="body1">
            If you send money to a person using an email address that is not
            registered with us, the money will not be credited until the
            intended recipient has claimed the money following the steps we have
            set out for them. Until then, there is no relationship between us
            and the intended recipient, and the money continues to belong to
            you. We will refund the money to you if the intended recipient does
            not claim the money or if they have failed our customer checks
            within a reasonable time period as determined by us.
          </Typography>

          <Typography variant="h2">
            15. Maintaining your VCMTS Account
          </Typography>

          <Typography variant="h3">
            15.1 Transaction history is displayed on your VCMTS Account.
          </Typography>

          <Typography variant="body1">
            All your transactions (including your current balance, money you
            have uploaded, received, sent, and/or withdrawn) are recorded in the
            transaction history section of your VCMTS Account. You may access
            this information after you log in to your VCMTS Account. We have
            allocated a reference number to each transaction; you should quote
            this reference number when communicating with us about a particular
            transaction.
          </Typography>

          <Typography variant="h3">
            15.2 Check your VCMTS Account regularly.
          </Typography>

          <Typography variant="body1">
            You must check your VCMTS Account regularly and carefully and
            contact us immediately if you don’t recognize a transaction or think
            we have made a payment incorrectly. You must tell us about any
            unauthorized or incorrectly executed transactions immediately, but
            no later than thirteen (13) months from the transaction; otherwise,
            you may not be entitled to have any errors corrected.
          </Typography>

          <Typography variant="h3">
            15.3 You accept the risks of holding your funds in multiple
            currencies.
          </Typography>

          <Typography variant="body1">
            You agree and accept all the risks associated with maintaining
            Currency Accounts in multiple currencies, including any risks
            associated with fluctuations in the relevant exchange rates over
            time. You agree that you will not use our Services for speculative
            trading.
          </Typography>

          <Typography variant="h3">
            15.4 No negative balance in your Multi Currency Account.
          </Typography>

          <Typography variant="body1">
            You promise to always have a zero or positive balance in your Multi
            Currency Account. If your Multi Currency Account goes into a
            negative balance as a result of a chargeback, reversal of a
            transaction, deduction of fees, or any other action carried out by
            you, you promise to repay the negative balance immediately without
            any notice from us. We may send you reminders or take such other
            reasonable actions to recover the negative balance from you; for
            example, we may use a debt collection service or take further legal
            actions. We will charge you for any costs we may incur as a result
            of these additional collection efforts.
          </Typography>

          <Typography variant="h3">15.5 Taxes.</Typography>

          <Typography variant="body1">
            You are responsible for any taxes that may be applicable to payments
            you make or receive, and it is your responsibility to collect,
            report, and pay the correct tax to the appropriate tax authority.
          </Typography>

          <Typography variant="h2">
            16. Withdrawing from your Multi Currency Account
          </Typography>

          <Typography variant="h3">
            16.1 You can request to withdraw your money.
          </Typography>

          <Typography variant="body1">
            After you log in to your Multi Currency Account, you may request all
            or part of your money held in your Multi Currency Account to be
            withdrawn. Press "send money" and follow the steps as prompted on
            screen. We will charge you a fee for each withdrawal request where
            this is permissible by law, we will let you know the exact amount
            when you submit your request. You can also find out more information
            about the fees we charge on the Pricing page.
          </Typography>

          <Typography variant="h3">
            16.2 Payout Methods available to you.
          </Typography>

          <Typography variant="body1">
            You may be presented with one or more methods of withdrawal (in this
            Agreement, we will call these methods "Payout Methods"). The number
            of Payout Methods made available to you will depend on a number of
            factors, including where you live and your verification status with
            us. We cannot guarantee the use of any particular Payout Method and
            may change or stop offering a Payout Method at any time without
            notice to you, but we will ensure that you will always have at least
            one Payout Method available to you.
          </Typography>

          <Typography variant="h3">
            16.3 You must provide correct information to us.
          </Typography>

          <Typography variant="body1">
            When setting up your withdrawal request, you must ensure that the
            information you provide is correct and complete. We will not be
            responsible for money sent to the wrong recipient as a result of
            incorrect information provided by you. If you have provided wrong
            information to us, you may ask us to assist you in recovering the
            money, but we cannot guarantee that such efforts will be successful.
          </Typography>

          <Typography variant="h3">
            16.4 Your withdrawal request is subject to limits.
          </Typography>

          <Typography variant="body1">
            You agree that your Multi Currency Account is subject to withdrawal
            limits. If your withdrawal request exceeds the current limit, we may
            decline your request and require you to provide additional documents
            to us so that we can carry out additional checks before allowing the
            money to be withdrawn.
          </Typography>

          <Typography variant="h2">17. How much will you pay?</Typography>

          <Typography variant="h3">17.1 You must pay our fees.</Typography>

          <Typography variant="body1">
            You must pay the fees in connection with the use of our Services. We
            will not process your transaction until we have received the fees
            from you.
          </Typography>
          <Typography variant="h2">17. How much will you pay?</Typography>

          <Typography variant="h3">
            17.2 You can see our fee structure on the Pricing page.
          </Typography>

          <Typography variant="body1">
            We will let you know the exact amount payable by you when you set up
            your order. You can see our fee structure on the "Pricing" page. For
            clarity, the fees applicable to you as set out on the "Pricing" page
            form part of this Agreement, which may be subject to change as set
            out in section 26.
          </Typography>

          <Typography variant="h3">
            17.3 We can make deductions from your Multi Currency Account.
          </Typography>

          <Typography variant="body1">
            You agree that we are authorized to deduct our fees, any applicable
            reversal amounts, and/or any amounts you owe us from your Multi
            Currency Account. If you don’t have enough money in your Multi
            Currency Account to cover these amounts, we may refuse to execute
            the relevant transaction or provide any Services to you.
          </Typography>

          <Typography variant="h3">
            17.4 We may charge you a variable fee during periods of potential
            heightened volatility or unpredictability in the foreign exchange
            market, as determined by VCMTS.
          </Typography>

          <Typography variant="body1">
            We will let you know if this fee is applicable when you set up your
            order, for more information see here.
          </Typography>

          <Typography variant="h2">18. Currency Conversion</Typography>

          <Typography variant="h3">
            18.1 You may convert the money held in one currency in your Multi
            Currency Account into other currencies we support from time to time.
          </Typography>

          <Typography variant="body1">
            You can only perform a conversion in respect of funds that you
            already hold in your Multi Currency Account. A conversion fee will
            apply when we perform a currency conversion, for more information,
            see here.
          </Typography>

          <Typography variant="h3">
            18.2 Refusing a currency conversion order.
          </Typography>

          <Typography variant="body1">
            We reserve the right in our sole discretion to refuse any currency
            conversion order. Reasons for refusal may include but are not
            limited to incorrect information about the recipient, insufficient
            available funds, or where we believe you may have violated this
            Agreement, including we believe you are attempting to engage in
            currency trading or other trading for purposes not permitted by this
            Agreement. We will endeavor to notify you of any refusal, using the
            contact information in your Multi Currency Account, stating (where
            possible) the reasons for such refusal and explaining how to correct
            any errors. However, we will not notify you if such notification may
            be unlawful.
          </Typography>

          <Typography variant="h2">
            19. Closing your Multi Currency Account and/or VCMTS Account
          </Typography>

          <Typography variant="h3">
            19.1 You may close your Multi Currency Account and/or VCMTS Account
            at any time.
          </Typography>

          <Typography variant="body1">
            You may end this Agreement and close your Multi Currency Account
            and/or VCMTS Account at any time by contacting our Customer Support.
          </Typography>

          <Typography variant="h3">
            19.2 You should withdraw your money within a reasonable time.
          </Typography>

          <Typography variant="body1">
            At the time of closure, if you still have money in your Multi
            Currency Account, you must withdraw your money within a reasonable
            period of time by following the steps described in section 16. After
            a reasonable period of time, you will no longer have access to your
            Multi Currency Account, but you can still withdraw your money by
            contacting Customer Support. You have the right to do this for a
            period of six (6) years from the date your Multi Currency Account is
            closed.
          </Typography>

          <Typography variant="h3">
            19.3 You must not close your VCMTS Account to avoid an
            investigation.
          </Typography>

          <Typography variant="body1">
            You must not close your Multi Currency Account and/or VCMTS Account
            to avoid an investigation. If you attempt to close your Multi
            Currency Account and/or VCMTS Account during an investigation, we
            may hold your money until the investigation is fully completed in
            order to protect our or a third party’s interest.
          </Typography>

          <Typography variant="h3">
            19.4 You are responsible for your Multi Currency Account and/or
            VCMTS Account after closure.
          </Typography>

          <Typography variant="body1">
            You agree that you will continue to be responsible for all
            obligations related to your Multi Currency Account and/or VCMTS
            Account even after it is closed.
          </Typography>

          <Typography variant="h2">20. Intellectual property rights</Typography>

          <Typography variant="h3">
            20.1 While you are using our Services, you may use the VCMTS
            Materials only for your personal use and solely as necessary in
            relation to those Services.
          </Typography>

          <Typography variant="h3">
            20.2 "VCMTS Materials" include any software (including without
            limitation the App, the API, developer tools, sample source code,
            and code libraries), data, materials, content and printed and
            electronic documentation (including any specifications and
            integration guides) developed and provided by us or our affiliates
            to you, or available for download from our Website.
          </Typography>

          <Typography variant="body1">
            You may not, and may not attempt to, directly or indirectly: ​
            <ul>
              <li>
                Transfer, sublicense, loan, sell, assign, lease, rent,
                distribute or grant rights in the Services or the VCMTS
                Materials to any person or entity;
              </li>
              <li>
                Remove, obscure, or alter any notice of any of our trademarks,
                or other "intellectual property" appearing on or contained
                within the Services or on any VCMTS Materials;
              </li>
              <li>
                Modify, copy, tamper with or otherwise create derivative works
                of any software included in the VCMTS Materials;
              </li>
              <li>
                Reverse engineer, disassemble, or decompile the VCMTS Materials
                or the Services or apply any other process or procedure to
                derive the source code of any software included in the VCMTS
                Materials or as part of the Services.
              </li>
            </ul>
          </Typography>

          <Typography variant="h2">
            21. Our responsibility for loss or damage
          </Typography>

          <Typography variant="h3">
            21.1 We are responsible to you for foreseeable loss and damage
            caused by us.
          </Typography>

          <Typography variant="body1">
            If we do not reasonably meet our commitments to you, we are
            responsible for loss or damage you suffer that is a foreseeable
            result of our breaking this contract or our failing to use
            reasonable care and skill. We are not responsible for any loss or
            damage that is not foreseeable. Loss or damage is foreseeable if
            either it is obvious that it will happen or if, at the time the
            contract was made, both we and you knew it might happen, for
            example, if you discussed it with us during your account sign up
            process.
          </Typography>

          <Typography variant="h3">
            21.2 We do not exclude or limit in any way our liability to you
            where it would be unlawful to do so.
          </Typography>

          <Typography variant="body1">
            This includes liability for death or personal injury caused by our
            negligence or the negligence of our employees, agents, or
            subcontractors; for fraud or fraudulent misrepresentation.
          </Typography>

          <Typography variant="h3">
            21.3 We are not liable for business losses.
          </Typography>

          <Typography variant="body1">
            If you use our Services for any commercial or business purpose, we
            will have no liability to you for any loss of profit, loss of
            business, business interruption, or loss of business opportunity.
          </Typography>
          <Typography variant="h3">
            21.4 We are not liable for technological attacks.
          </Typography>

          <Typography variant="body1">
            We will not be liable for any loss or damage caused by a virus, or
            other technological attacks or harmful material that may infect your
            computer equipment, computer programmes, data, or other proprietary
            material related to your use of our Services.
          </Typography>

          <Typography variant="h3">
            21.5 We have no control over websites linked to and from our
            Website.
          </Typography>

          <Typography variant="body1">
            We assume no responsibility for their content or any loss or damage
            that may arise from your use of them.
          </Typography>

          <Typography variant="h3">
            21.6 Our liability to you for unauthorized payments or our mistake.
          </Typography>

          <Typography variant="body1">
            In case of an unauthorized payment or mistake due to our error, we
            shall at your request immediately refund the payment amount,
            including all fees deducted by us. This shall not apply:
            <ul>
              <li>
                (a) where your VCMTS Account, or its personalized security
                features, are lost, stolen or misappropriated. You will be
                liable for the first 50 EUR of any unauthorized payments if we
                believe you should have been aware of the loss, theft, or
                unauthorized use. We will not hold you liable for the first 50
                EUR if the unauthorized payment was caused either by our acts or
                omissions or those of a third party expressly carrying out
                activities on our behalf. Your liability for the first 50 EUR
                also does not apply to any unauthorized transactions made after
                you have notified us that your VCMTS Account may have been
                compromised (using the details we’ve given you);
              </li>
              <li>
                (b) if you have acted fraudulently, in which case we will not
                refund you in any circumstances;
              </li>
              <li>
                (c) if you do not quickly notify us of security issues on your
                VCMTS Account (e.g., loss of your password), you remain liable
                for losses incurred up to your notification to us;
              </li>
              <li>
                (d) if the payment transaction was unauthorized but you have
                with intent or gross negligence compromised the security of your
                VCMTS Account or failed to comply with your obligations to use
                your VCMTS Account in the manner set out in this Agreement. In
                such a case, you shall be solely liable for all losses; or
              </li>
              <li>
                (e) if you don’t let us know about the unauthorized or
                incorrectly completed transaction within thirteen (13) months
                from the date of the payment transaction.
              </li>
            </ul>
          </Typography>

          <Typography variant="h3">
            21.7 You are responsible for checking your VCMTS Account regularly.
          </Typography>

          <Typography variant="body1">
            We rely on you to regularly check the transactions history of your
            VCMTS Account and to contact Customer Support immediately in case
            you have any questions or concerns.
          </Typography>

          <Typography variant="h3">
            21.8 We are not liable for things that are outside of our control.
          </Typography>

          <Typography variant="body1">
            We (and our affiliates) cannot be liable for our inability to
            deliver or delay as a result of things that are outside our control.
          </Typography>

          <Typography variant="h3">
            21.9 You are liable for breaking this Agreement or applicable laws.
          </Typography>

          <Typography variant="body1">
            In the unlikely event of loss or claims or costs and expenses
            arising out of your breach of this Agreement, any applicable law or
            regulation and/or your use of our Services, you agree to compensate
            us and our affiliates and hold us harmless. This provision will
            continue after our relationship ends.
          </Typography>

          <Typography variant="h3">
            21.10 What happens if you owe us money.
          </Typography>

          <Typography variant="body1">
            In the event you are liable for any amounts owed to us, we may
            immediately remove such amounts from your Multi Currency Account (if
            available). If there are insufficient funds in your Multi Currency
            Account to cover your liability, we reserve the right to collect
            your debt to us by using any payments received in your Multi
            Currency Account, and otherwise you agree to reimburse us through
            other means. We may also recover amounts you owe us through legal
            means, including, without limitation, through the use of a debt
            collection agency.
          </Typography>

          <Typography variant="h2">22. Accessing our Services</Typography>

          <Typography variant="h3">
            22.1 We will try to make sure our Services are available to you when
            you need them.
          </Typography>

          <Typography variant="body1">
            However, we do not guarantee that our Services will always be
            available or be uninterrupted. We may suspend, withdraw, discontinue
            or change all or any part of our Services without notice. We will
            not be liable to you if for any reason our Services are unavailable
            at any time. You are responsible for making all arrangements
            necessary for you to have access to our Services. If you have
            granted permission to a third party to access your account, we may
            refuse access to that third party if we are concerned about
            unauthorised or fraudulent access by that third party. We will give
            you notice if we do this, either before or immediately after we
            refuse access, unless notifying you would be unlawful or compromise
            our reasonable security measures.
          </Typography>

          <Typography variant="h2">23. Information security</Typography>

          <Typography variant="h3">
            23.1 You are responsible for configuring your information
            technology, computer programmes, and platform in order to access our
            Services.
          </Typography>

          <Typography variant="body1">
            You should use your own virus protection software. We take security
            very seriously at Vic Pay Inc. and we work
            hard, using state-of-the-art security measures, to make sure that
            your information remains secure. Our Services are a safe and
            convenient way to send money to friends and family and to other
            people that you trust. However, we do advise you to consider very
            carefully before sending money to anyone that you do not know well.
          </Typography>

          <Typography variant="h3">
            23.2 You must not misuse our Services.
          </Typography>

          <Typography variant="body1">
            You must not misuse our Services by introducing viruses, trojans,
            worms, logic bombs, or other materials which are malicious or
            technologically harmful. You must not attempt to gain unauthorised
            access to our Website, our servers, computers, or databases. You
            must not attack our Website with any type of denial of service
            attack. By breaching this provision, you would commit a criminal
            offence under UK law. We will report any such breach to the relevant
            law enforcement authorities and we will co-operate with those
            authorities by disclosing your identity to them. In the event of
            such a breach, your right to use our Website and/or our Services
            will cease immediately. If you are aware of anyone or any entity
            that is using the Services inappropriately, please contact us.
            Similarly, if you receive any emails, purporting to be from VCMTS,
            which you suspect may be phishing emails, please forward the email
            to our Customer Service.
          </Typography>

          <Typography variant="h3">
            23.3 Monitoring your VCMTS Account login.
          </Typography>

          <Typography variant="body1">
            In addition to your credentials, we gather data from your devices
            and the way you log in to try to detect any fraudulent or suspicious
            attempt to access your VCMTS Account. We do not guarantee that, even
            by collecting this data, we’ll be able to prevent a fraudulent
            transaction or suspicious attempt to access your VCMTS Account. If
            we manage to detect a fraudulent or suspicious attempt of access, we
            may block it as deemed necessary.
          </Typography>
          <Typography variant="h2">24. Linking to our site</Typography>

          <Typography variant="h3">
            24.1 You may link to our Website provided you follow certain rules.
          </Typography>

          <Typography variant="body1">
            You may link to our Website, provided:
            <ul>
              <li>
                (a) you do so in a way that is fair and legal and does not
                damage our reputation or take advantage of it;
              </li>
              <li>
                (b) you do not suggest any form of association, approval or
                endorsement on our part where none exists;
              </li>
              <li>(c) you do not frame our Website on any other site; and</li>
              <li>(d) the website complies with our Acceptable Use Policy.</li>
            </ul>
            We reserve the right to withdraw linking permission without notice.
          </Typography>

          <Typography variant="h2">
            25. When we can end this Agreement or suspend our Services
          </Typography>

          <Typography variant="h3">
            25.1 We may end this Agreement by giving you two (2) months’ notice.
          </Typography>

          <Typography variant="body1">
            We may end this Agreement and close your Multi Currency Account
            and/or VCMTS Account or any service associated with it by giving you
            two (2) months’ prior notice.
          </Typography>

          <Typography variant="h3">
            25.2 We may suspend or close your Multi Currency Account and/or
            VCMTS Account without notice in certain circumstances.
          </Typography>

          <Typography variant="body1">
            We may at any time suspend or close your Multi Currency Account
            and/or VCMTS Account and/or end this Agreement without notice if:
            <ul>
              <li>
                (a) you breach any provision of this Agreement or documents
                referred to in this Agreement;
              </li>
              <li>
                (b) we are requested or directed to do so by any competent court
                of law, government authority, public agency, or law enforcement
                agency;
              </li>
              <li>
                (c) we have serious reasons to believe you are in breach of any
                applicable law or regulation; or
              </li>
              <li>
                (d) we have serious reasons to believe you are involved in any
                fraudulent activity, money laundering, terrorism financing or
                other criminal or illegal activity.
              </li>
            </ul>
          </Typography>

          <Typography variant="h3">
            25.3 We may suspend your Multi Currency Account and/or VCMTS Account
            for security reasons.
          </Typography>

          <Typography variant="body1">
            We may suspend your Multi Currency Account and/or VCMTS Account or
            restrict its functionality if we have reasonable concerns about:
            <ul>
              <li>
                (a) the security of your Multi Currency Account and/or VCMTS
                Account; or
              </li>
              <li>
                (b) suspected unauthorized or fraudulent use of your Multi
                Currency Account and/or VCMTS Account.
              </li>
            </ul>
          </Typography>

          <Typography variant="h3">
            25.4 We will give you notice of suspension where possible.
          </Typography>

          <Typography variant="body1">
            We will give you notice of any suspension or restriction and the
            reasons for such suspension or restriction as soon as we can, either
            before the suspension or restriction is put in place, or immediately
            after, unless notifying you would be unlawful or compromise our
            reasonable security measures. We will lift the suspension and/or the
            restriction as soon as practicable after the reasons for the
            suspension and/or restriction have ceased to exist.
          </Typography>

          <Typography variant="h3">
            25.5 You cannot use the App if this Agreement ends.
          </Typography>

          <Typography variant="body1">
            On termination for any reason all rights granted to you in
            connection with the App shall cease, you must immediately delete or
            remove the App from your devices.
          </Typography>

          <Typography variant="h2">26. Our right to make changes</Typography>

          <Typography variant="h3">
            26.1 We may change this Agreement by giving you at least two (2)
            months’ prior written notice.
          </Typography>

          <Typography variant="body1">
            If we do this, you can terminate this Agreement immediately and free
            of charge at any time before the proposed date of the entry into
            force of the proposed changes, by providing written notice to us. If
            we do not hear from you during the notice period, you will be
            considered as having accepted the proposed changes and they will
            apply to you from the effective date specified on the notice.
          </Typography>

          <Typography variant="h3">
            26.2 In some instances, we may change this Agreement immediately.
          </Typography>

          <Typography variant="body1">
            Despite section 26.1, changes to this Agreement which are (1) more
            favorable to you; (2) required by law; or (3) related to the
            addition of a new service, extra functionality to the existing
            Services; or (4) changes which neither reduce your rights nor
            increase your responsibilities, will come into effect immediately if
            they are stated in the change notice. Changes to exchange rates
            shall come into effect immediately without notice and you shall not
            have the right to object to such a change.
          </Typography>

          <Typography variant="h2">27. How we may contact you</Typography>

          <Typography variant="h3">
            27.1 We usually contact you via email.
          </Typography>

          <Typography variant="body1">
            For this purpose, you must at all times maintain at least one valid
            email address in your VCMTS Account profile. You should check for
            incoming messages regularly and frequently; these emails may contain
            links to further communication on our Website. If you don’t maintain
            or check your email and other methods of communications, you will
            miss emails about your transactions and our Services. We cannot be
            liable for any consequence or loss if you don’t do this. If we have
            reasonable concerns either about the security of your Multi Currency
            Account and/or VCMTS Account, or any suspected or actual fraudulent
            use of your Multi Currency Account and/or VCMTS Account, we will
            contact you via telephone, email, or both (unless contacting you
            would be unlawful or compromise our reasonable security measures).
          </Typography>
          <Typography variant="h2">
            27.2 Other ways we may contact you.
          </Typography>

          <Typography variant="body1">
            In addition to communicating via email, we may contact you via
            letter or telephone where appropriate. If you use any mobile
            services, we may also communicate with you via SMS. Any
            communications or notices sent by:
            <ul>
              <li>
                Email will be deemed received by you on the same day if it is
                received in your email inbox before 5pm on a Business Day. If it
                is received in your email inbox after 5pm on a Business Day or
                at any other time, it will be deemed received on the next
                Business Day.
              </li>
              <li>
                Post will be deemed received three (3) days from the date of
                posting for UK post or within five (5) days of posting for
                international post.
              </li>
              <li>SMS will be deemed received the same day.</li>
            </ul>
          </Typography>

          <Typography variant="h2">
            27.3 Where legislation requires us to provide information to you on
            a durable medium, we will either send you an email (with or without
            attachment) or send you a notification pointing you to information
            on our Website in a way that enables you to retain the information
            in print format or other format that can be retained by you
            permanently for future reference. Do keep copies of all
            communications we send or make available to you.
          </Typography>

          <Typography variant="h2">
            27.4 If you need a copy of the current Agreement or any other
            relevant document, please contact Customer Support.
          </Typography>

          <Typography variant="h2">
            27.5 This Agreement is made in the English language. Documents or
            communications in any other languages are for your convenience and
            only the English language version of them is official.
          </Typography>

          <Typography variant="h2">28. Complaints</Typography>

          <Typography variant="h3">
            28.1 If you have any complaints about us or our Services, you may
            contact us following our customer complaint procedure.
          </Typography>

          <Typography variant="h2">29. Other important terms</Typography>

          <Typography variant="h3">
            29.1 Nobody else has any rights under this Agreement.
          </Typography>

          <Typography variant="body1">
            This Agreement is between you and us. No other person shall have any
            rights to enforce any of its terms. Neither of us will need to get
            the agreement of any other person in order to end or make any
            changes to this Agreement.
          </Typography>

          <Typography variant="h3">
            29.2 We may transfer this Agreement to someone else.
          </Typography>

          <Typography variant="body1">
            You may not transfer, assign, mortgage, charge, subcontract, declare
            a trust over or deal in any other manner with any or all of your
            rights and obligations under this Agreement (including the VCMTS
            Account) without our prior written consent. We reserve the right to
            transfer, assign or novate this Agreement (including the VCMTS
            Account) or any right or obligation under this Agreement at any time
            without your consent and if permissible by law. This does not affect
            your rights to close your Multi Currency Account and/or VCMTS
            Account under section 19.
          </Typography>

          <Typography variant="h3">
            29.3 If a court finds part of this Agreement illegal, the rest will
            continue in force.
          </Typography>

          <Typography variant="body1">
            Each of the paragraphs of this Agreement operates separately. If any
            court or relevant authority decides that any of them are unlawful,
            the remaining paragraphs will remain in full force and effect.
          </Typography>

          <Typography variant="h3">
            29.4 Even if we delay in enforcing this Agreement, we can still
            enforce it later.
          </Typography>

          <Typography variant="body1">
            If we delay in asking you to do certain things or in taking action,
            it will not prevent us from taking steps against you at a later
            date.
          </Typography>

          <Typography variant="h3">
            29.5 This Agreement supersedes any other previous agreements.
          </Typography>

          <Typography variant="body1">
            This Agreement supersedes and extinguishes all previous agreements
            between you and VCMTS, whether written or oral, relating to its
            subject matter.
          </Typography>

          <Typography variant="h3">
            29.6 Which laws apply to this Agreement and where you may bring
            legal proceedings.
          </Typography>

          <Typography variant="body1">
            This Agreement is governed by UK law. Any dispute between you and us
            in connection with your VCMTS Account and/or this Agreement may be
            brought in the courts of Glasgow, United Kingdom.
          </Typography>
          <Typography variant="h1">Acceptable Use Policy</Typography>

          <Typography variant="body1">
            This Acceptable Use Policy sets out the terms under which you may
            access our Services and applies as soon as you access and/or use
            VCMTS.
          </Typography>

          <Typography variant="body1">
            Please check the User Agreement for the meaning of the defined
            words.
          </Typography>

          <Typography variant="h2">1. Restricted activities</Typography>

          <Typography variant="h3">
            1.1 You may use our Services only for lawful purposes.
          </Typography>

          <Typography variant="body1">
            You may not use our Services:
            <ul>
              <li>
                in any way that breaches any applicable local, national, or
                international law or regulation, or causes VCMTS to breach any
                applicable law or regulation;
              </li>
              <li>
                in any way that is unlawful or fraudulent, or has any unlawful
                or fraudulent purpose or effect;
              </li>
              <li>
                for the purpose of harming or attempting to harm minors in any
                way;
              </li>
              <li>
                for anything that is abusive, harmful, or does not comply with
                our content standards;
              </li>
              <li>
                for any unsolicited or unauthorised advertising, promotional
                material, or any other form of spam;
              </li>
              <li>
                to deal in harmful programs such as viruses, spyware, or similar
                computer code designed to adversely affect the operation of any
                computer software or hardware;
              </li>
              <li>
                in any way that would locally or internationally evade any
                applicable taxes or facilitate tax evasion.
              </li>
            </ul>
          </Typography>

          <Typography variant="h2">
            1.2 Unsupported businesses and transactions
          </Typography>

          <Typography variant="body1">
            The restrictions below do not apply to lawful transactions using the
            VCMTS Card. For more information on the acceptable use of the VCMTS
            Card, please see the VCMTS Card Supplement to the Customer
            Agreement.
          </Typography>

          <Typography variant="body1">
            We do not support businesses or transactions that are involved in
            any of the following categories; such businesses or transactions may
            be declined. While this list is representative, it is not
            exhaustive, and we reserve our right to deny our services to any
            customers who exceed our risk tolerance.
          </Typography>

          <Typography variant="h3">
            1.2.1 Regulated or illegal products and services
          </Typography>

          <Typography variant="body1">
            <ul>
              <li>Adult content.</li>
              <li>
                Pornography and other visual content depicting explicitly sexual
                acts.
              </li>
              <li>
                Services of sexual nature (webcam shows, live chats,
                prostitution, escorts, etc).
              </li>
              <li>
                Sexually oriented establishments (massage parlors, strip clubs,
                gentleman’s clubs).
              </li>
              <li>Alcohol businesses.</li>
              <li>
                Payments for the sale of alcohol to retail customers are not
                supported.
              </li>
              <li>Tobacco products.</li>
              <li>Cannabis.</li>
              <li>Products containing CBD.</li>
              <li>
                Any other products or services related to legal marijuana trade.
              </li>
              <li>
                Certain controlled substances or other products that present a
                risk to consumer safety.
              </li>
              <li>Smart drugs, nootropic supplements.</li>
              <li>
                Substances that provide similar effects as illegal drugs
                (kratom, khat, etc.)
              </li>
              <li>Anabolic steroids and peptides.</li>
              <li>Drug paraphernalia.</li>
              <li>
                Any equipment, product, or material which is intended for
                making, using, or concealing drugs
              </li>
              <li>Pharmaceuticals.</li>
              <li>
                Nutraceuticals, pseudo-pharmaceuticals, and other products which
                make unreasonable health claims not approved or verified by the
                applicable regulatory body.
              </li>
              <li>Online pharmacies.</li>
              <li>Prescription-only pharmaceutical drugs.</li>
              <li>
                Payments for the sale of pharmaceutical products to retail
                customers are not supported.
              </li>
              <li>Chemicals.</li>
              <li>Commodity chemicals.</li>
              <li>Counterfeit or unauthorised goods.</li>
              <li>Unauthorised sale of designer and/or brand products.</li>
              <li>Sale of illegally imported or exported goods.</li>
              <li>Gambling.</li>
              <li>Lotteries.</li>
              <li>Fantasy sports with cash prizes.</li>
              <li>Games of chance with cash prizes.</li>
              <li>Sales of in-game currencies by unauthorised vendors.</li>
              <li>
                Any type of gambling payments or businesses related to Turkey,
                the United States, Canada, the United Arab Emirates or
                Singapore.
              </li>
              <li>
                Any other type of businesses related to gambling, up to VCMTS’s
                own discretion.
              </li>
              <li>Intellectual property or proprietary rights infringement.</li>
              <li>
                Any product or service that directly infringes or facilitates
                infringement upon the copyright, patent, trademark, trade
                secrets, proprietary, or privacy rights of any third party.
              </li>
              <li>
                Products and services which are not legal in the jurisdiction
                they are being offered in.
              </li>
            </ul>
          </Typography>
          <Typography variant="h3">
            1.2.2 Financial and other professional services
          </Typography>

          <Typography variant="body1">
            <ul>
              <li>Binary options.</li>
              <li>Escrow services.</li>
              <li>Using VCMTS Borderless account as an escrow account.</li>
              <li>
                Companies involved in the exchange or trading of
                cryptocurrencies, or any other virtual currencies.
              </li>
              <li>
                Including payments for the purpose of purchasing
                cryptocurrencies.
              </li>
              <li>
                Marketplaces from countries outside of the United Kingdom, the
                European Economic Area and/or European Union or the United
                States.
              </li>
              <li>
                Money service businesses, or any businesses that carry on the
                activity of:
              </li>
              <ul>
                <li>
                  Operating a bureau de change or currency exchange service.
                </li>
                <li>
                  Transmitting money, or any representation of monetary value,
                  on behalf of third parties.
                </li>
                <li>Cashing cheques.</li>
                <li>Payment processing.</li>
              </ul>
              <li>
                Platforms allowing the trading and/or exchanging of
                FX/CFD/options.
              </li>
              <li>Shell banks.</li>
              <li>Superannuation funds.</li>
              <li>Syndicates.</li>
              <li>
                Trust and corporate service providers that are involved in:
              </li>
              <ul>
                <li>Opening accounts on behalf of other businesses.</li>
                <li>Offshore company formation.</li>
                <li>
                  Using nominee directors and/or shareholders to obscure company
                  ownership.
                </li>
                <li>
                  Any other financial services operating without a licence where
                  one is required.
                </li>
              </ul>
            </ul>
          </Typography>
          <Typography variant="h3">
            1.2.3 Other restricted activities (continued)
          </Typography>

          <Typography variant="body1">
            <ul>
              <li>IPTV and VOIP services.</li>
              <li>
                Individuals, entities, or countries subject to international
                sanctions.
              </li>
              <li>Weaponry, military and semi-military goods and services.</li>
              <li>
                Weapons (including weapons of historic significance), military
                software, or any other goods or services intended for military
                use.
              </li>
              <li>
                Nonprofit organisations and charities from countries outside of
                Canada, the United Kingdom, the European Economic Area and/or
                European Union, Switzerland, USA, Australia, or New Zealand.
              </li>
              <ul>
                <li>
                  Unregistered charities are not supported from any regions.
                </li>
              </ul>
              <li>
                Trusts and foundations from countries outside of Canada, United
                Kingdom, European Economic Area and/or European Union,
                Switzerland, USA, Australia, or New Zealand.
              </li>
              <li>
                Trade of restricted and/or endangered animal species and
                products derived from them.
              </li>
              <li>
                Multi-level marketing, pyramid schemes, get rich quick schemes,
                referral marketing.
              </li>
              <ul>
                <li>
                  Including any other services promising unreasonably high
                  rewards.
                </li>
              </ul>
              <li>
                The United Arab Emirates-based oil, gas and shipping companies,
                as well as related activities.
              </li>
              <li>
                Businesses with relations or activity directly or indirectly
                linked to Cuba.
              </li>
              <li>Surrogacy businesses.</li>
              <li>
                Currency-based restrictions as outlined in various FAQ pages.
              </li>
            </ul>
          </Typography>
          <Typography variant="h3">1.3 You also agree:</Typography>

          <Typography variant="body1">
            <ul>
              <li>
                not to copy or use any part of our Services in contravention of
                the provisions of our User Agreement.
              </li>
              <li>
                not to access without authority, interfere with, damage, or
                disrupt:
              </li>
              <ul>
                <li>any part of our Services;</li>
                <li>
                  any equipment or network on which our Website is stored;
                </li>
                <li>any software used in the provision of our Services;</li>
                <li>
                  any equipment, network, or software owned or used by any third
                  party.
                </li>
              </ul>
              <li>
                not to use your VCMTS Account in a manner that is likely to
                result in complaints, disputes, reversals, chargebacks, or other
                liabilities to VCMTS, other Customers, third parties, or you.
              </li>
            </ul>
          </Typography>

          <Typography variant="h3">
            1.4 You may only use your VCMTS Account Number to receive funds into
            your VCMTS Account for the following purposes:
          </Typography>

          <Typography variant="body1">
            <ul>
              <li>receiving your own salary and/or wages;</li>
              <li>
                receiving payouts from e-commerce and freelancer platforms;
              </li>
              <li>
                receiving payments from family, friends or other people you know
                for personal purposes;
              </li>
              <li>
                receiving payments from your clients and other third parties for
                the purpose of business payments.
              </li>
            </ul>
            You may not use your personal VCMTS account to receive business
            payments.
          </Typography>

          <Typography variant="h3">2. Content standards</Typography>

          <Typography variant="body1">
            These content standards apply to any material you contribute to our
            Services (contributions).
          </Typography>

          <Typography variant="h4">2.1 Contributions must:</Typography>

          <Typography variant="body1">
            <ul>
              <li>be accurate;</li>
              <li>be genuinely held (where they state opinions);</li>
              <li>
                comply with applicable law in the UK and in any country from
                which they are posted, or to which they relate.
              </li>
            </ul>
          </Typography>

          <Typography variant="h4">2.2 Contributions must not:</Typography>

          <Typography variant="body1">
            <ul>
              <li>contain any material which is defamatory;</li>
              <li>
                contain any material which is obscene, offensive, hateful, or
                inflammatory;
              </li>
              <li>promote sexually explicit material;</li>
              <li>promote violence;</li>
              <li>
                promote discrimination based on race, sex, religion,
                nationality, disability, sexual orientation, or age;
              </li>
              <li>
                infringe any copyright, database right, or trademark of any
                other person;
              </li>
              <li>be likely to deceive any person;</li>
              <li>
                be made in breach of any legal duty owed to a third party, such
                as a contractual duty or a duty of confidence;
              </li>
              <li>promote any illegal activity;</li>
              <li>
                be abusive, threatening in any way, invade another’s privacy, or
                cause annoyance, inconvenience, or needless anxiety;
              </li>
              <li>
                be likely to harass, upset, embarrass, alarm, or annoy any other
                person;
              </li>
              <li>
                be used to impersonate any person, or to misrepresent your
                identity or affiliation with any person;
              </li>
              <li>
                give the impression that they relate to VCMTS, if this is not
                the case;
              </li>
              <li>
                advocate, promote, or assist any unlawful act such as (by way of
                example only) copyright infringement or computer misuse.
              </li>
            </ul>
          </Typography>

          <Typography variant="h3">3. Suspension and termination</Typography>

          <Typography variant="body1">
            We alone will determine whether there has been a breach of this
            Acceptable Use Policy through your use of our Services.
          </Typography>

          <Typography variant="h4">
            3.1 We take breach of this policy seriously and may take the
            following actions:
          </Typography>

          <Typography variant="body1">
            <ul>
              <li>
                immediate, temporary, or permanent withdrawal of your right to
                use our Services;
              </li>
              <li>
                suspend or cancel your payment orders and take such other
                actions as we consider necessary;
              </li>
              <li>
                immediate, temporary, or permanent removal of any posting or
                material uploaded by you;
              </li>
              <li>issue of a warning;</li>
              <li>
                legal action against you including proceedings for reimbursement
                of all costs on an “all expenses” basis;
              </li>
              <li>
                reporting and disclosure of information to law enforcement
                authorities.
              </li>
            </ul>
          </Typography>

          <Typography variant="h3">
            4. Changes to the Acceptable Use Policy
          </Typography>

          <Typography variant="body1">
            We may revise this Acceptable Use Policy at any time by amending
            this page. We recommend checking this page regularly as it is
            legally binding to you.
          </Typography>
          <Typography variant="h1">Cookie Policy</Typography>

          <Typography variant="body1">
            We ("VCMTS") use small files (known as, Cookie, Cookies) to see how
            you use our site and products. We put them on your computer when you
            visit our site. We use them to know it’s you and store information
            about your visits. With Cookies we can give you a better browsing
            experience, make our products better, and keep our marketing costs
            down. They make it easier to log in, and they show us where you
            might need help with our products.
          </Typography>

          <Typography variant="body1">
            Below, we’ve explained how and why we use Cookies, and what each of
            them do:
          </Typography>

          <Typography variant="h4">
            Strictly Necessary or Essential Cookies
          </Typography>

          <Typography variant="body1">
            Strictly Necessary or Essential Cookies are there for our website to
            work. They let us save your cookie choice and keep the site secure.
            They’re always on.
          </Typography>

          <Typography variant="h4">Functional Cookies</Typography>

          <Typography variant="body1">
            Functional Cookies give you a better browsing experience. These
            Cookies let us make the site suit your needs, like showing it in the
            same language as your web browser.
          </Typography>

          <Typography variant="h4">
            Analytical or Performance Cookies
          </Typography>

          <Typography variant="body1">
            Analytical or Performance Cookies help us make our products better.
            These let us use tools that show us how you use our products. They
            help us find and fix problems for our customers.
          </Typography>

          <Typography variant="h4">
            Cookies that keep our marketing costs down
          </Typography>

          <Typography variant="body1">
            Cookies that keep our marketing costs down. These make our marketing
            better — helping us to spend less on sites like Google and Facebook,
            and more on charging as little as we can. When our costs go down, so
            do our prices. We share this information in aggregate form with
            third parties, for the purpose of advertising.
          </Typography>

          <Typography variant="h3">How to block Cookies</Typography>

          <Typography variant="body1">
            You can choose to block all or some Cookies, to do this you should
            go to your browser settings. However, if you block all Cookies
            (including essential Cookies) you may not be able to use some of the
            site. Your browser settings also allow you to delete all Cookies
            stored on your device any time you wish.
          </Typography>

          <Typography variant="h3">How to control Cookies</Typography>

          <Typography variant="body1">
            The UK Information Commissioner’s Office has provided the following
            guidance on Cookies:
          </Typography>

          <Typography variant="body1">
            <ul>
              <li>
                You can read information on Cookies on different websites,
                including AboutCookies.org and AllAboutCookies.org.
              </li>
              <li>
                The European Interactive Digital Advertising Alliance website
                ‘Your Online Choices’: allows you to install an add-on to
                decline Cookies across different advertising networks. Google
                has developed a browser add-on to allow users to decline Google
                Analytics on all websites which use it. This is also available
                in the Chrome web store.
              </li>
              <li>
                Some browsers include a feature known as ‘Do Not Track’ or DNT.
                This allows you to choose whether a website can track you.
                However, whilst DNT is available in many browsers, websites do
                not need to recognise this request, so it may not always work.
                You can get help on how to use DNT in Microsoft Edge, Microsoft
                Internet Explorer, Mozilla Firefox, Google Chrome and Opera.
              </li>
              <li>
                For more information on how browsing works, visit the support
                pages for your browser: Microsoft Edge, Microsoft Internet
                Explorer, Mozilla Firefox, Google Chrome, Safari (IOS mobile and
                desktop) and Opera.
              </li>
            </ul>
          </Typography>

          <Typography variant="body1">
            If you worry about online tracking you can:
          </Typography>

          <Typography variant="body1">
            <ul>
              <li>
                Install a privacy-friendly browser on your device, such as
                Mozilla Firefox, Firefox Focus or Brave;
              </li>
              <li>
                Install anti-tracking and ad-blocking plug-ins on that browser,
                such as uBlock, Ghostery or PrivacyBadger; and
              </li>
              <li>
                Use privacy-friendly web search engines, such as DuckDuckGo or
                Qwant.
              </li>
            </ul>
          </Typography>

          <Typography variant="body1">
            If you have any questions about the Cookies or similar technologies
            in use on our website, please email info@victorum-capital.com
          </Typography>

          <Typography variant="body1">
            The table below contains technical descriptions for each of the
            Cookies we use and our reasons for using them.
          </Typography>
          <Typography variant="h1">
            CUSTOMER TERMS FOR ACQUIRING PAYMENTS SERVICES
          </Typography>

          <Typography variant="h2">Features</Typography>

          <Typography variant="h2">
            CUSTOMER TERMS FOR ACQUIRING PAYMENTS SERVICES
          </Typography>

          <Typography variant="h2">VCMTS</Typography>

          <Typography variant="h3">Last updated: 27 November 2023</Typography>

          <Typography variant="h2">1. This Agreement</Typography>
          <Typography variant="body1">
            <strong>1.1</strong> This is a contract between you (the “Customer”
            or “you”), and VCMTS (“VCMTS”, “we”, or “us”), which defines the
            terms and conditions on which we provide the Request Money Transfer
            Services (as defined in section 3 below) to you (the “Agreement”).
          </Typography>

          <Typography variant="body1">Under this Agreement:</Typography>

          <Typography variant="body1">
            (1) VCMTS is acting as a payment facilitator and will provide
            payment processing and payment acquiring services to you, so that
            you can offer cards as a payment method to your buyers in order for
            them to pay for your goods and services.
          </Typography>

          <Typography variant="body1">
            (2) We will provide payment gateway services to you so that you can
            accept online card-not-present payments from your buyers.
          </Typography>

          <Typography variant="body1">
            (3) We will be working with a third-party merchant acquirer
            (“Processor”) to provide the Request Money Services to you.
          </Typography>

          <Typography variant="body1">
            This Agreement refers to and incorporates by reference additional
            documents (the "Additional Documents"), which also apply to your use
            of our services, including:
          </Typography>

          <Typography variant="body1">
            (a) Our Customer Agreement, which defines the terms and conditions
            on which we provide our services to you;
          </Typography>

          <Typography variant="body1">
            (b) Our Privacy Policy, which sets out the terms on which we process
            any personal data we collect about you, or that you provide to us.
            By using our services, you consent to such processing, and you
            promise that all data provided by you is accurate;
          </Typography>

          <Typography variant="body1">
            (c) Our Cookie Policy, which sets out information about the
            “cookies” on our Website; and
          </Typography>

          <Typography variant="body1">
            (d) Our Acceptable Use Policy, which sets out the permitted and
            prohibited uses of our services.
          </Typography>

          <Typography variant="body1">
            The definitions of capitalised terms are below in the Glossary
            (section 2) or defined in parenthesis within this Agreement or the
            Additional Documents.
          </Typography>

          <Typography variant="body1">
            <strong>1.2</strong> By visiting our Website or using our Request
            Money Transfer Services (including downloading and using our App, or
            using our services via the API, a social media or other platform or
            other authorised third party), you confirm that you accept and agree
            to this Agreement in its most current form as posted on our Website,
            App or by an API Partner. If you do not agree, you must not use our
            Request Money Transfer Services.
          </Typography>

          <Typography variant="body1">
            <strong>1.3</strong> In case of any discrepancies between this
            Agreement and the Additional Documents or information we provide on
            our Website or App or via an API Partner, what is stated in this
            Agreement shall prevail.
          </Typography>

          <Typography variant="body1">
            <strong>1.4</strong> In order to receive some of our Services, you
            may be asked to agree to additional terms and conditions (including
            those referred to in section 1.2 above) which we will notify you
            about before you use that service.
          </Typography>

          <Typography variant="body1">
            <strong>1.5</strong> Future changes to this Agreement. Subject to
            section 11 of this Agreement, we will make changes to this Agreement
            from time to time. The revised Agreement will take effect as soon as
            it is posted on our Website and App or on the date notified to you.
          </Typography>

          <Typography variant="body1">
            <strong>1.6</strong> Where to get a copy of this Agreement. You can
            always see the most current version of this Agreement on our
            Website. If you would like a copy of this Agreement, you can
            download it or contact Customer Support.
          </Typography>

          <Typography variant="body1">
            <strong>1.7</strong> How to contact us. You can contact us by email
            or telephone. Our contact details are provided on the Contact page
            of our Website.
          </Typography>
          <Typography variant="h3">2. Glossary</Typography>

          <Typography variant="body1">In this Agreement:</Typography>

          <Typography variant="body1">
            <strong>"3-D Secure"</strong> means the "Three Domain Secure"
            protocol developed by Visa International Inc. (Visa) branded as
            "Verified by Visa" or the "MasterCard Secure Code" developed by
            MasterCard International Inc., including successive versions thereof
            and any amendments thereto.
          </Typography>

          <Typography variant="body1">
            <strong>"Additional Collateral"</strong> means non-interest-bearing
            funds provided to us by you as security to guarantee payment of any
            and all debt or liability from you to us and/or the Payment Schemes
            such as, without limitation, unpaid CS Fees, Deductions such as
            Chargebacks, Assessments, and Refunds, or other potential debt or
            liability, including those arising out of or in connection with any
            payment services.
          </Typography>

          <Typography variant="body1">
            <strong>"Agreement"</strong> means this agreement between us and you
            for the provision of the Request Money Transfer Services to you,
            including all Schedules and any other Schedule or document
            incorporated by reference.
          </Typography>

          <Typography variant="body1">
            <strong>"Alteration"</strong> has the meaning given to that term in
            section 13 of this Agreement.
          </Typography>

          <Typography variant="body1">
            <strong>"API"</strong> means the application programming interface
            provided by VCMTS, for example where applicable, through an API
            Partner.
          </Typography>

          <Typography variant="body1">
            <strong>"Applicable Law"</strong> means all laws, rules, and
            regulations (including the requirements, guidance or directions of
            any regulatory authority, agencies or governmental bodies)
            applicable to a party or to any Transaction, Refund, or Chargeback
            for the time being in force in any jurisdiction. These include but
            are not limited to anti-money laundering, anti-bribery, data
            protection, tax and consumer protection laws.
          </Typography>

          <Typography variant="body1">
            <strong>"Assessment"</strong> has the meaning given to that term in
            section 5.1 of this Agreement.
          </Typography>

          <Typography variant="body1">
            <strong>"Authorization"</strong> means the process whereby you
            request permission for a Payment Method to be used for a particular
            purchase of any Customer Product/Service. As this Agreement covers a
            variety of different Payment Methods you agree that if a Transaction
            status is "authorized", this means the payment transaction is likely
            to be successful, but the payment may still be blocked or subject to
            Chargeback by your Buyer (if a Chargeback is possible under the
            relevant Payment Scheme Rules). The likelihood of a payment as
            "authorized" being blocked or unsuccessful depends on the Payment
            Method used. In case of direct debit transactions in most cases a
            status of "Authorization Success" or similar only means the bank
            account exists and not that there are enough funds on the bank
            account to actually perform the payment.
          </Typography>

          <Typography variant="body1">
            <strong>"Authorized Representative"</strong> means the individual
            who has legal authority to agree to bind you to this Agreement
            and/or the individual who has legal authority to make any amendments
            to this Agreement.
          </Typography>

          <Typography variant="body1">
            <strong>""Business Day"</strong> means a day other than a Saturday
            or Sunday on which banks are open for normal business in the United
            Kingdom.
          </Typography>

          <Typography variant="body1">
            <strong>"Buyer"</strong> means any Person who is authorized to use a
            Payment Method issued to him/her and has initiated a Transaction in
            respect of products or services from you, including a Cardholder.
          </Typography>

          <Typography variant="body1">
            <strong>"Card"</strong> means any form of Credit Card or Debit Card,
            which may be used by a Cardholder to carry out a Transaction on a
            Cardholder’s account.
          </Typography>

          <Typography variant="body1">
            <strong>"Cardholder"</strong> means any person, including a Buyer,
            who is issued a Card and possesses and uses a Card, and where
            required on the Card, whose signature appears on the Card as an
            authorized user.
          </Typography>

          <Typography variant="body1">
            <strong>"Card Scheme"</strong> means Visa Inc., MasterCard
            Worldwide, and/or such comparable bodies which provide Cards and
            regulate Card acceptance as VCMTS may determine from time to time.
          </Typography>

          <Typography variant="body1">
            <strong>"Chargeback"</strong> means a Transaction which is
            successfully charged back on request of your Buyer or the Issuer
            pursuant to the relevant Payment Scheme Rules resulting in the
            cancellation of a Transaction in respect of which you have been paid
            or was due to be paid. If a Chargeback occurs for a Transaction in
            respect of which you have already received Payment of the related
            funds, this results in the unconditional obligation for you to
            immediately return the remitted funds to us.
          </Typography>

          <Typography variant="body1">
            <strong>"Chargeback and Assessment Costs"</strong> has the meaning
            given to that term in section 5.1 of this Agreement.
          </Typography>

          <Typography variant="body1">
            <strong>“Customer VCMTS Account”</strong> means a multi-currency
            account held by you with VCMTS, which may include a Jar, governed by
            your Customer Agreement with us.
          </Typography>

          <Typography variant="body1">
            <strong>"Customer Products/Services"</strong> means goods and/or
            services which you are selling on your URLs, and for which the
            Transactions are submitted for processing by us.
          </Typography>

          <Typography variant="body1">
            <strong>"Customer Service Fees" or "CS Fees"</strong> means the fees
            set out in the Pricing Page and section 6.1 of this Agreement.
          </Typography>

          <Typography variant="body1">
            <strong>"Data Controller"</strong> means the entity which alone or
            jointly with others determines the purposes and the means of the
            Processing of Personal Data.
          </Typography>

          <Typography variant="body1">
            <strong>"Data Processor"</strong> means the entity which Processes
            Personal Data on behalf of a Data Controller.
          </Typography>
          <Typography variant="body1">
            <strong>"Data Protection Laws and Regulations"</strong> means all
            privacy and data protection laws, including the UK GDPR and any
            applicable national implementing laws, regulations, and secondary
            legislation, applicable to the Processing of Personal Data under the
            Agreement.
          </Typography>

          <Typography variant="body1">
            <strong>"Data Receiver"</strong> means the party receiving the
            Personal Data from the Data Discloser.
          </Typography>

          <Typography variant="body1">
            <strong>"Data Subject"</strong> means Buyer, Cardholder, employee of
            Merchant, our employee, or other natural person whose Personal Data
            are processed in the context of this Agreement.
          </Typography>

          <Typography variant="body1">
            <strong>"Deductions"</strong> has the meaning given to that term in
            section 6.2 of this Agreement.
          </Typography>

          <Typography variant="body1">
            <strong>"EU"</strong> means the European Union.
          </Typography>

          <Typography variant="body1">
            <strong>"Force Majeure Event"</strong> has the meaning given to that
            term in section 17 of this Agreement.
          </Typography>

          <Typography variant="body1">
            <strong>“GDPR”</strong> means the EU General Data Protection
            Regulation 2016/679, as amended and replaced from time to time.
          </Typography>

          <Typography variant="body1">
            <strong>“Group Company”</strong> means for the purposes of this
            Agreement: (i) any direct or indirect holding company of a party to
            this Agreement and/or (ii) any direct or indirect subsidiary of the
            party or of any relevant holding company, including, where
            applicable, the party itself.
          </Typography>

          <Typography variant="body1">
            <strong>"Issuer"</strong> means an institution that issues Payment
            Methods to your Buyer and whose name appears on the Card or bank
            account statement as the Issuer or who enters into a contractual
            relationship with your Buyer with respect to the Payment Method.
          </Typography>

          <Typography variant="body1">
            <strong>"MATCH"</strong> means 'Member Alert to Control High Risk'
            database which is a database of terminated customers maintained by
            MasterCard International. It is referred to in MasterCard's Security
            Rules and Procedures as MATCH System.
          </Typography>

          <Typography variant="body1">
            <strong>"Payment"</strong> means an amount paid by us to you in
            respect of Settlement due to you in accordance with this Agreement.
          </Typography>

          <Typography variant="body1">
            <strong>"Payment Currency"</strong> means the currency in which the
            Transaction funds are remitted to you.
          </Typography>

          <Typography variant="body1">
            <strong>"Payment Date"</strong> means the Business Day (notified to
            you by us from time to time) on which Payment occurs.
          </Typography>

          <Typography variant="body1">
            <strong>"Payment Method"</strong> means a method of enabling you to
            accept payments by Buyers including Cards, online and offline bank
            Payment.
          </Typography>

          <Typography variant="body1">
            <strong>"Payment Scheme"</strong> means the party regulating and/or
            offering the relevant Payment Method. This specifically includes
            Card Schemes such as Visa Inc., MasterCard Worldwide.
          </Typography>

          <Typography variant="body1">
            <strong>"Payment Scheme Rules"</strong> means the collective set of
            by-laws, rules, regulations, operating regulations, procedures
            and/or waivers issued by the Payment Scheme, as may be amended or
            supplemented over time, and with which you must comply when using
            the relevant Payment Method. The Payment Scheme Rules specifically
            include the “Card Scheme Rules” which include but are not limited to
            Visa Inc. (referred to as 'Visa Core Rules and Visa Product and
            Service Rules' and available at URL
            https://www.visa.co.uk/support/consumer/visa-rules.html), MasterCard
            Worldwide (referred to as 'MasterCard Rules' and available at the
            URL
            https://www.mastercard.us/content/dam/public/mastercardcom/na/global-site/documents/mastercard-rules.pdf),
            Those rules that are not publicly available, shall be communicated
            to you, as per guidance and instruction of the relevant Payment
            Scheme.
          </Typography>

          <Typography variant="body1">
            <strong>"PCI DSS"</strong> stands for "Payment Card Industry Data
            Security Standards" and means the security standards for
            transmitting, processing or storing cardholder data and sensitive
            authentication data, as updated from time to time and published by
            the Payment Card Industry Security Standards Council at
            https://www.pcisecuritystandards.org.
          </Typography>

          <Typography variant="body1">
            <strong>"PCI SSC"</strong> means Payment Card Industry Security
            Standards Council.
          </Typography>

          <Typography variant="body1">
            <strong>"Personal Data"</strong> has the meaning given to it in
            GDPR, as amended from time to time, and is “any information relating
            to an identified or identifiable natural person (‘data subject’); an
            identifiable natural person is one who can be identified, directly
            or indirectly, in particular by reference to an identifier such as a
            name, an identification number, location data, an online identifier
            or to one or more factors specific to his physical, physiological,
            genetic, mental, economic, cultural or social identity of that
            natural person”. This includes but is not limited to personal and
            financial details of your Buyer, your employees, directors and
            shareholders (or you yourself if you are a sole trader).
          </Typography>

          <Typography variant="body1">
            <strong>"Personal Data Breach"</strong> means a breach of security
            leading to the accidental or unlawful destruction, loss, alteration,
            unauthorized disclosure of, or access to, Personal Data transmitted,
            stored or otherwise processed.
          </Typography>
          <Typography variant="body1">
            <strong>"Pricing Page"</strong> means the pricing information set
            out in this FAQ.
          </Typography>

          <Typography variant="body1">
            <strong>
              "Processing of Personal Data (or 'Process Personal Data')"
            </strong>{" "}
            means any operation or set of operations which is performed on
            Personal Data or on sets of Personal Data, whether or not by
            automated means, such as collection, recording, organization,
            structuring, storage, adaptation or alteration, retrieval,
            consultation, use, disclosure by transmission, dissemination or
            otherwise making available, alignment or combination, restriction,
            erasure or destruction.
          </Typography>

          <Typography variant="body1">
            <strong>"Recurring Transaction"</strong> means a repetitive periodic
            Transaction agreed in writing and in advance between you and your
            Buyer for which you debit Buyer’s Debit/Credit Card or bank account,
            such as subscriptions or instalments.
          </Typography>

          <Typography variant="body1">
            <strong>"Refund"</strong> means a full or partial reversal of a
            particular Transaction, whereby the funds are reimbursed to your
            Buyer on your initiative or request.
          </Typography>

          <Typography variant="body1">
            <strong>"Regulatory Authority"</strong> means in respect of a party
            any competent governmental or regulatory authority, law enforcement
            department or agency, court of law, or other law, rule or
            regulation-making body having jurisdiction over that party and/or to
            which that party submits or is subject, in any relevant territory
            and including any replacement or successor of any of the foregoing.
          </Typography>

          <Typography variant="body1">
            <strong>"Reported Fraud"</strong> means the Issuer fraud advices
            reported to us by the Payment Scheme (e.g. TC40 reported by Visa
            Inc.).
          </Typography>

          <Typography variant="body1">
            <strong>"Reported Fraud-to-sales Ratios"</strong> means value of the
            Reported Fraud divided by the gross sales volume, and calculated on
            a monthly basis for a one-month period.
          </Typography>

          <Typography variant="body1">
            <strong>"Request Money Service(s)"</strong> as defined in section 3
            of this Agreement, which for the avoidance of doubt, is a “Service”
            as defined under Additional Documents.
          </Typography>

          <Typography variant="body1">
            <strong>"Reserve Account"</strong> means the balance funded by your
            Rolling Reserve.
          </Typography>

          <Typography variant="body1">
            <strong>"Rolling Reserve(s)"</strong> means an amount withheld by us
            from the funds received from the Payment Scheme as a security for
            Chargebacks, Assessments or Refunds (or any other amounts mentioned
            in the Deductions), and Customer Service Fees due to each one of us,
            and held on the Reserve Account.
          </Typography>

          <Typography variant="body1">
            <strong>"Rolling Reserves Rate"</strong> means a percentage of the
            daily gross sales volume processed by us, which shall be subtracted
            from the daily settlements received by us from the Payment Schemes
            and held in the Reserve Account. The applicable Rolling Reserves
            Rate shall be set out in the Pricing Page.
          </Typography>

          <Typography variant="body1">
            <strong>"Schedule(s)"</strong> means the schedule(s) to this
            Agreement.
          </Typography>

          <Typography variant="body1">
            <strong>"Sensitive Authentication Data"</strong> means
            security-related information (including but not limited to card
            validation codes/values, full track data (from the magnetic stripe
            or equivalent on a chip), PINs, and PIN blocks) used to authenticate
            cardholders and/or authorize Transactions.
          </Typography>

          <Typography variant="body1">
            <strong>"Software"</strong> means the collective set of programs and
            data developed and/or operated by us and provided to you so that you
            can receive the Request Money Services.
          </Typography>

          <Typography variant="body1">
            <strong>"Sub-Processor"</strong> means the entity engaged by the
            Data Processor or any further sub-contractor to Process Personal
            Data on behalf of and under the instructions of the Data Controller.
          </Typography>

          <Typography variant="body1">
            <strong>"Taxes"</strong> has the meaning given to that term in
            section 6.8 of this Agreement.
          </Typography>

          <Typography variant="body1">
            <strong>"Tokenisation Service"</strong> is the process of taking
            sensitive information (e.g. credit card number) and substituting it
            with a non-sensitive string of characters, usually referred to
            within the payments industry as ‘Card Token’.
          </Typography>

          <Typography variant="body1">
            <strong>"Traffic"</strong> means the profile of Customer
            Transactions, including the volume of Transactions, average ticket
            size, spread across Payment Methods, geographical spread and other
            relevant information.
          </Typography>

          <Typography variant="body1">
            <strong>"Transaction"</strong> means a request to us by you to
            process the payment request and/or consent of your Buyer to his/her
            payment service provider in order for you to receive the payment for
            goods and/or services purchased by your Buyer.
          </Typography>

          <Typography variant="body1">
            <strong>"Transaction/Authorization Currency"</strong> means the
            currency in which the Transaction is originally offered to your
            Buyer and submitted to the Payment Schemes.
          </Typography>

          <Typography variant="body1">
            <strong>“You” and “Your”</strong> refers to the person(s) or legal
            entity that has accepted this Agreement and that is using the
            Request Money Services or otherwise exercising rights under this
            Agreement.
          </Typography>

          <Typography variant="body1">
            <strong>“URLs”</strong> means the address of any websites owned and
            operated by you where you accept, or state that you will accept,
            payments by Payment Methods supported by us in relation to products
            and/or services which are purchased by your Buyer from your
            websites.
          </Typography>
          <Typography variant="body1">
            <strong>“Use Policy”</strong> means the “prohibited and restricted
            services and products” list as set out at
            https://www.adyen.com/legal/list-restricted-prohibited or as
            provided to you in writing (as amended from time to time).
          </Typography>

          <Typography variant="body1">
            <strong>“VMAS (“Visa Merchant Alert Service”) database”</strong> is
            a database of terminated merchants maintained by Visa.
          </Typography>

          <Typography variant="body1">
            <strong>“Website”</strong> means any VCMTS webpage, including but
            not limited to www.victorum-capital.com.com, where we provide the
            Request Money Services to you.
          </Typography>

          <Typography variant="body1">
            <strong>“VCMTS”</strong> means VicPay Services Inc., a limited
            liability company registered in the United Kingdom, registered under
            SC745907 having its registered office at 2/3 48 West George Street,
            Glasgow, G2 1BP, United Kingdom.
          </Typography>

          <Typography variant="body1">
            <strong>“VCMTS Gateway”</strong> means a payment gateway used in the
            provision of the “payment gateway service”, described in section 3.2
            of this Agreement.
          </Typography>

          <Typography variant="body1">
            <strong>“VCMTS”</strong> means a Group Company in the Victorum
            Capital Group.
          </Typography>

          <Typography variant="body1">
            <strong>3. Our services</strong>
          </Typography>

          <Typography variant="body1">
            The services provided by us, through our Processor where applicable,
            include payment acquiring services, payment gateway services, and
            other business services (“Request Money Transfer Services”). The
            scope of our Request Money Transfer Services is further clarified
            below in sections 3.1 (Payment acquiring services) and 3.2 (Payment
            gateway services). In accepting the Request Money Transfer Services
            provided by us, you commit to comply with the terms of this
            Agreement, Payment Scheme Rules, and the Applicable Law relating to
            the supply of goods/services by you.
          </Typography>

          <Typography variant="body1">
            <strong>3.1 Payment acquiring services</strong> As part of our
            payment acquiring service, we will provide you with the following
            services:
          </Typography>

          <Typography variant="body1">
            (a) Enabling Transactions to be routed to the relevant Payment
            Scheme;
          </Typography>

          <Typography variant="body1">
            (b) Providing information and messaging about the status of the
            Transaction on the Payment Scheme network, including authorization
            status (e.g. authorized, declined, etc.), clearing and settlement
            advisement, Chargeback dispute status, etc.;
          </Typography>

          <Typography variant="body1">
            (c) Reconciling of: (i) the information routed to the relevant
            Payment Scheme with the information processed by that Payment
            Scheme, and communicated to us; (ii) the records and accounts of
            your entitlement to relevant funds with the records and accounts of
            the amounts safeguarded; and (iii) our internal records and accounts
            with those of our banking partners safeguarding the relevant funds;
          </Typography>

          <Typography variant="body1">
            (d) Handling of the funds related to refunded or disputed
            Transactions, and supporting the representment of disputed
            Transactions upon your request;
          </Typography>

          <Typography variant="body1">
            (e) Currency conversion services, where applicable;
          </Typography>

          <Typography variant="body1">
            (f) Paying out the funds to the Customer VCMTS Account as per
            section 3.4 of the Agreement; and/or
          </Typography>

          <Typography variant="body1">
            (g) Providing statements to you relating to the Payments, Customer
            Service Fees and Deductions.
          </Typography>

          <Typography variant="body1">
            <strong>3.2 Payment Gateway Service</strong> As part of our payment
            gateway service, we will provide you with services such as the
            following:
          </Typography>

          <Typography variant="body1">
            (a) Enabling the secure entry and processing of payment transaction
            data on the Processor Platform and the secure submission of this
            data to Processor by the Adyen Checkout API integrations (as further
            described on www.adyen.com/legal/services-description);
          </Typography>

          <Typography variant="body1">
            (b) Information reporting including information related to
            Transactions, Customer Service Fees, Chargebacks, Refunds, disputes,
            etc.;
          </Typography>

          <Typography variant="body1">(c) Tokenisation Service;</Typography>

          <Typography variant="body1">
            (d) a dispute resolution interface, where applicable; and/or
          </Typography>

          <Typography variant="body1">
            (e) a payment instruction interface.
          </Typography>

          <Typography variant="body1">
            <strong>3.3 Our acceptance of you as a user</strong>
          </Typography>

          <Typography variant="body1">
            (a) Our acceptance of you as a user of the Request Money Transfer
            Services and the relevant Payment Methods is strictly personal and
            limited to your use to receive payment for your own products and
            services.
          </Typography>

          <Typography variant="body1">
            (b) You shall only use the Request Money Transfer Services for your
            own use to receive payment for your own products and services. If we
            suspect that you are using the Request Money Transfer Services
            otherwise or in breach of this Agreement, we have the right to
            suspend the Request Money Transfer Services immediately. See further
            section 4 below “Your Obligations”.
          </Typography>

          <Typography variant="body1">
            (c) Support for each Payment Method is subject to acceptance by the
            relevant Payment Scheme used or the Processor, which the Payment
            Scheme or the Processor may withhold or withdraw at their discretion
            at any time. Certain Payment Schemes may require you to enter into a
            direct agreement with the Payment Scheme or Processor before you may
            use the relevant Payment Scheme.
          </Typography>

          <Typography variant="body1">
            <strong>3.4 Payment to you</strong>
          </Typography>

          <Typography variant="body1">
            (a) Subject to section 3.4(b) below and in respect of validly
            submitted Transactions in accordance with section 4.4 below, we will
            initiate or procure the initiation of a Payment to your Customer
            VCMTS Account in the timeframe as specified in Schedule 1. For the
            avoidance of doubt, we are not responsible for the time that it
            takes any third parties, including your buyers’ bank or your bank,
            to make those funds available to you, or for any errors in the
            payment card details or bank account details provided to us.
          </Typography>

          <Typography variant="body1">
            (b) We are only obliged to pay to you funds related to the
            Transactions for which we have received settlement(s) from the
            Payment Scheme, and this is net of the Customer Service Fees and any
            applicable Deductions. It is your responsibility to evaluate if the
            conditions of Transfer (which are set by us, taking into
            consideration the frequency of the Payment Schemes settlements to
            us) are acceptable to you before entering into this Agreement. You
            agree that any overpaid and/or unduly received funds (e.g. related
            to the Transactions for which we have not received the settlements
            from the Payment Scheme, or overpaid due to IT infrastructure
            breakdown) shall be, upon our written notice to you of such
            overpayment, and at our option: (i) deducted by us from the next
            Payment(s), and/or (ii) refunded immediately by you, and/or (iii)
            deducted from the Reserve Account or your Customer VCMTS Account.
          </Typography>
          <Typography variant="body1">
            (c) At our discretion, all Payments shall be subject to any Payment
            thresholds (to be pre-agreed between the parties) that have been
            set.
          </Typography>

          <Typography variant="body1">
            (d) You understand and agree that, to the extent permissible under
            Applicable Law, we will not compensate you for late or
            non-performance, or for any insolvency or bankruptcy of the Payment
            Scheme causing Payment or non-Payment.
          </Typography>

          <Typography variant="body1">
            (e) Notwithstanding anything to the contrary in the Agreement, we
            reserve the right to withhold and/or defer Payments related to
            Transactions if they are submitted for authorization, but reasonably
            suspected by us to be fraudulent purchases, or related to illegal
            activities or likely to become subject to a Chargeback and/or
            Payment Scheme investigation, until satisfactory completion of an
            investigation, that of the relevant Payment Scheme or that of a
            third party nominated by us hereby. You shall give your full
            co-operation to any such investigation. No interest or other
            compensation will be payable in respect of amounts withheld or
            deferred in accordance with this section 3.4(e) of this Agreement.
          </Typography>

          <Typography variant="body1">
            <strong>3.5 Payment methods and currencies supported</strong>
          </Typography>

          <Typography variant="body1">
            (a) We will support the Payment Methods and currencies set out in
            this FAQ.
          </Typography>

          <Typography variant="body1">
            (b) In our discretion, or as required by the Payment Scheme
            Processor, Applicable Law or any regulatory authority, we may
            discontinue one or more of the Payment Methods or make future
            support conditional on your acceptance of additional conditions or
            fees.
          </Typography>

          <Typography variant="body1">
            <strong>4. YOUR OBLIGATIONS</strong>
          </Typography>

          <Typography variant="body1">
            <strong>
              4.1 You must provide us with your Customer Information, keep us
              informed of data changes, and provide any additional financial
              information as may be required.
            </strong>
          </Typography>

          <Typography variant="body1">
            (a) In order to comply with the Applicable Law, including but not
            limited to anti-terrorism, financial services, anti-tax evasion and
            anti-money laundering laws and regulations imposing Customer Due
            Diligence (“CDD”) requirements, as well as with the Payment Scheme’s
            rules, you must, before entering into the Agreement, and immediately
            upon request, provide us with the following information as well as
            any other information or documentation that would be required under
            Applicable Law: about yourself, and in particular about your
            financial status, solvency and liquidity, your activities, your
            payment acquiring and processing arrangements, your shareholders,
            your ultimate beneficial owners and / or shareholders, the Customer
            Products/Services, your registered office address, as well as any
            and all regulatory licences and registrations required to sell
            Customer Products/Services (herein defined as the “Customer
            Information”). You warrant unconditionally that all Customer
            Information you provide to us is correct and up to date and
            undertake to provide us with at least five (5) Business Days prior
            written notice of any material change of the Customer Information,
            including in particular (but not limited to) any change of your
            directors, shareholders and/or ultimate beneficial owners.
          </Typography>

          <Typography variant="body1">
            (b) In addition to Customer Information specified in section 4.1(a)
            above, we may also from time to time request that you provide
            additional financial and other information such as: (i) the current
            actual or expected delivery dates for processed Transactions; (ii)
            estimates for the average time between Transaction authentication
            and the related delivery date; (iii) your ability to provide the
            Customer Products/Services, and/or (iv) your financial status,
            solvency and liquidity. You must provide such requested information
            within five (5) Business Days of our written request.
          </Typography>

          <Typography variant="body1">
            (c) If you fail to provide the data requested in accordance with
            sub-sections 4.1(a) and 4.1(b) above, we reserve the right to
            suspend the provision of the Request Money Transfer Services until
            such data is provided in the form and substance satisfactory to us
            in line with Applicable Law.
          </Typography>

          <Typography variant="body1">
            (d) You agree that we may run further checks on your identity,
            creditworthiness, and background by contacting and consulting
            relevant registries and governmental authorities or any other
            relevant sources.
          </Typography>

          <Typography variant="body1">
            (e) You hereby authorize us to share Customer Information, or any
            other information we receive from you, with the relevant Payment
            Scheme in order to obtain permission for providing access to the
            Payment Methods, or for any ongoing monitoring-related purpose.
          </Typography>

          <Typography variant="body1">
            <strong>
              4.2 Obligations and restrictions related to Customer
              Products/Services
            </strong>
          </Typography>

          <Typography variant="body1">
            (a) You agree to the following obligations and restrictions:
          </Typography>

          <Typography variant="body1">
            (i) You will only use the Request Money Transfer Services for
            payment of those Customer Products/Services which you registered for
            when entering into the Agreement with us, and which are reflected in
            the Customer Information;
          </Typography>

          <Typography variant="body1">
            (ii) You may not use the Request Money Transfer Services to
            facilitate the payment for products or services sold on URLs other
            than the one(s) set forth in the information provided to us. You may
            not resell the Service to the third parties whether in its entirety
            or partially;
          </Typography>

          <Typography variant="body1">
            (iii) You will only use the Request Money Transfer Services for
            payments made online on the URL(s) set forth in the information
            provided to us. You will not use the Request Money Transfer Services
            in relation to any other forms of payment including, without
            limitation, mail order or telephone order payments.
          </Typography>

          <Typography variant="body1">
            (iv) Prior to submitting Transactions in relation to the products
            and services which materially differ in value and/or type from those
            set out in the Customer Information, and could as such impact either
            of the following: risk and fraud profile of the Transaction,
            compliance with the Payment Schemes Rules, and/or the Applicable
            Law, you must update your Customer Information in writing;
          </Typography>

          <Typography variant="body1">
            (v) You are and remain solely responsible to ensure that the
            Customer Products/Services sold are compliant with the Payment
            Scheme Rules, and the Applicable Law in your country and the
            countries your customers are based in; and
          </Typography>

          <Typography variant="body1">
            (vi) You shall not use the Request Money Transfer Services for the
            payment of the products and services which are listed in the Use
            Policy and our Acceptable Use Policy. This list may be updated from
            time to time, at our discretion, to ensure compliance with
            Applicable Laws, compliance with the Payment Scheme Rules and
            prevent high levels of Chargebacks, reputational risks and/or reduce
            our exposure to potentially fraudulent or illegal transactions.
            Where a published change affects a material portion of the Customer
            Products/Services, you may terminate the Agreement by providing us
            with written notice to us in accordance with section 10 of this
            Agreement.
          </Typography>

          <Typography variant="body1">
            (b) Our acceptance of you as our customer should not be interpreted
            as advice or an opinion as to the legality of the Customer
            Products/Services, and/or of your intended use of the Request Money
            Transfer Services. The Request Money Transfer Services may not be
            used (and Transactions may not be submitted for processing) for
            prepaying the Customer Products/Services for which the delivery date
            (i.e. date on which a complete Customer Product/Service is delivered
            to your Buyer who paid for the Customer Product/Service) is in part,
            or in whole, more than six (6) months after the date the Transaction
            is submitted for processing, unless we provided explicit written
            consent stating otherwise.
          </Typography>

          <Typography variant="body1">
            <strong>4.3 Obligations relating to your Website</strong>
          </Typography>

          <Typography variant="body1">
            (a) You are required to provide precise URL(s) and may amend
            existing URLs/add new URLs from time to time, subject to our prior
            written approval, in which case the same obligations as apply to
            existing URLs shall apply with respect to these additional URLs.
          </Typography>

          <Typography variant="body1">
            (b) You agree to include the following information clearly and
            conspicuously on your websites: (i) the Payment Scheme’s brand mark
            in full colour to indicate that Payment Scheme’s acceptance; (ii) a
            complete description of the Customer Products/Services offered by
            you and the applicable terms and conditions; the terms and
            conditions should be displayed to your Buyer during the order
            process; (iii) complete description of the refund, return and
            cancellation policies (if you have a limited refund policy, it must
            be clearly communicated to your Buyer prior to the purchase); (iv) a
            “click to accept” button, or other acknowledgment, evidencing that
            your Buyer has accepted the return/refund policy; (v) your customer
            service contact information including email address or telephone
            number; (vi) your EU permanent business address; (vii) the
            Transaction currency in both words and symbols; (viii) all
            applicable export restrictions; (ix) your delivery policy, and
            special delivery policy if any; (x) your EU country which must be
            provided to your Buyer during the payment process; (xi) your
            Consumer data privacy policy; and (xii) your security capabilities
            and policy covering transmission of payment card details when the
            Payment Method used is a credit/debit card, or any other sensitive
            Buyer’s details in relation to other Payment Methods. Additional
            information may be required, depending on the Payment Method used by
            you.
          </Typography>
          <Typography variant="body1">
            <strong>
              4.4 Obligations relating to the submission of transactions and
              refunds for processing, integration, and related consents
            </strong>
          </Typography>

          <Typography variant="body1">
            (a) You shall submit all data required as set out in Schedule 2 for
            a Transaction and/or Refund and ensure that all Transactions and/or
            Refunds are validly presented in accordance with the Payment Scheme
            Rules.
          </Typography>

          <Typography variant="body1">
            (b) If you fail to comply with this obligation for each Transaction
            and/or Refund, we reserve the right to immediately suspend the
            Transaction and/or Refund processing. We may revise the required
            data needed to process Transactions and Refunds from time to time by
            giving notice to you by email.
          </Typography>

          <Typography variant="body1">
            (c) Where we execute a Transaction or a Refund in accordance with
            the data provided by you, the Transaction or Refund will be deemed
            to have been correctly executed by us and/or the Payment Scheme
            involved.
          </Typography>

          <Typography variant="body1">
            (d) Where the data provided by you to us is incorrect, we are not
            liable for the non-execution or defective execution of the
            Transaction and/or Refund. We will, however, endeavor to recover the
            funds involved in such a Transaction and/or Refund and reserve the
            right to claim from you the related costs and losses to us.
          </Typography>

          <Typography variant="body1">
            (e) You agree to share with us the email address of your Buyers, in
            compliance with the applicable data protection laws, and procure and
            provide consent where required for us to contact your Buyers
            directly for the purposes of:
          </Typography>

          <Typography variant="body1">
            (i) sending the receipt to confirm the status of Transaction and/or
            Refund, and/or to confirm the billing descriptor to appear on your
            Buyer’s debit/card and bank statement;
          </Typography>

          <Typography variant="body1">
            (ii) requesting any additional information to confirm the
            Transaction and/or Refund, and/or;
          </Typography>

          <Typography variant="body1">
            (iii) performing risk and/or fraud assessments and/or investigation,
            and of compliance with the anti-money laundering and
            counter-terrorism financing laws and regulations.
          </Typography>

          <Typography variant="body1">
            (f) For all Transactions processed through the Payment Gateway 3-D
            Secure authentication will be offered as an option, provided it is
            supported by the Payment Method used and implemented by the current
            Software of 3-D Secure authentication may be required and imposed by
            us should a Transaction be suspected to be fraudulent. If you opted
            out of 3-D Secure authentication for Transactions processed by us,
            where such 3-D Secure authentication is available, you understand
            that a higher pricing may be applied, and other restrictions may be
            applied by the Payment Schemes. We shall not be liable for any
            delays in the authentication response time or other malfunctioning
            of 3-D Secure authentication, where such malfunctioning is caused by
            third parties such as, but not limited to, the issuer banks and
            their 3-D Secure providers.
          </Typography>

          <Typography variant="body1">
            (g) You must fully comply with the PCI DSS, as amended from time to
            time, and any other applicable standards, rules, or recommendations
            of the PCI SSC and must complete a self-assessment questionnaire –{" "}
            <a href="https://www.pcisecuritystandards.org/document_library?category=saqs#results">
              https://www.pcisecuritystandards.org/document_library?category=saqs#results
            </a>{" "}
            at least once annually. You will provide evidence of your compliance
            with PCI DSS prior to the commencement of this Agreement and
            thereafter at least once annually, and at any time promptly
            following our request.
          </Typography>

          <Typography variant="body1">
            <strong>4.5 Obligations related to recurring transactions</strong>
          </Typography>

          <Typography variant="body1">
            (a) For each Recurring Transaction, you are required to obtain a
            prior express Recurring Transaction consent from your Buyer
            (including specifically the Cardholder), at the point of checkout or
            sale, for the Customer Products/Services sold using the Request
            Money Services. You must provide your Buyer with the following
            information when obtaining consent: (i) the amount of the Recurring
            Transaction; (ii) whether the amount is fixed or variable; (iii) the
            date of the Recurring Transaction; (iv) whether the date is fixed or
            variable; and (v) an agreed method of communication for all future
            correspondence with your Buyer.
          </Typography>

          <Typography variant="body1">
            (b) You must also, using the agreed method of communication, provide
            your Buyer with a confirmation that a Recurring Transaction
            agreement has been entered. This confirmation must be provided
            within two (2) Business Days of entering that Recurring Transaction
            agreement, which must be separate from the sales agreement.
          </Typography>

          <Typography variant="body1">
            (c) You must notify your Buyer that this Recurring Transaction
            consent is subject to cancellation by your Buyer at any time, and
            should not debit or attempt to debit your Buyer’s Card, Buyer’s bank
            account or any other Payment Method account after being notified of
            the cancellation of the Recurring Transaction consent.
          </Typography>

          <Typography variant="body1">
            (d) You shall notify your Buyer in writing of the amount to be
            debited and the due date of the debit at least fourteen (14) days
            prior to (i) the first debit, and (ii) each subsequent debit (unless
            the Recurring Transaction consent sets out the amounts payable and
            the due dates and none of these have changed or other objective
            criteria are agreed with your Buyer for calculating the due dates).
          </Typography>

          <Typography variant="body1">
            (e) You shall retain the Recurring Transaction consent for the
            duration of the recurring transactions, and for a period of eighteen
            (18) months after the final payment that is made pursuant to it. You
            shall produce the Recurring Transaction consent to us on the first
            demand.
          </Typography>

          <Typography variant="body1">
            (f) You shall not submit for processing by us an existing Recurring
            Transaction without our prior written consent, shall provide us with
            a correct Recurring Transaction indicator, the frequency of the
            Recurring Transactions, and the period over which the Recurring
            Transactions will take place, and shall comply with any and all
            additional Payment Scheme’s requirements and/or recommendations
            relating specifically to Recurring Transactions.
          </Typography>

          <Typography variant="body1">
            <strong>
              4.6 Obligations to comply with the Payment Scheme Rules (and in
              particular the Card Scheme Rules)
            </strong>
          </Typography>

          <Typography variant="body1">
            (a) You shall comply with the applicable Payment Scheme Rules and
            are strongly advised to regularly review the then-current Payment
            Scheme Rules (and in particular Card Scheme Rules), and the
            Applicable Law as applicable to your Customer Products/Services and
            business practices to ensure compliance with the same. For
            violations of certain key requirements, certain Payment Schemes such
            as Card Schemes can levy significant fines.
          </Typography>

          <Typography variant="body1">
            (b) Where we become aware of and/or receive any notice of a
            potential exposure to a fine related to your behavior, you will on
            first request provide all reasonable co-operation to help
            investigate the relevant circumstances and remedy the relevant
            violation, notwithstanding all other rights and remedies we might
            have in such a situation as per this Agreement. Where possible we
            will share with you the relevant feedback regarding the potential
            fine by the Payment Scheme.
          </Typography>

          <Typography variant="body1">
            (c) If fines are applied for your violations, these may be invoiced
            by the Payment Scheme to us as their contracting party. You shall
            fully indemnify and hold us harmless from any fines applied by the
            Payment Scheme as a result of your breach of the terms of the
            Agreement, the Payment Scheme Rules.
          </Typography>

          <Typography variant="body1">
            (d) If your annual processing volume reaches or exceeds an amount of
            USD 1,000,000 for Visa or Mastercard (or such other applicable
            amount set by the Payment Scheme from time to time), you are
            required by the applicable Payment Scheme Rules to enter into a
            direct contractual relationship with Processor.
          </Typography>

          <Typography variant="body1">
            (e) For the avoidance of doubt and subject to you meeting the
            threshold described in 5.6(d) above if applicable, you agree that
            you will contract with the Processor on the Processor’s standard
            terms and conditions, as set out on{" "}
            <a href="https://www.adyen.com/legal/terms-and-conditions">
              https://www.adyen.com/legal/terms-and-conditions
            </a>
            .
          </Typography>
          <Typography variant="body1">
            <strong>
              4.7 Obligation to implement Risk Management Controls and Records
              Retention
            </strong>
          </Typography>

          <Typography variant="body1">
            (a) You agree that you have implemented sufficient risk management
            controls (including but not limited to the restrictions to certain
            geographies required by us to manage fraud or credit risk exposure).
            You also agree to maintain the proper facilities, equipment,
            inventory and records.
          </Typography>

          <Typography variant="body1">
            (b) In addition to complying with all records retention provisions
            under the Applicable Law, and subject to the requirements of PCI
            DSS, you must maintain a copy of all electronic and other records
            related to the Transaction ordering and delivery of the Customer
            Products/Services for a period of eighteen (18) months. The copy of
            the records shall include, but not be limited to shipping details
            (if relevant), invoices for the delivered Customer Products/Services
            and all contacts with your Buyer. In case of any investigation by us
            or the Payment Scheme with respect to Chargebacks, suspected fraud
            or other requests for information, you must fully co-operate in the
            auditing of such records.
          </Typography>
          <Typography variant="body1">
            <strong>
              4.8 Obligations related to the security of Buyer’s payment
              instrument
            </strong>
          </Typography>

          <Typography variant="body1">
            (a) You guarantee not to copy, capture or intercept Buyer’s payment
            instrument related information such as card number and Sensitive
            Authentication Data that are entered on the VCMTS Payment Gateway.
            Strict rules on the security of payment instruments are imposed by
            the Payment Schemes (and specifically Card Schemes) and PCI SSC to
            protect Buyers against misuse of their payment instruments and are
            strictly enforced by the Card Schemes. A violation of these rules
            can lead to the application of fines by the Card Schemes. If we have
            any reasons to believe that you are copying, capturing or
            intercepting the above-mentioned information, in violation of the
            Payment Scheme Rules and PCI SSC’s rules, recommendations and
            standards (including specifically PCI DSS), we reserve the right to
            inspect your locations and to suspend processing of Transactions
            and/or Refunds and to suspend Payments. You shall fully indemnify
            and hold us and our affiliates harmless from any and all losses,
            claims (including applied fines by the Payment Scheme or claims by
            the Processor), costs or damages incurred as a result of your breach
            of this obligation.
          </Typography>

          <Typography variant="body1">
            (b) You shall immediately notify us if any Cardholder data,
            Sensitive Authentication Data or similar Buyer’s payment instrument
            related information is breached or compromised. You must strictly
            comply, with respect to the security of your Buyer’s payment
            instrument, with the terms of this Agreement, and with all the
            requirements of the Applicable Law, of the Payment Schemes (e.g.
            MasterCard’s Site Data Protection Program), as well as with the
            guidance, requirements and standards of PCI SCC such as PCI DSS.
          </Typography>

          <Typography variant="body1">
            <strong>
              4.9 Obligation to notify of errors, misappropriation and/or
              unauthorized use of the VCMTS Gateway
            </strong>
          </Typography>

          <Typography variant="body1">
            (a) You must notify us in writing immediately if you believe there
            has been or will be an error, or misappropriation or unauthorized
            use of the Payment Gateway. You must give us all the information in
            your possession as to the circumstances of any errors and/or
            misappropriation or unauthorized use of the Payment Gateway and take
            all reasonable steps to assist us in any investigation we may
            conduct.
          </Typography>

          <Typography variant="body1">
            (b) We might provide third parties with the information we consider
            relevant in such circumstances. In order to prevent misappropriation
            or unauthorized use of the Payment Gateway, you must keep safe any
            and all password(s) that are necessary to access or use the Payment
            Gateway and/or any confidential instruction provided by us for the
            integration of the Service.
          </Typography>

          <Typography variant="body1">
            (c) You shall also inform us promptly and no later than within
            thirteen (13) months after you become aware of any unauthorized or
            incorrectly executed Transaction and/or Refund. Where legally
            required, we shall refund you immediately for such unauthorized or
            incorrectly executed Refunds.
          </Typography>

          <Typography variant="body1">
            (d) You shall inform us promptly, and no later than sixty (60) days
            after you become aware of any non-execution or defective execution
            of the Refund, and we will make immediate efforts to trace the
            Refund and notify you of the outcome. Where we are liable for
            non-execution or defective execution, and where legally required, we
            will immediately refund the amount of the non-executed or defective
            Refund.
          </Typography>
          <Typography variant="body1">
            <strong>4.10 Obligations relating to Audit</strong>
          </Typography>

          <Typography variant="body1">
            (a) If we believe that a security breach or compromise of any
            Buyer’s data has occurred, we may require you to have a third party
            auditor that is approved by us to conduct a security audit of your
            systems and facilities and issue a report to be provided to us
            and/or the Payment Schemes, and you shall be required to remedy any
            defects identified within a reasonable period or a timeframe given
            by the Payment Schemes. Save for when an audit (i) is required by
            regulatory or Card Scheme requirements, (ii) is triggered by a
            material breach by you, or (iii) determines non-compliance by you
            with this Agreement, we shall reimburse you for your reasonable
            costs of providing information, access and assistance with respect
            to such audit.
          </Typography>

          <Typography variant="body1">
            (b) In addition to the above, you agree to allow us, subject to a
            thirty (30) days written notice from us, to inspect your locations
            to confirm that you are in compliance with the terms of this
            Agreement, and are maintaining the proper facilities, equipment,
            inventory, records, licences and permits where necessary to conduct
            your business. Our representatives may, during normal working hours,
            inspect, audit and make copies of your books, accounts, records, and
            files pertaining to any Transaction processed under this Agreement.
          </Typography>

          <Typography variant="body1">
            <strong>5. Chargeback, assessment and refunds</strong>
          </Typography>

          <Typography variant="body1">
            <strong>5.1 Chargeback and assessment liability</strong>
          </Typography>

          <Typography variant="body1">
            (a) You agree to be held responsible and liable for: (i) any and all
            Chargebacks; and (ii) any assessment, fines, fees, charges or
            expenses of any nature which a Payment Scheme and in particular Card
            Schemes, Issuers, levy on us at any time directly or indirectly in
            relation to any aspect of our relationship with you (all together
            defined herein as an “Assessment”). Each Chargeback and Assessment
            represents a debt immediately due and payable to us.
          </Typography>

          <Typography variant="body1">
            (b) Any Chargebacks for which you are required to reimburse us shall
            correspond to the whole or part of the processing value of the
            original Transaction.
          </Typography>

          <Typography variant="body1">
            (c) Where a Chargeback or an Assessment occurs, we shall immediately
            be entitled to debit the Reserve Account, Additional Collateral,
            and/or make a deduction from any amount received by us from the
            Payment Schemes in accordance with this Agreement (‘settled
            amount’), and/or invoice you to recover: (i) the full amount of the
            relevant Chargeback or Assessment; and (ii) any other costs,
            expenses, including without limitation legal fees and other legal
            expenses, liabilities or fines which we may incur as a result of or
            in connection with such Chargeback or Assessment (“Chargeback and
            Assessment Costs”).
          </Typography>

          <Typography variant="body1">
            (d) Where the full amount of any Chargeback, Assessment and/or any
            Chargeback and Assessment Costs is not debited by us from the
            Reserve Account, Additional Collateral, and/or deducted from any
            settled amount and/or invoiced, then we shall be entitled to
            otherwise recover from you by any means the full amount of such
            Chargeback, Assessment or ‘Chargeback and Assessment Cost’.
          </Typography>

          <Typography variant="body1">
            (e) We shall not be obliged to investigate the validity of any
            Chargeback or Assessment by any Issuer or Payment Scheme, whose
            decision or determination shall be final and binding in respect of
            any Chargeback or Assessment.
          </Typography>
          <Typography variant="body1">
            <strong>5.2 Chargeback and assessment period</strong>
          </Typography>

          <Typography variant="body1">
            As Chargebacks and Assessments may arise a considerable period after
            the date of the relevant Transaction, you acknowledge and agree
            that, notwithstanding any termination of the Agreement for any
            reason, we shall remain entitled to recover Chargebacks, Assessments
            and ‘Chargeback and Assessments Costs’ from you (and, where
            relevant, from any person who has provided us with a guarantee or
            security relating to your obligations under the Agreement) in
            respect of all Chargebacks, Assessments and ‘Chargeback and
            Assessment Costs’ that occur in relation to Transactions effected
            during the term of the Agreement.
          </Typography>
          <Typography variant="body1">
            <strong>5.3 Refunds</strong>
          </Typography>

          <Typography variant="body1">
            (a) As per section 4.3(b) of this Agreement, you must clearly and
            accurately disclose to your Buyer your refund, return and
            cancellation policy, and if you have a limited refund policy it must
            be clearly communicated to your Buyer prior to the purchase. Your
            refund, return and cancellation policy must comply with, and must be
            presented in accordance with, Applicable Law.
          </Typography>

          <Typography variant="body1">
            (b) You shall not: (i) give cash Refunds to a Buyer where the
            payment is made with a Card, other than when required by the
            Applicable Law, or (ii) accept cash or other compensation for making
            a Refund to a Card.
          </Typography>

          <Typography variant="body1">
            (c) We reserve the right to refuse to process or execute a Refund if
            it is prohibited by the Applicable Law or does not meet the
            conditions of this Agreement (subject to any mandatory rules under
            Applicable Law). We also reserve the right to suspend your ‘refund
            functionality’ provided by us if you are placed under fraud
            investigation, are deemed by us to be generating an excessive amount
            of Chargebacks, and/or for any other risk related reasons in our
            sole discretion.
          </Typography>

          <Typography variant="body1">
            (d) We will notify you of such refusal or suspension, subject to any
            restrictions by the Applicable Law, indicating the reasons for such
            refusal or suspension and the procedure for correcting factual
            mistakes that led to it where possible. Any payment order that we
            refuse will be deemed not to have been received for the purposes of
            (i) any execution times, and in particular those set out in
            paragraph (b) of this section, and of (ii) liability for
            non-execution or defective execution.
          </Typography>
          <Typography variant="body1">
            <strong>6. Payments and fees</strong>
          </Typography>

          <Typography variant="body1">
            <strong>6.1 Customer Service Fees</strong>
          </Typography>

          <Typography variant="body1">
            (a) You shall pay to us all applicable fees set out in the Pricing
            Page or as notified to you in accordance with the notification
            procedure set out in section 14.8 of this Agreement in which case
            your use of the Request Money Transfer Services after such
            notification shall constitute acceptance of the terms in the Pricing
            Page.
          </Typography>

          <Typography variant="body1">
            (b) Furthermore, the average Reported Fraud-to-Sales Ratios shall
            not exceed one (1) % in any period of three (3) consecutive months.
            Should the average Reported Fraud-to-Sales Ratios exceed one (1) %
            in any period of three (3) consecutive months, in addition to the
            fees under (a) and (b) of this section 6.1, we may at our sole
            discretion, apply the Excessive Fraud Fee, and such fee shall be
            payable on a rolling basis in the month that follows the relevant
            three (3) month period in respect of which it was accrued.
          </Typography>

          <Typography variant="body1">
            (c) All the fees referred to in (a) of this section 6.1 are together
            and collectively referred to in this Agreement as the “Customer
            Service Fees “ or “CS Fees”.
          </Typography>

          <Typography variant="body1">
            <strong>6.2 Deductions</strong>
          </Typography>

          <Typography variant="body1">
            You shall be liable for all deductions applicable under this
            Agreement (“Deductions”). Deductions include: (i) Chargebacks, (ii)
            Assessments, (iii) Refunds, and (iv) amounts needed to comply with
            the Rolling Reserves Rate. For the avoidance of doubt, sections
            relating to “Reversals and Chargebacks” of the Customer Agreement
            shall also apply to you.
          </Typography>

          <Typography variant="body1">
            <strong>
              6.3 Collection of CS Fees and/or Deductions, and related
              Statements
            </strong>
          </Typography>

          <Typography variant="body1">
            (a) We have the right to collect the CS Fees and/or Deductions, at
            our option, by:
          </Typography>

          <ul>
            <li>
              <Typography variant="body1">
                debiting such amounts from the received funds held by us,
                without notice or demand, before Payments;
              </Typography>
            </li>
            <li>
              <Typography variant="body1">
                debiting such amount from the Reserve Account and/or Additional
                Collateral Account, without notice or demand;
              </Typography>
            </li>
            <li>
              <Typography variant="body1">
                deducting such amounts from your Customer VCMTS Account
                (including Jars and/or any invested balances);
              </Typography>
            </li>
            <li>
              <Typography variant="body1">
                debiting such amounts from your designated bank account by
                direct debit;
              </Typography>
            </li>
            <li>
              <Typography variant="body1">
                deducting from any fees that are due to you from us;
              </Typography>
            </li>
            <li>
              <Typography variant="body1">
                invoicing the amount of the CS Fees and/or Deductions to you;
                and/or
              </Typography>
            </li>
            <li>
              <Typography variant="body1">
                taking any lawful collection measures, in court or otherwise to
                collect such sums.
              </Typography>
            </li>
          </ul>

          <Typography variant="body1">
            (b) You are required to maintain with your bank a direct debit
            instruction (or equivalent) to authorize us to directly debit from
            your designated bank account any sums due to us and payable by you
            under or in connection with this Agreement. You will ensure that
            your designated bank account and your Customer VCMTS Account shall
            at all times have a credit balance sufficient to meet any sums due
            and payable to us under or in connection with this Agreement.
          </Typography>

          <Typography variant="body1">
            (c) In the event you are liable for any amounts owed to us, we may
            immediately remove such amounts from your Customer VCMTS Account. If
            there are insufficient funds in your Customer VCMTS Account and/or
            your designated bank account to cover your liability, we reserve the
            right to collect your debt to us by using any other funds you hold
            with us, including if applicable, your VCMTS investment account,
            invested balance or invested Jar, and otherwise you agree to
            reimburse us through other means. We may also recover amounts you
            owe us through any other legal means, including, without limitation,
            through the use of a debt collection agency.
          </Typography>

          <Typography variant="body1">
            (d) Where the CS Fees and Deductions are collected by invoicing the
            amount to you, you must pay sums due under any invoice under this
            Agreement within fourteen (14) days of the date of the receipt of
            such invoice or such time period as applies to direct debit.
          </Typography>

          <Typography variant="body1">
            (e) Interest shall accrue on any unpaid invoice owned by you to us
            at the statutory interest rate of at least eight (8) % per annum
            above the European Central Bank’s rate on marginal lending facility.
            Such interest shall accrue on a daily basis from the due date until
            actual payment of the overdue amount, whether before or after
            judgment. You shall pay the interest together with the overdue
            amount.
          </Typography>

          <Typography variant="body1">
            (f) We shall provide you with access to electronic statements and/or
            invoices as applicable. Printed statements can be requested by
            contacting us (additional reasonable costs may be charged by us).
          </Typography>

          <Typography variant="body1">
            <strong>6.4 Adjustments to CS Fees</strong>
          </Typography>

          <Typography variant="body1">
            (a) We shall have the right to change the CS Fees at any time upon
            two (2) months’ notice to you. You may, however, during the two (2)
            months’ notice period, terminate the Agreement with us by providing
            a written notice.
          </Typography>

          <Typography variant="body1">
            (b) You acknowledge that the CS Fees are assessed by us based on
            Traffic characteristics provided by you including but not limited to
            the average ticket size, and the volume of Transactions.
          </Typography>

          <Typography variant="body1">
            (c) If the actual Traffic differs materially from the figures
            provided by you, we have the right to proportionally adjust our
            fees, based on the actual then-current Traffic characteristics upon
            two (2) months’ notice to you. You may, however, during the two (2)
            months’ notice period, terminate the Agreement with us by providing
            a written notice.
          </Typography>
          <Typography variant="body1">
            <strong>6.5 Reserve Account</strong>
          </Typography>

          <Typography variant="body1">
            (a) You agree that we are entitled to (at our sole discretion) to
            subtract a percentage of the daily gross sales volume processed by
            us from daily settlements received by us from the Payment Schemes
            (“Rolling Reserves”), and such funds shall be retained by us in
            order to be used to cover for unpaid CS Fees, Deductions such as
            Chargebacks, Assessments, and Refunds, or your other payment
            obligations under this Agreement.
          </Typography>

          <Typography variant="body1">
            (b) Rolling Reserves may be capped or converted to a fixed reserve
            amount after a set period of time, to be held in the Reserve
            Account, as determined by us. The difference between the held and
            released Rolling Reserves will be communicated to you in the
            statements under section ‘Reserve Account’. The Reserve Account is a
            separate element of the Customer account, which serves the reserve
            functionality. The Rolling Reserves Rate shall be set out in the
            Pricing Page. However, we, at our sole discretion, may change the
            Rolling Reserves Rate and/or the terms of the Reserve Account based
            on your payment processing history immediately upon a written
            notification to you. You agree that you are not entitled to any
            interest on the funds credited in the Reserve Account, that you have
            no right to direct that account, and that you cannot and will not
            assign or grant any security interest in those funds or that
            account, or allow any encumbrance upon the funds contained on that
            account.
          </Typography>

          <Typography variant="body1">
            (c) Funds in the Reserve Account will remain in the Reserve Account
            for twenty-six (26) weeks following the date of termination set out
            in the termination letter of this Agreement or your last Transaction
            submitted to us, provided, however, that you will remain liable to
            us for all liabilities occurring beyond such twenty-six (26) weeks
            period.
          </Typography>

          <Typography variant="body1">
            (d) In case of your insolvency, the funds held in the Reserve
            Account will be available for the purposes of the insolvency
            administration only after twenty-six (26) weeks, and subject to any
            additional liability you owe to us under this Agreement occurring
            between your insolvency event and the expiry of the period of
            twenty-six (26) weeks.
          </Typography>
          <Typography variant="body1">
            <strong>6.6 Additional Collateral</strong>
          </Typography>

          <Typography variant="body1">
            (a) In addition to the Reserve Account, we may, at our sole
            discretion, request you to provide funds to us as non-interest
            bearing ‘Additional Collateral’ as a security to guarantee payment
            of any and all debt or liability from you to us and/or the Payment
            Schemes such as, without limitation, unpaid CS Fees, Deductions such
            as Chargebacks, Assessments, and Refunds, or other potential debt or
            liability, including those arising out of or in connection with any
            Payment services. We will fund the Additional Collateral, replenish
            and maintain it at the designated level by deducting the required
            amount from Payments or any other funds due to you.
          </Typography>

          <Typography variant="body1">
            (b) We may at our sole discretion at any time and without prior
            notice draw and receive amounts from the Additional Collateral as
            required to cover any amounts owed to us, the Payment Schemes which
            cannot be deducted from your Payments because of lack of funds or
            otherwise. We may subsequently replenish the Additional Collateral
            from Payments and funds due to you under this Agreement or require
            that you make a payment to us for the amount required to replenish
            the Additional Collateral.
          </Typography>

          <Typography variant="body1">
            (c) Unless otherwise advised by us, the Additional Collateral will
            be held and maintained for a minimum of six (6) months from the
            termination of this Agreement. If after such six (6) month period
            there is still a risk of more Deductions such as Chargebacks,
            Assessments, and Refunds, or other potential debt or liability, then
            we will have the right to withhold the funds until such risk is
            eliminated. Upon expiration of this six (6) month period (or longer,
            as the case may be), any remaining amount of Additional Collateral
            will be Transferred to you. We will inform you of any charges
            debited to the Additional Collateral during this period.
          </Typography>

          <Typography variant="body1">
            (d) We may change the Additional Collateral upon notice and at our
            sole discretion depending on refund ratios, fraud ratios, Chargeback
            ratios and other risk considerations.
          </Typography>

          <Typography variant="body1">
            (e) You expressly acknowledge and agree:
          </Typography>

          <Typography variant="body1" component="ul">
            <li>
              (i) that the Additional Collateral is separate to and does not
              form part of the funds subject to our safeguarding obligations;
            </li>
            <li>
              (ii) to any charge or debit made by us against the Additional
              Collateral;
            </li>
            <li>
              (iii) that you are not entitled to any interest on the Additional
              Collateral;
            </li>
            <li>
              (iv) that you have no right to direct the Additional Collateral;
              and
            </li>
            <li>
              (v) that you cannot and will not assign or grant any security
              interest in the Additional Collateral, or allow any encumbrance
              upon those funds.
            </li>
          </Typography>

          <Typography variant="body1">
            (f) We may, without notice to you, apply deposits in the Reserve
            Account and/or to the Additional Collateral against any outstanding
            amounts owed to us under this Agreement, or any other future
            agreement between you and any of us. All our rights with respect to
            the Reserve and Additional Collateral shall survive the termination
            of this Agreement.
          </Typography>

          <Typography variant="body1">
            <strong>6.7 Set-off</strong>
          </Typography>

          <Typography variant="body1">
            You hereby authorizes us to set-off by whatever means the whole or
            any part of your liabilities to us under this Agreement (or any
            other contract with us) against any funds credited to or owing to
            you under this Agreement (or any other contract with us) and/or in
            your Customer VCMTS Account. We may exercise this right of set-off
            at any time, without notice to you, whether either liability is
            present or future, liquidated or unliquidated, and whether or not
            either liability arises under this Agreement. If the liabilities to
            be set off are expressed in different currencies, we may convert
            either liability at a market rate of exchange for the purpose of
            set-off. In the event such set-off does not fully reimburse us for
            the amount owed, you shall immediately pay us such amount. You shall
            hold harmless any financial institution that follows our request
            pursuant to this section. Any exercise of our right under this
            provision is without prejudice and in addition to any rights or
            remedies available to us under this Agreement or otherwise.
          </Typography>
          <Typography variant="body1">
            <strong>6.8 Taxes</strong>
          </Typography>

          <Typography variant="body1">
            (a) Unless stated otherwise, all our fees, charges and other
            payments to be made are exclusive of VAT, and any other applicable
            taxes or levies under any Applicable Law (“Taxes”), for which you
            will be separately liable.
          </Typography>

          <Typography variant="body1">
            (b) It is your responsibility to determine what, if any, Taxes apply
            to the sale of your products and services and/or the payments you
            receive in connection with your use of the Service. It is solely
            your responsibility to assess, collect, report, or remit the correct
            tax to the relevant tax authority. We are not obligated to, nor will
            we, determine whether Taxes apply, and will not calculate, collect
            or remit any Taxes to any tax authority arising from any
            Transaction, and this remains strictly your liability.
          </Typography>

          <Typography variant="body1">
            <strong>7. API and other Software</strong>
          </Typography>

          <Typography variant="body1">
            <strong>7.1 Software general</strong>
          </Typography>

          <Typography variant="body1">
            (a) We provide the Software (and, where applicable, any other
            relevant software) that enables you to use the Service. Subject to
            section 7.2 below, we reserve the right to change or amend these and
            the interface at any time, to provide you with a new version, and/or
            to change the functionalities and characteristics, and to require
            you to install or update any and all software in order to continue
            using the Service.
          </Typography>

          <Typography variant="body1">
            (b) The property rights in the API, the Software and any and all
            other materials, and all other intellectual property rights related
            to the Request Money Transfer Services are owned by us and our
            licensors. The Agreement does not transfer any intellectual property
            rights with respect thereto and only provides you with a limited,
            non-exclusive and non-transferable licence (without the right to
            sub-licence) to use the Software and all other materials made
            available by us solely for the purpose of using the Request Money
            Transfer Services in accordance with this Agreement and the
            applicable usage instructions communicated to you by email. You
            shall not prepare any derivative work based on VCMTS Group Company’s
            intellectual property, nor shall you translate, reverse engineer,
            decompile or disassemble VCMTS Group’s intellectual property.
          </Typography>
          <Typography variant="body1">
            <strong>7.2 Changes to Software</strong>
          </Typography>

          <Typography variant="body1">
            (a) Changes will be implemented by us which may materially reduce
            the functionality of the Request Money Transfer Services: (i) the
            need to follow generally accepted changes in the payment industry
            standards, (ii) changes in the Applicable Law, Payment Scheme Rules,
            (iii) the need for increased security due to security risks
            identified by us, or (iv) other reasonable grounds which warrant the
            reduction of functionality. If you are significantly impacted by a
            material reduction of functionality due to a change in our Software
            and, where applicable, any other relevant software, you may
            terminate the Agreement by giving written notice to us within thirty
            (30) days after we announced the change.
          </Typography>

          <Typography variant="body1">
            (b) We endeavor to provide advance notice of changes to the API and
            applicable software. Shorter notice periods may have to be made to
            comply with the Applicable Law, changes in requirements from the
            Payment Scheme, the need for increased security due to security
            risks identified by us, or in case of any updates to software.
          </Typography>

          <Typography variant="body1">
            <strong>8. Data protection and privacy</strong>
          </Typography>

          <Typography variant="body1">
            <strong>
              8.1 For the purposes of this Agreement, both we and you shall act
              as an independent data controller in relation to the Processing of
              Personal Data that we each process in the course of the
              performance of this Agreement and both of us will comply with our
              respective obligations under applicable Data Protection Law in
              relation to our respective Processing Purposes.
            </strong>
          </Typography>

          <Typography variant="body1">
            <strong>
              8.2 Taking into account the state of technical development and the
              nature of Processing, both Parties will implement appropriate
              technical, security and organisational measures against
              unauthorised or unlawful Processing of Personal Data and against
              accidental loss or destruction of or damage to Personal Data in
              compliance with the GDPR and all Data Protection Laws and
              Regulations including the measures contemplated by Article 32 of
              GDPR.
            </strong>
          </Typography>

          <Typography variant="body1">
            <strong>8.3 Each party represents and warrants that:</strong>
          </Typography>

          <Typography variant="body1">
            (a) it will comply with its respective obligations under applicable
            Data Protection Laws and Regulations;
          </Typography>

          <Typography variant="body1">
            (b) it has an appropriate lawful basis under Data Protection Laws
            and Regulations; and
          </Typography>

          <Typography variant="body1">
            (c) in each case, with respect to the sharing of Personal Data with
            the other party, it will act as contemplated by this Agreement in
            section 8.4 below.
          </Typography>

          <Typography variant="body1">
            <strong>8.4 Each Party agrees to:</strong>
          </Typography>

          <Typography variant="body1">
            (a) process the Personal Data of the other party only for its own
            Processing Purposes as contemplated by this Agreement;
          </Typography>

          <Typography variant="body1">
            (b) process Personal Data in accordance with Applicable Law
          </Typography>

          <Typography variant="body1">
            (c) promptly provide the other party such reasonable cooperation,
            information and assistance as required to allow the other party to
            comply with its obligations under Data Protection Laws and
            Regulations. Each party shall meet their own costs in providing the
            same save to the extent that it is required pursuant to a breach of
            this Agreement by the other; and
          </Typography>

          <Typography variant="body1">
            (d) notify the other party of any potential or actual unauthorised
            disclosure, deletion, or third-party access to the Shared Personal
            Data as soon as possible and, in any event, within one (1) Business
            Day of identification of any potential or actual loss to enable the
            Parties to consider what action is required to resolve the issue.
          </Typography>
          <Typography variant="body1">
            <strong>8.5 The Parties:</strong>
          </Typography>

          <Typography variant="body1">
            (a) must not knowingly perform their obligations under this section
            8 in such a way as to cause the other party to breach any of its
            obligations under Data Protection Laws and Regulations; and
          </Typography>

          <Typography variant="body1">
            (b) accept full responsibility and liability for the acts or
            omissions of their processors and contractors in respect of the
            provisions of this Agreement.
          </Typography>

          <Typography variant="body1">
            <strong>
              8.6 In the event that either party agrees to act as Data Processor
              on behalf of the other in relation to the Personal Data, the
              Parties shall enter into a data processing agreement in accordance
              with the applicable Data Protection Laws Regulations prior to
              commencing such processing.
            </strong>
          </Typography>

          <Typography variant="body1">
            <strong>
              8.7 Both us and you will ensure that persons authorised to process
              the Personal Data have committed themselves to confidentiality or
              are under an appropriate statutory obligation of confidentiality
              and comply with all applicable Data Protection Laws and
              Regulations.
            </strong>
          </Typography>

          <Typography variant="body1">
            <strong>
              8.8 You warrant that you have all necessary consents and
              permissions in relation to the Personal Data concerned for the
              purposes of processing Personal Data under this Agreement.
            </strong>
          </Typography>

          <Typography variant="body1">
            <strong>
              8.9 You agree to indemnify us, keep us indemnified for and defend
              us against, at your own expense, all reasonable costs, claims,
              damages or expenses incurred by us, for which you may become
              liable due to any failure by you or your employees or agents to
              comply with any of your obligations set out in this section 8.
            </strong>
          </Typography>

          <Typography variant="body1">
            <strong>
              8.10 Except where expressly permitted by law, the Data Receiver
              shall not retain, or Process Personal Data shared under this
              Agreement for longer than is necessary for the purposes
              contemplated by this Agreement.
            </strong>
          </Typography>

          <Typography variant="body1">
            <strong>
              8.11 If at any time during the term of this Agreement Data
              Protection Laws and Regulations change in a way that this section
              8 is no longer adequate for the purpose of governing lawful data
              processing, the Parties shall negotiate in good faith to review
              this section in the light of the new legislation.
            </strong>
          </Typography>
          <Typography variant="body1">
            <strong>9. Security</strong>
          </Typography>

          <Typography variant="body1">
            (a) We may at any time require you to procure that a person (either
            legal or physical or both) or persons satisfactory to us, provide us
            with a guarantee and/or indemnity in respect of your obligations
            (including contingent or potential obligations) from time to time
            under the Agreement. In such case, a separate document from this
            Agreement must be executed by you.
          </Typography>

          <Typography variant="body1">
            (b) We may at any time require you to grant to us, or procure the
            granting to us of, security other than guarantees or indemnities in
            such form, including the requirement to put funds into a bank
            account which we may specify (including a trust or other account
            which we may establish for such purpose) and over such assets (and
            free of other security interests or subject only to such other
            security interests and other rights as we shall permit) to secure to
            our satisfaction the performance of your obligations (including
            contingent or potential obligations) from time to time under this
            Agreement.
          </Typography>

          <Typography variant="body1">
            (c) We may exercise our rights under this section 9 either to
            require additional security or to require the replacement of a
            previous security which has been withdrawn or which we, for any
            reason, require to be replaced.
          </Typography>

          <Typography variant="body1">
            (d) Without prejudice to any other provision of the Agreement, your
            failure to comply with any requirement made under this section 9
            strictly in accordance with the relevant time limits shall
            constitute a material breach of this Agreement allowing for
            immediate termination without notice.
          </Typography>

          <Typography variant="body1">
            <strong>10. Term and termination</strong>
          </Typography>

          <Typography variant="body1">
            <strong>10.1 Term and voluntary termination</strong>
          </Typography>

          <Typography variant="body1">
            This Agreement is effective upon the date you accept these terms and
            conditions, by electronic means or otherwise, and except where
            explicitly agreed otherwise in the Agreement, the Agreement is
            entered into for an indefinite period until it is terminated: (i) by
            us by giving two (2) months’ prior written notice to you; or (ii) by
            you by giving one (1) month’s prior written notice to us.
          </Typography>
          <Typography variant="body1">
            <strong>10.2 Immediate termination or suspension</strong>
          </Typography>

          <Typography variant="body1">
            (a) We have the right to terminate the Agreement and/or to suspend
            the provision of any Service to you immediately upon written notice
            in part, or in whole, if:
          </Typography>

          <ul>
            <li>
              (i) The provision of Customer Products/Services is reasonably
              suspected by us to be in breach of the Applicable Law (including
              but not limited to anti-money laundering and terrorist financing
              legislations) or of the Payment Schemes Rules; or
            </li>
            <li>
              (ii) You have been listed on any sanctions list, including but not
              limited to the UK HM Treasury’s financial sanction lists, Office
              of Foreign Asset Control’s SDN list, World-Check, or a Payment
              Scheme’s fraud and risk databases such as MATCH, VMAS or
              equivalent; or
            </li>
            <li>
              (iii) You infringe or are suspected of infringing intellectual
              property rights, copyrightable works, patented inventions,
              trademarks and trade secrets, or are suspected of selling
              counterfeit and/or knockoff products/items/goods; or
            </li>
            <li>
              (iv) You materially change the type of the Customer
              Products/Services without obtaining our prior written permission
              to use the Request Money Transfer Services for the new or changed
              types of Customer Services/Products (including where the merchant
              category code provided by you is unsupported, incorrect, or does
              not represent your Customer Products/Services), or it is
              discovered by us that you provided substantially misleading and/or
              false information about the Customer Products/Services as part of
              the Information; or
            </li>
            <li>
              (v) You materially breach any of the terms of the Agreement, the
              Payment Scheme Rules and/or Applicable Law in the context of using
              the Request Money Services; or
            </li>
            <li>
              (vi) The Payment Scheme or the Processor demands us to terminate
              or suspend providing the Request Money Services to you; or
            </li>
            <li>
              (vii) The ratio of Chargebacks to Transactions exceeds 75,000 USD
              or 0.9%, the Reported Fraud-to-Sales Ratios exceed 0.9%, or we
              otherwise consider, at our sole and absolute discretion, that the
              total value of the Refunds, Chargebacks, and/or Reported Fraud,
              and/or the number of declined authorization requests and/or the
              number of Buyer complaints is excessive (also known as Excessive
              Activity); or
            </li>
            <li>
              (viii) We consider that there are clear indications that you are,
              or are likely to become (I) insolvent or subject to any insolvency
              proceedings (whether voluntarily or involuntarily) and/or (ii)
              unable to provide a material part of the Customer
              Products/Services; or
            </li>
            <li>
              (ix) You refuse to provide security requested in accordance with
              section 10 of this Agreement, and the withdrawal, removal,
              termination, or unenforceability of any security in relation to
              which we rely upon; or
            </li>
            <li>
              (x) You grant to a third party any security or charge over all or
              a significant proportion of your assets; or
            </li>
            <li>
              (xi) The change of control of you, or a sale or other disposal of
              any substantial division or part of your business, that we
              consider at our sole discretion would adversely affect us or our
              ability to comply with the Applicable Law; or
            </li>
            <li>
              (xii) You fail or do not satisfy any checks required by us
              including but not limited to any verification checks or checks on
              your identity, creditworthiness, and background; or
            </li>
            <li>
              (xiii) Your business or the URL(s) on which you sell your Customer
              Products/Services to your Buyers are no longer active, available
              or meet any of our criteria and requirements (as applicable); or
            </li>
            <li>
              (xiv) You undertake or have undertaken activities (such as scams
              or other fraudulent activities) which in our reasonable opinion
              are detrimental to our brand, image or reputation, or that of any
              Payment Schemes; or
            </li>
            <li>
              (xv) You act in a manner that, in our reasonable opinion, may or
              does give rise to increased risk of losses or liabilities to any
              of us.
            </li>
          </ul>

          <Typography variant="body1">
            (b) When this Agreement has been immediately terminated under
            section 10.2(a), we reserve the right to report you to the Payment
            Schemes for entering into MATCH, VMAS or equivalent databases of
            terminated customers, in accordance with the applicable Payment
            Scheme Rules.
          </Typography>

          <Typography variant="body1">
            <strong>11. Changes to this Agreement</strong>
          </Typography>

          <Typography variant="body1">
            (a) We may revise the Agreement from time to time by giving one (1)
            month’s written notice to you via email, post, or by posting such
            alterations on our website (“Alteration”). If you do not notify us
            of your objections to the Alteration within one (1) month after
            receiving written notice of the Alteration, you will be deemed to
            have accepted the Alteration.
          </Typography>

          <Typography variant="body1">
            (b) If you notify us of his objection to the Alteration within a
            period of two (2) months of our written notice of the Alteration,
            and we still do not withdraw the Alteration, you may terminate the
            Agreement immediately by giving us a written notice after the entry
            into force of the Alteration.
          </Typography>

          <Typography variant="body1">
            (c) However, if you do not object to the Alteration by terminating
            this Agreement no later than five (5) Business Days after the entry
            into force of the Alteration, you will be deemed to have accepted
            it. Your use of the Request Money Services after we provide any such
            notice constitutes your acceptance of the terms of the modified
            Agreement. You still remain liable to us after the termination of
            this Agreement for any liability you might have incurred and are
            responsible for prior to terminating this Agreement.
          </Typography>

          <Typography variant="body1">
            (d) You are not entitled to object to and shall not have the rights
            set out in this section for any change, which we implement in order
            to comply with Applicable Law or requirements by the relevant
            Payment Schemes or Processor. For such imposed changes shorter
            notice periods may be imposed.
          </Typography>

          <Typography variant="body1">
            (e) Notwithstanding anything in this Agreement, changes to this
            Agreement that do not require one (1) month’s notification are those
            which are: (i) more favorable to you; (ii) required by law; (iii)
            related to the addition of a new service, extra functionality to the
            existing Service; or (iv) changes that neither reduce your rights
            nor increase your responsibilities, will come into effect
            immediately if they are stated in the change notice. Changes to
            exchange rates shall come into effect immediately without notice and
            you shall not have the right to object to such a change.
          </Typography>
          <Typography variant="body1">
            <strong>12. Representations and warranties</strong>
          </Typography>

          <Typography variant="body1">
            You represent and warrant to us continuously and every time that you
            use the Request Money Services that:
          </Typography>

          <ul>
            <li>
              (a) you shall not use the Request Money Transfer Services in
              connection with any illegal or fraudulent business activities;
            </li>
            <li>
              (b) you are a validly organized and validly existing company in
              good standing under the laws where your principal office is
              located and shall inform us immediately should this change;
            </li>
            <li>
              (c) you have obtained and shall maintain any and all licenses,
              permits, and registrations required under the Applicable Law to
              conduct your business in all jurisdictions where you sell the
              Customer Products/Services and shall inform us immediately should
              this change;
            </li>
            <li>
              (d) you have the power to execute, deliver and perform this
              Agreement, and this Agreement is duly authorized, and will not
              violate any provisions of law, or conflict with any other
              agreement to which such party is subject;
            </li>
            <li>
              (e) to the best of your knowledge, there is no action, suit or
              proceeding at law or in equity now pending or threatened by or
              against or affecting you which would substantially impair your
              right to carry on your business as now conducted or adversely
              affect your financial condition or operations;
            </li>
            <li>
              (f) you have never experienced excessive Chargebacks, committed
              fraud, nor have you ever been terminated by an acquirer or asked
              to terminate your agreement with an acquirer or subject to any
              Payment Scheme’s monitoring programme(s);
            </li>
            <li>
              (g) your directors, shareholders, and ultimate beneficial owners
              have never been convicted of a criminal offense and are not
              currently subject to any investigation relating to any criminal
              offense, and you undertake to inform us immediately should this
              change;
            </li>
            <li>
              (h) your directors, shareholders, and ultimate beneficial owners
              are not listed on any sanctions list, including but not limited to
              the EU sanctions list, and U.S. Department of Treasury’s Office of
              Foreign Asset Control SDN list, and you undertake to inform us
              immediately should this change.
            </li>
          </ul>

          <Typography variant="body1">
            <strong>
              13. Indemnity, liability and limitation of liability
            </strong>
          </Typography>

          <Typography variant="body1">
            In this section 13, we use the term “VCMTS” or “us” to include
            Vic Pay Inc., and our affiliates, and each of
            their respective directors, officers, employees, agents, joint
            venturers, service providers and suppliers. Our affiliates include
            each entity that we control, we are controlled by or we are under
            common control with.
          </Typography>

          <Typography variant="body1">
            <strong>13.1 Indemnity</strong>
          </Typography>

          <Typography variant="body1">
            (a) Except to the extent prohibited under Applicable Laws, you shall
            indemnify for and hold each one of us harmless from any losses,
            damages, liabilities, judgments, awards, costs and/or expenses
            incurred by us or any VCMTS Group Company, or any liabilities,
            damages, judgments, awards, losses, costs and expenses or claim
            (including reasonable legal fees) brought against us by any third
            party (including any Regulatory Authority, the Processor and/or any
            Payment Scheme) arising out of or in connection with: (i) your,
            and/or any of your employees’, agents’ or authorized third parties’
            breach or alleged breach of this Agreement, (ii) your and/or any of
            your employees’, agents’ or authorized third parties’ breach or
            alleged breach of any Additional Documents, the Applicable Law
            and/or of the Payment Scheme Rules, (iii) your and/or any of your
            Buyers’, employees, agents’ or authorized third parties’ use of, or
            activities in connection with, the Request Money Services, and/ or
            (iv) any acts or omissions of you, your employees, your agents
            and/or authorized third parties.
          </Typography>

          <Typography variant="body1">
            (b) You shall also indemnify for and hold each of us harmless from
            any and all losses related to Chargebacks, Assessments and
            ‘Chargeback and Assessment Costs’, third party IP right
            infringements, and any other losses, claims, actions, injuries,
            liabilities, fines, penalties or expenses (including reasonable
            legal costs) arising out of or in connection with this Agreement.
          </Typography>
          <Typography variant="body1">
            <strong>
              13.2 Exclusion of liability for payment schemes, Processor and
              Issuers
            </strong>
          </Typography>

          <Typography variant="body1">
            (a) We will not be liable for acts or omissions of third parties. In
            no event shall VCMTS be liable for acts or omissions of the Payment
            Schemes, the Processor, and card issuers, or for events or
            activities originating outside our system (such as infrastructure
            failure, internet disturbances or malfunctioning in third party
            systems).
          </Typography>

          <Typography variant="body1">
            (b) We shall not be liable to you or any third party for any
            liquidated, indirect, consequential, exemplary, or incidental
            damages (including damages for loss of business profits, business
            interruption, loss of business information and the like) arising out
            of this Agreement.
          </Typography>

          <Typography variant="body1">
            <strong>13.3 Limitation of liability</strong>
          </Typography>

          <Typography variant="body1">
            (a) Subject to section 4.9 of this Agreement, and to the fullest
            extent permitted by the Applicable Law, our total liability or the
            total liability of the VCMTS and VCMTS Group Companies (and our and
            their respective employees, directors, agents and representatives)
            arising out of or in connection with this Agreement, whether in
            contract or in tort or other legal theory, shall not exceed the
            total amount of the Customer Service Fees (net of the applicable
            Interchange and Payment Scheme fees) in connection with your use of
            the Service during the twelve (12) months period immediately
            preceding the event giving rise to the claim for liability.
          </Typography>

          <Typography variant="body1">
            (b) In no event shall VCMTS be liable for lost profits or for any
            indirect, incidental, consequential, special, exemplary or punitive
            damages of any kind, under any contract, tort (including
            negligence), strict liability or other theory, including damages for
            loss of profits, use or data, loss of other intangibles, loss of
            business, loss of security of any information or other materials
            (including unauthorized interception by third parties of any
            information or other materials), even if advised in advance of the
            possibility of such damages or losses, however arising, including
            negligence, unless and to the extent prohibited by law. Our
            liability to you or any third parties in any circumstance is limited
            to the actual amount of direct damages.
          </Typography>

          <Typography variant="body1">
            (c) In addition, to the extent permitted by Applicable Law, VCMTS is
            not liable, and you agree not to hold VCMTS responsible, for any
            damages or losses (including, but not limited to, loss of money,
            goodwill, or reputation, profits, or other intangible losses or any
            special, indirect, or consequential damages) resulting directly or
            indirectly from: (1) your use of, or your inability to use, our
            websites, API, software, systems (including any networks and servers
            used to provide any of the Request Money Services) operated by us or
            on our behalf, or any of the Request Money Transfer Services; (2)
            delays or disruptions in our Website software, API, systems
            (including any networks and servers used to provide any of the
            Request Money Transfer Services) operated by us or on our behalf and
            any of the Request Money Transfer Services; (3) viruses or other
            malicious software obtained by accessing our websites, API,
            software, systems (including any networks and servers used to
            provide any of the Services) operated by us or on our behalf or any
            of the Request Money Transfer Services or any website or service
            linked to our websites, software or any of the Request Money
            Transfer Services; (4) glitches, bugs, errors, or inaccuracies of
            any kind in our websites, software, systems (including any networks
            and servers used to provide any of the Services) operated by us or
            on our behalf or any of the Services or in the information and
            graphics obtained from them; (5) the content, actions, or inactions
            of third parties; (6) a suspension or other action taken with
            respect to your Customer VCMTS Account; (7) your need to modify your
            practices, content, or behaviour, or your loss of or inability to do
            business, as a result of changes to this Agreement, any Additional
            Document or any other VCMTS policy; (8) the content, actions or
            inactions of any of your buyers.
          </Typography>
          <Typography variant="body1">
            <strong>13.4 Release</strong>
          </Typography>

          <Typography variant="body1">
            (a) If you have a dispute with your Buyer, any other Customer VCMTS
            Account holder or any third party from using the Request Money
            Transfer Services, you release VCMTS from any and all claims,
            demands and damages (actual and consequential) of every kind and
            nature, known and unknown, arising out of or in any way connected
            with such disputes. This means that you must use caution when
            dealing with third parties using our Request Money Transfer Services
            or your Customer VCMTS Account.
          </Typography>

          <Typography variant="body1">
            (b) In entering into this release you expressly waive any
            protections (whether statutory or otherwise) that would otherwise
            limit the coverage of this release to include only those claims
            which you may know or suspect to exist in your favour at the time of
            agreeing to this release.
          </Typography>

          <Typography variant="body1">
            <strong>13.5 Disclaimer of Warranty</strong>
          </Typography>

          <Typography variant="body1">
            The Request Money Transfer Services are provided “As-Is” “Where Is”
            and “Where Available” and without any representation or warranty,
            whether express, implied or statutory. VCMTS specifically disclaims
            any implied warranties of title, merchantability, fitness for a
            particular purpose and non-infringement. We disclaim all warranties
            with respect to the Request Money Transfer Services to the fullest
            extent permissible under applicable law, including the warranties of
            merchantability, fitness for a particular purpose, non-infringement
            and title.
          </Typography>

          <Typography variant="body1">
            <strong>13.6 Availability of Request Money Services</strong>
          </Typography>

          <Typography variant="body1">
            We will try to make sure our Request Money Transfer Services are
            available to you when you need them. However, we do not guarantee
            that our Request Money Transfer Services will always be available or
            be uninterrupted. We may suspend, withdraw, discontinue or change
            all or any part of our Request Money Transfer Service without
            notice. We will not be liable to you if for any reason our Request
            Money Transfer Services are unavailable at any time or for any
            period.
          </Typography>

          <Typography variant="body1">
            <strong>14. General Provisions</strong>
          </Typography>

          <Typography variant="body1">
            <strong>14.1 Security Threats</strong>
          </Typography>

          <Typography variant="body1">
            We may suspend your Customer VCMTS Account or restrict its
            functionality, at any time, if we have reasonable concerns about:
          </Typography>

          <Typography variant="body1">
            (a) the security of your Customer VCMTS Account or your profile;
          </Typography>

          <Typography variant="body1">
            (b) suspected unauthorised or fraudulent use of your Customer VCMTS
            Account or our Services; or
          </Typography>

          <Typography variant="body1">
            (c) suspected violations of this Agreement or the Additional
            Documents.
          </Typography>

          <Typography variant="body1">
            We will give you notice of any suspension or restriction and the
            reasons for such suspension or restriction as soon as we can, either
            before the suspension or restriction is put in place, or soon
            thereafter, unless notifying you would be unlawful or compromise our
            reasonable security measures.
          </Typography>
          <Typography variant="body1">
            <strong>14.2 Authorized Representative</strong>
          </Typography>

          <Typography variant="body1">
            You and your Authorized Representative individually affirm to us
            that your Authorized Representative is authorized on your behalf to
            provide any information required in order for us to provide the
            Request Money Transfer Services, to bind you to this Agreement, and
            to make any amendments to this Agreement in accordance with its
            terms, via any other method required by us. We may require you or
            your Authorized Representative to provide additional information or
            documentation demonstrating your Authorized Representative’s
            authority. Without our express written consent, neither you nor your
            Authorized Representative may register or attempt to register for a
            VCMTS profile on behalf of a user we previously terminated from use
            of the Request Money Transfer Services.
          </Typography>

          <Typography variant="body1">
            <strong>14.3 Marketing and other use of logos</strong>
          </Typography>

          <Typography variant="body1">
            (a) You agree that your name, standard logo and trademark(s) (as
            published by you) may be included by us on the VCMTS client list on
            our website and in our marketing materials. You grant to us and our
            affiliates, a worldwide, non-exclusive, non-transferable,
            non-sublicensable, royalty-free licence during the term of this
            Agreement to use your name, logo, trademark(s) and any other marks
            provided to us. We shall be entitled to use said list freely in our
            commercial efforts. You also agree that your name and standard logo
            may be included by us in our communications with your Buyer in
            relation to the Transaction. Any other use of your name, logo or
            information shall only occur with your prior written consent which
            you shall not unreasonably withhold.
          </Typography>

          <Typography variant="body1">
            (b) You may on your website in the information related section refer
            to us as your payment service provider, explaining that this is the
            reason why the name of VCMTS (or other VCMTS Group Company names,
            our trade name or similar) may appear on your bank statements. You
            may also include an internet link to our website in such a context.
            You may not use our logo anywhere else on your website, or
            otherwise, without our prior express written approval.
          </Typography>

          <Typography variant="body1">
            <strong>14.4 Assignment, Agency and Subcontracting</strong>
          </Typography>

          <Typography variant="body1">
            (a) Subject to the Applicable Law and the Payment Scheme Rules, we
            reserve the right to transfer, assign or novate this Agreement
            (including your Customer VCMTS Account) or any right or obligation
            under this Agreement at any time without your consent or providing
            written notice to you.
          </Typography>

          <Typography variant="body1">
            (b) You may not transfer, assign, mortgage, charge, subcontract,
            declare a trust over or deal in any other manner with any or all of
            your rights and obligations under this Agreement (including your
            Customer VCMTS Account) without our prior written consent.
          </Typography>

          <Typography variant="body1">
            (c) We may appoint at any time, and without prior notice to you, an
            agent or subcontractor to perform any of our obligations under the
            Agreement.
          </Typography>
          <Typography variant="body1">
            <strong>14.5 Partnership</strong>
          </Typography>

          <Typography variant="body1">
            Nothing in this Agreement shall be construed as the constitution of
            a partnership between the Parties, except where expressly provided,
            nor shall it constitute, or deem to constitute, one party as the
            agent of any other party for any purpose.
          </Typography>

          <Typography variant="body1">
            <strong>14.6 Severability</strong>
          </Typography>

          <Typography variant="body1">
            If any provision of this Agreement is found by any court or a
            competent public body or authority to be illegal, invalid, or
            unenforceable:
          </Typography>

          <Typography variant="body1">
            (a) Such illegality, invalidity, or unenforceability shall not
            affect the other provisions of this Agreement, which shall remain in
            full force and effect; and
          </Typography>

          <Typography variant="body1">
            (b) If such provision would cease to be illegal, invalid, or
            unenforceable if some part of the provision were modified or
            deleted, the provision in question shall apply with such minimum
            modification or deletion as may be necessary to make it legal,
            valid, and enforceable.
          </Typography>

          <Typography variant="body1">
            <strong>14.7 Waiver</strong>
          </Typography>

          <Typography variant="body1">
            (a) A waiver of any right or remedy under this Agreement or under
            Applicable Law is only effective if given in writing and shall not
            be deemed a waiver of any subsequent breach or default.
          </Typography>

          <Typography variant="body1">
            (b) A failure or delay by a party to exercise any right or remedy
            provided under this Agreement or under the Applicable Law shall not
            constitute a waiver of that or any other right or remedy, nor shall
            it prevent or restrict any further exercise of that or any other
            right or remedy. No single or partial exercise of any right or
            remedy provided under this Agreement or under the Applicable Law
            shall prevent or restrict the further exercise of that or any other
            right or remedy.
          </Typography>
          <Typography variant="body1">
            <strong>
              14.8 Consent to Electronic Communications and Electronic Signature
              Consent
            </strong>
          </Typography>

          <Typography variant="body1">
            Providing that you have internet access and have an email account to
            receive notices, communications, and information relating to the
            Request Money Transfer Services, you agree to the receipt of
            electronic communications and notices by email or the App or by
            posting of the information on our website. Such communications may
            pertain to the Request Money Transfer Services delivered by us,
            changes in laws or rules impacting the service or other reasons,
            such as the amendment of this Agreement. You may request a copy of
            any legally required disclosures (including this Agreement) from us,
            and we will provide this to you in a form that allows you to store
            and reproduce the information (for example, by email), and you may
            terminate your consent to receive required disclosures through
            electronic communications by contacting us as described in section
            1.7 of the Agreement. We may charge you a records request fee to
            provide this information. We reserve the right to terminate this
            Agreement if you withdraw your consent to receive electronic
            communications.
          </Typography>

          <Typography variant="body1">
            <strong>14.9 Governing law and jurisdiction</strong>
          </Typography>

          <Typography variant="body1">
            (a) This Agreement and any dispute or claim arising out of or in
            connection with it or its subject matter or formation (including
            non-contractual disputes or claims) shall be governed by and
            construed in accordance with the laws of the United Kingdom.
          </Typography>

          <Typography variant="body1">
            (b) Each party irrevocably agrees that the courts of the United
            Kingdom shall have exclusive jurisdiction to settle any dispute or
            claim arising out of or in connection with this Agreement or its
            subject matter or formation (including non-contractual disputes or
            claims), provided always that we shall not be limited to the forum
            of the United Kingdom for the enforcement of any judgment relating
            to this Agreement and shall have the right to bring the relevant
            action in any jurisdiction where you are incorporated or may have
            assets.
          </Typography>

          <Typography variant="body1">
            <strong>14.10 Complaints</strong>
          </Typography>

          <Typography variant="body1">
            If you have any complaints about us or the Request Money Transfer
            Services, you may contact us following our customer complaint
            procedure.
          </Typography>
          <Typography variant="body1">
            <strong>15. Force Majeure</strong>
          </Typography>

          <Typography variant="body1">
            Neither party shall be in breach of this Agreement nor liable for
            delay in performing, or failure to perform, any of its obligations
            under this Agreement if such delay or failure results from events,
            circumstances, or causes beyond its reasonable control, including,
            but not limited to, strikes, lock-outs, or other industrial disputes
            (whether involving the workforce of Parties or any other party),
            failure of a utility service or transport or telecommunications
            network or the internet, acts of God, war, riot, civil commotion,
            malicious damage, compliance with any law or governmental order,
            rule, regulation, or direction, accident, breakdown of plant or
            machinery, fire, flood, pandemic, storm, or default of suppliers or
            subcontractors (each a “Force Majeure Event”). If the Force Majeure
            Event makes it impossible for the relevant party to perform any of
            its obligations under this Agreement for a period exceeding two (2)
            months, that party may terminate this Agreement with immediate
            effect by giving written notice to the other party.
          </Typography>

          <Typography variant="body1">
            <strong>16. Entire Agreement</strong>
          </Typography>

          <Typography variant="body1">
            <strong>16.1</strong> This Agreement and the Additional Documents
            supersede and extinguish all previous agreements between you and
            VCMTS, whether written or oral, relating to its subject matter.
          </Typography>

          <Typography variant="body1">
            <strong>16.2</strong> Each party to this Agreement warrants that it
            has not relied on any representations, arrangements, understanding,
            or agreement (whether written or oral) not expressly set out or
            referred to in the Agreement. The only remedy available to any party
            in respect of any such representations, arrangements, understanding,
            or agreement shall be for the breach of contract in accordance with
            the terms of this Agreement.
          </Typography>

          <Typography variant="body1">
            <strong>SCHEDULE 1</strong>
          </Typography>

          <Typography variant="body1">
            <strong>SETTLEMENT TIMEFRAMES</strong>
          </Typography>

          <Typography variant="body1">
            The timeframe for Payment is as notified to you from time to time
            and is assessed in accordance with your risk profile (as further
            explained in this FAQ).
          </Typography>

          <Typography variant="body1">
            <strong>SCHEDULE 2</strong>
          </Typography>

          <Typography variant="body1">
            <strong>DATA REQUIRED TO BE SUBMITTED BY YOU</strong>
          </Typography>

          <Typography variant="body1">
            - Amount for payment for each Transaction
          </Typography>

          <Typography variant="body1">
            - Currency for payment for each Transaction
          </Typography>
        </div>
      </div>
    </ThemeProvider>
  );
};

export default TermsUK;
