import { Avatar, Box, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import i18next from "i18next";

interface Props {
  item: any;
  index: number;
}

function RecipientListItem({ item, index }: Props) {
  const navigate = useNavigate();

  const handleOnClick = () => {
    navigate(`/recipient-details/${item.id}`);
  };

  function getAccountEnding(item: any): string {
    const typeToFieldMap: Record<string, string | undefined> = {
      iban: "IBAN",
      swift_code: "accountNumber",
      sort_code: "accountNumber",
      aba: "accountNumber",
    };

    const fieldType = typeToFieldMap[item.meta.type];

    return fieldType
      ? item &&
          item.meta &&
          item.meta.details &&
          item.meta.details[fieldType].slice(-4)
      : "";
  }

  return (
    <div key={"recipient" + index}>
      <Box className="allRecipientSingle" onClick={handleOnClick}>
        <Box className="allRecipientInfo">
          <Box className="avatarAndNameEndingIn">
            <Avatar
              style={{
                width: 48,
                height: 48,
                backgroundColor: "#E5E8E2",
              }}
            >
              {item.meta.accountHolderName && (
                <>
                  <span className="spanAvatar">
                    {item.meta.accountHolderName
                      .split(" ")
                      .map((namePart: any, index: any) =>
                        index < 2 ? namePart.charAt(0).toUpperCase() : ""
                      )
                      .join("")}
                  </span>
                </>
              )}
            </Avatar>
            <Box className="nameAndEndingIn">
              <Typography className="allRecipientName">
                {item && item.meta && item.meta.accountHolderName}
              </Typography>
              <Typography className="allRecipientEndingIn">
                {item && item.meta && item.meta.currency}{" "}
                {i18next.t("account ending in")} {getAccountEnding(item)}
              </Typography>
            </Box>
          </Box>
          <Box>
            <NavigateNextIcon className="arrayRightIcon" />
          </Box>
        </Box>
      </Box>
    </div>
  );
}

export default RecipientListItem;
