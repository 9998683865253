import React from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Box, Typography } from "@mui/material";
import HeaderLanding from "../components/HeaderLanding/HeaderLanding";
import { useAppSelector } from "src/store";
import FooterLanding from "../components/FooterLanding/FooterLanding";
import "./LandingPagesGlobal.scss";
import TitleBackBox from "../components/TitleBackBox/TitleBackBox";
import HeadlineVvidgetSmall from "../components/HeadlineVvidget/HeadlineVvidgetSmall";
import { useNavigate } from "react-router-dom";
import LogoTextVvidget from "../components/LogoTextVvidget/LogoTextVvidget";
import i18next from "i18next";

const defaultTheme = createTheme();

const MediaPress: React.FC = () => {
  const { user } = useAppSelector((state) => state.auth);

  const navigate = useNavigate();

  const navigateToContact = () => {
    navigate("/contact-us");
  };

  return (
    <div>
      <HeaderLanding user={user} />
      <div className="margintTopPagesMainCont">
        <ThemeProvider theme={defaultTheme}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Box className="titleHeadlineSmallBG">
              <Box className="landingPagesMainBox">
                <Box>
                  <TitleBackBox title={i18next.t("Media & press")} />
                </Box>
                <Box>
                  <HeadlineVvidgetSmall
                    headline={i18next.t("We are giving our insights - as much as we can")}
                    subtitle={
                      i18next.t("Are you a journalist, media representative, blogger or influencer? Do you work for a TV or radio station or run a podcast")
                    }
                    smallText={i18next.t("Need additional information")}
                    buttonText={i18next.t("Contact us")}
                    onClickFunction={navigateToContact}
                  />
                </Box>
              </Box>
            </Box>
            <Box className="landingPagesMainBox">
              <Box>
                <Typography
                  variant="h3"
                  sx={{ marginBottom: "1rem", marginTop: "64px" }}
                  className="headlineLandingPages"
                >
                  {i18next.t("Media Inquiries and Information Requests")}
                </Typography>

                <Box sx={{ marginTop: "2rem" }}>
                  <Typography
                    variant="h3"
                    sx={{
                      marginBottom: "1rem",
                    }}
                    className="headlineMiniLandingPages"
                  >
                    {" "}
                    {i18next.t("For Journalists and Media Representatives")}
                  </Typography>
                  <Typography
                    variant="body1"
                    paragraph
                    className="textsLandingPage"
                  >
                    {i18next.t("Are you a journalist, media representative, blogger or influencer? Do you work for a TV or radio station or run a podcast")}
                  </Typography>
                </Box>
                <Box sx={{ marginTop: "2rem" }}>
                  <Typography
                    variant="h3"
                    sx={{
                      marginBottom: "1rem",
                    }}
                    className="headlineMiniLandingPages"
                  >
                    {" "}
                    {i18next.t("Reporting on VicPay and the Victorum Group")}
                  </Typography>
                  <Typography
                    variant="body1"
                    paragraph
                    className="textsLandingPage"
                  >
                    {i18next.t("Do you want to report on VicPay, the Victorum Group, our technology or our CEO? Do you need information, images or are you interested in an interview")}
                  </Typography>
                  <Typography
                    variant="body1"
                    paragraph
                    className="textsLandingPage"
                  >
                    {i18next.t("Then you've come to the right place")}
                  </Typography>
                </Box>
              </Box>
              <Box
                sx={{
                  marginBottom: "52px",
                  "@media(max-width: 1023px)": {
                    marginBottom: "76px",
                  },
                }}
              >
                <LogoTextVvidget
                  text={
                    <>
                      {i18next.t("Whether you have an individual interview request or high-resolution video material, please do not hesitate to send your enquiry to our communications department at")}{" "}
                      <a
                        href="mailto:press@vicpayments.com"
                        className="linksHover"
                        style={{ marginLeft: "5px" }}
                      >
                        press@vicpayments.com
                      </a>
                      .<br />
                      {i18next.t("Please understand that our contacts are only available for enquiries from media representatives")}
                    </>
                  }
                />
              </Box>
              <Box>
                <Typography
                  variant="h3"
                  sx={{
                    marginBottom: "1rem",
                  }}
                  className="headlineMiniLandingPages"
                >
                  {" "}
                  {i18next.t("General Inquiries and Support")}
                </Typography>
                <Typography
                  variant="body1"
                  paragraph
                  className="textsLandingPage"
                >
                  {i18next.t("For other enquiries, such as about our products, career opportunities or other areas of the company, simply")}{" "}
                  <span
                    className="linksHover"
                    onClick={() => navigate("/contact-us")}
                  >
                    {i18next.t("click here")}
                  </span>
                </Typography>
                <Typography
                  variant="body1"
                  paragraph
                  className="textsLandingPage"
                >
                  {i18next.t("If you are not sure which department is the right one, simply select +1 604-260-0738 and we will be happy to help you")}
                </Typography>
              </Box>
            </Box>
          </Box>
        </ThemeProvider>
      </div>
      <Box sx={{ marginTop: "4rem" }}>
        <FooterLanding />
      </Box>
    </div>
  );
};

export default MediaPress;
