import IconTextWidget from "../../components/IconTextWidget/IconTextWidget";
import currencyIcon from "../../../../assets/images/currencies.png";
import merchantIcon from "../../../../assets/images/merchant.png";
import users from "../../../../assets/images/users.png";
import icon24 from "../../../../assets/images/24.png";
import languages from "../../../../assets/images/languages.png";
import i18next from "i18next";

const PageInfoWidget = () => {
  return (
    <div className="icon-text-widgets-container">
      <IconTextWidget
        iconSrc={currencyIcon}
        title={i18next.t("Accounts in USD, EUR and other currencies")}
        text={i18next.t("Accounts in USD, EUR and other currencies")}
      />
      <IconTextWidget
        iconSrc={merchantIcon}
        title={i18next.t("Local and international deposits and withdrawals")}
        text={i18next.t("Local and international deposits and withdrawals")}
      />
      <IconTextWidget
        iconSrc={languages}
        title={i18next.t("Multilingual Support")}
        text=""
      />
      <IconTextWidget
        iconSrc={users}
        title={i18next.t("Robust multi-tier account security")}
        text={i18next.t("Robust multi-tier account security with 2FA")}
      />
      <IconTextWidget
        iconSrc={icon24}
        title={i18next.t("First response in 1 hour or faster")}
        text={i18next.t("First response in 1 hour or faster")}
      />
    </div>
  );
};

export default PageInfoWidget;
