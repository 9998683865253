import React from 'react';
import QRCode from 'qrcode.react';

function QRCodeGenerator({ link }: { link: string }) {
  return (
    <div>
      <QRCode value={link} />
    </div>
  );
}

export default QRCodeGenerator;
