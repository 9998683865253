import Typography from "@mui/material/Typography";
import moment from "moment";
import "../../pages/RecipientDetails/RecipientDetails.scss";
import React from "react";
import "../../style/global.scss";
import i18next from "i18next";

const translatedKeys: any = [
  { legalType: i18next.t("Legal Type") },
  { bankName: i18next.t("Bank Name") },
];
const formatKey = (key: string) => {
  let res = "";
  if (translatedKeys[key]) {
    res = translatedKeys[key];
  } else {
    const formattedKey = key
      .replace(/([A-Z])/g, " $1")
      .replace(/^./, (str) => str.toUpperCase());
    if (formattedKey) res = i18next.t(formattedKey);
  }

  if (res === "") res = key;
  return res;
};
const RecipientMetaDetails = ({ recipientDetails }: any) => {
  const details = recipientDetails?.meta?.details;

  if (!details) {
    return null;
  }

  const renderValue = (key: any, value: any) => {
    if (typeof value === "object" && value !== null) {
      return Object.entries(value).map(([subKey, subValue]: any) =>
        subValue ? (
          <div key={"value" + subKey} className="keyAndValueMain">
            <div>
              <Typography
                className="mb-1rem d-flex jc-start keys registration0875RemFontSize"
                variant="h5"
              >
                {formatKey(subKey)}
              </Typography>
            </div>
            <div>
              <Typography
                className="mb-1rem d-flex jc-start values registration0875RemFontSize"
                variant="h5"
              >
                {subValue}
              </Typography>
            </div>
          </div>
        ) : (
          <React.Fragment key={"empty" + subKey}></React.Fragment>
        )
      );
    } else {
      return (
        <div key={"value" + key} className="keyAndValueMain">
          <div>
            <Typography
              className="mb-1rem d-flex jc-start keys registration0875RemFontSize"
              variant="h5"
            >
              {formatKey(key)}
            </Typography>
          </div>
          <div>
            <Typography
              className="mb-1rem d-flex jc-start values registration0875RemFontSize"
              variant="h5"
            >
              {value}
            </Typography>
          </div>
        </div>
      );
    }
  };

  return (
    <>
      {recipientDetails &&
        recipientDetails.meta &&
        recipientDetails.meta.accountHolderName && (
          <div className="keyAndValueMain">
            <div>
              <Typography
                className="mb-1rem  d-flex jc-start keys registration0875RemFontSize"
                variant="h5"
              >
                {i18next.t("Account Holder Name")}
              </Typography>
            </div>
            <div>
              <Typography
                className="mb-1rem  d-flex jc-start values registration0875RemFontSize"
                variant="h5"
              >
                {recipientDetails?.meta?.accountHolderName}
              </Typography>
            </div>
          </div>
        )}
      {recipientDetails && recipientDetails.created_at && (
        <div className="keyAndValueMain">
          <div>
            <Typography
              className="mb-1rem  d-flex jc-start keys registration0875RemFontSize"
              variant="h5"
            >
              {i18next.t("Created At")}
            </Typography>
          </div>
          <div>
            <Typography
              className="mb-1rem  d-flex jc-start values registration0875RemFontSize"
              variant="h5"
            >
              {" "}
              {recipientDetails?.created_at
                ? moment(recipientDetails.created_at).format("DD/MM/YYYY")
                : ""}
            </Typography>
          </div>
        </div>
      )}
      {recipientDetails &&
        recipientDetails.meta &&
        recipientDetails.meta.currency && (
          <div className="keyAndValueMain">
            <div>
              <Typography
                className="mb-1rem  d-flex jc-start keys registration0875RemFontSize registration0875RemFontSize"
                variant="h5"
              >
                {i18next.t("Currency")}
              </Typography>
            </div>
            <div>
              <Typography
                className="mb-1rem  d-flex jc-start values registration0875RemFontSize"
                variant="h5"
              >
                {recipientDetails?.meta?.currency}
              </Typography>
            </div>
          </div>
        )}
      {recipientDetails &&
        recipientDetails.meta &&
        recipientDetails.meta.type && (
          <div className="keyAndValueMain">
            <div>
              <Typography
                className="mb-1rem  d-flex jc-start keys registration0875RemFontSize"
                variant="h5"
              >
                {i18next.t("Type")}
              </Typography>
            </div>
            <div>
              <Typography
                className="mb-1rem  d-flex jc-start values registration0875RemFontSize"
                variant="h5"
              >
                {recipientDetails?.meta?.type}
              </Typography>
            </div>
          </div>
        )}

      {Object.entries(details).map(([key, value]) => {
        if (value !== null) {
          return renderValue(key, value);
        }

        return null;
      })}
    </>
  );
};
export default RecipientMetaDetails;
