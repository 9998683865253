import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Nav from "src/components/layout/nav/Nav";
import { Box, Typography } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate } from "react-router-dom";
import RecipientsList from "../../pages/RecipientsList/RecipientsList";
import { useEffect } from "react";
import { useUser } from "src/hooks/useUser";
import i18next from "i18next";

const defaultTheme = createTheme();

const RecipientsPage = () => {
  const navigate = useNavigate();
  const user = useUser();

  const handleNavigate = () => {
    navigate("/home");
  };

  useEffect(() => {
    if (user?.role === "Employee") {
      navigate("/admin");
    }
  }, [user, navigate]);

  return (
    <Box>
      <Nav step={0} isStepperVisible={false} isProfileSetVisible={true} />
      <ThemeProvider theme={defaultTheme}>
        <Container component="main">
          <Box className="settingsMain">
            <div className="d-flex ai-center mb-3-rem">
              <p
                className="back-button-icon circle-around-text-smaller helvetica mr-1-rem cp"
                onClick={handleNavigate}
              >
                <ArrowBackIcon className="muiIcons" />
              </p>

              <Typography
                className="your-details-text d-flex jc-start pageTitleFontSize"
                component="h1"
                variant="h5"
                sx={{
                  fontFamily: "Helvetica, sans-serif",
                  fontWeight: "600",
                  textAlign: "start",
                }}
              >
                {i18next.t("Recipients")}
              </Typography>
            </div>
            <RecipientsList hideMenu={true} />
          </Box>
        </Container>
      </ThemeProvider>
    </Box>
  );
};

export default RecipientsPage;
