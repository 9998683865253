import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { useEffect, useState } from "react";
import Nav from "src/components/layout/nav/Nav";
import PublishIcon from "@mui/icons-material/Publish";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import AddIcon from "@mui/icons-material/Add";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import transactionsService from "src/store/transactions/transactions_service";

import {
  Box,
  Button,
  InputAdornment,
  TextareaAutosize,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import api from "src/store/interceptors/api";
import ConfirmationDialog from "src/components/shared/ConfirmationDialog/ConfirmationDialog";
import LoadingIndicator from "src/components/shared/LoadingIndicator";
import { capitalizeWords, truncateFileName } from "src/utils/helpers";

import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import TransactionAdminActions from "./TransactionAdminActions";
import { useAppSelector } from "src/store";
import i18next from "i18next";
const defaultTheme = createTheme();

interface Props {
  transactionDetails: any;
  transactionId: any;
  errorPage: any;
  loadingPage: boolean;
}
function TransactionDetailCrypto({
  transactionDetails,
  transactionId,
  loadingPage,
  errorPage,
}: Props) {
  const { user } = useAppSelector((state) => state.auth);
  const [loading, setLoading] = useState(loadingPage);
  const [error, setError] = useState(errorPage);
  const navigate = useNavigate();

  const [targetValue, setTargetValue] = useState("");
  const [sourceValue, setSourceValue] = useState("");

  const [isDownloading, setIsDownloading] = useState(false);
  const [selectedFile, setSelectedFile] = useState<any>("");
  const [notes, setNotes] = useState("");
  const receivedText = transactionsService.getProperReceivedText(
    transactionDetails?.status
  );

  const transactionData = transactionDetails?.crypto_meta ?? null;

  const handleFileChange = async (event: any) => {
    if (!selectedFile) {
      if (!event?.target?.files?.length) return;
      const file = event.target.files[0];
      if (file) {
        const formData = new FormData();
        formData.append("attachment", file);
        formData.append("_method", "PATCH");
        try {
          const response = await transactionsService.uploadAttachment(
            transactionId,
            file
          );
          if (response && response?.data && response?.data?.data.attachment) {
            response.data.data.attachment.name =
              response.data.data.attachment.original_name;
            setSelectedFile(response.data.data.attachment);
          }
        } catch (error) {
          console.error("Error uploading file: ", error);
        }
      }
    } else {
      const fileURL = selectedFile.file_url;
      try {
        const response = await fetch(fileURL);
        const blob = await response.blob();

        const openLink = document.createElement("a");
        const blobURL = URL.createObjectURL(blob);
        openLink.href = blobURL;
        openLink.target = "_blank";
        document.body.appendChild(openLink);
        openLink.click();
        document.body.removeChild(openLink);
        URL.revokeObjectURL(blobURL);
      } catch (error) {
        console.error("Error downloading file: ", error);
      }
    }
  };

  const handleNotesChange = (event: any) => {
    setNotes(event.target.value);
  };

  const renderIcon = (status: string) => {
    const iconType = transactionsService.getProperIconBasedOnStatus(status);
    switch (iconType) {
      case "up":
        return (
          <ArrowUpwardIcon style={{ color: "#0e0f0c" }} className="muiIcons" />
        );
      case "plus":
        return <AddIcon style={{ color: "#0e0f0c" }} className="muiIcons" />;
      default:
        return (
          <ArrowUpwardIcon style={{ color: "#0e0f0c" }} className="muiIcons" />
        );
    }
  };

  // conf dialog start
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isDialogCancelOpen, setIsDialogCancelOpen] = useState(false);

  const handleDialogCancelClose = () => {
    setIsDialogCancelOpen(false);
  };

  const handleDialogCancelConfirm = (e: any) => {
    setIsDialogCancelOpen(false);
    handleCancel();
  };

  const handleDialogClose = () => {
    setIsDialogOpen(false);
  };

  const handleDialogConfirm = (e: any) => {
    setIsDialogOpen(false);
    handleRemoveFileClick(e);
  };

  const handleRemoveFileClickStart = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDialogOpen(true);
  };

  const handleRemoveFileClick = async (e: any) => {
    e.preventDefault();
    e.stopPropagation();

    try {
      await api.put(`/remove-transaction/${transactionId}/attachment`);
      const fileInput = document.getElementById(
        "file-input"
      ) as HTMLInputElement;
      if (fileInput) {
        fileInput.value = "";
      }
      setSelectedFile(null);
    } catch (error) {
      console.error("Error removing the attachment:", error);
    }
  };

  const handleNavigate = () => {
    navigate(-1);
    //navigate("/transactions-list");
  };

  const handleCancel = async () => {
    try {
      setLoading(true);
      await api.get(`/cancel-transaction/${transactionId}`);
      setLoading(false);
      window.location.reload();
    } catch (error) {
      setLoading(false);
      console.error("Error canceling the transfer:", error);
    }
  };
  const handleDownload = () => {
    transactionsService.downloadTransactionInvoice(
      transactionId,
      () => setIsDownloading(true),
      () => setIsDownloading(false)
    );
  };

  useEffect(() => {
    if (transactionDetails?.crypto_amount_sent) {
      setSourceValue(transactionDetails.crypto_amount_sent);
    }

    if (transactionDetails?.crypto_amount_sent) {
      setTargetValue(transactionDetails.crypto_amount_sent);
    }

    if (transactionDetails?.description)
      setNotes(transactionDetails.description);

    if (transactionDetails?.attachment) {
      transactionDetails.attachment.name =
        transactionDetails.attachment.original_name;
      setSelectedFile(transactionDetails.attachment);
    }
  }, [transactionDetails]);

  const handleBlur = async () => {
    if (
      transactionDetails &&
      transactionDetails.notes &&
      notes === transactionDetails.description
    )
      return;
    const formData = new FormData();
    formData.append("_method", "PATCH");
    formData.append("description", notes === "" ? "null" : notes);
    try {
      await api.post(`/transactions/${transactionId}`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      transactionDetails.description = notes;
    } catch (error) {
      console.error("Error updating notes: ", error);
    }
  };

  const initialSelectedTab =
    transactionsService.translateStatus(transactionDetails?.status) ===
    "Cancelled"
      ? "Details"
      : "Updates";
  const [selectedTab, setSelectedTab] = useState(initialSelectedTab);

  const handleClick = (tabName: string) => {
    setSelectedTab(tabName);
  };

  const textStyle = (tabName: string) => ({
    fontFamily: "Helvetica, sans-serif",
    fontSize: "1rem",
    fontWeight: selectedTab === tabName ? "600" : "400",
    color: "#454745",
    marginBottom: "0.5rem",
    marginRight: tabName === "Updates" ? "3rem" : "0",
    textDecoration: selectedTab === tabName ? "underline" : "none",
    cursor: "pointer",
  });
  return (
    <div>
      <ConfirmationDialog
        open={isDialogOpen}
        handleClose={handleDialogClose}
        handleConfirm={handleDialogConfirm}
        content={{
          title: i18next.t("Sure you'd like to remove this file?"),
          body: i18next.t("Note that this action cannot be undone."),
          cancelText: i18next.t("Cancel"),
          confirmText: i18next.t("Remove file"),
        }}
        className="custom-dialog"
      />
      <ConfirmationDialog
        open={isDialogCancelOpen}
        handleClose={handleDialogCancelClose}
        handleConfirm={handleDialogCancelConfirm}
        content={{
          title: i18next.t("Sure you'd like to cancel this transaction?"),
          body: i18next.t("Note that this action cannot be undone."),
          cancelText: i18next.t("No"),
          confirmText: i18next.t("Cancel transaction"),
        }}
        className="custom-dialog"
      />

      <Nav step={0} isStepperVisible={false} isProfileSetVisible={true} />
      <div className="content-container-profile main-color">
        <ThemeProvider theme={defaultTheme}>
          <Container component="main">
            {loading ? (
              <LoadingIndicator />
            ) : error && error !== "" ? (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  marginBottom: "4rem",
                }}
              >
                <Typography
                  className="your-details-text d-flex jc-start"
                  component="h1"
                  variant="h5"
                  sx={{
                    fontFamily: "Helvetica, sans-serif",
                    fontSize: "1.875rem",
                    fontWeight: "600",
                    textAlign: "start",
                    marginBottom: "2rem",
                  }}
                >
                  {error}
                </Typography>
              </Box>
            ) : (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  marginBottom: "4rem",
                }}
              >
                <div className="d-flex ai-center mb-3-rem">
                  <p
                    className="back-button-icon circle-around-text-smaller helvetica mr-1-rem cp"
                    onClick={handleNavigate}
                  >
                    <ArrowBackIcon className="muiIcons" />
                  </p>

                  <Typography
                    className="your-details-text d-flex jc-start pageTitleFontSize"
                    component="h1"
                    variant="h5"
                    sx={{
                      fontFamily: "Helvetica, sans-serif",
                      fontWeight: "600",
                      textAlign: "start",
                    }}
                  >
                    {i18next.t("Transaction details")}
                  </Typography>
                </div>

                {user &&
                  user.role &&
                  (user.role === "Admin" || user.role === "Employee") && (
                    <TransactionAdminActions
                      loadingPage={loading}
                      transactionDetails={transactionDetails}
                      transactionId={transactionId}
                    />
                  )}
                <Box sx={{ mt: 2 }}>
                  <div className="personal-box">
                    <div className="transaction-item-details">
                      <div className="d-flex ai-center jc-center">
                        <p className="back-button-icon circle-around-text-smaller helvetica mr-1-rem">
                          {/* <ArrowUpwardIcon style={{ color: "#163300" }} /> */}
                          {renderIcon(transactionDetails?.status)}{" "}
                        </p>
                        <div>
                          <Typography
                            component="h1"
                            variant="h5"
                            sx={{
                              fontFamily: "Helvetica, sans-serif",
                              fontSize: "1rem",
                              fontWeight: "600",
                              color: "#454745",
                              marginBottom: "0.5rem",
                            }}
                          >
                            {capitalizeWords(
                              transactionDetails?.recipient?.account_holder
                            )}
                          </Typography>
                          <Typography
                            component="h1"
                            variant="h5"
                            sx={{
                              fontFamily: "Helvetica, sans-serif",
                              fontSize: "0.875rem",
                              fontWeight: "200",
                              color: "#454745",
                            }}
                          >
                            {i18next.t(
                              transactionsService.translateStatus(
                                transactionDetails?.status
                              )
                            )}
                          </Typography>
                        </div>
                      </div>
                      <div className="ta-end">
                        <Typography
                          component="h1"
                          variant="h5"
                          sx={{
                            fontFamily: "Helvetica, sans-serif",
                            fontSize: "1rem",
                            fontWeight: "600",
                            color: "#454745",
                            marginBottom: "0.5rem",
                          }}
                        >
                          {/* {(parseFloat(transactionDetails?.meta?.targetValue).toFixed(2))}{" "} */}
                          {/* {transactionsService.formatNumber(transactionDetails?.meta?.targetValue)}{" "} */}
                          {transactionsService?.formatNumberCrypto(
                            transactionDetails?.crypto_amount_sent
                          )}{" "}
                          {transactionDetails?.currency_target?.code ?? ""}
                        </Typography>
                        <Typography
                          component="h1"
                          variant="h5"
                          sx={{
                            fontFamily: "Helvetica, sans-serif",
                            fontSize: "0.875rem",
                            fontWeight: "200",
                            color: "#454745",
                          }}
                        >
                          {transactionDetails?.crypto_amount_sent}{" "}
                          {transactionDetails?.currency?.code}
                        </Typography>
                      </div>
                    </div>
                    <div className="horizontal-line-list mb-8"></div>
                    <div className="small-nav-box">
                      {initialSelectedTab !== "Details" && (
                        <Typography
                          component="h1"
                          variant="h5"
                          sx={textStyle("Updates")}
                          onClick={() => handleClick("Updates")}
                        >
                          {i18next.t("Updates")}
                        </Typography>
                      )}

                      <Typography
                        component="h1"
                        variant="h5"
                        sx={textStyle("Details")}
                        onClick={() => handleClick("Details")}
                      >
                        {i18next.t("Details")}
                      </Typography>
                    </div>

                    {selectedTab === "Updates" && (
                      // updates content
                      <div className="updates-content">
                        <div className="horizontal-line-list mb-8"></div>
                        <div className="small-nav-box pb-2-rem">
                          <Typography
                            component="h1"
                            variant="h5"
                            sx={{
                              fontFamily: "Helvetica, sans-serif",
                              fontSize: "0.875rem",
                              fontWeight: "200",
                              color: "#454745",
                              marginRight: "6rem",
                            }}
                          >
                            {i18next.t("Transaction number")}
                          </Typography>
                          <Typography
                            component="h1"
                            variant="h5"
                            sx={{
                              fontFamily: "Helvetica, sans-serif",
                              fontSize: "0.875rem",
                              fontWeight: "200",
                              color: "#454745",
                            }}
                          >
                            #T{transactionDetails?.id}
                          </Typography>
                        </div>
                        <div className="horizontal-line-list mb-8"></div>
                        <h3
                          style={{
                            fontFamily: "Helvetica, sans-serif",
                            fontSize: "0.875rem",
                            fontWeight: "200",
                            color: "#454745",
                            marginTop: "1rem",
                          }}
                        >
                          {i18next.t("Send")}
                        </h3>
                        <TextField
                          variant="outlined"
                          value={sourceValue}
                          inputProps={{
                            readOnly: true,
                          }}
                          InputProps={{
                            sx: {
                              borderRadius: "10px",
                              height: "3rem",
                              marginBottom: "1.5rem",
                            },
                            endAdornment: (
                              <InputAdornment position="end">
                                <div className="d-flex ai-center">
                                  <span
                                    style={{
                                      marginRight: "1.5rem",
                                      fontSize: "1rem",
                                    }}
                                  >
                                    {transactionDetails?.currency?.code || ""}
                                  </span>
                                </div>
                              </InputAdornment>
                            ),
                          }}
                          sx={{
                            width: "100%",
                            borderRadius: "10px",
                            marginTop: "8px",
                            "& .MuiOutlinedInput-root": {
                              paddingRight: 0,
                            },
                            "& .MuiInputAdornment-root": {
                              backgroundColor: "transparent",
                              pointerEvents: "none",
                            },
                          }}
                        />

                        <h3
                          style={{
                            fontFamily: "Helvetica, sans-serif",
                            fontSize: "0.875rem",
                            fontWeight: "200",
                            color: "#454745",
                            marginTop: "1rem",
                          }}
                        >
                          {i18next.t("Recipient gets")}
                        </h3>
                        <TextField
                          variant="outlined"
                          value={targetValue}
                          // type="number"
                          // onChange={handleTargetValueChange}
                          inputProps={{
                            // Use inputProps for native attributes like readOnly
                            readOnly: true,
                          }}
                          InputProps={{
                            sx: {
                              borderRadius: "10px",
                              height: "3rem",
                              marginBottom: "1.5rem",
                            },
                            endAdornment: (
                              <InputAdornment position="end">
                                <div className="d-flex ai-center">
                                  <span
                                    style={{
                                      marginRight: "1.5rem",
                                      fontSize: "1rem",
                                    }}
                                  >
                                    {transactionDetails?.crypto_amount_sent
                                      ?.code || ""}
                                  </span>
                                </div>
                              </InputAdornment>
                            ),
                          }}
                          sx={{
                            width: "100%",
                            borderRadius: "10px",
                            marginTop: "8px",
                            "& .MuiOutlinedInput-root": {
                              paddingRight: 0,
                            },
                            "& .MuiInputAdornment-root": {
                              backgroundColor: "transparent",
                              pointerEvents: "none",
                            },
                          }}
                        />
                        {/* <Button
                          onClick={handleDownload}
                          fullWidth
                          variant="contained"
                          sx={{
                            color: "white",
                            backgroundColor: "#2A5182",
                            borderRadius: "24px",
                            height: "3rem",
                            textTransform: "none",
                            marginTop: "0.5rem",
                            marginBottom: "1rem",
                            fontFamily: "Helvetica, sans-serif",
                            fontSize: "0.9rem",
                          }}
                          disabled={isDownloading}
                        >
                          Print PDF
                        </Button> */}

                        {transactionDetails &&
                          transactionDetails.status &&
                          transactionDetails.status ===
                            "transaction_payment_waiting" && (
                            <Button
                              onClick={() => {
                                if (!isDialogCancelOpen)
                                  setIsDialogCancelOpen(true);
                              }}
                              fullWidth
                              variant="contained"
                              sx={{
                                color: "white",
                                backgroundColor: "#a8200d",
                                borderRadius: "24px",
                                height: "3rem",
                                textTransform: "none",
                                marginTop: "0.5rem",
                                marginBottom: "1rem",
                                fontFamily: "Helvetica, sans-serif",
                                fontSize: "0.9rem",
                              }}
                            >
                              {i18next.t("Cancel transaction")}
                            </Button>
                          )}
                      </div>
                    )}
                    {selectedTab === "Details" && (
                      //   details content
                      <div className="details-content">
                        <div className="horizontal-line-list"></div>
                        <div className="pb-2-rem trans-bank-container">
                          <div className="transaction-details-small-container">
                            <Typography
                              component="h1"
                              variant="h5"
                              sx={{
                                fontFamily: "Helvetica, sans-serif",
                                fontSize: "1.125rem",
                                fontWeight: "600",
                                color: "#454745",
                                marginBottom: "1rem",
                              }}
                            >
                              {i18next.t("Transaction details")}
                            </Typography>

                            <div className="d-flex ai-center jusifyContentSpaceBetween mb-0-5rem">
                              <Typography
                                component="h1"
                                variant="h5"
                                sx={{
                                  fontFamily: "Helvetica, sans-serif",
                                  fontSize: "0.875rem",
                                  fontWeight: "200",
                                  color: "#454745",
                                }}
                              >
                                {i18next.t("You’re sending")}
                              </Typography>
                              <Typography
                                component="h1"
                                variant="h5"
                                sx={{
                                  fontFamily: "Helvetica, sans-serif",
                                  fontSize: "0.875rem",
                                  fontWeight: "200",
                                  color: "#454745",
                                }}
                              >
                                {transactionDetails?.crypto_amount_sent}{" "}
                                {transactionDetails?.currency?.code}{" "}
                              </Typography>
                            </div>

                            <div className="d-flex ai-center jusifyContentSpaceBetween mb-0-5rem">
                              <Typography
                                component="h1"
                                variant="h5"
                                sx={{
                                  fontFamily: "Helvetica, sans-serif",
                                  fontSize: "0.875rem",
                                  fontWeight: "400",
                                  color: "#454745",
                                  textDecoration: "underline",
                                }}
                              >
                                {i18next.t("Fee")}
                              </Typography>
                              <Typography
                                component="h1"
                                variant="h5"
                                sx={{
                                  fontFamily: "Helvetica, sans-serif",
                                  fontSize: "0.875rem",
                                  fontWeight: "200",
                                  color: "#454745",
                                }}
                              >
                                {transactionDetails?.crypto_fee
                                  ? transactionsService.formatNumberCrypto(
                                      transactionDetails?.crypto_fee
                                    )
                                  : transactionsService.formatNumberCrypto(
                                      transactionDetails?.crypto_fee
                                    )}{" "}
                                {transactionDetails?.currency?.code}{" "}
                              </Typography>
                            </div>

                            {/* converted start block */}
                            {transactionDetails?.currency?.code !==
                              transactionDetails?.currency_target?.code && (
                              <div>
                                <div className="d-flex ai-center jusifyContentSpaceBetween mb-0-5rem">
                                  <Typography
                                    component="h1"
                                    variant="h5"
                                    sx={{
                                      fontFamily: "Helvetica, sans-serif",
                                      fontSize: "0.875rem",
                                      fontWeight: "200",
                                      color: "#454745",
                                    }}
                                  >
                                    {i18next.t("We converted")}
                                  </Typography>
                                  <Typography
                                    component="h1"
                                    variant="h5"
                                    sx={{
                                      fontFamily: "Helvetica, sans-serif",
                                      fontSize: "0.875rem",
                                      fontWeight: "200",
                                      color: "#454745",
                                    }}
                                  >
                                    {transactionsService.formatNumber(
                                      parseFloat(
                                        transactionDetails.crypto_amount_sent
                                      ) - parseFloat(transactionDetails.fee)
                                    )}{" "}
                                    {transactionDetails?.currency?.code}
                                  </Typography>
                                </div>

                                <div className="d-flex ai-center jusifyContentSpaceBetween">
                                  <Typography
                                    component="h1"
                                    variant="h5"
                                    sx={{
                                      fontFamily: "Helvetica, sans-serif",
                                      fontSize: "0.875rem",
                                      fontWeight: "200",
                                      color: "#454745",
                                    }}
                                  >
                                    {i18next.t("Exchange rate")}
                                  </Typography>
                                  <Typography
                                    component="h1"
                                    variant="h5"
                                    sx={{
                                      fontFamily: "Helvetica, sans-serif",
                                      fontSize: "0.875rem",
                                      fontWeight: "200",
                                      color: "#454745",
                                    }}
                                  >
                                    {transactionDetails?.rate ?? ""}{" "}
                                  </Typography>
                                </div>
                              </div>
                            )}
                            {/* converted end block */}
                            <div className="line-dashed"></div>
                            <div className="d-flex ai-center jusifyContentSpaceBetween">
                              {/* receive start */}
                              {receivedText === "up" ? (
                                <Typography
                                  component="h1"
                                  variant="h5"
                                  sx={{
                                    fontFamily: "Helvetica, sans-serif",
                                    fontSize: "0.875rem",
                                    fontWeight: "200",
                                    color: "#454745",
                                  }}
                                >
                                  {capitalizeWords(
                                    transactionDetails?.recipient
                                      ?.account_holder
                                  )}{" "}
                                  {i18next.t("received")}
                                </Typography>
                              ) : (
                                <Typography
                                  component="h1"
                                  variant="h5"
                                  sx={{
                                    fontFamily: "Helvetica, sans-serif",
                                    fontSize: "0.875rem",
                                    fontWeight: "200",
                                    color: "#454745",
                                  }}
                                >
                                  {i18next.t("You will receive")}
                                </Typography>
                              )}
                              {/* receive end */}

                              <Typography
                                component="h1"
                                variant="h5"
                                sx={{
                                  fontFamily: "Helvetica, sans-serif",
                                  fontSize: "0.875rem",
                                  fontWeight: "600",
                                  color: "#454745",
                                }}
                              >
                                {transactionsService.formatNumberCrypto(
                                  transactionDetails?.crypto_amount_sent
                                )}{" "}
                                {transactionDetails?.currency_target?.code}
                              </Typography>
                            </div>
                            <div className="line-dashed"></div>
                            <div className="d-flex ai-center jusifyContentSpaceBetween">
                              <Typography
                                component="h1"
                                variant="h5"
                                sx={{
                                  fontFamily: "Helvetica, sans-serif",
                                  fontSize: "0.875rem",
                                  fontWeight: "200",
                                  color: "#454745",
                                }}
                              >
                                {i18next.t("Transaction number")}
                              </Typography>
                              <Typography
                                component="h1"
                                variant="h5"
                                sx={{
                                  fontFamily: "Helvetica, sans-serif",
                                  fontSize: "0.875rem",
                                  fontWeight: "200",
                                  color: "#454745",
                                }}
                              >
                                #T{transactionDetails?.id}
                              </Typography>
                            </div>
                          </div>

                          <div className="new-bank-details-container">
                            {/* another bank details start */}
                            {receivedText === "up" && (
                              <div>
                                <Typography
                                  component="h1"
                                  variant="h5"
                                  sx={{
                                    fontFamily: "Helvetica, sans-serif",
                                    fontSize: "0.875rem",
                                    fontWeight: "600",
                                    color: "#454745",
                                    marginBottom: "0.5rem",
                                  }}
                                >
                                  {i18next.t("Recipient wallet address")}
                                </Typography>

                                {transactionData ? (
                                  <div className="d-flex ai-center jusifyContentSpaceBetween mb-0-5rem">
                                    <Typography
                                      component="h1"
                                      variant="h5"
                                      sx={{
                                        fontFamily: "Helvetica, sans-serif",
                                        fontSize: "0.875rem",
                                        fontWeight: "200",
                                        color: "#454745",
                                        wordBreak: "break-word",
                                      }}
                                    >
                                      {transactionData?.to}
                                    </Typography>
                                  </div>
                                ) : (
                                  ""
                                )}
                              </div>
                            )}

                            <div
                              className="d-flex ai-center mb-1rem"
                              style={{
                                marginTop:
                                  receivedText !== "up" ? "0rem" : "2rem",
                              }}
                            >
                              <Typography
                                component="h1"
                                variant="h5"
                                sx={{
                                  fontFamily: "Helvetica, sans-serif",
                                  fontSize: "1.125rem",
                                  fontWeight: "600",
                                  color: "#454745",
                                  // marginBottom: "1rem",
                                  // marginTop:
                                  //   receivedText !== "up" ? "0rem" : "2rem",
                                }}
                              >
                                {i18next.t("Note")}
                              </Typography>
                              <Tooltip title={i18next.t("e.g. lunch with client")}>
                                <HelpOutlineIcon
                                  sx={{ marginLeft: "8px" }}
                                  style={{ color: "#163300" }}
                                />
                              </Tooltip>
                            </div>

                            <TextField
                              value={notes === "null" ? "" : notes}
                              onChange={handleNotesChange}
                              onBlur={handleBlur}
                              aria-label="maximum height"
                              placeholder={i18next.t(
                                "Add a few notes to help you later"
                              )}
                              InputProps={{
                                rows: 2,
                                multiline: true,
                                inputComponent: "textarea",
                                style: {
                                  borderRadius: "10px",
                                  fontFamily: "Helvetica, sans-serif",
                                  fontSize: "0.9rem",
                                  width: "100%",
                                  resize: "none",
                                  paddingTop: "1rem",
                                  paddingLeft: "1rem",
                                },
                              }}
                              style={{
                                fontFamily: "Helvetica, sans-serif",
                                fontSize: "0.9rem",
                                width: "100%",
                                resize: "none",
                                paddingTop: "1rem",
                                borderRadius: "10px",
                              }}
                              variant="outlined" // or any other variant you prefer
                            />

                            <Typography
                              component="h1"
                              variant="h5"
                              sx={{
                                fontFamily: "Helvetica, sans-serif",
                                fontSize: "1.125rem",
                                fontWeight: "600",
                                color: "#454745",
                                marginBottom: "1rem",
                                marginTop: "2rem",
                              }}
                            >
                              {i18next.t("Attachment")}
                            </Typography>
                            <div
                              className="upload-container"
                              onClick={handleFileChange}
                            >
                              {selectedFile ? (
                                <div className="d-flex ai-center">
                                  <CheckCircleOutlineIcon className="check-icon" />

                                  <div className="ml-1rem">
                                    <Typography
                                      component="h1"
                                      variant="h5"
                                      sx={{
                                        fontFamily: "Helvetica, sans-serif",
                                        fontSize: "0.875rem",
                                        fontWeight: "200",
                                        color: "#454745",
                                        marginBottom: "1rem",
                                      }}
                                    >
                                      {truncateFileName(selectedFile.name, 30)}
                                    </Typography>

                                    <Typography
                                      component="h1"
                                      variant="h5"
                                      sx={{
                                        fontFamily: "Helvetica, sans-serif",
                                        fontSize: "0.875rem",
                                        fontWeight: "600",
                                        color: "#454745",
                                      }}
                                    >
                                      {i18next.t("Uploaded")}
                                    </Typography>
                                  </div>
                                  <HighlightOffIcon
                                    onClick={handleRemoveFileClickStart}
                                    className="ml-3rem icon-hover-effect"
                                    style={{ width: "18px" }}
                                  />
                                </div>
                              ) : (
                                <div
                                  className="d-flex ai-center"
                                  onClick={() => {
                                    const fileInput =
                                      document.getElementById("file-input");
                                    if (fileInput) fileInput.click();
                                  }}
                                >
                                  <PublishIcon style={{ color: "#163300" }} />
                                  <div className="ml-1rem">
                                    <Typography
                                      component="h1"
                                      variant="h5"
                                      sx={{
                                        fontFamily: "Helvetica, sans-serif",
                                        fontSize: "1.125rem",
                                        fontWeight: "400",
                                        color: "#454745",
                                        marginBottom: "1rem",
                                        textDecoration: "underline",
                                      }}
                                    >
                                      {i18next.t("Upload file")}
                                    </Typography>
                                    <Typography
                                      component="h1"
                                      variant="h5"
                                      sx={{
                                        fontFamily: "Helvetica, sans-serif",
                                        fontSize: "0.875rem",
                                        fontWeight: "200",
                                        color: "#454745",
                                      }}
                                    >
                                      {i18next.t(
                                        "PDF, JPG, JPEG, PNG less than 10MB"
                                      )}
                                    </Typography>
                                  </div>
                                </div>
                              )}
                              <input
                                type="file"
                                id="file-input"
                                style={{ display: "none" }}
                                onChange={handleFileChange}
                                accept="application/pdf, image/jpeg, image/png"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </Box>
              </Box>
            )}
          </Container>
        </ThemeProvider>
      </div>
    </div>
  );
}

export default TransactionDetailCrypto;
