import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import "../Users.scss";
import Nav from "src/components/layout/nav/Nav";
import { Box, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import LoadingIndicator from "src/components/shared/LoadingIndicator";
import usersService from "src/store/users/users_services";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { formatAccountNumber } from "src/utils/helpers";
import Tooltip from "@mui/material/Tooltip";
import UserVerification from "src/components/Users/UserDetails/UserVerification";

const defaultTheme = createTheme();

export default function VerificationFiles() {
  const { userId }: any = useParams();
  const [userData, setUserData] = useState<any>([]);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();
  const location = useLocation();

  const handleGetUser = async () => {
    setIsLoading(true);

    const response = await usersService.getUserById(userId);
    if (response) {
      const { data } = response;
      setIsLoading(false);
      if (data) {
        setUserData(data);
      }
    }
  };

  const getStatusColor = (status: string) => {
    switch (status) {
      case "created":
        return "rgb(255, 68, 68)";
      case "transaction_created":
        return "rgb(255, 153, 0)";
      case "target_reached":
        return "rgb(34, 197, 94)";
      default:
        return "red"; // Default color if status is unknown
    }
  };

  const handleNavigate = () => {
    // If the current path is exactly matching the user detail view
    // if (location.pathname === `/admin/users/${userId}`) {
    navigate("/admin/users/");
    // } else {
    //   navigate(-1);
    // }
  };

  useEffect(() => {
    handleGetUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <Nav step={0} isStepperVisible={false} isProfileSetVisible={true} />
      <div className="main-admin-container">
        <div className="content-container-profile main-color">
          <ThemeProvider theme={defaultTheme}>
            <Container component="main">
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Box sx={{ flex: 1 }}>
                  <Typography
                    className="mb-1rem  d-flex jc-start FontSize1275"
                    component="h1"
                    variant="h5"
                    sx={{
                      justifyContent: "flex-end",
                      fontFamily: "Helvetica, sans-serif",
                      fontWeight: 600,
                      color: "#000000",
                    }}
                  >
                    Account number:{" "}
                    {userData && userData.id
                      ? formatAccountNumber(userData.id)
                      : ""}
                  </Typography>
                </Box>
                <div className="d-flex ai-center mb-2-rem">
                  <p
                    className="back-button-icon circle-around-text-smaller helvetica mr-1-rem cp"
                    onClick={handleNavigate}
                  >
                    <ArrowBackIcon className="muiIcons" />
                  </p>
                  <Typography
                    className="your-details-text d-flex jc-start pageTitleFontSize"
                    component="h1"
                    variant="h5"
                    sx={{
                      fontFamily: "Helvetica, sans-serif",
                      textAlign: "center",
                      fontWeight: "600",
                    }}
                  >
                    {userData &&
                    userData.company_name &&
                    userData.account_type === "business"
                      ? userData.company_name
                      : userData.first_name && userData.last_name
                      ? userData.first_name + " " + userData.last_name
                      : "User"}

                    <div className="acc-status ml-8">
                      <Tooltip title={userData?.account_status || "Created"}>
                        <span
                          style={{
                            height: "15px",
                            width: "15px",
                            backgroundColor: getStatusColor(
                              userData?.account_status
                            ),
                            borderRadius: "50%",
                            display: "inline-block",
                            marginRight: "5px", // Adjust spacing as needed
                          }}
                        />
                      </Tooltip>
                    </div>
                  </Typography>
                </div>
                <div>
                  {isLoading ? (
                    <LoadingIndicator />
                  ) : (
                    <div>
                      <UserVerification data={userData} />
                    </div>
                  )}
                </div>
              </Box>
            </Container>
          </ThemeProvider>
        </div>
      </div>
    </div>
  );
}
