import { Box, Button, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import api from "src/store/interceptors/api";
import FilePreview from "../../shared/FilePreview";
import { useParams } from "react-router-dom";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { acceptableProofOfId, businessFiles, collectedFor, companyFiles, personalFiles, proofOfAddress, scannedFiles } from "src/utils/helpers";
import profileService from "src/store/profile/profile_service";
import { VisuallyHiddenInput } from "../../shared/VisuallyHiddenInput";
import UserDetailsTabs from "../../UserDetailsTabs/UserDetailsTabs";
import UserBankingDetails from "./UserBankingDetails";

function User({ data }: any) {
  const [user, setUser] = useState(data);
  const [loggedUser, setLoggedUser] = useState("");
  const [selectedFiles, setSelectedFiles] = useState<any>({
    frontID: null,
    idBack: null,
    passport: null,
    personalPhoto: null,
    aml: null,
    companyRegistration: null,
    businessLicence: null,
    businessAddress: null,
    sof: null,
    ubo: null,
    tin: null,
  });

  const userProfileObject: any = { ...user };
  const { userId } = useParams();

  const handleFileRemove = (id: number, kycItem: any) => {
    return async () => {
      const res = await profileService.adminRemoveVerificationPhoto(userId, id);
      if (res && res.data) {
        setSelectedFiles((prev: any) => ({
          ...prev,
          [kycItem.file]: null,
        }));
        setUser(res.data);
      }
    };
  };

  const handleFileUpload = async (
    event: React.ChangeEvent<HTMLInputElement>,
    kycItem: any
  ) => {
    if (event.target.files && event.target.files.length > 0) {
      const file = event.target.files[0];
      setSelectedFiles((prev: any) => ({
        ...prev,
        [kycItem.file]: { file: file, preview: URL.createObjectURL(file) },
      }));
      let res = await profileService.kycUploadFile(userId, file, kycItem.file);

      if (res && res.data) {
        setUser(res.data);
      }
    }
  };

  const getLoggedUserRole = async () => {
    try {
      const response = await api.get(`me`);
      const loggedUserRole =
        response && response.data && response.data.data.role;
      if (loggedUserRole) {
        setLoggedUser(loggedUserRole);
      }
    } catch (exception) {
      console.log(exception);
    }
  };

  useEffect(() => {
    getLoggedUserRole();
  }, [loggedUser]);

  useEffect(() => {
    setUser(data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return (
    <Box className="recentBox">
      {!user ? (
        <Typography className="noDataText">No user found</Typography>
      ) : (
        <Box>
          <UserDetailsTabs user={user} />
          <Box sx={{ mt: 2 }}>
            {/* <div className="d-flex ai-center mb-3-rem">
              <Typography
                className="your-details-text d-flex jc-start"
                component="h1"
                variant="h5"
                sx={{
                  fontFamily: "Helvetica, sans-serif",
                  fontSize: "1.875rem",
                  textAlign: "center",
                  fontWeight: "600",
                }}
              >
                {user &&
                user.role &&
                (user.role === "Admin" || user.role === "Employee")
                  ? "Account details"
                  : "Your details"}
              </Typography>
            </div> */}

            <Box sx={{ mt: 2 }}>
              <UserBankingDetails user={user} loggedUser={loggedUser} path="admin" />
              {user && user.account_type === "business" && (
                <div>
                  {/* Verification start */}
                  <Typography
                    className="mb-1rem  d-flex jc-start pageTitleFontSize"
                    component="h1"
                    variant="h5"
                    sx={{
                      fontFamily: "Helvetica, sans-serif",
                      fontWeight: "600",
                      marginTop: "2rem",
                    }}
                  >
                    Business verification files
                  </Typography>

                  <div className="personal-box mb-big">
                    {businessFiles.map((kycItem, kycIndex) => (
                      <div
                        key={"kyc" + kycIndex}
                        className="one-flex-line"
                        style={{ paddingTop: 10, paddingBottom: 10 }}
                      >
                        <div className="verification-box">
                          <Typography
                            className="mb-1rem  d-flex jc-start registration0875RemFontSize"
                            component="h1"
                            variant="h5"
                            sx={{
                              fontFamily: "Helvetica, sans-serif",
                              textDecoration: "underline",
                              cursor: "pointer",
                            }}
                          >
                            {kycItem.title} {kycItem.required ? "*" : ""}
                          </Typography>

                          <Button
                            sx={{
                              marginBottom: 2,
                              textTransform: "none",
                              "&:hover": {
                                backgroundColor: "#2A5182",
                                color: "white",
                              },
                            }}
                            className="btnsLoginHeight oneRemFontSize"
                            component="label"
                            role={undefined}
                            variant="contained"
                            tabIndex={-1}
                            startIcon={<CloudUploadIcon className="muiIcons" />}
                          >
                            {userProfileObject[kycItem.file] &&
                            userProfileObject[kycItem.file].file_url
                              ? "Change file"
                              : "Upload file"}
                            <VisuallyHiddenInput
                              onChange={(e) => handleFileUpload(e, kycItem)}
                              type="file"
                            />
                          </Button>

                          {userProfileObject[kycItem.file] &&
                            userProfileObject[kycItem.file].file_url && (
                              <Button
                                className="btnsLoginHeight oneRemFontSize"
                                sx={{
                                  marginLeft: 2,
                                  marginBottom: 2,
                                  textTransform: "none",
                                  "&:hover": {
                                    backgroundColor: "#2A5182",
                                    color: "white",
                                  },
                                }}
                                component="label"
                                onClick={handleFileRemove(
                                  userProfileObject[kycItem.id],
                                  kycItem
                                )}
                                role={undefined}
                                variant="contained"
                                tabIndex={-1}
                                startIcon={
                                  <HighlightOffIcon className="muiIcons" />
                                }
                              >
                                Remove file
                              </Button>
                            )}

                          {selectedFiles &&
                          selectedFiles[kycItem.file] &&
                          selectedFiles[kycItem.file].preview ? (
                            <FilePreview
                              src={selectedFiles[kycItem.file].preview}
                              width="90%"
                              file={selectedFiles[kycItem.file].file}
                              alt={kycItem.title}
                            />
                          ) : userProfileObject[kycItem.file]?.file_url ? (
                            <FilePreview
                              src={
                                selectedFiles[kycItem.file]?.preview ??
                                userProfileObject[kycItem.file].file_url
                              }
                              width="90%"
                              file={
                                selectedFiles[kycItem.file]?.file ??
                                userProfileObject[kycItem.file]
                              }
                              alt={kycItem.title}
                            />
                          ) : (
                            <Typography
                              className="mb-1rem  d-flex jc-start registration0875RemFontSize"
                              component="h1"
                              variant="h5"
                              sx={{
                                fontFamily: "Helvetica, sans-serif",
                                fontWeight: 400, // Adjusted this to a numerical value
                                color: "#454745",
                              }}
                            >
                              {"No " + kycItem.title}
                            </Typography>
                          )}
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              )}
              {/* Verification start */}
              
              <Typography
                className="mb-1rem  d-flex jc-start loginTitleFontSize"
                component="h1"
                variant="h5"
                sx={{
                  fontFamily: "Helvetica, sans-serif",
                  fontWeight: "600",
                  marginTop: "2rem",
                }}
              >
                Business Onboarding
              </Typography>

              <div className="personal-box mb-big">
                {acceptableProofOfId.map((kycPersonalItem, kycPersonalIndex) => (
                  <div
                    key={"kyc" + kycPersonalIndex}
                    className="one-flex-line"
                    style={{ paddingTop: 10, paddingBottom: 10 }}
                  >
                    <div className="verification-box">
                      <Typography
                        className="mb-1rem  d-flex jc-start registration0875RemFontSize"
                        component="h1"
                        variant="h5"
                        sx={{
                          fontFamily: "Helvetica, sans-serif",
                          textDecoration: "underline",
                          cursor: "pointer",
                        }}
                      >
                        {kycPersonalItem.title}{" "}
                        {kycPersonalItem.required ? "*" : ""}
                      </Typography>

                      <Button
                        className="btnsLoginHeight oneRemFontSize"
                        sx={{
                          marginBottom: 2,
                          textTransform: "none",
                          "&:hover": {
                            backgroundColor: "#2A5182",
                            color: "white",
                          },
                        }}
                        component="label"
                        role={undefined}
                        variant="contained"
                        tabIndex={-1}
                        startIcon={<CloudUploadIcon className="muiIcons" />}
                      >
                        {userProfileObject[kycPersonalItem.file] &&
                        userProfileObject[kycPersonalItem.file].file_url
                          ? "Change file"
                          : "Upload file"}
                        <VisuallyHiddenInput
                          onChange={(e) => handleFileUpload(e, kycPersonalItem)}
                          type="file"
                        />
                      </Button>

                      {userProfileObject[kycPersonalItem.file] &&
                        userProfileObject[kycPersonalItem.file].file_url && (
                          <Button
                            className="btnsLoginHeight oneRemFontSize"
                            sx={{
                              marginLeft: 2,
                              marginBottom: 2,
                              textTransform: "none",
                              "&:hover": {
                                backgroundColor: "#2A5182",
                                color: "white",
                              },
                            }}
                            component="label"
                            onClick={handleFileRemove(
                              userProfileObject[kycPersonalItem.id],
                              kycPersonalItem
                            )}
                            role={undefined}
                            variant="contained"
                            tabIndex={-1}
                            startIcon={
                              <HighlightOffIcon className="muiIcons" />
                            }
                          >
                            Remove file
                          </Button>
                        )}

                      {selectedFiles &&
                      selectedFiles[kycPersonalItem.file] &&
                      selectedFiles[kycPersonalItem.file].preview ? (
                        <FilePreview
                          src={selectedFiles[kycPersonalItem.file].preview}
                          width="90%"
                          file={selectedFiles[kycPersonalItem.file].file}
                          alt={kycPersonalItem.title}
                        />
                      ) : userProfileObject[kycPersonalItem.file]?.file_url ? (
                        <FilePreview
                          src={
                            selectedFiles[kycPersonalItem.file]?.preview ??
                            userProfileObject[kycPersonalItem.file].file_url
                          }
                          width="90%"
                          file={
                            selectedFiles[kycPersonalItem.file]?.file ??
                            userProfileObject[kycPersonalItem.file]
                          }
                          alt={kycPersonalItem.title}
                        />
                      ) : (
                        <Typography
                          className="mb-1rem  d-flex jc-start registration0875RemFontSize"
                          component="h1"
                          variant="h5"
                          sx={{
                            fontFamily: "Helvetica, sans-serif",
                            fontWeight: 400, // Adjusted this to a numerical value
                            color: "#454745",
                          }}
                        >
                          {"No " + kycPersonalItem.title}
                        </Typography>
                      )}
                    </div>
                  </div>
                ))}
              </div>


              <div className="personal-box mb-big">
                {collectedFor.map((kycPersonalItem, kycPersonalIndex) => (
                  <div
                    key={"kyc" + kycPersonalIndex}
                    className="one-flex-line"
                    style={{ paddingTop: 10, paddingBottom: 10 }}
                  >
                    <div className="verification-box">
                      <Typography
                        className="mb-1rem  d-flex jc-start registration0875RemFontSize"
                        component="h1"
                        variant="h5"
                        sx={{
                          fontFamily: "Helvetica, sans-serif",
                          textDecoration: "underline",
                          cursor: "pointer",
                        }}
                      >
                        {kycPersonalItem.title}{" "}
                        {kycPersonalItem.required ? "*" : ""}
                      </Typography>

                      <Button
                        className="btnsLoginHeight oneRemFontSize"
                        sx={{
                          marginBottom: 2,
                          textTransform: "none",
                          "&:hover": {
                            backgroundColor: "#2A5182",
                            color: "white",
                          },
                        }}
                        component="label"
                        role={undefined}
                        variant="contained"
                        tabIndex={-1}
                        startIcon={<CloudUploadIcon className="muiIcons" />}
                      >
                        {userProfileObject[kycPersonalItem.file] &&
                        userProfileObject[kycPersonalItem.file].file_url
                          ? "Change file"
                          : "Upload file"}
                        <VisuallyHiddenInput
                          onChange={(e) => handleFileUpload(e, kycPersonalItem)}
                          type="file"
                        />
                      </Button>

                      {userProfileObject[kycPersonalItem.file] &&
                        userProfileObject[kycPersonalItem.file].file_url && (
                          <Button
                            className="btnsLoginHeight oneRemFontSize"
                            sx={{
                              marginLeft: 2,
                              marginBottom: 2,
                              textTransform: "none",
                              "&:hover": {
                                backgroundColor: "#2A5182",
                                color: "white",
                              },
                            }}
                            component="label"
                            onClick={handleFileRemove(
                              userProfileObject[kycPersonalItem.id],
                              kycPersonalItem
                            )}
                            role={undefined}
                            variant="contained"
                            tabIndex={-1}
                            startIcon={
                              <HighlightOffIcon className="muiIcons" />
                            }
                          >
                            Remove file
                          </Button>
                        )}

                      {selectedFiles &&
                      selectedFiles[kycPersonalItem.file] &&
                      selectedFiles[kycPersonalItem.file].preview ? (
                        <FilePreview
                          src={selectedFiles[kycPersonalItem.file].preview}
                          width="90%"
                          file={selectedFiles[kycPersonalItem.file].file}
                          alt={kycPersonalItem.title}
                        />
                      ) : userProfileObject[kycPersonalItem.file]?.file_url ? (
                        <FilePreview
                          src={
                            selectedFiles[kycPersonalItem.file]?.preview ??
                            userProfileObject[kycPersonalItem.file].file_url
                          }
                          width="90%"
                          file={
                            selectedFiles[kycPersonalItem.file]?.file ??
                            userProfileObject[kycPersonalItem.file]
                          }
                          alt={kycPersonalItem.title}
                        />
                      ) : (
                        <Typography
                          className="mb-1rem  d-flex jc-start registration0875RemFontSize"
                          component="h1"
                          variant="h5"
                          sx={{
                            fontFamily: "Helvetica, sans-serif",
                            fontWeight: 400, // Adjusted this to a numerical value
                            color: "#454745",
                          }}
                        >
                          {"No " + kycPersonalItem.title}
                        </Typography>
                      )}
                    </div>
                  </div>
                ))}
              </div>

              <div className="personal-box mb-big">
                {proofOfAddress.map((kycPersonalItem, kycPersonalIndex) => (
                  <div
                    key={"kyc" + kycPersonalIndex}
                    className="one-flex-line"
                    style={{ paddingTop: 10, paddingBottom: 10 }}
                  >
                    <div className="verification-box">
                      <Typography
                        className="mb-1rem  d-flex jc-start registration0875RemFontSize"
                        component="h1"
                        variant="h5"
                        sx={{
                          fontFamily: "Helvetica, sans-serif",
                          textDecoration: "underline",
                          cursor: "pointer",
                        }}
                      >
                        {kycPersonalItem.title}{" "}
                        {kycPersonalItem.required ? "*" : ""}
                      </Typography>

                      <Button
                        className="btnsLoginHeight oneRemFontSize"
                        sx={{
                          marginBottom: 2,
                          textTransform: "none",
                          "&:hover": {
                            backgroundColor: "#2A5182",
                            color: "white",
                          },
                        }}
                        component="label"
                        role={undefined}
                        variant="contained"
                        tabIndex={-1}
                        startIcon={<CloudUploadIcon className="muiIcons" />}
                      >
                        {userProfileObject[kycPersonalItem.file] &&
                        userProfileObject[kycPersonalItem.file].file_url
                          ? "Change file"
                          : "Upload file"}
                        <VisuallyHiddenInput
                          onChange={(e) => handleFileUpload(e, kycPersonalItem)}
                          type="file"
                        />
                      </Button>

                      {userProfileObject[kycPersonalItem.file] &&
                        userProfileObject[kycPersonalItem.file].file_url && (
                          <Button
                            className="btnsLoginHeight oneRemFontSize"
                            sx={{
                              marginLeft: 2,
                              marginBottom: 2,
                              textTransform: "none",
                              "&:hover": {
                                backgroundColor: "#2A5182",
                                color: "white",
                              },
                            }}
                            component="label"
                            onClick={handleFileRemove(
                              userProfileObject[kycPersonalItem.id],
                              kycPersonalItem
                            )}
                            role={undefined}
                            variant="contained"
                            tabIndex={-1}
                            startIcon={
                              <HighlightOffIcon className="muiIcons" />
                            }
                          >
                            Remove file
                          </Button>
                        )}

                      {selectedFiles &&
                      selectedFiles[kycPersonalItem.file] &&
                      selectedFiles[kycPersonalItem.file].preview ? (
                        <FilePreview
                          src={selectedFiles[kycPersonalItem.file].preview}
                          width="90%"
                          file={selectedFiles[kycPersonalItem.file].file}
                          alt={kycPersonalItem.title}
                        />
                      ) : userProfileObject[kycPersonalItem.file]?.file_url ? (
                        <FilePreview
                          src={
                            selectedFiles[kycPersonalItem.file]?.preview ??
                            userProfileObject[kycPersonalItem.file].file_url
                          }
                          width="90%"
                          file={
                            selectedFiles[kycPersonalItem.file]?.file ??
                            userProfileObject[kycPersonalItem.file]
                          }
                          alt={kycPersonalItem.title}
                        />
                      ) : (
                        <Typography
                          className="mb-1rem  d-flex jc-start registration0875RemFontSize"
                          component="h1"
                          variant="h5"
                          sx={{
                            fontFamily: "Helvetica, sans-serif",
                            fontWeight: 400, // Adjusted this to a numerical value
                            color: "#454745",
                          }}
                        >
                          {"No " + kycPersonalItem.title}
                        </Typography>
                      )}
                    </div>
                  </div>
                ))}
              </div>

              <div className="personal-box mb-big">
                {companyFiles.map((kycPersonalItem, kycPersonalIndex) => (
                  <div
                    key={"kyc" + kycPersonalIndex}
                    className="one-flex-line"
                    style={{ paddingTop: 10, paddingBottom: 10 }}
                  >
                    <div className="verification-box">
                      <Typography
                        className="mb-1rem  d-flex jc-start registration0875RemFontSize"
                        component="h1"
                        variant="h5"
                        sx={{
                          fontFamily: "Helvetica, sans-serif",
                          textDecoration: "underline",
                          cursor: "pointer",
                        }}
                      >
                        {kycPersonalItem.title}{" "}
                        {kycPersonalItem.required ? "*" : ""}
                      </Typography>

                      <Button
                        className="btnsLoginHeight oneRemFontSize"
                        sx={{
                          marginBottom: 2,
                          textTransform: "none",
                          "&:hover": {
                            backgroundColor: "#2A5182",
                            color: "white",
                          },
                        }}
                        component="label"
                        role={undefined}
                        variant="contained"
                        tabIndex={-1}
                        startIcon={<CloudUploadIcon className="muiIcons" />}
                      >
                        {userProfileObject[kycPersonalItem.file] &&
                        userProfileObject[kycPersonalItem.file].file_url
                          ? "Change file"
                          : "Upload file"}
                        <VisuallyHiddenInput
                          onChange={(e) => handleFileUpload(e, kycPersonalItem)}
                          type="file"
                        />
                      </Button>

                      {userProfileObject[kycPersonalItem.file] &&
                        userProfileObject[kycPersonalItem.file].file_url && (
                          <Button
                            className="btnsLoginHeight oneRemFontSize"
                            sx={{
                              marginLeft: 2,
                              marginBottom: 2,
                              textTransform: "none",
                              "&:hover": {
                                backgroundColor: "#2A5182",
                                color: "white",
                              },
                            }}
                            component="label"
                            onClick={handleFileRemove(
                              userProfileObject[kycPersonalItem.id],
                              kycPersonalItem
                            )}
                            role={undefined}
                            variant="contained"
                            tabIndex={-1}
                            startIcon={
                              <HighlightOffIcon className="muiIcons" />
                            }
                          >
                            Remove file
                          </Button>
                        )}

                      {selectedFiles &&
                      selectedFiles[kycPersonalItem.file] &&
                      selectedFiles[kycPersonalItem.file].preview ? (
                        <FilePreview
                          src={selectedFiles[kycPersonalItem.file].preview}
                          width="90%"
                          file={selectedFiles[kycPersonalItem.file].file}
                          alt={kycPersonalItem.title}
                        />
                      ) : userProfileObject[kycPersonalItem.file]?.file_url ? (
                        <FilePreview
                          src={
                            selectedFiles[kycPersonalItem.file]?.preview ??
                            userProfileObject[kycPersonalItem.file].file_url
                          }
                          width="90%"
                          file={
                            selectedFiles[kycPersonalItem.file]?.file ??
                            userProfileObject[kycPersonalItem.file]
                          }
                          alt={kycPersonalItem.title}
                        />
                      ) : (
                        <Typography
                          className="mb-1rem  d-flex jc-start registration0875RemFontSize"
                          component="h1"
                          variant="h5"
                          sx={{
                            fontFamily: "Helvetica, sans-serif",
                            fontWeight: 400, // Adjusted this to a numerical value
                            color: "#454745",
                          }}
                        >
                          {"No " + kycPersonalItem.title}
                        </Typography>
                      )}
                    </div>
                  </div>
                ))}
              </div>

              <Typography
                className="mb-1rem  d-flex jc-start loginTitleFontSize"
                component="h1"
                variant="h5"
                sx={{
                  fontFamily: "Helvetica, sans-serif",
                  fontWeight: "600",
                  marginTop: "2rem",
                }}
              >
                Personal verification files
              </Typography>

              <div className="personal-box mb-big">
                {personalFiles.map((kycPersonalItem, kycPersonalIndex) => (
                  <div
                    key={"kyc" + kycPersonalIndex}
                    className="one-flex-line"
                    style={{ paddingTop: 10, paddingBottom: 10 }}
                  >
                    <div className="verification-box">
                      <Typography
                        className="mb-1rem  d-flex jc-start registration0875RemFontSize"
                        component="h1"
                        variant="h5"
                        sx={{
                          fontFamily: "Helvetica, sans-serif",
                          textDecoration: "underline",
                          cursor: "pointer",
                        }}
                      >
                        {kycPersonalItem.title}{" "}
                        {kycPersonalItem.required ? "*" : ""}
                      </Typography>

                      <Button
                        className="btnsLoginHeight oneRemFontSize"
                        sx={{
                          marginBottom: 2,
                          textTransform: "none",
                          "&:hover": {
                            backgroundColor: "#2A5182",
                            color: "white",
                          },
                        }}
                        component="label"
                        role={undefined}
                        variant="contained"
                        tabIndex={-1}
                        startIcon={<CloudUploadIcon className="muiIcons" />}
                      >
                        {userProfileObject[kycPersonalItem.file] &&
                        userProfileObject[kycPersonalItem.file].file_url
                          ? "Change file"
                          : "Upload file"}
                        <VisuallyHiddenInput
                          onChange={(e) => handleFileUpload(e, kycPersonalItem)}
                          type="file"
                        />
                      </Button>

                      {userProfileObject[kycPersonalItem.file] &&
                        userProfileObject[kycPersonalItem.file].file_url && (
                          <Button
                            className="btnsLoginHeight oneRemFontSize"
                            sx={{
                              marginLeft: 2,
                              marginBottom: 2,
                              textTransform: "none",
                              "&:hover": {
                                backgroundColor: "#2A5182",
                                color: "white",
                              },
                            }}
                            component="label"
                            onClick={handleFileRemove(
                              userProfileObject[kycPersonalItem.id],
                              kycPersonalItem
                            )}
                            role={undefined}
                            variant="contained"
                            tabIndex={-1}
                            startIcon={
                              <HighlightOffIcon className="muiIcons" />
                            }
                          >
                            Remove file
                          </Button>
                        )}

                      {selectedFiles &&
                      selectedFiles[kycPersonalItem.file] &&
                      selectedFiles[kycPersonalItem.file].preview ? (
                        <FilePreview
                          src={selectedFiles[kycPersonalItem.file].preview}
                          width="90%"
                          file={selectedFiles[kycPersonalItem.file].file}
                          alt={kycPersonalItem.title}
                        />
                      ) : userProfileObject[kycPersonalItem.file]?.file_url ? (
                        <FilePreview
                          src={
                            selectedFiles[kycPersonalItem.file]?.preview ??
                            userProfileObject[kycPersonalItem.file].file_url
                          }
                          width="90%"
                          file={
                            selectedFiles[kycPersonalItem.file]?.file ??
                            userProfileObject[kycPersonalItem.file]
                          }
                          alt={kycPersonalItem.title}
                        />
                      ) : (
                        <Typography
                          className="mb-1rem  d-flex jc-start registration0875RemFontSize"
                          component="h1"
                          variant="h5"
                          sx={{
                            fontFamily: "Helvetica, sans-serif",
                            fontWeight: 400, // Adjusted this to a numerical value
                            color: "#454745",
                          }}
                        >
                          {"No " + kycPersonalItem.title}
                        </Typography>
                      )}
                    </div>
                  </div>
                ))}
              </div>

              <Typography
                className="mb-1rem  d-flex jc-start loginTitleFontSize"
                component="h1"
                variant="h5"
                sx={{
                  fontFamily: "Helvetica, sans-serif",
                  fontWeight: "600",
                  marginTop: "2rem",
                }}
              >
                Scan files
              </Typography>

              <div className="personal-box mb-big">
                {scannedFiles.map((kycPersonalItem, kycPersonalIndex) => (
                  <div
                    key={"kycscan" + kycPersonalIndex}
                    className="one-flex-line"
                    style={{ paddingTop: 10, paddingBottom: 10 }}
                  >
                    <div className="verification-box">
                      <Typography
                        className="mb-1rem  d-flex jc-start registration0875RemFontSize"
                        component="h1"
                        variant="h5"
                        sx={{
                          fontFamily: "Helvetica, sans-serif",
                          textDecoration: "underline",
                          cursor: "pointer",
                        }}
                      >
                        {kycPersonalItem.title}{" "}
                        {kycPersonalItem.required ? "*" : ""}
                      </Typography>

                      <Button
                        className="btnsLoginHeight oneRemFontSize"
                        sx={{
                          marginBottom: 2,
                          textTransform: "none",
                          "&:hover": {
                            backgroundColor: "#2A5182",
                            color: "white",
                          },
                        }}
                        component="label"
                        role={undefined}
                        variant="contained"
                        tabIndex={-1}
                        startIcon={<CloudUploadIcon className="muiIcons" />}
                      >
                        {userProfileObject[kycPersonalItem.file] &&
                        userProfileObject[kycPersonalItem.file].file_url
                          ? "Change file"
                          : "Upload file"}
                        <VisuallyHiddenInput
                          onChange={(e) => handleFileUpload(e, kycPersonalItem)}
                          type="file"
                        />
                      </Button>

                      {userProfileObject[kycPersonalItem.file] &&
                        userProfileObject[kycPersonalItem.file].file_url && (
                          <Button
                            className="btnsLoginHeight oneRemFontSize"
                            sx={{
                              marginLeft: 2,
                              marginBottom: 2,
                              textTransform: "none",
                              "&:hover": {
                                backgroundColor: "#2A5182",
                                color: "white",
                              },
                            }}
                            component="label"
                            onClick={handleFileRemove(
                              userProfileObject[kycPersonalItem.id].id,
                              kycPersonalItem
                            )}
                            role={undefined}
                            variant="contained"
                            tabIndex={-1}
                            startIcon={<HighlightOffIcon />}
                          >
                            Remove file
                          </Button>
                        )}

                      {selectedFiles &&
                      selectedFiles[kycPersonalItem.file] &&
                      selectedFiles[kycPersonalItem.file].preview ? (
                        <FilePreview
                          src={selectedFiles[kycPersonalItem.file].preview}
                          width="90%"
                          file={selectedFiles[kycPersonalItem.file].file}
                          alt={kycPersonalItem.title}
                        />
                      ) : userProfileObject[kycPersonalItem.file]?.file_url ? (
                        <FilePreview
                          src={
                            selectedFiles[kycPersonalItem.file]?.preview ??
                            userProfileObject[kycPersonalItem.file].file_url
                          }
                          width="90%"
                          file={
                            selectedFiles[kycPersonalItem.file]?.file ??
                            userProfileObject[kycPersonalItem.file]
                          }
                          alt={kycPersonalItem.title}
                        />
                      ) : (
                        <Typography
                          className="mb-1rem  d-flex jc-start registration0875RemFontSize"
                          component="h1"
                          variant="h5"
                          sx={{
                            fontFamily: "Helvetica, sans-serif",
                            fontWeight: 400, // Adjusted this to a numerical value
                            color: "#454745",
                          }}
                        >
                          {"No " + kycPersonalItem.title}
                        </Typography>
                      )}
                    </div>
                  </div>
                ))}
              </div>
            </Box>
          </Box>
        </Box>
      )}
    </Box>
  );
}

export default User;
