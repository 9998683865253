import { Typography } from "@mui/material";
import "./TransactionsListItem.scss";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import { useNavigate } from "react-router-dom";
import transactionsService from "src/store/transactions/transactions_service";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import AddIcon from "@mui/icons-material/Add";
import { getTransactionIcon } from "src/store/transactions/transactions_service_helper";
import i18next from "src/i18n";

interface Props {
  item: any;
  redirectPage: string | null;
}

const colorDisplay = (item: any) => {
  if (item.transaction_type && item.transaction_type === "m_transfer_out") {
    return "#000000";
    //return "#870000";
  } else if (
    item.transaction_type &&
    item.transaction_type === "m_transfer_in"
  ) {
    return "#008724";
  }
  return "#000000";
};

const detailsDisplay = (item: any) => {
  if (
    item.transaction_type &&
    item.transaction_type === "crypto_transfer_out"
  ) {
    let itemStatus = i18next.t(
      transactionsService.translateStatus(item.status)
    );
    let userName =
      item &&
      item.user &&
      item.user.company_name &&
      item.user.account_type === "business"
        ? item.user.company_name
        : item && item.user && item.user.first_name && item.user.last_name
        ? item.user.first_name + " " + item.user.last_name
        : "";
    if (userName !== "") userName = i18next.t("By") + " " + userName + ", ";
    return userName + " " + itemStatus;
  } else if (
    item.transaction_type &&
    item.transaction_type === "m_transfer_in"
  ) {
    let itemStatus = i18next.t("Balance added");
    return itemStatus;
  }
  return item.status;
};
function TransactionsListItemCrypto({ item, redirectPage }: Props) {
  const navigate = useNavigate();
  let displayText;
  const handleOnClick = () => {
    navigate(`/transaction-details/${item.id}`);
  };

  const renderIcon = (status: string, type: string) => {
    const iconType = transactionsService.getProperIconBasedOnStatusBalance(
      status,
      type
    );
    switch (iconType) {
      case "up":
        displayText = item?.recipient?.account_holder
          ? item?.recipient?.account_holder
          : i18next.t("Transaction");
        return (
          <ArrowUpwardIcon style={{ color: "#0e0f0c" }} className="muiIcons" />
        );
      case "plus":
        displayText =
          item &&
          item.user &&
          item.user.company_name &&
          item.user.account_type === "business"
            ? item.user.company_name
            : item && item.user && item.user.first_name && item.user.last_name
            ? item.user.first_name + " " + item.user.last_name
            : i18next.t("Balance");
        return <AddIcon style={{ color: "#0e0f0c" }} className="muiIcons" />;
      case "credit-card":
        displayText =
          item &&
          item.user &&
          item.user.company_name &&
          item.user.account_type === "business"
            ? item.user.company_name
            : item && item.user && item.user.first_name && item.user.last_name
            ? item.user.first_name + " " + item.user.last_name
            : i18next.t("Balance");
        return (
          <CreditCardIcon style={{ color: "#0e0f0c" }} className="muiIcons" />
        );
      case "bank":
        displayText =
          item &&
          item.user &&
          item.user.company_name &&
          item.user.account_type === "business"
            ? item.user.company_name
            : item && item.user && item.user.first_name && item.user.last_name
            ? item.user.first_name + " " + item.user.last_name
            : i18next.t("Balance");
        return (
          <AccountBalanceIcon
            style={{ color: "#0e0f0c" }}
            className="muiIcons"
          />
        );

      // case "plus":
      //    displayText = `To your ${item?.meta?.targetCurrency} balance`;
      //    return <AddIcon style={{ color: "#0e0f0c" }} />;
      default:
        return (
          <ArrowUpwardIcon style={{ color: "#0e0f0c" }} className="muiIcons" />
        );
    }
  };

  return (
    <div className="transaction-item" onClick={handleOnClick}>
      <div className="d-flex ai-center jc-center">
        <p className="back-button-icon circle-around-text-smaller helvetica mr-1-rem">
          {renderIcon(item.status, item.transaction_type)}{" "}
        </p>

        <div>
          <Typography
            className="oneRemFontSize subtitleMaxWidthMob"
            component="h1"
            variant="h5"
            sx={{
              fontFamily: "Helvetica, sans-serif",
              fontWeight: "600",
              color: "#454745",
              marginBottom: "0.5rem",
            }}
          >
            {displayText}
            {/* {item?.recipient?.account_holder} */}
          </Typography>
          <Typography
            component="h1"
            className="registration0875RemFontSize subtitleMaxWidthMob"
            variant="h5"
            sx={{
              fontFamily: "Helvetica, sans-serif",
              fontWeight: "300",
              color: "#454745",
            }}
          >
            {detailsDisplay(item)}
          </Typography>
        </div>
      </div>
      <div className="ta-end d-flex ai-center">
        <div>
          <Typography
            component="h1"
            className="oneRemFontSize"
            variant="h5"
            sx={{
              fontFamily: "Helvetica, sans-serif",
              fontWeight: "600",
              color: colorDisplay(item),
              marginBottom: "0.5rem",
            }}
          >
            {getTransactionIcon(item)}
            {transactionsService.formatNumberCrypto(
              item.crypto_amount_sent
            )}{" "}
            {item?.currency?.code}
          </Typography>
          <Typography
            component="h1"
            variant="h5"
            className="registration0875RemFontSize"
            sx={{
              fontFamily: "Helvetica, sans-serif",
              fontWeight: "300",
              color: colorDisplay(item),
            }}
          >
            {item.crypto_amount_sent && parseFloat(item.crypto_amount_sent) > 0
              ? transactionsService.formatNumberCrypto(item.crypto_amount_sent)
              : transactionsService.formatNumberCrypto(
                  item?.crypto_amount_sent
                )}{" "}
          </Typography>
        </div>
      </div>
    </div>
  );
}

export default TransactionsListItemCrypto;
