import {
  Box,
  Button,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import i18next from "i18next";
import React, { useEffect, useState } from "react";
import api from "src/store/interceptors/api";

interface Props {
  formik: any;
  initialsField: any;
}
const currencies = ["EUR", "USD", "GBP"];

function EditOnBoardingForm({ formik, initialsField }: Props) {
  const [loggedUser, setLoggedUser] = useState("");

  const getLoggedUserRole = async () => {
    try {
      const response = await api.get(`me`);
      const loggedUserRole =
        response && response.data && response.data.data.role;
      if (loggedUserRole) {
        setLoggedUser(loggedUserRole);
      }
    } catch (exception) {
      console.log(exception);
    }
  };

  useEffect(() => {
    getLoggedUserRole();
  }, [loggedUser]);

  const isFieldDisabled = (field: any) => {
    if (loggedUser === "Admin") {
      return false;
    }
    if (field === "pay_in_currency" || field === "pay_out_currency") {
      if (initialsField[field].length > 0 && loggedUser !== "Admin") {
        return true;
      }
    } else {
      if (loggedUser !== "Admin" && initialsField[field] !== "") {
        return true;
      }
    }
  };
  return (
    <Box>
      <Box className="mb-big">
        <Typography
          className="pageTitleFontSize"
          component="h1"
          variant="h5"
          sx={{
            fontFamily: "Helvetica, sans-serif",
            fontWeight: "600",
            color: "#0e0f0c",
            maxWidth: "600px",
            overflow: "hidden",
          }}
        >
          {i18next.t("Money in")}
        </Typography>
        <Box className="radio-group">
          <InputLabel
            id="currency"
            className="oneRemFontSize"
            style={{
              marginBottom: "8px",
              marginTop: "8px",
              color: "#000000",
            }}
          >
            {i18next.t("IBAN needs")}
          </InputLabel>
          <RadioGroup
            className="radioGroupButton"
            aria-labelledby="demo-controlled-radio-buttons-group"
            name="iban_needs"
            value={formik.values.iban_needs}
            onChange={formik.handleChange}
          >
            <FormControlLabel
              disabled={isFieldDisabled("iban_needs")}
              value="LU"
              control={<Radio />}
              label="LU"
              sx={{
                "& .MuiTypography-root": {
                  fontSize: "14px",

                  "@media (max-width:600px)": {
                    fontSize: "14px !important",
                  },
                },
              }}
            />
            <FormControlLabel
              disabled={isFieldDisabled("iban_needs")}
              value="NL"
              control={<Radio />}
              label="NL"
              sx={{
                "& .MuiTypography-root": {
                  fontSize: "14px",

                  "@media (max-width:600px)": {
                    fontSize: "14px !important",
                  },
                },
              }}
            />

            <FormControlLabel
              disabled={isFieldDisabled("iban_needs")}
              value="GBP"
              control={<Radio />}
              label="GBP"
              sx={{
                "& .MuiTypography-root": {
                  fontSize: "14px",

                  "@media (max-width:600px)": {
                    fontSize: "14px !important",
                  },
                },
              }}
            />
          </RadioGroup>
        </Box>
        <InputLabel
          id="pay_in_currency"
          className="oneRemFontSize"
          style={{
            marginBottom: "8px",
            marginTop: "8px",
            color: "#000000",
          }}
        >
          {i18next.t("Pay-in currencies")}
        </InputLabel>
        <Box className="customInputContainer">
          <Box className="customInputSubContainer">
            <Select
              disabled={isFieldDisabled("pay_in_currency")}
              multiple={true}
              labelId="pay_in_currency"
              id="pay_in_currency"
              name="pay_in_currency"
              style={{ width: "100%" }}
              inputProps={{
                classes: {
                  notchedOutline: "customInputNoBorder",
                  input: "customInputpadding",
                },
              }}
              sx={{
                borderRadius: "16px",
                height: "55px",
                "& .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input":
                  {
                    padding: "16.5px 14px 16.5px 14px",
                  },
                "& .css-1d3z3hw-MuiOutlinedInput-notchedOutline": {
                  border: "none !important",
                },
              }}
              value={formik.values.pay_in_currency}
              onChange={formik.handleChange}
            >
              {currencies.map((menuItem: any, menuIndex: any) => (
                <MenuItem key={"currenct" + menuIndex} value={menuItem}>
                  {menuItem}
                </MenuItem>
              ))}
            </Select>
          </Box>
        </Box>
        <Box
          className="mb-big"
          style={{ paddingTop: 5, paddingBottom: 5, marginBottom: 10 }}
        >
          <Typography className="labelsLocalIban">
            {i18next.t("Volume of money in")}
          </Typography>
          <Box className="customInputContainer">
            <Box className="customInputSubContainer">
              <TextField
                disabled={isFieldDisabled("volume_of_money_in")}
                style={{ width: "100%" }}
                type="number"
                id="volume_of_money_in"
                value={formik.values.volume_of_money_in}
                name="volume_of_money_in"
                onChange={formik.handleChange}
                variant="outlined"
                sx={{
                  "& .MuiOutlinedInput-root": {
                    paddingRight: "0px!important",
                  },
                }}
                InputProps={{
                  classes: {
                    notchedOutline: "customInputNoBorder",
                    input: "customInputpadding",
                  },
                  sx: {
                    sx: {
                      "input:-webkit-autofill, input:-webkit-autofill:hover, input:-webkit-autofill:focus, input:-webkit-autofill:active":
                        {
                          borderRadius: "16px !important",
                          "-webkit-text-fill-color": "inherit",
                          "-webkit-box-shadow":
                            "0 0 0px 1000px #e0e3e7 inset !important",
                          backgroundColor: "#e0e3e7 !important",
                        },
                    },
                  },
                }}
              />
            </Box>
          </Box>
        </Box>

        <Typography
          className="pageTitleFontSize"
          component="h1"
          variant="h5"
          sx={{
            fontFamily: "Helvetica, sans-serif",
            fontWeight: "600",
            color: "#0e0f0c",
            maxWidth: "600px",
            overflow: "hidden",
          }}
        >
          {i18next.t("Money out")}
        </Typography>
        <InputLabel
          id="pay_out_currency"
          className="oneRemFontSize"
          style={{
            marginBottom: "8px",
            marginTop: "8px",
            color: "#000000",
          }}
        >
          {i18next.t("Pay-out currencies")}
        </InputLabel>
        <Box className="customInputContainer">
          <Box className="customInputSubContainer">
            <Select
              disabled={isFieldDisabled("pay_out_currency")}
              multiple={true}
              labelId="pay_out_currency"
              id="pay_out_currency"
              name="pay_out_currency"
              style={{ width: "100%" }}
              inputProps={{
                classes: {
                  notchedOutline: "customInputNoBorder",
                  input: "customInputpadding",
                },
              }}
              sx={{
                borderRadius: "16px",
                height: "55px",
                "& .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input":
                  {
                    padding: "16.5px 14px 16.5px 14px",
                  },
                "& .css-1d3z3hw-MuiOutlinedInput-notchedOutline": {
                  border: "none !important",
                },
              }}
              value={formik.values.pay_out_currency}
              onChange={formik.handleChange}
            >
              {currencies.map((menuItem: any, menuIndex: any) => (
                <MenuItem key={"currenct" + menuIndex} value={menuItem}>
                  {menuItem}
                </MenuItem>
              ))}
            </Select>
          </Box>
        </Box>

        <Box
          className="mb-big"
          style={{ paddingTop: 5, paddingBottom: 5, marginBottom: 10 }}
        >
          <Typography className="labelsLocalIban">
            {i18next.t("Volume of money out")}
          </Typography>
          <Box className="customInputContainer">
            <Box className="customInputSubContainer">
              <TextField
                disabled={isFieldDisabled("volume_of_money_out")}
                style={{ width: "100%" }}
                type="number"
                id="volume_of_money_out"
                value={formik.values.volume_of_money_out}
                name="volume_of_money_out"
                onChange={formik.handleChange}
                variant="outlined"
                inputProps={{
                  classes: {
                    notchedOutline: "customInputNoBorder",
                    input: "customInputpadding",
                  },
                }}
                sx={{
                  borderRadius: "16px",
                  height: "55px",
                  "& .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input":
                    {
                      padding: "16.5px 14px 16.5px 14px",
                    },
                  "& .css-1d3z3hw-MuiOutlinedInput-notchedOutline": {
                    border: "none !important",
                  },
                }}
              />
            </Box>
          </Box>
        </Box>

        <Typography
          className="pageTitleFontSize"
          component="h1"
          variant="h5"
          sx={{
            fontFamily: "Helvetica, sans-serif",
            fontWeight: "600",
            color: "#0e0f0c",
            maxWidth: "600px",
            overflow: "hidden",
          }}
        >
          {i18next.t("Holding activities")}
        </Typography>
        <Box style={{ paddingTop: 5, paddingBottom: 5 }}>
          <Typography className="labelsLocalIban">
            {i18next.t(
              "What assets does the company hold and where? – if there are multiple holding layers, please clarify the final operational level including geography and industry"
            )}
          </Typography>
          <Box className="customInputContainer">
            <Box className="customInputSubContainer">
              <TextField
                disabled={isFieldDisabled("holding_activities_1")}
                minRows={3}
                style={{ width: "100%" }}
                multiline={true}
                value={formik.values.holding_activities_1}
                name="holding_activities_1"
                onChange={formik.handleChange}
                variant="outlined"
                placeholder={i18next.t("Please enter the information...")}
                inputProps={{
                  classes: {
                    notchedOutline: "customInputNoBorder",
                    input: "customInputpadding",
                  },
                }}
                sx={{
                  borderRadius: "16px",
                  "& .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input":
                    {
                      padding: "16.5px 14px 16.5px 14px",
                    },
                  "& .css-1d3z3hw-MuiOutlinedInput-notchedOutline": {
                    border: "none !important",
                  },
                }}
              />
            </Box>
          </Box>
        </Box>

        <Box style={{ paddingTop: 5, paddingBottom: 5 }}>
          <Typography className="labelsLocalIban">
            {i18next.t("How were the assets obtained?")}
          </Typography>
          <Box className="customInputContainer">
            <Box className="customInputSubContainer">
              <TextField
                disabled={isFieldDisabled("holding_activities_2")}
                minRows={3}
                style={{ width: "100%" }}
                multiline={true}
                value={formik.values.holding_activities_2}
                name="holding_activities_2"
                placeholder={i18next.t("Please enter the information...")}
                onChange={formik.handleChange}
                variant="outlined"
                inputProps={{
                  classes: {
                    notchedOutline: "customInputNoBorder",
                    input: "customInputpadding",
                  },
                }}
                sx={{
                  borderRadius: "16px",
                  "& .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input":
                    {
                      padding: "16.5px 14px 16.5px 14px",
                    },
                  "& .css-1d3z3hw-MuiOutlinedInput-notchedOutline": {
                    border: "none !important",
                  },
                }}
              />
            </Box>
          </Box>
        </Box>
      </Box>

      <Box className="mb-big">
        <Typography
          className="pageTitleFontSize"
          component="h1"
          variant="h5"
          sx={{
            fontFamily: "Helvetica, sans-serif",
            fontWeight: "600",
            color: "#0e0f0c",
            maxWidth: "600px",
            overflow: "hidden",
          }}
        >
          {i18next.t("Trading Activities")}
        </Typography>
        <Box style={{ paddingTop: 5, paddingBottom: 5 }}>
          <Typography className="labelsLocalIban">
            {i18next.t(
              "Describe the delivery chain and how this is funded by the prospect."
            )}
          </Typography>
          <Box className="customInputContainer">
            <Box className="customInputSubContainer">
              <TextField
                disabled={isFieldDisabled("trading_activities_1")}
                minRows={3}
                style={{ width: "100%" }}
                multiline={true}
                placeholder={i18next.t("Please enter the information...")}
                value={formik.values.trading_activities_1}
                name="trading_activities_1"
                onChange={formik.handleChange}
                variant="outlined"
                inputProps={{
                  classes: {
                    notchedOutline: "customInputNoBorder",
                    input: "customInputpadding",
                  },
                }}
                sx={{
                  borderRadius: "16px",
                  "& .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input":
                    {
                      padding: "16.5px 14px 16.5px 14px",
                    },
                  "& .css-1d3z3hw-MuiOutlinedInput-notchedOutline": {
                    border: "none !important",
                  },
                }}
              />
            </Box>
          </Box>
        </Box>

        <Box style={{ paddingTop: 5, paddingBottom: 5 }}>
          <Typography className="labelsLocalIban">
            {i18next.t(
              "Collect two forms of proof that would evidence the above statements such as invoices, contracts, bank statement, financial statement, etc"
            )}
          </Typography>
          <Box className="customInputContainer">
            <Box className="customInputSubContainer">
              <TextField
                disabled={isFieldDisabled("trading_activities_2")}
                minRows={3}
                style={{ width: "100%" }}
                multiline={true}
                placeholder={i18next.t("Please enter the information...")}
                value={formik.values.trading_activities_2}
                name="trading_activities_2"
                onChange={formik.handleChange}
                variant="outlined"
                inputProps={{
                  classes: {
                    notchedOutline: "customInputNoBorder",
                    input: "customInputpadding",
                  },
                }}
                sx={{
                  borderRadius: "16px",
                  "& .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input":
                    {
                      padding: "16.5px 14px 16.5px 14px",
                    },
                  "& .css-1d3z3hw-MuiOutlinedInput-notchedOutline": {
                    border: "none !important",
                  },
                }}
              />
            </Box>
          </Box>
        </Box>
      </Box>

      <Box className="mb-big">
        <Typography
          className="pageTitleFontSize"
          component="h1"
          variant="h5"
          sx={{
            fontFamily: "Helvetica, sans-serif",
            fontWeight: "600",
            color: "#0e0f0c",
            maxWidth: "600px",
            whiteSpace: "wrap",
            overflow: "hidden",
          }}
        >
          {i18next.t("For prospects acting as intermediaries")}
        </Typography>
        <Box style={{ paddingTop: 5, paddingBottom: 5 }}>
          <Typography className="labelsLocalIban">
            {i18next.t(
              "What is the role of the company and added value for the final recipient of goods?"
            )}
          </Typography>
          <Box className="customInputContainer">
            <Box className="customInputSubContainer">
              <TextField
                disabled={isFieldDisabled("prospect_aspect_1")}
                minRows={3}
                style={{ width: "100%" }}
                multiline={true}
                placeholder={i18next.t("Please enter the information...")}
                value={formik.values.prospect_aspect_1}
                name="prospect_aspect_1"
                onChange={formik.handleChange}
                variant="outlined"
                inputProps={{
                  classes: {
                    notchedOutline: "customInputNoBorder",
                    input: "customInputpadding",
                  },
                }}
                sx={{
                  borderRadius: "16px",
                  "& .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input":
                    {
                      padding: "16.5px 14px 16.5px 14px",
                    },
                  "& .css-1d3z3hw-MuiOutlinedInput-notchedOutline": {
                    border: "none !important",
                  },
                }}
              />
            </Box>
          </Box>
        </Box>

        <Box style={{ paddingTop: 5, paddingBottom: 5 }}>
          <Typography className="labelsLocalIban">
            {i18next.t(
              "Collect a full chain of documents including invoices, contracts, customs documents, transportation for at least 2 orders"
            )}
          </Typography>
          <Box className="customInputContainer">
            <Box className="customInputSubContainer">
              <TextField
                disabled={isFieldDisabled("prospect_aspect_2")}
                minRows={3}
                style={{ width: "100%" }}
                multiline={true}
                placeholder={i18next.t("Please enter the information...")}
                value={formik.values.prospect_aspect_2}
                name="prospect_aspect_2"
                onChange={formik.handleChange}
                variant="outlined"
                inputProps={{
                  classes: {
                    notchedOutline: "customInputNoBorder",
                    input: "customInputpadding",
                  },
                }}
                sx={{
                  borderRadius: "16px",
                  "& .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input":
                    {
                      padding: "16.5px 14px 16.5px 14px",
                    },
                  "& .css-1d3z3hw-MuiOutlinedInput-notchedOutline": {
                    border: "none !important",
                  },
                }}
              />
            </Box>
          </Box>
        </Box>
      </Box>

      <Box className="mb-big">
        <Typography
          className="pageTitleFontSize"
          component="h1"
          variant="h5"
          sx={{
            fontFamily: "Helvetica, sans-serif",
            fontWeight: "600",
            color: "#0e0f0c",
            maxWidth: "600px",
            overflow: "hidden",
          }}
        >
          {i18next.t("IT/Marketing/Online Marketing/Legal Services")}
        </Typography>
        <Box style={{ paddingTop: 5, paddingBottom: 5 }}>
          <Typography className="labelsLocalIban">
            {i18next.t(
              "Detailed description of the services provided including industry and geography of the final clients"
            )}
          </Typography>
          <Box className="customInputContainer">
            <Box className="customInputSubContainer">
              <TextField
                disabled={isFieldDisabled("it_marketing_1")}
                minRows={3}
                style={{ width: "100%" }}
                multiline={true}
                placeholder={i18next.t("Please enter the information...")}
                value={formik.values.it_marketing_1}
                name="it_marketing_1"
                onChange={formik.handleChange}
                variant="outlined"
                inputProps={{
                  classes: {
                    notchedOutline: "customInputNoBorder",
                    input: "customInputpadding",
                  },
                }}
                sx={{
                  borderRadius: "16px",
                  "& .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input":
                    {
                      padding: "16.5px 14px 16.5px 14px",
                    },
                  "& .css-1d3z3hw-MuiOutlinedInput-notchedOutline": {
                    border: "none !important",
                  },
                }}
              />
            </Box>
          </Box>
        </Box>

        <Box style={{ paddingTop: 5, paddingBottom: 5 }}>
          <Typography className="labelsLocalIban">
            {i18next.t(
              "How does the company provide the services (own employees, UBO on his own, outsourced third party suppliers, freelance contractors?)"
            )}
          </Typography>
          <Box className="customInputContainer">
            <Box className="customInputSubContainer">
              <TextField
                disabled={isFieldDisabled("it_marketing_2")}
                minRows={3}
                style={{ width: "100%" }}
                multiline={true}
                placeholder={i18next.t("Please enter the information...")}
                value={formik.values.it_marketing_2}
                name="it_marketing_2"
                onChange={formik.handleChange}
                variant="outlined"
                inputProps={{
                  classes: {
                    notchedOutline: "customInputNoBorder",
                    input: "customInputpadding",
                  },
                }}
                sx={{
                  borderRadius: "16px",
                  "& .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input":
                    {
                      padding: "16.5px 14px 16.5px 14px",
                    },
                  "& .css-1d3z3hw-MuiOutlinedInput-notchedOutline": {
                    border: "none !important",
                  },
                }}
              />
            </Box>
          </Box>
        </Box>

        <Box style={{ paddingTop: 5, paddingBottom: 5 }}>
          <Typography className="labelsLocalIban">
            {i18next.t(
              "What documents does the prospect have to confirm the services were rendered to the client? (fee calculation, agreement, timesheets, act of acceptance, etc)"
            )}
          </Typography>
          <Box className="customInputContainer">
            <Box className="customInputSubContainer">
              <TextField
                disabled={isFieldDisabled("it_marketing_3")}
                minRows={3}
                style={{ width: "100%" }}
                multiline={true}
                placeholder={i18next.t("Please enter the information...")}
                value={formik.values.it_marketing_3}
                name="it_marketing_3"
                onChange={formik.handleChange}
                variant="outlined"
                inputProps={{
                  classes: {
                    notchedOutline: "customInputNoBorder",
                    input: "customInputpadding",
                  },
                }}
                sx={{
                  borderRadius: "16px",
                  "& .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input":
                    {
                      padding: "16.5px 14px 16.5px 14px",
                    },
                  "& .css-1d3z3hw-MuiOutlinedInput-notchedOutline": {
                    border: "none !important",
                  },
                }}
              />
            </Box>
          </Box>
        </Box>
      </Box>

      {/* missing */}
      <Box className="mb-big">
        <Typography
          className="pageTitleFontSize"
          component="h1"
          variant="h5"
          sx={{
            fontFamily: "Helvetica, sans-serif",
            fontWeight: "600",
            color: "#0e0f0c",
            maxWidth: "600px",
            overflow: "hidden",
          }}
        >
          {i18next.t("For intermediary service providers")}
        </Typography>
        <Box style={{ paddingTop: 5, paddingBottom: 5 }}>
          <Typography className="labelsLocalIban">
            {i18next.t(
              "What is the final purpose of the service provision? (for example; the client provides marketing services for Dutch based advertising company that serves clients in gaming)."
            )}
          </Typography>
          <Box className="customInputContainer">
            <Box className="customInputSubContainer">
              <TextField
                disabled={isFieldDisabled("intermediary_service_1")}
                minRows={3}
                style={{ width: "100%" }}
                multiline={true}
                placeholder={i18next.t("Please enter the information...")}
                value={formik.values.intermediary_service_1}
                name="intermediary_service_1"
                onChange={formik.handleChange}
                variant="outlined"
                inputProps={{
                  classes: {
                    notchedOutline: "customInputNoBorder",
                    input: "customInputpadding",
                  },
                }}
                sx={{
                  borderRadius: "16px",
                  "& .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input":
                    {
                      padding: "16.5px 14px 16.5px 14px",
                    },
                  "& .css-1d3z3hw-MuiOutlinedInput-notchedOutline": {
                    border: "none !important",
                  },
                }}
              />
            </Box>
          </Box>
        </Box>

        <Box style={{ paddingTop: 5, paddingBottom: 5 }}>
          <Typography className="labelsLocalIban">
            {i18next.t(
              "What documents does the prospect have to confirm the services were rendered to the client?"
            )}
          </Typography>
          <Box className="customInputContainer">
            <Box className="customInputSubContainer">
              <TextField
                disabled={isFieldDisabled("intermediary_service_2")}
                minRows={3}
                style={{ width: "100%" }}
                multiline={true}
                placeholder={i18next.t("Please enter the information...")}
                value={formik.values.intermediary_service_2}
                name="intermediary_service_2"
                onChange={formik.handleChange}
                variant="outlined"
                inputProps={{
                  classes: {
                    notchedOutline: "customInputNoBorder",
                    input: "customInputpadding",
                  },
                }}
                sx={{
                  borderRadius: "16px",
                  "& .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input":
                    {
                      padding: "16.5px 14px 16.5px 14px",
                    },
                  "& .css-1d3z3hw-MuiOutlinedInput-notchedOutline": {
                    border: "none !important",
                  },
                }}
              />
            </Box>
          </Box>
        </Box>
      </Box>

      <Box className="mb-big">
        <Typography
          className="pageTitleFontSize"
          component="h1"
          variant="h5"
          sx={{
            fontFamily: "Helvetica, sans-serif",
            fontWeight: "600",
            color: "#0e0f0c",
            maxWidth: "600px",
            overflow: "hidden",
          }}
        >
          {i18next.t("Consulting")}
        </Typography>
        <Box style={{ paddingTop: 5, paddingBottom: 5 }}>
          <Typography className="labelsLocalIban">
            {i18next.t("What industries are served?")}
          </Typography>
          <Box className="customInputContainer">
            <Box className="customInputSubContainer">
              <TextField
                minRows={3}
                disabled={isFieldDisabled("consulting_1")}
                style={{ width: "100%" }}
                multiline={true}
                placeholder={i18next.t("Please enter the information...")}
                value={formik.values.consulting_1}
                name="consulting_1"
                onChange={formik.handleChange}
                variant="outlined"
                inputProps={{
                  classes: {
                    notchedOutline: "customInputNoBorder",
                    input: "customInputpadding",
                  },
                }}
                sx={{
                  borderRadius: "16px",
                  "& .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input":
                    {
                      padding: "16.5px 14px 16.5px 14px",
                    },
                  "& .css-1d3z3hw-MuiOutlinedInput-notchedOutline": {
                    border: "none !important",
                  },
                }}
              />
            </Box>
          </Box>
        </Box>

        <Box style={{ paddingTop: 5, paddingBottom: 5 }}>
          <Typography className="labelsLocalIban">
            {i18next.t(
              "What is the expertise of the service provider? What resources does the company have to provide the services?"
            )}
          </Typography>
          <Box className="customInputContainer">
            <Box className="customInputSubContainer">
              <TextField
                disabled={isFieldDisabled("consulting_2")}
                minRows={3}
                style={{ width: "100%" }}
                multiline={true}
                placeholder={i18next.t("Please enter the information...")}
                value={formik.values.consulting_2}
                name="consulting_2"
                onChange={formik.handleChange}
                variant="outlined"
                inputProps={{
                  classes: {
                    notchedOutline: "customInputNoBorder",
                    input: "customInputpadding",
                  },
                }}
                sx={{
                  borderRadius: "16px",
                  "& .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input":
                    {
                      padding: "16.5px 14px 16.5px 14px",
                    },
                  "& .css-1d3z3hw-MuiOutlinedInput-notchedOutline": {
                    border: "none !important",
                  },
                }}
              />
            </Box>
          </Box>
        </Box>
      </Box>

      <Box className="mb-big">
        <Typography
          className="pageTitleFontSize"
          component="h1"
          variant="h5"
          sx={{
            fontFamily: "Helvetica, sans-serif",
            fontWeight: "600",
            color: "#0e0f0c",
            maxWidth: "600px",
            overflow: "hidden",
          }}
        >
          {i18next.t("Investment companies")}
        </Typography>
        <Box style={{ paddingTop: 5, paddingBottom: 5 }}>
          <Typography className="labelsLocalIban">
            {i18next.t(
              "License, or explanation as to why a license is not required (with the link to relevant legislation)"
            )}
          </Typography>
          <Box className="customInputContainer">
            <Box className="customInputSubContainer">
              <TextField
                disabled={isFieldDisabled("investment_1")}
                minRows={3}
                style={{ width: "100%" }}
                multiline={true}
                placeholder={i18next.t("Please enter the information...")}
                value={formik.values.investment_1}
                name="investment_1"
                onChange={formik.handleChange}
                variant="outlined"
                inputProps={{
                  classes: {
                    notchedOutline: "customInputNoBorder",
                    input: "customInputpadding",
                  },
                }}
                sx={{
                  borderRadius: "16px",
                  "& .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input":
                    {
                      padding: "16.5px 14px 16.5px 14px",
                    },
                  "& .css-1d3z3hw-MuiOutlinedInput-notchedOutline": {
                    border: "none !important",
                  },
                }}
              />
            </Box>
          </Box>
        </Box>

        <Box style={{ paddingTop: 5, paddingBottom: 5 }}>
          <Typography className="labelsLocalIban">
            {i18next.t(
              "Flow of funds – explanation of whether these are 1st or 3rd party funds?"
            )}
          </Typography>
          <Box className="customInputContainer">
            <Box className="customInputSubContainer">
              <TextField
                disabled={isFieldDisabled("investment_2")}
                minRows={3}
                style={{ width: "100%" }}
                multiline={true}
                placeholder={i18next.t("Please enter the information...")}
                value={formik.values.investment_2}
                name="investment_2"
                onChange={formik.handleChange}
                variant="outlined"
                inputProps={{
                  classes: {
                    notchedOutline: "customInputNoBorder",
                    input: "customInputpadding",
                  },
                }}
                sx={{
                  borderRadius: "16px",
                  "& .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input":
                    {
                      padding: "16.5px 14px 16.5px 14px",
                    },
                  "& .css-1d3z3hw-MuiOutlinedInput-notchedOutline": {
                    border: "none !important",
                  },
                }}
              />
            </Box>
          </Box>
        </Box>

        <Box style={{ paddingTop: 5, paddingBottom: 5 }}>
          <Typography className="labelsLocalIban">
            {i18next.t(
              "The nature of the investments; where the funds are invested to and what is the purpose and how returns to investors are made"
            )}
          </Typography>
          <Box className="customInputContainer">
            <Box className="customInputSubContainer">
              <TextField
                disabled={isFieldDisabled("investment_3")}
                minRows={3}
                style={{ width: "100%" }}
                multiline={true}
                placeholder={i18next.t("Please enter the information...")}
                value={formik.values.investment_3}
                name="investment_3"
                onChange={formik.handleChange}
                variant="outlined"
                inputProps={{
                  classes: {
                    notchedOutline: "customInputNoBorder",
                    input: "customInputpadding",
                  },
                }}
                sx={{
                  borderRadius: "16px",
                  "& .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input":
                    {
                      padding: "16.5px 14px 16.5px 14px",
                    },
                  "& .css-1d3z3hw-MuiOutlinedInput-notchedOutline": {
                    border: "none !important",
                  },
                }}
              />
            </Box>
          </Box>
        </Box>

        <Box style={{ paddingTop: 5, paddingBottom: 5 }}>
          <Typography className="labelsLocalIban">
            {i18next.t(
              "Explanation of AML/KYC policies that are in place to protect investors"
            )}
          </Typography>
          <Box className="customInputContainer">
            <Box className="customInputSubContainer">
              <TextField
                disabled={isFieldDisabled("investment_4")}
                minRows={3}
                style={{ width: "100%" }}
                multiline={true}
                placeholder={i18next.t("Please enter the information...")}
                value={formik.values.investment_4}
                name="investment_4"
                onChange={formik.handleChange}
                variant="outlined"
                inputProps={{
                  classes: {
                    notchedOutline: "customInputNoBorder",
                    input: "customInputpadding",
                  },
                }}
                sx={{
                  borderRadius: "16px",
                  "& .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input":
                    {
                      padding: "16.5px 14px 16.5px 14px",
                    },
                  "& .css-1d3z3hw-MuiOutlinedInput-notchedOutline": {
                    border: "none !important",
                  },
                }}
              />
            </Box>
          </Box>
        </Box>

        <Button
          onClick={(e: any) => {
            formik.handleSubmit(e);
          }}
          fullWidth
          variant="contained"
          className="oneRemFontSize btnsLoginHeight"
          sx={{
            color: "white",
            backgroundColor: "#2A5182",
            borderRadius: "24px",
            textTransform: "none",
            marginTop: "2rem",
            fontFamily: "Helvetica, sans-serif",
            marginBottom: "4rem",
          }}
        >
          {i18next.t("Save")}
        </Button>
      </Box>
    </Box>
  );
}

export default EditOnBoardingForm;
