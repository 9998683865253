import React, {
  useState,
  forwardRef,
  ForwardedRef,
  useImperativeHandle,
  useRef,
  useEffect,
} from "react";
import {
  TextField,
  InputAdornment,
  IconButton,
  Typography,
  Box,
} from "@mui/material";
import {
  Visibility,
  VisibilityOff,
  Close,
  InfoOutlined,
} from "@mui/icons-material";
import ErrorMessage from "./ErrorMessage";
import "./CustomInput.scss";

interface Props {
  id?: string;
  label?: string;
  placeHolder?: string;
  value: string | undefined;
  onChangeText: (value: string) => void;
  isPassword?: boolean;
  isTouched: boolean;
  errors: any | null;
  type?: string;
  isRequired?: boolean;
  nextId?: string;
  editable?: boolean;
  showFloatLabel?: boolean;
  needVerified?: boolean;
}

const CustomInput = forwardRef<HTMLInputElement, Props>(
  (
    {
      id,
      label,
      placeHolder,
      value,
      onChangeText,
      isPassword,
      isTouched,
      errors,
      type,
      isRequired,
      nextId,
      editable = true,
      showFloatLabel = false,
      needVerified,
    },
    ref: ForwardedRef<HTMLInputElement>
  ) => {
    const [color, setColor] = useState("#e0e3e7");
    const [showPassword, setShowPassword] = useState(false);
    const inputRef = useRef<HTMLInputElement>(null);

    useImperativeHandle(ref, () => inputRef.current as HTMLInputElement);

    const handleShowPassword = () => {
      setShowPassword((prev) => !prev);
    };

    const handleClearText = () => {
      onChangeText("");
    };

    const handleKeyPress = (event: React.KeyboardEvent) => {
      if (event.key === "Enter" && nextId) {
        const nextField = document.getElementById(nextId) as HTMLInputElement;
        if (nextField) {
          nextField.focus();
        }
      }
    };

    useEffect(() => {
      setColor(errors && isTouched ? "#B22222" : "#e0e3e7");
    }, [errors, isTouched]);

    return (
      <Box className="customInputContainer">
        <Box
          className={`customInputSubContainer ${
            editable ? "" : "customInputSubContainer--disabled"
          } ${needVerified ? "customInputSubContainer--need-verified" : ""} ${
            errors ? "customInputSubContainerError" : "customInputSubContainer"
          }`}
          style={{ borderColor: color }}
        >
          <TextField
            id={id}
            label={showFloatLabel ? label : ""}
            placeholder={placeHolder}
            value={value}
            onChange={(e) => onChangeText(e.target.value)}
            type={
              isPassword ? (showPassword ? "text" : "password") : type || "text"
            }
            inputRef={inputRef}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  {value && !isPassword && (
                    <IconButton onClick={handleClearText}>
                      <Close />
                    </IconButton>
                  )}
                  {isPassword && (
                    <IconButton onClick={handleShowPassword}>
                      {showPassword ? (
                        <VisibilityOff className="muiIcons" />
                      ) : (
                        <Visibility className="muiIcons" />
                      )}
                    </IconButton>
                  )}
                </InputAdornment>
              ),
              classes: {
                notchedOutline: "customInputNoBorder",
                input: "customInputpadding",
              },
              sx: {
                "input:-webkit-autofill, input:-webkit-autofill:hover, input:-webkit-autofill:focus, input:-webkit-autofill:active":
                  {
                    borderRadius: "16px !important",
                    "-webkit-text-fill-color": "inherit",
                    "-webkit-box-shadow": errors
                      ? "0 0 0px 1000px #fdd1ce inset !important"
                      : "0 0 0px 1000px #e0e3e7 inset !important",
                    backgroundColor: "#e0e3e7 !important",
                  },
              },
            }}
            InputLabelProps={{
              classes: {
                root: errors ? "customInputLabelError" : "customInputLabel",
              },
            }}
            fullWidth
            variant="outlined"
            error={!!errors && !!isTouched}
            // helperText={isTouched && errors ? errors.toString() : ""}
            disabled={!editable}
            onKeyPress={handleKeyPress}
            required={isRequired}
          />
        </Box>
        {isTouched && errors && (
          <ErrorMessage
            style={{ marginLeft: 16 }}
            message={errors.toString()}
          />
        )}
        {needVerified && (
          <Typography
            variant="body2"
            color="error"
            className="need-verified"
            style={{ marginLeft: 15 }}
          >
            <InfoOutlined style={{ marginRight: 5 }} /> Verify this email
          </Typography>
        )}
      </Box>
    );
  }
);

export default CustomInput;
