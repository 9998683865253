import React, { useState, useEffect } from "react";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import "./AddBalance.scss";
import Nav from "src/components/layout/nav/Nav";
import { Typography, Box, Button } from "@mui/material";
import MoneyAddForm from "./components/MoneyAddForm";
import PaymentForm from "./components/PaymentForm";
import api from "src/store/interceptors/api";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import OTPVerification from "src/components/shared/OTPVerification";
import PaymentInstructions from "./components/PaymentInstructions";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import CurrencyBitcoinIcon from "@mui/icons-material/CurrencyBitcoin";
import CreateCryptoForm from "./components/Crypto/CreateCrypto";
import CryptoDetail from "./components/Crypto/CryptoDetail";
import LoadingIndicator from "src/components/shared/LoadingIndicator";
import { useAppSelector } from "src/store";
import i18next from "i18next";
import { PERSONAL_ACCOUNT } from "src/components/shared/constants";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY ?? "");
const defaultTheme = createTheme();
const defaultCurrencies = ["EUR"];

const AddBalance: React.FC = () => {
  const { user } = useAppSelector((state) => state.auth);
  const location = useLocation();
  const navigate = useNavigate();

  const [initialSelectedCurrency, setInitialSelectedCurrency] = useState<
    string | null
  >(null);
  const [selectedMethod, setSelectedMethod] = useState<string | null>(null);

  const [paymentInstructions, setPaymentInstructions] = useState<{} | null>(
    null
  );
  const [initialBalance, setInitialBalance] = useState<number | null>(null);
  const [balance, setBalance] = useState<any>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [handleRequested, setHandleRequested] = useState(false);
  const [isVerified, setIsVerified] = useState(false);
  const [payload, setPayload] = useState<any>({});
  const [clientSecret, setClientSecret] = useState(null);
  const [finished, setFinished] = useState(false);
  const [error, setError] = useState(false);
  const [amount, setAmount] = useState(0);
  const [amountCurrency, setAmountCurrency] = useState<any>(null);
  const [isDetailsShown, setIsDetailsShown] = useState(false);

  const personal = user?.account_type === PERSONAL_ACCOUNT;

  const handleVerification = (data: any) => {
    setIsVerified(true);
    setHandleRequested(false);
    if (
      payload &&
      payload.total &&
      payload.currency &&
      selectedMethod === "card"
    )
      handleMoneyAdd(payload.total, payload.currency, data.uuid);

    if (
      payload &&
      payload.total &&
      payload.currency &&
      selectedMethod === "bank"
    )
      handleMoneyAddBank(payload.total, payload.currency, data.uuid);

    if (
      payload &&
      payload.total &&
      payload.currency &&
      selectedMethod === "crypto"
    )
      handleMoneyAddCrypto(
        payload.total,
        payload.currency,
        data.uuid,
        payload.feeData
      );
  };
  const handleNavigate = () => {
    navigate("/home");
  };

  useEffect(() => {
    getMe();
    getInitialData();
  }, []);
  const getInitialData = async () => {
    await api
      .get("/user-balances")
      .then((response: any) => {
        if (response && response.data && response.data.data) {
          const resData = response.data.data;
          const balanceCurrencies = response.data.data
            ? response.data.data.map((item: any) => item.currency.code)
            : [];
          const missingCurrencies = defaultCurrencies.filter(
            (code) => !balanceCurrencies.includes(code)
          );
          missingCurrencies.map((item, index) => {
            resData.push({
              id: 0,
              balance: "0.00",
              user_id: user && user.id ? user.id : 0,
              currency: {
                id: 0,
                code: item,
                name: item,
                symbol: null,
                status: 1,
                exchange_rate: null,
                decimal_places: null,
                created_at: "2024-01-09T08:49:39.000000Z",
                updated_at: "2024-01-09T08:49:39.000000Z",
              },
            });
          });

          setBalance(resData);
        }
      })
      .catch((error) => {
        console.error(error.message);
      });
  };

  useEffect(() => {
    if (location.state && location.state.initialSelectedCurrency && balance) {
      const initialCurrency = location.state.initialSelectedCurrency;
      const initialBalanceItem = balance.find(
        (balanceItem: any) => balanceItem.currency.code === initialCurrency
      );

      if (initialBalanceItem) {
        setInitialSelectedCurrency(initialCurrency);
        setInitialBalance(initialBalanceItem.balance);
      }
    }
  }, [balance, location.state]);

  const getMe = async () => {
    try {
      await api
        .get("/me")
        .then((response: any) => {
          if (response) {
            if (
              response &&
              response.data &&
              response.data.data &&
              response.data.data.verification_approved === 0
            ) {
              if (response.data.data.verification_requested === 1) {
                alert("Your account is in review!");
                navigate("/home");
              } else {
                alert("Please complete the banking onboarding!");
                navigate("/banking");
              }
            }

            if (
              response &&
              response.data &&
              response.data.data &&
              response.data.data.role &&
              response.data.data.role === "Employee"
            ) {
              navigate("/admin");
            }
          }
        })
        .catch((error) => {
          navigate("/home");
        });
    } catch (error) {
      console.log(error);
    }
  };

  const handleMoneyAddVerification = async (total: number, currency: any) => {
    if (!handleRequested) {
      setHandleRequested(true);
      setPayload({ total, currency });
    }
  };

  const handleCryptoAdd = async (
    total: number,
    currency: any,
    feeData: any
  ) => {
    if (!handleRequested) {
      setHandleRequested(true);
      setPayload({ total, currency, feeData });
    }
  };

  const handleMoneyAddCrypto = async (
    total: number,
    currency: any,
    uuid: string,
    feeData: any
  ) => {
    setIsLoading(true);
    try {
      if (currency && currency.currency) {
        const response = await api.post(`crypto-payment`, {
          order_currency: currency.currency,
          order_amount: total,
          fee_total: feeData?.fee,
          fee_percentage: feeData?.feePercentage,
          uuid: uuid,
        });

        setIsLoading(false);
        if (response && response.data) {
          setAmount(total);
          setAmountCurrency(currency);
          setIsDetailsShown(true);
          setPaymentInstructions(
            response.data.data
              ? { ...response.data.data }
              : { ...response.data }
          );
        } else {
          toast.error(i18next.t("Please try again later"));
        }
      } else {
        setIsLoading(false);
        toast.error(
          i18next.t(
            "Please select the currency or check the entered amount amount"
          )
        );
      }
    } catch (error) {
      setIsLoading(false);
      toast.error(
        i18next.t(
          "Please select the currency or check the entered amount amount"
        )
      );
    }
  };

  const handleMoneyAddBank = async (
    total: number,
    currency: any,
    uuid: string
  ) => {
    setIsLoading(true);
    try {
      if (currency && currency.currency) {
        const response = await api.post(`payment-requests`, {
          currency: currency.currency,
          balance_id: currency.id,
          amount: total,
          uuid: uuid,
        });

        setIsLoading(false);
        if (response && response.data) {
          setAmount(total);
          setAmountCurrency(currency);
          setPaymentInstructions(
            response.data.data
              ? { ...response.data.data }
              : { ...response.data }
          );
        } else {
          toast.error(i18next.t("Please try again later"));
          setIsLoading(false);
        }
      } else {
        toast.error(
          i18next.t(
            "Please select the currency or check the entered amount amount"
          )
        );
        setIsLoading(false);
      }
    } catch (error) {
      toast.error(
        i18next.t(
          "Please select the currency or check the entered amount amount"
        )
      );
      setIsLoading(false);
    }
  };
  const handleMoneyAdd = async (total: number, currency: any, uuid: string) => {
    setIsLoading(true);
    try {
      if (currency && currency.currency) {
        const response = await api.post(`payment`, {
          currency: currency.currency,
          amount: total,
          uuid: uuid,
        });

        setIsLoading(false);
        if (response && response.data) {
          const data = response.data;
          setAmount(total);
          setAmountCurrency(currency);
          setClientSecret(data.clientSecret);
        } else {
          toast.error(i18next.t("Please try again later"));

          console.error("Failed to get client secret:", response.statusText);
        }
      } else {
        setIsLoading(false);
        toast.error(
          i18next.t(
            "Please select the currency or check the entered amount amount"
          )
        );
      }
    } catch (error) {
      setIsLoading(false);
      toast.error(
        i18next.t(
          "Please select the currency or check the entered amount amount"
        )
      );
    }
  };

  const handlePaymentSuccess = async (intent: any) => {
    try {
      if (amountCurrency && amountCurrency.currency) {
        const response = await api.post(`update-wise-balance`, {
          currency: amountCurrency.currency,
          amount: amount,
          intent: intent,
        });

        if (response) {
          toast.success(i18next.t("Balance updated successfuly"));
          setFinished(true);
          /*
          setTimeout(() => {
            window.location.reload();
          }, 2000);
          */
        } else {
          setError(true);
          toast.error(i18next.t("Failed to update balance:"), response);
        }
      } else {
        setError(true);
        toast.error(
          i18next.t(
            "Please select the currency or check the entered amount amount"
          )
        );
      }
    } catch (error) {
      setError(true);
      console.error("Error:", error);
    }
  };
  return (
    <Elements stripe={stripePromise}>
      <div>
        <Nav step={0} isStepperVisible={false} isProfileSetVisible={true} />
        <div className="content-container-profile">
          <ThemeProvider theme={defaultTheme}>
            <Container component="main">
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <div className="d-flex ai-center mb-3-rem">
                  <p
                    className="back-button-icon circle-around-text-smaller helvetica mr-1-rem cp"
                    onClick={handleNavigate}
                  >
                    <ArrowBackIcon className="muiIcons" />
                  </p>

                  <Typography
                    className="your-details-text d-flex jc-start pageTitleFontSize"
                    component="h1"
                    variant="h5"
                    sx={{
                      fontFamily: "Helvetica, sans-serif",
                      fontWeight: "600",
                      textAlign: "start",
                    }}
                  >
                    {i18next.t("Add Balance")}
                  </Typography>
                </div>

                {!isVerified && handleRequested && (
                  <OTPVerification onSubmit={handleVerification} />
                )}

                {!selectedMethod && (
                  <Box>
                    <Typography
                      className="your-details-text d-flex jc-start pageTitleFontSize"
                      component="h1"
                      variant="h5"
                      sx={{
                        fontFamily: "Helvetica, sans-serif",
                        fontWeight: "600",
                        textAlign: "start",
                      }}
                    >
                      {i18next.t("Select payment method")}
                    </Typography>
                    <Box className="buttons-container">
                      {personal && (
                        <>
                          <Button
                            sx={{ width: "80%", m: 1, height: "6rem" }}
                            className="paymentMethodButton"
                            variant="contained"
                            onClick={(e: any) => setSelectedMethod("bank")}
                          >
                            <div style={{ textAlign: "center" }}>
                              <AccountBalanceIcon />
                              <p style={{ fontSize: "12px" }}>
                                {i18next.t("Bank")}
                              </p>
                            </div>
                          </Button>
                          <Button
                            sx={{ width: "80%", m: 1, height: "6rem" }}
                            className="paymentMethodButton"
                            variant="contained"
                            onClick={(e: any) => setSelectedMethod("card")}
                          >
                            {/* Credit Card */}
                            <div style={{ textAlign: "center" }}>
                              <CreditCardIcon />
                              <p style={{ fontSize: "12px" }}>
                                {i18next.t("Credit Card")}
                              </p>
                            </div>
                          </Button>
                        </>
                      )}
                      <Button
                        sx={{
                          m: 1,
                          height: "6rem",
                        }}
                        className={`paymentMethodButton ${
                          personal
                            ? "cryptoAddBalanceBtn"
                            : "cryptoAddBalanceBtnBusiness"
                        }`}
                        variant="contained"
                        onClick={(e: any) => setSelectedMethod("crypto")}
                      >
                        {/* Crypto */}
                        <div style={{ textAlign: "center" }}>
                          <CurrencyBitcoinIcon />
                          <p style={{ fontSize: "12px" }}>
                            {i18next.t("Crypto")}
                          </p>
                        </div>
                      </Button>
                    </Box>
                  </Box>
                )}
                {!handleRequested &&
                  !error &&
                  !finished &&
                  selectedMethod === "card" && (
                    <div>
                      {!clientSecret && !isVerified && (
                        <MoneyAddForm
                          onSubmit={handleMoneyAddVerification}
                          initialSelectedCurrency={initialSelectedCurrency}
                        />
                      )}
                      {isVerified && clientSecret && (
                        <PaymentForm
                          clientSecret={clientSecret}
                          onSuccess={handlePaymentSuccess}
                        />
                      )}
                    </div>
                  )}
                {!handleRequested &&
                  !error &&
                  !finished &&
                  selectedMethod === "bank" && (
                    <div>
                      {!paymentInstructions && !isLoading && (
                        <MoneyAddForm
                          onSubmit={handleMoneyAddVerification}
                          initialSelectedCurrency={initialSelectedCurrency}
                        />
                      )}
                      {isLoading && <LoadingIndicator />}
                      {isVerified && paymentInstructions && (
                        <PaymentInstructions data={paymentInstructions} />
                      )}
                    </div>
                  )}
                {!handleRequested &&
                  !error &&
                  !finished &&
                  selectedMethod === "crypto" && (
                    <div>
                      {!paymentInstructions &&
                        !isDetailsShown &&
                        !isLoading && (
                          <CreateCryptoForm onSubmit={handleCryptoAdd} />
                        )}
                      {isLoading && <LoadingIndicator />}
                      {isVerified && isDetailsShown && (
                        <CryptoDetail cryptoData={paymentInstructions} />
                      )}
                    </div>
                  )}
                {isVerified && !error && finished && (
                  <div>
                    <Typography
                      className="your-details-text d-flex jc-start registration1375RemFontSize"
                      component="h1"
                      variant="h4"
                      sx={{
                        fontFamily: "Helvetica, sans-serif",
                        fontWeight: "600",
                        textAlign: "start",
                      }}
                    >
                      {i18next.t("Transaction completed")}
                    </Typography>
                  </div>
                )}

                {error && (
                  <div>
                    <Typography
                      className="your-details-text d-flex jc-start registration1375RemFontSize"
                      component="h1"
                      variant="h4"
                      sx={{
                        fontFamily: "Helvetica, sans-serif",
                        fontWeight: "600",
                        textAlign: "start",
                      }}
                    >
                      {i18next.t(
                        "Error processing transaction, please try again"
                      )}
                    </Typography>
                  </div>
                )}
              </Box>
            </Container>
          </ThemeProvider>
        </div>
      </div>
    </Elements>
  );
};

export default AddBalance;
