import React, { useEffect, useState } from "react";
import IconButton from "@mui/material/IconButton";
import Badge from "@mui/material/Badge";
import NotificationsIcon from "@mui/icons-material/Notifications";
import Drawer from "@mui/material/Drawer";
import "./NotificationBadge.scss";
import NotificationDrawerContent from "../NotificationDrawerContent/NotificationDrawerContent";
import { ReactComponent as NotificationIcon } from "../../../assets/images/notification.svg";

import api from "src/store/interceptors/api";

export default function NotificationBadge({ user }: any) {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [badgeContent, setBadgeContent] = useState(0);

  const handleGetNotifications = async () => {
    try {
      const res = await api.get("/unread-notifications");
      if (res && res.data) {
        const { data } = res;
        if (data.unread_notification) {
          setBadgeContent(data.unread_notification);
        } else {
          setBadgeContent(0);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const updateNotifications = async () => {
    handleGetNotifications();
  };

  useEffect(() => {
    handleGetNotifications();
  }, []);

  const toggleDrawer =
    (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event.type === "keydown" &&
        ((event as React.KeyboardEvent).key === "Tab" ||
          (event as React.KeyboardEvent).key === "Shift")
      ) {
        return;
      }
      setIsDrawerOpen(open);
    };

  return (
    <>
      <IconButton aria-label="some-label" onClick={toggleDrawer(true)}>
        <Badge
          badgeContent={badgeContent}
          max={999}
          color="secondary"
          sx={{
            "& .MuiBadge-badge": {
              backgroundColor: "rgba(255, 0, 0, 1)",
              fontSize: "12px",
            },
            "@media (max-width:768px)": {
              "& .MuiBadge-badge": {
                minWidth: "16px",
                height: "16px",
                fontSize: "10px",
                fontWeight: "400",
                padding: "0 6px",
              },
            },
            "@media (max-width:500px)": {
              "& .MuiBadge-badge": {
                minWidth: "14px",
                height: "14px",
                fontSize: "8px",
                fontWeight: "400",
                padding: "0 6px",
              },
            },
            "@media (max-width:400px)": {
              "& .MuiBadge-badge": {
                minWidth: "12px",
                height: "12px",
                fontSize: "8px",
                fontWeight: "400",
                padding: "0 4px",
              },
            },
          }}
        >
          <NotificationIcon className="chatIcon" />
        </Badge>
      </IconButton>

      <Drawer anchor="right" open={isDrawerOpen} onClose={toggleDrawer(false)}>
        <NotificationDrawerContent
          user={user}
          updateNotifications={updateNotifications}
          toggleDrawer={toggleDrawer}
        />
      </Drawer>
    </>
  );
}
