import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../interceptors/api";
import {
  User,
  VeifyPasswordResponse,
} from "../../types/register.types";
import { getToken, getUser, removeUser, storeUser } from "../persist/persist";

export const storage = localStorage;

interface InitialStateAuth {
  isLoading: boolean;
  isSuccess: boolean;
  isError: boolean;
  token: string | null;
  user: User | null;
}

const initialAuthState: InitialStateAuth = {
  user: getUser(),
  isLoading: false,
  isSuccess: false,
  isError: false,
  token: getToken(),
};

export let bearerToken = initialAuthState.token;

export const loginUser = createAsyncThunk<any, any>(
  "user/login-email",
  async (credentials: any, thunkAPI) => {
    try {
      const response = await api.post(`/auth/login`, credentials);

      if (response && response.data && response.data.access_token) {
        storeUser("victorumuser", JSON.stringify(response.data.user));
        storeUser("token", response.data.access_token);

        return response.data as VeifyPasswordResponse;
      } else {
        if(response?.data?.action === "check_verification_code") {
          return thunkAPI.rejectWithValue("check_verification_code");
        }else{
          return thunkAPI.rejectWithValue("Failed to fetch issues.");
        }
      }
    } catch (error:any) {
      console.log("error", error?.response?.data?.error);
      return thunkAPI.rejectWithValue(error?.response?.data?.error);
    }
  }
);

export const loginWallet = createAsyncThunk<any, any>(
  "user/login-wallet",
  async (payload: any, thunkAPI) => {
    try {
      const response = await api.post(`/wallet-login`, payload);

      if (response && response.data && response.data.access_token) {
        //storeUser('user_data_global', credentials);
        storeUser("victorumuser", JSON.stringify(response.data.user));
        storeUser("token", response.data.access_token);
        return response.data as VeifyPasswordResponse;
      } else {
        return thunkAPI.rejectWithValue("Failed to fetch issues.");
      }
    } catch (error) {
      console.log(error);
      return thunkAPI.rejectWithValue("Failed to fetch issues.");
    }
  }
);

const authSlice = createSlice({
  name: "authentication",
  initialState: initialAuthState,
  reducers: {
    updateUser(state, action) {
      state.user = action.payload;
      storeUser("victorumuser", JSON.stringify(action.payload)); // Persist the updated user data
    },
    logout(state) {
      removeUser("victorumuser");
      removeUser("token");

      state.token = null;
      state.isSuccess = false;
      state.isLoading = false;
      state.user = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(loginUser.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(loginUser.fulfilled, (state, action) => {
        state.user = action.payload;
        state.isSuccess = true;
        state.isLoading = false;
      })
      .addCase(loginUser.rejected, (state) => {
        state.user = null;
        state.token = null;
        state.isLoading = false;
        state.isSuccess = false;
        state.isError = true;
      }).addCase(loginWallet.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(loginWallet.fulfilled, (state, action) => {
        state.user = action.payload;
        state.isSuccess = true;
        state.isLoading = false;
      })
      .addCase(loginWallet.rejected, (state) => {
        state.user = null;
        state.token = null;
        state.isLoading = false;
        state.isSuccess = false;
        state.isError = true;
      });
  },
});

export const { logout } = authSlice.actions;
export const { updateUser } = authSlice.actions;

export default authSlice.reducer;
