import React from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Box, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import HeaderLanding from "../components/HeaderLanding/HeaderLanding";
import { useAppSelector } from "src/store";
import FooterLanding from "../components/FooterLanding/FooterLanding";
import "./LandingPagesGlobal.scss";
import TitleBackBox from "../components/TitleBackBox/TitleBackBox";
import HeadlineVvidgetSmall from "../components/HeadlineVvidget/HeadlineVvidgetSmall";
import TitleTextVvidget from "../components/TitleTextVvidget/TitleTextVvidget";
import LogoTextVvidget from "../components/LogoTextVvidget/LogoTextVvidget";
import ImageWithText from "../components/ImageWithText/ImageWithText";
import CryptoImg from "../../../assets/images/instantPaymentsImg.jpg";
import i18next from "i18next";

const defaultTheme = createTheme();

const VisaCard: React.FC = () => {
  const { user } = useAppSelector((state) => state.auth);

  const navigate = useNavigate();

  const navigateToContactUs = (event: any) => {
    navigate("/contact-us");
  };

  return (
    <div>
      <HeaderLanding user={user} />
      <div className="margintTopPagesMainCont">
        <ThemeProvider theme={defaultTheme}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Box className="titleHeadlineSmallBG">
              <Box className="landingPagesMainBox">
                <Box>
                  <TitleBackBox title={i18next.t("VISA Card")} />
                </Box>
                <Box>
                  <HeadlineVvidgetSmall
                    headline={i18next.t(
                      "230 million points of acceptance - certainly also there, where you need them now!"
                    )}
                    subtitle={i18next.t(
                      "Payment security – Visa and VicPay monitor all transactions 24 hours a day to help prevent, detect and resolve fraud and to protect your transactions through your VicPay VISA card!"
                    )}
                    smallText={i18next.t("Request debit card")}
                    buttonText={i18next.t("Contact Us")}
                    onClickFunction={navigateToContactUs}
                  />
                </Box>
              </Box>
            </Box>
            <Box className="landingPagesMainBox">
              <Typography
                variant="h3"
                sx={{
                  marginBottom: "1rem",
                  textAlign: "center",
                }}
                className="headlineLandingPages"
              >
                {" "}
                {i18next.t("Cards to spend worldwide!")}
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  textAlign: "center",
                  margin: "auto",
                }}
                paragraph
                className="textsLandingPage"
              >
                {i18next.t(
                  "With every account you get an individually designed visa debit card."
                )}
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  textAlign: "center",
                  margin: "0 auto 1rem auto",
                }}
                paragraph
                className="textsLandingPage"
              >
                {i18next.t(
                  "This universal card has all you need: easy online, mobile and contactless payments and cash withdrawals at any ATM with a VISA logo"
                )}
              </Typography>
              <Box className="getYourCardImagePaymentsPage"></Box>
              <Box
                sx={{
                  marginTop: "5rem",
                  marginBottom: "4rem",
                  display: "grid",
                  gridTemplateColumns: ["1fr", "1fr 1fr"],
                  gap: [2, 10],
                }}
              >
                <Box>
                  <Typography
                    variant="h3"
                    sx={{
                      marginBottom: "1rem",
                    }}
                    className="headlineMiniLandingPages"
                  >
                    {" "}
                    {i18next.t(
                      "Pay Anywhere, Anytime with Our Visa Debit Card"
                    )}
                  </Typography>
                  <Typography
                    variant="body1"
                    paragraph
                    className="textsLandingPage"
                  >
                    {i18next.t(
                      "No matter where you are. Because our Visa debit card is accepted worldwide you can pay and get cash everywhere without additional fees."
                    )}
                  </Typography>
                </Box>
                <Box>
                  <Typography
                    variant="h3"
                    sx={{
                      marginBottom: "1rem",
                    }}
                    className="headlineMiniLandingPages"
                  >
                    {" "}
                    {i18next.t(
                      "Manage Your Card Anytime, Anywhere with Our App"
                    )}
                  </Typography>
                  <Typography
                    variant="body1"
                    paragraph
                    className="textsLandingPage"
                  >
                    {i18next.t(
                      "You can activate or block your card, change the PIN and adjust daily payment limits directly in the app."
                    )}
                  </Typography>
                </Box>
              </Box>
              <Box sx={{ marginTop: "2rem" }}>
                <Typography
                  variant="h3"
                  sx={{
                    marginBottom: "1rem",
                  }}
                  className="headlineMiniLandingPages"
                >
                  {" "}
                  {i18next.t("VicPay Protects Your Funds")}
                </Typography>
                <Typography
                  variant="body1"
                  paragraph
                  className="textsLandingPage"
                >
                  {i18next.t(
                    "Be aware that with VicPay the funds in your account are protected up to XXXXXX CAD (YYYYYYY USD )"
                  )}{" "}
                  <span
                    className="linksHover"
                    onClick={() => navigate("/safety-of-funds")}
                  >
                    {i18next.t("Safety of funds")}
                  </span>
                </Typography>
              </Box>
              <Box sx={{ marginTop: "2rem" }}>
                <Typography
                  variant="h3"
                  sx={{
                    marginBottom: "1rem",
                  }}
                  className="headlineMiniLandingPages"
                >
                  {" "}
                  {i18next.t("Transparent transaction policy")}
                </Typography>
                <Typography
                  variant="body1"
                  paragraph
                  className="textsLandingPage"
                >
                  {i18next.t(
                    "The transactions through your VicPay Visa card are automatically deducted from your VicPay account, so you can access your up-to-date balance at any time through our website or our app!"
                  )}
                </Typography>
              </Box>
              <Box
                sx={{
                  marginTop: "6rem",
                  marginBottom: "4rem",
                  display: "grid",
                  gridTemplateColumns: ["1fr", "1fr 1fr", "1fr 1fr 1fr"],
                  gap: [2, 5],
                }}
              >
                <Box>
                  <TitleTextVvidget
                    showBoxStyle={true}
                    title={i18next.t("Seamless Payments")}
                    text={i18next.t(
                      "Contactless, online or with Apple Pay and Google Pay!"
                    )}
                  />
                </Box>
                <Box>
                  <TitleTextVvidget
                    showBoxStyle={true}
                    title={i18next.t("Low-Fee Cash Access")}
                    text={i18next.t(
                      "Get cash at any ATM with a Visa logo for lowest fees!"
                    )}
                  />
                </Box>
                <Box>
                  <TitleTextVvidget
                    showBoxStyle={true}
                    title={i18next.t("Secure Online Transactions")}
                    text={i18next.t(
                      "Pay quickly and securely online using your VicPay Visa card!"
                    )}
                  />
                </Box>
                <Box>
                  <TitleTextVvidget
                    showBoxStyle={true}
                    title={i18next.t("Total Card Control")}
                    text={i18next.t(
                      "Manage your card directly in the app or through the VicPay website!"
                    )}
                  />
                </Box>
                <Box>
                  <TitleTextVvidget
                    showBoxStyle={true}
                    title={i18next.t("Instant Alerts")}
                    text={i18next.t(
                      "Notifications instantly for every payment to your smartphone!"
                    )}
                  />
                </Box>
                <Box>
                  <TitleTextVvidget
                    showBoxStyle={true}
                    title={i18next.t("Flexible Card Management")}
                    text={i18next.t(
                      "Activate or block your card, change the PIN and set daily limits online!"
                    )}
                  />
                </Box>
                <Box>
                  <TitleTextVvidget
                    showBoxStyle={true}
                    title={i18next.t("Share the Convenience")}
                    text={i18next.t(
                      "Get additional cards for shared and business accounts!"
                    )}
                  />
                </Box>
                <Box>
                  <TitleTextVvidget
                    showBoxStyle={true}
                    title={i18next.t("Streamlined Banking")}
                    text={i18next.t(
                      "Apply online for an IBAN account and connect it to your VicPay Visa!"
                    )}
                  />
                </Box>
              </Box>
              <Box>
                <LogoTextVvidget
                  text={i18next.t(
                    "Access your bank account securely with Visa Debit to make purchases, pay bills or get cash, anywhere in the world. With Visa Debit, you stay in control, because what you spend is deducted directly from your bank account. What’s more, Visa Debit is accepted everywhere Visa is – online, overseas, over the phone, at ATMs and in-store."
                  )}
                />
              </Box>
              <Box>
                <ImageWithText
                  backgroundImage={CryptoImg}
                  noiseImage="/img/payments-noise.png"
                  title={i18next.t("It’s easier to use than cash and checks")}
                  text={i18next.t(
                    "Full convenience – Save when you pick up cash at an ATM and leave all your other cards at home: you won´t be able to pick up cash for lower fees than with us!"
                  )}
                />
              </Box>
              <Box
                sx={{
                  paddingBottom: "8px",
                  "@media(max-width: 1200px)": {
                    paddingBottom: "0px",
                    marginTop: "112px",
                  },
                  "@media(max-width: 1023px)": {
                    marginTop: "40px",
                  },
                }}
              >
                <TitleTextVvidget
                  title={i18next.t("Payment security")}
                  text={i18next.t(
                    "Visa and VicPay monitor all transactions 24 hours a day to help prevent, detect and resolve fraud and to protect your transactions through your VicPay VISA card!"
                  )}
                />
              </Box>
              <Box sx={{ marginTop: "4rem" }}>
                <Typography
                  variant="h3"
                  sx={{
                    marginBottom: "1rem",
                  }}
                  className="headlineMiniLandingPages"
                >
                  {i18next.t("Open Your VicPay Account Instantly!")}
                </Typography>
                <Typography
                  variant="body1"
                  paragraph
                  className="textsLandingPage"
                >
                  {i18next.t("Don't lose time")}{" "}
                  <span
                    style={{ marginLeft: "5px" }}
                    className="linksHover"
                    onClick={() => navigate("/registration")}
                  >
                    {i18next.t("click here")}
                  </span>{" "}
                  {i18next.t(
                    "and open your VicPay account instantly! In case you need support during the online application, please dial"
                  )}
                  <br />{" "}
                  {i18next.t("+1 604-260-0738 to speak with our account team!")}
                </Typography>
              </Box>
            </Box>
          </Box>
        </ThemeProvider>
      </div>
      <Box sx={{ marginTop: "4rem" }}>
        <FooterLanding />
      </Box>
    </div>
  );
};

export default VisaCard;
