import React, { useState } from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Box, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import HeaderLanding from "../components/HeaderLanding/HeaderLanding";
import { useAppSelector } from "src/store";
import FooterLanding from "../components/FooterLanding/FooterLanding";
import "./LandingPagesGlobal.scss";
import TitleBackBox from "../components/TitleBackBox/TitleBackBox";
import HeadlineVvidgetSmall from "../components/HeadlineVvidget/HeadlineVvidgetSmall";
import { now } from "moment";
import Terms from "src/components/TermsAndCondition/Terms";

const defaultTheme = createTheme();
const AmlPolicy: React.FC = () => {
  const { user } = useAppSelector((state) => state.auth);

  const [openAgreement, setOpenAgreement] = useState<any>(false);

  const navigate = useNavigate();

  const navigateToContactUs = (event: any) => {
    navigate("/contact-us");
  };
  const handleOpenTerms = (path: string) => {
    setOpenAgreement(now());
  };
  const openLinkInNewTab = (url: any) => {
    const newWindow = window.open(url, "_blank", "noopener,noreferrer");
    if (newWindow) newWindow.opener = null;
  };

  return (
    <div>
      <HeaderLanding user={user} />
      <div className="margintTopPagesMainCont">
        <ThemeProvider theme={defaultTheme}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Box className="titleHeadlineSmallBG">
              <Box className="landingPagesMainBox">
                <Box>
                  <TitleBackBox title="AML Policy" />
                </Box>
                <Box>
                  <HeadlineVvidgetSmall
                    headline={"Compliance Officer"}
                    subtitle={
                      "The Company appoints a designated Compliance Officer (MLRO) to implement and monitor performance of the procedures reflected in the AML Policy (CA)."
                    }
                    smallText={"Contact our support team"}
                    buttonText={"Contact Us"}
                    onClickFunction={navigateToContactUs}
                  />
                </Box>
              </Box>
            </Box>
            <Box className="landingPagesMainBox">
              <Box>
                <Typography
                  variant="h3"
                  paragraph
                  className="headlineMiniLandingPages"
                >
                  <strong>General Provisions</strong>
                </Typography>
                <Typography
                  variant="body1"
                  paragraph
                  className="textsLandingPage"
                >
                  System Services are provided, where applicable, by VicPay
                  Inc., (hereinafter “the Company”, “we”).
                  {/* , incorporation number
                  790478002BC0001, registered at 50 Carroll St., Toronto, ON, Canada
                  M4M3G3, is a registered provider of money service business
                  (MSB), regulated by the FINTRAC Canada, MSB registration
                  number: M23153297. */}
                </Typography>
                <Typography
                  variant="body1"
                  paragraph
                  className="textsLandingPage"
                >
                  The Company shall comply with the requirements contained in
                  the Proceeds of Crime (Money Laundering) and Terrorist
                  Financing Act (PCMLTFA) and associated Regulations, as well as
                  the requirements of other laws and regulations to the extent
                  in which they relate to the Company’s operations.
                </Typography>
                <Typography
                  variant="body1"
                  paragraph
                  className="textsLandingPage"
                >
                  The Company adheres to the policies and procedures outlined in
                  this document (hereinafter “the AML Statement”, “the
                  Statement” or “the policy”).
                </Typography>
                <Typography
                  variant="body1"
                  paragraph
                  className="textsLandingPage"
                >
                  The Company develops this AML Policy (CA), introduces
                  amendments and additions to it at its own discretion, and
                  oversees compliance with its provisions and requirements. The
                  Company also adopts internal policies and procedures to ensure
                  compliance with anti-money laundering and terrorist financing
                  laws.
                </Typography>
                <Typography
                  variant="body1"
                  paragraph
                  className="textsLandingPage"
                >
                  The current version of the AML Policy (CA) is always available
                  on the website{" "}
                  <span
                    className="linksHover"
                    onClick={() =>
                      openLinkInNewTab("https://www.vicpayments.com/")
                    }
                  >
                    {" "}
                    www.vicpayments.com
                  </span>
                  .
                </Typography>
                <Typography
                  variant="body1"
                  paragraph
                  className="textsLandingPage"
                >
                  The Customer shall read the AML Policy (CA) before accepting
                  the System{" "}
                  <span
                    className="linksHover"
                    onClick={() => handleOpenTerms("/terms-and-conditions")}
                  >
                    {" "}
                    Terms and Conditions
                  </span>
                  . Acceptance by the Customer of the Terms and Conditions, as
                  well as the performance by the Customer, who previously
                  accepted the Agreement, of transactions in the System, means
                  the Customer's consent to all the provisions of the current
                  version of this AML Policy (CA).
                </Typography>
                <Typography
                  variant="h3"
                  paragraph
                  className="headlineMiniLandingPages"
                >
                  <strong>
                    Appointment of a Designated Compliance Officer
                  </strong>
                </Typography>
                <Typography
                  variant="body1"
                  paragraph
                  className="textsLandingPage"
                >
                  The Company appoints a designated Compliance Officer (MLRO) to
                  implement and monitor performance of the procedures reflected
                  in the AML Policy (CA). The Compliance Officer is responsible
                  for the direction of the AML Policy (CA), and for ensuring
                  that all existing and future employees and business affiliates
                  of the Company adhere to the policy and procedural standards
                  outlined in this document.
                </Typography>
                <Typography
                  variant="body1"
                  paragraph
                  className="textsLandingPage"
                >
                  The Compliance Officer is responsible for the collection,
                  analysis, and investigation of information on any suspicious
                  activities and the training of the Company’s employees
                  pertaining to the relevant procedures; the Compliance Officer
                  shall determine the procedures and rules for carrying out
                  Customers’ identification, reviewing and monitoring unusual
                  transactions and technical features of the Company’s
                  implementation of this AML Policy (CA); assessing the adequacy
                  of system resources, including those required to identify and
                  report suspicious and attempted suspicious transactions;
                  ensuring that customer due diligence (“CDD”) and enhanced due
                  diligence (“EDD”) is conducted.
                </Typography>
                <Typography
                  variant="h3"
                  paragraph
                  className="headlineMiniLandingPages"
                >
                  <strong>Customer Information Collected</strong>
                </Typography>
                <Typography
                  variant="body1"
                  paragraph
                  className="textsLandingPage"
                >
                  The Company uses specific procedures for identification and
                  verification of Customers.
                </Typography>
                <Typography
                  variant="body1"
                  paragraph
                  className="textsLandingPage"
                >
                  From Customers who request demo access to the System
                  (financial transactions are not available), the Company
                  requests only the name and email address.
                </Typography>
                <Typography
                  variant="body1"
                  paragraph
                  className="textsLandingPage"
                >
                  In order to get full access to the System Services, Customers
                  are subject to identification procedure. For the purposes of
                  Customers’ identification, the Company requests the following:
                </Typography>
                <Typography
                  variant="body1"
                  component="ul"
                  sx={{ marginLeft: "22px" }}
                  paragraph
                  className="textsLandingPage"
                >
                  <li className="textsLandingPage">
                    proof of identity document (passport, driving license,
                    national identity card, etc.);
                  </li>
                  <li className="textsLandingPage">
                    proof of address (bank statement, utility bill, etc.);
                  </li>
                  <li className="textsLandingPage">
                    phone number verification by receiving a code via SMS;
                  </li>
                  <li className="textsLandingPage">
                    requires to undergo a liveness check via KYC service
                    provider.
                  </li>
                </Typography>
                <Typography
                  variant="body1"
                  paragraph
                  className="textsLandingPage"
                >
                  Information on the purpose and intended nature of the account
                  opening (“PIN”), source of wealth, occupation, and name of
                  employer. The PIN provides context for the types of
                  transactions and activities that the customer conducts.
                </Typography>
                <Typography
                  variant="body1"
                  paragraph
                  className="textsLandingPage"
                >
                  The Company places transaction limits on all individual
                  transactions. For customers with higher limits, the Company
                  requires that customers provide the additional information and
                  documents to fulfill the enhanced KYC requirements, including,
                  but not limited to, confirmation of source of funds (i.e.:
                  income statement, tax receipt, inheritance proof form, bank
                  statement, etc.). All requests for higher limits are reviewed
                  on a case-by-case basis with a focus on validating the source
                  of wealth.
                </Typography>
                <Typography
                  variant="h3"
                  paragraph
                  className="headlineMiniLandingPages"
                >
                  <strong>Customer Identity Verification</strong>
                </Typography>
                <Typography
                  variant="body1"
                  paragraph
                  className="textsLandingPage"
                >
                  When the Company is required to verify the identity of an
                  individual, the government photo identification document
                  method must be used. The Company relies on valid, current, and
                  authentic photo identification documents (“ID”), issued by a
                  federal, provincial, or territorial government to verify the
                  identity of an individual. An ID issued by a municipal
                  government (either Canadian or foreign) is not acceptable.
                </Typography>
                <Typography
                  variant="body1"
                  paragraph
                  className="textsLandingPage"
                >
                  The Company does not accept an ID when:
                </Typography>
                <Typography
                  variant="body1"
                  component="ul"
                  paragraph
                  sx={{ marginLeft: "22px" }}
                  className="textsLandingPage"
                >
                  <li className="textsLandingPage">
                    it does not indicate the individual’s name, or the name that
                    the individual provided does not match the name appearing on
                    the ID;
                  </li>
                  <li className="textsLandingPage">
                    it does not have a photo, or the photo on the ID provided
                    does not match the likeness of the individual presenting it;
                  </li>
                  <li className="textsLandingPage">
                    the ID does not have a unique identifier; or
                  </li>
                  <li className="textsLandingPage">the ID is expired.</li>
                </Typography>
                <Typography
                  variant="body1"
                  paragraph
                  style={{ marginTop: "1rem" }}
                  className="textsLandingPage"
                >
                  When verifying the identity of an individual remotely, the
                  Company relies on a third-party provider that verifies the
                  authenticity of the ID, conducts “liveness testing”, and
                  confirms that the ID belongs to the individual whose identity
                  is being verified.
                </Typography>
                <Typography
                  variant="body1"
                  paragraph
                  className="textsLandingPage"
                >
                  The Company conducts CDD and EDD procedures to avoid the risk
                  of being held liable and to protect Customers and itself from
                  a Customer’s attempts to use the services for carrying out
                  illegal activities.
                </Typography>
                <Typography
                  variant="body1"
                  paragraph
                  className="textsLandingPage"
                >
                  As part of the CDD procedures, the Company evaluates
                  Customers’ transactions, as well as collects and stores
                  information on the essential facts pertaining to Customers,
                  potential Customers, and their transactions.
                </Typography>
                <Typography
                  variant="body1"
                  paragraph
                  className="textsLandingPage"
                >
                  After carrying out the identification procedures pertaining to
                  a Customer, the Company stores the information obtained in
                  this Customer’s file. The Company retains information on
                  potential Customers to whom access to the services was denied
                  due to the AML Policy (CA) and procedures.
                </Typography>
                <Typography
                  variant="body1"
                  paragraph
                  className="textsLandingPage"
                >
                  The Company is committed to protecting Customers’ privacy
                  rights and the confidentiality of their personal data. The
                  Company collects personal data from Customers only to the
                  extent necessary to ensure the Company is properly providing
                  services to Customers and to comply with the applicable laws.
                  Such personal data of Customers and former Customers may be
                  disclosed to third parties only in a limited number of
                  circumstances, in accordance with the applicable laws and
                  agreements between the Company and the Customer. Privacy
                  Notice is available here:{" "}
                  <span
                    className="linksHover"
                    onClick={() =>
                      openLinkInNewTab("https://www.vicpayments.com/")
                    }
                  >
                    {" "}
                    www.vicpayments.com
                  </span>
                  .
                </Typography>
                <Typography
                  variant="body1"
                  paragraph
                  className="textsLandingPage"
                >
                  The Company shall carefully maintain at head office Customers’
                  files, including statements, transaction reports, receipts,
                  notes, internal correspondence, and any other documents
                  related to the Customer in a machine-readable or electronic
                  form for a period of 5 (five) years after: the day on which
                  the account to which they relate is closed, or the day on
                  which the last business transaction is conducted; or the day
                  on which they were created depending on the nature of a record
                  as defined in article 148 of Proceeds of Crime (Money
                  Laundering) and Terrorist Financing Regulations (PCMLFTR).
                </Typography>
                <Typography
                  variant="h3"
                  paragraph
                  className="headlineMiniLandingPages"
                >
                  <strong>Periodic Review of Collected Information</strong>
                </Typography>
                <Typography
                  variant="body1"
                  paragraph
                  className="textsLandingPage"
                >
                  The Company will review the information, data and documents
                  obtained from the Customer together with the risk assessment
                  periodically to ensure that the information and the assessment
                  are up-to-date and remain relevant. The frequency of such
                  reviews shall be determined by the level of risk the Customer
                  poses or at trigger events.
                </Typography>
                <Typography
                  variant="body1"
                  paragraph
                  className="textsLandingPage"
                >
                  All customer profiles are subject to ongoing monitoring to:
                </Typography>
                <Typography
                  variant="body1"
                  component="ul"
                  paragraph
                  sx={{ marginLeft: "22px" }}
                  className="textsLandingPage"
                >
                  <li className="textsLandingPage">
                    classify and periodically reassess customer risk level based
                    on transactions and activities;
                  </li>
                  <li className="textsLandingPage">
                    keep customer identification and PIN records up to date; and
                  </li>
                  <li className="textsLandingPage">
                    detect any suspicious transactions.
                  </li>
                </Typography>
                <Typography
                  variant="body1"
                  paragraph
                  style={{ marginTop: "1rem" }}
                  className="textsLandingPage"
                >
                  Customer information is updated on an ongoing basis, if in the
                  course of operations new information becomes available. If
                  during a customer information review, any of the following has
                  not been updated, the Company takes steps to update the
                  following information:
                </Typography>
                <Typography
                  variant="body1"
                  component="ul"
                  paragraph
                  sx={{ marginLeft: "22px" }}
                  className="textsLandingPage"
                >
                  <li className="textsLandingPage">Customer’s name;</li>
                  <li className="textsLandingPage">Customer’s address;</li>
                  <li className="textsLandingPage">Customer’s occupation;</li>
                  <li className="textsLandingPage">
                    Customer’s telephone number and email; and
                  </li>
                  <li className="textsLandingPage">
                    Customer’s PEP/HIO status.
                  </li>
                </Typography>
                <Typography
                  variant="body1"
                  paragraph
                  style={{ marginTop: "1rem" }}
                  className="textsLandingPage"
                >
                  The PIN record is considered during reviews of unusual
                  transactions and during scheduled transaction reviews. If the
                  transaction activity does not appear to be consistent with the
                  stated PIN, the Customer is contacted to update it.
                </Typography>
                <Typography
                  variant="h3"
                  paragraph
                  className="headlineMiniLandingPages"
                >
                  <strong>Third-Party Determination</strong>
                </Typography>
                <Typography
                  variant="body1"
                  paragraph
                  className="textsLandingPage"
                >
                  The Company takes reasonable measures to determine whether a
                  Customer is acting on behalf of a third party and keeps
                  third-party determination records.
                </Typography>
                <Typography
                  variant="h3"
                  paragraph
                  className="headlineMiniLandingPages"
                >
                  <strong>Classification of Customer Risk</strong>
                </Typography>
                <Typography
                  variant="body1"
                  paragraph
                  className="textsLandingPage"
                >
                  The Company conducts and documents a risk assessment of the
                  relationship with the Customer and conducts due diligence
                  measures consistent with the Company’s risk assessment to
                  ensure that enhanced measures are put in place for high-risk
                  relationships.
                </Typography>
                <Typography
                  variant="body1"
                  paragraph
                  className="textsLandingPage"
                >
                  The Company uses an industry recognized software provider to
                  assist in the risk assessment process. Risk ratings are
                  applied in a manner that is consistent with the Company’s risk
                  assessment.
                </Typography>
                <Typography
                  variant="body1"
                  paragraph
                  className="textsLandingPage"
                >
                  The Company assigns a risk rating to each customer. A
                  classification of high risk is determined by several factors
                  including transaction type, certain patterns of transaction
                  activity, and customer specific characteristics. Customers are
                  assessed as low risk if they are not assessed as high risk.
                </Typography>
                <Typography
                  variant="body1"
                  paragraph
                  className="textsLandingPage"
                >
                  All information collected as part of the customer risk
                  assessment, including if a Customer is rated high risk,
                  becomes part of the customer profile.
                </Typography>
                <Typography
                  variant="body1"
                  paragraph
                  className="textsLandingPage"
                >
                  Excessive risk occurs in situations where the risk of dealing
                  with a certain individual, entity, or customer type is too
                  high for the Company to accept the relationship with the
                  Customer.
                </Typography>
                <Typography
                  variant="body1"
                  paragraph
                  className="textsLandingPage"
                >
                  Risk ratings are updated on an ongoing and periodic basis and
                  are based on factors that include, but are not limited to:
                </Typography>
                <Typography
                  variant="body1"
                  component="ul"
                  paragraph
                  sx={{ marginLeft: "22px" }}
                  className="textsLandingPage"
                >
                  <li className="textsLandingPage">
                    transaction activity; and
                  </li>
                  <li className="textsLandingPage">
                    characteristics, including PEP/HIO and sanctions status.
                  </li>
                </Typography>
                <Typography
                  variant="body1"
                  paragraph
                  style={{ marginTop: "1rem" }}
                  className="textsLandingPage"
                >
                  A reassessment of customer risk may also be triggered by:
                </Typography>
                <Typography
                  variant="body1"
                  component="ul"
                  sx={{ marginLeft: "22px" }}
                  paragraph
                  className="textsLandingPage"
                >
                  <li className="textsLandingPage">
                    becoming aware of illegal or illicit activities;
                  </li>
                  <li className="textsLandingPage">
                    a change in characteristics, including PIN or other risk
                    factors for assessing customer risk; and
                  </li>
                  <li className="textsLandingPage">
                    submitting a report to intelligence or enforcement agencies
                    with respect to suspicious transactions or terrorist
                    property.
                  </li>
                </Typography>
                <Typography
                  variant="body1"
                  paragraph
                  style={{ marginTop: "1rem" }}
                  className="textsLandingPage"
                >
                  Company’s monitoring software assists in its ongoing
                  assessment of relationship-based risk by adjusting a
                  customer’s numerical risk score whenever there is a change to
                  the customer information, or as a result of transaction
                  activity. The numeric score is updated for a variety of
                  attributes and related formulas which are associated with
                  higher ML/TF risk in publications issued by FINTRAC and FATF
                  and Company’s risk assessment.
                </Typography>
                <Typography
                  variant="h3"
                  paragraph
                  className="headlineMiniLandingPages"
                >
                  <strong>Enhanced Due Diligence</strong>
                </Typography>
                <Typography
                  variant="body1"
                  paragraph
                  className="textsLandingPage"
                >
                  The Company applies EDD measures when a Customer is determined
                  to be high risk. Measures are designed to mitigate the
                  specific risks posed by each high-risk Customer to
                  sufficiently mitigate a customer’s risk.
                </Typography>
                <Typography
                  variant="h3"
                  paragraph
                  className="headlineMiniLandingPages"
                >
                  <strong>Ongoing Monitoring of Transactions</strong>
                </Typography>
                <Typography
                  variant="body1"
                  paragraph
                  className="textsLandingPage"
                >
                  The Company applies various checking and monitoring algorithms
                  to make sure all required CDD procedures are executed in a
                  timely manner, persons with limitations (sanctions list and
                  politically exposed persons screening) are detected, and
                  comprehensive automated online monitoring is carried out.
                  Financial data analysis includes several major components:
                </Typography>
                <Typography
                  variant="body1"
                  component="ul"
                  paragraph
                  sx={{ marginLeft: "22px" }}
                  className="textsLandingPage"
                >
                  <li className="textsLandingPage">
                    Monitoring of sanctions lists;
                  </li>
                  <li className="textsLandingPage">
                    Monitoring of user activity and user system environment;
                  </li>
                  <li className="textsLandingPage">Transaction monitoring;</li>
                  <li className="textsLandingPage">
                    Analysis of remaining balances, exchange rate fluctuations
                    and other aspects; and
                  </li>
                  <li className="textsLandingPage">
                    Tools enhancing manual data analysis possibilities.
                  </li>
                </Typography>
                <Typography
                  variant="body1"
                  paragraph
                  className="textsLandingPage"
                  style={{ marginTop: "1rem" }}
                >
                  The Company applies risk-based approach to define account and
                  transaction risk level. The Company has implemented automated
                  risk assessment system (RAS) to analyse the risk profile of
                  the users and ongoing transactions to prevent illegal and
                  fraudulent activities. However, any significant decisions that
                  may impact the Customer are subject to a manual review.
                </Typography>
                <Typography
                  variant="body1"
                  paragraph
                  className="textsLandingPage"
                >
                  Transaction monitoring is conducted systematically by the
                  Company’s third-party systems both in real-time and
                  historically by generating alerts for transactions and
                  transaction patterns that meet a series of rules within the
                  system. These rules are based on a variety of attributes and
                  related formulas which are associated with higher ML/TF risk
                  in publications issued by FINTRAC and the FATF and adjusted
                  for based on the Corporation’s Risk Assessment.
                </Typography>
                <Typography
                  variant="h3"
                  paragraph
                  className="headlineMiniLandingPages"
                >
                  <strong>
                    Identification and Detection of Suspicious Activities
                  </strong>
                </Typography>
                <Typography
                  variant="body1"
                  paragraph
                  className="textsLandingPage"
                >
                  The Company understands the importance of identifying and
                  detecting suspicious activity through monitoring and reviewing
                  the activity of customer transactions. The Company has a
                  process for reviewing transactions to identify potentially
                  suspicious activity and takes reasonable measures to identify
                  individuals and entities who conduct or attempt to conduct a
                  suspicious transaction. The Company monitors the transactions
                  for evidence of certain patterns of activity that could be
                  indicative of suspicious activity, such as whether the
                  transactions are consistent with the information known about
                  the Customer or the transactions are demonstrative of known
                  suspicious indicators. Monitoring is also conducted to
                  determine whether a customer’s current risk rating needs to be
                  adjusted. Any financial transaction that may be related to
                  money laundering activities shall be considered to be
                  suspicious activities.
                </Typography>
                <Typography
                  variant="body1"
                  paragraph
                  className="textsLandingPage"
                >
                  Grounds for determining that a specific transaction is
                  suspicious may be personal observations and experience of the
                  Company’s employees, as well as information received or
                  identified. Suspicious activity includes a transaction that
                  any employee knows or suspects to: involve proceeds from an
                  illegal activity; evade currency transaction reporting
                  requirements; vary significantly from the customer’s normal
                  transactions; a third party gained access to the customer's
                  account or the activities are performed under instructions of
                  a third party; or has no business or apparent lawful purpose
                  and the Company knows of no reasonable explanation for the
                  transaction after examining the available facts, including the
                  background and possible purpose of the transaction.
                </Typography>
                <Typography
                  variant="body1"
                  paragraph
                  className="textsLandingPage"
                >
                  The Company will apply enhanced scrutiny to manually monitor
                  customer transactions, in a manner reasonably designed to
                  detect money laundering and suspicious activity. To identify
                  suspicious transactions, the Company is entitled to perform
                  enhanced due diligence measures and request additional
                  information from the Customer confirming the economic purpose
                  of the transaction and the origin of funds. Any potentially
                  suspicious activity is escalated to the MLRO for
                  investigation. In conducting their assessment as to whether
                  there are reasonable grounds to suspect, the MLRO must
                  consider additional information sources such as the customer’s
                  transaction history, as well as customer characteristics and
                  information (such as the PIN) that are contained in the
                  customer’s file. If required, the MLRO may elect to contact
                  the customer or employee to obtain additional information
                  about the transaction.
                </Typography>
                <Typography
                  variant="body1"
                  paragraph
                  className="textsLandingPage"
                >
                  In accordance with the applicable laws of Canada and the
                  requirements of international organizations, the Company may,
                  where appropriate and without the obligation of obtaining the
                  Customer’s approval or notifying the Customer, notify
                  regulating and/or law enforcement agencies of any suspicious
                  transactions. Different requirements for reporting suspicious
                  transactions may depend on the nature and amount of a
                  transaction.
                </Typography>
                <Typography
                  variant="body1"
                  paragraph
                  className="textsLandingPage"
                >
                  The Company shall continuously conduct due diligence
                  procedures pertaining to its Customers and scrutinize
                  transactions carried out by them to ensure these transactions’
                  compatibility with the Company’s knowledge of its Customers,
                  their business and, when necessary, their source of funds.
                </Typography>
                <Typography
                  variant="h3"
                  paragraph
                  className="headlineMiniLandingPages"
                >
                  <strong>PEP, HIO and Sanctions Screening</strong>
                </Typography>
                <Typography
                  variant="body1"
                  paragraph
                  className="textsLandingPage"
                >
                  The Company shall comply with the requirements contained in
                  the Canada’s legislative measure against terrorists, terrorist
                  groups, and other listed and sanctioned individuals and
                  entities are contained in various Canadian statutes and
                  regulations, or those adopted by Canada, including the
                  Criminal Code of Canada, United Nations Act, and Special
                  Economic Measures Act (“SEMA”). Specific measures vary
                  depending on the relevant legislation, but broadly include:
                  prohibitions in dealing with property owned or controlled by
                  any person or entity listed on a government created list, or
                  one created by a relevant regulatory body, of those
                  individuals or entities associated with or suspected of being
                  associated with terrorism (“Designated Persons”); prohibitions
                  on providing any financial or related services in respect of
                  property owned or controlled by Designated Persons; and
                  prohibitions on entering or facilitating transactions with, or
                  making available property or financial services to, Designated
                  Persons.
                </Typography>
                <Typography
                  variant="body1"
                  paragraph
                  className="textsLandingPage"
                >
                  The Company does not knowingly enter into transactions with,
                  or provide or assist transfers to, or for the benefit of,
                  governments, entities, charities, organizations, and
                  individuals targeted by required sanctions, including, but not
                  limited to, Canadian anti-terrorism measures and Canadian
                  economic sanctions. To that end, the Company screens its
                  customer database and payments records for the names of
                  Designated Persons.
                </Typography>
                <Typography
                  variant="body1"
                  paragraph
                  className="textsLandingPage"
                >
                  The Company is required to determine if a customer is a
                  politically exposed person (“PEP”)/head of an international
                  organization (“HIO”). Reasonable measures to make a PEP
                  determination include:
                </Typography>
                <Typography
                  variant="body1"
                  component="ul"
                  paragraph
                  sx={{ marginLeft: "22px" }}
                  className="textsLandingPage"
                >
                  <li className="textsLandingPage">
                    Asking the Customer if they are a PEP and documenting their
                    response; and/or
                  </li>
                  <li className="textsLandingPage">
                    Independently confirming the customer’s PEP status by using
                    an industry-recognized database.
                  </li>
                </Typography>
                <Typography
                  variant="body1"
                  paragraph
                  className="textsLandingPage"
                >
                  The Company does not knowingly enter into transactions with,
                  or provide or assist transfers to, or for the benefit of the
                  Politically Exposed Persons.
                </Typography>
                <Typography
                  variant="body1"
                  paragraph
                  className="textsLandingPage"
                >
                  The Company uses an external industry recognized software
                  provider for PEP, HIO and Sanctions screening, which
                  automatically scans names in the system to find potential name
                  matches.
                </Typography>
                <Typography
                  variant="body1"
                  paragraph
                  className="textsLandingPage"
                >
                  Scans are conducted at onboarding and every transaction for
                  all names collected as part of that transaction, including
                  counterparties and any known third parties.
                </Typography>
                <Typography
                  variant="body1"
                  paragraph
                  className="textsLandingPage"
                >
                  The Company shall periodically refer to and consult the lists
                  published by the authorities of Canada, other countries and
                  international organizations that contain lists of known
                  terrorists or persons suspected of terrorist activities,
                  terrorist organizations, high-risk countries, a limited list
                  of countries subject to the OFAC sanctions, jurisdictions that
                  do not provide sufficient level of anti-money laundering
                  procedures, as well as countries subject to sanctions to
                  determine whether the Company’s Customer or potential
                  Customer, and/or such Customer’s country of jurisdiction is
                  included in the above lists.
                </Typography>
                <Typography
                  variant="body1"
                  paragraph
                  className="textsLandingPage"
                >
                  The Company continuously conducts checks against the Sanctions
                  lists and the lists promulgated pursuant to the laws listed
                  below:
                </Typography>
                <Typography
                  variant="body1"
                  component="ul"
                  paragraph
                  sx={{ marginLeft: "22px" }}
                  className="textsLandingPage"
                >
                  <li className="textsLandingPage">Criminal Code;</li>
                  <li className="textsLandingPage">United Nations Act;</li>
                  <li className="textsLandingPage">
                    Justice for Victims of Corrupt Foreign Officials Act;
                  </li>
                  <li className="textsLandingPage">
                    Freezing the Assets of Corrupt Foreign Officials Act;
                  </li>
                  <li className="textsLandingPage">SEMA;</li>
                  <li className="textsLandingPage">UN Sanctions;</li>
                  <li className="textsLandingPage">Interpol Red List;</li>
                  <li className="textsLandingPage">HM Treasury;</li>
                  <li className="textsLandingPage">US OFAC Sanctions;</li>
                  <li className="textsLandingPage">UK Sanctions;</li>
                  <li className="textsLandingPage">
                    EU Consolidated list of Sanctions.
                  </li>
                </Typography>
                <Typography
                  variant="body1"
                  paragraph
                  className="textsLandingPage"
                >
                  To adjudicate a potential name match, common differentiators
                  such as date of birth or country of origin are compared to
                  identification information collected to determine the veracity
                  of the match. If the match is a true match, any customer funds
                  in possession are held and no transactions are processed
                  (regardless of the status of the transactions) without an
                  evidence-based discount of the match, or written judicial, law
                  enforcement, or ministerial direction.
                </Typography>
                <Typography
                  variant="h3"
                  paragraph
                  className="headlineMiniLandingPages"
                >
                  Third Parties
                </Typography>
                <Typography
                  variant="body1"
                  paragraph
                  className="textsLandingPage"
                >
                  To perform some services and conduct business activities, the
                  Company uses third-party service providers. The company shall
                  try to determine, during the initial and ongoing due diligence
                  process, to the extent possible whether there are any
                  initiated investigations and filed lawsuits against any such
                  third-party service providers. The company shall also
                  determine whether a third-party provider has obtained all the
                  necessary licenses, permits, and approvals before establishing
                  a business relationship with such third-party service
                  provider.
                </Typography>
                <Typography
                  variant="body1"
                  paragraph
                  className="textsLandingPage"
                >
                  Regarding its own staff, the Company shall carefully review
                  all candidates for employment and determine whether the
                  activities of a new employee fall in the category that is
                  susceptible to money laundering activities. In addition, the
                  Company has prepared and implements a number of personnel
                  training programs on customer identification procedures and
                  prevention of money laundering activities.
                </Typography>
                <Typography
                  variant="h3"
                  paragraph
                  className="headlineMiniLandingPages"
                >
                  Civil and Criminal Penalties
                </Typography>
                <Typography
                  variant="body1"
                  paragraph
                  className="textsLandingPage"
                >
                  Government authorities and international organizations may
                  impose severe civil and criminal penalties against any person
                  who violates the laws and regulations referred to in paragraph
                  1.2 of the AML Policy (CA). Such civil and criminal penalties
                  may include fines in the amount of up to millions of dollars,
                  and the term of criminal punishment may be up to 10 (ten)
                  years in prison. In addition, government authorities may
                  confiscate any property involved in criminal violation of
                  these laws and regulations, including companies, bank
                  accounts, or any other assets that may be associated with
                  criminal violations.
                </Typography>
                <Typography
                  variant="h3"
                  paragraph
                  className="headlineMiniLandingPages"
                >
                  Compliance Statement
                </Typography>
                <Typography
                  variant="body1"
                  className="textsLandingPage"
                  paragraph
                >
                  The Customer certifies that he/she has read and understood
                  this AML Policy (CA), and that they shall operate in full
                  compliance with the requirements and standards outlined in the
                  AML Policy (CA) and comply with all applicable laws and other
                  regulations and requirements governing its activities as a
                  customer.
                </Typography>
                <Typography
                  variant="body1"
                  paragraph
                  className="textsLandingPage"
                >
                  The Customer acknowledges that he/she is responsible for
                  his/her actions in accordance with the effective laws in the
                  field discussed in this AML Policy (CA) and shall bear
                  responsibility pertaining to failure to comply with such laws.
                </Typography>
              </Box>
            </Box>
          </Box>
        </ThemeProvider>
      </div>
      <Box sx={{ marginTop: "4rem" }}>
        <FooterLanding />
      </Box>
      <Terms
        margin={5}
        openForce={openAgreement}
        hideButton={true}
        hideCheckbox={true}
        onChecked={() => console.log("Checked")}
        type="customer-agreement-personal"
      />
    </div>
  );
};

export default AmlPolicy;
