import { Typography } from "@mui/material";
import { formatDateTime } from "src/utils/helpers";
import "./TableList.scss";
interface Props {
  item: any;
  index: number;
}

function TableListItem({ item, index }: Props) {
  return (
    <div className="transaction-item">
      <div className="d-flex ai-center jc-center">
        <Typography
          className="oneRemFontSize"
          component="h1"
          variant="h5"
          sx={{
            fontFamily: "Helvetica, sans-serif",
            fontWeight: "600",
            color: "#454745",
            paddingRight: "1rem",
            marginBottom: "0.5rem",
          }}
        >
          {index + 1}.
        </Typography>
        <div>
          <Typography
            component="h1"
            variant="h5"
            className="oneRemFontSize"
            sx={{
              fontFamily: "Helvetica, sans-serif",
              fontWeight: "600",
              color: "#454745",
              marginBottom: "0.5rem",
            }}
          >
            Code
            {/* {item?.recipient?.account_holder} */}
          </Typography>
          <Typography
            component="h1"
            variant="h5"
            className="registration0875RemFontSize"
            sx={{
              fontFamily: "Helvetica, sans-serif",
              fontWeight: "300",
              color: "#454745",
            }}
          >
            {item?.code ?? "/"}
          </Typography>
        </div>
      </div>
      <div className="ta-end">
        <Typography
          component="h1"
          variant="h5"
          className="oneRemFontSize"
          sx={{
            fontFamily: "Helvetica, sans-serif",
            fontWeight: "600",
            color: "#454745",
            marginBottom: "0.5rem",
          }}
        >
          Time
        </Typography>
        <Typography
          component="h1"
          variant="h5"
          className="registration0875RemFontSize"
          sx={{
            fontFamily: "Helvetica, sans-serif",
            fontWeight: "300",
            color: "#454745",
          }}
        >
          {item?.created_at ? formatDateTime(item.created_at) : ""}
        </Typography>
      </div>
    </div>
  );
}

export default TableListItem;
