import React from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Box, Typography } from "@mui/material";
import HeaderLanding from "../components/HeaderLanding/HeaderLanding";
import { useAppSelector } from "src/store";
import FooterLanding from "../components/FooterLanding/FooterLanding";
import "./LandingPagesGlobal.scss";
import TitleBackBox from "../components/TitleBackBox/TitleBackBox";
import HeadlineVvidgetSmall from "../components/HeadlineVvidget/HeadlineVvidgetSmall";
import { useNavigate } from "react-router-dom";
import i18next from "i18next";
const defaultTheme = createTheme();

const InHouseWorkshop: React.FC = () => {
  const { user } = useAppSelector((state) => state.auth);
  const navigate = useNavigate();

  const navigateToRegistration = (event: any) => {
    navigate("/registration");
  };

  return (
    <div>
      <HeaderLanding user={user} />
      <div className="margintTopPagesMainCont">
        <ThemeProvider theme={defaultTheme}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Box className="titleHeadlineSmallBG">
              <Box className="landingPagesMainBox">
                <Box>
                  <TitleBackBox title={i18next.t("House workshop")} />
                </Box>
                <Box>
                  <HeadlineVvidgetSmall
                    headline={i18next.t(
                      "Meet the team and learn more about VicPay and our services!"
                    )}
                    smallText={i18next.t("Join customers all over the world")}
                    buttonText={i18next.t("Create a free account")}
                    onClickFunction={navigateToRegistration}
                  />
                </Box>
              </Box>
            </Box>
            <Box className="landingPagesMainBox">
              <Box>
                <Typography
                  variant="h3"
                  sx={{
                    marginBottom: "1rem",
                  }}
                  className="headlineMiniLandingPages"
                >
                  {i18next.t(
                    "VicPay's Commitment to Technological Advancement"
                  )}
                </Typography>
                <Typography
                  variant="body1"
                  paragraph
                  className="textsLandingPage"
                >
                  {i18next.t(
                    "Technology is developing at breakneck speed, the interconnectedness of the world and the financial and currency markets is constantly increasing and requires constant training of employees and further development of the technology here at VicPay."
                  )}
                </Typography>
              </Box>
              <Box>
                <Typography
                  variant="h3"
                  sx={{
                    marginBottom: "1rem",
                  }}
                  className="headlineMiniLandingPages"
                >
                  {i18next.t("VicPay's Customer-Centric Approach")}
                </Typography>
                <Typography
                  variant="body1"
                  paragraph
                  className="textsLandingPage"
                >
                  {i18next.t(
                    "Our success is based in part on our close relationship and dialogue with our customers: we may offer transfers and cryptocurrencies online, but we are just as available physically as we are digitally!"
                  )}
                </Typography>
                <Typography
                  variant="body1"
                  paragraph
                  className="textsLandingPage"
                >
                  {i18next.t(
                    "That's why we offer regular workshops at our Vancouver office, but also online via video conferencing on various topics."
                  )}
                </Typography>
              </Box>
              <Box>
                <Typography
                  variant="h3"
                  sx={{
                    marginBottom: "1rem",
                  }}
                  className="headlineMiniLandingPages"
                >
                  {i18next.t("VicPay's Comprehensive Support and Features")}
                </Typography>
                <Typography
                  variant="body1"
                  paragraph
                  className="textsLandingPage"
                >
                  {i18next.t(
                    "We are firmly convinced that our customers are more satisfied than average because we offer a maximum of coaching, support, workshops and other individualised care, which is unfortunately neglected by many payment providers"
                  )}
                </Typography>
                <Typography
                  variant="body1"
                  paragraph
                  className="textsLandingPage"
                >
                  {i18next.t(
                    "It's actually quite simple: we have developed a great platform with many fantastic functions, which is continuously being developed, improved and expanded."
                  )}
                </Typography>
                <Typography
                  variant="body1"
                  paragraph
                  className="textsLandingPage"
                >
                  {i18next.t(
                    "Use the simple and uncomplicated functions of our platform right away, but there is much more:"
                  )}
                </Typography>
                <Typography
                  variant="body1"
                  component="ul"
                  paragraph
                  sx={{ marginLeft: "22px" }}
                  className="textsLandingPage"
                >
                  <li className="textsLandingPage">
                    {i18next.t(
                      "Find out more about the extensive automation functions of our platform"
                    )}
                  </li>
                  <li className="textsLandingPage">
                    {i18next.t(
                      "Meet our team in person and get to know our management team"
                    )}
                  </li>
                  <li className="textsLandingPage">
                    {i18next.t(
                      "Learn about methods to significantly reduce currency conversion costs"
                    )}
                  </li>
                  <li className="textsLandingPage">
                    {i18next.t(
                      "Immerse yourself in the world of cryptocurrencies and blockchain technology"
                    )}
                  </li>
                  <li className="textsLandingPage">
                    {i18next.t(
                      "Receive information on how you can save high costs with SEPA transfers"
                    )}
                  </li>
                  <li className="textsLandingPage">
                    {i18next.t(
                      "Bulk transfers, scheduled transfers, automatic conversion and more - our features for companies"
                    )}
                  </li>
                  <li className="textsLandingPage">
                    {i18next.t(
                      "For online shops: Integration of VicPay into webshops and existing payment processes"
                    )}
                  </li>
                </Typography>
                <Typography
                  variant="body1"
                  paragraph
                  className="textsLandingPage"
                >
                  {i18next.t(
                    "With a few exceptions, our workshops are free of charge. Should costs arise, these will be noted in detail."
                  )}
                </Typography>
              </Box>
              <Box sx={{ marginBottom: "1rem" }}>
                <Typography
                  variant="h3"
                  sx={{
                    marginBottom: "1rem",
                  }}
                  className="headlineMiniLandingPages"
                >
                  {i18next.t("Questions about Workshops?")}
                </Typography>
                <Typography
                  variant="body1"
                  paragraph
                  className="textsLandingPage"
                >
                  {i18next.t(
                    "If you have any questions about one of our workshops, please do not hesitate to contact us at +1 604-260-0738 - we will be happy to help you!"
                  )}
                </Typography>
              </Box>
              <Box
                sx={{ display: "flex", gap: "20px", flexDirection: "column" }}
              >
                <Typography
                  variant="body1"
                  paragraph
                  className="textsLandingPage"
                >
                  <span className="textsLandingsmallNoFontSize">
                    {i18next.t("WORKSHOP 1:")}
                  </span>
                  <br />
                  <span className="textsLandingsmallNoFontSize">
                    {i18next.t("Topic")}
                  </span>
                  <br />
                  <span className="textsLandingsmallNoFontSize">
                    {" "}
                    {i18next.t("When")}
                  </span>{" "}
                  {i18next.t("2023 / December 01 / Fr PM - 6 PM")}
                  <br />
                  <span className="textsLandingsmallNoFontSize">
                    {i18next.t("Where")}
                  </span>
                </Typography>
                <Typography
                  variant="body1"
                  paragraph
                  className="textsLandingPage"
                >
                  <span className="textsLandingsmallNoFontSize">
                    {i18next.t("WORKSHOP 2:")}
                  </span>
                  <br />
                  <span className="textsLandingsmallNoFontSize">
                    {i18next.t("Topic")}
                  </span>
                  <br />
                  <span className="textsLandingsmallNoFontSize">
                    {" "}
                    {i18next.t("When")}
                  </span>{" "}
                  {i18next.t("2024 / January 12 / Fr PM - 6 PM")}
                  <br />
                  <span className="textsLandingsmallNoFontSize">
                    {" "}
                    {i18next.t("Where")}
                  </span>
                </Typography>
                <Typography
                  variant="body1"
                  paragraph
                  className="textsLandingPage"
                >
                  <span className="textsLandingsmallNoFontSize">
                    {i18next.t("WORKSHOP 3:")}
                  </span>
                  <br />
                  <span className="textsLandingsmallNoFontSize">
                    {i18next.t("Topic")}
                  </span>
                  <br />
                  <span className="textsLandingsmallNoFontSize">
                    {" "}
                    {i18next.t("When")}
                  </span>{" "}
                  {i18next.t("2024 / February 02 / Fr PM - 6 PM")}
                  <br />
                  <span className="textsLandingsmallNoFontSize">
                    {" "}
                    {i18next.t("Where")}
                  </span>
                </Typography>
                <Typography
                  variant="body1"
                  paragraph
                  className="textsLandingPage"
                >
                  <span className="textsLandingsmallNoFontSize">
                    {i18next.t("WORKSHOP 4:")}
                  </span>
                  <br />
                  <span className="textsLandingsmallNoFontSize">
                    {i18next.t("Topic")}
                  </span>
                  <br />
                  <span className="textsLandingsmallNoFontSize">
                    {" "}
                    {i18next.t("When")}
                  </span>{" "}
                  {i18next.t("2024 / Mar 01 / Fr 4 PM - 6 PM")}
                  <br />
                  <span className="textsLandingsmallNoFontSize">
                    {" "}
                    {i18next.t("Where")}
                  </span>
                </Typography>
                <Typography
                  variant="body1"
                  paragraph
                  className="textsLandingPage"
                >
                  <span className="textsLandingsmallNoFontSize">
                    {i18next.t("WORKSHOP 5:")}
                  </span>
                  <br />
                  <span className="textsLandingsmallNoFontSize">
                    {i18next.t("Topic")}
                  </span>
                  <br />
                  <span className="textsLandingsmallNoFontSize">
                    {" "}
                    {i18next.t("When")}
                  </span>{" "}
                  {i18next.t("2024 / Apr 05 / Fr 4 PM - 6 PM")}
                  <br />
                  <span className="textsLandingsmallNoFontSize">
                    {" "}
                    {i18next.t("Where")}
                  </span>
                </Typography>
                <Typography
                  variant="body1"
                  paragraph
                  className="textsLandingPage"
                >
                  <span className="textsLandingsmallNoFontSize">
                    {i18next.t("WORKSHOP 6:")}
                  </span>
                  <br />
                  <span className="textsLandingsmallNoFontSize">
                    {i18next.t("Topic")}
                  </span>
                  <br />
                  <span className="textsLandingsmallNoFontSize">
                    {" "}
                    {i18next.t("When")}
                  </span>{" "}
                  {i18next.t("2024 / May 03 / Fr 4 PM - 6 PM")}
                  <br />
                  <span className="textsLandingsmallNoFontSize">
                    {" "}
                    {i18next.t("Where")}
                  </span>
                </Typography>
                <Typography
                  variant="body1"
                  paragraph
                  className="textsLandingPage"
                >
                  <span className="textsLandingsmallNoFontSize">
                    {i18next.t("WORKSHOP 7:")}
                  </span>
                  <br />
                  <span className="textsLandingsmallNoFontSize">
                    {i18next.t("Topic")}
                  </span>
                  <br />
                  <span className="textsLandingsmallNoFontSize">
                    {" "}
                    {i18next.t("When")}
                  </span>{" "}
                  {i18next.t("2024 / June 07 / Fr 4 PM - 6 PM")}
                  <br />
                  <span className="textsLandingsmallNoFontSize">
                    {" "}
                    {i18next.t("Where")}
                  </span>
                </Typography>
                <Typography
                  variant="body1"
                  paragraph
                  className="textsLandingPage"
                >
                  <span className="textsLandingsmallNoFontSize">
                    {i18next.t("WORKSHOP 8:")}
                  </span>
                  <br />
                  <span className="textsLandingsmallNoFontSize">
                    {i18next.t("Topic")}
                  </span>
                  <br />
                  <span className="textsLandingsmallNoFontSize">
                    {" "}
                    {i18next.t("When")}
                  </span>
                  <br />
                  <span className="textsLandingsmallNoFontSize">
                    {" "}
                    {i18next.t("Where")}
                  </span>
                </Typography>
                <Typography
                  variant="body1"
                  paragraph
                  className="textsLandingPage"
                >
                  <span className="textsLandingsmallNoFontSize">
                    {i18next.t("WORKSHOP 9:")}
                  </span>
                  <br />
                  <span className="textsLandingsmallNoFontSize">
                    {i18next.t("Topic")}
                  </span>
                  <br />
                  <span className="textsLandingsmallNoFontSize">
                    {" "}
                    {i18next.t("When")}
                  </span>
                  <br />
                  <span className="textsLandingsmallNoFontSize">
                    {" "}
                    {i18next.t("Where")}
                  </span>
                </Typography>
                <Typography
                  variant="body1"
                  paragraph
                  className="textsLandingPage"
                >
                  <span className="textsLandingsmallNoFontSize">
                    {i18next.t("WORKSHOP 10:")}
                  </span>
                  <br />
                  <span className="textsLandingsmallNoFontSize">
                    {i18next.t("Topic")}
                  </span>
                  <br />
                  <span className="textsLandingsmallNoFontSize">
                    {" "}
                    {i18next.t("When")}
                  </span>
                  <br />
                  <span className="textsLandingsmallNoFontSize">
                    {" "}
                    {i18next.t("Where")}
                  </span>
                </Typography>
                <Typography
                  variant="body1"
                  paragraph
                  className="textsLandingPage"
                >
                  <span className="textsLandingsmallNoFontSize">
                    {i18next.t("WORKSHOP 11:")}
                  </span>
                  <br />
                  <span className="textsLandingsmallNoFontSize">
                    {i18next.t("Topic")}
                  </span>
                  <br />
                  <span className="textsLandingsmallNoFontSize">
                    {" "}
                    {i18next.t("When")}
                  </span>
                  <br />
                  <span className="textsLandingsmallNoFontSize">
                    {" "}
                    {i18next.t("Where")}
                  </span>
                </Typography>
                <Typography
                  variant="body1"
                  paragraph
                  className="textsLandingPage"
                >
                  <span className="textsLandingsmallNoFontSize">
                    {i18next.t("WORKSHOP 12:")}
                  </span>
                  <br />
                  <span className="textsLandingsmallNoFontSize">
                    {i18next.t("Topic")}
                  </span>
                  <br />
                  <span className="textsLandingsmallNoFontSize">
                    {" "}
                    {i18next.t("When")}
                  </span>
                  <br />
                  <span className="textsLandingsmallNoFontSize">
                    {" "}
                    {i18next.t("Where")}
                  </span>
                </Typography>
              </Box>
            </Box>
          </Box>
        </ThemeProvider>
      </div>
      <Box sx={{ marginTop: "4rem" }}>
        <FooterLanding />
      </Box>
    </div>
  );
};

export default InHouseWorkshop;
