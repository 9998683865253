import { Box, Button, Typography } from "@mui/material";
import "../../pages/LandingPagesGlobal.scss";

const HeadlineVvidgetSmall = ({
  headline,
  subtitle,
  smallText,
  buttonText,
  onClickFunction,
}: any) => {
  return (
    <>
      <Box>
        <Box className="headlineVvidgetMainBoxSmall">
          <Box sx={{ maxWidth: "1005px", width: "100%" }}>
            <Typography
              variant="h4"
              sx={{ marginBottom: "1rem", maxWidth: "652px" }}
              className="blueHeadlineMiniLandingPages"
            >
              {headline}
            </Typography>

            <Typography
              variant="body1"
              paragraph
              sx={{ maxWidth: "652px", marginBottom: "0px !important" }}
              className="textsLandingPage"
            >
              {subtitle}
            </Typography>
          </Box>
          <Box className="smallTextAndBtnBox">
            <Box>
              <Typography className="smallText">{smallText}</Typography>
            </Box>
            <Box>
              <Button className="smallTextBtn" onClick={onClickFunction}>{buttonText}</Button>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default HeadlineVvidgetSmall;
