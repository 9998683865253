import { Box, Modal, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import "./CameraAccessModal.scss";
import PriorityYellowIcon from "src/pages/Authentication/Registration/components/PriorityIcon";
import i18next from "i18next";

interface Props {
  open: boolean;
  handleClose: () => void;
}

let cameraAccessInstructions: string[];
const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

if (isMobile) {
  cameraAccessInstructions = [
    i18next.t(
      "Please ensure you have granted camera access to your browser in your device settings."
    ),
    i18next.t("If you have already granted access, please refresh the page."),
  ];
} else if (navigator.userAgent.indexOf("Chrome") !== -1) {
  cameraAccessInstructions = [
    i18next.t("At the top right, click More. Settings."),
    i18next.t("Click Privacy and security."),
    i18next.t("Click Site settings."),
    i18next.t("Click Camera."),
    i18next.t("Find and click https://vicpayments.com/ in the list."),
    i18next.t("On camera permission select 'allow' to access your camera."),
    i18next.t("Refresh the page for the changes to take effect."),
  ];
} else if (navigator.userAgent.indexOf("Firefox") !== -1) {
  cameraAccessInstructions = [
    i18next.t(
      "Look for a camera icon in the address bar. It may have a red cross if access is denied."
    ),
    i18next.t("Click on the camera icon."),
    i18next.t("Select 'Allow' from the dropdown menu."),
    i18next.t("Refresh the page for the changes to take effect."),
  ];
} else if (navigator.userAgent.indexOf("Safari") !== -1) {
  cameraAccessInstructions = [
    i18next.t("Go to Safari > Preferences from the menu bar."),
    i18next.t("Click on the 'Websites' tab."),
    i18next.t("Select 'Camera' from the left sidebar."),
    i18next.t(
      "Find https://vicpayments.com/ in the list and set it to 'Allow' or 'Ask' from the dropdown menu."
    ),
    i18next.t(
      "Close the Preferences window and refresh the page for the changes to take effect."
    ),
  ];
} else if (navigator.userAgent.indexOf("Edge") !== -1) {
  cameraAccessInstructions = [
    i18next.t(
      "Look for a camera icon in the address bar. It may be crossed out if access is denied."
    ),
    i18next.t("Click on the camera icon."),
    i18next.t(
      "Select 'Always allow https://vicpayments.com/ to access your camera' from the dropdown menu."
    ),
    i18next.t("Refresh the page for the changes to take effect."),
  ];
} else {
  cameraAccessInstructions = [
    i18next.t(
      "To use this feature, please grant camera access in your browser settings."
    ),
  ];
}

const CameraAccessModal: React.FC<Props> = ({ open, handleClose }) => {
  return (
    <Modal open={open} onClose={handleClose}>
      <Box className="cameraAccessModalMain">
        <CloseIcon
          onClick={handleClose}
          className="closeAcsessCameraModalIcon"
        />
        <Box className="priorityAndCamerRequiredText">
          <PriorityYellowIcon />
          <Typography className="registration1375RemFontSize">
            {i18next.t("Camera Access Required")}
          </Typography>
        </Box>
        <Box className="componentLiInstructions">
          {cameraAccessInstructions.map((instruction, index) => (
            <Typography component="li" key={index} className="pageTextFontSize">
              {instruction}
            </Typography>
          ))}
        </Box>
      </Box>
    </Modal>
  );
};

export default CameraAccessModal;
