import React, { useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import { Box, IconButton, TextField, Typography } from "@mui/material";
import LoadingIndicator from "../LoadingIndicator";
import "./ChatDialog.scss";
import UploadFileOrImage from "../UploadFileOrImage/UploadFileOrImage";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import PDFModal from "../PdfModal/PdfModal";
import PictureAsPdfOutlinedIcon from "@mui/icons-material/PictureAsPdfOutlined";

interface Props {
  open: boolean;
  handleClose: () => void;
  handleConfirm: (e: any, message: any) => void;
  content: {
    title: string;
    body: string;
    cancelText: string;
    confirmText: string;
  };
  className?: string;
  loading?: boolean;
  showFileUpload?: boolean;
  fileUpload?: File[];
  onFileSelect?: (files: FileList | null) => void;
}

const ChatDialog = ({
  open,
  handleClose,
  handleConfirm,
  content,
  className,
  loading = false,
  showFileUpload = false,
  fileUpload = [],
  onFileSelect = () => {},
}: Props) => {
  const [comment, setComment] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [selectedPdf, setSelectedPdf] = useState("");

  const handleOpenModal = (pdfUrl: any) => {
    setSelectedPdf(pdfUrl);
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleCommentChange = (event: any) => {
    setComment(event.target.value);
  };
  const handleRemoveFile = () => {
    onFileSelect(null);
  };

  const isButtonDisabled = () => {
    const isCommentEmpty = !comment || !/\S/.test(comment);
    const isFileUploaded = fileUpload.length > 0;

    return isCommentEmpty && !isFileUploaded;
  };

  return (
    <Dialog
      className={className}
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      {loading ? (
        <Box className="dialogLoaderBox">
          <LoadingIndicator />
        </Box>
      ) : (
        <>
          <DialogTitle id="alert-dialog-title">
            <Typography
              className="FontSize1275"
              sx={{
                fontFamily: "Helvetica, sans-serif",
                fontWeight: "600",
                color: "#454745",
                marginBottom: "1rem",
              }}
            >
              {content.title}
            </Typography>
            <div className="horizontal-line-list mb-8"></div>
          </DialogTitle>
          <DialogContent sx={{ padding: "17px 24px" }}>
            <div id="alert-dialog-description">
              <TextField
                value={comment === "null" ? "" : comment}
                onChange={handleCommentChange}
                aria-label="maximum height"
                placeholder="Write your message..."
                InputProps={{
                  rows: 4,
                  multiline: true,
                  inputComponent: "textarea",
                  style: {
                    fontFamily: "Helvetica, sans-serif",
                    width: "100%",
                    paddingTop: "1rem",
                    paddingLeft: "1rem",
                  },
                  sx: {
                    fontSize: "16px",
                    "&:-internal-autofill-selected": {
                      backgroundColor: "transparent !important",
                    },
                    "@media (max-width:600px)": {
                      fontSize: "14px !important",
                    },
                  },
                }}
                style={{
                  fontFamily: "Helvetica, sans-serif",
                  width: "100%",
                }}
                variant="filled"
              ></TextField>
            </div>
            {showFileUpload && (
              <DialogContentText
                sx={{
                  display: "flex",
                  alignItems: "center",
                  marginTop: "10px",
                }}
              >
                {fileUpload.length > 0 && (
                  <>
                    {fileUpload[0].type.startsWith("image") ? (
                      <img
                        src={URL.createObjectURL(fileUpload[0])}
                        alt={fileUpload[0].name}
                        style={{
                          maxHeight: "60px",
                          maxWidth: "60px",
                          objectFit: "cover",
                          borderRadius: "4px",
                          marginRight: "5px",
                        }}
                      />
                    ) : (
                      <>
                        {fileUpload[0].type === "application/pdf" && (
                          <IconButton
                            onClick={() =>
                              handleOpenModal(
                                URL.createObjectURL(fileUpload[0])
                              )
                            }
                          >
                            <PictureAsPdfOutlinedIcon className="muiIcons" />
                          </IconButton>
                        )}
                      </>
                    )}
                    {fileUpload[0].type === "application/pdf" && (
                      <PDFModal
                        open={openModal}
                        handleClose={handleCloseModal}
                        fileUrl={selectedPdf}
                      />
                    )}
                    <Typography
                      variant="body2"
                      sx={{
                        marginRight: "8px",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                      }}
                    >
                      {fileUpload[0].name}
                    </Typography>
                    <IconButton
                      color="error"
                      onClick={handleRemoveFile}
                      size="small"
                    >
                      <DeleteOutlineIcon className="muiIcons" />
                    </IconButton>
                  </>
                )}
              </DialogContentText>
            )}
          </DialogContent>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              padding: "0 24px",
            }}
          >
            <DialogActions
              sx={{
                flexDirection: "column",
                marginBottom: "1rem",
                width: showFileUpload ? "87%" : "100%",
              }}
            >
              <Button
                type="submit"
                fullWidth
                variant="contained"
                onClick={(e) => {
                  handleConfirm(e, comment.trim());
                  setComment("");
                }}
                disabled={isButtonDisabled()}
                className="button-confirm-modal btnsLoginHeight oneRemFontSize"
                sx={{
                  borderRadius: "20px",
                  boxShadow: "none",
                  fontWeight: 600,
                  textTransform: "none",
                  width: "95%",
                }}
              >
                {content.confirmText}
              </Button>
            </DialogActions>
            {showFileUpload && (
              <DialogActions
                sx={{
                  flexDirection: "column",
                  marginBottom: "1rem",
                }}
              >
                <Box>
                  <UploadFileOrImage onFileSelect={onFileSelect} />
                </Box>
              </DialogActions>
            )}
          </Box>
        </>
      )}
    </Dialog>
  );
};

export default ChatDialog;
