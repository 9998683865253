import React, { useEffect, useState } from "react";
import "./NotificationDrawerContent.scss";
import { Typography, Button, Divider } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import NotificationsList from "../Notifications/NotificationsList";
import api from "src/store/interceptors/api";
import { useNavigate } from "react-router";
import i18next from "i18next";

type Props = {
  toggleDrawer: any;
  user: any;
  updateNotifications: () => void;
};

function NotificationDrawerContent({
  toggleDrawer,
  updateNotifications,
  user,
}: Props) {
  const navigate = useNavigate();
  const [notificationsList, setNotificationsList] = useState([]);

  const handleSetNotificationsList = (list: any) => {
    const sort: any = [...list];
    sort.sort((a: any, b: any) => a.is_read - b.is_read);
    setNotificationsList(sort);
  };
  const handleUpdateNotification = async (id?: number) => {
    if (id) {
      const filter: any = [...notificationsList];
      const foundItem: any =
        filter && filter.length > 0
          ? filter.findIndex((item: any) => item.id === id)
          : -1;
      if (foundItem > -1) {
        filter[foundItem].is_read = true;
        setNotificationsList(notificationsList);
      }
    }
    updateNotifications();
  };
  const handleGetNotifications = async () => {
    try {
      const res: any = await api.get("/notifications?per_page=10");
      if (res && res.data) {
        const { data, meta } = res;
        if (data) {
          setNotificationsList(data.data);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };
  const navigateToNotifications = () => {
    navigate("/notifications");
  };

  useEffect(() => {
    handleGetNotifications();
  }, []);

  return (
    <div className="drawer-container" role="presentation">
      <div className="drawer-content">
        <div className="header-not">
          <Typography
            className="d-flex jc-start FontSize12"
            component="h1"
            variant="h4"
            sx={{
              fontFamily: "Helvetica, sans-serif",
              fontWeight: "600",
              textAlign: "start",
            }}
          >
            {i18next.t("Inbox")}
          </Typography>
          <CloseIcon
            className="searchIconTransactionFilters cp"
            onClick={toggleDrawer(false)}
          />
        </div>
        <div className="horizontal-line-list mb-8 mt-12"></div>

        {/*}
        <div className="img-box-not">
          <img
            src={imageUrl}
            alt="r"
            height="172"
            width="160"
            className="img-box-mark"
          />
          <div className="text-overlay">Spend abroad with <br/>  a card up <br/> to 8x cheaper.</div>
        </div>
        */}

        <div className="notifications-cotainer">
          <NotificationsList
            user={user}
            updateNotifications={handleUpdateNotification}
            data={notificationsList}
          />
        </div>
        {notificationsList && notificationsList.length > 0 ? (
          <div className="footer-not">
            <Button
              className="viewALlNotificationBtn registration0875RemFontSize"
              onClick={navigateToNotifications}
            >
              {i18next.t("View All")}
            </Button>
          </div>
        ) : null}
      </div>
    </div>
  );
}

export default NotificationDrawerContent;
