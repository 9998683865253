import { Typography, IconButton, Tooltip, Box } from "@mui/material";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import useClipboard from "react-use-clipboard";
import i18next from "i18next";
import { useState } from "react";

const CopyableTypography = ({ value }: any) => {
  const [isCopied, setCopied] = useClipboard(value);
  const [showTooltip, setShowTooltip] = useState<boolean>(false);

  const handleCopy = () => {
    setCopied();
    setShowTooltip(true);
    setTimeout(() => setShowTooltip(false), 2000);
  };

  return (
    <Box className="copyableTypographyBox">
      <Typography className="FontSize1075 copyableTypographyValue">
        {value}
        <span className="copyableTypographyValueUnderline" />
      </Typography>
      <Tooltip
        title={showTooltip ? i18next.t("COPIED!") : ""}
        open={showTooltip}
        arrow
        placement="top"
        classes={{
          tooltip: "customTooltipAccDetails",
          arrow: "customTooltipArrowACCDetails",
        }}
        PopperProps={{
          sx: {
            zIndex: 1,
          },
        }}
      >
        <span>
          <IconButton
            onClick={handleCopy}
            className="copyableTypographyIconBtn"
          >
            <ContentCopyIcon className="copyableTypographyIcon" />
          </IconButton>
        </span>
      </Tooltip>
    </Box>
  );
};

export default CopyableTypography;
