import React, { useRef } from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Box, Typography } from "@mui/material";
import HeaderLanding from "../components/HeaderLanding/HeaderLanding";
import { useAppSelector } from "src/store";
import FooterLanding from "../components/FooterLanding/FooterLanding";
import ContactUsForm from "../components/LandingSections/ContactUs";
import TitleBackBox from "../components/TitleBackBox/TitleBackBox";
import HeadlineVvidgetSmall from "../components/HeadlineVvidget/HeadlineVvidgetSmall";
import i18next from "i18next";
import LogoTextVvidget from "../components/LogoTextVvidget/LogoTextVvidget";

const defaultTheme = createTheme();

const ContactUs: React.FC = () => {
  const { user } = useAppSelector((state) => state.auth);

  const containerRef = useRef<HTMLDivElement>(null);

  const scrollToContainer = () => {
    containerRef.current?.scrollIntoView({ behavior: "smooth" });
  };
  const sendEmail = (email: any) => {
    const mailtoUrl = `mailto:${email}`;

    window.location.href = mailtoUrl;
  };

  return (
    <div>
      <HeaderLanding user={user} />
      <div className="margintTopPagesMainCont">
        <ThemeProvider theme={defaultTheme}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Box className="titleHeadlineSmallBG">
              <Box className="landingPagesMainBox">
                <Box>
                  <TitleBackBox title={i18next.t("Contact us")} />
                </Box>
                <Box>
                  <HeadlineVvidgetSmall
                    subtitle={i18next.t(
                      "Embrace innovation and security with VicPay Experience a new era of money transfer that offers unparalleled efficiency and safety. Don't miss out on the opportunity to be part of a visionary financial network."
                    )}
                    smallText={i18next.t("In case of any questions")}
                    buttonText={i18next.t("Contact Us")}
                    onClickFunction={scrollToContainer}
                  />
                </Box>
              </Box>
            </Box>
            <Box className="landingPagesMainBox">
              <Typography
                variant="h3"
                sx={{ marginBottom: "1rem" }}
                className="headlineMiniLandingPages"
              >
                {i18next.t("Here are our full contact details at a glance")}
              </Typography>
              <Typography
                variant="body1"
                paragraph
                className="textsLandingPage"
                style={{ marginTop: "2rem" }}
              >
                <span className="textsLandingsmallNoFontSize">
                  Victorum Capital Services Limited
                  <br />
                  6 Tokavaig, Alavik Lodge
                  <br />
                  IV44 8QL, Teangue
                  <br />
                  Great Britain
                </span>
              </Typography>
              <Typography
                variant="body1"
                paragraph
                className="textsLandingPage"
              >
                <span className="textsLandingsmallNoFontSize">
                  {" "}
                  {i18next.t("Phone")}:
                </span>{" "}
                +1 604-260-0738
              </Typography>
              <Typography
                variant="body1"
                paragraph
                className="textsLandingPage"
              >
                <span className="textsLandingsmallNoFontSize">
                  {i18next.t("Compliance")}:
                </span>{" "}
                <a
                  href="mailto:compliance@vicpayments.com"
                  className="linksHover"
                  style={{ marginLeft: "5px" }}
                >
                  compliance@vicpayments.com
                </a>
              </Typography>
              <Typography
                variant="body1"
                paragraph
                className="textsLandingPage"
              >
                <span className="textsLandingsmallNoFontSize">
                  {i18next.t("Media & Press")}:
                </span>{" "}
                <a
                  href="mailto:
                  press@vicpayments.com"
                  className="linksHover"
                  style={{ marginLeft: "5px" }}
                >
                  press@vicpayments.com
                </a>
              </Typography>
              <Typography
                variant="body1"
                paragraph
                className="textsLandingPage"
              >
                <span className="textsLandingsmallNoFontSize">
                  {i18next.t("Administration")}:
                </span>{" "}
                <a
                  href="mailto:
                  administration@vicpayments.com"
                  className="linksHover"
                  style={{ marginLeft: "5px" }}
                >
                  administration@vicpayments.com
                </a>
              </Typography>
              <Typography
                variant="body1"
                paragraph
                className="textsLandingPage"
              >
                <span className="textsLandingsmallNoFontSize">
                  {i18next.t("Registered in Scotland, United Kingdom")}
                </span>
              </Typography>
              <Typography
                variant="body1"
                paragraph
                className="textsLandingPage"
              >
                <span className="textsLandingsmallNoFontSize">
                  {i18next.t("Company number")}
                </span>{" "}
                SC745907
              </Typography>
              <Typography
                variant="body1"
                paragraph
                className="textsLandingPage"
              >
                <span className="textsLandingsmallNoFontSize">
                  {" "}
                  {i18next.t("CEO")}{" "}
                </span>
                Osbert Döhl
              </Typography>
              <Box>
                <LogoTextVvidget
                  text={
                    <>
                      {i18next.t(
                        "You want to speak to one of our transfer and currency experts? Would you like to handle transactions through the phone or do you need more technology insights before deciding to open an account? We've got you covered"
                      )}{" "}
                      {i18next.t(
                        "Dial +1 604-260-0738 daily from 09 AM to 11 PM to speak with our account team or write us an email to"
                      )}{" "}
                      <span
                        onClick={() => sendEmail("info@vicpayments.com")}
                        style={{
                          textDecoration: "underline",
                          cursor: "pointer",
                        }}
                        className="linksHover"
                      >
                        <br />
                        info@vicpayments.com
                      </span>
                      .
                    </>
                  }
                />
              </Box>
              <Typography
                variant="h3"
                sx={{
                  marginBottom: "1rem",
                }}
                className="headlineMiniLandingPages"
              >
                {i18next.t("Effortless Reach-out Option")}
              </Typography>
              <Typography
                variant="body1"
                paragraph
                className="textsLandingPage"
              >
                {i18next.t(
                  "In case you want to send us a quick message, you can use our easy contact form below"
                )}
              </Typography>
              <Box ref={containerRef}>
                <ContactUsForm isContactUsPage={true} />
              </Box>
              <Typography
                variant="h3"
                sx={{ marginBottom: "1rem" }}
                className="headlineLandingPages"
              >
                {i18next.t("Would you like to meet us")}
              </Typography>
              <Box>
                <Typography
                  variant="h3"
                  sx={{
                    marginBottom: "1rem",
                  }}
                  className="headlineMiniLandingPages"
                >
                  {i18next.t("Open 7 Days a Week")}
                </Typography>
                <Typography
                  variant="body1"
                  paragraph
                  className="textsLandingPage"
                >
                  {i18next.t(
                    "We are open Mo - Mo from 09 AM to 11 PM - just drop in to meet the team"
                  )}
                </Typography>
              </Box>
              <Box>
                <Typography
                  variant="h3"
                  sx={{
                    marginBottom: "1rem",
                  }}
                  className="headlineMiniLandingPages"
                >
                  {i18next.t("Historic Highland Location")}
                </Typography>
                <Typography
                  variant="body1"
                  paragraph
                  className="textsLandingPage"
                >
                  {i18next.t(
                    "We are based on the Isle of Skye, in Knock Bay on the west side of the Sound of Sleat, just around the corner from Knock Castle"
                  )}
                </Typography>
              </Box>
            </Box>
          </Box>
        </ThemeProvider>
      </div>
      <Box sx={{ marginTop: "4rem" }}>
        <FooterLanding />
      </Box>
    </div>
  );
};

export default ContactUs;
