import { Avatar, Box, Typography } from "@mui/material";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { useNavigate } from "react-router-dom";
import GppBadIcon from "@mui/icons-material/GppBad";
import VerifiedIcon from "@mui/icons-material/Verified";

function UserCardItem({ data, index, onClick, isSelected, type }: any) {
  const navigate = useNavigate();
  const handleOnClick = () => {
    if (type === "accountTransfer") {
      onClick();
    } else {
      navigate(`/admin/users/${data.id}/account-information`);
    }
  };

  const itemStyle = isSelected ? { backgroundColor: "#BDC3C7" } : {};

  const userName =
    data && data.company_name && data.account_type === "business"
      ? data.company_name
      : data.first_name && data.last_name
      ? data.first_name + " " + data.last_name
      : "";
  const email = data && data.email ? data.email : " - ";
  const role = data && data.role ? data.role : "User";
  return (
    <Box
      key={"recipient" + index}
      className="allRecipientSingle-acc"
      onClick={handleOnClick}
      style={itemStyle}
    >
      <Box className="allRecipientInfo">
        <Box className="avatarAndNameEndingIn">
          <Avatar
            style={{
              width: 48,
              height: 48,
              backgroundColor: "#E5E8E2",
            }}
          >
            {userName && (
              <>
                <span className="spanAvatar">
                  {userName
                    .split(" ")
                    .map((namePart: any, index: number) =>
                      index < 2 ? namePart.charAt(0).toUpperCase() : ""
                    )
                    .join("")}
                </span>
              </>
            )}
          </Avatar>
          <div className="d-flex ai-center">
            <Box sx={{ paddingRight: 2 }}>
              {data && data.verification_approved ? (
                <VerifiedIcon style={{ color: "#00c943" }} />
              ) : (
                <GppBadIcon style={{ color: "#c90014" }} />
              )}
            </Box>
            <Box className="nameAndEndingIn mr-1rem">
              <Typography className="allRecipientName">{userName}</Typography>
              <Typography className="allRecipientEndingIn">
                 {type !== "accountTransfer" ?? "(" + role + ")"}
              </Typography>
            </Box>
          </div>
        </Box>
        <Box>
          <NavigateNextIcon className="arrayRightIcon" />
        </Box>
      </Box>
    </Box>
  );
}

export default UserCardItem;
