import {
  useWeb3React,
  Web3ReactHooks,
  Web3ReactProvider,
} from "@web3-react/core";
import type { WalletConnect as WalletConnectV2 } from "@web3-react/walletconnect-v2";
import {
  hooks as walletConnectV2Hooks,
  walletConnectV2,
} from "src/services/Connectors/walletConnectV2";
//import { getName } from "src/services/utils";
const connectors: [WalletConnectV2, Web3ReactHooks][] = [
  //[metaMask, metaMaskHooks],
  //[walletConnect, walletConnectHooks],
  [walletConnectV2, walletConnectV2Hooks],
  //[coinbaseWallet, coinbaseWalletHooks],
  //  [network, networkHooks],
];
/*
function DisconnectCustom() {
  const { connector } = useWeb3React();

  const { deactivate } = connector;
  if (deactivate && connector) {
    //deactivate();
    console.log("deactivate");
    connector.provider = undefined;
    void connector.resetState();
    void connector.deactivate;
  }
}
*/
function Child() {
  const { connector } = useWeb3React();
  /*
  const { deactivate } = connector;
  if(deactivate && connector){
      //deactivate();
      
     // console.log('deactivate');
      //connector.resetState();
     //connector.provider = undefined;
     /// void connector.resetState();
      void connector.deactivate;

    }
    */
  //console.log(`Priority Connector is: ${getName(connector)}`);
  return null;
}

export default function Provider() {
  return (
    <Web3ReactProvider connectors={connectors}>
      <Child />
    </Web3ReactProvider>
  );
}
