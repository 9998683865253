import { useState } from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Button from "@mui/material/Button";
import "./LoginPINModal.scss";
import KeyIcon from "@mui/icons-material/Key";
import { toast } from "react-toastify";
import i18next from "src/i18n";
import BackspaceOutlinedIcon from "@mui/icons-material/BackspaceOutlined";

const CreatePINModal = ({
  onClose,
  showModal,
}: {
  onClose: (pin: string) => void;
  showModal: boolean;
}) => {
  const [attempts, setAttempts] = useState<any>(1);
  const [pin, setPin] = useState<string>("");
  const [confirmPin, setConfirmPin] = useState<string>("");
  const [disableButtons, setDisableButtons] = useState<boolean>(false);

  const handlePinButtonClick = (index: number) => {
    const newPin = pin + index.toString();
    setPin(newPin);

    if (newPin.length === 6) {
      setDisableButtons(true);
      handleCreatePin(newPin);
    }
  };

  const handleBackspaceClick = () => {
    const newPin = pin.slice(0, -1);
    setPin(newPin);
  };

  const handleCreatePin = async (pin: string) => {
    try {
      if (attempts === 1) {
        setConfirmPin(pin);
        setAttempts(2);
        setPin("");
        setDisableButtons(false);
      } else {
        if (confirmPin === pin) {
          onClose(pin);
          setPin("");
          setDisableButtons(false);
          setAttempts(1);
        } else {
          toast.error(i18next.t("PIN not matching!"));
          setPin("");
          setAttempts(1);
          setDisableButtons(false);
        }
      }
    } catch (error) {
      setPin("");
      setDisableButtons(false);
    }
  };

  return (
    <Modal
      open={showModal}
      aria-labelledby="password-modal-title"
      aria-describedby="password-modal-description"
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: {
            xs: "90%",
            sm: 400,
          },
          bgcolor: "#f2f2f2",
          boxShadow: 24,
          borderRadius: 5,
          p: 4,
          display: "flex",
          flexDirection: "column",
          gap: 2,
        }}
      >
        <KeyIcon sx={{ fontSize: 70, color: "#2a5182", margin: "auto" }} />
        <h2
          className="password-modal-title FontSize12"
          style={{ textAlign: "center" }}
        >
          {attempts === 1
            ? i18next.t("Please create your PIN code")
            : i18next.t("Please confirm your PIN code")}
        </h2>

        <Box>
          <Box className="pinDots">
            {Array.from({ length: 6 }, (_, index) => (
              <Box
                key={index}
                className={`pinDot ${index < pin.length ? "filled" : ""}`}
              />
            ))}
          </Box>
          <Box className="pinBtnsBox">
            {[1, 2, 3, 4, 5, 6, 7, 8, 9, 0].map((number) => (
              <Box
                key={number}
                className={`pinBtnBox ${number === 0 ? "zero" : ""}`}
              >
                <Button
                  variant="outlined"
                  className="pinButton"
                  onClick={() => handlePinButtonClick(number)}
                  disabled={disableButtons}
                >
                  {number}
                </Button>
              </Box>
            ))}
            <Box className="pinBtnBox backspace">
              <Button
                variant="outlined"
                className="backspaceButton"
                onClick={handleBackspaceClick}
                disabled={disableButtons}
              >
                <BackspaceOutlinedIcon className="backspaceModalIcon" />
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};

export default CreatePINModal;
