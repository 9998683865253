import { Box, Checkbox, FormControlLabel, Typography } from "@mui/material";
import i18next from "i18next";
import React, { useState } from "react";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import { updateUserProfile } from "src/store/profile/profile_slice";

import { useAppSelector, useAppDispatch } from "src/store";
const BankDetailsStep = ({ termsProvider }: any) => {
  const dispatch = useAppDispatch();
  const { user } = useAppSelector((state) => state.auth);

  const [termsApproval, setTermsApproval] = useState<boolean>(termsProvider);

  const handleTermsAccepted = (termsChecked: any) => {
    if (termsChecked) {
      if (user && user.id) {
        let valuesForBe: any = {};
        valuesForBe.verification_provider_terms = 1;
        valuesForBe.id = user.id ?? null;
        dispatch(updateUserProfile(valuesForBe));
        setTermsApproval(true);
      }
    }
  };
  return (
    <div className="content-container-profile main-color animated-text moveInRight">
      <Typography
        className="pageTitleFontSize"
        component="h1"
        variant="h5"
        sx={{
          fontFamily: "Helvetica, sans-serif",
          fontWeight: "600",
          color: "#0e0f0c",
          maxWidth: "500px",
          whiteSpace: "nowrap",
          textOverflow: "ellipsis",
          overflow: "hidden",
        }}
      >
        {i18next.t("Bank details")}
      </Typography>

      <Typography sx={{ mt: "20px", mb: "50px" }}>
        {i18next.t(
          "Thank you for passing Video identification verification. You will get notification once your account and account details are ready."
        )}
        <br />
        <br />
        {i18next.t(
          "Account will be provided by our provider 3S Money."
        )}
        <br />
      </Typography>
      <FormControlLabel
          control={
            <Checkbox onChange={handleTermsAccepted} checked={termsApproval} />
          }
          label="Authorise the VicPay platform and team to view and manage your bank account provided by 3S"
        />
        <br />
        <br />
      <Box className="clock-section">
        <AccessTimeIcon />
        <Typography>{i18next.t("Account in creation")}</Typography>
      </Box>
    </div>
  );
};

export default BankDetailsStep;
