import { Box, Step, StepLabel, Stepper } from "@mui/material";
import i18next from "i18next";
import React from "react";
import { useNavigate } from "react-router-dom";
import imageUrl from "src/assets/images/logoPay.png";

interface Props {
  activeStep: number;
}

function BankingNav({ activeStep }: Props) {
  const navigate = useNavigate();

  function redirectToHome() {
    navigate("/home");
  }

  const getSteps = () => {
    return [
      i18next.t("Account"),
      i18next.t("Documents"),
      i18next.t("Review"),
      i18next.t("Video identification"),
      i18next.t("Bank details"),
    ];
  };

  return (
    <div className="nav-container mb-big enterpaymentMainHeaderBox">
      <div className="logo-box" onClick={redirectToHome}>
        <img src={imageUrl} alt="r" className="logoEnterpaymentHeader" />
      </div>
      <Box sx={{ width: "40rem" }}>
        <Stepper activeStep={activeStep} alternativeLabel>
          {getSteps().map((label) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
      </Box>
    </div>
  );
}

export default BankingNav;
