import Typography from "@mui/material/Typography";
import { useLocation } from "react-router-dom";

const CustomTabs = ({ text, url, onClick }: any) => {
  const location = useLocation();

  return (
    <Typography
      className="your-details-text d-flex jc-start oneRemFontSize"
      component="h1"
      variant="h5"
      sx={{
        fontFamily: "Helvetica, sans-serif",
        textAlign: "center",
        fontWeight: "600",
        marginRight: "2rem",
        color: location.pathname === url ? "#2A5182" : "inherit",
        backgroundColor: location.pathname === url ? "#16330014" : "none",
        cursor: "pointer",
        "&:hover": {
          backgroundColor: "rgb(244, 246, 248)",
        },
        padding: "0.6rem",
        borderRadius: "16px",
      }}
      onClick={() => onClick(text)}
    >
      {text}
    </Typography>
  );
};

export default CustomTabs;
