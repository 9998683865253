import { Box, InputAdornment, TextField, Typography } from "@mui/material";
import "./News.scss";
import SearchIcon from "@mui/icons-material/Search";
import { useState } from "react";
import NewsBox from "./NewsBox";
import NewsDefault from "../../../../assets/images/logoPay.png";
import NewsJson from "./News.json";
import i18next from "i18next";

interface Article {
  id: number;
  image?: string;
  date: string;
  category: string;
  title: string;
  subtitle?: string;
  text?: string;
}

const News = () => {
  const [tab, setTab] = useState("all");
  const [searchNews, setSearchNews] = useState("");

  const [news] = useState<Article[]>(NewsJson.news);

  const handleNewsClick = (id: number) => {
    window.location.href = `/news/${id}`;
  };

  let filteredNews = news;

  if (tab !== "all") {
    filteredNews = filteredNews.filter((article) => article.category === tab);
  }

  if (searchNews.length >= 3) {
    filteredNews = filteredNews.filter((article) =>
      article.title.toLowerCase().includes(searchNews.toLowerCase())
    );
  }

  return (
    <Box>
      <Box>
        <Box className="latesNewsAndSearch">
          <Box>
            <Typography variant="h3" className="headlineMiniLandingPages">
              {i18next.t("Latest news")}
            </Typography>
          </Box>
          <Box className="newsSearchtextFieldBox">
            <TextField
              fullWidth
              placeholder={i18next.t("Search news")}
              sx={{
                background: "#fff",
                height: "48px",
                "@media (max-width:768px)": {
                  height: "40px",
                },
                "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                  {
                    borderColor: "#b2c9e6",
                  },
                "& .css-md26zr-MuiInputBase-root-MuiOutlinedInput-root": {
                  height: "48px",
                },
                "& .css-8j6b76-MuiInputBase-root-MuiOutlinedInput-root": {
                  height: "48px",
                  borderRadius: "32px",
                  "@media (max-width:768px)": {
                    height: "40px",
                  },
                },
                "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                  {
                    borderColor: "#b2c9e6",
                  },
                "& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline":
                  {
                    borderColor: "#ff3366",
                  },
                "& .MuiOutlinedInput-input": {
                  color: "#2a5182",
                  "@media (max-width:600px)": {
                    fontSize: "14px",
                  },
                },
                "& .Mui-focused .MuiOutlinedInput-input::placeholder": {
                  color: "#3e78c1",
                },
                "& .Mui-error .MuiOutlinedInput-input::placeholder": {
                  color: "#ff3366",
                },
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon sx={{ minWidth: "18px", minHeight: "18px" }} />
                  </InputAdornment>
                ),
              }}
              value={searchNews}
              onChange={(e) => setSearchNews(e.target.value)}
            />
          </Box>
        </Box>
        <Box className="newsTabsBox">
          <Box
            onClick={() => {
              setTab("all");
            }}
          >
            <Typography className={tab === "all" ? "newstabActive" : "newstab"}>
              {i18next.t("All")}
            </Typography>
          </Box>
          <Box
            onClick={() => {
              setTab("Newsroom");
            }}
          >
            <Typography
              className={tab === "Newsroom" ? "newstabActive" : "newstab"}
            >
              {i18next.t("Newsroom")}
            </Typography>
          </Box>
          <Box
            onClick={() => {
              setTab("House workshops");
            }}
          >
            <Typography
              className={
                tab === "House workshops" ? "newstabActive" : "newstab"
              }
            >
              {i18next.t("House workshops")}
            </Typography>
          </Box>
        </Box>
      </Box>
      <Box className="newsBoxGrid">
        {filteredNews.length > 0 ? (
          filteredNews.map((article) => (
            <NewsBox
              id={article.id}
              key={article.id}
              image={article.image ?? NewsDefault}
              date={article.date}
              category={article.category}
              title={article.title}
              subtitle={article.subtitle ?? ""}
              text={article.text ?? ""}
              onSelectCategory={(category) => setTab(category)}
              onNewsClick={handleNewsClick}
            />
          ))
        ) : (
          <Box sx={{ width: "100%" }}>
            <Typography variant="body1" paragraph className="textsLandingPage">
              {i18next.t("No articles found matching your search.")}
            </Typography>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default News;
