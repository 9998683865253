import { useEffect, useState } from "react";
import LoadingIndicator from "src/components/shared/LoadingIndicator";
import Timeline from "src/components/shared/Timeline/Timeline";
import api from "src/store/interceptors/api";
import transactionsService from "src/store/transactions/transactions_service";
import usersService from "src/store/users/users_services";

export default function ActionsTab() {
  const [verificationRequests, setVerificationRequests] = useState([]);
  const [balanceRequestData, setBalanceRequestData] = useState<any[]>([]);
  const [transactionsData, setTransactionsData] = useState<any[]>([]);
  const [accountCreatedData, setAccountCreatedData] = useState<any[]>([]);
  const [accountLimitData, setAccountLimitData] = useState<any[]>([]);
  const [verificationCodes, setVerificationCodes] = useState<any[]>([]);
  const [accountRequestsData, setAccountRequestsData] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  // actions start ------------------------------

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true); // Set loading to true before starting to fetch data
      try {
        const fetchPromises = [
          usersService.getUsers(
            `?page=1&per_page=10&filter[verification_requested]=1`
          ),
          api.get(`payment-requests?status=PUBLISHED&page=1&per_page=10`),
          transactionsService.getTransactions(
            `?status=transaction_payment_waiting&per_page=10`
          ),
          transactionsService.getAccountDataByStatus(
            `?status=created&per_page=20`
          ),
          transactionsService.getAccountDataByStatus(
            `?status=transaction_created&per_page=20`
          ),
          api.get(`admin/verification-codes-list?per_page=10`),
          transactionsService.getAccountRequestsData(
            `?status=transaction_created&per_page=20`
          ),
        ];

        const [
          verificationResponse,
          balanceResponse,
          transactionResponse,
          accountCreatedResponse,
          accountLimitResponse,
          verificationCodesResponse,
          accountRequestsResponse,
        ] = await Promise.all(fetchPromises);

        setVerificationRequests(
          verificationResponse.data.map((user: any) => ({
            status: "cancelled", // Use actual logic here
            mainInfo: `${user.first_name} ${user.last_name}`,
            timeText: new Date(user.created_at).toLocaleString("default", {
              year: "numeric",
              month: "long",
              day: "numeric",
              hour: "2-digit",
              minute: "2-digit",
            }),
            id: user.id,
          }))
        );
        // Verification requests end

        //  Payment requests start
        const formattedBalanceRequests = balanceResponse.data.data
          .filter((item: any) => item.status === "PUBLISHED")
          .map((item: any) => ({
            status: "cancelled", // Replace with actual status logic if necessary
            mainInfo: `${item.user.first_name} ${item.user.last_name}, amount: ${item.amount} ${item.currency.code}`,
            timeText: new Date(item.created_at).toLocaleString("default", {
              year: "numeric",
              month: "long",
              day: "numeric",
              hour: "2-digit",
              minute: "2-digit",
            }),
            id: item.id,
          }));
        setBalanceRequestData(formattedBalanceRequests);
        //  Payment requests end

        //  transactions start
        const formattedTransactions = transactionResponse.data.map(
          (transaction: any) => ({
            // ...formatting logic
            status: transaction.transaction_type.startsWith("balance_out")
              ? "cancelled"
              : "sent",
            mainInfo: `${transaction.user.first_name} ${transaction.user.last_name}, amount: ${transaction.amount_sent} ${transaction.currency.code}`,
            timeText: new Date(transaction.created_at).toLocaleString(
              "default",
              {}
            ),
            id: transaction.id,
            color: transaction.transaction_type.startsWith("balance_out")
              ? "red"
              : "green",
          })
        );
        setTransactionsData(formattedTransactions);
        //  transactions end

        const formatedAccountCreatedData = accountCreatedResponse.data.map(
          (accData: any) => ({
            status: "cancelled",
            mainInfo: `${
              accData.display_name ||
              accData.first_name + " " + accData.last_name
            }, number of transactions: ${accData.transaction_count}`,
            timeText: `Amount: ${transactionsService.formatNumber(
              accData.total_transaction_amount
            )}`,
            id: accData.id,
            color: "red",
          })
        );
        setAccountCreatedData(formatedAccountCreatedData);

        // Format and set account limit data
        const formatedAccountLimitData = accountLimitResponse.data.map(
          (accData: any) => ({
            status: "sending",
            mainInfo: `${
              accData.display_name ||
              accData.first_name + " " + accData.last_name
            }, number of transactions: ${accData.transaction_count}`,
            timeText: `Amount: ${transactionsService.formatNumber(
              accData.total_transaction_amount
            )}`,
            id: accData.id,
            color: "red",
          })
        );
        setAccountLimitData(formatedAccountLimitData);

        // Format and set account requests data
        const formatedAccountRequestsData = accountRequestsResponse.data.map(
          (accData: any) => ({
            status: "sending",
            mainInfo: `${
              accData.user?.display_name ||
              accData.user?.first_name + " " + accData.user?.last_name
            }, account : ${accData.currency?.code}`,
            timeText: `${accData?.requested_account_date}`,
            id: accData.user?.id,
            color: "red",
          })
        );
        setAccountRequestsData(formatedAccountRequestsData);

        // Format and set verification codes data
        setVerificationCodes(
          verificationCodesResponse.data.data.map((user: any) => ({
            mainInfo: `${user.code} - ${user.user.first_name} ${user.user.last_name} - ${user.user.email}`,
            timeText: new Date(user.created_at).toLocaleString("default", {
              year: "numeric",
              month: "long",
              day: "numeric",
              hour: "2-digit",
              minute: "2-digit",
            }),
            id: user.id,
            status: user.verified === 0 ? "cancelled" : "sent",
          }))
        );
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setIsLoading(false);
      }
    };
    fetchData();
  }, []);
  // actions end ------------------------------

  if (isLoading) {
    return <LoadingIndicator />; // Render loading state
  }

  return (
    <div className="actions-tab">
      <div className="verification-payment-container">
        <div className="single-timeline-box">
          <Timeline
            title="Verification requests"
            timelineData={verificationRequests}
          />
        </div>

        <div className="single-timeline-box">
          <Timeline
            title="Payment requests"
            timelineData={balanceRequestData}
          />
        </div>
      </div>

      <div className="verification-payment-container">
        <div className="single-timeline-box">
          <Timeline title="Transactions" timelineData={transactionsData} />
        </div>

        <div className="single-timeline-box">
          <Timeline
            title="Verification codes"
            timelineData={verificationCodes}
          />
        </div>
      </div>

      <div className="verification-payment-container">
        <div className="single-timeline-box">
          <Timeline
            title="Account Status / Created"
            timelineData={accountCreatedData}
          />
        </div>

        <div className="single-timeline-box">
          <Timeline
            title="Account Status / Has transactions"
            timelineData={accountLimitData}
          />
        </div>
      </div>

      <div id="accountRequests" className="verification-payment-container">
        <div className="single-timeline-box">
          <Timeline
            title="Account requests"
            timelineData={accountRequestsData}
          />
        </div>
      </div>
    </div>
  );
}
