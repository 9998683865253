import { Avatar, Box, Typography } from "@mui/material";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { useNavigate } from "react-router-dom";
import "../../pages/Admin/Branches/Branches.scss";

function BranchCardItem({ data, index }: any) {
  const navigate = useNavigate();

  const handleOnClick = () => {
    navigate(`/admin/branches/${data.id}`);
  };

  return (
    <Box
      key={"recipient" + index}
      className="allRecipientSingle-acc"
      onClick={handleOnClick}
    >
      <Box className="allRecipientInfo">
        <Box className="avatarAndNameEndingIn">
          <Avatar
            style={{
              width: 48,
              height: 48,
              backgroundColor: "#E5E8E2",
              color: "#454745",
            }}
          >
            {data.name
              .split(" ")
              .map((namePart: any, index: any) =>
                index < 2 ? namePart.charAt(0).toUpperCase() : ""
              )
              .join("")}
          </Avatar>
          <div className="d-flex ai-center">
            <Box className="nameAndEndingIn mr-1rem">
              <Typography className="allRecipientName">{data.name}</Typography>
              <Typography className="countrySymbolCodeBranches">
                {data.country} - {data.currency.symbol}
                {data.currency.code}
              </Typography>
            </Box>
          </div>
        </Box>
        <Box>
          <NavigateNextIcon className="arrayRightIcon" />
        </Box>
      </Box>
    </Box>
  );
}

export default BranchCardItem;
