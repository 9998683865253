import { Avatar, Box, IconButton, TextField, Typography } from "@mui/material";
import "../../../pages/Chat/Chat.scss";
import MoodIcon from "@mui/icons-material/Mood";
import UploadFileOrImage from "../UploadFileOrImage/UploadFileOrImage";
import { useFormik } from "formik";
import * as Yup from "yup";
import api from "src/store/interceptors/api";
import { useEffect, useRef, useState } from "react";
import LoadingIndicator from "../LoadingIndicator";
import SmsOutlinedIcon from "@mui/icons-material/SmsOutlined";
import ConversationContainerUser from "./ConversationContainerUser";
import EmojiPicker from "../EmojiPicker/Emoji";
import SendIcon from "@mui/icons-material/Send";
import PDFModal from "../PdfModal/PdfModal";
import CloseIcon from "@mui/icons-material/Close";
import i18next from "i18next";

export default function ChatContainerUser({ user, conversations }: any) {
  const [conversationMessagesUser, setConversationMessagesUser] = useState<
    any[]
  >([]);
  const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
  const [loadingUserChat, setLoadingUserChat] = useState(false);
  const [selectedEmoji, setSelectedEmoji] = useState("");
  const [loadMore, setLoadMore] = useState(false);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);

  const chatContainerRef = useRef<HTMLDivElement>(null);

  // pdf start
  const [openModal, setOpenModal] = useState(false);
  // State to store the URL of the PDF to display
  const [pdfUrl, setPdfUrl] = useState("");

  // Function to handle opening the PDF Modal
  const handleOpenModal = (fileUrl: any) => {
    setPdfUrl(fileUrl); // Set the URL of the PDF to display
    setOpenModal(true); // Open the modal
  };

  // Function to handle closing the PDF Modal
  const handleCloseModal = () => {
    setOpenModal(false);
  };

  // Function to handle removing the selected file
  const handleRemoveFile = () => {
    setSelectedFiles([]); // Clear the selected file
  };

  const renderFileName = (selectedFiles: any, handleOpenModal: any) => {
    const fileExists = selectedFiles.length > 0;
    const fileType = fileExists ? selectedFiles[0].type : "";
    const fileName = fileExists ? selectedFiles[0].name : "";

    const isPdf = fileType === "application/pdf";
    const isImage = fileType.startsWith("image/");

    const fileStyle: any = {
      textAlign: "center",
      fontSize: "10px",
      textDecoration: isPdf ? "underline" : "none",
      color: isPdf ? "#007BFF" : "inherit",
      cursor: isPdf || isImage ? "pointer" : "default",
    };

    const handleClick = () => {
      if (isPdf) {
        const fileUrl = URL.createObjectURL(selectedFiles[0]);
        handleOpenModal(fileUrl); // For PDFs, open the modal with the file
      }
      // Add logic for image click if needed
    };

    // Display for image files
    if (isImage) {
      return (
        <div className="d-flex ai-center">
          <div className="borderChat">
            <img
              src={URL.createObjectURL(selectedFiles[0])}
              alt={fileName}
              style={{
                maxWidth: "50px",
                //  paddingBottom: "8px",
                // margin: "auto",
                objectFit: "cover",
              }}
            />
          </div>

          <div>
            <IconButton
              onClick={handleRemoveFile}
              aria-label="close"
              style={{ marginLeft: "4px" }}
            >
              <CloseIcon
                sx={{
                  // height: 20,
                  fontSize: "0.85rem",
                }}
              />
            </IconButton>
          </div>
        </div>
      );
    }

    // Display for PDF and other files
    return (
      fileName.length > 0 && (
        <div className="d-flex ai-center">
          <div className="borderChat cp" onClick={handleClick}>
            <p style={fileStyle}>
              {fileExists
                ? `${fileName.substring(0, 9)}${
                    fileName.length > 9 ? "..." : ""
                  }`
                : ""}
            </p>
          </div>
          <div>
            <IconButton onClick={handleRemoveFile} aria-label="close">
              <CloseIcon
                sx={{
                  // height: 20,
                  fontSize: "0.85rem",
                }}
              />
            </IconButton>
          </div>
        </div>
      )
    );
  };

  // pdf end

  const handleKeyPress = (e: any) => {
    if (e.key === "Enter") {
      e.preventDefault();
      formik.handleSubmit();
    }
  };

  const handleEmojiSelect = (emoji: any) => {
    const updatedMessage = `${formik.values.message} ${emoji.native}`;
    formik.setFieldValue("message", updatedMessage);
    setSelectedEmoji(emoji.native);
  };

  const handleFileSelect = (files: FileList | null) => {
    if (files && files.length > 0) {
      setSelectedFiles([files[0]]);
      const fileNameContainer = document.getElementById("selectedFileName");
      if (fileNameContainer) {
        fileNameContainer.innerText = files[0].name;
      }
    } else {
      setSelectedFiles([]);
      const fileNameContainer = document.getElementById("selectedFileName");
      if (fileNameContainer) {
        fileNameContainer.innerText = "";
      }
    }
  };
  const resetFileInput = () => {
    const fileNameContainer = document.getElementById("selectedFileName");
    if (fileNameContainer) {
      fileNameContainer.innerText = "";
    }
    setSelectedFiles([]);
  };

  const appendMessages = async (msg: any) => {
    const oldMsg = [...conversationMessagesUser];
    const newMsg = [msg, ...oldMsg];
    setConversationMessagesUser(newMsg);
    resetFileInput();
  };

  const getConversationMessages = async (page = 1) => {
    // setLoadingUserChat(true);
    setLoadMore(true);
    let urlConversationMessagesUser = `/support-conversations?page=${page}&per_page=20`;

    try {
      const response = await api.get(urlConversationMessagesUser);

      if (response && response.data && response.data.data) {
        if (page === 1) {
          setConversationMessagesUser(response.data.data);
        } else {
          setConversationMessagesUser((prevData) => [
            ...prevData,
            ...response.data.data,
          ]);
        }
        setPage(response.data.meta.current_page);
        setTotalPages(response.data.meta.last_page);
      } else if (response.status === 404) {
        setConversationMessagesUser([]);
      }
      setLoadMore(false);
      // setLoadingUserChat(false);
    } catch (err) {
      console.error("Error getting user conversation messages: ", err);
      setConversationMessagesUser([]);
      setLoadingUserChat(false);
      setLoadMore(false);
    }
  };

  const handleGetMoreMessages = async () => {
    if (totalPages && page < totalPages) {
      const container = chatContainerRef.current;
      const currentScrollTop = container?.scrollTop || 0;
      const currentHeight = container?.scrollHeight || 0;

      await getConversationMessages(page + 1);

      if (container) {
        const newHeight = container.scrollHeight;
        const heightDiff = newHeight - currentHeight;
        container.scrollTop = currentScrollTop + heightDiff;
      }
    }
  };

  const formik = useFormik({
    initialValues: {
      message: "",
    },
    onSubmit: async (data) => {
      try {
        if (selectedFiles.length === 0 && data.message.trim() === "") {
          return;
        }
        const formData = new FormData();
        if (data.message.length > 0) {
          formData.append("message", data.message);
        }
        if (selectedFiles && selectedFiles.length > 0) {
          for (let i = 0; i < selectedFiles.length; i++) {
            formData.append(`attachments[${i}]`, selectedFiles[i]);
          }
        }

        const response = await api.post("/conversations", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });

        if (response.status === 200) {
          resetFileInput();
          formik.resetForm();
          if (
            response &&
            response.data &&
            response.data.data &&
            response.data.data.last_message
          ) {
            appendMessages(response.data.data.last_message);
          }
        }
      } catch (error) {
        console.error("Error:", error);
      }
    },
    validationSchema: Yup.object({
      // message: Yup.string().required("Message is required"),
    }),
  });
  useEffect(() => {
    getConversationMessages();
  }, []);

  return (
    <Box className="convoMain">
      {loadingUserChat ? (
        <LoadingIndicator />
      ) : (
        <>
          <Box className="chatHead">
            <Box className="chatHeadAvatarName">
              <Box>
                {conversations &&
                conversations.user &&
                conversations.user.image !== null ? (
                  <Avatar
                    src={
                      conversations.user?.image?.file_url
                        ? conversations.user.image.file_url
                        : null
                    }
                    alt={`${conversations.last_message.username}`}
                    sx={{ width: "40px", height: "40px", objectFit: "cover" }}
                  ></Avatar>
                ) : (
                  <Avatar sx={{ width: "40px", height: "40px" }}></Avatar>
                )}
              </Box>
              <Box className="nameAndOnlineBox">
                <Box>
                  <Typography className="headName">
                    {user?.role !== "Admin" && user?.role !== "Employee"
                      ? "Admin"
                      : conversationMessagesUser.length > 0
                      ? conversationMessagesUser[0].username
                      : "Unknown User"}
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>
          {conversationMessagesUser && conversationMessagesUser.length > 0 ? (
            <ConversationContainerUser
              handleScroll={handleGetMoreMessages}
              conversations={conversations}
              user={user}
              conversationMessagesUser={conversationMessagesUser}
              chatContainerRef={chatContainerRef}
              loadMore={loadMore}
            />
          ) : (
            <Box className="noConversationSelectedBox">
              <Typography>
                <>
                  <SmsOutlinedIcon sx={{ width: "100%" }} />
                  <Typography textAlign={"center"}>
                    {i18next.t("You can ask for help")}
                  </Typography>
                </>
              </Typography>
            </Box>
          )}
          <Box className="chatBoxAndSendMessageBox">
            <div>s</div>

            <Box className="sendMessageBox">
              <Box sx={{ width: "100%" }}>
                <TextField
                  name="message"
                  variant="standard"
                  placeholder="Type a message"
                  sx={{ width: "100%" }}
                  value={formik.values.message}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  onKeyDown={handleKeyPress}
                />
              </Box>
              <div>
                {/* Call the renderFileName function and pass in the selectedFiles and handleOpenModal */}
                {renderFileName(selectedFiles, handleOpenModal)}

                {/* PDFModal component */}
                <PDFModal
                  open={openModal}
                  handleClose={handleCloseModal}
                  fileUrl={pdfUrl}
                />
              </div>
              <Box>
                <div>
                  <EmojiPicker onSelect={handleEmojiSelect} />
                </div>
              </Box>
              <Box>
                <UploadFileOrImage onFileSelect={handleFileSelect} />
              </Box>
              <Box
                style={{ marginTop: "6px", cursor: "pointer" }}
                onClick={(e) => {
                  e.preventDefault();
                  formik.handleSubmit();
                }}
              >
                <SendIcon
                  fontSize="large"
                  style={{ color: "#2A5182", fontSize: "1.4rem" }}
                />
              </Box>
            </Box>
          </Box>
        </>
      )}
    </Box>
  );
}
