import { useState } from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Button from "@mui/material/Button";
import "./LoginPINModal.scss";
import KeyIcon from "@mui/icons-material/Key";
import LogoutOutlinedIcon from "@mui/icons-material/LogoutOutlined";
import { logout } from "src/store/login/login_slice";
import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "src/store";
import api from "src/store/interceptors/api";
import { toast } from "react-toastify";
import { storeUser } from "src/store/persist/persist";
import i18next from "i18next";
import BackspaceOutlinedIcon from "@mui/icons-material/BackspaceOutlined";

const LoginPINModal = ({
  onClose,
  user,
  showModal,
}: {
  onClose: () => void;
  user: any;
  showModal: boolean;
}) => {
  const [pin, setPin] = useState<string>("");
  const [disableButtons, setDisableButtons] = useState<boolean>(false);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const handlePinButtonClick = (index: number) => {
    const newPin = pin + index.toString();
    setPin(newPin);

    if (newPin.length === 6) {
      setDisableButtons(true);
      handleLoginWithPin(newPin);
    }
  };

  const handleBackspaceClick = () => {
    const newPin = pin.slice(0, -1);
    setPin(newPin);
  };

  const handleLoginWithPin = async (pin: string) => {
    try {
      const response = await api.post("/auth/login-with-pin", {
        email: user?.email,
        pin: pin,
      });

      if (response.status === 200) {
        if (response && response.data && response.data.access_token) {
          storeUser("token", response.data.access_token);
        }
        onClose();
        setPin("");
        setDisableButtons(false);
      } else {
        toast.error(i18next.t("Incorrect PIN. Please try again."));
        setPin("");
        setDisableButtons(false);
      }
    } catch (error) {
      setPin("");
      setDisableButtons(false);
    }
  };

  const handleLogoutClick = () => {
    dispatch(logout());
    onClose();
    navigate("/");
  };

  return (
    <Modal
      open={showModal}
      aria-labelledby="password-modal-title"
      aria-describedby="password-modal-description"
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: {
            xs: "90%",
            sm: 400,
          },
          bgcolor: "#f2f2f2",
          boxShadow: 24,
          borderRadius: 5,
          p: 4,
          display: "flex",
          flexDirection: "column",
          gap: 2,
        }}
      >
        <KeyIcon sx={{ fontSize: 70, color: "#2a5182", margin: "auto" }} />
        <h2
          className="password-modal-title FontSize12"
          style={{ textAlign: "center" }}
        >
          {i18next.t("Please enter your PIN code")}
        </h2>
        <Box>
          <Box className="pinDots">
            {Array.from({ length: 6 }, (_, index) => (
              <Box
                key={index}
                className={`pinDot ${index < pin.length ? "filled" : ""}`}
              />
            ))}
          </Box>
          <Box className="pinBtnsBox">
            {[1, 2, 3, 4, 5, 6, 7, 8, 9, 0].map((number) => (
              <Box
                key={number}
                className={`pinBtnBox ${number === 0 ? "zero" : ""}`}
              >
                <Button
                  variant="outlined"
                  className="pinButton"
                  onClick={() => handlePinButtonClick(number)}
                  disabled={disableButtons}
                >
                  {number}
                </Button>
              </Box>
            ))}
            <Box className="pinBtnBox backspace">
              <Button
                variant="outlined"
                className="backspaceButton"
                onClick={handleBackspaceClick}
                disabled={disableButtons}
              >
                <BackspaceOutlinedIcon className="backspaceModalIcon" />
              </Button>
            </Box>
          </Box>
          <Button
            fullWidth
            onClick={handleLogoutClick}
            variant="text"
            className="btnsLoginHeight oneRemFontSize"
            sx={{
              mt: 2,
              borderRadius: "10px",
            }}
          >
            <LogoutOutlinedIcon />
            {i18next.t("Logout")}
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default LoginPINModal;
