import { Typography, Box } from "@mui/material";
import { useNavigate } from "react-router-dom";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import Terms from "../TermsAndCondition/Terms";
import i18next from "i18next";
interface Props {
  data: any[];
  user: any;
  fromPage?: string;
  showTitle?: boolean | null | undefined;
}

function InvestmentListItem({ data, user, fromPage, showTitle }: Props) {
  const navigate = useNavigate();

  const handleOnClick = (item: any) => {
    if (fromPage && fromPage === "admin")
      navigate(`/admin/investments/${item.id}`);
    else navigate(`/investment-detail/${item.id}`);
  };

  const renderIcon = () => {
    return (
      <ArrowUpwardIcon style={{ color: "#0e0f0c" }} className="muiIcons" />
    );
  };

  const formatDate = (sectionName: string) => {
    // Check if sectionName is a valid date
    const date = new Date(sectionName);
    if (!isNaN(date.getTime())) {
      // It's a valid date, format it
      const currentLanguage = i18next.language || "en-US";
      return new Intl.DateTimeFormat(currentLanguage, {
        month: "long",
        day: "numeric",
      }).format(date);
    } else {
      // Not a valid date, return as is
      return sectionName;
    }
  };

  const getUserName = (user: any) => {
    if (!user) return "";

    if (user.account_type === "business" && user.company_name) {
      return user.company_name;
    }

    if (user.account_type === "personal" && user.first_name && user.last_name) {
      return `${user.first_name} ${user.last_name}`;
    }

    return "";
  };
  return (
    <div className="transaction-section">
      <div className="transaction-list-container">
        {showTitle && data && data.length > 0 && (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <div className="d-flex ai-center mb-1-rem">
              <Typography
                className="your-details-text d-flex jc-start"
                component="h1"
                variant="h5"
                sx={{
                  fontFamily: "Helvetica, sans-serif",
                  fontSize: "1.875rem",
                  textAlign: "center",
                  fontWeight: "600",
                }}
              >
                Investment orders
              </Typography>
            </div>
          </Box>
        )}
        {data &&
          data.length > 0 &&
          data.map((item, index) => (
            <div
              className="transaction-item"
              onClick={() => handleOnClick(item)}
              key={"uniqueID" + index}
            >
              <div className="d-flex ai-center jc-center">
                <p className="back-button-icon circle-around-text-smaller helvetica mr-1-rem">
                  {renderIcon()}{" "}
                </p>
                <div>
                  <Typography
                    component="h1"
                    variant="h5"
                    sx={{
                      fontFamily: "Helvetica, sans-serif",
                      fontSize: "1rem",
                      fontWeight: "600",
                      color: "#454745",
                      marginBottom: "0.5rem",
                    }}
                  >
                    {getUserName(item.user)}
                  </Typography>
                  <Typography
                    component="h1"
                    variant="h5"
                    sx={{
                      fontFamily: "Helvetica, sans-serif",
                      fontSize: "0.875rem",
                      fontWeight: "200",
                      color: "#454745",
                    }}
                  >
                    {item.status} / {formatDate(item.created_at)}
                  </Typography>
                </div>
              </div>
              <div className="ta-end">
                <Typography
                  component="h1"
                  variant="h5"
                  sx={{
                    fontFamily: "Helvetica, sans-serif",
                    fontSize: "0.875rem",
                    fontWeight: "200",
                    color: "#454745",
                  }}
                >
                  {item.amount}
                </Typography>
              </div>
            </div>
          ))}
      </div>
    </div>
  );
}

export default InvestmentListItem;
