import { useFormik } from "formik";
import { useState } from "react";
import * as Yup from "yup";
import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import SwapHorizIcon from "@mui/icons-material/SwapHoriz";
import "./AmountStep.scss";
import i18next from "i18next";
import BackNextBtns from "../shared/BackNextBtns/BackNextBtns";
import { useNavigate } from "react-router-dom";

interface Props {
  setStep: (value: number) => void;
  step: number;
  currencies: any;
  setQuest: (value: any) => void;
  navigate: ReturnType<typeof useNavigate>;
}

function International({
  currencies,
  step,
  setStep,
  setQuest,
  navigate,
}: Props) {
  const [senderCurrency, setSenderCurrency] = useState(currencies[0]);
  const [recipientCurrency, setRecipientCurrency] = useState(
    currencies && currencies.length > 0 ? currencies[1] : currencies[0]
  );
  const [errorMsg, setErrorMsg] = useState<string | null>(null);

  const handleSwapCurrency = () => {
    const tmpSender = senderCurrency;
    const tmpReceiver = recipientCurrency;
    formik.setFieldValue("recipentCurrency", tmpSender.currency);
    formik.setFieldValue("senderCurrency", tmpReceiver.currency);
    setRecipientCurrency(senderCurrency);
    setSenderCurrency(recipientCurrency);
  };
  const handleSenderCurrencyChange = (event: any, newValue: any) => {
    setErrorMsg(null);

    if (newValue && newValue.currency) {
      formik.setFieldValue("recipentCurrency", newValue.currency);
      formik.setFieldValue("senderCurrency", newValue.currency);

      if (newValue.currency === recipientCurrency.currency) {
        setRecipientCurrency(senderCurrency);
      }
      setSenderCurrency(newValue);
    }
  };

  const handleRecipientCurrencyChange = (event: any, newValue: any) => {
    setErrorMsg(null);
    if (newValue.currency === recipientCurrency.currency) {
      setSenderCurrency(recipientCurrency);
    }
    setRecipientCurrency(newValue ? newValue : null);
  };

  const formik = useFormik({
    initialValues: {
      senderAmount: "",
      senderCurrency: currencies[0].currency,
      recipientAmount: "",
      recipientCurrency:
        currencies && currencies.length > 0
          ? currencies[1].currency
          : currencies[0].currency,
      country_code: "",
    },
    onSubmit: async (values: any) => {
      setErrorMsg(null);
      setQuest({
        sourceCurrency: senderCurrency.currency,
        targetCurrency: recipientCurrency.currency,
        formated: {
          sourceCurrency: senderCurrency.currency,
          targetCurrency: recipientCurrency.currency,
        },
      });
      setStep(step + 1);
    },
    validationSchema: Yup.object({
      senderCurrency: Yup.string().required(
        i18next.t("Sender Currency is required.")
      ),
      recipientCurrency: Yup.string().required(
        i18next.t("Recepient currency is required.")
      ),
    }),
  });

  return (
    <>
      <div>
        <Typography className="labelsInternational">
          {i18next.t("From currency")}
        </Typography>
        <Autocomplete
          id="currency-select"
          sx={{
            "& .MuiOutlinedInput-root": {
              borderRadius: "10px",
            },
          }}
          options={
            currencies && currencies.length > 0
              ? currencies.filter(
                  (item: any) => item.currency !== recipientCurrency.currency
                )
              : []
          }
          value={senderCurrency}
          getOptionLabel={(option) => option.currency}
          onChange={handleSenderCurrencyChange}
          renderOption={(props, option) => (
            <Box
              component="li"
              {...props}
              className="d-flex ai-center mt-1-rem cp"
            >
              <p className="normal-text" style={{ marginLeft: 10 }}>
                {option.currency}
              </p>
            </Box>
          )}
          renderInput={(params) => <TextField {...params} />}
          clearIcon={null}
        />

        <Grid container spacing={5} sx={{ padding: 1 }}>
          <Grid item xs={8}>
            <Typography className="labelsInternational">
              {i18next.t("To Currency")}
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Button type="submit" variant="text" onClick={handleSwapCurrency}>
              <SwapHorizIcon className="muiIcons" />
            </Button>
          </Grid>
        </Grid>

        <Autocomplete
          id="currency-select"
          sx={{
            marginBottom: "16px",
            "& .MuiOutlinedInput-root": {
              borderRadius: "10px",
            },
          }}
          options={currencies.filter(
            (item: any) => item.currency !== senderCurrency.currency
          )}
          value={recipientCurrency}
          getOptionLabel={(option) => option.currency}
          onChange={handleRecipientCurrencyChange}
          renderOption={(props, option) => (
            <Box
              component="li"
              {...props}
              className="d-flex ai-center mt-1-rem cp"
            >
              <p className="normal-text" style={{ marginLeft: 10 }}>
                {option.currency}
              </p>
            </Box>
          )}
          renderInput={(params) => <TextField {...params} />}
          clearIcon={null}
        />

        {errorMsg && <Typography className="ErrorMsg">{errorMsg}</Typography>}
        <div className="d-flex ai-center jusifyContentSpaceBetween mt-8">
          <BackNextBtns
            backText={i18next.t("Cancel")}
            nextText={i18next.t("Continue")}
            isNextDisabled={!formik.values.senderCurrency || errorMsg}
            backType="button"
            nextType="submit"
            onBackClick={() => navigate(-1)}
            onNextClick={(e: any) => formik.handleSubmit(e)}
          />
        </div>
      </div>
    </>
  );
}

export default International;
