import React, { useState, useEffect } from "react";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Nav from "src/components/layout/nav/Nav";
import { Typography, Box } from "@mui/material";
import api from "src/store/interceptors/api";
import { useAppSelector } from "src/store";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate } from "react-router-dom";
import LoadingIndicator from "src/components/shared/LoadingIndicator";
import BalanceRequestListItem from "src/pages/Balance/components/BalanceRequestListItem";
import CryptoRequestListItem from "src/pages/Balance/components/CryptoRequestListItem";
import LinearProgress from "@mui/material/LinearProgress";
import { useLocation } from "react-router-dom";

const defaultTheme = createTheme();
const BalanceRequestListAdmin: React.FC = () => {
  const { user } = useAppSelector((state) => state.auth);
  const navigate = useNavigate();
  const [balanceRequestData, setBalanceRequestData] = useState<any[]>([]);
  const [cryptoRequestData, setCryptoRequestData] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingBalanceOrCrypto, setIsLoadingBalanceOrCrypto] =
    useState(false);
  const location = useLocation();

  // tabs start
  const [selectedTab, setSelectedTab] = useState("PaymentRequestsBalance");

  // balance requests fiter api start
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  // balance requests fiter api end

  const handleTabClick = (tabName: string) => {
    // Reset data states
    setBalanceRequestData([]);
    setCryptoRequestData([]);
    setCurrentPage(1);
    setTotalPages(0);
    setIsLoading(true);

    // Set the selected tab
    setSelectedTab(tabName);

    // Navigate based on tab name
    if (tabName === "Crypto") {
      navigate("/admin/balance-list/crypto");
    } else if (tabName === "PaymentRequestsBalance") {
      navigate("/admin/balance-list/request-payments");
    }
  };
  // tabs end

  const handleNavigate = () => {
    navigate("/admin");
  };

  const getBalanceRequests = async (page = 1) => {
    if (balanceRequestData.length === 0 || page === 1) {
      setIsLoading(true);
    } else {
      setIsLoadingBalanceOrCrypto(true);
    }

    try {
      const response = await api.get(
        `payment-requests?page=${page}&per_page=20`
      );
      if (response && response.data && response.data.data) {
        // Filter out any data that's already been set to prevent duplicates
        const newData = response.data.data.filter(
          (newItem: any) =>
            !balanceRequestData.some(
              (existingItem) => existingItem.id === newItem.id
            )
        );

        setBalanceRequestData((prevData) => [...prevData, ...newData]);
        setCurrentPage(response.data.meta.current_page);
        setTotalPages(response.data.meta.last_page);
      }
    } catch (error) {
      console.error("Failed to fetch balance requests", error);
    } finally {
      setIsLoadingBalanceOrCrypto(false);
      setIsLoading(false);
    }
  };

  const getCryptoDetails = async (page = 1) => {
    if (cryptoRequestData.length === 0 || page === 1) {
      setIsLoading(true);
    } else {
      setIsLoadingBalanceOrCrypto(true);
    }

    try {
      const response = await api.get(`crypto-payment?page=${page}&per_page=20`);
      if (response && response.data && response.data.data) {
        // Filter out any data that's already been set to prevent duplicates
        const newData = response.data.data.filter(
          (newItem: any) =>
            !cryptoRequestData.some(
              (existingItem) => existingItem.id === newItem.id
            )
        );

        setCryptoRequestData((prevData) => [...prevData, ...newData]);
        setCurrentPage(response.data.meta.current_page);
        setTotalPages(response.data.meta.last_page);
      }
    } catch (error) {
      console.error("Failed to fetch balance requests", error);
    } finally {
      setIsLoadingBalanceOrCrypto(false);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    // Check if the current URL is for the 'crypto' tab
    if (location.pathname.includes("/admin/balance-list/crypto")) {
      setSelectedTab("Crypto");
    }
    // Check if the current URL is for the 'payment requests balance' tab
    else if (
      location.pathname.includes("/admin/balance-list/request-payments")
    ) {
      setSelectedTab("PaymentRequestsBalance");
    }
  }, [location]);

  // useEffect for tab switching
  useEffect(() => {
    if (selectedTab === "PaymentRequestsBalance") {
      getBalanceRequests();
    } else if (selectedTab === "Crypto") {
      getCryptoDetails();
    }
    // This effect runs whenever selectedTab changes.
  }, [selectedTab]);

  // on scroll get more items start
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleGetMoreBalanceRequests = async () => {
    // Check that totalPages is not null or undefined before comparing
    if (totalPages && currentPage < totalPages) {
      await getBalanceRequests(currentPage + 1);
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleGetMoreCryptoRequests = async () => {
    if (totalPages && currentPage < totalPages) {
      await getCryptoDetails(currentPage + 1);
    }
  };

  const debounce = (func: any, wait: any) => {
    let timeout: any;

    return function executedFunction(...args: any) {
      const later = () => {
        clearTimeout(timeout);
        func(...args);
      };

      clearTimeout(timeout);
      timeout = setTimeout(later, wait);
    };
  };

  useEffect(() => {
    // Define a debounced handle scroll
    const debouncedHandleScroll = debounce(() => {
      const scrollTop =
        (document.documentElement && document.documentElement.scrollTop) ||
        document.body.scrollTop;

      const scrollHeight =
        (document.documentElement && document.documentElement.scrollHeight) ||
        document.body.scrollHeight;

      const clientHeight =
        document.documentElement.clientHeight || window.innerHeight;

      // Add a buffer so that the event is triggered a little before the user actually hits the bottom
      const buffer = 100;

      if (scrollTop + clientHeight >= scrollHeight - buffer && !isLoading) {
        if (selectedTab === "PaymentRequestsBalance") {
          handleGetMoreBalanceRequests();
        } else if (selectedTab === "Crypto") {
          handleGetMoreCryptoRequests();
        }
      }
    }, 100); // 100ms debounce time

    // Attach the debounced scroll event handler
    window.addEventListener("scroll", debouncedHandleScroll);

    return () => {
      window.removeEventListener("scroll", debouncedHandleScroll);
    };
  }, [
    isLoading,
    selectedTab,
    handleGetMoreBalanceRequests,
    handleGetMoreCryptoRequests,
  ]);

  //   // on scroll get more items end

  return (
    <div>
      <Nav step={0} isStepperVisible={false} isProfileSetVisible={true} />
      <div className="content-container-profile">
        <ThemeProvider theme={defaultTheme}>
          <Container component="main">
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <div>
                <div
                  className="d-flex ai-center mb-3-rem"
                  style={{ marginBottom: "32px", marginLeft: "12px" }}
                >
                  <p
                    className="back-button-icon circle-around-text-smaller helvetica mr-1-rem cp"
                    onClick={handleNavigate}
                  >
                    <ArrowBackIcon className="muiIcons"/>
                  </p>

                  <Typography
                    className="your-details-text d-flex jc-start oneRemFontSize"
                    component="h1"
                    variant="h5"
                    sx={{
                      fontFamily: "Helvetica, sans-serif",
                      textAlign: "center",
                      fontWeight: "600",
                      marginRight: "2rem",
                      color:
                        selectedTab === "PaymentRequestsBalance"
                          ? "#2A5182"
                          : "inherit",
                      cursor: "pointer",
                      backgroundColor:
                        selectedTab === "PaymentRequestsBalance"
                          ? "#16330014;"
                          : "none",
                      "&:hover": {
                        backgroundColor: "rgb(244, 246, 248)",
                      },
                      padding: "0.6rem",
                      borderRadius: "16px",
                    }}
                    onClick={() => handleTabClick("PaymentRequestsBalance")}
                  >
                    Balance
                  </Typography>

                  <Typography
                    className="your-details-text d-flex jc-start oneRemFontSize"
                    component="h1"
                    variant="h5"
                    sx={{
                      fontFamily: "Helvetica, sans-serif",
                      textAlign: "center",
                      fontWeight: "600",
                      color: selectedTab === "Crypto" ? "#2A5182" : "inherit",
                      cursor: "pointer",
                      backgroundColor:
                        selectedTab === "Crypto" ? "#16330014;" : "none",
                      padding: "0.6rem",
                      borderRadius: "16px",
                      "&:hover": {
                        backgroundColor: "rgb(244, 246, 248)",
                      },
                    }}
                    onClick={() => handleTabClick("Crypto")}
                  >
                    Crypto
                  </Typography>
                </div>
              </div>
              {isLoading && <LoadingIndicator />}
              {!isLoading && selectedTab === "PaymentRequestsBalance" && (
                <BalanceRequestListItem user={user} data={balanceRequestData} />
              )}

              {!isLoading && selectedTab === "Crypto" && (
                // <BalanceRequestListItem user={user} data={balanceRequestData} />
                <CryptoRequestListItem user={user} data={cryptoRequestData} />
              )}

              {isLoadingBalanceOrCrypto && (
                <Box sx={{ width: "100%" }}>
                  <LinearProgress />
                </Box>
              )}
            </Box>
          </Container>
        </ThemeProvider>
      </div>
    </div>
  );
};

export default BalanceRequestListAdmin;
