import React from "react";
import { Modal, Typography, Button, Box } from "@mui/material";
import "./AreYouSureModal.scss";
import i18next from "src/i18n";

interface AreYouSureModalProps {
  open: boolean;
  handleClose: () => void;
  handleYes: () => void;
  title: string;
}

const AreYouSureModal: React.FC<AreYouSureModalProps> = ({
  open,
  handleClose,
  handleYes,
  title,
}) => {
  return (
    <Modal open={open} onClose={handleClose}>
      <Box className="areYouSureModalMain">
        <Typography className="areYouSureText">{title}</Typography>
        <Box sx={{ display: "flex", justifyContent: "flex-end", gap: "10px" }}>
          <Button
            onClick={handleClose}
            variant="contained"
            className="cancelBtn"
          >
            {i18next.t("Cancel")}
          </Button>
          <Button onClick={handleYes} variant="contained" className="yesBtn">
            {i18next.t("Yes")}
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default AreYouSureModal;
