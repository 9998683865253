import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { useEffect, useState } from "react";
import LoadingIndicator from "src/components/shared/LoadingIndicator";
import RecipientList from "src/components/RecipientsList/RecipientList";
import recipientsService from "src/store/recipients/recipients_services";
import {
  Box,
  Button,
  InputAdornment,
  Pagination,
  TextField,
  Typography,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import i18next from "i18next";

const defaultTheme = createTheme();

export default function RecipientsList({ hideMenu }: any) {
  const [savedUser, setSavedUser] = useState<any[]>([]);
  const [page, setPage] = useState(1);
  const [perPage] = useState(10);
  const [totalPages, setTotalPages] = useState(1);
  const [total, setTotal] = useState(null);
  const [searchValue, setSearchValue] = useState("");
  const [loading, setLoading] = useState(false);

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(event.target.value);
    setPage(1);
  };

  const handleGetRecipients = async () => {
    setLoading(true);
    let urlFilter = null;
    if (searchValue) {
      urlFilter = `?page=${page}&per_page=${perPage}&search[account_holder]=${searchValue}`;
    } else {
      urlFilter = `?page=${page}&per_page=${perPage}`;
    }
    const response = await recipientsService.getRecipients(urlFilter);
    const { data, meta } = response;
    setLoading(false);
    if (data) {
      setSavedUser(data);
    }
    if (meta) {
      setTotalPages(meta?.last_page || 1);
      setTotal(meta?.total || 0);
    }
  };

  useEffect(() => {
    handleGetRecipients();
  }, [searchValue, page]);

  return (
    <div>
      <div className="content-container-profile">
        <ThemeProvider theme={defaultTheme}>
          <Container component="main">
            {!hideMenu && (
              <Typography
                className="your-details-text d-flex jc-start"
                component="h1"
                variant="h5"
                sx={{
                  fontFamily: "Helvetica, sans-serif",
                  fontSize: "1.875rem",
                  fontWeight: "600",
                  textAlign: "start",
                }}
              >
                {i18next.t("Recipients")}
              </Typography>
            )}
            <Box sx={{ marginTop: "32px" }}>
              <TextField
                placeholder={i18next.t("Search recipient by name")}
                style={{ width: "100%" }}
                value={searchValue}
                onChange={handleSearchChange}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon className="searchIcon" />
                    </InputAdornment>
                  ),
                  sx: {
                    borderRadius: "40px",
                    height: "48px",
                    fontSize: "16px",
                    "&:-internal-autofill-selected": {
                      backgroundColor: "transparent !important",
                    },
                  },
                }}
              ></TextField>
            </Box>

            {loading ? (
              <LoadingIndicator />
            ) : (
              <Box>
                <RecipientList
                  data={savedUser}
                  hideNavigation={true}
                  loading={loading}
                />
                {total && total > perPage ? (
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      marginTop: "2rem",
                    }}
                  >
                    <Pagination
                      count={totalPages}
                      page={page}
                      onChange={(event, value) => setPage(value)}
                      color="primary"
                    />
                  </Box>
                ) : null}
              </Box>
            )}
          </Container>
        </ThemeProvider>
      </div>
    </div>
  );
}
