import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import "./TransactionsList.scss";
import Nav from "src/components/layout/nav/Nav";
import { useEffect, useState } from "react";
import transactionsService from "src/store/transactions/transactions_service";
import LoadingIndicator from "src/components/shared/LoadingIndicator";
import TransactionList from "src/components/TransactionsList/TransactionList";
import { LinearProgress } from "@mui/material";
import { debounce } from "lodash";
import api from "src/store/interceptors/api";

const defaultTheme = createTheme();

export default function TransactionsList() {
  const [transactionsData, setTransactionsData] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [searchValue, setSearchValue] = useState("");
  const [searchFilters, setSearchFilters] = useState(null);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [perPage, setPerPage] = useState(20);
  const [currentPage, setCurrentPage] = useState(1);
  const [hasMorePages, setHasMorePages] = useState(true);
  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(event.target.value);
  };

  const handleClearFilters = () => {
    setSearchFilters(null);
  };
  const handleApplyFilters = (filters: any) => {
    let strFilters = null;
    let urlFilter = null;
    if (filters) {
      if (!urlFilter) urlFilter = `?`;
      const aryFilters: any = [];
      Object.entries(filters).map(([subKey, subValue]: any) => {
        if (subKey) {
          if (subValue) {
            if (subKey === "recipients") {
              if (subValue && subValue.users && subValue.users.length > 0) {
                aryFilters.push(
                  "multiple_search[recipient_id]=" + subValue.users.join(",")
                );
              }
            } else if (subKey === "clients") {
              if (subValue && subValue.users && subValue.users.length > 0) {
                aryFilters.push(
                  "multiple_search[recipient_id]=" + subValue.users.join(",")
                );
              }
            } else if (subKey === "dates") {
              if (subValue && subValue.dateFrom) {
                aryFilters.push("start_date[created_at]=" + subValue.dateFrom);
              }
              if (subValue && subValue.dateTo) {
                aryFilters.push("end_date[created_at]=" + subValue.dateTo);
              }
            } else if (subKey === "direction") {
              if (
                subValue &&
                subValue.selectedDirection &&
                subValue.selectedDirection
              ) {
                let aryFilter;
                if (subValue.selectedDirection === "in") {
                  aryFilter = "search[transaction_type]=balance_in";
                } else if (subValue.selectedDirection === "out") {
                  aryFilter = "search[transaction_type]=balance_out";
                } else if (subValue.selectedDirection === "All") {
                  aryFilter =
                    "transaction_type[0]=balance_in&transaction_type[1]=balance_out";
                }
                if (subValue.selectedDirection !== "in") {
                  aryFilters.push(
                    // "exact_search[transaction_type]=" + subValue.selectedDirection
                    aryFilter
                  );
                }
              }
            } else if (subKey === "status") {
              if (subValue && subValue.selectedStatus) {
                if (subValue.selectedStatus !== "All")
                  aryFilters.push("search[status]=" + subValue.selectedStatus);
              }
            } else if (subKey === "currency") {
              if (subValue && subValue.selectedCurrency && subValue.selectedCurrency.id) {
                aryFilters.push(
                  "search[currency_id]=" + subValue.selectedCurrency.id
                );
              }
            } else if (subKey === "types") {
              if (subValue && Array.isArray(subValue.methods)) {
                // Check if all three methods are selected
                const allMethodsSelected = ["Bank", "Card", "Balance"].every(
                  (method) => subValue.methods.includes(method)
                );

                if (allMethodsSelected) {
                  // If all three are selected, push a special word or phrase
                  aryFilters.push("exact_search[transaction_type]=All");
                } else {
                  const bankIndex = subValue.methods.indexOf("Bank");
                  const cardIndex = subValue.methods.indexOf("Card");
                  const bothSelected = bankIndex !== -1 && cardIndex !== -1;

                  if (bothSelected) {
                    // Both Bank and Card are selected, construct the filter accordingly
                    aryFilters.push(
                      "transaction_type[0]=balance_in_bank&transaction_type[1]=balance_in_credit_card"
                    );
                  } else {
                    // Only one or neither selected, proceed as before
                    const filtersToAdd = subValue.methods
                      .map((method: any) => {
                        switch (method) {
                          case "Bank":
                            return "search[transaction_type]=balance_in_bank";
                          case "Card":
                            return "search[transaction_type]=balance_in_credit_card";
                          case "Balance":
                            return "search[transaction_type]=balance_in";
                          default:
                            return null;
                        }
                      })
                      .filter(Boolean);
                    aryFilters.push(...filtersToAdd);
                  }
                }
              }
            }
          }
        }
      });
      if (aryFilters && aryFilters.length > 0) {
        strFilters = aryFilters.join("&");
        setSearchFilters(strFilters);
      }
    }
  };

  const printPdf = async () => {
    try {
      let url = "transaction-pdf";

      let urlFilter = null;
      if (searchValue) {
        urlFilter = `?search[user.first_name]=${searchValue}&search[userTo.first_name]=${searchValue}&search[userFrom.first_name]=${searchValue}&search[recipient.account_holder]=${searchValue}`;
      } else {
        urlFilter = ``;
      }
      if (searchFilters) {
        if (!urlFilter) urlFilter = `?`;
        urlFilter = urlFilter + "&" + searchFilters;
      }
      const response = await api.get(url + urlFilter, {
        responseType: "blob",
      });

      const fileURL = window.URL.createObjectURL(new Blob([response.data]));
      const fileLink = document.createElement("a");
      fileLink.href = fileURL;
      
      fileLink.setAttribute("download", "Transactions-"+ Date.now() +".pdf");
      document.body.appendChild(fileLink);

      fileLink.click();

      window.URL.revokeObjectURL(fileURL);
      fileLink.remove();
    } catch (error) {
      console.error("Error downloading filtered transactions PDF:", error);
      throw error;
    }
  };
  const handleGetTransactions = async (page: number = 1) => {
    let urlFilter = null;
    if (searchValue) {
      urlFilter = `?per_page=${perPage}&page=${page}&search[user.first_name]=${searchValue}&search[userTo.first_name]=${searchValue}&search[userFrom.first_name]=${searchValue}&search[recipient.account_holder]=${searchValue}`;
    } else {
      urlFilter = `?per_page=${perPage}&page=${page}`;
    }
    if (searchFilters) {
      if (!urlFilter) urlFilter = `?`;
      urlFilter = urlFilter + "&" + searchFilters;
    }
    const response = await transactionsService.getTransactions(urlFilter);
    if (response) {
      const processedTransactions =
        transactionsService.processTransactions(response);
      setTransactionsData(processedTransactions);
      setIsLoading(false);
    } else {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    handleGetTransactions();
  }, [searchValue, searchFilters, currentPage, perPage]);

  const handleGetMoreTransactions = async () => {
    try {
      setIsLoadingMore(true);

      if (!hasMorePages) {
        setIsLoadingMore(false);
        return;
      }

      const nextPage = currentPage + 1;

      const response = await transactionsService.getTransactions(
        `?per_page=${perPage}&page=${nextPage}`
      );

      if (response) {
        const processedTransactions =
          transactionsService.processTransactions(response);

        if (perPage > response.meta.total) {
          setHasMorePages(false);
        }
        setTimeout(() => {
          setCurrentPage(nextPage);
          setPerPage(perPage + 20);

          setTransactionsData((prevData) => [
            ...prevData,
            ...processedTransactions,
          ]);

          setIsLoadingMore(false);
        }, 500);
      }
    } catch (error) {
      setIsLoadingMore(false);
    }
  };

  const handleScroll = debounce(() => {
    const scrollTop =
      (document.documentElement && document.documentElement.scrollTop) ||
      document.body.scrollTop;
    const scrollHeight =
      (document.documentElement && document.documentElement.scrollHeight) ||
      document.body.scrollHeight;
    const clientHeight =
      document.documentElement.clientHeight || window.innerHeight;

    if (scrollTop + clientHeight >= scrollHeight - 20 && !isLoadingMore) {
      handleGetMoreTransactions();
    }
  }, 100);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [isLoadingMore]);

  return (
    <div>
      <Nav step={0} isStepperVisible={false} isProfileSetVisible={true} />
      <div className="content-container-profile">
        <ThemeProvider theme={defaultTheme}>
          <Container component="main">
            {isLoading ? (
              <LoadingIndicator />
            ) : (
              <TransactionList
                data={transactionsData}
                printPdf={printPdf}
                handleSearchChange={handleSearchChange}
                applyFilters={handleApplyFilters}
                handleClearFilters={handleClearFilters}
                searchValue={searchValue}
              />
            )}
            {isLoadingMore && <LinearProgress />}
          </Container>
        </ThemeProvider>
      </div>
    </div>
  );
}
