import React, { useState, useEffect } from "react";
import Paper from "@mui/material/Paper";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";

import "./Terms.scss";
import CustomerAgreementPersonal from "./Content/CustomerAgreementPersonal";
import { Box } from "@mui/material";

import Modal from "@mui/material/Modal";
import Button from "@mui/material/Button";
import LocalPoliceIcon from "@mui/icons-material/LocalPolice";
import TermsUK from "./Content/TermsUK";
import i18next from "src/i18n";

interface TermsProps {
  type: string;
  onChecked: (checked: any) => void;
  open?: boolean;
  openForce?: boolean;
  hideButton?: boolean;
  hideCheckbox?: boolean;
  buttonSettings?: boolean;
  settingsButton?: boolean;
  margin?: number;
}

const getTermContent = (type: string) => {
  if (type === "customer-agreement-personal") {
    return <CustomerAgreementPersonal />;
  } else if (type === "terms-uk") {
    return <TermsUK />;
  } else {
    return "";
  }
};
const Terms: React.FC<TermsProps> = ({
  type,
  onChecked,
  open,
  openForce,
  hideButton,
  hideCheckbox,
  buttonSettings,
  settingsButton = false,
  margin = 5,
}) => {
  const [isChecked, setChecked] = useState(false);
  const [enableCheck, setEnableCheck] = useState(false);

  const [openModal, setOpenModal] = useState(false);

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleOnChecked = () => {
    const statusChecked = !isChecked;
    setChecked(statusChecked);
    onChecked(statusChecked);
  };

  const handleScroll = (e: any) => {
    const target = e.target;
    const isAtBottom =
      target.scrollHeight - target.scrollTop <= target.clientHeight + 250;
    //  console.log(target.scrollHeight - target.scrollTop, target.clientHeight, target.scrollHeight +' - ' + target.scrollTop + ' === ' + target.clientHeight)
    if (!enableCheck) setEnableCheck(isAtBottom);
  };

  useEffect(() => {
    if (open) {
      if (!openModal) setOpenModal(true);
    } else {
      if (openModal) setOpenModal(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  useEffect(() => {
    if (openForce) {
      setOpenModal(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openForce]);

  return (
    <Box sx={{ margin }}>
      {!hideButton &&
        (buttonSettings ? (
          <Button
            className="walletBtn"
            onClick={handleOpenModal}
            startIcon={<LocalPoliceIcon />}
          >
            {settingsButton
              ? i18next.t("Terms and conditions")
              : i18next.t("Read Terms and conditions")}
          </Button>
        ) : (
          <Button
            sx={{ color: "#000", textDecoration: "underline" }}
            variant="text"
            onClick={handleOpenModal}
            startIcon={<LocalPoliceIcon />}
          >
            {i18next.t("Read Terms and conditions")}
          </Button>
        ))}

      <Modal
        open={openModal}
        onClose={handleCloseModal}
        aria-labelledby="terms-modal-title"
        aria-describedby="terms-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "90%",
            bgcolor: "background.paper",
            p: 4,
          }}
        >
          <Paper
            style={{
              maxHeight: "70vh",
              overflowY: "auto",
              marginBottom: 32,
              padding: 20,
            }}
            onScroll={handleScroll}
          >
            {getTermContent(type)}
          </Paper>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            {!hideCheckbox && (
              <FormControlLabel
                control={
                  <Checkbox onChange={handleOnChecked} checked={isChecked} />
                }
                label="Accept Terms"
                disabled={!enableCheck}
              />
            )}
            <Button
              variant="text"
              sx={{
                border: "1px solid #2a5182",
                borderRadius: "24px",
                width: "100px",
                textTransform: "none",
                "&:hover": {
                  backgroundColor: "#2a5182",
                  color: "#ffffff",
                  transition: "all 0.3s",
                },
              }}
              onClick={handleCloseModal}
            >
              {i18next.t("Close")}
            </Button>
          </Box>
        </Box>
      </Modal>
    </Box>
  );
};

export default Terms;
