import { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { useNavigate, useParams } from "react-router-dom";
import Nav from "src/components/layout/nav/Nav";
import * as Yup from "yup";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import LoadingIndicator from "src/components/shared/LoadingIndicator";
import api from "src/store/interceptors/api";
import { Autocomplete, Button, TextField } from "@mui/material";
import CountrySelect from "src/components/shared/CountrySelect/CountrySelect";
import { useFormik } from "formik";
import { toast } from "react-toastify";

const defaultTheme = createTheme();

export default function EditBranch() {
  const { branchId }: any = useParams();
  const [currencies, setCurrencies] = useState<any>(null);
  const [currency, setCurrency] = useState<any>(null);
  const [branchDetails, setBranchDetails] = useState<any>(null);
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const handleNavigate = () => {
    navigate(-1);
  };

  const fetchBranchDetails = async () => {
    setLoading(true);
    try {
      const response = await api.get(`/branches/${branchId}`);
      setBranchDetails(response?.data?.data);
    } catch (error: any) {}
    setLoading(false);
  };

  const formik = useFormik({
    initialValues: {
      currency_id: branchDetails ? branchDetails.currency_id : "",
      name: branchDetails ? branchDetails.name : "",
      country: branchDetails ? branchDetails.country : "",
      city: branchDetails ? branchDetails.city : "",
      address: branchDetails ? branchDetails.address : "",
      note: branchDetails ? branchDetails.note : "",
    },
    onSubmit: async (values: any) => {
      if (values.note.trim() === "") {
        values.note = null;
      }
      try {
        await api.put(`/branches/${branchId}`, values);
        toast.success("Successfully edited a branch");
        setTimeout(() => {
          navigate(-1);
        }, 1000);
      } catch (error) {
        toast.error("Something went wrong");
      }
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Branch name is a required field."),
      country: Yup.string().required("Country is a required field."),
      city: Yup.string().required("City is a required field."),
      address: Yup.string().required("Address is a required field."),
    }),
  });

  const handleCountrySelect = (value: any) => {
    formik.setFieldValue("country", value);
  };

  const getInitialData = async () => {
    await api
      .get("/profile/balances")
      .then((response: any) => {
        setCurrencies(response.data);
        setCurrency(response.data && response.data[0]);
      })
      .catch((error) => {
        console.error(error.message);
      });
  };

  const handleCurrencyChange = (event: any, newValue: any) => {
    setCurrency(newValue);
    formik.setFieldValue("currency_id", newValue.id);
  };

  useEffect(() => {
    if (branchId) {
      fetchBranchDetails();
    }
  }, [branchId]);

  useEffect(() => {
    getInitialData();
  }, []);

  useEffect(() => {
    if (branchDetails) {
      formik.setFieldValue("currency_id", branchDetails.currency_id);
      formik.setFieldValue("name", branchDetails.name);
      formik.setFieldValue("country", branchDetails.country);
      formik.setFieldValue("city", branchDetails.city);
      formik.setFieldValue("address", branchDetails.address);
      formik.setFieldValue("note", branchDetails.note);
    }
  }, [branchDetails, formik.setFieldValue]);

  return (
    <>
      <div>
        <Nav step={0} isStepperVisible={false} />
        <div className="content-container">
          <ThemeProvider theme={defaultTheme}>
            <Container component="main">
              {loading ? (
                <LoadingIndicator />
              ) : (
                <>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <div className="d-flex ai-center">
                      <p
                        className="back-button-icon circle-around-text-smaller helvetica mr-1-rem cp"
                        onClick={handleNavigate}
                      >
                        <ArrowBackIcon className="muiIcons" />
                      </p>
                      <Typography
                        className="ta-center pageTitleFontSize"
                        component="h1"
                        variant="h5"
                        sx={{
                          fontFamily: "Helvetica, sans-serif",
                          fontWeight: "600",
                          color: "#0e0f0c",
                          maxWidth: "500px",
                          whiteSpace: "nowrap",
                          textOverflow: "ellipsis",
                          overflow: "hidden",
                        }}
                      >
                        Edit branch details
                      </Typography>
                    </div>
                  </Box>
                  <form onSubmit={formik.handleSubmit}>
                    <Box sx={{ mt: "4rem" }}>
                      <div className="label-and-field mb-1rem">
                        <Box>
                          <Typography className="selectCurrencyLabel registration0875RemFontSize">
                            Select currency
                          </Typography>
                        </Box>
                        <Autocomplete
                          id="currency-select"
                          sx={{
                            width: "100%",
                            borderRadius: "10px",
                          }}
                          options={currencies}
                          value={currency}
                          getOptionLabel={(option) => option.currency}
                          onChange={handleCurrencyChange}
                          renderOption={(props, option) => (
                            <Box
                              component="li"
                              {...props}
                              className="d-flex ai-center mt-1-rem cp"
                            >
                              <p
                                className="normal-text"
                                style={{ marginLeft: 10 }}
                              >
                                {option.currency}
                              </p>
                            </Box>
                          )}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              InputProps={{
                                ...params.InputProps,
                                sx: {
                                  borderRadius: "10px",
                                  height: "48px",
                                  fontSize: "16px",
                                  "&:-internal-autofill-selected": {
                                    backgroundColor: "transparent !important",
                                  },
                                  "@media (max-width:600px)": {
                                    fontSize: "14px !important",
                                  },
                                },
                              }}
                            />
                          )}
                          clearIcon={null}
                        />
                      </div>

                      <div className="label-and-field mb-1rem">
                        <Typography
                          className="d-flex jc-start registration0875RemFontSize"
                          component="h1"
                          variant="h5"
                          sx={{
                            fontFamily: "Helvetica, sans-serif",
                            fontWeight: "300",
                            color: "#454745",
                            marginBottom: "-8px",
                          }}
                        >
                          Branch name
                        </Typography>

                        <TextField
                          margin="normal"
                          fullWidth
                          placeholder="Name"
                          name="name"
                          autoFocus
                          value={formik.values.name}
                          onChange={formik.handleChange}
                          InputProps={{
                            sx: {
                              borderRadius: "10px",
                              height: "48px",
                              fontSize: "16px",
                              "&:-internal-autofill-selected": {
                                backgroundColor: "transparent !important",
                              },
                              "@media (max-width:600px)": {
                                fontSize: "14px !important",
                              },
                            },
                          }}
                        />
                        {formik.touched.name && formik.errors.name && (
                          <Box
                            className="form-error-message"
                            style={{ fontFamily: "Helvetica, sans-serif" }}
                          >
                            {formik.errors.name.toString()}
                          </Box>
                        )}
                      </div>

                      <div className="label-and-field mb-1rem">
                        <CountrySelect
                          value={formik.values.country}
                          isTouched={formik.touched.country}
                          errors={formik.errors.country}
                          onSelect={handleCountrySelect}
                          showTitle={true}
                          showLabel={false}
                        />
                      </div>

                      <div className="label-and-field mb-1rem">
                        <Typography
                          className="d-flex jc-start registration0875RemFontSize"
                          component="h1"
                          variant="h5"
                          sx={{
                            fontFamily: "Helvetica, sans-serif",
                            fontWeight: "300",
                            color: "#454745",
                            marginBottom: "-8px",
                          }}
                        >
                          City
                        </Typography>

                        <TextField
                          margin="normal"
                          required
                          fullWidth
                          id="city"
                          placeholder="City"
                          name="city"
                          value={formik.values.city}
                          onChange={formik.handleChange}
                          InputProps={{
                            sx: {
                              borderRadius: "10px",
                              height: "48px",
                              fontSize: "16px",
                              "&:-internal-autofill-selected": {
                                backgroundColor: "transparent !important",
                              },
                              "@media (max-width:600px)": {
                                fontSize: "14px !important",
                              },
                            },
                          }}
                        />
                        {formik.touched.city && formik.errors.city && (
                          <Box
                            className="form-error-message"
                            style={{ fontFamily: "Helvetica, sans-serif" }}
                          >
                            {formik.errors.city.toString()}
                          </Box>
                        )}
                      </div>

                      <div className="label-and-field mb-1rem">
                        <Typography
                          className="d-flex jc-start registration0875RemFontSize"
                          component="h1"
                          variant="h5"
                          sx={{
                            fontFamily: "Helvetica, sans-serif",
                            fontWeight: "300",
                            color: "#454745",
                            marginBottom: "-8px",
                          }}
                        >
                          Address
                        </Typography>

                        <TextField
                          margin="normal"
                          required
                          fullWidth
                          id="address"
                          placeholder="address"
                          name="address"
                          value={formik.values.address}
                          onChange={formik.handleChange}
                          InputProps={{
                            sx: {
                              borderRadius: "10px",
                              height: "48px",
                              fontSize: "16px",
                              "&:-internal-autofill-selected": {
                                backgroundColor: "transparent !important",
                              },
                              "@media (max-width:600px)": {
                                fontSize: "14px !important",
                              },
                            },
                          }}
                        />
                        {formik.touched.address && formik.errors.address && (
                          <Box
                            className="form-error-message"
                            style={{ fontFamily: "Helvetica, sans-serif" }}
                          >
                            {formik.errors.address.toString()}
                          </Box>
                        )}
                      </div>
                      <div className="label-and-field mb-1rem">
                        <Typography
                          className="d-flex jc-start registration0875RemFontSize"
                          component="h1"
                          variant="h5"
                          sx={{
                            fontFamily: "Helvetica, sans-serif",
                            fontWeight: "300",
                            color: "#454745",
                            marginBottom: "-8px",
                          }}
                        >
                          Note
                        </Typography>
                        <TextField
                          InputProps={{
                            rows: 4,
                            multiline: true,
                            inputComponent: "textarea",
                            style: {
                              borderRadius: "10px",
                              fontFamily: "Helvetica, sans-serif",
                              width: "100%",
                              resize: "none",
                              paddingTop: "1rem",
                              paddingLeft: "1rem",
                            },
                            sx: {
                              fontSize: "16px",
                              "&:-internal-autofill-selected": {
                                backgroundColor: "transparent !important",
                              },
                              "@media (max-width:600px)": {
                                fontSize: "14px !important",
                              },
                            },
                          }}
                          name="note"
                          id="note"
                          value={formik.values.note}
                          onChange={formik.handleChange}
                          aria-label="maximum height"
                          placeholder="Write a note..."
                          style={{
                            fontFamily: "Helvetica, sans-serif",
                            width: "100%",
                            resize: "none",
                            paddingTop: "1rem",
                            borderRadius: "10px",
                          }}
                        />
                      </div>
                    </Box>
                    <Button
                      fullWidth
                      variant="contained"
                      type="submit"
                      className="oneRemFontSize btnsLoginHeight"
                      sx={{
                        color: "white",
                        backgroundColor: "#2A5182",
                        borderRadius: "24px",
                        textTransform: "none",
                        marginTop: "2rem",
                        fontFamily: "Helvetica, sans-serif",
                        marginBottom: "4rem",
                      }}
                    >
                      Save
                    </Button>
                  </form>
                </>
              )}
            </Container>
          </ThemeProvider>
        </div>
      </div>
    </>
  );
}
