import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useAppSelector } from "src/store";
import api from "src/store/interceptors/api";
import Nav from "src/components/layout/nav/Nav";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Box, Typography } from "@mui/material";
import Container from "@mui/material/Container";
import i18next from "i18next";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useFormik } from "formik";
import EditOnBoardingForm from "./components/EditOnBoardingForm";
import { toast } from "react-toastify";

const defaultTheme = createTheme();
function EditOnBoardingDetails() {
  const location = useLocation();
  const navigate = useNavigate();
  const [userState, setUserState] = useState<any>(null);

  const { user } = useAppSelector((state) => state.auth);
  const [initialFormikValues, setInitialFormikValues] = useState({
    iban_needs: "",
    pay_in_currency: [],
    volume_of_money_in: "",
    pay_out_currency: [],
    volume_of_money_out: "",
    verification_country_code: "",
    bussiness_activities: "",
    company_in_bussiness: "",
    holding_activities_1: "",
    holding_activities_2: "",
    trading_activities_1: "",
    trading_activities_2: "",
    prospect_aspect_1: "",
    prospect_aspect_2: "",
    it_marketing_1: "",
    it_marketing_2: "",
    it_marketing_3: "",
    intermediary_service_1: "",
    intermediary_service_2: "",
    consulting_1: "",
    consulting_2: "",
    investment_1: "",
    investment_2: "",
    investment_3: "",
    investment_4: "",
  });

  const getUserProfile = async () => {
    try {
      const response = await api.get(`me`);
      if (response && response.data && response.data.data) {
        if (response && response.data && response.data.data) {
          const userData = {...response.data.data, consulting_1: "test"};
          setUserState(userData);
          fillTheForm(userData);
        }
      }
      if (
        response &&
        response.data &&
        response.data.data &&
        response.data.data.role === "Employee"
      ) {
        navigate("/admin");
      }
    } catch (exception) {}
  };

  const handleNavigate = () => {
    if (location.state && location.state.user) {
      // Extracting userId from the URL
      navigate(-1);
    } else {
      navigate("/profile");
    }
  };

  const fillTheForm = (userData: any) => {
    let pay_in_currency: any = [];
    let pay_out_currency: any = [];
    if (typeof userData.pay_in_currency === "string") {
      pay_in_currency = userData.pay_in_currency
        .split(",")
        .map((item: any) => item.trim());
    }
    if (typeof userData.pay_out_currency === "string") {
      pay_out_currency = userData.pay_out_currency
        .split(",")
        .map((item: any) => item.trim());
    }
    // Update initial form values based on the fetched user data
    setInitialFormikValues((prevValues) => ({
      ...prevValues,
      iban_needs: userData.iban_needs || "",
      pay_in_currency,
      volume_of_money_in: userData.volume_of_money_in || "",
      pay_out_currency,
      volume_of_money_out: userData.volume_of_money_out || "",
      verification_country_code: userData.verification_country_code || "",
      bussiness_activities: userData.bussiness_activities || "",
      company_in_bussiness: userData.company_in_bussiness || "",
      holding_activities_1: userData.holding_activities_1 || "",
      holding_activities_2: userData.holding_activities_2 || "",
      trading_activities_1: userData.trading_activities_1 || "",
      trading_activities_2: userData.trading_activities_2 || "",
      prospect_aspect_1: userData.prospect_aspect_1 || "",
      prospect_aspect_2: userData.prospect_aspect_2 || "",
      it_marketing_1: userData.it_marketing_1 || "",
      it_marketing_2: userData.it_marketing_2 || "",
      it_marketing_3: userData.it_marketing_3 || "",
      intermediary_service_1: userData.intermediary_service_1 || "",
      intermediary_service_2: userData.intermediary_service_2 || "",
      consulting_1: userData.consulting_1 || "",
      consulting_2: userData.consulting_2 || "",
      investment_1: userData.investment_1 || "",
      investment_2: userData.investment_2 || "",
      investment_3: userData.investment_3 || "",
      investment_4: userData.investment_4 || "",
    }));
  };

  useEffect(() => {
    if (location.state && location.state.user) {
      setUserState(location.state.user);
      fillTheForm(location.state.user);
    } else {
      getUserProfile();
    }
  }, [location.state]);

  const formik = useFormik({
    initialValues: initialFormikValues,
    enableReinitialize: true,
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: async (values: any) => {

        const {...excludedData } = values;

        if (excludedData.pay_in_currency) {
          excludedData.pay_in_currency =
            excludedData.pay_in_currency.join(", ");
        }

        if (excludedData.pay_out_currency) {
          excludedData.pay_out_currency =
            excludedData.pay_out_currency.join(", ");
        }

        const finalData: any = Object.fromEntries(
          Object.entries(excludedData).filter(
            ([key, value]) => value !== null && value !== undefined
          )
        );

        if (user && user.id) {
          finalData.id = user.id;

          const formData = new FormData();

          for (const finalDataKey in finalData) {
            if (finalData.hasOwnProperty(finalDataKey)) {
              //console.log('finaldata', finalData, 'finalDataKey',finalDataKey);
              formData.append(finalDataKey, finalData[finalDataKey]);
            }
          }

          //  console.log(values, 'values');
          //console.log(finalData, 'finalData');
          // ;;return;
          formData.append("verification_type", "onboarding");
          formData.append("_method", "PATCH");

          try {
           await api.post(`/users/${userState.id}`, formData, {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            }).then((res: any) => {
              toast.success(i18next.t("Successfully updated personal details"));
              setTimeout(() => {
                navigate(-1);
              }, 1000);
            })
            .catch((error: any) => {
              toast.error(i18next.t("Something went wrong"));
              console.log(error);
            });
            // console.log(response);


          } catch (error) {
            console.error(`Error uploading: `, error);
          }
        } else {
          alert("Please try again!");
          return;
        }
        // console.log(finalData);
      
    },
  });

  return (
    <>
      <div>
        <Nav step={0} isStepperVisible={false} />
        <div className="content-container">
          <ThemeProvider theme={defaultTheme}>
            <Container component="main">
              <Box
                component="form"
                onSubmit={formik.handleSubmit}
                noValidate
                sx={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <div className="d-flex ai-center mb-1-rem">
                  <p
                    className="back-button-icon circle-around-text-smaller helvetica mr-1-rem cp"
                    onClick={handleNavigate}
                  >
                    <ArrowBackIcon className="muiIcons" />
                  </p>
                  <Typography
                    className="ta-center pageTitleFontSize"
                    component="h1"
                    variant="h5"
                    sx={{
                      fontFamily: "Helvetica, sans-serif",
                      fontWeight: "600",
                      color: "#0e0f0c",
                    }}
                  >
                    {i18next.t("Edit")} {i18next.t("Business Account")}
                  </Typography>
                </div>
              </Box>

              <EditOnBoardingForm formik={formik} initialsField={initialFormikValues} />
            </Container>
          </ThemeProvider>
        </div>
      </div>
    </>
  );
}

export default EditOnBoardingDetails;
