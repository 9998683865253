import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { useEffect, useState } from "react";
import LoadingIndicator from "src/components/shared/LoadingIndicator";
import { Box, Divider, Pagination, Typography } from "@mui/material";
import Nav from "src/components/layout/nav/Nav";
import api from "src/store/interceptors/api";
import BranchCardItem from "src/components/Branches/BranchCardItem";
import { useNavigate } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import "./Branches.scss";

const defaultTheme = createTheme();

export default function BranchesList() {
  interface Branch {
    id: number;
    name: string;
    country: string;
    currency: {
      id: number;
      code: string;
      name: string;
      symbol: string;
    };
    bank_details: [
      {
        id: number;
        type: string;
        branch_id: number;
        created_at: string;
        updated_at: string;
        bank_detail_meta: [
          {
            id: number;
            key: string;
            value: string;
            order: number;
            bank_detail_id: number;
            created_at: string;
            updated_at: string;
          }
        ];
      }
    ];
  }
  const [data, setData] = useState<Branch[] | undefined>();
  const [page, setPage] = useState(1);
  const [perPage] = useState(10);
  const [totalPages, setTotalPages] = useState(1);
  const [total, setTotal] = useState(null);
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const handleGetBranches = async () => {
    setLoading(true);
    let urlBranches = null;
    urlBranches = `/branches?page=${page}&per_page=${perPage}`;
    const response = await api.get(urlBranches);
    const res = response.data;
    const { data, meta } = res;
    if (data) {
      setData(data);
    }
    if (meta) {
      setTotalPages(meta?.last_page || 1);
      setTotal(meta?.total || 0);
    }
    setLoading(false);
  };

  const handleNavigate = () => {
    navigate("/admin/add-branch");
  };
  const handleNavigateBack = () => {
    navigate(-1);
  };
  useEffect(() => {
    handleGetBranches();
  }, [page]);

  return (
    <div>
      <Nav step={0} isStepperVisible={false} isProfileSetVisible={true} />
      <div className="main-admin-container">
        <div className="content-container-profile main-color">
          <ThemeProvider theme={defaultTheme}>
            <Container component="main">
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <div className="d-flex ai-center">
                  <p
                    className="back-button-icon circle-around-text-smaller helvetica mr-1-rem cp"
                    onClick={handleNavigateBack}
                  >
                    <ArrowBackIcon className="muiIcons"/>
                  </p>
                </div>
                <Typography
                  className="your-details-text d-flex jc-start pageTitleFontSize"
                  component="h1"
                  variant="h5"
                  sx={{
                    fontFamily: "Helvetica, sans-serif",
                    fontWeight: "600",
                    textAlign: "start",
                  }}
                >
                  Branches
                </Typography>
              </Box>

              {loading ? (
                <LoadingIndicator />
              ) : (
                <Box sx={{ marginTop: "50px" }}>
                  <div className="d-flex ai-center gap-24">
                    <Typography className="recentText">
                      List of branches
                    </Typography>

                    <Typography
                      onClick={handleNavigate}
                      className="recentText ml-8 cp"
                      style={{ color: "#ff0000 !important" }}
                    >
                      <p style={{ color: "#ff0000 !important" }}>
                        Add a new branch
                      </p>
                    </Typography>
                  </div>
                  <Divider style={{ marginBottom: 8 }} />
                  {data && data.length > 0 ? (
                    <Box className="allRecipientMain">
                      {data?.map((item: any, index: number) => (
                        <BranchCardItem
                          key={"usercard" + index}
                          data={item}
                          index={index}
                        />
                      ))}
                    </Box>
                  ) : (
                    <Box sx={{ marginTop: "50px" }}>
                      <Typography className="noDataText">
                        No Branches found
                      </Typography>
                    </Box>
                  )}
                  {total && total > perPage ? (
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        marginTop: "2rem",
                      }}
                    >
                      <Pagination
                        count={totalPages}
                        page={page}
                        onChange={(event, value) => setPage(value)}
                        color="primary"
                      />
                    </Box>
                  ) : null}
                </Box>
              )}
            </Container>
          </ThemeProvider>
        </div>
      </div>
    </div>
  );
}
