import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { Box } from "@mui/material";
import ErrorMessage from "./CustomInput/ErrorMessage";

interface Props {
  label: string;
  value: any;
  onChange: (label: any, value: any) => void;
  isTouched: any;
  errors: any;
  type?: string;
  required: boolean;
  name: string;
}
function DatePickerInputFiled({
  label,
  value,
  onChange,
  isTouched,
  errors,
  required,
  name,
}: Props) {
  return (
    <Box className="customInputContainer">
      <Box
        className={`customInputSubContainer  ${
          errors ? "customInputSubContainerError" : "customInputSubContainer"
        }`}
        sx={{ height: "55px" }}
      >
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            className="w-100 form-input-filed"
            value={value}
            onChange={(value: any) => onChange(name, value)}
            slotProps={{
              textField: {
                placeholder: label,
                // helperText: isTouched && errors ? errors.toString() : "",
                required: required,
                error: isTouched && errors !== null && errors !== undefined,
                InputProps: {
                  sx: {
                    "input:-webkit-autofill, input:-webkit-autofill:hover, input:-webkit-autofill:focus, input:-webkit-autofill:active":
                      {
                        borderRadius: "16px !important",
                        "-webkit-text-fill-color": "inherit",
                        "-webkit-box-shadow": errors
                          ? "0 0 0px 1000px #feedec inset !important"
                          : "0 0 0px 1000px #e0e3e7 inset !important",
                        backgroundColor: "#e0e3e7 !important",
                      },
                  },
                  classes: {
                    notchedOutline: "customInputNoBorder",
                    input: "customInputpadding",
                  },
                },
                InputLabelProps: {
                  classes: {
                    root: errors ? "customInputLabelError" : "customInputLabel",
                  },
                },
              },
            }}
          />
        </LocalizationProvider>
      </Box>
      {isTouched && errors && (
        <ErrorMessage style={{ marginLeft: 16 }} message={errors.toString()} />
      )}
    </Box>
  );
}

export default DatePickerInputFiled;
