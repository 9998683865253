import React, { useState } from 'react';

const useModalState = (initialType = null) => {
  const [open, setOpen] = useState(false);
  const [modalType, setModalType] = useState(initialType);

  const handleOpen = (type = null) => {
    setModalType(type);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setModalType(null); // Reset the modal type upon closing
  };

  return { open, modalType, handleOpen, handleClose };
};

export default useModalState;
