import { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import "./EditBusiness.scss";
import { useNavigate, useLocation } from "react-router-dom";
import Nav from "src/components/layout/nav/Nav";
import { useAppSelector } from "src/store";
import { MenuItem, Select } from "@mui/material";
import CountrySelect from "src/components/shared/CountrySelect/CountrySelect";
import { useFormik } from "formik";
import * as Yup from "yup";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import api from "src/store/interceptors/api";
import { toast } from "react-toastify";
import i18next from "i18next";

// Improved email validation using regular expression

const defaultTheme = createTheme();

export default function EditBusiness() {
  const location = useLocation();

  const navigate = useNavigate();
  const [completeUser, setCompleteUser] = useState<any>(null);
  const [userState, setUserState] = useState<any>(null);
  const [isUserNewBusiness, setIsUserNewBusiness] = useState<boolean>(false);
  const { user } = useAppSelector((state) => state.auth);

  const getUserProfile = async () => {
    try {
      const response = await api.get(`me`);
      if (response && response.data && response.data.data) {
        setUserState(response.data.data?.companies[0]);
        if (response.data.data?.companies.length === 0) {
          setIsUserNewBusiness(true);
        }
        setCompleteUser(response.data.data);
      }
    } catch (exception) {}
  };

  useEffect(() => {
    // Check if user data is passed through navigation state
    if (location?.state && location?.state?.user) {
      // console.log("upali smo, skonto je da ima user");
      setUserState(location.state.user.companies[0]);
      if (location.state.user.companies.length === 0) {
        setIsUserNewBusiness(true);
      }
      setCompleteUser(location.state.user);
    } else {
      // If no user data is passed, call the API to fetch user profile
      // "nismo upali, skonto je da nema usera;
      getUserProfile();
    }
  }, [location.state]);

  const formatBirthday = (
    dobDay: string,
    dobMonth: string,
    dobYear: string
  ) => {
    if (!dobDay || !dobMonth || !dobYear) {
      return null;
    }
    const paddedDay = dobDay.padStart(2, "0");
    const paddedMonth = dobMonth.padStart(2, "0");
    const birthday = `${dobYear}-${paddedMonth}-${paddedDay}`;
    return birthday;
  };

  const handleNavigate = () => {
    if (location?.state && location?.state?.user) {
      // Extracting userId from the URL
      navigate(-1);
    } else {
      navigate("/profile");
    }
  };

  const convertDateToISOFormat = (dateString: any) => {
    const months: any = {
      Jan: "01",
      Feb: "02",
      Mar: "03",
      Apr: "04",
      May: "05",
      Jun: "06",
      Jul: "07",
      Aug: "08",
      Sep: "09",
      Oct: "10",
      Nov: "11",
      Dec: "12",
    };

    const parts = dateString.split("-");

    if (parts.length === 3) {
      const year = parts[0];
      const month = months[parts[1]];
      const day = parts[2].padStart(2, "0");

      if (!month) {
        return null; // or throw an error
      }

      return `${year}-${month}-${day}`;
    } else {
      return null; // or throw an error
    }
  };

  const formik = useFormik({
    initialValues: {
      legalName: "",
      registrationNumber: "",
      dobDay: "",
      dobMonth: "",
      dobYear: "",
      phoneNumber: "",
      country: "",
      homeAddress: "",
      city: "",
      postalCode: "",
      webAdress: "",
      numberOfEmployees: "",
    },
    onSubmit: async (values: any) => {
      const formattedBirthday = formatBirthday(
        values.dobDay.toString(),
        values.dobMonth.toString(),
        values.dobYear.toString()
      );
      const convertedDate = convertDateToISOFormat(formattedBirthday);

      let valuesForBe: any = {};
      valuesForBe.name = values.legalName.toString();
      valuesForBe.registration_number = values.registrationNumber.toString();
      valuesForBe.incorporation_date = convertedDate;
      valuesForBe.phone = values.phoneNumber.toString();
      valuesForBe.country = values.country.toString();
      valuesForBe.address = values.homeAddress.toString();
      valuesForBe.city = values.city.toString();
      valuesForBe.postal_code = values.postalCode.toString();
      valuesForBe.id = userState?.id ?? null;
      valuesForBe.user_id = completeUser?.id ?? null;
      valuesForBe.website = values.webAdress.toString();
      valuesForBe.number_of_employees = values.numberOfEmployees.toString();

      if (isUserNewBusiness) {
        await api
          .post("/companies", valuesForBe)
          .then((res: any) => {
            toast.success(i18next.t("Successfully added business details"));
            setTimeout(() => {
              navigate(-2);
            }, 1000);
          })
          .catch((error: any) => {
            toast.error(i18next.t("Something went wrong"));
            console.log(error);
          });
      } else {
        await api
          .put("/companies/" + userState?.id, valuesForBe)
          .then((res: any) => {
            toast.success(i18next.t("Successfully updated business details"));
            setTimeout(() => {
              navigate(-1);
            }, 1000);
          })
          .catch((error: any) => {
            toast.error(i18next.t("Something went wrong"));
            console.log(error);
          });
      }
    },
    validationSchema: Yup.object({
      legalName: Yup.string().required(
        i18next.t("Legal name is a required field")
      ),
      registrationNumber: Yup.string().required(
        i18next.t("Registration number is a required field")
      ),
      dobDay: Yup.string().required(
        i18next.t("Incorporation day required field")
      ),
      dobYear: Yup.string().required(
        i18next.t("Incorporation year is a required field")
      ),
      phoneNumber: Yup.string().required(
        i18next.t("Phone number is a required field")
      ),
      country: Yup.string().required(i18next.t("Country is a required field")),
      homeAddress: Yup.string().required(
        i18next.t("Business address is a required field")
      ),
      city: Yup.string().required(i18next.t("City is a required field")),
      postalCode: Yup.string().required(
        i18next.t("Postal Code is a required field")
      ),
      webAdress: Yup.string().required(
        i18next.t("Web adress is a required field")
      ),
      numberOfEmployees: Yup.string().required(
        i18next.t("Number of employees is a required field")
      ),
    }),
    validate: (values: any) => {
      const errors: any = {};
      Object.keys(values).forEach((key) => {
        if (!values[key]) {
          errors[key] = i18next.t("This field is required");
        }
      });
      return errors;
    },
  });

  useEffect(() => {
    if (userState) {
      const monthNames = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ];

      const birthday = new Date(userState.incorporation_date);
      const day = birthday.getDate();
      const monthString = monthNames[birthday.getMonth()];
      const year = birthday.getFullYear();

      formik.setFieldValue("legalName", userState.name);
      formik.setFieldValue("registrationNumber", userState.registration_number);
      formik.setFieldValue("dobDay", day.toString());
      formik.setFieldValue("dobMonth", monthString.toString());
      formik.setFieldValue("dobYear", year.toString());
      formik.setFieldValue("phoneNumber", userState.phone);
      formik.setFieldValue("country", userState.country ?? "");
      formik.setFieldValue("homeAddress", userState.address);
      formik.setFieldValue("city", userState.city);
      formik.setFieldValue("postalCode", userState.postal_code);
      formik.setFieldValue("webAdress", userState.website);
      formik.setFieldValue("numberOfEmployees", userState.number_of_employees);
    }
  }, [userState, formik.setFieldValue]);

  const handleCountrySelect = (value: any) => {
    formik.setFieldValue("country", value);
  };

  const canChangeData =
    user?.role === "Admin" ||
    user?.role === "Employee" ||
    (completeUser &&
      completeUser.companies &&
      completeUser.companies.length === 0);
  return (
    <>
      {
        <div>
          <Nav step={0} isStepperVisible={false} />
          <div className="content-container">
            <ThemeProvider theme={defaultTheme}>
              <Container component="main">
                <Box
                  component="form"
                  onSubmit={formik.handleSubmit}
                  noValidate
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <div className="d-flex ai-center mb-1-rem">
                    <p
                      className="back-button-icon circle-around-text-smaller helvetica mr-1-rem cp"
                      onClick={handleNavigate}
                    >
                      <ArrowBackIcon className="muiIcons" />
                    </p>
                    <Typography
                      className="ta-center pageTitleFontSize"
                      component="h1"
                      variant="h5"
                      sx={{
                        fontFamily: "Helvetica, sans-serif",
                        fontWeight: "600",
                        color: "#0e0f0c",
                      }}
                    >
                      {i18next.t("Edit business details")}
                    </Typography>
                  </div>

                  <Typography
                    component="h5"
                    variant="h5"
                    sx={{
                      fontFamily: "Helvetica, sans-serif",
                      fontSize: "0.875rem",
                      fontWeight: "200",
                      color: "#454745",
                    }}
                  >
                    {i18next.t(
                      "After you’ve made a transfer or been verified, you can’t edit some of the fields"
                    )}
                  </Typography>

                  <Box sx={{ mt: "4rem" }}>
                    <div className="label-and-field mb-1rem">
                      <Typography
                        className="d-flex jc-start"
                        component="h1"
                        variant="h5"
                        sx={{
                          fontFamily: "Helvetica, sans-serif",
                          fontSize: "0.875rem",
                          fontWeight: "200",
                          color: "#454745",
                          marginBottom: "-8px",
                        }}
                      >
                        {i18next.t("Legal name")}
                      </Typography>

                      <TextField
                        margin="normal"
                        fullWidth
                        placeholder={i18next.t("Full business name")}
                        name="legalName"
                        autoFocus
                        value={formik.values.legalName}
                        onChange={formik.handleChange}
                        disabled={!canChangeData ? true : false}
                        InputProps={{
                          sx: {
                            borderRadius: "10px",
                            height: "3rem",
                            "&:-internal-autofill-selected": {
                              backgroundColor: "transparent !important",
                              fontSize: 10,
                            },
                          },
                        }}
                      />
                      {formik.touched.legalName && formik.errors.legalName && (
                        <Box
                          className="form-error-message"
                          style={{ fontFamily: "Helvetica, sans-serif" }}
                        >
                          {formik.errors.legalName.toString()}
                        </Box>
                      )}
                    </div>

                    <div className="label-and-field mb-1rem">
                      <Typography
                        className="d-flex jc-start"
                        component="h1"
                        variant="h5"
                        sx={{
                          fontFamily: "Helvetica, sans-serif",
                          fontSize: "0.875rem",
                          fontWeight: "200",
                          color: "#454745",
                          marginBottom: "-8px",
                        }}
                      >
                        {i18next.t("Registration number")}
                      </Typography>

                      <TextField
                        margin="normal"
                        required
                        fullWidth
                        id="registrationNumber"
                        placeholder={i18next.t("Enter registration number")}
                        name="registrationNumber"
                        value={formik.values.registrationNumber}
                        onChange={formik.handleChange}
                        disabled={!canChangeData ? true : false}
                        InputProps={{
                          sx: {
                            borderRadius: "10px",
                            height: "3rem",
                            "&:-internal-autofill-selected": {
                              backgroundColor: "transparent !important",
                              fontSize: 10,
                            },
                          },
                        }}
                      />
                      {formik.touched.registrationNumber &&
                        formik.errors.registrationNumber && (
                          <Box
                            className="form-error-message"
                            style={{ fontFamily: "Helvetica, sans-serif" }}
                          >
                            {formik.errors.registrationNumber.toString()}
                          </Box>
                        )}
                    </div>

                    <div className="label-and-field mb-1rem">
                      <Typography
                        className="d-flex jc-start"
                        component="h1"
                        variant="h5"
                        sx={{
                          fontFamily: "Helvetica, sans-serif",
                          fontSize: "0.875rem",
                          fontWeight: "200",
                          color: "#454745",
                          marginBottom: "-8px",
                        }}
                      >
                        {i18next.t("Date of incorporation")}
                      </Typography>
                      <div className="d-flex jusifyContentSpaceBetween">
                        <TextField
                          margin="normal"
                          required
                          id="dobDay"
                          type="number"
                          placeholder={i18next.t("DD")}
                          name="dobDay"
                          disabled={!canChangeData ? true : false}
                          value={formik.values.dobDay}
                          onChange={formik.handleChange}
                          InputProps={{
                            sx: {
                              borderRadius: "10px",
                              width: "6.4rem",
                              height: "3rem",
                              "&:-internal-autofill-selected": {
                                backgroundColor: "transparent !important",
                                fontSize: 10,
                              },
                            },
                          }}
                        />
                        <Select
                          id="dobMonth"
                          name="dobMonth"
                          value={formik.values.dobMonth}
                          onChange={formik.handleChange}
                          defaultValue={formik.values.dobMonth}
                          disabled={!canChangeData ? true : false}
                          sx={{
                            width: "9rem",
                            height: "3rem",
                            borderRadius: "10px",
                            marginTop: "17px",
                          }}
                        >
                          <MenuItem value={"Jan"}>{i18next.t("Jan")}</MenuItem>
                          <MenuItem value={"Feb"}>{i18next.t("Feb")}</MenuItem>
                          <MenuItem value={"Mar"}>{i18next.t("Mar")}</MenuItem>
                          <MenuItem value={"Apr"}>{i18next.t("Apr")}</MenuItem>
                          <MenuItem value={"May"}>{i18next.t("May")}</MenuItem>
                          <MenuItem value={"Jun"}>{i18next.t("Jun")}</MenuItem>
                          <MenuItem value={"Jul"}>{i18next.t("Jul")}</MenuItem>
                          <MenuItem value={"Aug"}>{i18next.t("Aug")}</MenuItem>
                          <MenuItem value={"Sep"}>{i18next.t("Sep")}</MenuItem>
                          <MenuItem value={"Oct"}>{i18next.t("Oct")}</MenuItem>
                          <MenuItem value={"Nov"}>{i18next.t("Nov")}</MenuItem>
                          <MenuItem value={"Dec"}>{i18next.t("Dec")}</MenuItem>
                        </Select>
                        <TextField
                          margin="normal"
                          required
                          type="number"
                          id="dobYear"
                          placeholder={i18next.t("YYYY")}
                          name="dobYear"
                          value={formik.values.dobYear}
                          onChange={formik.handleChange}
                          disabled={!canChangeData ? true : false}
                          InputProps={{
                            sx: {
                              borderRadius: "10px",
                              height: "3rem",
                              "&:-internal-autofill-selected": {
                                backgroundColor: "transparent !important",
                                fontSize: 10,
                              },
                            },
                          }}
                        />
                      </div>
                      {formik.touched.dobDay && formik.errors.dobDay && (
                        <Box
                          className="form-error-message"
                          style={{ fontFamily: "Helvetica, sans-serif" }}
                        >
                          {formik.errors.dobDay.toString()}
                        </Box>
                      )}

                      {formik.touched.dobMonth && formik.errors.dobMonth && (
                        <Box
                          className="form-error-message"
                          style={{ fontFamily: "Helvetica, sans-serif" }}
                        >
                          {formik.errors.dobMonth.toString()}
                        </Box>
                      )}

                      {formik.touched.dobYear && formik.errors.dobYear && (
                        <Box
                          className="form-error-message"
                          style={{ fontFamily: "Helvetica, sans-serif" }}
                        >
                          {formik.errors.dobYear.toString()}
                        </Box>
                      )}
                    </div>

                    <div className="label-and-field mb-1rem">
                      <Typography
                        className="d-flex jc-start"
                        component="h1"
                        variant="h5"
                        sx={{
                          fontFamily: "Helvetica, sans-serif",
                          fontSize: "0.875rem",
                          fontWeight: "200",
                          color: "#454745",
                          marginBottom: "-8px",
                        }}
                      >
                        {i18next.t("Mobile phone number")}
                      </Typography>

                      <TextField
                        margin="normal"
                        required
                        fullWidth
                        id="phoneNumber"
                        placeholder={i18next.t("Enter phone number")}
                        name="phoneNumber"
                        type="number"
                        value={formik.values.phoneNumber}
                        onChange={formik.handleChange}
                        InputProps={{
                          sx: {
                            borderRadius: "10px",
                            height: "3rem",
                            "&:-internal-autofill-selected": {
                              backgroundColor: "transparent !important",
                              fontSize: 10,
                            },
                          },
                        }}
                      />
                      {formik.touched.phoneNumber &&
                        formik.errors.phoneNumber && (
                          <Box
                            className="form-error-message"
                            style={{ fontFamily: "Helvetica, sans-serif" }}
                          >
                            {formik.errors.phoneNumber.toString()}
                          </Box>
                        )}
                    </div>

                    <div className="label-and-field mb-1rem">
                      <CountrySelect
                        value={formik.values.country}
                        isTouched={formik.touched.country}
                        errors={formik.errors.country}
                        onSelect={handleCountrySelect}
                        disabled={!canChangeData ? true : false}
                      />
                    </div>

                    <div className="label-and-field mb-1rem">
                      <Typography
                        className="d-flex jc-start"
                        component="h1"
                        variant="h5"
                        sx={{
                          fontFamily: "Helvetica, sans-serif",
                          fontSize: "0.875rem",
                          fontWeight: "200",
                          color: "#454745",
                          marginBottom: "-8px",
                        }}
                      >
                        {i18next.t("Business address")}
                      </Typography>

                      <TextField
                        margin="normal"
                        required
                        fullWidth
                        id="homeAddress"
                        placeholder={i18next.t("Street name")}
                        name="homeAddress"
                        value={formik.values.homeAddress}
                        disabled={!canChangeData ? true : false}
                        onChange={formik.handleChange}
                        InputProps={{
                          sx: {
                            borderRadius: "10px",
                            height: "3rem",
                            "&:-internal-autofill-selected": {
                              backgroundColor: "transparent !important",
                              fontSize: 10,
                            },
                          },
                        }}
                      />
                      {formik.touched.homeAddress &&
                        formik.errors.homeAddress && (
                          <Box
                            className="form-error-message"
                            style={{ fontFamily: "Helvetica, sans-serif" }}
                          >
                            {formik.errors.homeAddress.toString()}
                          </Box>
                        )}
                    </div>

                    <div className="label-and-field mb-1rem">
                      <Typography
                        className="d-flex jc-start"
                        component="h1"
                        variant="h5"
                        sx={{
                          fontFamily: "Helvetica, sans-serif",
                          fontSize: "0.875rem",
                          fontWeight: "200",
                          color: "#454745",
                          marginBottom: "-8px",
                        }}
                      >
                        {i18next.t("City")}
                      </Typography>

                      <TextField
                        margin="normal"
                        required
                        fullWidth
                        id="city"
                        placeholder={i18next.t("City")}
                        name="city"
                        value={formik.values.city}
                        disabled={!canChangeData ? true : false}
                        onChange={formik.handleChange}
                        InputProps={{
                          sx: {
                            borderRadius: "10px",
                            height: "3rem",
                            "&:-internal-autofill-selected": {
                              backgroundColor: "transparent !important",
                              fontSize: 10,
                            },
                          },
                        }}
                      />
                      {formik.touched.city && formik.errors.city && (
                        <Box
                          className="form-error-message"
                          style={{ fontFamily: "Helvetica, sans-serif" }}
                        >
                          {formik.errors.city.toString()}
                        </Box>
                      )}
                    </div>

                    <div className="label-and-field mb-1rem">
                      <Typography
                        className="d-flex jc-start"
                        component="h1"
                        variant="h5"
                        sx={{
                          fontFamily: "Helvetica, sans-serif",
                          fontSize: "0.875rem",
                          fontWeight: "200",
                          color: "#454745",
                          marginBottom: "-8px",
                        }}
                      >
                        {i18next.t("Postal Code")}
                      </Typography>

                      <TextField
                        margin="normal"
                        required
                        fullWidth
                        id="postalCode"
                        placeholder={i18next.t("Postal Code")}
                        name="postalCode"
                        value={formik.values.postalCode}
                        onChange={formik.handleChange}
                        disabled={!canChangeData ? true : false}
                        InputProps={{
                          sx: {
                            borderRadius: "10px",
                            height: "3rem",
                            "&:-internal-autofill-selected": {
                              backgroundColor: "transparent !important",
                              fontSize: 10,
                            },
                          },
                        }}
                      />
                    </div>
                    {formik.touched.postalCode && formik.errors.postalCode && (
                      <Box
                        className="form-error-message"
                        style={{ fontFamily: "Helvetica, sans-serif" }}
                      >
                        {formik.errors.postalCode.toString()}
                      </Box>
                    )}

                    <div className="label-and-field mb-1rem">
                      <Typography
                        className="d-flex jc-start"
                        component="h1"
                        variant="h5"
                        sx={{
                          fontFamily: "Helvetica, sans-serif",
                          fontSize: "0.875rem",
                          fontWeight: "200",
                          color: "#454745",
                          marginBottom: "-8px",
                        }}
                      >
                        {i18next.t("Web")}
                      </Typography>

                      <TextField
                        margin="normal"
                        required
                        fullWidth
                        id="webAdress"
                        placeholder={i18next.t("Web adress")}
                        name="webAdress"
                        value={formik.values.webAdress}
                        disabled={!canChangeData ? true : false}
                        onChange={formik.handleChange}
                        InputProps={{
                          sx: {
                            borderRadius: "10px",
                            height: "3rem",
                            "&:-internal-autofill-selected": {
                              backgroundColor: "transparent !important",
                              fontSize: 10,
                            },
                          },
                        }}
                      />
                    </div>
                    {formik.touched.webAdress && formik.errors.webAdress && (
                      <Box
                        className="form-error-message"
                        style={{ fontFamily: "Helvetica, sans-serif" }}
                      >
                        {formik.errors.webAdress.toString()}
                      </Box>
                    )}

                    <div className="label-and-field">
                      <Typography
                        className="d-flex jc-start"
                        component="h1"
                        variant="h5"
                        sx={{
                          fontFamily: "Helvetica, sans-serif",
                          fontSize: "0.875rem",
                          fontWeight: "200",
                          color: "#454745",
                          marginBottom: "-8px",
                        }}
                      >
                        {i18next.t("Number of employees")}
                      </Typography>

                      <TextField
                        margin="normal"
                        required
                        type="number"
                        fullWidth
                        id="numberOfEmployees"
                        placeholder={i18next.t("Enter number of employees")}
                        name="numberOfEmployees"
                        value={formik.values.numberOfEmployees}
                        disabled={!canChangeData ? true : false}
                        onChange={formik.handleChange}
                        InputProps={{
                          sx: {
                            borderRadius: "10px",
                            height: "3rem",
                            "&:-internal-autofill-selected": {
                              backgroundColor: "transparent !important",
                              fontSize: 10,
                            },
                          },
                        }}
                      />
                    </div>
                    {formik.touched.numberOfEmployees &&
                      formik.errors.numberOfEmployees && (
                        <Box
                          className="form-error-message"
                          style={{ fontFamily: "Helvetica, sans-serif" }}
                        >
                          {formik.errors.numberOfEmployees.toString()}
                        </Box>
                      )}
                  </Box>

                  <Button
                    fullWidth
                    variant="contained"
                    type="submit"
                    sx={{
                      color: "white",
                      backgroundColor: "#2A5182",
                      borderRadius: "24px",
                      height: "3rem",
                      textTransform: "none",
                      marginTop: "2rem",
                      fontFamily: "Helvetica, sans-serif",
                      fontSize: "0.9rem",
                      marginBottom: "4rem",
                    }}
                  >
                    {i18next.t("Save")}
                  </Button>
                </Box>
              </Container>
            </ThemeProvider>
          </div>
        </div>
      }
    </>
  );
}
