import Typography from "@mui/material/Typography";

const BranchDetailsBasic = ({ branchDetails }: any) => {
  return (
    <>
      {branchDetails &&
        branchDetails.bank_detail_meta &&
        branchDetails.bank_details_meta.type && (
          <div className="keyAndValueMain">
            <div>
              <Typography
                className="mb-1rem  d-flex jc-start keys registration0875RemFontSize"
                variant="h5"
              >
                Currency
              </Typography>
            </div>
            <div>
              <Typography
                className="mb-1rem  d-flex jc-start values registration0875RemFontSize"
                variant="h5"
              >
                {" "}
                {branchDetails?.bank_details_meta?.type}
              </Typography>
            </div>
          </div>
        )}
      {branchDetails && branchDetails.currency && (
        <div className="keyAndValueMain">
          <div>
            <Typography className="mb-1rem  d-flex jc-start keys registration0875RemFontSize" variant="h5">
              Currency
            </Typography>
          </div>
          <div>
            <Typography
              className="mb-1rem  d-flex jc-start values registration0875RemFontSize"
              variant="h5"
            >
              {" "}
              {branchDetails?.currency?.symbol} {branchDetails?.currency?.code}
            </Typography>
          </div>
        </div>
      )}
      {branchDetails && branchDetails.name && (
        <div className="keyAndValueMain">
          <div>
            <Typography className="mb-1rem  d-flex jc-start keys registration0875RemFontSize" variant="h5">
              Branch's Name
            </Typography>
          </div>
          <div>
            <Typography
              className="mb-1rem  d-flex jc-start values registration0875RemFontSize"
              variant="h5"
            >
              {" "}
              {branchDetails?.name}
            </Typography>
          </div>
        </div>
      )}
      {branchDetails && branchDetails.country && (
        <div className="keyAndValueMain">
          <div>
            <Typography className="mb-1rem  d-flex jc-start keys registration0875RemFontSize" variant="h5">
              Country
            </Typography>
          </div>
          <div>
            <Typography
              className="mb-1rem  d-flex jc-start values registration0875RemFontSize"
              variant="h5"
            >
              {" "}
              {branchDetails?.country}
            </Typography>
          </div>
        </div>
      )}
      {branchDetails && branchDetails.city && (
        <div className="keyAndValueMain">
          <div>
            <Typography className="mb-1rem  d-flex jc-start keys registration0875RemFontSize" variant="h5">
              City
            </Typography>
          </div>
          <div>
            <Typography
              className="mb-1rem  d-flex jc-start values registration0875RemFontSize"
              variant="h5"
            >
              {" "}
              {branchDetails?.city}
            </Typography>
          </div>
        </div>
      )}
      {branchDetails && branchDetails.address && (
        <div className="keyAndValueMain">
          <div>
            <Typography className="mb-1rem  d-flex jc-start keys registration0875RemFontSize" variant="h5">
              Address
            </Typography>
          </div>
          <div>
            <Typography
              className="mb-1rem  d-flex jc-start values registration0875RemFontSize"
              variant="h5"
            >
              {" "}
              {branchDetails?.address}
            </Typography>
          </div>
        </div>
      )}
      {branchDetails && branchDetails.note && (
        <div className="keyAndValueMain">
          <div>
            <Typography className="mb-1rem  d-flex jc-start keys registration0875RemFontSize" variant="h5">
              Note
            </Typography>
          </div>
          <div>
            <Typography
              className="mb-1rem  d-flex jc-start values registration0875RemFontSize"
              variant="h5"
            >
              {" "}
              {branchDetails?.note}
            </Typography>
          </div>
        </div>
      )}
    </>
  );
};
export default BranchDetailsBasic;
