import { Box, FormControl, MenuItem, Select, Typography } from "@mui/material";
import { ReactNode } from "react";
import "./ChartBox.scss";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import { Area, AreaChart, ResponsiveContainer, Tooltip } from "recharts";
import LoadingCash from "../LoadingCash";
import transactionsService from "src/store/transactions/transactions_service";

interface ChartBoxProps {
  title: string;
  content: string | null;
  onClick?: any;
  bgColor: string;
  color: string;
  chartData?: any;
  iconName?: string;
  cost: string;
  currency?: any;
  data?: any;
  isSelect?: string;
  incomePeriod?: any;
  handleIncomeChange?: any;
}

const ChartBox: React.FC<ChartBoxProps> = ({
  title,
  content,
  onClick,
  bgColor,
  color,
  chartData,
  iconName,
  cost,
  currency,
  data,
  isSelect,
  incomePeriod,
  handleIncomeChange,
}) => {
  const renderIcon = () => {
    switch (iconName) {
      case "up":
        return (
          <ArrowUpwardIcon sx={{ fontSize: 18, color: "rgb(200, 250, 214)" }} />
        );
      case "down":
        return (
          <ArrowDownwardIcon
            sx={{ fontSize: 18, color: "rgb(200, 250, 214)" }}
          />
        );
      case "money":
        return (
          <MonetizationOnIcon
            sx={{ fontSize: 18, color: "rgb(200, 250, 214)" }}
          />
        );
      default:
        return null;
    }
  };

  const CustomTooltip = ({ active, payload }: any) => {
    if (active && payload && payload.length) {
      const data = payload[0].payload;
      return (
        <div
          style={{
            backgroundColor: "#fff",
            padding: "10px",
            color: color,
            border: "1px solid #ccc",
            borderRadius: "5px",
            boxShadow: "0px 0px 5px #aaa",
          }}
        >
          <div style={{ margin: 0 }}>{`${data.name} : ${
            data.amount + getCurrencySymbol(currency)
          }`}</div>
        </div>
      );
    }

    return null;
  };

  const getCurrencySymbol = (currencyCode: string) => {
    const symbols: { [key: string]: string } = {
      USD: "$",
      EUR: "€",
      // ... other currency codes
    };
    return symbols[currencyCode] || currencyCode;
  };

  const val =
    cost && parseFloat(cost) > 0
      ? transactionsService.formatNumber(cost)
      : cost;
  return (
    <Box
      onClick={onClick}
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        bgcolor: bgColor,
        width: "100%",
        height: "100%",
        borderRadius: 4,
        transition: "transform 0.3s ease-in-out",
        "&:hover": {
          transform: "rotateX(5deg) rotateY(10deg)",
        },
      }}
      className="chartBoxMain"
    >
      <div
        className="d-flex ai-start fx-column"
        style={{ padding: "24px" }}
      >
        <Typography
          sx={{
            fontFamily: "Inter, sans-serif",
            fontSize: "16px",
            fontWeight: 600,
            color: { color },
          }}
          variant="subtitle1"
        >
          {title}
        </Typography>
        <div className="d-flex ai-center">
          <div
            className="render-icon-big"
            style={{
              backgroundColor: color,
            }}
          >
            {renderIcon()}
          </div>

          <div>
            {data ? (
              <Typography
                sx={{
                  fontFamily: "Inter, sans-serif",
                  fontWeight: 600,
                  color: { color },
                }}
                className="loginTitleFontSize"
                variant="subtitle1"
              >
                {getCurrencySymbol(currency)}
                {val}
              </Typography>
            ) : (
              <LoadingCash />
            )}
          </div>
        </div>

        {!isSelect ? (
          // <Box
          //   sx={{
          //     width: 48,
          //     height: 48,
          //     borderRadius: "50%",
          //     backgroundColor: color,
          //     display: "flex",
          //     alignItems: "center",
          //     justifyContent: "center",
          //   }}
          // >
          //   {renderIcon()}
          // </Box>
          <></>
        ) : (
          <div
            style={{
              display: "flex",
              alignItems: "start",
              justifyContent: "end",
              // marginRight: "1rem",
            }}
          >
            <div
              style={{
                // width: 165,
                display: "flex",
                alignItems: "end",
                justifyContent: "end",
              }}
            >
              <FormControl fullWidth>
                <Select
                  sx={{
                    height: "30px",
                    borderRadius: "16px",
                    backgroundColor: bgColor,
                    fontSize: "14px",
                    marginBottom: "1rem",
                    color: color,
                    border: "none",
                    "&:focus": {
                      outline: "none",
                      borderRadius: "8px",
                    },
                    "& .MuiOutlinedInput-notchedOutline": {
                      border: "none",
                    },
                    ".MuiSelect-select": {
                      borderRadius: "8px",
                    },
                  }}
                  value={incomePeriod}
                  onChange={handleIncomeChange}
                >
                  <MenuItem
                    sx={{
                      color: color,
                      fontSize: "14px",
                    }}
                    value={"today"}
                  >
                    Today
                  </MenuItem>
                  <MenuItem
                    sx={{
                      color: color,
                      fontSize: "14px",
                    }}
                    value={"last7Days"}
                  >
                    Last 7 Days
                  </MenuItem>
                  <MenuItem
                    sx={{
                      color: color,
                      fontSize: "14px",
                    }}
                    value={"last30Days"}
                  >
                    Last 30 Days
                  </MenuItem>
                  <MenuItem
                    sx={{
                      color: color,
                      fontSize: "14px",
                    }}
                    value={"last12Months"}
                  >
                    Last 12 Months
                  </MenuItem>
                  <MenuItem
                    sx={{
                      color: color,
                      fontSize: "14px",
                    }}
                    value={"lastTwoYears"}
                  >
                    Last Two Years
                  </MenuItem>
                </Select>
              </FormControl>
            </div>
          </div>
        )}

        {/* filter end */}
      </div>

      <div>
        {isSelect ? (
          <div
            className="render-icon"
            style={{
              backgroundColor: color,
            }}
          >
            {renderIcon()}
          </div>
        ) : (
          data && (
            <Typography
              sx={{
                fontFamily: "Inter, sans-serif",
                fontSize: "14px",
                fontWeight: 600,
                color: color,
                marginBottom: "1rem",
                paddingLeft: "24px",
              }}
              variant="subtitle1"
            >
              {content ?? ""}
            </Typography>
          )
        )}
      </div>

      <Box
        sx={{
          borderBottomLeftRadius: "16px",
          borderBottomRightRadius: "16px",
          overflow: "hidden",
        }}
      >
        <ResponsiveContainer width="100%" height={100}>
          <AreaChart data={chartData} margin={{}}>
            <Tooltip content={<CustomTooltip />} />
            <Area
              type="monotone"
              dataKey="amount"
              stroke={color}
              fill={color}
            />
          </AreaChart>
        </ResponsiveContainer>
      </Box>
    </Box>
  );
};

export default ChartBox;
