import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
// import "./Admin.scss";
import {
  Box,
  FormControl,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import AccountBox from "src/components/shared/AccountBox/AccountBox";
import GroupIcon from "@mui/icons-material/Group";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import PaidIcon from "@mui/icons-material/Paid";
import api from "src/store/interceptors/api";
import LoadingIndicator from "src/components/shared/LoadingIndicator";
import ChartBox from "src/components/shared/ChartBox/ChartBox";
import ChartBar from "src/components/shared/ChartBar/ChartBar";
import { barOptions } from "src/utils/helpers";
import ChartPie from "src/components/shared/ChartsExternal/ChartPie/ChartPie";
import React from "react";
import SlideAnimation from "src/components/shared/SlideAnimation/SlideAnimation";

const defaultTheme = createTheme();

const prepareGraphCountriesData = (data: any) => {
  let result: any = [];
  data.forEach((item: any) => {
    if (item && item.name && item.amount)
      result.push({
        label: item.name,
        value: item.amount,
      });
  });

  return { series: result };
};
const prepareGraphUsersData = (data: any) => {
  let result: any = [];
  data.forEach((item: any) => {
    result.push({
      name: item.name,
      amt: item.amount,
      registrations: item.amount,
    });
  });
  if (result) return result;
  return null;
};

const prepareGraphBalanceData = (data: any) => {
  let result: any = {};

  if (data.expenses && data.expenses.data) {
    data.expenses.data.forEach((item: any) => {
      if (item.name && item.amount && item.amount > 0) {
        if (!result[item.name]) result[item.name] = {};
        if (!result[item.name].expense) result[item.name].expense = 0;
        result[item.name].expense = item.amount;
      }
    });
  }

  if (data.income && data.income.data) {
    data.income.data.forEach((item: any) => {
      if (item.name && item.amount && item.amount > 0) {
        if (!result[item.name]) result[item.name] = {};
        if (!result[item.name].income) result[item.name].income = 0;
        result[item.name].income = item.amount;
      }
    });
  }

  if (data.pending && data.pending.data) {
    data.pending.data.forEach((item: any) => {
      if (item.name && item.amount && item.amount > 0) {
        if (!result[item.name]) result[item.name] = {};
        if (!result[item.name].pending) result[item.name].pending = 0;
        result[item.name].pending = item.amount;
      }
    });
  }

  if (result) {
    const response: any = [];
    Object.entries(result).forEach(([key, item]: [string, any]) => {
      let income = item.income ?? 0;
      let expense = item.expense ?? 0;
      let pending = item.pending ?? 0;
      let sum = parseFloat(income) + parseFloat(expense) + parseFloat(pending);

      response.push({
        name: key,
        income: income,
        expense: expense,
        pending: pending,
        amt: sum,
      });
    });

    return response;
  }

  return null;
};

const barOptionsForBalance = barOptions("balance");
const barOptionsForRegistrations = barOptions("user-registration");
export default function AdminDashboard() {
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const [chartData, setChartData] = useState<any>(null);
  const [chartData2, setChartData2] = useState<any>(null);
  const [chartDataCountries, setChartDataCountries] = useState<any>(null);
  const [chartDataRegistrations, setChartDataRegistrations] =
    useState<any>(null);

  // chart filter by date start
  const [initialThreeBoxesPeriod, setInitialThreeBoxesPeriod] =
    React.useState("last30Days");
  const [incomePeriod, setIncomePeriod] = React.useState("last30Days");
  const [expensesPeriod, setExpensesPeriod] = React.useState("last30Days");
  const [balancePeriod, setBalancePeriod] = React.useState("last30Days");
  const [userRegistrationsPeriod, setUserRegistrationsPeriod] =
    React.useState("last30Days");
  const [countriesPeriod, setCountriesPeriod] = React.useState("last30Days");
  const [urlFilter, setUrlFilter] = useState<string>("");

  useEffect(() => {
    let strFilter = "&incomePeriod=" + incomePeriod;
    strFilter += "&expensesPeriod=" + expensesPeriod;
    strFilter += "&balancePeriod=" + balancePeriod;
    strFilter += "&userRegistrationsPeriod=" + userRegistrationsPeriod;
    strFilter += "&countriesPeriod=" + countriesPeriod;
    strFilter += "&initialThreeBoxesPeriod=" + initialThreeBoxesPeriod;
    setUrlFilter(strFilter);
  }, [
    incomePeriod,
    expensesPeriod,
    balancePeriod,
    userRegistrationsPeriod,
    countriesPeriod,
    initialThreeBoxesPeriod,
  ]);

  useEffect(() => {
    const fetchTotals = async () => {
      try {
        const response = await api.get(`/admin/totals?action=true${urlFilter}`);
        return response.data;
      } catch (error) {
        console.error("There was a problem fetching the totals:", error);
        throw error; // This will be caught by the catch block in loadData
      }
    };

    const getChartData = async () => {
      try {
        const response = await api.get(
          `/balance-statistics?filter=admin${urlFilter}`
        );
        return response.data;
      } catch (error) {
        console.error("There was a problem fetching chart data:", error);
        throw error; // This will be caught by the catch block in loadData
      }
    };

    const loadData = async () => {
      setLoading(true);
      try {
        const [totalsData, chartDataResponse] = await Promise.all([
          fetchTotals(),
          getChartData(),
        ]);

        if (totalsData) {
          setTotals(totalsData.totals);
          if (totalsData.users) {
            setChartDataRegistrations(prepareGraphUsersData(totalsData.users));
          }
          if (totalsData.country) {
            setChartDataCountries(
              prepareGraphCountriesData(totalsData.country)
            );
          }
        }

        if (chartDataResponse) {
          setChartData(chartDataResponse);
          const graph = prepareGraphBalanceData(chartDataResponse);
          setChartData2(graph);
        }
      } catch (error) {
        console.error("Error loading data:", error);
      } finally {
        setLoading(false);
      }
    };

    loadData();
  }, []);

  const handleChange =
    (setter: (value: string) => void, type: string) =>
    (event: SelectChangeEvent) => {
      setter(event.target.value as string);
    };
  // chart filter by date end

  const [totals, setTotals] = useState({
    user_count: 0,
    recipient_count: 0,
    transaction_count: 0,
  });
  const [selected, setSelected] = useState("Home");


  const redirectTo = (url: string) => {
    navigate(url);
  };

  return (
    <div>
      <div className="main-admin-container">
        <div className="content-container-admin main-color">
          <ThemeProvider theme={defaultTheme}>
            <Container component="main">
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <div className="filter-and-boxes">
                  {/* start filter*/}
                  <div
                    style={{
                      display: "flex",
                      alignItems: "end",
                      justifyContent: "end",
                      marginRight: "1rem",
                    }}
                  >
                    <div
                      style={{
                        width: 165,
                        display: "flex",
                        alignItems: "end",
                        justifyContent: "end",
                      }}
                    >
                      <FormControl fullWidth>
                        <Select
                          sx={{
                            height: "30px",
                            borderRadius: "16px",
                            backgroundColor: "rgb(244, 246, 248)",
                            fontSize: "14px",
                            marginBottom: "1rem",
                            color: "#2A5182",
                            border: "none",
                            "&:focus": {
                              outline: "none",
                              borderRadius: "8px",
                            },
                            "& .MuiOutlinedInput-notchedOutline": {
                              border: "none",
                            },
                            ".MuiSelect-select": {
                              borderRadius: "8px",
                            },
                          }}
                          value={initialThreeBoxesPeriod}
                          onChange={handleChange(
                            setInitialThreeBoxesPeriod,
                            "initialThreeBoxesPeriod"
                          )}
                        >
                          <MenuItem
                            sx={{
                              color: "#2A5182",
                              fontSize: "14px",
                            }}
                            value={"today"}
                          >
                            Today
                          </MenuItem>
                          <MenuItem
                            sx={{
                              color: "#2A5182",
                              fontSize: "14px",
                            }}
                            value={"last7Days"}
                          >
                            Last 7 Days
                          </MenuItem>
                          <MenuItem
                            sx={{
                              color: "#2A5182",
                              fontSize: "14px",
                            }}
                            value={"last30Days"}
                          >
                            Last 30 Days
                          </MenuItem>
                          <MenuItem
                            sx={{
                              color: "#2A5182",
                              fontSize: "14px",
                            }}
                            value={"last12Months"}
                          >
                            Last 12 Months
                          </MenuItem>
                          <MenuItem
                            sx={{
                              color: "#2A5182",
                              fontSize: "14px",
                            }}
                            value={"lastTwoYears"}
                          >
                            Last Two Years
                          </MenuItem>
                        </Select>
                      </FormControl>
                    </div>
                  </div>
                  {/* end filter*/}

                  {loading ? (
                    <LoadingIndicator />
                  ) : selected === "Home" ? (
                    <div className="box-container">
                      <Box
                        sx={{
                          marginRight: "0.5rem",
                          marginBottom: "1rem",
                          width: "100%",
                          marginLeft: "0.5rem",
                          cursor: "pointer",
                        }}
                        onClick={() => redirectTo("/admin/users")}
                      >
                        <AccountBox
                          img={<GroupIcon />}
                          title="Users"
                          content={totals.user_count.toString()}
                          isSmaller={true}
                        />
                      </Box>
                      <Box
                        sx={{
                          marginRight: "0.5rem",
                          marginBottom: "1rem",
                          width: "100%",
                          marginLeft: "0.5rem",
                          cursor: "pointer",
                        }}
                        onClick={() => redirectTo("/admin/recipients")}
                      >
                        <AccountBox
                          img={<GroupAddIcon />}
                          title="Recipients"
                          content={totals.recipient_count.toString()}
                          isSmaller={true}
                        />
                      </Box>
                      <Box
                        sx={{
                          marginRight: "0.5rem",
                          marginBottom: "1rem",
                          width: "100%",
                          marginLeft: "0.5rem",
                          cursor: "pointer",
                          height: "100%",
                        }}
                        onClick={() => redirectTo("/transactions-list")}
                      >
                        <AccountBox
                          img={<PaidIcon />}
                          title="Transactions"
                          content={totals.transaction_count.toString()}
                          isSmaller={true}
                        />
                      </Box>
                    </div>
                  ) : selected === "Users" ? (
                    <div>Users</div>
                  ) : selected === "Recipients" ? (
                    <div>Recipients</div>
                  ) : null}
                </div>
              </Box>

              <div className="">
                {/* charts start */}

                <div className="charts-contianer mt-1-5rem ml-8 mr-8 income-box">
                  <div>
                    <ChartBox
                      title={"Income" ?? ""}
                      data={
                        chartData && chartData.income ? chartData.income : null
                      }
                      content={
                        chartData?.income?.percentage
                          ? `${
                              chartData?.income?.percentage
                                ? chartData.income.percentage
                                : `than last month`
                            }`
                          : "Last 30 days"
                      }
                      bgColor="rgba(91, 228, 155, 0.2)"
                      color="rgb(0, 75, 80)"
                      chartData={chartData?.income?.data}
                      iconName="up"
                      cost={
                        chartData?.income?.amount
                          ? chartData?.income?.amount.toFixed(2)
                          : 0
                      }
                      currency={"EUR"}
                      incomePeriod={incomePeriod}
                      handleIncomeChange={handleChange(
                        setIncomePeriod,
                        "incomePeriod"
                      )}
                    />
                  </div>

                  <div>
                    <ChartBox
                      title={"Expenses" ?? ""}
                      data={
                        chartData && chartData.expenses
                          ? chartData.expenses
                          : null
                      }
                      content={
                        chartData?.expenses?.percentage
                          ? `${
                              chartData?.expenses?.percentage
                                ? chartData.expenses.percentage
                                : `than last month`
                            }`
                          : "Last 30 days"
                      }
                      bgColor="rgba(255, 214, 102, 0.2)"
                      color="rgb(122, 65, 0)"
                      chartData={chartData?.expenses?.data}
                      iconName="down"
                      cost={
                        chartData?.expenses?.amount
                          ? chartData?.expenses?.amount.toFixed(2)
                          : 0
                      }
                      currency={"EUR"}
                      incomePeriod={expensesPeriod}
                      handleIncomeChange={handleChange(
                        setExpensesPeriod,
                        "expensesPeriod"
                      )}
                    />
                  </div>
                </div>

                {chartData2 && (
                  <div className="bar-year-container">
                    <SlideAnimation threshold={350}>
                      <ChartBar
                        data={chartData2}
                        keyBars={barOptionsForBalance}
                        title="Balance Statistics"
                        rightTitle="select"
                        initialThreeBoxesPeriod={balancePeriod}
                        handleThreeBoxesChange={handleChange(
                          setBalancePeriod,
                          "balancePeriod"
                        )}
                      />
                    </SlideAnimation>
                  </div>
                )}

                {chartDataRegistrations && (
                  <div className="bar-year-container">
                    <SlideAnimation threshold={650}>
                      <ChartBar
                        data={chartDataRegistrations}
                        keyBars={barOptionsForRegistrations}
                        title="User registrations"
                        rightTitle="select"
                        initialThreeBoxesPeriod={userRegistrationsPeriod}
                        handleThreeBoxesChange={handleChange(
                          setUserRegistrationsPeriod,
                          "userRegistrationsPeriod"
                        )}
                      />
                    </SlideAnimation>
                  </div>
                )}

                {chartDataCountries && (
                  <SlideAnimation threshold={950}>
                    <ChartPie
                      title="Accounts per countries"
                      chart={chartDataCountries}
                      initialThreeBoxesPeriod={countriesPeriod}
                      handleThreeBoxesChange={handleChange(
                        setCountriesPeriod,
                        "countriesPeriod"
                      )}
                    />
                  </SlideAnimation>
                )}
                {/* chart end */}
              </div>
            </Container>
          </ThemeProvider>
        </div>
      </div>
    </div>
  );
}
