import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { Data, Recipient } from "../../types/recipient.types";
import recipientsService from "./recipients_services";

interface InitialStateRecipient {
  isLoading: boolean;
  isSuccess: boolean;
  isError: boolean;
  recipient: Data | null;
  url: string | null;
  Recipient: Recipient | null;
  successCRUD: boolean;
}

const initialRecipientState: InitialStateRecipient = {
  isLoading: false,
  isSuccess: false,
  isError: false,
  recipient: null,
  url: null,
  successCRUD: false,
  Recipient: null,
};

export const editRecipient = createAsyncThunk(
  "recipient/edit",
  async (data: { recipientId: string, updateData: any }, thunkAPI) => {
    try {
      const { recipientId, updateData } = data;
      return await recipientsService.editRecipientsById(recipientId, updateData);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

const recipientSlice = createSlice({
  name: "recipient",
  initialState: initialRecipientState,
  reducers: {
    resetRecipientState(state) {
      state.isSuccess = false;
      state.isError = false;
      state.successCRUD = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(editRecipient.pending, (state) => {
        state.isLoading = true;
        state.successCRUD = false;
        
      })
      .addCase(editRecipient.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.successCRUD = true;
      })
      .addCase(editRecipient.rejected, (state) => {
        state.isSuccess = false;
        state.isLoading = false;
        state.isError = true;
        state.successCRUD = false;
      });
  },
});

export const { resetRecipientState } = recipientSlice.actions;
export default recipientSlice.reducer;
