import { Box, Button } from "@mui/material";
import i18next from "i18next";
import { useNavigate } from "react-router-dom";
import CustomInput from "../shared/CustomInput/CustomInput";

interface Props {
  email: string;
  handleChange: (value: any) => void;
  handleNextClick: () => void;
  isEmailValid: boolean;
}
function FirstStep({
  email,
  handleChange,
  handleNextClick,
  isEmailValid,
}: Props) {
  const navigate = useNavigate();

  const navigateToLogin = (event: any) => {
    navigate("/login");
  };

  return (
    <Box className="initial-screen d-flex ai-center jc-center fx-column content-container-home">
      <h3
        className="big-subtitle fw-400 loginTitleFontSize"
        style={{ fontFamily: "Helvetica, sans-serif" }}
      >
        {i18next.t("Create your secure VicPay Account")}
      </h3>
      <Box className="d-flex ai-center jc-center mt-1-5rem">
        <h5
          className="very-small-text fw-300 mr-0-3 registration08RemFontSize"
          style={{
            fontFamily: "Helvetica, sans-serif",
            marginBottom: "2.2rem",
          }}
        >
          {i18next.t("Already have a VicPay Account? Log In here")}
        </h5>
        <p
          style={{
            fontFamily: "Helvetica, sans-serif",
            marginBottom: "2.2rem",
            cursor: "pointer",
          }}
          className="very-small-text black-text fw-bold registration08RemFontSize"
          onClick={navigateToLogin}
        >
          {i18next.t("Log in")}
        </p>
      </Box>

      <Box className="email-field d-flex fx-column mt-2rem">
        <h3
          className="small-text mb-0-5rem fw-400 oneRemFontSize"
          style={{ fontFamily: "Helvetica, sans-serif" }}
        >
          {i18next.t("Please, enter your email address")}
        </h3>
        <Box sx={{ mt: 1 }}>
          <CustomInput
            id="email"
            placeHolder={i18next.t("Email Address")}
            value={email}
            onChangeText={handleChange}
            errors={
              !isEmailValid
                ? i18next.t("Please enter a valid email address")
                : null
            }
            isRequired={true}
            isTouched={!isEmailValid}
          />
        </Box>
      </Box>
      <Button
        type="button"
        fullWidth
        variant="contained"
        className="btnsLoginHeight oneRemFontSize"
        sx={{
          color: "white",
          backgroundColor: "#2A5182",
          borderRadius: "24px",
          textTransform: "none",
          fontFamily: "Helvetica, sans-serif",
        }}
        onClick={handleNextClick}
        disabled={!isEmailValid || !email}
      >
        {i18next.t("Next")}
      </Button>
    </Box>
  );
}

export default FirstStep;
