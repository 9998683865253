import React, { useRef, useEffect, useState } from "react";
import "./Registration.scss";
import Webcam from "react-webcam";
import { useFaceDetection } from "react-use-face-detection";
import FaceDetection from "@mediapipe/face_detection";
import { Camera } from "@mediapipe/camera_utils";
import { Box, Button, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import KYCGuideImage from "../../assets/images/kyc_guide.png";
import { toast } from "react-toastify";
import CameraAccessModal from "../shared/CameraAccessModal/CameraAccessModal";
import i18next from "i18next";

interface Props {
  open: boolean;
  handleClose: () => void;
  handleUploadScannedFile: (
    facePhoto: any,
    idPhoto: any,
    handleUploadScannedFile: any
  ) => void;
}
const ScannFaceAndIdModal: React.FC<Props> = ({
  open,
  handleClose,
  handleUploadScannedFile,
}) => {
  let canCaptureImage = false;
  const canvasRef = useRef<any>(null);
  const [width, setWidth] = useState(800);
  const [height, setHeight] = useState(640);
  const [cameraAccessModal, setcameraAccessModal] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth >= 800) {
        setWidth(window.innerWidth / 2);
        setHeight(640);
      }

      if (window.innerWidth < 600) {
        setWidth(window.innerWidth);
        setHeight(window.innerHeight);
      }
    };

    window.addEventListener("resize", handleResize);

    // Cleanup the event listener on unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const { webcamRef, boundingBox, isLoading, detected, facesDetected }: any =
    useFaceDetection({
      faceDetectionOptions: {
        model: "short",
      },
      faceDetection: new FaceDetection.FaceDetection({
        locateFile: (file) => `/mediapipe/models/face_detection/${file}`,
      }),
      camera: ({ mediaSrc, onFrame }) =>
        new Camera(mediaSrc, {
          onFrame,
          width,
          height,
        }),
    });
  useEffect(() => {
    const interval = setInterval(() => {
      const webcam: any = webcamRef.current;
      const canvas: any = canvasRef.current;

      if (webcam && webcam.video.readyState === 4) {
        const video = webcam.video;
        const context = canvas.getContext("2d");

        // Set canvas size to match webcam video size
        canvas.width = video.videoWidth;
        canvas.height = video.videoHeight;
        // Apply blur effect to the entire canvas
        context.filter = "blur(20px)";
        context.drawImage(video, 0, 0, canvas.width, canvas.height);

        const rectWidth = 400;
        const rectHeight = 350;
        const rectX = (canvas.width - rectWidth) / 2;
        const rectY = 0;
        context.clearRect(rectX, rectY, rectWidth, rectHeight);

        const rectX2 = (canvas.width - 250) / 2;
        const rectY2 = canvas.width - 270;
        context.clearRect(rectX2, rectY2, 250, 90);
      }
    }, 100);

    return () => clearInterval(interval);
  }, [open]);

  function base64ToFile(base64String: any, fileName: string) {
    // Extract the base64 data (remove data:image/jpeg;base64, prefix)
    const base64Data = base64String.replace(
      /^data:image\/(png|jpeg);base64,/,
      ""
    );

    // Convert base64 to a Blob
    const byteCharacters = atob(base64Data);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], { type: "image/jpeg" });

    // Create a File object from the Blob
    return new File([blob], fileName, { type: "image/jpeg" });
  }

  const handleCapturePhoto = () => {
    if (canCaptureImage) {
      const canvas: any = canvasRef.current;
      if (canvas !== null) {
        const facePhoto = base64ToFile(
          faceDetectionCapturePhoto(canvas),
          "facePhoto.jpeg"
        );
        const idPhoto = base64ToFile(
          detectCardIdCapturePhoto(canvas),
          "idPhoto.jpeg"
        );
        const completePhoto = base64ToFile(
          captureCompletePhoto(canvas),
          "completePhoto.jpeg"
        );
        handleUploadScannedFile(facePhoto, idPhoto, completePhoto);
      }

      if (webcamRef && webcamRef.current && webcamRef.current.video) {
        navigator.mediaDevices
          .getUserMedia({ video: true, audio: false })
          .then((stream: any) => {
            stream.getTracks().forEach((track: any) => track.stop());
          })
          .catch(function (err) {
            console.log(err);
            if (
              err.name === "NotFoundError" ||
              err.name === "DevicesNotFoundError"
            ) {
              setcameraAccessModal(true);
            } else if (
              err.name === "NotReadableError" ||
              err.name === "TrackStartError"
            ) {
              setcameraAccessModal(true);
            } else if (
              err.name === "OverconstrainedError" ||
              err.name === "ConstraintNotSatisfiedError"
            ) {
              setcameraAccessModal(true);
            } else if (
              err.name === "NotAllowedError" ||
              err.name === "PermissionDeniedError"
            ) {
              setcameraAccessModal(true);
            } else if (err.name === "TypeError" || err.name === "TypeError") {
              setcameraAccessModal(true);
            } else {
              setcameraAccessModal(true);
            }
          });
      }
    } else {
      toast.error(
        i18next.t(
          "You have to position your face in order to complete the capture"
        )
      );
    }
  };

  const faceDetectionCapturePhoto = (canvas: any) => {
    const webcam = webcamRef.current.video;
    const rectWidth = 400;
    const rectHeight = 350;
    const rectX = (canvas.width - rectWidth) / 2;
    const rectY = 20;

    canvas.width = rectWidth;
    canvas.height = rectHeight;
    const context = canvas.getContext("2d");

    context.drawImage(
      webcam,
      rectX,
      rectY,
      rectWidth,
      rectHeight,
      0,
      0,
      rectWidth,
      rectHeight
    );

    const capturedImageUrl = canvas.toDataURL("image/jpeg");
    return capturedImageUrl;
  };

  const detectCardIdCapturePhoto = (canvas: any) => {
    const webcam = webcamRef.current.video;
    const rectWidth = 300;
    const rectHeight = 150;
    const rectX = canvas.width - 230;
    const rectY = canvas.width - 70;
    canvas.width = rectWidth;
    canvas.height = rectHeight;
    const context = canvas.getContext("2d");

    context.drawImage(
      webcam,
      rectX,
      rectY,
      rectWidth,
      rectHeight,
      0,
      0,
      rectWidth,
      rectHeight
    );

    const capturedImageUrl = canvas.toDataURL("image/jpeg");
    return capturedImageUrl;
  };

  const captureCompletePhoto = (canvas: any) => {
    const webcam = webcamRef.current.video;

    canvas.width = webcam.videoWidth;
    canvas.height = webcam.videoHeight;
    const context = canvas.getContext("2d");

    context.drawImage(webcam, 0, 0);

    const capturedImageUrl = canvas.toDataURL("image/jpeg");
    return capturedImageUrl;
  };

  return (
    <Box className="myModal">
      <Box className="myModalWrapper">
        <div
          className="closeIcon"
          onClick={() => {
            if (webcamRef && webcamRef.current && webcamRef.current.video) {
              navigator.mediaDevices
                .getUserMedia({ video: true, audio: false })
                .then((stream: any) => {
                  stream.getTracks().forEach((track: any) => track.stop());
                });
            }
            handleClose();
          }}
        >
          <CloseIcon />
        </div>
        <Typography
          className="fs-1-625 fw-400"
          style={{
            fontFamily: "Helvetica, sans-serif",
            fontWeight: 400,
            textAlign: "center",
            marginTop: 20,
          }}
        >
          {i18next.t("Scan your Face and ID Card")}
        </Typography>
        <div className="container">
          <div className="container-wrapper">
            <div style={{ width: "800px", height: "auto" }}>
              <div className="webcam-container">
                {boundingBox.map((box: any, index: number) => {
                  if (canvasRef.current !== null) {
                    const boxX = box.xCenter * canvasRef.current.width;
                    const boxY = box.yCenter * canvasRef.current.height;

                    if (
                      boxX >= (canvasRef.current.width - 250) / 2 &&
                      boxX <= canvasRef.current.width / 2 &&
                      boxY >= 50 &&
                      boxY <= 250
                    ) {
                      canCaptureImage = true;
                      return (
                        <div
                          key={index}
                          style={{
                            border: "2px dashed red",
                            position: "absolute",
                            top: `${box.yCenter * 50}%`,
                            left: `${box.xCenter * 92}%`,
                            width: `${box.width * 120}%`,
                            height: `${box.height * 160}%`,
                            zIndex: 2,
                            borderRadius: "50%",
                          }}
                        />
                      );
                    } else {
                      canCaptureImage = false;

                      return null;
                    }
                  } else {
                    return null;
                  }
                })}

                <div className="clear-area" />

                <div className="clear-area-card" />
                <canvas ref={canvasRef} className="webcam-canvas" />
                <div style={{ borderRadius: 10 }}>
                  <Webcam
                    ref={webcamRef}
                    className="webcam-view"
                    screenshotFormat="image/jpeg"
                    width={800}
                    height={640}
                  />
                </div>
              </div>
              <div className="container-bottom">
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: "50px",
                  }}
                >
                  <Button
                    variant="contained"
                    style={{ alignSelf: "center" }}
                    onClick={() => handleCapturePhoto()}
                  >
                    <CameraAltIcon style={{ marginRight: 5 }} />{" "}
                    {i18next.t("capture")}
                  </Button>
                  <Button
                    variant="contained"
                    style={{ alignSelf: "center", backgroundColor: "red" }}
                    onClick={() => {
                      if (
                        webcamRef &&
                        webcamRef.current &&
                        webcamRef.current.video
                      ) {
                        navigator.mediaDevices
                          .getUserMedia({ video: true, audio: false })
                          .then((stream: any) => {
                            stream
                              .getTracks()
                              .forEach((track: any) => track.stop());
                          });
                      }
                      handleClose();
                    }}
                  >
                    <CloseIcon />
                    {i18next.t("Close")}
                  </Button>
                </Box>

                <img
                  src={KYCGuideImage}
                  alt="KYC guid Iamge"
                  className="kycImage"
                />
              </div>
            </div>
          </div>
        </div>
      </Box>
      <CameraAccessModal
        open={cameraAccessModal}
        handleClose={() => setcameraAccessModal(false)}
      />
    </Box>
  );
};

export default ScannFaceAndIdModal;
