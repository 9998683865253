import { Box, TextField, Typography } from "@mui/material";
import i18next from "i18next";
import React from "react";

interface Props {
  formik: any;
}
function ConfirmationOfSourceOfFunds({ formik }: Props) {
  return (
    <Box>
      <Box className="mb-big">
        <Typography
          className="pageTitleFontSize"
          component="h1"
          variant="h5"
          sx={{
            fontFamily: "Helvetica, sans-serif",
            fontWeight: "600",
            color: "#0e0f0c",
            maxWidth: "600px",
            overflow: "hidden",
          }}
        >
          {i18next.t("Holding activities")}
        </Typography>
        <div style={{ paddingTop: 5, paddingBottom: 5 }}>
          <Typography className="labelsLocalIban">
            {i18next.t(
              "What assets does the company hold and where? – if there are multiple holding layers, please clarify the final operational level including geography and industry"
            )}
          </Typography>
          <TextField
            minRows={3}
            style={{ width: "100%" }}
            multiline={true}
            value={formik.values.holding_activities_1}
            name="holding_activities_1"
            onChange={formik.handleChange}
            variant="outlined"
            placeholder={i18next.t("Please enter the information...")}
            sx={{
              "& .MuiOutlinedInput-root": {
                paddingRight: "0px!important",
              },
            }}
            InputProps={{
              sx: {
                borderRadius: "10px",
                "@media (max-width: 600px)": {
                  fontSize: "14px",
                },
              },
            }}
          />
        </div>

        <div style={{ paddingTop: 5, paddingBottom: 5 }}>
          <Typography className="labelsLocalIban">
            {i18next.t("How were the assets obtained?")}
          </Typography>
          <TextField
            minRows={3}
            style={{ width: "100%" }}
            multiline={true}
            value={formik.values.holding_activities_2}
            name="holding_activities_2"
            placeholder={i18next.t("Please enter the information...")}
            onChange={formik.handleChange}
            variant="outlined"
            sx={{
              "& .MuiOutlinedInput-root": {
                paddingRight: "0px!important",
              },
            }}
            InputProps={{
              sx: {
                borderRadius: "10px",
                "@media (max-width: 600px)": {
                  fontSize: "14px",
                },
              },
            }}
          />
        </div>
      </Box>

      <Box className="mb-big">
        <Typography
          className="pageTitleFontSize"
          component="h1"
          variant="h5"
          sx={{
            fontFamily: "Helvetica, sans-serif",
            fontWeight: "600",
            color: "#0e0f0c",
            maxWidth: "600px",
            overflow: "hidden",
          }}
        >
          {i18next.t("Trading Activities")}
        </Typography>
        <div style={{ paddingTop: 5, paddingBottom: 5 }}>
          <Typography className="labelsLocalIban">
            {i18next.t(
              "Describe the delivery chain and how this is funded by the prospect."
            )}
          </Typography>
          <TextField
            minRows={3}
            style={{ width: "100%" }}
            multiline={true}
            placeholder={i18next.t("Please enter the information...")}
            value={formik.values.trading_activities_1}
            name="trading_activities_1"
            onChange={formik.handleChange}
            variant="outlined"
            sx={{
              "& .MuiOutlinedInput-root": {
                paddingRight: "0px!important",
              },
            }}
            InputProps={{
              sx: {
                borderRadius: "10px",
                "@media (max-width: 600px)": {
                  fontSize: "14px",
                },
              },
            }}
          />
        </div>

        <div style={{ paddingTop: 5, paddingBottom: 5 }}>
          <Typography className="labelsLocalIban">
            {i18next.t(
              "Collect two forms of proof that would evidence the above statements such as invoices, contracts, bank statement, financial statement, etc"
            )}
          </Typography>
          <TextField
            minRows={3}
            style={{ width: "100%" }}
            multiline={true}
            placeholder={i18next.t("Please enter the information...")}
            value={formik.values.trading_activities_2}
            name="trading_activities_2"
            onChange={formik.handleChange}
            variant="outlined"
            sx={{
              "& .MuiOutlinedInput-root": {
                paddingRight: "0px!important",
              },
            }}
            InputProps={{
              sx: {
                borderRadius: "10px",
                "@media (max-width: 600px)": {
                  fontSize: "14px",
                },
              },
            }}
          />
        </div>
      </Box>

      <Box className="mb-big">
        <Typography
          className="pageTitleFontSize"
          component="h1"
          variant="h5"
          sx={{
            fontFamily: "Helvetica, sans-serif",
            fontWeight: "600",
            color: "#0e0f0c",
            maxWidth: "600px",
            whiteSpace: "wrap",
            overflow: "hidden",
          }}
        >
          {i18next.t("For prospects acting as intermediaries")}
        </Typography>
        <div style={{ paddingTop: 5, paddingBottom: 5 }}>
          <Typography className="labelsLocalIban">
            {i18next.t(
              "What is the role of the company and added value for the final recipient of goods?"
            )}
          </Typography>
          <TextField
            minRows={3}
            style={{ width: "100%" }}
            multiline={true}
            placeholder={i18next.t("Please enter the information...")}
            value={formik.values.prospect_aspect_1}
            name="prospect_aspect_1"
            onChange={formik.handleChange}
            variant="outlined"
            sx={{
              "& .MuiOutlinedInput-root": {
                paddingRight: "0px!important",
              },
            }}
            InputProps={{
              sx: {
                borderRadius: "10px",
                "@media (max-width: 600px)": {
                  fontSize: "14px",
                },
              },
            }}
          />
        </div>

        <div style={{ paddingTop: 5, paddingBottom: 5 }}>
          <Typography className="labelsLocalIban">
            {i18next.t(
              "Collect a full chain of documents including invoices, contracts, customs documents, transportation for at least 2 orders"
            )}
          </Typography>
          <TextField
            minRows={3}
            style={{ width: "100%" }}
            multiline={true}
            placeholder={i18next.t("Please enter the information...")}
            value={formik.values.prospect_aspect_2}
            name="prospect_aspect_2"
            onChange={formik.handleChange}
            variant="outlined"
            sx={{
              "& .MuiOutlinedInput-root": {
                paddingRight: "0px!important",
              },
            }}
            InputProps={{
              sx: {
                borderRadius: "10px",
                "@media (max-width: 600px)": {
                  fontSize: "14px",
                },
              },
            }}
          />
        </div>
      </Box>

      <Box className="mb-big">
        <Typography
          className="pageTitleFontSize"
          component="h1"
          variant="h5"
          sx={{
            fontFamily: "Helvetica, sans-serif",
            fontWeight: "600",
            color: "#0e0f0c",
            maxWidth: "600px",
            overflow: "hidden",
          }}
        >
          {i18next.t("IT/Marketing/Online Marketing/Legal Services")}
        </Typography>
        <div style={{ paddingTop: 5, paddingBottom: 5 }}>
          <Typography className="labelsLocalIban">
            {i18next.t(
              "Detailed description of the services provided including industry and geography of the final clients"
            )}
          </Typography>
          <TextField
            minRows={3}
            style={{ width: "100%" }}
            multiline={true}
            placeholder={i18next.t("Please enter the information...")}
            value={formik.values.it_marketing_1}
            name="it_marketing_1"
            onChange={formik.handleChange}
            variant="outlined"
            sx={{
              "& .MuiOutlinedInput-root": {
                paddingRight: "0px!important",
              },
            }}
            InputProps={{
              sx: {
                borderRadius: "10px",
                "@media (max-width: 600px)": {
                  fontSize: "14px",
                },
              },
            }}
          />
        </div>

        <div style={{ paddingTop: 5, paddingBottom: 5 }}>
          <Typography className="labelsLocalIban">
            {i18next.t(
              "How does the company provide the services (own employees, UBO on his own, outsourced third party suppliers, freelance contractors?)"
            )}
          </Typography>
          <TextField
            minRows={3}
            style={{ width: "100%" }}
            multiline={true}
            placeholder={i18next.t("Please enter the information...")}
            value={formik.values.it_marketing_2}
            name="it_marketing_2"
            onChange={formik.handleChange}
            variant="outlined"
            sx={{
              "& .MuiOutlinedInput-root": {
                paddingRight: "0px!important",
              },
            }}
            InputProps={{
              sx: {
                borderRadius: "10px",
                "@media (max-width: 600px)": {
                  fontSize: "14px",
                },
              },
            }}
          />
        </div>

        <div style={{ paddingTop: 5, paddingBottom: 5 }}>
          <Typography className="labelsLocalIban">
            {i18next.t(
              "What documents does the prospect have to confirm the services were rendered to the client? (fee calculation, agreement, timesheets, act of acceptance, etc)"
            )}
          </Typography>
          <TextField
            minRows={3}
            style={{ width: "100%" }}
            multiline={true}
            placeholder={i18next.t("Please enter the information...")}
            value={formik.values.it_marketing_3}
            name="it_marketing_3"
            onChange={formik.handleChange}
            variant="outlined"
            sx={{
              "& .MuiOutlinedInput-root": {
                paddingRight: "0px!important",
              },
            }}
            InputProps={{
              sx: {
                borderRadius: "10px",
                "@media (max-width: 600px)": {
                  fontSize: "14px",
                },
              },
            }}
          />
        </div>
      </Box>

      {/* missing */}
      <Box className="mb-big">
        <Typography
          className="pageTitleFontSize"
          component="h1"
          variant="h5"
          sx={{
            fontFamily: "Helvetica, sans-serif",
            fontWeight: "600",
            color: "#0e0f0c",
            maxWidth: "600px",
            overflow: "hidden",
          }}
        >
          {i18next.t("For intermediary service providers")}
        </Typography>
        <div style={{ paddingTop: 5, paddingBottom: 5 }}>
          <Typography className="labelsLocalIban">
            {i18next.t(
              "What is the final purpose of the service provision? (for example; the client provides marketing services for Dutch based advertising company that serves clients in gaming)."
            )}
          </Typography>
          <TextField
            minRows={3}
            style={{ width: "100%" }}
            multiline={true}
            placeholder={i18next.t("Please enter the information...")}
            value={formik.values.intermediary_service_1}
            name="intermediary_service_1"
            onChange={formik.handleChange}
            variant="outlined"
            sx={{
              "& .MuiOutlinedInput-root": {
                paddingRight: "0px!important",
              },
            }}
            InputProps={{
              sx: {
                borderRadius: "10px",
                "@media (max-width: 600px)": {
                  fontSize: "14px",
                },
              },
            }}
          />
        </div>

        <div style={{ paddingTop: 5, paddingBottom: 5 }}>
          <Typography className="labelsLocalIban">
            {i18next.t(
              "What documents does the prospect have to confirm the services were rendered to the client?"
            )}
          </Typography>
          <TextField
            minRows={3}
            style={{ width: "100%" }}
            multiline={true}
            placeholder={i18next.t("Please enter the information...")}
            value={formik.values.intermediary_service_2}
            name="intermediary_service_2"
            onChange={formik.handleChange}
            variant="outlined"
            sx={{
              "& .MuiOutlinedInput-root": {
                paddingRight: "0px!important",
              },
            }}
            InputProps={{
              sx: {
                borderRadius: "10px",
                "@media (max-width: 600px)": {
                  fontSize: "14px",
                },
              },
            }}
          />
        </div>
      </Box>

      <Box className="mb-big">
        <Typography
          className="pageTitleFontSize"
          component="h1"
          variant="h5"
          sx={{
            fontFamily: "Helvetica, sans-serif",
            fontWeight: "600",
            color: "#0e0f0c",
            maxWidth: "600px",
            overflow: "hidden",
          }}
        >
          {i18next.t("Consulting")}
        </Typography>
        <div style={{ paddingTop: 5, paddingBottom: 5 }}>
          <Typography className="labelsLocalIban">
            {i18next.t(
              "What industries are served?"
            )}
          </Typography>
          <TextField
            minRows={3}
            style={{ width: "100%" }}
            multiline={true}
            placeholder={i18next.t("Please enter the information...")}
            value={formik.values.consulting_1}
            name="consulting_1"
            onChange={formik.handleChange}
            variant="outlined"
            sx={{
              "& .MuiOutlinedInput-root": {
                paddingRight: "0px!important",
              },
            }}
            InputProps={{
              sx: {
                borderRadius: "10px",
                "@media (max-width: 600px)": {
                  fontSize: "14px",
                },
              },
            }}
          />
        </div>

        <div style={{ paddingTop: 5, paddingBottom: 5 }}>
          <Typography className="labelsLocalIban">
            {i18next.t(
              "What is the expertise of the service provider? What resources does the company have to provide the services?"
            )}
          </Typography>
          <TextField
            minRows={3}
            style={{ width: "100%" }}
            multiline={true}
            placeholder={i18next.t("Please enter the information...")}
            value={formik.values.consulting_2}
            name="consulting_2"
            onChange={formik.handleChange}
            variant="outlined"
            sx={{
              "& .MuiOutlinedInput-root": {
                paddingRight: "0px!important",
              },
            }}
            InputProps={{
              sx: {
                borderRadius: "10px",
                "@media (max-width: 600px)": {
                  fontSize: "14px",
                },
              },
            }}
          />
        </div>
      </Box>

      <Box className="mb-big">
        <Typography
          className="pageTitleFontSize"
          component="h1"
          variant="h5"
          sx={{
            fontFamily: "Helvetica, sans-serif",
            fontWeight: "600",
            color: "#0e0f0c",
            maxWidth: "600px",
            overflow: "hidden",
          }}
        >
          {i18next.t("Investment companies")}
        </Typography>
        <div style={{ paddingTop: 5, paddingBottom: 5 }}>
          <Typography className="labelsLocalIban">
            {i18next.t(
              "License, or explanation as to why a license is not required (with the link to relevant legislation)"
            )}
          </Typography>
          <TextField
            minRows={3}
            style={{ width: "100%" }}
            multiline={true}
            placeholder={i18next.t("Please enter the information...")}
            value={formik.values.investment_1}
            name="investment_1"
            onChange={formik.handleChange}
            variant="outlined"
            sx={{
              "& .MuiOutlinedInput-root": {
                paddingRight: "0px!important",
              },
            }}
            InputProps={{
              sx: {
                borderRadius: "10px",
                "@media (max-width: 600px)": {
                  fontSize: "14px",
                },
              },
            }}
          />
        </div>

        <div style={{ paddingTop: 5, paddingBottom: 5 }}>
          <Typography className="labelsLocalIban">
            {i18next.t(
              "Flow of funds – explanation of whether these are 1st or 3rd party funds?"
            )}
          </Typography>
          <TextField
            minRows={3}
            style={{ width: "100%" }}
            multiline={true}
            placeholder={i18next.t("Please enter the information...")}
            value={formik.values.investment_2}
            name="investment_2"
            onChange={formik.handleChange}
            variant="outlined"
            sx={{
              "& .MuiOutlinedInput-root": {
                paddingRight: "0px!important",
              },
            }}
            InputProps={{
              sx: {
                borderRadius: "10px",
                "@media (max-width: 600px)": {
                  fontSize: "14px",
                },
              },
            }}
          />
        </div>

        <div style={{ paddingTop: 5, paddingBottom: 5 }}>
          <Typography className="labelsLocalIban">
            {i18next.t(
              "The nature of the investments; where the funds are invested to and what is the purpose and how returns to investors are made"
            )}
          </Typography>
          <TextField
            minRows={3}
            style={{ width: "100%" }}
            multiline={true}
            placeholder={i18next.t("Please enter the information...")}
            value={formik.values.investment_3}
            name="investment_3"
            onChange={formik.handleChange}
            variant="outlined"
            sx={{
              "& .MuiOutlinedInput-root": {
                paddingRight: "0px!important",
              },
            }}
            InputProps={{
              sx: {
                borderRadius: "10px",
                "@media (max-width: 600px)": {
                  fontSize: "14px",
                },
              },
            }}
          />
        </div>

        <div style={{ paddingTop: 5, paddingBottom: 5 }}>
          <Typography className="labelsLocalIban">
            {i18next.t(
              "Explanation of AML/KYC policies that are in place to protect investors"
            )}
          </Typography>
          <TextField
            minRows={3}
            style={{ width: "100%" }}
            multiline={true}
            placeholder={i18next.t("Please enter the information...")}
            value={formik.values.investment_4}
            name="investment_4"
            onChange={formik.handleChange}
            variant="outlined"
            sx={{
              "& .MuiOutlinedInput-root": {
                paddingRight: "0px!important",
              },
            }}
            InputProps={{
              sx: {
                borderRadius: "10px",
                "@media (max-width: 600px)": {
                  fontSize: "14px",
                },
              },
            }}
          />
        </div>

        
      </Box>


    </Box>
  );
}

export default ConfirmationOfSourceOfFunds;
