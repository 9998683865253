import { Box, Button, Typography } from "@mui/material";
import "./FiltersDrawer.scss";
import HighlightOffRoundedIcon from "@mui/icons-material/HighlightOffRounded";
import i18next from "src/i18n";

interface FiltersProps {
  selectedFilters: any;
  handleSelectedFilters: (filters: any) => void;
}
function TypeFilters({ selectedFilters, handleSelectedFilters }: FiltersProps) {
  const handleToggleType = (types: string) => {
    const filters: any = { ...selectedFilters };
    if (!filters.types) filters.types = {};
    if (!filters.types.methods) filters.types.methods = [];

    const index = filters.types.methods.indexOf(types);
    if (index === -1) {
      filters.types.methods.push(types);
    } else {
      filters.types.methods.splice(index, 1);
    }

    handleSelectedFilters(filters);
  };

  const isTypeSelected = (types: string) => {
    return (
      selectedFilters.types &&
      selectedFilters.types.methods &&
      selectedFilters.types.methods.includes(types)
    );
  };

  return (
    <Box>
      <Typography className="subBodies">{i18next.t("Type")}</Typography>
      <Box className="btnTypesBox">
        <Button
          onClick={() => handleToggleType("Balance")}
          className={`buttonType ${
            isTypeSelected("Balance") ? "buttonTypeActive" : ""
          }`}
          endIcon={
            isTypeSelected("Balance") ? <HighlightOffRoundedIcon /> : null
          }
        >
          {i18next.t("Balance")}
        </Button>

        <Button
          onClick={() => handleToggleType("Bank")}
          className={`buttonType ${
            isTypeSelected("Bank") ? "buttonTypeActive" : ""
          }`}
          endIcon={isTypeSelected("Bank") ? <HighlightOffRoundedIcon /> : null}
        >
          {i18next.t("Bank")}
        </Button>

        <Button
          onClick={() => handleToggleType("Card")}
          className={`buttonType ${
            isTypeSelected("Card") ? "buttonTypeActive" : ""
          }`}
          endIcon={isTypeSelected("Card") ? <HighlightOffRoundedIcon /> : null}
        >
          {i18next.t("Card")}
        </Button>
      </Box>
    </Box>
  );
}

export default TypeFilters;
