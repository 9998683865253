import { Button } from "@mui/material";
import i18next from "i18next";
import React, { useEffect, useState } from "react";
interface Props {
  errors: string[];
  setStep: (value: number) => void;
  formik: any;
  step: number;
  isLoading?: boolean;
  lastStep: number;
}

function ContinueBackButtons({
  errors,
  setStep,
  formik,
  step,
  isLoading,
  lastStep,
}: Props) {
  const [isClicked, setIsClicked] = useState(false);
  const goBack = () => {
    if (step > 1) {
      setStep(step - 1);
    }
  };

  const goContinue = (e: any) => {
    formik.handleSubmit(e);
    if (step < lastStep) {
      setTimeout(() => {
        setIsClicked(true);
      }, 1000);
    }
  };

  useEffect(() => {
    const errorInArray = errors.find((x: string) => {
      if (x in formik.touched && x in formik.errors) {
        return true;
      }

      return false;
    });
    if (!errorInArray && isClicked) {
      formik.setErrors({});

      formik.setTouched({});
      setIsClicked(false);
      setStep(step + 1);
    }
    setIsClicked(false);
  }, [errors, isClicked]);

  useEffect(() => {
    formik.setErrors({});
  }, []);
  return (
    <div
      className={`button-constiner mt-2-5rem ${
        step > 1 ? "jusifyContentSpaceBetween" : "jusifyContentEnd"
      }`}
    >
      {step > 1 && (
        <Button
          type="button"
          fullWidth
          className="btnsLoginHeight oneRemFontSize"
          variant="contained"
          sx={{
            color: "white",
            backgroundColor: "#2A5182",
            borderRadius: "24px",
            textTransform: "none",
            fontFamily: "Helvetica, sans-serif",
            width: "45%",
          }}
          onClick={goBack}
        >
          {i18next.t("Back")}
        </Button>
      )}
      <Button
        type="button"
        fullWidth
        variant="contained"
        className="btnsLoginHeight oneRemFontSize"
        sx={{
          color: "white",
          backgroundColor: "#82A3A1",
          borderRadius: "24px",
          width: "45%",
          textTransform: "none",
          fontFamily: "Helvetica, sans-serif",
          justifySelf: "flex-end",
        }}
        onClick={goContinue}
      >
        {i18next.t("Continue")}
      </Button>
    </div>
  );
}

export default ContinueBackButtons;
