import React from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Typography, Box } from "@mui/material";
import { useNavigate } from "react-router-dom";
import HeaderLanding from "../components/HeaderLanding/HeaderLanding";
import { useAppSelector } from "src/store";
import FooterLanding from "../components/FooterLanding/FooterLanding";
import HeadlineVvidgetSmall from "../components/HeadlineVvidget/HeadlineVvidgetSmall";
import TitleBackBox from "../components/TitleBackBox/TitleBackBox";
import LogoTextVvidget from "../components/LogoTextVvidget/LogoTextVvidget";
import TextNumbersVvidget from "../components/TextNumbersVvidget/TextNumbersVvidget";
import i18next from "i18next";

const defaultTheme = createTheme();

const OnlineNewsroom: React.FC = () => {
  const { user } = useAppSelector((state) => state.auth);

  const navigate = useNavigate();

  const handleNavigateContact = () => {
    navigate("/contact-us");
  };

  return (
    <div>
      <HeaderLanding user={user} />
      <div className="margintTopPagesMainCont">
        <ThemeProvider theme={defaultTheme}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Box className="titleHeadlineSmallBG">
              <Box className="landingPagesMainBox">
                <Box>
                  <TitleBackBox title={i18next.t("Complains")} />
                </Box>
                <Box>
                  <HeadlineVvidgetSmall
                    headline={i18next.t("We are sorry to see you here")}
                    subtitle={i18next.t(
                      "We’re sorry you didn’t get the service you’d expect from us and in case your account has been deactivated, you can make an appeal and we'll let you know about next steps"
                    )}
                    smallText={i18next.t("Contact our support team")}
                    buttonText={i18next.t("Contact Us")}
                    onClickFunction={handleNavigateContact}
                  />
                </Box>
              </Box>
            </Box>
            <Box className="landingPagesMainBox">
              <Box>
                <TextNumbersVvidget
                  number="01"
                  title={i18next.t("Contact our Support Desk")}
                  text={
                    <>
                      {i18next.t(
                        "The fastest way to solve your issue is by reaching out to our dedicated support team"
                      )}
                    </>
                  }
                />
                <TextNumbersVvidget
                  number="02"
                  title={i18next.t("Call our dedicated support number")}
                  text={
                    <>
                      {i18next.t(
                        "Dial +1 604-260-0738 to speak directly with a member of our support team"
                      )}
                    </>
                  }
                />
                <TextNumbersVvidget
                  number="03"
                  title={i18next.t("Use our contact form")}
                  text={
                    <>
                      {i18next.t("Alternatively, you can use our contact form")}{" "}
                      <span
                        className="linksHover"
                        onClick={() => navigate("/contact-us")}
                      >
                        {i18next.t("here")}
                      </span>{" "}
                      {i18next.t("to submit your issue")}
                    </>
                  }
                />
                <TextNumbersVvidget
                  number="04"
                  title={i18next.t("Resolution within 24 hours")}
                  text={
                    <>
                      {i18next.t(
                        "We typically resolve all issues within 24 hours of receiving your complaint"
                      )}
                    </>
                  }
                />
                <TextNumbersVvidget
                  number="05"
                  title={i18next.t("Confirmation message")}
                  text={
                    <>
                      {i18next.t(
                        "You will receive a written message confirming that we have received your complaint"
                      )}
                    </>
                  }
                />
                <TextNumbersVvidget
                  number="06"
                  title={i18next.t("Further information may be requested")}
                  text={
                    <>
                      {i18next.t(
                        "If necessary, we may request additional information to assist with resolving your issue"
                      )}
                    </>
                  }
                />
              </Box>

              <Box
                sx={{
                  marginTop: "48px",
                  "@media(max-width: 1023px)": {
                    marginTop: "24px",
                  },
                }}
              >
                <LogoTextVvidget
                  text={
                    <>
                      {i18next.t(
                        "We are looking into each case carefully and responding as quickly as possible"
                      )}
                      <br />
                      {i18next.t(
                        "We aim to acknowledge your complaint within 24 hours and send you a final response within 7 calendar days, in case you are able to provide all necessary documents"
                      )}
                      <br />
                      {i18next.t(
                        "On rare occasions, we may unfortunately need to extend the time period for investigating your case in detail"
                      )}
                    </>
                  }
                />
              </Box>

              <Typography
                variant="h3"
                sx={{ marginBottom: "1rem" }}
                className="headlineMiniLandingPages"
              >
                {" "}
                {i18next.t(
                  "In case you want to send us written correspondence, please send it to"
                )}
              </Typography>
              <Typography
                variant="body1"
                paragraph
                className="textsLandingPage"
                style={{ marginTop: "1rem" }}
              >
                <span className="textsLandingsmallNoFontSize">
                  Victorum Capital Services Limited
                  <br />
                  6 Tokavaig, Alavik Lodge
                  <br />
                  IV44 8QL, Teangue
                  <br />
                  Great Britain
                </span>
              </Typography>
              <Typography
                variant="body1"
                paragraph
                className="textsLandingPage"
              >
                <span className="textsLandingsmallNoFontSize">
                  {i18next.t("Registered in Scotland, United Kingdom")}
                </span>
              </Typography>
              <Typography
                variant="body1"
                paragraph
                className="textsLandingPage"
              >
                <span className="textsLandingsmallNoFontSize">
                  {i18next.t("Company number")}
                </span>{" "}
                SC745907
              </Typography>
              <Typography
                variant="body1"
                paragraph
                className="textsLandingPage"
              >
                <span className="textsLandingsmallNoFontSize">
                  {i18next.t("CEO")}
                </span>{" "}
                Osbert Döhl
              </Typography>
            </Box>
          </Box>
        </ThemeProvider>
      </div>
      <Box sx={{ marginTop: "4rem" }}>
        <FooterLanding />
      </Box>
    </div>
  );
};

export default OnlineNewsroom;
