import { useState, useEffect, Fragment } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import api from "src/store/interceptors/api";
import LoadingIndicator from "../LoadingIndicator";
import { Box, MenuItem, Select, SelectChangeEvent } from "@mui/material";
import i18next from "src/i18n";

const AddCurrencyModal = ({ userBalance, updateList }: any) => {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [requestTime, setRequestTime] = useState<any>(null);

  const [currencies, setCurrencies] = useState<any>([]);
  const [currency, setCurrency] = useState<string | null | undefined>(null);

  const handleAddCurrency = async () => {
    setLoading(true);
    const payload = {
      currency: currency,
    };
    await api
      .post("/create-currency", payload)
      .then((response: any) => {
        setLoading(false);
        handleClose();
        updateList();
      })
      .catch((error) => {
        setLoading(false);
        console.error(error.message);
      });
  };
  const getInitialData = async () => {
    setLoading(true);
    const res = await api
      .get("/profile/balances")
      .then((response: any) => {
        const data = response && response.data ? response.data : [];
        const filtered = userBalance.map((item: any) => item.currency.code);
        const currency = data
          .filter((code: any) => !filtered.includes(code.currency))
          .map((item: any) => item.currency);
        setCurrencies(currency);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        console.error(error.message);
      });
  };

  const handleCurrencyChange = (event: SelectChangeEvent) => {
    setCurrency(event.target.value as string);
  };

  const handleClickOpen = () => {
    setCurrency(null);
    setOpen(true);
    getInitialData();
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    const queryParameters = new URLSearchParams(window.location.search);
    const action = queryParameters.get("action");
    const time = queryParameters.get("t");
    if (
      time !== requestTime &&
      userBalance &&
      action &&
      action === "add-currency"
    ) {
      if (!open) {
        setTimeout(() => {
          setRequestTime(time);
          handleClickOpen();
        }, 1000);
      }
    }
  }, [requestTime, userBalance, window.location.search]);

  return (
    <Fragment>
      <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{
          component: "form",
          onSubmit: (event: React.FormEvent<HTMLFormElement>) => {
            event.preventDefault();
            if (currency) {
              handleAddCurrency();
            } else {
              alert(i18next.t("Please select the currency!"));
            }
          },
        }}
      >
        <DialogTitle
          className="FontSize1275"
          style={
            currencies.length > 0
              ? { textAlign: "left" }
              : { textAlign: "center" }
          }
        >
          {i18next.t("Add New Currency")}
        </DialogTitle>
        <DialogContent>
          {loading ? (
            <LoadingIndicator />
          ) : (
            <Box>
              {currencies.length > 0 ? (
                <>
                  <DialogContentText className="oneRemFontSize">
                    {i18next.t("Please select the currency from the list")}
                  </DialogContentText>
                  <Box>
                    <Select
                      style={{ width: "100%" }}
                      value={currency ?? ""}
                      onChange={handleCurrencyChange}
                    >
                      {currencies.map((item: any, index: any) => {
                        return (
                          <MenuItem key={"currencyitem" + index} value={item}>
                            {item}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </Box>
                </>
              ) : (
                <DialogContentText
                  style={{ textAlign: "center" }}
                  className="oneRemFontSize"
                >
                  {i18next.t("No more currencies to add")}
                </DialogContentText>
              )}
            </Box>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            className="btnsLoginHeight oneRemFontSize"
            onClick={handleClose}
          >
            {i18next.t("Cancel")}
          </Button>
          <Button
            className="btnsLoginHeight oneRemFontSize"
            type="submit"
            disabled={loading || !currency}
          >
            {i18next.t("Add new currency")}
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
};

export default AddCurrencyModal;
