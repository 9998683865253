import { Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import AddIcon from "@mui/icons-material/Add";
import i18next from "i18next";
interface Props {
  data: any[];
  user: any;
  fromPage?: string;
}

function BalanceRequestListItem({ data, user, fromPage }: Props) {
  const navigate = useNavigate();

  const handleOnClick = (item: any) => {
    const url = `/balance-detail/${item.id}`;
    navigate(url);
  };

  const renderIcon = () => {
    return <AddIcon style={{ color: "#0e0f0c" }} className="muiIcons" />;
  };

  const formatDate = (sectionName: string) => {
    // Check if sectionName is a valid date
    const date = new Date(sectionName);
    if (!isNaN(date.getTime())) {
      // It's a valid date, format it
      const currentLanguage = i18next.language || "en-US";
      return new Intl.DateTimeFormat(currentLanguage, {
        month: "long",
        day: "numeric",
      }).format(date);
    } else {
      // Not a valid date, return as is
      return sectionName;
    }
  };

  const getUserDisplayName = (user: any) => {
    if (!user) return "";

    if (user.account_type === "business" && user.company_name) {
      return user.company_name;
    }

    if (user.first_name && user.last_name) {
      return `${user.first_name} ${user.last_name}`;
    }

    return "";
  };
  return (
    <div className="transaction-section">
      <div className="transaction-list-container">
        {data && data.length > 0 ? (
          data.map((item, index) => (
            <div
              className="transaction-item"
              onClick={() => handleOnClick(item)}
              key={"uniqueID" + index}
            >
              <div className="d-flex ai-center jc-center">
                <p className="back-button-icon circle-around-text-smaller helvetica mr-1-rem">
                  {renderIcon()}{" "}
                </p>
                <div>
                  <Typography
                    component="h1"
                    variant="h5"
                    className="registration0875RemFontSize"
                    sx={{
                      fontFamily: "Helvetica, sans-serif",
                      // fontSize: "1rem",
                      fontWeight: "600",
                      color: "#454745",
                      marginBottom: "0.5rem",
                    }}
                  >
                    {getUserDisplayName(item.user)}
                  </Typography>
                  <Typography
                    component="h1"
                    variant="h5"
                    className="registration0875RemFontSize"
                    sx={{
                      fontFamily: "Helvetica, sans-serif",
                      // fontSize: "0.875rem",
                      fontWeight: "300",
                      color: "#454745",
                    }}
                  >
                    {i18next.t(item.status)} / {formatDate(item.created_at)}
                  </Typography>
                </div>
              </div>
              <div className="ta-end">
                <Typography
                  component="h1"
                  variant="h5"
                  className="registration0875RemFontSize"
                  sx={{
                    fontFamily: "Helvetica, sans-serif",
                    // fontSize: "0.875rem",
                    fontWeight: "300",
                    color: "#454745",
                  }}
                >
                  {item.amount}{" "}
                  {item && item.currency && item.currency.code
                    ? item.currency.code
                    : ""}
                </Typography>
              </div>
            </div>
          ))
        ) : (
          <Typography
            className="your-details-text d-flex jc-start underline registration1375RemFontSize"
            component="h1"
            variant="h5"
            sx={{
              fontFamily: "Helvetica, sans-serif",
              // fontSize: "1.875rem",
              fontWeight: "300",
              textAlign: "start",
              marginBottom: "2rem",
            }}
          >
            {i18next.t("No requests to display!")}
          </Typography>
        )}
      </div>
    </div>
  );
}

export default BalanceRequestListItem;
