import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { useEffect, useState } from "react";
import LoadingIndicator from "src/components/shared/LoadingIndicator";
import {
  Box,
  InputAdornment,
  Pagination,
  TextField,
  Typography,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import Nav from "src/components/layout/nav/Nav";
import NotificationsList from "src/components/shared/Notifications/NotificationsList";
import api from "src/store/interceptors/api";
import { useAppSelector } from "src/store";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate } from "react-router";
import i18next from "i18next";

const defaultTheme = createTheme();

export default function NotificationsPage() {
  const navigate = useNavigate();
  const { user } = useAppSelector((state) => state.auth);
  const [notificationsList, setNotificationsList] = useState([]);
  const [page, setPage] = useState(1);
  const [perPage] = useState(10);
  const [totalPages, setTotalPages] = useState(1);
  const [total, setTotal] = useState(0);
  const [searchValue, setSearchValue] = useState("");
  const [loading, setLoading] = useState(false);

    const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      setSearchValue(event.target.value);
      setPage(1);
    };

  const handleReadAllNotifications = async () => {
    const res: any = await api.put(`/notifications/mark-as-read`);
    if (res) {
      window.location.reload();
      //updateNotifications();
    }
  };
  const handleUpdateNotification = async (id?: number) => {
    if (id) {
      const filter: any = [...notificationsList];
      const foundItem: any =
        filter && filter.length > 0
          ? filter.findIndex((item: any) => item.id === id)
          : -1;
      if (foundItem > -1) {
        filter[foundItem].is_read = true;
        setNotificationsList(notificationsList);
      }
    }
    updateNotifications();
  };

  const handleGetNotifications = async () => {
    try {
      setLoading(true);

      let urlFilter = "";
      if (searchValue) {
        urlFilter = `?page=${page}&per_page=${perPage}&search[data]=${searchValue}`;
      } else {
        urlFilter = `?page=${page}&per_page=${perPage}`;
      }
      const res: any = await api.get(
        `/notifications${urlFilter}`
      );

      if (res && res.data) {
        const { data } = res;
        if (data) {
          setNotificationsList(data.data);
          const metaInfo = data.meta;
          setTotalPages(metaInfo?.last_page || 1);
          setTotal(metaInfo?.total || 0);
        }
      }
      setLoading(false);
    } catch (error) {
      console.error(error);
    }
  };

  const updateNotifications = async () => {
    handleGetNotifications();
  };

  useEffect(() => {
    handleGetNotifications();
  }, [searchValue, page]);

  const handleNavigate = () => {
    navigate("/home");
  };

  return (
    <div>
      <Nav step={0} isStepperVisible={false} isProfileSetVisible={true} />
      <div className="main-admin-container">
        <div className="content-container-profile main-color">
          <ThemeProvider theme={defaultTheme}>
            <Container component="main">
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <p
                  className="back-button-icon circle-around-text-smaller helvetica mr-1-rem cp"
                  onClick={handleNavigate}
                >
                  <ArrowBackIcon className="muiIcons"/>
                </p>
                <Typography
                  className="your-details-text d-flex jc-start pageTitleFontSize"
                  component="h1"
                  variant="h5"
                  sx={{
                    fontFamily: "Helvetica, sans-serif",
                    fontWeight: "600",
                    textAlign: "start",
                  }}
                >
                  {i18next.t("Notifications")}
                </Typography>
              </Box>
              <Box sx={{ marginTop: "32px", marginBottom: "32px" }}>
                <TextField
                  placeholder={i18next.t("Search Notification By Name")}
                  style={{ width: "100%" }}
                  value={searchValue}
                  onChange={handleSearchChange}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon className="searchIcon" />
                      </InputAdornment>
                    ),
                    sx: {
                      borderRadius: "40px",
                      height: "48px",
                      fontSize: "16px",
                      "&:-internal-autofill-selected": {
                        backgroundColor: "transparent !important",
                      },
                    },
                  }}
                ></TextField>
              </Box>

              {loading ? (
                <LoadingIndicator />
              ) : (
                <Box>
                  <NotificationsList
                    showMarkAllAsRead={true}
                    notificationTitle={i18next.t("All")}
                    user={user}
                    readAllNotifications={handleReadAllNotifications}
                    updateNotifications={handleUpdateNotification}
                    data={notificationsList}
                  />
                  {total && total > perPage ? (
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        marginTop: "2rem",
                      }}
                    >
                      <Pagination
                        count={totalPages}
                        page={page}
                        onChange={(event, value) => setPage(value)}
                        color="primary"
                      />
                    </Box>
                  ) : null}
                </Box>
              )}
            </Container>
          </ThemeProvider>
        </div>
      </div>
    </div>
  );
}
