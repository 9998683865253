import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import profileService, { removeProfilePhoto } from "./profile_service"; // Adjust this import based on your actual service file
import { User } from "src/types/register.types";
import { storeUser } from "../persist/persist";
import { toast } from "react-toastify";
import i18next from "i18next";

interface InitialStateProfile {
  isLoading: boolean;
  isError: boolean;
  isSuccess: boolean;
  userProfile: User | null;
}

interface UpdateUserProfilePhotoPayload {
  userData: User; // Replace 'User' with the actual type of your user data
  file: File | null;
}

const initialStateProfile: InitialStateProfile = {
  isLoading: false,
  isError: false,
  isSuccess: false,
  userProfile: null,
};

export const updateUser = createAsyncThunk(
  "profile/updateUser",
  async (userData: User, thunkAPI) => {
    try {
      return userData;
    } catch (error) {
      console.log("error", error);
      return thunkAPI.rejectWithValue("Failed to update user profile.");
    }
  }
);

export const updateUserProfile = createAsyncThunk(
  "profile/updateUserProfile",
  async (userData: User, thunkAPI) => {
    try {
      const response = await profileService.updateUserProfile(userData);
      if (response && response.data) {
        storeUser("victorumuser", JSON.stringify(response.data));
      }
      toast.success(i18next.t("Successfully updated personal details"));

      return response.data;
    } catch (error) {
      console.log("error", error);
      return thunkAPI.rejectWithValue("Failed to update user profile.");
    }
  }
);

export const updateUserProfilePhoto = createAsyncThunk<
  User,
  UpdateUserProfilePhotoPayload,
  {}
>("profile/updateUserProfilePhoto", async ({ userData, file }, thunkAPI) => {
  try {
    const response = await profileService.uploadUserPhoto(
      userData.id.toString(),
      file
    );
    if (response && response.data) {
      storeUser("victorumuser", JSON.stringify(response.data));
    }

    return response.data;
  } catch (error) {
    console.log("error", error);
    return thunkAPI.rejectWithValue("Failed to update user profile photo.");
  }
});

const profileSlice = createSlice({
  name: "profile",
  initialState: initialStateProfile,
  reducers: {
    resetProfileState(state) {
      state.isError = false;
      state.isSuccess = false;
      state.isLoading = false;
      state.userProfile = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(updateUserProfile.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateUserProfile.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.userProfile = action.payload;
      })
      .addCase(updateUser.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.userProfile = action.payload;
      })
      .addCase(updateUserProfile.rejected, (state) => {
        state.isLoading = false;
        state.isError = true;
      })
      .addCase(updateUserProfilePhoto.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateUserProfilePhoto.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.userProfile = action.payload;
      })
      .addCase(updateUserProfilePhoto.rejected, (state) => {
        state.isLoading = false;
        state.isError = true;
      })
      .addCase(removeProfilePhoto.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(removeProfilePhoto.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.userProfile = action.payload; // Updated user data without the photo
      })
      .addCase(removeProfilePhoto.rejected, (state) => {
        state.isLoading = false;
        state.isError = true;
      });
  },
});

export const { resetProfileState } = profileSlice.actions;

export default profileSlice.reducer;
