import { Box, Button } from "@mui/material";
import { useState } from "react";
import imageUrl from "src/assets/images/email-small@2x.webp";
import PriorityYellowIcon from "src/pages/Authentication/Registration/components/PriorityIcon";
import { useAppDispatch } from "src/store";
import { registerEmail, verifyEmail } from "src/store/register/register_slice";
import OTPInput from "./OTPInput";
import registerService from "src/store/register/register_service";
import { loginUser } from "src/store/login/login_slice";
import i18next from "i18next";

interface Props {
  email: string;
  password?: string;
  isEmailValid: boolean;
  isDataFromModal?: boolean;
}

function SecondStep({ email, isEmailValid, isDataFromModal, password }: Props) {
  const dispatch = useAppDispatch();

  const [inputValues, setInputValues] = useState({
    input1: "",
    input2: "",
    input3: "",
    input4: "",
    input5: "",
    input6: "",
  });

  const handleInputChange = (inputId: string, value: number) => {
    setInputValues((prevInputValues) => ({
      ...prevInputValues,
      [inputId]: value,
    }));
  };

  const resendMail = () => {
    if (isDataFromModal) {
      dispatch(loginUser({ email, password }));
    }
    dispatch(registerEmail({ email }));
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    if (isDataFromModal) {
      let data = {
        email: email,
        code:
          inputValues.input1 +
          inputValues.input2 +
          inputValues.input3 +
          inputValues.input4 +
          inputValues.input5 +
          inputValues.input6,
      };
      const response: any = await registerService.verifyEmailAfterChange(data);
      if (response && response?.user && response?.access_token) {
        dispatch(loginUser({ email, password }));
      }
      return;
    }
    const otp =
      inputValues.input1 +
      inputValues.input2 +
      inputValues.input3 +
      inputValues.input4 +
      inputValues.input5 +
      inputValues.input6;

    dispatch(verifyEmail({ email, code: otp }));
  };

  return (
    <div className="initial-screen d-flex ai-center jc-center fx-column ta-center content-container-home">
      <img src={imageUrl} className="mb-24" alt="r" width="150" />

      <h3 className="big-text fw-600 loginTitleFontSize">
        {i18next.t("Please confirm your email address!")}
      </h3>

      <div className="box-warning mt-2rem ta-start">
        <PriorityYellowIcon />

        <h3
          className="small-text mb-0-5rem fw-300 loginSubtitleFontSize"
          style={{
            fontFamily: "Helvetica, sans-serif",
            lineHeight: 1.5,
          }}
        >
          {i18next.t("Follow the link in the email we sent to")}{" "}
          <strong>{email}</strong>. {i18next.t("The email can take up to")}{" "}
          <strong>{i18next.t("1 minute")}</strong>{" "}
          {i18next.t("to arrive, then please")}{" "}
          <strong>{i18next.t("enter the code.")}</strong>{" "}
          {i18next.t("we sent in the message here:")}
        </h3>
      </div>

      <div
        id="otp"
        className="inputs d-flex flex-row justify-content-center mt-2"
        style={{ paddingTop: "24px" }}
        data-autosubmit="true"
      >
        <p className="loginSubtitleFontSize">{i18next.t("or")}</p>
        <OTPInput
          id="input1"
          value={inputValues.input1}
          onValueChange={handleInputChange}
          previousId={null}
          handleSubmit={handleSubmit}
          nextId="input2"
        />
        <OTPInput
          id="input2"
          value={inputValues.input2}
          onValueChange={handleInputChange}
          previousId="input1"
          handleSubmit={handleSubmit}
          nextId="input3"
        />
        <OTPInput
          id="input3"
          value={inputValues.input3}
          onValueChange={handleInputChange}
          previousId="input2"
          handleSubmit={handleSubmit}
          nextId="input4"
        />
        <OTPInput
          id="input4"
          value={inputValues.input4}
          onValueChange={handleInputChange}
          previousId="input3"
          handleSubmit={handleSubmit}
          nextId="input5"
        />

        <OTPInput
          id="input5"
          value={inputValues.input5}
          onValueChange={handleInputChange}
          previousId="input4"
          handleSubmit={handleSubmit}
          nextId="input6"
        />

        <OTPInput
          id="input6"
          value={inputValues.input6}
          onValueChange={handleInputChange}
          previousId="input5"
          handleSubmit={handleSubmit}
          nextId="input7"
        />
      </div>

      <Box sx={{ justifyContent: "space-between", width: "100%" }}>
        <Button
          style={{ flex: 1, marginTop: "2rem" }}
          type="submit"
          variant="contained"
          className="btnsLoginHeight oneRemFontSize"
          sx={{
            color: "#000000",
            backgroundColor: "transparent",
            borderRadius: "24px",
            boxShadow: "none",
            border: "1px solid #000000",
            fontWeight: 600,
            textTransform: "none",
            fontFamily: "Helvetica, sans-serif",
            "&:hover": {
              backgroundColor: "#2A5182",
              color: "white", // Change the text color on hover if needed
            },
          }}
          onClick={handleSubmit}
          disabled={
            !(
              inputValues &&
              inputValues.input1 &&
              inputValues.input2 &&
              inputValues.input3 &&
              inputValues.input4 &&
              inputValues.input5 &&
              inputValues.input6
            )
          }
        >
          {i18next.t("Continue")}
        </Button>

        <Button
          style={{ flex: 1, marginTop: "2rem", marginLeft: 5 }}
          type="submit"
          variant="contained"
          className="btnsLoginHeight oneRemFontSize"
          sx={{
            color: "#000000",
            backgroundColor: "transparent",
            borderRadius: "24px",
            boxShadow: "none",
            border: "1px solid #000000",
            fontWeight: 600,
            textTransform: "none",
            fontFamily: "Helvetica, sans-serif",
            "&:hover": {
              backgroundColor: "#2A5182",
              color: "white", // Change the text color on hover if needed
            },
          }}
          onClick={resendMail}
          disabled={!isEmailValid}
        >
          {i18next.t("Resend email")}
        </Button>
      </Box>
    </div>
  );
}

export default SecondStep;
