import { Box, Button, Typography } from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import "./FiltersDrawer.scss";
import { useState, useEffect } from "react";
import dayjs, { Dayjs } from "dayjs";
import i18next from "src/i18n";

import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

interface FiltersProps {
  selectedFilters: any;
  handleSelectedFilters: (filters: any) => void;
}

function DateFilters({ selectedFilters, handleSelectedFilters }: FiltersProps) {
  const [selectedDatePeriod, setSelectedDatePeriod] = useState<any>(
    selectedFilters && selectedFilters.dates && selectedFilters.dates.datePeriod
      ? selectedFilters.dates.datePeriod
      : null
  );

  const [selectedDateFrom, setSelectedDateFrom] = useState<any>(
    selectedFilters && selectedFilters.dates && selectedFilters.dates.dateFrom
      ? dayjs(selectedFilters.dates.dateFrom)
      : null
  );

  const [selectedDateTo, setSelectedDateTo] = useState<any>(
    selectedFilters && selectedFilters.dates && selectedFilters.dates.dateTo
      ? dayjs(selectedFilters.dates.dateTo)
      : null
  );

  useEffect(() => {
    if (
      selectedFilters &&
      selectedFilters.dates &&
      selectedFilters.dates.datePeriod
    ) {
      setSelectedDatePeriod(selectedFilters.dates.datePeriod);
    } else {
      setSelectedDatePeriod(null);
    }
    if (
      selectedFilters &&
      selectedFilters.dates &&
      selectedFilters.dates.dateFrom
    ) {
      setSelectedDateFrom(dayjs(selectedFilters.dates.dateFrom));
    } else {
      setSelectedDateFrom(null);
    }
    if (
      selectedFilters &&
      selectedFilters.dates &&
      selectedFilters.dates.dateTo
    ) {
      setSelectedDateTo(dayjs(selectedFilters.dates.dateTo));
    } else {
      setSelectedDateTo(null);
    }
  }, [selectedFilters]);

  const handleTogglePeriod = (period: string) => {
    let dateFrom = null;
    let dateTo = null;
    let datePeriod = null;

    switch (period) {
      case "Last month":
        datePeriod = period;
        dateFrom = dayjs().subtract(1, "month").startOf("month");
        dateTo = dayjs().subtract(1, "month").endOf("month");
        break;
      case "Last quarter":
        datePeriod = period;
        const endOfPreviousMonth = dayjs().subtract(1, "month").endOf("month");
        dateFrom = endOfPreviousMonth.subtract(2, "months").startOf("month");
        dateTo = endOfPreviousMonth;
        break;
      case "Last year":
        datePeriod = period;
        dateFrom = dayjs().subtract(1, "year").startOf("year");
        dateTo = dayjs().subtract(1, "year").endOf("year");
        break;
      default:
        break;
    }

    if (datePeriod && dateFrom && dateTo) {
      const isValidDateFrom = dateFrom.isValid();
      const isValidDateTo = dateTo.isValid();

      if (isValidDateFrom && isValidDateTo) {
        //setSelectedDateFrom(dateFrom);
        //setSelectedDateTo(dateTo);
        // setSelectedDatePeriod(datePeriod);

        handleSelectedFilters({
          ...selectedFilters,
          dates: {
            dateFrom: dayjs(dateFrom).format("YYYY-MM-DD"),
            dateTo: dayjs(dateTo).format("YYYY-MM-DD"),
            datePeriod,
          },
        });
      } else {
        console.error("Invalid dateFrom or dateTo");
      }
    } else {
      setSelectedDatePeriod(null);
    }
  };

  const handleDateFromChange = (date: Dayjs | null) => {
    if (date && date.isAfter(selectedDateTo)) {
      console.error("Date from must not be after date to");
      return; // Do not update if the new date from is after the date to
    }

    if (date) {
      const formattedDate = dayjs(date);
      const isValidDate = formattedDate.isValid();
      if (isValidDate) {
        setSelectedDateFrom(formattedDate); // Update state only if date is valid
        handleSelectedFilters({
          ...selectedFilters,
          dates: {
            ...selectedFilters.dates,
            dateFrom: formattedDate.format("YYYY-MM-DD"),
            datePeriod: null, // Reset the date period since a specific date range is being set
          },
        });
      }
    }
  };

  const handleDateToChange = (date: Dayjs | null) => {
    if (date && date.isBefore(selectedDateFrom)) {
      console.error("Date to must not be before date from");
      return; // Do not update if the new date to is before the date from
    }

    if (date) {
      const formattedDate = dayjs(date);
      const isValidDate = formattedDate.isValid();
      if (isValidDate) {
        setSelectedDateTo(formattedDate); // Update state only if date is valid
        handleSelectedFilters({
          ...selectedFilters,
          dates: {
            ...selectedFilters.dates,
            dateTo: formattedDate.format("YYYY-MM-DD"),
            datePeriod: null, // Reset the date period since a specific date range is being set
          },
        });
      }
    }
  };

  return (
    <Box>
      <Typography className="subBodies">{i18next.t("Date")}</Typography>
      <Box className="lastPeriodBtns">
        <Button
          onClick={() => handleTogglePeriod("Last month")}
          className={`lastPeriodBtn ${
            selectedDatePeriod && selectedDatePeriod === "Last month"
              ? "lastPeriodBtnActive"
              : ""
          }`}
        >
          {i18next.t("Last month")}
        </Button>

        <Button
          onClick={() => handleTogglePeriod("Last quarter")}
          className={`lastPeriodBtn ${
            selectedDatePeriod && selectedDatePeriod === "Last quarter"
              ? "lastPeriodBtnActive"
              : ""
          }`}
        >
          {i18next.t("Last quarter")}
        </Button>

        <Button
          onClick={() => handleTogglePeriod("Last year")}
          className={`lastPeriodBtn ${
            selectedDatePeriod && selectedDatePeriod === "Last year"
              ? "lastPeriodBtnActive"
              : ""
          }`}
        >
          {i18next.t("Last year")}
        </Button>
      </Box>
      <Box className="datePickersMain">
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            name="date_from"
            label={i18next.t("Date from")}
            value={selectedDateFrom ? selectedDateFrom : null}
            onChange={handleDateFromChange}
            shouldDisableDate={(day) =>
              Boolean(selectedDateTo && day.isAfter(selectedDateTo))
            }
          />
        </LocalizationProvider>

        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            name="date_to"
            label={i18next.t("Date to")}
            value={selectedDateTo ? selectedDateTo : null}
            onChange={handleDateToChange}
            shouldDisableDate={(day) =>
              Boolean(selectedDateFrom && day.isBefore(selectedDateFrom))
            }
          />
        </LocalizationProvider>
      </Box>
    </Box>
  );
}

export default DateFilters;
