import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { useNavigate, useParams } from "react-router-dom";
import Nav from "src/components/layout/nav/Nav";
import { MenuItem, Select, SelectChangeEvent } from "@mui/material";
import { useFormik } from "formik";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import api from "src/store/interceptors/api";
import { toast } from "react-toastify";
import { useEffect, useState } from "react";
import ControlPointOutlinedIcon from "@mui/icons-material/ControlPointOutlined";
import "./Branches.scss";

const defaultTheme = createTheme();

interface DynamicField {
  key: string;
  value: string;
}

export default function AddBranch() {
  const { branchId }: any = useParams();
  const [branchDetails, setBranchDetails] = useState<any>(null);

  const [loading, setLoading] = useState(false);
  const [dynamicFields, setDynamicFields] = useState<DynamicField[]>([]);
  const [type, setType] = useState<string>("");

  const navigate = useNavigate();

  const handleNavigate = () => {
    navigate(-1);
  };

  const fetchBranchDetails = async () => {
    setLoading(true);
    try {
      const response = await api.get(`/branches/${branchId}`);
      setBranchDetails(response?.data?.data);

      if (response?.data?.data?.bank_details?.[0]?.type) {
        setType(response?.data?.data?.bank_details?.[0]?.type);
      }
    } catch (error: any) {}
    setLoading(false);
  };

  const branchAddID =
    branchDetails &&
    branchDetails.bank_details &&
    branchDetails.bank_details[0] &&
    branchDetails.bank_details[0].branch_id;

  const formik = useFormik({
    initialValues: {
      type: type,
      branch_id: branchAddID ? branchAddID : 1,
      bank_detail_meta: [],
    },
    onSubmit: async (values: any) => {
      const { type, branch_id, bank_detail_meta } = values;

      try {
        const payload = {
          type,
          branch_id,
          bank_detail_meta: [
            ...bank_detail_meta,
            ...dynamicFields.filter(
              (field) => field.key !== "" || field.value !== ""
            ),
          ],
        };

        await api.post(`/bank-details`, payload);
        toast.success("Successfully created a new bank details");
        setTimeout(() => {
          navigate(-1);
        }, 1000);
      } catch (error) {
        toast.error("Something went wrong");
      }
    },
  });

  const handleTypeChange = (event: SelectChangeEvent) => {
    const selectedType = event.target.value;
    const oppositeType = selectedType === "Local" ? "International" : "Local";
    setType(oppositeType);
    formik.setFieldValue("type", oppositeType);
  };

  const handleAddField = () => {
    const newField = { key: "", value: "" };
    const updatedFields = [...dynamicFields, newField];
    setDynamicFields(updatedFields);
  };

  useEffect(() => {
    if (branchId) {
      fetchBranchDetails();
    }
  }, [branchId]);
  useEffect(() => {
    formik.setValues({
      type: type === "Local" ? "International" : "Local",
      branch_id: branchAddID ? branchAddID : 1,
      bank_detail_meta: [],
    });
  }, [branchAddID, type]);

  return (
    <>
      {
        <div>
          <Nav step={0} isStepperVisible={false} />
          <div className="content-container">
            <ThemeProvider theme={defaultTheme}>
              <Container component="main">
                <Box
                  component="form"
                  onSubmit={formik.handleSubmit}
                  noValidate
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <div className="d-flex ai-center mb-1-rem">
                    <p
                      className="back-button-icon circle-around-text-smaller helvetica mr-1-rem cp"
                      onClick={handleNavigate}
                    >
                      <ArrowBackIcon className="muiIcons" />
                    </p>
                    <Typography
                      className="ta-center pageTitleFontSize"
                      component="h1"
                      variant="h5"
                      sx={{
                        fontFamily: "Helvetica, sans-serif",
                        fontWeight: "600",
                        color: "#0e0f0c",
                      }}
                    >
                      Create a payment method
                    </Typography>
                  </div>

                  <Box sx={{ mt: "4rem" }}>
                    <div className="label-and-field mb-1rem">
                      <Typography
                        className="d-flex jc-start registration0875RemFontSize"
                        component="h1"
                        variant="h5"
                        sx={{
                          fontFamily: "Helvetica, sans-serif",
                          fontWeight: "300",
                          color: "#454745",
                          marginBottom: "8px",
                        }}
                      >
                        Type
                      </Typography>

                      <Select
                        id="type"
                        name="type"
                        placeholder="Select type"
                        value={type === "Local" ? "International" : "Local"}
                        fullWidth
                        onChange={handleTypeChange}
                        sx={{
                          borderRadius: "10px",
                          height: "48px",
                          fontSize: "16px",
                          "&:-internal-autofill-selected": {
                            backgroundColor: "transparent !important",
                          },
                          "@media (max-width:600px)": {
                            fontSize: "14px !important",
                          },
                        }}
                      >
                        <MenuItem value={"Local"} disabled={type === "Local"}>
                          Local
                        </MenuItem>
                        <MenuItem
                          value={"International"}
                          disabled={type === "International"}
                        >
                          International
                        </MenuItem>
                      </Select>
                    </div>
                    {dynamicFields.map((field, index) => (
                      <div key={index} className="addedFieldsBoxAdd">
                        <TextField
                          placeholder={`Title (etc. IBAN)`}
                          value={field.key}
                          fullWidth
                          InputProps={{
                            sx: {
                              borderRadius: "10px",
                              height: "48px",
                              fontSize: "16px",
                              "&:-internal-autofill-selected": {
                                backgroundColor: "transparent !important",
                              },
                              "@media (max-width:600px)": {
                                fontSize: "14px !important",
                              },
                            },
                          }}
                          onChange={(e) => {
                            const updatedFields = [...dynamicFields];
                            updatedFields[index].key = e.target.value;
                            setDynamicFields(updatedFields);
                          }}
                        />
                        <TextField
                          placeholder={`Value (etc.DE75512108001245126199)`}
                          value={field.value}
                          fullWidth
                          InputProps={{
                            sx: {
                              borderRadius: "10px",
                              height: "48px",
                              fontSize: "16px",
                              "&:-internal-autofill-selected": {
                                backgroundColor: "transparent !important",
                              },
                              "@media (max-width:600px)": {
                                fontSize: "14px !important",
                              },
                            },
                          }}
                          onChange={(e) => {
                            const updatedFields = [...dynamicFields];
                            updatedFields[index].value = e.target.value;
                            setDynamicFields(updatedFields);
                          }}
                        />
                      </div>
                    ))}
                    <div className="label-and-field mb-1rem">
                      <Button
                        className="addDetailsBtn oneRemFontSize btnsLoginHeight"
                        onClick={handleAddField}
                        startIcon={
                          <ControlPointOutlinedIcon className="muiIcons" />
                        }
                      >
                        Add Details
                      </Button>
                    </div>
                  </Box>

                  <Button
                    fullWidth
                    variant="contained"
                    type="submit"
                    className="btnsLoginHeight oneRemFontSize"
                    sx={{
                      color: "white",
                      backgroundColor: "#2A5182",
                      borderRadius: "24px",
                      textTransform: "none",
                      marginTop: "2rem",
                      fontFamily: "Helvetica, sans-serif",
                      marginBottom: "4rem",
                    }}
                  >
                    Save
                  </Button>
                </Box>
              </Container>
            </ThemeProvider>
          </div>
        </div>
      }
    </>
  );
}
