import Typography from "@mui/material/Typography";
import moment from "moment";
import "../../pages/RecipientDetails/RecipientDetails.scss";
import i18next from "i18next";

const RecipientDetailsBasic = ({ recipientDetails }: any) => {
  return (
    <>
      {recipientDetails && recipientDetails.account_holder && (
        <div className="keyAndValueMain">
          <div>
            <Typography className="mb-1rem  d-flex jc-start keys" variant="h5">
              {i18next.t("Account Holder Name")}
            </Typography>
          </div>
          <div>
            <Typography
              className="mb-1rem  d-flex jc-start values"
              variant="h5"
            >
              {" "}
              {recipientDetails?.account_holder}
            </Typography>
          </div>
        </div>
      )}
      {recipientDetails && recipientDetails.created_at && (
        <div className="keyAndValueMain">
          <div>
            <Typography className="mb-1rem  d-flex jc-start keys" variant="h5">
              {i18next.t("Created At")}
            </Typography>
          </div>
          <div>
            <Typography
              className="mb-1rem  d-flex jc-start values"
              variant="h5"
            >
              {" "}
              {recipientDetails?.created_at
                ? moment(recipientDetails.created_at).format("DD/MM/YYYY")
                : ""}
            </Typography>
          </div>
        </div>
      )}
      {recipientDetails && recipientDetails.currency && (
        <div className="keyAndValueMain">
          <div>
            <Typography className="mb-1rem  d-flex jc-start keys" variant="h5">
              {i18next.t("Currency")}
            </Typography>
          </div>
          <div>
            <Typography
              className="mb-1rem  d-flex jc-start values"
              variant="h5"
            >
              {" "}
              {recipientDetails?.currency}
            </Typography>
          </div>
        </div>
      )}
      {recipientDetails &&
      recipientDetails.iban &&
      recipientDetails.type === "iban" ? (
        <div className="keyAndValueMain">
          <div>
            <Typography className="mb-1rem  d-flex jc-start keys" variant="h5">
              IBAN
            </Typography>
          </div>
          <div>
            <Typography
              className="mb-1rem  d-flex jc-start values"
              variant="h5"
            >
              {" "}
              {recipientDetails?.iban}
            </Typography>
          </div>
        </div>
      ) : null}
      {recipientDetails &&
      recipientDetails.account_number &&
      recipientDetails.type === "sort_code" ? (
        <div className="keyAndValueMain">
          <div>
            <Typography className="mb-1rem  d-flex jc-start keys" variant="h5">
              {i18next.t("Account Number")}
            </Typography>
          </div>
          <div>
            <Typography
              className="mb-1rem  d-flex jc-start values"
              variant="h5"
            >
              {" "}
              {recipientDetails?.account_number}
            </Typography>
          </div>
        </div>
      ) : null}
      {recipientDetails &&
      recipientDetails.sort_code &&
      recipientDetails.type === "sort_code" ? (
        <div className="keyAndValueMain">
          <div>
            <Typography className="mb-1rem  d-flex jc-start keys" variant="h5">
              {i18next.t("Sort Code")}
            </Typography>
          </div>
          <div>
            <Typography
              className="mb-1rem  d-flex jc-start values"
              variant="h5"
            >
              {" "}
              {recipientDetails?.sort_code}
            </Typography>
          </div>
        </div>
      ) : null}
    </>
  );
};
export default RecipientDetailsBasic;
