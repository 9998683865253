import { Button } from "@mui/material";
import i18next from "i18next";
import emailConfirmedImage from "src/assets/images/check-mark-small@2x.webp";
interface Props {
  handleContinueClick: () => void;
  isEmailValid: boolean;
}
function ThirdStep({ handleContinueClick, isEmailValid }: Props) {
  return (
    <div className="initial-screen d-flex ai-center jc-center fx-column ta-center content-container-home">
      <img src={emailConfirmedImage} className="mb-24" alt="r" width="150" />

      <h3 className="big-text fw-600 loginTitleFontSize">
        {i18next.t("Thanks - your email is confirmed!")}
      </h3>

      <p
        className="small-text fw-300 mt-1-5rem letter-spacing-66 oneRemFontSize"
        style={{ fontFamily: "Helvetica, sans-serif" }}
      >
        {i18next.t(
          "Please click the continue button below to open you your account."
        )}
      </p>

      <Button
        type="submit"
        fullWidth
        variant="contained"
        className="btnsLoginHeight oneRemFontSize"
        sx={{
          color: "white",
          backgroundColor: "#2A5182",
          borderRadius: "24px",
          minWidth: "33rem",
          marginTop: "2rem",
          textTransform: "none",
          fontFamily: "Helvetica, sans-serif",
        }}
        onClick={handleContinueClick}
        disabled={!isEmailValid}
      >
        {i18next.t("Continue")}
      </Button>
    </div>
  );
}

export default ThirdStep;
