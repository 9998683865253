import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import { Typography } from "@mui/material";

interface Props {
  open: boolean;
  handleClose: () => void;
  handleConfirm: (e: any) => void;
  content: {
    title: string;
    body: string;
    cancelText: string;
    confirmText: string;
  };
  className?: string;
}

const ConfirmationDialog = ({
  open,
  handleClose,
  handleConfirm,
  content,
  className,
}: Props) => {
  return (
    <Dialog
      className={className}
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        <Typography
          component="h1"
          variant="h5"
          sx={{
            fontFamily: "Helvetica, sans-serif",
            fontSize: "1.125rem",
            fontWeight: "600",
            color: "#454745",
            marginBottom: "1rem",
          }}
        >
          {content.title}
        </Typography>
        <div className="horizontal-line-list mb-8"></div>
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          <Typography
            component="h1"
            variant="h5"
            sx={{
              fontFamily: "Helvetica, sans-serif",
              fontSize: "0.875rem",
              fontWeight: "200",
              color: "#454745",
              marginBottom: "1.5rem",
            }}
          >
            {content.body}
          </Typography>
          <div className="horizontal-line-list"></div>
        </DialogContentText>
      </DialogContent>
      <DialogActions className="" sx={{ flexDirection: 'column', marginBottom: '1rem' }}>
   {/* Add marginBottom for spacing */}
    <Button
      type="submit"
      fullWidth
      variant="contained"
      className="button-reject-modal"
      sx={{
        borderRadius: "20px",
        height: "3rem",
        textTransform: "none",
        boxShadow: "none",
        fontSize: "0.9rem",
        width: "95%",
      }}
      onClick={handleClose}
    >
      {content.cancelText}
    </Button>
  
    <Button
      type="submit"
      fullWidth
      variant="contained"
      onClick={handleConfirm}
      className="button-confirm-modal"
      sx={{
        borderRadius: "20px",
        height: "3rem",
        boxShadow: "none",
        fontWeight: 600,
        textTransform: "none",
        fontSize: "0.9rem",
        marginTop: "0.5rem",
        width: "95%",
      }}
    >
      {content.confirmText}
    </Button>
  
</DialogActions>

      
    </Dialog>
  );
};

export default ConfirmationDialog;
