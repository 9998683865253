import { Typography } from "@mui/material";
import "./TransactionsListItem.scss";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import { useNavigate } from "react-router-dom";
import AddIcon from "@mui/icons-material/Add";
import transactionsService from "src/store/transactions/transactions_service";
import i18next from "i18next";

interface Props {
  item: any;
  redirectPage: string | null;
}

function TransactionListItem({ item, redirectPage }: Props) {
  const navigate = useNavigate();
  let displayText;
  const handleOnClick = () => {
    navigate(`/transaction-details/${item.id}`);
  };

  const renderIcon = (status: string) => {
    const iconType = transactionsService.getProperIconBasedOnStatus(status);
    switch (iconType) {
      case "up":
        displayText = item?.recipient?.account_holder;
        return (
          <ArrowUpwardIcon style={{ color: "#0e0f0c" }} className="muiIcons" />
        );
      // case "plus":
      //    displayText = `To your ${item?.meta?.targetCurrency} balance`;
      //    return <AddIcon style={{ color: "#0e0f0c" }} />;
      default:
        return (
          <ArrowUpwardIcon style={{ color: "#0e0f0c" }} className="muiIcons" />
        );
    }
  };

  return (
    <div className="transaction-item" onClick={handleOnClick}>
      <div className="d-flex ai-center jc-center">
        <p className="back-button-icon circle-around-text-smaller helvetica mr-1-rem">
          {renderIcon(item.status)}{" "}
        </p>
        <div>
          <Typography
            component="h1"
            variant="h5"
            className="oneRemFontSize subtitleMaxWidthMob"
            sx={{
              fontFamily: "Helvetica, sans-serif",
              fontWeight: "600",
              color: "#454745",
              marginBottom: "0.5rem",
            }}
          >
            {displayText}
            {/* {item?.recipient?.account_holder} */}
          </Typography>
          <Typography
            component="h1"
            className="registration0875RemFontSize subtitleMaxWidthMob"
            variant="h5"
            sx={{
              fontFamily: "Helvetica, sans-serif",
              fontWeight: "300",
              color: "#454745",
            }}
          >
            {i18next.t(transactionsService.translateStatus(item.status))}
          </Typography>
        </div>
      </div>
      <div className="ta-end">
        <Typography
          component="h1"
          className="oneRemFontSize subtitleMaxWidthMob"
          variant="h5"
          sx={{
            fontFamily: "Helvetica, sans-serif",
            fontWeight: "600",
            color: "#454745",
            marginBottom: "0.5rem",
          }}
        >
          {transactionsService.formatNumber(item?.meta?.targetValue)}{" "}
          {item?.meta?.targetCurrency}
        </Typography>
        <Typography
          component="h1"
          variant="h5"
          className="registration0875RemFontSize"
          sx={{
            fontFamily: "Helvetica, sans-serif",
            fontWeight: "300",
            color: "#454745",
          }}
        >
          {item.amount_sent && parseFloat(item.amount_sent) > 0
            ? transactionsService.formatNumber(item.amount_sent)
            : transactionsService.formatNumber(
                item?.transfer?.invoiceAmount
              )}{" "}
          {item?.meta?.sourceCurrency}
        </Typography>
      </div>
    </div>
  );
}

export default TransactionListItem;
