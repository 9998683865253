import { Modal, Box, Typography } from "@mui/material";
import i18next from "i18next";

interface ReusableModalProps {
  open: boolean;
  onClose: () => void; // Changed to a more specific type for better type safety
  onPhotoChange: any; // Changed to a more specific type
  onPhotoRemove: any; // Changed to a more specific type
}

const ReusableModal = ({
  open,
  onClose,
  onPhotoChange,
  onPhotoRemove,
}: ReusableModalProps) => {
  const style1 = {
    position: "absolute" as "absolute", // Added as 'absolute' to avoid TypeScript errors
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
    // ... Add other styles you have in your original modal's Box component
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style1}>
        <Typography
          id="modal-modal-title"
          variant="h6"
          component="h2"
          fontSize={"0.875rem"}
          sx={{
            textDecoration: "underline",
            cursor: "pointer",
            marginBottom: "1rem",
          }}
          onClick={() => {
            onPhotoChange();
            onClose();
          }}
        >
          {i18next.t("Change photo")}
        </Typography>
        <Typography
          id="modal-modal-title"
          variant="h6"
          component="h2"
          fontSize={"0.875rem"}
          sx={{
            textDecoration: "underline",
            cursor: "pointer",
          }}
          onClick={() => {
            onPhotoRemove();
            onClose();
          }}
        >
          {i18next.t("Remove photo")}
        </Typography>
      </Box>
    </Modal>
  );
};

export default ReusableModal;
