// import { useFormik } from "formik";
import {
  Button,
  TextField,
  Autocomplete,
  Box,
  InputAdornment,
  Select,
  MenuItem,
  Typography,
  FormControl,
  InputLabel,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import api from "src/store/interceptors/api";
import BTC from "../../../../assets/images/btclogo.png";
import ETH from "../../../../assets/images/ethlogo.png";
import transactionsService from "src/store/transactions/transactions_service";
import i18next from "i18next";

interface CreateCryptoFormProps {
  onSubmit: (amount: any, currency: any, feeData: any) => void;
}

interface ExchangeData {
  from_currency: string;
  to_currency: string;
  exchange_rate: any;
  crypto_amount: any;
}

const getFee = (amount: any) => {
  if (amount) {
    let feePercentage = 1.1 / 100;
    let fee = parseFloat(amount) * feePercentage;
    return { fee, feePercentage };
  }
  return { fee: 0, feePercentage: 0 };
};
const getCurrencyFlag = (currencyCode: any) => {
  if (currencyCode === "BTC") {
    return (
      <img style={{ width: "100%", maxWidth: 25 }} src={BTC} alt="BTC Flag" />
    );
  } else if (currencyCode === "ETH") {
    return (
      <img style={{ width: "100%", maxWidth: 25 }} src={ETH} alt="ETH Flag" />
    );
  }
};

const currencyList = ["BTC", "ETH"];
const CreateCryptoForm: React.FC<CreateCryptoFormProps> = ({ onSubmit }) => {
  const [base, setBase] = useState("BTC");
  const handleBaseChange = (event: any) => {
    const newBase = event.target.value as string;
    setBase(newBase);
  };

  const [currencies, setCurrencies] = useState<any>(null);
  const [amount, setAmount] = useState("");
  const [currency, setCurrency] = useState(
    currencies && currencies[0] ? currencies[0] : null
  );
  const debounceTimeoutRef = useRef<any>(null);

  const [loading, setLoading] = useState(false);
  const [exchangeData, setExchangeData] = useState<ExchangeData | null>(null);
  const [error, setError] = useState("");

  const handleSubmit = (e: any) => {
    e.preventDefault();
    const feeData = getFee(amount);
    const total = parseFloat(amount) + feeData.fee;
    onSubmit(total, currency, feeData);
  };

  const getInitialData = async () => {
    let res = [
      {
        id: 2,
        currency: "EUR",
        amount: { value: 0, currency: "EUR" },
        reservedAmount: { value: 0, currency: "EUR" },
        cashAmount: { value: 0, currency: "EUR" },
        totalWorth: { value: 0, currency: "EUR" },
        type: "STANDARD",
        name: null,
        icon: null,
        investmentState: "NOT_INVESTED",
        creationTime: "2023-12-20T00:08:27.808943Z",
        modificationTime: "2024-01-25T21:18:41.631046Z",
        visible: true,
        primary: true,
        groupId: null,
      },
    ];
    setCurrencies(res);
    setCurrency(res[0]);

    /*
    await api
      .get("/profile/balances")
      .then((response: any) => {
        console.log(response.data);
        setCurrencies(response.data);
        setCurrency(
          response && response.data && response.data[0]
            ? response.data[0]
            : null
        );
      })
      .catch((error) => {
        console.error(error.message);
      });
      */
  };

  useEffect(() => {
    getInitialData();
  }, []);

  const handleAmountChange = (event: any) => {
    setLoading(true);
    const value = event.target.value;
    if (/^[0-9]*\.?[0-9]*$/.test(value)) {
      clearTimeout(debounceTimeoutRef.current);
      setAmount(value);
    }
  };

  const handleCurrencyChange = (event: any, newValue: any) => {
    setCurrency(newValue);
  };

  useEffect(() => {
    if (debounceTimeoutRef.current) {
      clearTimeout(debounceTimeoutRef.current);
    }

    debounceTimeoutRef.current = window.setTimeout(() => {
      const fetchExchangeRate = async () => {
        if (amount && currency && currency.currency) {
          setLoading(true);
          setError("");

          try {
            const feeData = getFee(amount);
            const total = parseFloat(amount) + feeData.fee;
            const response = await api.get(
              `/crypto-exchange-rate?crypto_currency=${base}&currency=${currency.currency}&amount=${total}`
            );
            setExchangeData(response.data);
          } catch (err) {
            setError("Failed to fetch exchange data: " + err);
            console.error("Error fetching exchange rate:", err);
          } finally {
            setLoading(false);
          }
        }
      };

      fetchExchangeRate();
    }, 500);
    return () => {
      if (debounceTimeoutRef.current) {
        clearTimeout(debounceTimeoutRef.current);
      }
    };
  }, [amount, currency]);

  const feeRes = getFee(amount);
  const totalWithFee = feeRes.fee + parseFloat(amount);
  return (
    <div>
      <form>
        <div
          className=""
          style={{ display: "flex", flexDirection: "column", gap: 10 }}
        >
          <FormControl fullWidth style={{}}>
            <InputLabel>{i18next.t("Base Currency")}</InputLabel>
            <Select
              fullWidth
              label={i18next.t("Base Currency")}
              value={base}
              onChange={handleBaseChange}
              sx={{
                minWidth: "120px",
                minHeight: "48px",
                borderRadius: "10px",
                "@media (max-width: 600px)": {
                  "& .MuiSelect-select": {
                    fontSize: "14px",
                  },
                },
              }}
              MenuProps={{
                anchorOrigin: {
                  vertical: "bottom",
                  horizontal: "left",
                },
                transformOrigin: {
                  vertical: "top",
                  horizontal: "left",
                },
              }}
            >
              {currencyList.map((currency: any) => (
                <MenuItem key={"base" + currency} value={currency}>
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    {getCurrencyFlag(currency)}{" "}
                    <Typography sx={{ paddingLeft: 2 }} variant="body1">
                      {currency}
                    </Typography>
                  </Box>
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          {/* btc end */}

          <TextField
            style={{ width: "100%" }}
            type="text"
            name="amount"
            placeholder={i18next.t("Enter amount")}
            value={amount}
            onChange={handleAmountChange}
            variant="outlined"
            sx={{
              "& .MuiOutlinedInput-root": {
                paddingRight: "0px!important",
              },
            }}
            InputProps={{
              sx: {
                borderRadius: "10px",
                minHeight: "48px",
                "@media (max-width:600px)": {
                  fontSize: "14px",
                },
              },
              endAdornment: (
                <InputAdornment position="end">
                  <Autocomplete
                    id="currency-select"
                    sx={{
                      width: "11rem",
                      borderRadius: "10px",
                      "& .MuiOutlinedInput-root": {
                        border: "none",
                      },
                      "& .Mui-focused .MuiOutlinedInput-root": {
                        border: "none",
                      },
                      "& .MuiOutlinedInput-notchedOutline": {
                        border: "none",
                      },
                    }}
                    options={currencies}
                    value={currency}
                    aria-placeholder="Select"
                    getOptionLabel={(option) => option.currency}
                    onChange={handleCurrencyChange}
                    renderOption={(props, option) => (
                      <Box
                        component="li"
                        {...props}
                        className="d-flex ai-center mt-1-rem cp"
                      >
                        <p className="normal-text" style={{ marginLeft: 10 }}>
                          {option.currency}
                        </p>
                      </Box>
                    )}
                    renderInput={(params) => <TextField {...params} />}
                    clearIcon={null}
                  />
                </InputAdornment>
              ),
            }}
          />
        </div>

        {/*  currency start  */}
        {exchangeData && (
          <div className="fee-info fee-info-text mt-16 mb-1rem helvetica">
            {amount && parseFloat(amount) < 1000 && (
              <div className="d-flex ai-center jusifyContentSpaceBetween mt-12 mb-12">
                <div className="d-flex ai-center">
                  <Typography
                    className="amountsInfoTexts"
                    style={{ color: loading ? "grey" : "red" }}
                  >
                    <strong>{i18next.t("Min currency amount 1000 EUR")}</strong>
                  </Typography>
                </div>
              </div>
            )}
            <div className="d-flex ai-center jusifyContentSpaceBetween">
              <div className="d-flex ai-center">
                <Typography className="minEqX">+</Typography>
                <Typography
                  className="amountsInfoTexts"
                  style={{ color: loading ? "grey" : "black" }}
                >
                  <strong>
                    {transactionsService.formatNumber(feeRes?.fee)} EUR
                  </strong>
                </Typography>
              </div>
              <div className="d-flex ai-center">
                <Typography className="transferInfoTexts">Fee</Typography>
              </div>
            </div>

            <div className="d-flex ai-center jusifyContentSpaceBetween mt-12">
              <div className="d-flex ai-center">
                <Typography className="minEqX">=</Typography>
                <Typography
                  className="amountsInfoTexts underline"
                  style={{ color: loading ? "grey" : "black" }}
                >
                  <strong>
                    {transactionsService.formatNumber(totalWithFee)} EUR
                  </strong>
                </Typography>
              </div>
              <Typography className="transferInfoTexts">
                {i18next.t("Amount to convert")}
              </Typography>
            </div>

            <div className="d-flex ai-center jusifyContentSpaceBetween mt-12">
              <div className="d-flex ai-center">
                <Typography className="minEqX">=</Typography>
                <Typography
                  className="amountsInfoTexts underline"
                  style={{ color: loading ? "grey" : "black" }}
                >
                  <strong>{exchangeData?.crypto_amount}</strong>
                </Typography>
              </div>
              <Typography className="transferInfoTexts">
                {i18next.t("Crypto amount to pay")}
              </Typography>
            </div>
          </div>
        )}
        {/* currency end */}

        <div className="d-flex ai-center jusifyContentSpaceBetween mt-8">
          <Button
            type="submit"
            fullWidth
            variant="contained"
            className="btnsLoginHeight oneRemFontSize"
            sx={{
              color: "white",
              backgroundColor: "#2A5182",
              borderRadius: "24px",
              height: "3rem",
              textTransform: "none",
              marginTop: "0.5rem",
              marginBottom: "1rem",
              fontFamily: "Helvetica, sans-serif",
              fontSize: "0.9rem",
            }}
            disabled={
              !currency || loading || amount === "" || parseFloat(amount) < 1000
                ? true
                : false
            }
            onClick={handleSubmit}
          >
            {i18next.t("Continue")}
          </Button>
        </div>
      </form>
    </div>
  );
};

export default CreateCryptoForm;
