import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import "./LoadingCash.scss";
const LoadingCash = () => (
  <div className="loader">
    <div className="loader__image">
      <div className="loader__coin">
        <AttachMoneyIcon />
      </div>
    </div>
  </div>
);

export default LoadingCash;
