import { Box, Button, Typography } from "@mui/material";
import { formatDateTime } from "src/utils/helpers";
import "./AccountRequestsTableList.scss";
import CheckCircleOutline from "@mui/icons-material/CheckCircleOutline";
import api from "src/store/interceptors/api";
import { toast } from "react-toastify";
interface Props {
  item: any;
  index: number;
}

function AccountRequestsTableListItem({ item, index }: Props) {
  const handleRequest = async (type: any, id: any) => {
    try {
      let url = "";
      if (type === "approve") url = "account-approve";
      if (type === "reject") url = "account-reject";
      const res = await api.post(`/user-balance/${url}`, {
        balanceId: id,
      });
      if (res) {
        toast.success(
          res && res.data && res.data.message
            ? res.data.message
            : "Request completed"
        );
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      }
    } catch (error: any) {
      toast.error(
        "There was a problem with your request, please contact VicPay support!"
      );
    }
  };
  return (
    <div className="transaction-item">
      <div className="d-flex ai-center jc-center">
        <Typography
          component="h1"
          variant="h5"
          className="oneRemFontSize"
          sx={{
            fontFamily: "Helvetica, sans-serif",
            fontWeight: "600",
            color: "#454745",
            paddingRight: "1rem",
            marginBottom: "0.5rem",
          }}
        >
          {index + 1}.
        </Typography>
        <div>
          <Typography
            component="h1"
            variant="h5"
            className="oneRemFontSize"
            sx={{
              fontFamily: "Helvetica, sans-serif",
              fontWeight: "600",
              color: "#454745",
              marginBottom: "0.5rem",
            }}
          >
            Account
            {/* {item?.recipient?.account_holder} */}
          </Typography>
          <Typography
            component="h1"
            variant="h5"
            className="registration0875RemFontSize"
            sx={{
              fontFamily: "Helvetica, sans-serif",
              fontWeight: "300",
              color: "#454745",
            }}
          >
            {item?.currency?.code ?? "/"}
          </Typography>
        </div>
      </div>
      <div className="ta-end">
        <Typography
          component="h1"
          variant="h5"
          className="oneRemFontSize"
          sx={{
            fontFamily: "Helvetica, sans-serif",
            fontWeight: "600",
            color: "#454745",
            marginBottom: "0.5rem",
          }}
        >
          Status
        </Typography>
        <Typography
          component="h1"
          variant="h5"
          className="registration0875RemFontSize"
          sx={{
            fontFamily: "Helvetica, sans-serif",
            fontWeight: "300",
            color: "#454745",
          }}
        >
          {item.requested_account_date &&
            !item.approved_account_date &&
            !item.rejected_account_date && (
              <Box>
                <Button
                  variant="contained"
                  className="oneRemFontSize btnsLoginHeight"
                  onClick={() => handleRequest("approve", item.id)}
                  sx={{
                    color: "#fff",
                    borderRadius: "24px",
                    boxShadow: "none",
                    cursor: "pointer",
                    border: "1px solid #000000",
                    fontWeight: 400,
                    textTransform: "none",
                    fontFamily: "Helvetica, sans-serif",
                    "&:hover": {
                      backgroundColor: "#2A5182",
                      color: "white",
                    },
                  }}
                >
                  Approve{" "}
                  <CheckCircleOutline
                    className="muiIcons"
                    style={{ paddingLeft: 5, color: "#ffffff" }}
                  />
                </Button>
                <Button
                  className="oneRemFontSize btnsLoginHeight"
                  variant="contained"
                  onClick={() => handleRequest("reject", item.id)}
                  sx={{
                    marginLeft: 1,
                    color: "#fff",
                    borderRadius: "24px",
                    backgroundColor: "red",
                    boxShadow: "none",
                    cursor: "pointer",
                    border: "1px solid #000000",
                    fontWeight: 400,
                    textTransform: "none",
                    fontFamily: "Helvetica, sans-serif",
                    "&:hover": {
                      backgroundColor: "red",
                      color: "white",
                    },
                  }}
                >
                  Reject
                </Button>
              </Box>
            )}
          {item.approved_account_date ? "Approved" : ""}
          {item.rejected_account_date ? "Rejected" : ""}
        </Typography>
      </div>
      <div className="ta-end">
        <Typography
          className="oneRemFontSize"
          component="h1"
          variant="h5"
          sx={{
            fontFamily: "Helvetica, sans-serif",
            fontWeight: "600",
            color: "#454745",
            marginBottom: "0.5rem",
          }}
        >
          Time
        </Typography>
        <Typography
          component="h1"
          variant="h5"
          className="registration0875RemFontSize"
          sx={{
            fontFamily: "Helvetica, sans-serif",
            fontWeight: "300",
            color: "#454745",
          }}
        >
          {item?.requested_account_date
            ? formatDateTime(item.requested_account_date)
            : ""}
        </Typography>
      </div>
    </div>
  );
}

export default AccountRequestsTableListItem;
