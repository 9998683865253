import React from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Typography, Box } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "src/store";
import HeaderLanding from "../components/HeaderLanding/HeaderLanding";
import FooterLanding from "../components/FooterLanding/FooterLanding";
import TitleBackBox from "../components/TitleBackBox/TitleBackBox";
import HeadlineVvidgetSmall from "../components/HeadlineVvidget/HeadlineVvidgetSmall";
import i18next from "i18next";

const defaultTheme = createTheme();

const PrivacyPolicy: React.FC = () => {
  const { user } = useAppSelector((state) => state.auth);

  const navigate = useNavigate();

  const handleNavigateContact = () => {
    navigate("/contact-us");
  };
  const sendEmail = (email: any) => {
    const mailtoUrl = `mailto:${email}`;
    window.location.href = mailtoUrl;
  };

  return (
    <div>
      <HeaderLanding user={user} />
      <div className="margintTopPagesMainCont">
        <ThemeProvider theme={defaultTheme}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Box className="titleHeadlineSmallBG">
              <Box className="landingPagesMainBox">
                <Box>
                  <TitleBackBox title={i18next.t("Privacy Policy")} />
                </Box>
                <Box>
                  <HeadlineVvidgetSmall
                    headline={i18next.t("Keep it underground")}
                    subtitle={""}
                    smallText={i18next.t("In case of any questions")}
                    buttonText={i18next.t("Contact us")}
                    onClickFunction={handleNavigateContact}
                  />
                </Box>
              </Box>
            </Box>
            <Box className="landingPagesMainBox">
              <Typography
                variant="body1"
                paragraph
                className="textsLandingPage"
              >
                {i18next.t(
                  "The information in the fine print on this page is significant"
                )}
              </Typography>
              <Typography
                variant="body1"
                paragraph
                className="textsLandingPage"
              >
                {i18next.t(
                  "VicPay Services Inc. and subsidiaries (“Victorum”, “we”, or “us”) welcome you to our home page. We are delighted that you are using our mobile apps and e-services, and that you are interested in our products and services"
                )}
              </Typography>
              <Typography
                variant="body1"
                paragraph
                className="textsLandingPage"
              >
                {i18next.t(
                  "VicPay Services Inc.’s commitment to confidentiality is fundamental to doing business and supporting our investors and clients and forms the basis of trusting relationships in successful business communications"
                )}
              </Typography>
              <Typography
                variant="body1"
                paragraph
                className="textsLandingPage"
              >
                {i18next.t(
                  "In a world where data breaches, hacking and phishing attacks are on the rise, we pay great attention to protecting your privacy and your personal data"
                )}
              </Typography>
              <Typography
                variant="body1"
                paragraph
                className="textsLandingPage"
              >
                {i18next.t(
                  "We understand that by using our services and technologies, you are entrusting us with personal information about yourself. For this reason, we certify that we are aware of our responsibility for the protection and security of such data"
                )}
              </Typography>

              <Typography
                variant="body1"
                paragraph
                className="textsLandingPage"
              >
                {i18next.t(
                  "We can also guarantee that our company will always use your personal data in a reliable and fair manner, in compliance with all relevant data protection regulations. More information can be found in the Data Protection Declaration"
                )}
              </Typography>
              <Typography
                variant="body1"
                paragraph
                className="textsLandingPage"
              >
                {i18next.t(
                  "We guarantee that we will only disclose your personal data to third parties (authorities, tax authorities, banks, etc.) if this is requested by a court or similar legal ruling"
                )}
              </Typography>
              <Typography
                variant="body1"
                paragraph
                className="textsLandingPage"
              >
                {i18next.t(
                  "You have the right to be accurately informed about how we use your personal data; just click here and we will inform you clearly and in detail about what data about you we store and how we use it"
                )}
              </Typography>
              <Typography
                variant="body1"
                paragraph
                className="textsLandingPage"
              >
                {i18next.t(
                  "If you have any concerns about our use of your personal data, we will be happy to help clarify the issue; simply send us an email to"
                )}{" "}
                <span
                  onClick={() => sendEmail("datenschutz@victorum-capital.com")}
                  style={{
                    textDecoration: "underline",
                    cursor: "pointer",
                  }}
                  className="linksHover"
                >
                  {" "}
                  datenschutz@victorum-capital.com
                </span>
                .
              </Typography>
            </Box>
          </Box>
        </ThemeProvider>
      </div>
      <Box sx={{ marginTop: "4rem" }}>
        <FooterLanding />
      </Box>
    </div>
  );
};

export default PrivacyPolicy;
