import { Box, Button, Container, Typography, createTheme } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ThemeProvider } from "@emotion/react";
import Nav from "src/components/layout/nav/Nav";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import LoadingIndicator from "src/components/shared/LoadingIndicator";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import BranchDetailsMeta from "src/components/Branches/BranchDetailsMeta";
import BranchDetailsBasic from "src/components/Branches/BranchDetailsBasic";
import api from "src/store/interceptors/api";
import "./Branches.scss";
import ControlPointOutlinedIcon from "@mui/icons-material/ControlPointOutlined";

const defaultTheme = createTheme();

function BranchDetail() {
  const { branchId } = useParams<{ branchId: string }>();
  const [branchDetails, setBranchDetails] = useState<any>(null);
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const hideAddBankDetailsButton =
    branchDetails &&
    branchDetails.bank_details &&
    branchDetails.bank_details.length >= 2;

  const bankType =
    branchDetails &&
    branchDetails.bank_details &&
    branchDetails.bank_details[0] &&
    branchDetails.bank_details[0].type;

  const bankTypeSec =
    branchDetails &&
    branchDetails.bank_details &&
    branchDetails.bank_details[1] &&
    branchDetails.bank_details[1].type;

  const handleNavigate = () => {
    navigate(-1);
  };
  const navigateToAddBankDetails = () => {
    navigate(`/admin/add-bank-details/${branchId}`);
  };

  const handleEditBranchClick = (event: any) => {
    navigate(`/admin/edit-branch/${branchId}`);
  };
  const handleEditLocalDetailsClick = (event: any) => {
    navigate(`/admin/edit-local-details/${branchId}`);
  };
  const handleEditInternationalDetailsClick = (event: any) => {
    navigate(`/admin/edit-International-details/${branchId}`);
  };

  const fetchBranchDetails = async () => {
    setLoading(true);
    try {
      const response = await api.get(`/branches/${branchId}`);
      setBranchDetails(response?.data?.data);
    } catch (error: any) {}
    setLoading(false);
  };

  useEffect(() => {
    if (branchId) {
      fetchBranchDetails();
    }
  }, [branchId]);

  return (
    <div>
      <Nav step={0} isStepperVisible={false} isProfileSetVisible={true} />
      <div className="main-admin-container">
        <div className="content-container-profile main-color">
          <ThemeProvider theme={defaultTheme}>
            <Container component="main">
              {loading ? (
                <LoadingIndicator />
              ) : (
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <Box className="recentBox">
                    <div className="d-flex ai-center mb-3-rem">
                      <p
                        className="back-button-icon circle-around-text-smaller helvetica mr-1-rem cp"
                        onClick={handleNavigate}
                      >
                        <ArrowBackIcon className="muiIcons" />
                      </p>

                      <Typography
                        className="your-details-text d-flex jc-start pageTitleFontSize"
                        component="h1"
                        variant="h5"
                        sx={{
                          fontFamily: "Helvetica, sans-serif",
                          fontWeight: "600",
                          textAlign: "start",
                        }}
                      >
                        Branch details
                      </Typography>
                    </div>
                    <Box>
                      {!loading && branchDetails && (
                        <Box sx={{ mt: 2 }}>
                          <div className="d-flex jusifyContentSpaceBetween ai-center mb-1rem recipientsInfoTextBtnsBox">
                            <Typography
                              className="d-flex jc-start infoTextMobile registration1375RemFontSize"
                              component="h1"
                              variant="h5"
                              sx={{
                                fontFamily: "Helvetica, sans-serif",
                                fontWeight: "600",
                              }}
                            >
                              Branch details
                            </Typography>
                            <Box className="editDeleteBtnsBox">
                              <Button
                                variant="contained"
                                onClick={handleEditBranchClick}
                                startIcon={
                                  <EditOutlinedIcon className="recipientsBtnsIcon" />
                                }
                                className="editRecipientBtn"
                              >
                                Edit branch
                              </Button>
                            </Box>
                          </div>
                          <div className="personal-box">
                            <div className="infoGrid">
                              <BranchDetailsBasic
                                branchDetails={branchDetails}
                              />
                            </div>
                          </div>
                        </Box>
                      )}
                      {!loading &&
                        branchDetails &&
                        branchDetails.bank_details &&
                        branchDetails.bank_details[0] && (
                          <Box className="mb-1rem" sx={{ mt: 2 }}>
                            <div className="d-flex jusifyContentSpaceBetween ai-center mb-1rem recipientsInfoTextBtnsBox">
                              <Typography
                                className="d-flex jc-start infoTextMobile registration1375RemFontSize"
                                component="h1"
                                variant="h5"
                                sx={{
                                  fontFamily: "Helvetica, sans-serif",
                                  fontWeight: "600",
                                }}
                              >
                                {bankType} payment method
                              </Typography>
                              <Box className="editDeleteBtnsBox">
                                <Button
                                  variant="contained"
                                  onClick={
                                    branchDetails &&
                                    branchDetails.bank_details[0] &&
                                    branchDetails.bank_details[0].type ===
                                      "Local"
                                      ? handleEditLocalDetailsClick
                                      : handleEditInternationalDetailsClick
                                  }
                                  startIcon={
                                    <EditOutlinedIcon className="recipientsBtnsIcon" />
                                  }
                                  className="editRecipientBtn"
                                >
                                  {branchDetails &&
                                  branchDetails.bank_details[0] &&
                                  branchDetails.bank_details[0].type === "Local"
                                    ? "Edit local method"
                                    : "Edit international method"}
                                </Button>
                              </Box>
                            </div>
                            <div className="personal-box">
                              <div className="infoGrid">
                                <BranchDetailsMeta
                                  branchDetailsType={
                                    branchDetails.bank_details?.[0]?.type
                                  }
                                  branchDetails={
                                    branchDetails?.bank_details?.[0]
                                      ?.bank_detail_meta
                                  }
                                />
                              </div>
                            </div>
                          </Box>
                        )}
                      {!loading &&
                        branchDetails &&
                        branchDetails.bank_details &&
                        branchDetails.bank_details[1] && (
                          <Box className="mb-1rem" sx={{ mt: 2 }}>
                            <div className="d-flex jusifyContentSpaceBetween ai-center mb-1rem recipientsInfoTextBtnsBox">
                              <Typography
                                className="d-flex jc-start infoTextMobile"
                                component="h1"
                                variant="h5"
                                sx={{
                                  fontFamily: "Helvetica, sans-serif",
                                  fontSize: "1.375rem",
                                  fontWeight: "600",
                                }}
                              >
                                {bankTypeSec} payment method
                              </Typography>
                              <Box className="editDeleteBtnsBox">
                                <Button
                                  variant="contained"
                                  onClick={
                                    branchDetails &&
                                    branchDetails.bank_details[1] &&
                                    branchDetails.bank_details[1].type ===
                                      "International"
                                      ? handleEditInternationalDetailsClick
                                      : handleEditLocalDetailsClick
                                  }
                                  startIcon={
                                    <EditOutlinedIcon className="recipientsBtnsIcon" />
                                  }
                                  className="editRecipientBtn"
                                >
                                  {" "}
                                  {branchDetails &&
                                  branchDetails.bank_details[1] &&
                                  branchDetails.bank_details[1].type ===
                                    "International"
                                    ? "Edit international method"
                                    : "Edit local method"}
                                </Button>
                              </Box>
                            </div>
                            <div className="personal-box">
                              <div className="infoGrid">
                                <BranchDetailsMeta
                                  branchDetailsType={
                                    branchDetails.bank_details?.[1]?.type
                                  }
                                  branchDetails={
                                    branchDetails?.bank_details?.[1]
                                      ?.bank_detail_meta
                                  }
                                />
                              </div>
                            </div>
                          </Box>
                        )}
                      {!hideAddBankDetailsButton && (
                        <Box>
                          <Button
                            className="addLocalInternationBtn btnsLoginHeight loginSubtitleFontSize"
                            onClick={navigateToAddBankDetails}
                            startIcon={
                              <ControlPointOutlinedIcon className="muiIcons" />
                            }
                          >
                            Add
                          </Button>
                        </Box>
                      )}
                    </Box>
                  </Box>
                </Box>
              )}
            </Container>
          </ThemeProvider>
        </div>
      </div>
    </div>
  );
}

export default BranchDetail;
