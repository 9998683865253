import React from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Typography, Box } from "@mui/material";
import { useNavigate } from "react-router-dom";
import HeaderLanding from "../components/HeaderLanding/HeaderLanding";
import { useAppSelector } from "src/store";
import FooterLanding from "../components/FooterLanding/FooterLanding";
import TitleBackBox from "../components/TitleBackBox/TitleBackBox";
import HeadlineVvidgetSmall from "../components/HeadlineVvidget/HeadlineVvidgetSmall";
import ImageWithText from "../components/ImageWithText/ImageWithText";
import CryptoImg from "../../../assets/images/cryptoImg.jpg";
import TitleTextVvidget from "../components/TitleTextVvidget/TitleTextVvidget";
import LogoTextVvidget from "../components/LogoTextVvidget/LogoTextVvidget";
import i18next from "i18next";

const defaultTheme = createTheme();
const Cryptos: React.FC = () => {
  const { user } = useAppSelector((state) => state.auth);

  const navigate = useNavigate();

  return (
    <div>
      <HeaderLanding user={user} />
      <div className="margintTopPagesMainCont">
        <ThemeProvider theme={defaultTheme}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            {" "}
            <Box className="titleHeadlineSmallBG">
              <Box className="landingPagesMainBox">
                <Box>
                  <TitleBackBox title={i18next.t("Crypto")} />
                </Box>

                <Box>
                  <HeadlineVvidgetSmall
                    headline={i18next.t(
                      "Are you ready for the next financial revolution?"
                    )}
                    subtitle={i18next.t(
                      "Crypto currencies, stable coins and tokens have driven a whole generation and created a completely new and decentralised world of wealth"
                    )}
                    smallText={i18next.t("Ready to take off?")}
                    buttonText={i18next.t("Create a free account")}
                    onClickFunction={() => {
                      navigate("/registration");
                    }}
                  />
                </Box>
              </Box>
            </Box>
            <Box className="landingPagesMainBox">
              <Box>
                <ImageWithText
                  backgroundImage={CryptoImg}
                  noiseImage="/img/payments-noise.png"
                  title={i18next.t("Crypto Made Easy")}
                  text={i18next.t(
                    "We are keeping this very simple and we make cryptocurrencies easy to deal with - no matter where you are!"
                  )}
                />
              </Box>

              <Box
                sx={{
                  paddingBottom: "8px",
                  "@media(max-width: 1200px)": {
                    paddingBottom: "0px",
                    marginTop: "112px",
                  },
                  "@media(max-width: 1023px)": {
                    marginTop: "40px",
                  },
                }}
              >
                <TitleTextVvidget
                  title={i18next.t(
                    "Effortless Crypto Trading and Transactions"
                  )}
                  text={i18next.t(
                    "Quickly buy and sell crypto and stablecoins at great prices, switch between crypto and fiat easily, instantly deposit and withdraw at world’s largest crypto exchanges, connect with different wallets and more."
                  )}
                />
              </Box>

              <Box>
                <LogoTextVvidget
                  text={i18next.t(
                    "Our clients can easily buy crypto with account or card balances for fair prices, within seconds and with maximum security. Through our app or website it´s possible to sell crypto for USD, EUR and other currencies, to receive great spreads through major crypto exchanges and to store, send and receive coins, such as BTC, ETH, LTC, USDT, USDC, BCH, XRP, TON, TRX, BNB, TRON."
                  )}
                />
              </Box>
              <Box>
                <Typography
                  variant="h3"
                  sx={{
                    marginBottom: "1rem",
                  }}
                  className="headlineMiniLandingPages"
                >
                  {i18next.t(
                    "Empowering SMEs and Startups with Secure Payment Solutions"
                  )}
                </Typography>
                <Typography
                  variant="body1"
                  paragraph
                  className="textsLandingPage"
                >
                  {i18next.t(
                    "Our regulated services are not just used by crypto enthusiasts all over the world, but also by a growing number of SME as well as startups, to integrate reliable payment infrastructures. Further we offer online backoffice solutions to help companies to stay compliant, to minimise risks and to monitor all transactions and addresses using industry-leading blockchain analytics solutions."
                  )}
                </Typography>
              </Box>
              <Box>
                <Typography
                  variant="h3"
                  sx={{
                    marginBottom: "1rem",
                  }}
                  className="headlineMiniLandingPages"
                >
                  {i18next.t("Explore Our Services")}
                </Typography>
                <Typography
                  variant="body1"
                  paragraph
                  className="textsLandingPage"
                >
                  {i18next.t("Click to learn more")}{" "}
                  <span
                    className="linksHover"
                    onClick={() => navigate("/about-us")}
                  >
                    {i18next.t("about our service")}
                  </span>
                  .
                </Typography>
              </Box>
              <Box>
                <Typography
                  variant="h3"
                  sx={{
                    marginBottom: "1rem",
                  }}
                  className="headlineMiniLandingPages"
                >
                  {i18next.t("Start Your Account Today")}
                </Typography>
                <Typography
                  variant="body1"
                  paragraph
                  className="textsLandingPage"
                >
                  {i18next.t("Click to here to")}{" "}
                  <span
                    className="linksHover"
                    onClick={() => navigate("/registration")}
                  >
                    {" "}
                    {i18next.t("open an account")}
                  </span>
                  .
                </Typography>
              </Box>
              <Box>
                <Typography
                  variant="h3"
                  sx={{
                    marginBottom: "1rem",
                  }}
                  className="headlineMiniLandingPages"
                >
                  {i18next.t("Contact Us for Assistance")}
                </Typography>
                <Typography
                  variant="body1"
                  paragraph
                  className="textsLandingPage"
                >
                  {i18next.t(
                    "You can reach us Monday to Monday from 10 AM to 6 PM - please dial +1 604-260-0738 or"
                  )}{" "}
                  <span
                    style={{ marginLeft: "5px" }}
                    className="linksHover"
                    onClick={() => navigate("/contact-us")}
                  >
                    {i18next.t("click here")}
                  </span>{" "}
                  {i18next.t("for more contact options!")}
                </Typography>
              </Box>
            </Box>
          </Box>
        </ThemeProvider>
      </div>
      <Box sx={{ marginTop: "4rem" }}>
        <FooterLanding />
      </Box>
    </div>
  );
};

export default Cryptos;
