// src/store/transactions/transactions_slice.js
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import transactionsService from "./transactions_service";

interface Transaction {
  // Define the structure of your transaction object here
}

interface InitialStateTransactions {
  isLoading: boolean;
  isError: boolean;
  isSuccess: boolean;
  transactions: Transaction[] | null;
  error: string | null;
}

const initialState: InitialStateTransactions = {
  isLoading: false,
  isError: false,
  isSuccess: false,
  transactions: null,
  error: null,
};

export const fetchTransactions = createAsyncThunk(
  "transactions/fetchTransactions",
  async (_, thunkAPI) => {
    try {
      const response = await transactionsService.getTransactions();
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue("error");
    }
  }
);

const transactionsSlice = createSlice({
  name: "transactions",
  initialState,
  reducers: {
    resetTransactionsState: (state) => {
      state.isLoading = false;
      state.isError = false;
      state.isSuccess = false;
      state.transactions = null;
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchTransactions.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchTransactions.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.transactions = action.payload;
      })
      .addCase(fetchTransactions.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.error = action.payload as string;
      });
  },
});

export const { resetTransactionsState } = transactionsSlice.actions;

export default transactionsSlice.reducer;
