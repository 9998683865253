import React from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Typography, Box } from "@mui/material";
import { useNavigate } from "react-router-dom";
import HeaderLanding from "../components/HeaderLanding/HeaderLanding";
import { useAppSelector } from "src/store";
import FooterLanding from "../components/FooterLanding/FooterLanding";
import "./LandingPagesGlobal.scss";
import LogoTextVvidget from "../components/LogoTextVvidget/LogoTextVvidget";
import ImageWithText from "../components/ImageWithText/ImageWithText";
import paymentsImage from "../../../assets/images/newsBB.jpg";
import TitleBackBox from "../components/TitleBackBox/TitleBackBox";
import HeadlineVvidgetSmall from "../components/HeadlineVvidget/HeadlineVvidgetSmall";
import News from "../components/News/News";
import i18next from "i18next";

const defaultTheme = createTheme();

const Individual: React.FC = () => {
  const { user } = useAppSelector((state) => state.auth);

  const navigate = useNavigate();
  const navigateToMediaPress = (event: any) => {
    navigate("/media-press");
  };

  return (
    <div>
      <HeaderLanding user={user} />
      <div className="margintTopPagesMainCont">
        <ThemeProvider theme={defaultTheme}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Box className="titleHeadlineSmallBG">
              <Box className="landingPagesMainBox">
                <Box>
                  <TitleBackBox title={i18next.t("Online newsroom")} />
                </Box>
                <Box>
                  <HeadlineVvidgetSmall
                    headline={i18next.t(
                      "What could be more valuable than getting news first?"
                    )}
                    subtitle={i18next.t(
                      "The world of finance, currencies and cryptos is changing just as rapidly as the technologies and payment methods."
                    )}
                    smallText={i18next.t("For journalists and press services")}
                    buttonText={i18next.t("Contact media & press desk")}
                    onClickFunction={navigateToMediaPress}
                  />
                </Box>
              </Box>
            </Box>
            <Box className="landingPagesMainBox">
              <div>
                <ImageWithText
                  backgroundImage={paymentsImage}
                  noiseImage="/img/payments-noise.png"
                  title={i18next.t("Get valuable news first")}
                  text={i18next.t(
                    "We will keep you informed about the latest news from the world of finance, currencies and cryptos."
                  )}
                />
              </div>
              <Box>
                <News />
              </Box>

              <Box
                sx={{
                  marginBottom: "52px",
                  "@media(max-width: 1023px)": {
                    marginBottom: "76px",
                  },
                }}
              >
                <LogoTextVvidget
                  text={i18next.t(
                    "To help you benefit from these changes, we keep you up to date in this section of our website and provide you with interesting information on economic and monetary policy, news from the crypto world and insights from our company and the Victorum Group."
                  )}
                />
              </Box>

              <Box>
                <Typography
                  variant="h3"
                  sx={{ marginBottom: "1rem" }}
                  className="headlineLandingPages"
                >
                  {i18next.t("Get in touch with us")}
                </Typography>
                <Typography
                  variant="body1"
                  paragraph
                  className="textsLandingPage"
                >
                  {i18next.t(
                    "For journalists and press services, we recommend to contact our dedicated media & press desk - simply click"
                  )}
                  <span
                    className="linksHover"
                    style={{
                      cursor: "pointer",
                      marginLeft: "7px",
                      marginRight: "7px",
                    }}
                    onClick={() => navigate("/media-press")}
                  >
                    {i18next.t("here")}
                  </span>
                  {i18next.t(
                    "for press photos, interview material and much more or dial +1 604-260-0738 to get in touch with our media & press department!"
                  )}
                </Typography>
              </Box>
            </Box>
          </Box>
        </ThemeProvider>
      </div>
      <Box sx={{ marginTop: "4rem" }}>
        <FooterLanding />
      </Box>
    </div>
  );
};

export default Individual;
