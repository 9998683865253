import React from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Typography, Box } from "@mui/material";
import { useNavigate } from "react-router-dom";
import HeaderLanding from "../components/HeaderLanding/HeaderLanding";
import { useAppSelector } from "src/store";
import FooterLanding from "../components/FooterLanding/FooterLanding";
import "./LandingPagesGlobal.scss";
import IconTextWidget from "../components/IconTextWidget/IconTextWidget";
import currencyIcon from "../../../assets/images/currencies.png";
import merchantIcon from "../../../assets/images/bank-account.png";
import users from "../../../assets/images/save-time.png";
import icon24 from "../../../assets/images/contact.png";
import languages from "../../../assets/images/internet.png";
import LogoTextVvidget from "../components/LogoTextVvidget/LogoTextVvidget";
import TitleTextVvidget from "../components/TitleTextVvidget/TitleTextVvidget";
import ImageWithText from "../components/ImageWithText/ImageWithText";
import currencyw from "../../../assets/images/cuurencyw3.webp";
import TitleBackBox from "../components/TitleBackBox/TitleBackBox";
import HeadlineVvidgetSmall from "../components/HeadlineVvidget/HeadlineVvidgetSmall";
import i18next from "i18next";

const defaultTheme = createTheme();
const MultiCurrrency: React.FC = () => {
  const { user } = useAppSelector((state) => state.auth);
  const navigate = useNavigate();
  const navigateToRegistration = (event: any) => {
    navigate("/registration");
  };

  return (
    <div>
      <HeaderLanding user={user} />
      <div className="margintTopPagesMainCont">
        <ThemeProvider theme={defaultTheme}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Box className="titleHeadlineSmallBG">
              <Box className="landingPagesMainBox">
                <Box>
                  <TitleBackBox title={i18next.t("Multi-Currency")}/>
                </Box>
                <Box>
                  <HeadlineVvidgetSmall
                    headline={
                      i18next.t("Multi-currency accounts for you or your company")
                    }
                    subtitle={
                      i18next.t("Our secure online platform and our app allow our customers to hold, send, receive and manage money across 12 currencies - all within one single account")
                    }
                    smallText={i18next.t("Join customers all over the world")}
                    buttonText={i18next.t("Create a free account")}
                    onClickFunction={navigateToRegistration}
                  />
                </Box>
              </Box>
            </Box>
            <Box className="landingPagesMainBox">
              <div className="icon-text-widgets-container">
                <IconTextWidget
                  iconSrc={merchantIcon}
                  title={i18next.t("Open, close or update accounts whenever you want")}
                  text={i18next.t("Local and international deposits and withdrawals")}
                />
                <IconTextWidget
                  iconSrc={currencyIcon}
                  title={i18next.t("Accounts in 12 major currencies")}
                  text={i18next.t("Accounts in USD, EUR and other currencies")}
                />
                <IconTextWidget
                  iconSrc={languages}
                  title={i18next.t("Worldwide partnerships with banks")}
                  text=""
                />
                <IconTextWidget
                  iconSrc={users}
                  title={i18next.t("Save time and money with our services")}
                  text={i18next.t("Robust multi-tier account security with 2FA")}
                />
                <IconTextWidget
                  iconSrc={icon24}
                  title={i18next.t("Easy and fast customer support")}
                  text={i18next.t("First response in 1 hour or faster")}
                />
              </div>

              <Box
                sx={{
                  marginBottom: "52px",
                  "@media(max-width: 1023px)": {
                    marginBottom: "76px",
                  },
                }}
              >
                <LogoTextVvidget
                  text={
                    i18next.t("With our multi-currency accounts, you and your business can access accounts in 12 major currencies. Your finance team and your customers can benefit from 49 currency pairs with instant FX settlement. A seamless solution for every single payment in every single currency")
                  }
                />
              </Box>

              <div>
                <ImageWithText
                  backgroundImage={currencyw}
                  noiseImage="/img/payments-noise.png"
                  title={i18next.t("12 major currencies")}
                  text={i18next.t("With our multi-currency accounts, you and your business can access accounts in 12 major currencies. A seamless solution for every single payment in every single currency")}
                />
              </div>
              <Box
                sx={{
                  paddingBottom: "56px",
                  "@media(max-width: 1200px)": {
                    paddingBottom: "0px",
                    marginTop: "64px",
                  },
                }}
              >
                <TitleTextVvidget
                  title={i18next.t("Multi currency accounts")}
                  text={
                    i18next.t("No need to swap between apps and accounts anymore - our multi currency accounts and our crypto features perfectly match the needs of globally active companies")
                  }
                  text2={
                    i18next.t("WIth our services we offer streamlined and automated reconciliation, time saving while managing payments and full flexibility receiving different currencies and payments")
                  }
                />
              </Box>
              <Box>
                <Typography
                  variant="h3"
                  sx={{ marginBottom: "1rem", marginTop: "64px" }}
                  className="headlineLandingPages"
                >
                  {i18next.t("Transform your management")}
                </Typography>

                <Box sx={{ marginTop: "2rem" }}>
                  <Typography
                    variant="h3"
                    sx={{
                      marginBottom: "1rem",
                    }}
                    className="headlineMiniLandingPages"
                  >
                    {" "}
                    {i18next.t("Effortless Account Management")}
                  </Typography>
                  <Typography
                    variant="body1"
                    paragraph
                    className="textsLandingPage"
                  >
                   {i18next.t("Our technology solutions and our worldwide partnerships with banks and financial services companies enable our clients to open, close or update multi-currency individual and business accounts whenever you want")}
                  </Typography>
                </Box>

                <Box sx={{ marginTop: "2rem" }}>
                  <Typography
                    variant="h3"
                    sx={{
                      marginBottom: "1rem",
                    }}
                    className="headlineMiniLandingPages"
                  >
                    {" "}
                    {i18next.t("Streamlined Treasury Transformation")}
                  </Typography>
                  <Typography
                    variant="body1"
                    paragraph
                    className="textsLandingPage"
                  >
                    {i18next.t("Transform your treasury management within minutes: Click")}
                    <span
                      className="linksHover"
                      style={{ marginLeft: "9px", marginRight: "9px" }}
                      onClick={() => navigate("/registration")}
                    >
                      {i18next.t("here")}
                    </span>
                    {i18next.t("to create a free business account and then complete our simple KYB process and verification - you´re already done and start")}
                  </Typography>
                </Box>

                <Box sx={{ marginTop: "2rem" }}>
                  <Typography
                    variant="h3"
                    sx={{
                      marginBottom: "1rem",
                    }}
                    className="headlineMiniLandingPages"
                  >
                    {" "}
                    {i18next.t("Contact Us Anytime")}
                  </Typography>
                  <Typography
                    variant="body1"
                    paragraph
                    className="textsLandingPage"
                  >
                    {i18next.t("In case of any questions do not hesitate to call us on +1 604-260-0738 or to send us a message to")}
                    <a
                      href="mailto:info@vicpayments.com"
                      className="linksHover"
                      style={{ marginLeft: "5px" }}
                    >
                      info@vicpayments.com
                    </a>
                    !
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>
        </ThemeProvider>
      </div>
      <Box sx={{ marginTop: "4rem" }}>
        <FooterLanding />
      </Box>
    </div>
  );
};

export default MultiCurrrency;
