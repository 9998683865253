import { Box, Typography } from "@mui/material";
import i18next from "i18next";
import React from "react";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
function ReviewStep() {
  return (
    <div className="content-container-profile main-color animated-text moveInRight">
      <Typography
        className="pageTitleFontSize"
        component="h1"
        variant="h5"
        sx={{
          fontFamily: "Helvetica, sans-serif",
          fontWeight: "600",
          color: "#0e0f0c",
          maxWidth: "500px",
          whiteSpace: "nowrap",
          textOverflow: "ellipsis",
          overflow: "hidden",
        }}
      >
        {i18next.t("Review")}
      </Typography>

      <Typography sx={{ mt: "20px", mb: "50px" }}>
        {i18next.t(
          "Thank you for  submitting all required documents and informations. Our compliance team will check your application and process your account."
        )}{" "}
        {i18next.t(
          "In case additional documents are needed our support team will contact you."
        )}
        <br />
        <br />
        {i18next.t(
          "Review process takes from 1 up to 10 business days depends  on data provided."
        )}
      </Typography>

      <Box className="clock-section">
        <AccessTimeIcon />
        <Typography>{i18next.t("Application in review")}</Typography>
      </Box>
      <Typography sx={{alignSelf: 'start'}}>
        {i18next.t(
          "While waiting for account approval you can find your IBAN details in your Account page"
        )}
      </Typography>
    </div>
  );
}

export default ReviewStep;
