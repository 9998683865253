import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import {
  Box,
  Typography,
  TextField,
  InputAdornment,
  Checkbox,
  Select,
  MenuItem,
  Button,
  SelectChangeEvent,
} from "@mui/material";
import { useEffect, useState } from "react";
import SearchIcon from "@mui/icons-material/Search";
import LoadingIndicator from "src/components/shared/LoadingIndicator";
import usersService from "src/store/users/users_services";
import Pagination from "@mui/material/Pagination";
import UserListBulkMessages from "./UsersListBulkMessages";
import ChatDialog from "src/components/shared/ChatDialog/ChatDialog";
import api from "src/store/interceptors/api";
import InsertCommentIcon from "@mui/icons-material/InsertComment";
import "../UserList.scss";
import { toast } from "react-toastify";

const defaultTheme = createTheme();

export default function UsersListFilter() {
  const [usersData, setUsersData] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [perPage] = useState(10);
  const [totalPages, setTotalPages] = useState(1);
  const [total, setTotal] = useState(null);
  const [searchValue, setSearchValue] = useState("");
  const [accountType, setAccountType] = useState<any>("all");
  const [selectedUsers, setSelectedUsers] = useState<any[]>([]);
  const [allUsersChecked, setAllUsersChecked] = useState(false);
  const [sendingMessage, setIsSendindMessage] = useState(false);
  const [specificSelectedUsers, setSpecificSelectedUsers] = useState<any[]>([]);
  const [isDialogChatOpen, setIsDialogChatOpen] = useState(false);
  const [deselectAll, setDeselectAll] = useState(false);
  const [fileUpload, setFileUpload] = useState<File[]>([]);

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(event.target.value);
  };

  const handleChangeAccountType = (event: SelectChangeEvent) => {
    setAccountType(event.target.value as string);
  };

  const handleDialogChatClose = () => {
    setIsDialogChatOpen(false);
  };

  const handleDialogChatConfirm = (e: any, message: any) => {
    handleSendMessage(message);
  };

  const handleDeselectAll = () => {
    setDeselectAll(true);

    setSelectedUsers([]);
    setSpecificSelectedUsers([]);
    setAllUsersChecked(false);

    setTimeout(() => {
      setDeselectAll(false);
    }, 300);
  };

  const handleFileSelect = (files: FileList | null) => {
    if (files && files.length > 0) {
      setFileUpload([files[0]]);
      const fileNameContainer = document.getElementById("selectedFileName");
      if (fileNameContainer) {
        fileNameContainer.innerText = files[0].name;
      }
    } else {
      setFileUpload([]);
      const fileNameContainer = document.getElementById("selectedFileName");
      if (fileNameContainer) {
        fileNameContainer.innerText = "";
      }
    }
  };

  const handleSendMessage = async (message: any) => {
    setIsSendindMessage(true);

    try {
      const formData = new FormData();
      if (message) {
        formData.append("message", message);
      }
      if (fileUpload && fileUpload.length > 0) {
        for (let i = 0; i < fileUpload.length; i++) {
          formData.append(`attachments[${i}]`, fileUpload[i]);
        }
      }
      if (selectedUsers.length === 0 && specificSelectedUsers.length > 0) {
        specificSelectedUsers.forEach((userId) => {
          formData.append("user_ids[]", String(userId));
        });
      } else {
        if (accountType !== "all") {
          formData.append("account_type", accountType);
        }
      }

      await api.post("/support-notification-message", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      toast.success("Message sent successfully!");
      setIsDialogChatOpen(false);
      setSelectedUsers([]);
      setSpecificSelectedUsers([]);
      setFileUpload([]);
      setAllUsersChecked(false);
    } catch (error) {
      setIsDialogChatOpen(false);
    } finally {
      setIsSendindMessage(false);
    }
  };

  const handleGetUsers = async () => {
    try {
      setIsLoading(true);
      let urlFilter: string = "";

      if (searchValue) {
        urlFilter = `?page=${page}&per_page=${perPage}&search[role]=User&search[companies.name]=${searchValue}&search[first_name]=${searchValue}&search[last_name]=${searchValue}&sort[first_name]=ASC`;
      } else {
        urlFilter = `?page=${page}&per_page=${perPage}&search[role]=User&sort[first_name]=ASC`;
      }

      let filters = [];
      let searchParam = "filter_search";
      filters.push("&search_condition=and");

      let filt = "";

      if (accountType !== "all") {
        filt = "&" + searchParam + "[account_type]=" + accountType;
        if (!filters.includes(filt)) filters.push(filt);
      }
      urlFilter += filters.join("");

      const response = await usersService.getUsers(urlFilter);
      const { data, meta } = response;
      setIsLoading(false);
      if (data) {
        const filteredUsers = data.filter((user: any) => user.role === "User");
        setUsersData(filteredUsers);
        // setUsersData(data);
        // setSpecificSelectedUsers([]);
      }
      if (meta) {
        setTotalPages(meta?.last_page || 1);
        setTotal(meta?.total || 0);
      }
    } catch (error) {
      console.log("error getting user");
    }
  };
  const handleSelectAll = async () => {
    try {
      setIsLoading(true);

      let urlFilter = `?per_page=${Number.MAX_SAFE_INTEGER}&sort[first_name]=ASC&search[role]=User`;

      if (accountType !== "all") {
        urlFilter += `&search[account_type]=${accountType}`;
      }
      const response = await usersService.getUsers(urlFilter);
      const filteredUsers = response.data || [];
      const filteredUserIds = filteredUsers.map((user: any) => user.id);

      if (
        selectedUsers.length === filteredUserIds.length &&
        specificSelectedUsers.length === 0
      ) {
        setSelectedUsers([]);
        setSpecificSelectedUsers([]);
        setAllUsersChecked(false);
      } else {
        setSelectedUsers(filteredUserIds);
        setSpecificSelectedUsers([]);
        setAllUsersChecked(true);
      }

      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  const handleSelectSpecificUser = (userId: number) => {
    setSpecificSelectedUsers((prevSpecificSelectedUsers) => {
      if (prevSpecificSelectedUsers.includes(userId)) {
        return prevSpecificSelectedUsers.filter((id) => id !== userId);
      } else {
        return [...prevSpecificSelectedUsers, userId];
      }
    });

    if (selectedUsers.includes(userId)) {
      setAllUsersChecked(false);
      setSelectedUsers([]);
    }
  };

  useEffect(() => {
    handleGetUsers();
    setSelectedUsers([]);
    setAllUsersChecked(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchValue, page, accountType]);

  return (
    <div>
      <div className="main-admin-container">
        <div className="content-container-profile main-color">
          <ThemeProvider theme={defaultTheme}>
            <Container component="main">
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <div className="d-flex ai-center mb-1-rem">
                  <Typography
                    className="your-details-text d-flex jc-start pageTitleFontSize"
                    component="h1"
                    variant="h5"
                    sx={{
                      fontFamily: "Helvetica, sans-serif",
                      textAlign: "center",
                      fontWeight: "600",
                    }}
                  >
                    Users
                  </Typography>
                </div>

                <div>
                  <Box>
                    <TextField
                      placeholder="Search user by name"
                      style={{ width: "100%" }}
                      value={searchValue}
                      onChange={handleSearchChange}
                      InputProps={{
                        autoComplete: "new-password",
                        startAdornment: (
                          <InputAdornment position="start">
                            <SearchIcon className="searchIcon" />
                          </InputAdornment>
                        ),
                        sx: {
                          borderRadius: "40px",
                          height: "48px",
                          fontSize: "16px",
                          "&:-internal-autofill-selected": {
                            backgroundColor: "transparent !important",
                          },
                        },
                      }}
                    ></TextField>
                  </Box>

                  <Box className="d-flex ai-center selectAllAccountTypeSendMsgBox">
                    <Box className="selectAllUsersAccountTypeBox">
                      <div className="d-flex ai-center">
                        <Checkbox
                          checked={allUsersChecked}
                          onChange={handleSelectAll}
                        />
                        <Typography
                          component="span"
                          variant="h5"
                          className="registration0875RemFontSize"
                          sx={{
                            fontFamily: "Helvetica, sans-serif",
                            textAlign: "center",
                            fontWeight: 300,
                          }}
                        >
                          Select all users
                        </Typography>
                      </div>
                      <div className="d-flex ai-center ml-8">
                        <Select
                          sx={{
                            height: "30px",
                            borderRadius: "16px",
                            backgroundColor: "rgb(244, 246, 248)",
                            fontSize: "14px",
                            color: "#2A5182",
                            border: "none",
                            "&:focus": {
                              outline: "none",
                              borderRadius: "8px",
                            },
                            "& .MuiOutlinedInput-notchedOutline": {
                              border: "none",
                            },
                            ".MuiSelect-select": {
                              borderRadius: "8px",
                            },
                          }}
                          value={accountType}
                          onChange={handleChangeAccountType}
                        >
                          <MenuItem
                            value={"all"}
                            sx={{ color: "#2A5182", fontSize: "14px" }}
                          >
                            All
                          </MenuItem>
                          <MenuItem
                            sx={{ color: "#2A5182", fontSize: "14px" }}
                            value={"personal"}
                          >
                            Personal
                          </MenuItem>
                          <MenuItem
                            sx={{ color: "#2A5182", fontSize: "14px" }}
                            value={"business"}
                          >
                            Business
                          </MenuItem>
                        </Select>
                        <Typography
                          component="span"
                          variant="h5"
                          className="registration0875RemFontSize"
                          sx={{
                            fontFamily: "Helvetica, sans-serif",
                            textAlign: "center",
                            fontWeight: 300,
                            marginLeft: "0.5rem",
                          }}
                        >
                          Account type
                        </Typography>
                      </div>
                      {(selectedUsers.length > 0 ||
                        specificSelectedUsers.length > 0) && (
                        <div className="d-flex ai-center ml-8">
                          <Button
                            onClick={handleDeselectAll}
                            className="yesBtn oneRemFontSize"
                          >
                            <Typography
                              component="span"
                              variant="h5"
                              className="oneRemFontSize"
                              sx={{
                                fontFamily: "Helvetica, sans-serif",
                                textAlign: "center",
                                color: "#fff",
                                textTransform: "none",
                                fontWeight: 300,
                              }}
                            >
                              Deselect users
                            </Typography>
                            {/* <Checkbox
                              checked={deselectAll}
                              onChange={handleDeselectAllChange}
                            /> */}
                          </Button>
                        </div>
                      )}
                    </Box>
                    <Box>
                      <Button
                        variant="contained"
                        onClick={() => {
                          if (!isDialogChatOpen) setIsDialogChatOpen(true);
                        }}
                        disabled={
                          selectedUsers.length === 0 &&
                          specificSelectedUsers.length === 0
                        }
                        className="oneRemFontSize"
                        sx={{
                          color: "#fff",
                          borderRadius: "24px",
                          boxShadow: "none",
                          fontWeight: 400,
                          textTransform: "none",
                          fontFamily: "Helvetica, sans-serif",
                          textIndent: 10,
                          "&:hover": {
                            backgroundColor: "#2A5182",
                            color: "white",
                          },
                        }}
                      >
                        <InsertCommentIcon
                          style={{ color: "#ffffff" }}
                          className="muiIcons"
                        />{" "}
                        Send message
                      </Button>
                    </Box>
                  </Box>
                </div>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <Typography className="recentText">
                    Selected users:{" "}
                  </Typography>
                  <Typography className="selectedUsersCounter">
                    {selectedUsers.length || specificSelectedUsers.length}
                  </Typography>
                </Box>
                {!allUsersChecked && (
                  <div>
                    {isLoading ? (
                      <LoadingIndicator />
                    ) : (
                      <div>
                        <UserListBulkMessages
                          data={usersData}
                          total={total}
                          onSpecificSelectUser={handleSelectSpecificUser}
                          selectedUsers={specificSelectedUsers}
                        />
                        {total && total > perPage ? (
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "center",
                              marginTop: "2rem",
                            }}
                          >
                            <Pagination
                              count={totalPages}
                              page={page}
                              onChange={(event, value) => setPage(value)}
                              color="primary"
                            />
                          </Box>
                        ) : null}
                      </div>
                    )}
                  </div>
                )}
              </Box>
              <ChatDialog
                open={isDialogChatOpen}
                handleClose={handleDialogChatClose}
                handleConfirm={handleDialogChatConfirm}
                loading={sendingMessage}
                showFileUpload={true}
                fileUpload={fileUpload}
                onFileSelect={handleFileSelect}
                content={{
                  title: "Send message",
                  body: "Note that this action cannot be undone.",
                  cancelText: "No",
                  confirmText: "Send message",
                }}
                className="custom-dialog"
              />
            </Container>
          </ThemeProvider>
        </div>
      </div>
    </div>
  );
}
