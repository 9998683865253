import "../../pages/LandingPage.scss";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Typography,
} from "@mui/material";
import i18next from "i18next";
import AddIcon from "@mui/icons-material/Add";

function FAQS({ isFaqPage = false }) {
  return (
    <Box id="faq" className="faqsContainer">
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          gap: "32px",
        }}
      >
        {!isFaqPage && (
          <Typography className="faqsText">
            {i18next.t("Frequently asked questions:")}
          </Typography>
        )}
        <Typography
          className={isFaqPage ? "faqsDescTextFaqPage" : "faqsDescText"}
        >
          {i18next.t("Streamlining Your Financial Journey with VicPay")}
        </Typography>
      </Box>
      <Box className="accordionMainContainer">
        <Accordion className="accordion accordion-custom-style">
          <AccordionSummary
            expandIcon={<AddIcon fontSize="medium" color={"primary"} />}
            aria-controls="panel1-content"
            id="panel1-header"
          >
            <Typography className="accordionQuestionText">
              {i18next.t("How secure is an account and my personal data")}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography className="accordionAnswerText">
              {" "}
              {i18next.t(
                "The decentralization of the building blocks of each account ensures the greatest possible security at all times"
              )}
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion className="accordion accordion-custom-style">
          <AccordionSummary
            expandIcon={<AddIcon fontSize="medium" color={"primary"} />}
            aria-controls="panel1-content"
            id="panel1-header"
          >
            <Typography className="accordionQuestionText">
              {i18next.t("Can I also make normal bank transfers")}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography className="accordionAnswerText">
              {" "}
              {i18next.t(
                "The answer is simple - yes. You can make worldwide transfers"
              )}
            </Typography>
          </AccordionDetails>
        </Accordion>

        {/* start */}
        <Accordion className="accordion accordion-custom-style">
          <AccordionSummary
            expandIcon={<AddIcon fontSize="medium" color={"primary"} />}
            aria-controls="panel1-content"
            id="panel1-header"
          >
            <Typography className="accordionQuestionText">
              {i18next.t(
                "Do you report my transactions to the relevant tax office"
              )}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography className="accordionAnswerText">
              {" "}
              {i18next.t(
                "Corresponding notifications are the sole responsibility of our customers. We strongly recommend that you comply with your local laws"
              )}
            </Typography>
          </AccordionDetails>
        </Accordion>
        {/* end */}

        {/* start */}
        <Accordion className="accordion accordion-custom-style">
          <AccordionSummary
            expandIcon={<AddIcon fontSize="medium" color={"primary"} />}
            aria-controls="panel1-content"
            id="panel1-header"
          >
            <Typography className="accordionQuestionText">
              {i18next.t("Can I open additional accounts in my name")}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography className="accordionAnswerText">
              {" "}
              {i18next.t(
                "Yes, you can. If required, you still have the option of opening a business account"
              )}
            </Typography>
          </AccordionDetails>
        </Accordion>
        {/* end */}

        {/* start */}
        <Accordion className="accordion accordion-custom-style">
          <AccordionSummary
            expandIcon={<AddIcon fontSize="medium" color={"primary"} />}
            aria-controls="panel1-content"
            id="panel1-header"
          >
            <Typography className="accordionQuestionText">
              {i18next.t("Can I invest in your company")}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography className="accordionAnswerText">
              {" "}
              {i18next.t(
                "We offer the opportunity to invest in shares of our Vic Pay Inc. If you are interested, please do not hesitate to contact us"
              )}
            </Typography>
          </AccordionDetails>
        </Accordion>
        {/* end */}

        {/* start */}
        <Accordion className="accordion accordion-custom-style">
          <AccordionSummary
            expandIcon={<AddIcon fontSize="medium" color={"primary"} />}
            aria-controls="panel1-content"
            id="panel1-header"
          >
            <Typography className="accordionQuestionText">
              {i18next.t("Which cryptocurrencies can I use on my account")}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography className="accordionAnswerText">
              {" "}
              {i18next.t(
                "You can buy and sell all common cryptocurrencies such as Bitcoin, Etherium, Tether, Binance Coin and Dogecoin via your account"
              )}
            </Typography>
          </AccordionDetails>
        </Accordion>
        {/* end */}

        {/* start */}
        <Accordion className="accordion accordion-custom-style">
          <AccordionSummary
            expandIcon={<AddIcon fontSize="medium" color={"primary"} />}
            aria-controls="panel1-content"
            id="panel1-header"
          >
            <Typography className="accordionQuestionText">
              {i18next.t("Is money that I deposit into my account secure")}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography className="accordionAnswerText">
              {" "}
              {i18next.t(
                "All your deposited money is protected by the Deposit Protection Fund of the European Union within the statutory maximum amounts pursuant to Section 6 (2) of the ESF Statute (SESF)"
              )}
            </Typography>
          </AccordionDetails>
        </Accordion>
        {/* end */}

        {/* start */}
        <Accordion className="accordion accordion-custom-style">
          <AccordionSummary
            expandIcon={<AddIcon fontSize="medium" color={"primary"} />}
            aria-controls="panel1-content"
            id="panel1-header"
          >
            <Typography className="accordionQuestionText">
              {i18next.t("Is the card you offer a genuine credit card")}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography className="accordionAnswerText">
              {" "}
              {i18next.t(
                "It is a debit card with all its advantages for daily payment transactions"
              )}
            </Typography>
          </AccordionDetails>
        </Accordion>
        {/* end */}

        {/* start */}
        <Accordion className="accordion accordion-custom-style">
          <AccordionSummary
            expandIcon={<AddIcon fontSize="medium" color={"primary"} />}
            aria-controls="panel1-content"
            id="panel1-header"
          >
            <Typography className="accordionQuestionText">
              {i18next.t("Is VicPay a traditional bank")}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography className="accordionAnswerText">
              {" "}
              {i18next.t(
                "VicPay is not a bank, but a pure internet platform for financial transactions"
              )}
            </Typography>
          </AccordionDetails>
        </Accordion>
        {/* end */}
      </Box>
    </Box>
  );
}

export default FAQS;
