import { Button } from "@mui/material";
import React, { useRef, useState } from "react";
import PriorityYellowIcon from "src/pages/Authentication/Registration/components/PriorityIcon";
import OTPInput from "../Registration/OTPInput";
import imageUrl from "src/assets/images/sms.png";
import { useAppSelector } from "src/store";
import { uuidGenerator } from "src/utils/helpers";
import api from "src/store/interceptors/api";
import { toast } from "react-toastify";
import i18next from "i18next";

interface Props {
  onSubmit: (value: any) => void;
  phoneNumber?: string | null;
  userToken?: string | null;
  email?: string | null;
  step?: any;
}

const getPhone = (user: any) => {
  let phone = "";
  if (user.phone && user.country_code) {
    phone = user.country_code + "" + user.phone;
    if (phone[0] !== "+") phone = "+" + phone;
  }
  return phone;
};
function OTPVerification({
  onSubmit,
  phoneNumber,
  userToken,
  email,
  step,
}: Props) {
  const { user } = useAppSelector((state) => state.auth);
  const [codeSent, setIsCodeSent] = useState(false);
  const [uuid, setUuid] = useState<string | null>(null);

  const [inputValues, setInputValues] = useState({
    input1: "",
    input2: "",
    input3: "",
    input4: "",
    input5: "",
    input6: "",
  });

  const inputRefs: any = useRef([
    React.createRef(),
    React.createRef(),
    React.createRef(),
    React.createRef(),
    React.createRef(),
    React.createRef(),
  ]);

  const handleInputChange = (inputId: string, value: number) => {
    const index = parseInt(inputId.replace("input", ""), 10) - 1;

    setInputValues((prevInputValues) => ({
      ...prevInputValues,
      [inputId]: value,
    }));

    if (value && index < inputRefs.current.length - 1) {
      inputRefs?.current[index + 1]?.current?.focus();
    }
  };

  const sendEmail = async () => {
    //dispatch(registerEmail({ email }));

    const codeUUID = uuidGenerator();
    const response = await api.post(`verification-request`, {
      uuid: codeUUID,
      userToken: userToken ?? null,
    });
    setUuid(codeUUID);
    setIsCodeSent(true);
  };
  const resendMail = async () => {
    const response = await api.post(`verification-request`, {
      uuid: uuid,
      userToken: userToken ?? null,
    });
    setIsCodeSent(true);
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    const otp =
      inputValues.input1 +
      inputValues.input2 +
      inputValues.input3 +
      inputValues.input4 +
      inputValues.input5 +
      inputValues.input6;

    const data = {
      email: user && user.email ? user.email : email ? email : "",
      code: otp,
      uuid: uuid,
      userToken: userToken ?? null,
    };
    if (otp.length < 6) {
      return;
    }

    const response = await api.post(`verification-check`, {
      code: otp,
      uuid: uuid,
      userToken: userToken ?? null,
      type: "add-balance",
    });

    if (response) {
      if (response.data.success) {
        onSubmit(data);
        return true;
      }
    }
    toast.error(i18next.t("Please double check the verification code!"));
    setInputValues({
      input1: "",
      input2: "",
      input3: "",
      input4: "",
      input5: "",
      input6: "",
    });

    // dispatch(verifyEmail({email, code: otp}))
  };

  const phone = phoneNumber ? phoneNumber : user ? getPhone(user) : "";

  return (
    <div>
      {!codeSent ? (
        <div className="initial-screen d-flex ai-center jc-center fx-column ta-center content-container-home">
          <img src={imageUrl} className="mb-24" alt="r" width="150" />

          <h3 className="big-text fw-600 loginTitleFontSize">
            {i18next.t("CONFIRMATION CODE")}
          </h3>

          <div className="box-warning-center mt-2rem ta-start">
            <PriorityYellowIcon />
            <div>
              <h3
                className="small-text fw-300 oneRemFontSize"
                style={{
                  fontFamily: "Helvetica, sans-serif",
                  lineHeight: 1.5,
                }}
              >
                {i18next.t("Code will be sent to")}{" "}
                <strong>{phone && phone !== "" ? phone : ""}</strong>{" "}
                {i18next.t("and")}{" "}
                <strong>
                  {user && user.email ? user.email : email ? email : ""}
                </strong>
                .
              </h3>
            </div>
          </div>
          <Button
            style={{ marginTop: "2rem" }}
            type="submit"
            fullWidth
            variant="contained"
            className="btnsLoginHeight oneRemFontSize"
            sx={{
              color: "#000000",
              backgroundColor: "transparent",
              borderRadius: "24px",
              boxShadow: "none",
              border: "1px solid #000000",
              fontWeight: 600,
              textTransform: "none",
              fontFamily: "Helvetica, sans-serif",
              "&:hover": {
                backgroundColor: "#2A5182",
                color: "white", // Change the text color on hover if needed
              },
            }}
            onClick={sendEmail}
            disabled={false}
          >
            {i18next.t("Send verification code")}
          </Button>
        </div>
      ) : (
        <div className="initial-screen d-flex ai-center jc-center fx-column ta-center content-container-home">
          <img src={imageUrl} className="mb-24" alt="r" width="150" />

          <h3 className="big-text fw-600 loginTitleFontSize">
            {i18next.t("CONFIRMATION CODE")}
          </h3>

          <div className="box-warning mt-2rem ta-start">
            <PriorityYellowIcon />

            <h3
              className="small-text fw-300 oneRemFontSize"
              style={{
                fontFamily: "Helvetica, sans-serif",
                lineHeight: 1.5,
              }}
            >
              {i18next.t("Follow the message we sent to")}{" "}
              <strong>{phone && phone !== "" ? phone : ""}</strong>{" "}
              {i18next.t("or")}{" "}
              <strong>
                {user && user.email ? user.email : email ? email : ""}
              </strong>
              . {i18next.t("The message can take up to")}{" "}
              <strong>{i18next.t("1 minute")}</strong>{" "}
              {i18next.t("to arrive or")}{" "}
              <strong>{i18next.t("enter the code.")}</strong>
            </h3>
          </div>

          <div
            id="otp"
            className="inputs d-flex flex-row justify-content-center mt-2"
            style={{ paddingTop: "24px" }}
            data-autosubmit="true"
          >
            <p className="oneRemFontSize">{i18next.t("or")}</p>
            <OTPInput
              id="input1"
              value={inputValues.input1}
              onValueChange={handleInputChange}
              previousId={null}
              handleSubmit={handleSubmit}
              nextId="input2"
            />
            <OTPInput
              id="input2"
              value={inputValues.input2}
              onValueChange={handleInputChange}
              previousId="input1"
              handleSubmit={handleSubmit}
              nextId="input3"
            />
            <OTPInput
              id="input3"
              value={inputValues.input3}
              onValueChange={handleInputChange}
              previousId="input2"
              handleSubmit={handleSubmit}
              nextId="input4"
            />
            <OTPInput
              id="input4"
              value={inputValues.input4}
              onValueChange={handleInputChange}
              previousId="input3"
              handleSubmit={handleSubmit}
              nextId="input5"
            />

            <OTPInput
              id="input5"
              value={inputValues.input5}
              onValueChange={handleInputChange}
              previousId="input4"
              handleSubmit={handleSubmit}
              nextId="input6"
            />

            <OTPInput
              id="input6"
              value={inputValues.input6}
              onValueChange={handleInputChange}
              previousId="input5"
              handleSubmit={handleSubmit}
              nextId="input7"
            />
          </div>

          <Button
            style={{ marginTop: "2rem" }}
            type="submit"
            fullWidth
            variant="contained"
            className="btnsLoginHeight oneRemFontSize"
            sx={{
              color: "#000000",
              backgroundColor: "transparent",
              borderRadius: "24px",
              boxShadow: "none",
              border: "1px solid #000000",
              fontWeight: 600,
              textTransform: "none",
              fontFamily: "Helvetica, sans-serif",
              "&:hover": {
                backgroundColor: "#2A5182",
                color: "white", // Change the text color on hover if needed
              },
            }}
            onClick={resendMail}
            disabled={false}
          >
            {i18next.t("Resend code")}
          </Button>
        </div>
      )}
    </div>
  );
}

export default OTPVerification;
