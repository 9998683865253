import React from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Typography, Box } from "@mui/material";
import { useNavigate } from "react-router-dom";
import HeaderLanding from "../components/HeaderLanding/HeaderLanding";
import { useAppSelector } from "src/store";
import FooterLanding from "../components/FooterLanding/FooterLanding";
import "./LandingPagesGlobal.scss";
import IconTextWidget from "../components/IconTextWidget/IconTextWidget";
import currencyIcon from "../../../assets/images/currencies.png";
import merchantIcon from "../../../assets/images/merchant.png";
import users from "../../../assets/images/users.png";
import icon24 from "../../../assets/images/24.png";
import languages from "../../../assets/images/languages.png";
import LogoTextVvidget from "../components/LogoTextVvidget/LogoTextVvidget";
import ImageWithText from "../components/ImageWithText/ImageWithText";
import paymentsImage from "../../../assets/images/payments.jpg";
import TitleBackBox from "../components/TitleBackBox/TitleBackBox";
import HeadlineVvidgetSmall from "../components/HeadlineVvidget/HeadlineVvidgetSmall";
import i18next from "i18next";

const defaultTheme = createTheme();
const Individual: React.FC = () => {
  const { user } = useAppSelector((state: any) => state.auth);

  const navigate = useNavigate();

  const navigateToRegistration = (event: any) => {
    navigate("/registration");
  };

  return (
    <div>
      <HeaderLanding user={user} />
      <div className="margintTopPagesMainCont">
        <ThemeProvider theme={defaultTheme}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Box className="titleHeadlineSmallBG">
              <Box className="landingPagesMainBox">
                <Box>
                  <TitleBackBox title={i18next.t("Individual")} />
                </Box>
                <Box>
                  <HeadlineVvidgetSmall
                    headline={
                      i18next.t("Multilingual and 24/7 support for individual clients from all over the world")
                    }
                    subtitle={i18next.t(
                      "VicPay is the partner for anyone who has had enough of slow branch banks and high fees"
                    )
                    }
                    smallText={i18next.t("Join customers all over the world")}
                    buttonText={i18next.t("Create a free account")}
                    onClickFunction={navigateToRegistration}
                  />
                </Box>
              </Box>
            </Box>
            <Box className="landingPagesMainBox">
              <div className="icon-text-widgets-container">
                <IconTextWidget
                  iconSrc={currencyIcon}
                  title={i18next.t("Accounts in USD, EUR and other currencies")}
                  text={i18next.t("Accounts in USD, EUR and other currencies")}
                />
                <IconTextWidget
                  iconSrc={merchantIcon}
                  title={i18next.t("Local and international deposits and withdrawals")}
                  text={i18next.t("Local and international deposits and withdrawals")}
                />
                <IconTextWidget
                  iconSrc={languages}
                  title={i18next.t("Multilingual Support")}
                  text=""
                />
                <IconTextWidget
                  iconSrc={users}
                  title={i18next.t("Robust multi-tier account security")}
                  text={i18next.t("Robust multi-tier account security with 2FA")}
                />
                <IconTextWidget
                  iconSrc={icon24}
                  title={i18next.t("First response in 1 hour or faster")}
                  text={i18next.t("First response in 1 hour or faster")}
                />
              </div>

              <Box
                sx={{
                  marginBottom: "52px",
                  "@media(max-width: 1023px)": {
                    marginBottom: "76px",
                  },
                }}
              >
                <LogoTextVvidget
                  text={
                    i18next.t("We offer you IBAN accounts, up to 18 currencies for your account balance, local and international payments, wire bank transfers, instant payments, cryptocurrencies, and a VISA card for your cash spendings")
                  }
                />
              </Box>

              <div>
                <ImageWithText
                  backgroundImage={paymentsImage}
                  noiseImage="/img/payments-noise.png"
                  title={i18next.t("Send payments worldwide")}
                  text={i18next.t("Send, receive, and exchange fiat and cryptocurrencies and be sure that you always have full control over your spendings and fees")}
                />
              </div>
              <Box
                sx={{
                  marginTop: "4rem",
                  marginBottom: "4rem",
                  display: "grid",
                  gridTemplateColumns: ["1fr", "1fr 1fr"],
                  gap: [2, 5],
                }}
              >
                <Box>
                  <Typography
                    variant="h3"
                    sx={{
                      marginBottom: "1rem",
                    }}
                    className="headlineMiniLandingPages"
                  >
                    {" "}
                    {i18next.t("One partner")}
                  </Typography>
                  <Typography
                    variant="body1"
                    paragraph
                    className="textsLandingPage"
                  >
                    {i18next.t("Do you only want one partner for digital transfers, cryptocurrencies, and currency exchange")}
                  </Typography>
                  <Typography
                    variant="body1"
                    paragraph
                    className="textsLandingPage"
                  >
                    {i18next.t("Security and data protection are your top priorities")}
                  </Typography>
                </Box>
                <Box>
                  <Typography
                    variant="h3"
                    sx={{
                      marginBottom: "1rem",
                    }}
                    className="headlineMiniLandingPages"
                  >
                    {" "}
                    {i18next.t("Secure Discounts Everywhere")}
                  </Typography>
                  <Typography
                    variant="body1"
                    paragraph
                    className="textsLandingPage"
                  >
                    {i18next.t("Enjoy discounts shopping online and offline and be sure that the highest measures of security are protecting your online account and your funds")}
                  </Typography>
                </Box>
              </Box>
              <Box>
                <Typography
                  variant="h3"
                  sx={{ marginBottom: "1rem" }}
                  className="headlineLandingPages"
                >
                  {i18next.t("Create free account now")}
                </Typography>
                <Box sx={{ marginTop: "2rem" }}>
                  <Typography
                    variant="h3"
                    sx={{
                      marginBottom: "1rem",
                    }}
                    className="headlineMiniLandingPages"
                  >
                    {" "}
                    {i18next.t("Join Us Worldwide")}
                  </Typography>
                  <Typography
                    variant="body1"
                    paragraph
                    className="textsLandingPage"
                  >
                    {i18next.t("Join customers all over the world and create a free account")}{" "}
                    <span
                      className="linksHover"
                      onClick={() => navigate("/registration")}
                    >
                      {i18next.t("here")}
                    </span>
                    !
                  </Typography>
                </Box>
                <Box sx={{ marginTop: "2rem" }}>
                  <Typography
                    variant="h3"
                    sx={{
                      marginBottom: "1rem",
                    }}
                    className="headlineMiniLandingPages"
                  >
                    {" "}
                    {i18next.t("24/7 Multilingual Support")}
                  </Typography>
                  <Typography
                    variant="body1"
                    paragraph
                    className="textsLandingPage"
                  >
                    {i18next.t("Based in Vancouver, Canada our international team is available on 365/24/7 and support clients from all over the world in English, French, Spanish, Italian, Russian, Arabic, Chinese, and Farsi")}
                  </Typography>
                </Box>
                <Box sx={{ marginTop: "2rem" }}>
                  <Typography
                    variant="h3"
                    sx={{
                      marginBottom: "1rem",
                    }}
                    className="headlineMiniLandingPages"
                  >
                    {" "}
                    {i18next.t("Contact Us Any Way You Prefer")}
                  </Typography>
                  <Typography
                    variant="body1"
                    paragraph
                    className="textsLandingPage"
                  >
                    {i18next.t("Simply dial +1 604-260-0738 or click")}{" "}
                    <span
                      className="linksHover"
                      onClick={() => navigate("/contact-us")}
                    >
                      {i18next.t("here")}
                    </span>{" "}
                    {i18next.t("to get in touch with us! In case you want to pay us a visit, click")}{" "}
                    <span
                      className="linksHover"
                      onClick={() => navigate("/about-us")}
                    >
                      {i18next.t("here")}
                    </span>{" "}
                    {i18next.t("to find the opening hours and address details")}
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>
        </ThemeProvider>
      </div>
      <Box sx={{ marginTop: "4rem" }}>
        <FooterLanding />
      </Box>
    </div>
  );
};

export default Individual;
