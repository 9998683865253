import React from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import Typography from "@mui/material/Typography";
import { useNavigate } from "react-router-dom";
import { capitalizeWords } from "src/utils/helpers";
import UserIcon from "src/assets/images/user.png";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import AdminPanelSettingsOutlinedIcon from "@mui/icons-material/AdminPanelSettingsOutlined";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import LogoutOutlinedIcon from "@mui/icons-material/LogoutOutlined";
import QuestionAnswerOutlinedIcon from "@mui/icons-material/QuestionAnswerOutlined";
import PeopleIcon from "@mui/icons-material/People";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import "./Nav.scss";
import { Avatar } from "@mui/material";
import i18next from "src/i18n";

type HamMenuProps = {
  isOpen: boolean;
  closeMenu: () => void;
  logout: () => void;
  profile: () => void;
  admin: () => void;
  getUserDisplayName: (userObject: any) => string;
  branches: () => void;
  redirect: (path: string) => void;
  user: any;
  userObject: any;
};

const HamMenu: React.FC<HamMenuProps> = ({
  isOpen,
  closeMenu,
  logout,
  profile,
  user,
  getUserDisplayName,
  admin,
  branches,
  redirect,
  userObject,
}) => {
  const navigate = useNavigate();

  const handleNavigate = (path: string) => {
    navigate(path);
  };

  return (
    <div>
      <Drawer anchor="left" open={isOpen} onClose={closeMenu}>
        <Box
          sx={{
            width: "280px",
            backgroundColor: "#fff",
            padding: "20px",
          }}
        >
          <div onClick={closeMenu}>
            <ArrowBackIcon
              className="iconsHamMenu"
              style={{ cursor: "pointer" }}
            />
          </div>

          <div className="profile-container-mobile image-container image-container-ham">
            <Avatar
              src={
                userObject?.image?.file_url
                  ? userObject.image.file_url
                  : UserIcon
              }
              alt={getUserDisplayName(userObject)}
              sx={{ width: 48, height: 48, objectFit: "cover" }}
            />

            {userObject && (
              <Typography
                className="small-text blue-text ml-8"
                style={{
                  fontFamily: "Helvetica, sans-serif",
                  fontWeight: 300,
                  fontSize: 14,
                  whiteSpace: "nowrap",
                  maxWidth: "230px",
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                }}
              >
                {getUserDisplayName(userObject)}
              </Typography>
            )}
          </div>

          <div className="ham-menu-items-list">
            {(user?.role === "Admin" || user?.role === "Employee") && (
              <div>
                <div className="ham-menu-item" onClick={admin}>
                  <AdminPanelSettingsOutlinedIcon className="iconsHamMenu" />
                  <Typography className="mobileMenuItems">
                    {i18next.t("Admin")}
                  </Typography>
                </div>

                <div className="small items" style={{ marginLeft: "21px" }}>
                  <div className="small items" style={{ marginLeft: "21px" }}>
                    <div
                      className="ham-menu-item ml-8"
                      onClick={() => handleNavigate("/admin")}
                    >
                      <Typography className="mobileMenuItems">
                        {i18next.t("Home")}
                      </Typography>
                    </div>
                    <div
                      className="ham-menu-item ml-8"
                      onClick={() => handleNavigate("/admin/branches")}
                    >
                      <Typography className="mobileMenuItems">
                        {i18next.t("Branches")}
                      </Typography>
                    </div>
                    <div
                      className="ham-menu-item ml-8"
                      onClick={() => handleNavigate("/admin/users")}
                    >
                      <Typography className="mobileMenuItems">
                        {i18next.t("Users")}
                      </Typography>
                    </div>
                    <div
                      className="ham-menu-item ml-8"
                      onClick={() => handleNavigate("/admin/recipients")}
                    >
                      <Typography className="mobileMenuItems">
                        {i18next.t("Recipients")}
                      </Typography>
                    </div>
                    <div
                      className="ham-menu-item ml-8"
                      onClick={() =>
                        handleNavigate("/admin/balance-list/request-payments")
                      }
                    >
                      <Typography className="mobileMenuItems">
                        {i18next.t("Requests")}
                      </Typography>
                    </div>
                    {/* <div
                      className="ham-menu-item ml-8"
                      onClick={() => handleNavigate("/admin/investments")}
                    >
                      <Typography className="mobileMenuItems">
                        Investments
                      </Typography>
                    </div> */}
                  </div>
                </div>
              </div>
            )}

            <div>
              <div className="ham-menu-item" onClick={profile}>
                <PersonOutlinedIcon className="iconsHamMenu" />
                <Typography className="mobileMenuItems">
                  {i18next.t("Profile")}
                </Typography>
              </div>

              <div className="small items" style={{ marginLeft: "21px" }}>
                <div className="small items" style={{ marginLeft: "21px" }}>
                  <div
                    className="ham-menu-item ml-8"
                    onClick={() => handleNavigate("/add-balance")}
                  >
                    <Typography className="mobileMenuItems">
                      {i18next.t("Add Balance")}
                    </Typography>
                  </div>
                  <div
                    className="ham-menu-item ml-8"
                    onClick={() =>
                      handleNavigate("/balance-list/request-payments")
                    }
                  >
                    <Typography className="mobileMenuItems">
                      {i18next.t("Requests")}
                    </Typography>
                  </div>
                  <div
                    className="ham-menu-item ml-8"
                    onClick={() => handleNavigate("/enterpayment")}
                  >
                    <Typography className="mobileMenuItems">
                      {i18next.t("Create Transaction")}
                    </Typography>
                  </div>
                  {/* <div
                    className="ham-menu-item ml-8"
                    onClick={() => handleNavigate("/account-transfer")}
                  >
                    <Typography className="mobileMenuItems">
                      {i18next.t("Account Transfer")}
                    </Typography>
                  </div> */}
                  <div
                    className="ham-menu-item ml-8"
                    onClick={() => handleNavigate("/transactions-list")}
                  >
                    <Typography className="mobileMenuItems">
                      {i18next.t("Transactions")}
                    </Typography>
                  </div>
                  {/* <div
                    className="ham-menu-item ml-8"
                    onClick={() => handleNavigate("/investment")}
                  >
                    <Typography className="mobileMenuItems">
                      Investment
                    </Typography>
                  </div> */}
                </div>
              </div>
            </div>

            {user?.role !== "Employee" && (
              <div
                className="ham-menu-item"
                onClick={() => redirect("banking")}
              >
                <CreditCardIcon className="iconsHamMenu" />
                <Typography className="mobileMenuItems">
                  {i18next.t("Banking")}
                </Typography>
              </div>
            )}

            {/* {user?.role !== "Employee" && (
              <div
                className="ham-menu-item"
                onClick={() => redirect("account-details")}
              >
                <CreditCardIcon className="iconsHamMenu" />
                <Typography className="mobileMenuItems">
                  {i18next.t("Account details")}
                </Typography>
              </div>
            )} */}

            <div
              className="ham-menu-item"
              onClick={() => redirect("recipients")}
            >
              <PeopleIcon className="iconsHamMenu" />
              <Typography className="mobileMenuItems">
                {i18next.t("Recipients")}
              </Typography>
            </div>
            {/* {user?.role !== "Employee" && (
              <div
                className="ham-menu-item"
                onClick={() =>
                  redirect("home?action=add-currency&t=" + Date.now())
                }
              >
                <CreditCardIcon className="iconsHamMenu" />
                <Typography className="mobileMenuItems">
                  {i18next.t("Add currency")}
                </Typography>
              </div>
            )} */}
            <div className="ham-menu-item" onClick={() => redirect("settings")}>
              <SettingsOutlinedIcon className="iconsHamMenu" />
              <Typography className="mobileMenuItems">
                {i18next.t("Settings")}
              </Typography>
            </div>

            <div
              className="ham-menu-item"
              onClick={() =>
                redirect(
                  user?.role === "Admin" || user?.role === "Employee"
                    ? "admin/chat"
                    : "chat"
                )
              }
            >
              <QuestionAnswerOutlinedIcon className="iconsHamMenu" />
              <Typography className="mobileMenuItems">
                {i18next.t("Support")}
              </Typography>
            </div>

            <div onClick={logout} className="ham-menu-item">
              <LogoutOutlinedIcon className="iconsHamMenu" />
              <Typography className="mobileMenuItems">
                {i18next.t("Logout")}
              </Typography>
            </div>
          </div>
        </Box>
      </Drawer>
    </div>
  );
};

export default HamMenu;
