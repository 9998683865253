import {
  Box,
  Typography,
  MenuItem,
  Button,
  TextareaAutosize,
} from "@mui/material";
import { formatDate, formatInvestmentNumber } from "src/utils/helpers";
import { useState } from "react";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import api from "src/store/interceptors/api";
import LoadingIndicator from "../shared/LoadingIndicator";

const statusOptions = [
  "Created",
  "In process",
  "Completed",
  "Rejected",
  "Deleted",
];
function InvestmentDetail({
  data,
  redirectPage,
  hideNavigation,
  style,
  handleGetInvestment,
  fromPage,
}: any) {
  const [note, setNote] = useState<string>("");
  const [status, setStatus] = useState<string>(data.status);
  const [isLoading, setIsLoading] = useState(false);

  const handleChange = (event: SelectChangeEvent) => {
    setStatus(event.target.value as string);
  };

  const handleChangeNote = (event: any) => {
    setNote(event.target.value as string);
  };

  const handleSubmit = async () => {
    setIsLoading(true);
    const payload: any = {
      status: status,
      _method: "PATCH",
    };
    if (note && note !== "") {
      payload.note = note;
    }

    if (data.id) {
      await api.post(`investment-requests/` + data.id, payload);
      setIsLoading(false);
      setNote("");
      window.location.reload();

      //handleGetInvestment();
    } else {
      setIsLoading(false);
    }
  };

  return (
    <Box className="recentBox">
      {!data ? (
        <Typography className="noDataText">No investment found</Typography>
      ) : isLoading ? (
        <LoadingIndicator />
      ) : (
        <Box>
          <Box sx={{ mt: 4 }}>
            <div className="d-flex jusifyContentSpaceBetween ai-center mb-1rem">
              <Typography
                className="d-flex jc-start"
                component="h1"
                variant="h5"
                sx={{
                  fontFamily: "Helvetica, sans-serif",
                  fontSize: "1.375rem",
                  fontWeight: "600",
                }}
              >
                Personal details
              </Typography>
            </div>

            <div className="personal-box">
              {data &&
                data.user &&
                data.user.company_name &&
                data.user.account_type === "business" && (
                  <div className="one-flex-line">
                    <div className="desc-name-box">
                      <Typography
                        className="mb-1rem  d-flex jc-start"
                        component="h1"
                        variant="h5"
                        sx={{
                          fontFamily: "Helvetica, sans-serif",
                          fontSize: "0.875rem",
                          fontWeight: ".875rem",
                          color: "#454745",
                        }}
                      >
                        Company name
                      </Typography>

                      <Typography
                        className="mb-1rem  d-flex jc-start"
                        component="h1"
                        variant="h5"
                        sx={{
                          fontFamily: "Helvetica, sans-serif",
                          fontSize: "0.875rem",
                          fontWeight: ".875rem",
                          color: "#454745",
                        }}
                      >
                        {data?.user?.company_name}
                      </Typography>
                    </div>
                  </div>
                )}

              <div className="one-flex-line mt-16">
                <div className="desc-name-box">
                  <Typography
                    className="mb-1rem  d-flex jc-start"
                    component="h1"
                    variant="h5"
                    sx={{
                      fontFamily: "Helvetica, sans-serif",
                      fontSize: "0.875rem",
                      fontWeight: ".875rem",
                      color: "#454745",
                    }}
                  >
                    First name
                  </Typography>

                  <Typography
                    className="mb-1rem  d-flex jc-start"
                    component="h1"
                    variant="h5"
                    sx={{
                      fontFamily: "Helvetica, sans-serif",
                      fontSize: "0.875rem",
                      fontWeight: ".875rem",
                      color: "#454745",
                    }}
                  >
                    {data?.user?.first_name}
                  </Typography>
                </div>

                <div className="desc-name-box">
                  <Typography
                    className="mb-1rem  d-flex jc-start"
                    component="h1"
                    variant="h5"
                    sx={{
                      fontFamily: "Helvetica, sans-serif",
                      fontSize: "0.875rem",
                      fontWeight: ".875rem",
                      color: "#454745",
                    }}
                  >
                    Last name
                  </Typography>

                  <Typography
                    className="mb-1rem  d-flex jc-start"
                    component="h1"
                    variant="h5"
                    sx={{
                      fontFamily: "Helvetica, sans-serif",
                      fontSize: "0.875rem",
                      fontWeight: ".875rem",
                      color: "#454745",
                    }}
                  >
                    {data?.user?.last_name}
                  </Typography>
                </div>
              </div>

              <div className="one-flex-line mt-16">
                <div className="desc-name-box">
                  <Typography
                    className="mb-1rem  d-flex jc-start"
                    component="h1"
                    variant="h5"
                    sx={{
                      fontFamily: "Helvetica, sans-serif",
                      fontSize: "0.875rem",
                      fontWeight: ".875rem",
                      color: "#454745",
                    }}
                  >
                    Email
                  </Typography>

                  <Typography
                    className="mb-1rem  d-flex jc-start"
                    component="h1"
                    variant="h5"
                    sx={{
                      fontFamily: "Helvetica, sans-serif",
                      fontSize: "0.875rem",
                      fontWeight: ".875rem",
                      color: "#454745",
                    }}
                  >
                    {data?.user?.email ?? ""}
                  </Typography>
                </div>

                <div className="desc-name-box">
                  <Typography
                    className="mb-1rem  d-flex jc-start"
                    component="h1"
                    variant="h5"
                    sx={{
                      fontFamily: "Helvetica, sans-serif",
                      fontSize: "0.875rem",
                      fontWeight: ".875rem",
                      color: "#454745",
                    }}
                  >
                    Phone
                  </Typography>

                  <Typography
                    className="mb-1rem  d-flex jc-start"
                    component="h1"
                    variant="h5"
                    sx={{
                      fontFamily: "Helvetica, sans-serif",
                      fontSize: "0.875rem",
                      fontWeight: ".875rem",
                      color: "#454745",
                    }}
                  >
                    {data?.user?.country_code ?? ""} {data?.user?.phone ?? ""}
                  </Typography>
                </div>
              </div>
            </div>
          </Box>
          <Box sx={{ mt: 4 }}>
            <div className="d-flex jusifyContentSpaceBetween ai-center mb-1rem">
              <Typography
                className="d-flex jc-start"
                component="h1"
                variant="h5"
                sx={{
                  fontFamily: "Helvetica, sans-serif",
                  fontSize: "1.375rem",
                  fontWeight: "600",
                }}
              >
                Investment details
              </Typography>
            </div>

            <div className="personal-box">
              <div style={{ flex: 1, paddingTop: 5, paddingBottom: 5 }}>
                <div>
                  <Typography
                    className="mb-1rem  d-flex jc-start"
                    component="h1"
                    variant="h5"
                    sx={{
                      fontFamily: "Helvetica, sans-serif",
                      fontSize: "0.875rem",
                      fontWeight: ".875rem",
                      color: "#454745",
                    }}
                  >
                    ID
                  </Typography>
                </div>
                <div>
                  <Typography
                    className="mb-1rem  d-flex jc-start"
                    component="h1"
                    variant="h5"
                    sx={{
                      fontFamily: "Helvetica, sans-serif",
                      fontSize: "0.875rem",
                      fontWeight: ".875rem",
                      color: "#454745",
                    }}
                  >
                    <b>
                      {data && data.id ? formatInvestmentNumber(data.id) : ""}
                    </b>
                  </Typography>
                </div>
              </div>
              <div className="one-flex-line">
                <div className="desc-name-box">
                  <Typography
                    className="mb-1rem  d-flex jc-start"
                    component="h1"
                    variant="h5"
                    sx={{
                      fontFamily: "Helvetica, sans-serif",
                      fontSize: "0.875rem",
                      fontWeight: ".875rem",
                      color: "#454745",
                    }}
                  >
                    Date requested
                  </Typography>

                  <Typography
                    className="mb-1rem  d-flex jc-start"
                    component="h1"
                    variant="h5"
                    sx={{
                      fontFamily: "Helvetica, sans-serif",
                      fontSize: "0.875rem",
                      fontWeight: ".875rem",
                      color: "#454745",
                    }}
                  >
                    <b>
                      {data && data.created_at
                        ? formatDate(data.created_at)
                        : ""}
                    </b>
                  </Typography>
                </div>

                <div className="desc-name-box">
                  <Typography
                    className="mb-1rem  d-flex jc-start"
                    component="h1"
                    variant="h5"
                    sx={{
                      fontFamily: "Helvetica, sans-serif",
                      fontSize: "0.875rem",
                      fontWeight: ".875rem",
                      color: "#454745",
                    }}
                  >
                    Amount
                  </Typography>

                  <Typography
                    className="mb-1rem  d-flex jc-start"
                    component="h1"
                    variant="h5"
                    sx={{
                      fontFamily: "Helvetica, sans-serif",
                      fontSize: "0.875rem",
                      fontWeight: ".875rem",
                      color: "#454745",
                    }}
                  >
                    <b>{data?.amount ?? ""}</b>
                  </Typography>
                </div>
              </div>

              <div className="two-flex-line mt-16">
                <div>
                  <Typography
                    className="mb-1rem  d-flex jc-start"
                    component="h1"
                    variant="h5"
                    sx={{
                      fontFamily: "Helvetica, sans-serif",
                      fontSize: "0.875rem",
                      fontWeight: ".875rem",
                      color: "#454745",
                    }}
                  >
                    Note
                  </Typography>

                  <Typography
                    className="mb-1rem  d-flex jc-start"
                    component="h1"
                    variant="h5"
                    sx={{
                      fontFamily: "Helvetica, sans-serif",
                      fontSize: "0.875rem",
                      fontWeight: ".875rem",
                      color: "#454745",
                    }}
                  >
                    {data?.note ?? "-"}
                  </Typography>
                </div>
              </div>
            </div>
          </Box>

          <Box sx={{ mt: 4, mb: 4 }}>
            <div className="d-flex jusifyContentSpaceBetween ai-center mb-1rem">
              <Typography
                className="d-flex jc-start"
                component="h1"
                variant="h5"
                sx={{
                  fontFamily: "Helvetica, sans-serif",
                  fontSize: "1.375rem",
                  fontWeight: "600",
                }}
              >
                Current status
              </Typography>
            </div>

            <div className="personal-box">
              <div className="two-flex-line">
                <div className="desc-name-box">
                  <Typography
                    className="mb-1rem  d-flex jc-start"
                    component="h1"
                    variant="h5"
                    sx={{
                      fontFamily: "Helvetica, sans-serif",
                      fontSize: "0.875rem",
                      fontWeight: ".875rem",
                      color: "#454745",
                    }}
                  >
                    Status: <b className="ml-small">{data.status ?? ""}</b>
                  </Typography>
                </div>
              </div>
              {fromPage &&
                fromPage === "admin" &&
                data &&
                data.status &&
                data.status !== "Completed" && (
                  <div style={{ paddingBottom: 40 }}>
                    <div className="pt-1rem pb-1rem">
                      <Typography
                        className="mb-1rem  d-flex jc-start"
                        component="h1"
                        variant="h5"
                        sx={{
                          fontFamily: "Helvetica, sans-serif",
                          fontSize: "0.875rem",
                          fontWeight: ".875rem",
                          color: "#454745",
                        }}
                      >
                        Change status
                      </Typography>

                      <Select
                        style={{ width: "100%" }}
                        defaultValue={status}
                        value={status}
                        onChange={handleChange}
                      >
                        {statusOptions.map((statusItem, statusItemIndex) => {
                          return (
                            <MenuItem
                              key={"statusitem" + statusItem + statusItemIndex}
                              value={statusItem}
                            >
                              {statusItem}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </div>
                    <div>
                      <div className="mt-16 w-100">
                        <TextareaAutosize
                          value={note}
                          onChange={handleChangeNote}
                          maxRows={4}
                          minRows={4}
                          aria-label="maximum height"
                          placeholder="Write notes..."
                          style={{
                            fontFamily: "Helvetica, sans-serif",
                            fontSize: "0.9rem",
                            width: "100%",
                            resize: "none",
                            paddingTop: "1rem",
                            paddingLeft: "1rem",
                            borderRadius: "10px",
                          }}
                        />
                      </div>
                      <div className="d-flex ai-center jusifyContentSpaceBetween mt-8">
                        <Button
                          type="submit"
                          fullWidth
                          variant="contained"
                          sx={{
                            color: "white",
                            backgroundColor: "#2A5182",
                            borderRadius: "24px",
                            height: "3rem",
                            textTransform: "none",
                            marginTop: "0.5rem",
                            marginBottom: "1rem",
                            fontFamily: "Helvetica, sans-serif",
                            fontSize: "0.9rem",
                          }}
                          disabled={status === data.status}
                          onClick={handleSubmit}
                        >
                          Update status
                        </Button>
                      </div>
                    </div>
                  </div>
                )}
            </div>
          </Box>

          <Box sx={{ mt: 4, mb: 4 }}>
            <div className="d-flex jusifyContentSpaceBetween ai-center mb-1rem">
              <Typography
                className="d-flex jc-start"
                component="h1"
                variant="h5"
                sx={{
                  fontFamily: "Helvetica, sans-serif",
                  fontSize: "1.375rem",
                  fontWeight: "600",
                }}
              >
                Log
              </Typography>
            </div>

            <div className="personal-box">
              {data &&
                data.investment_logs &&
                data.investment_logs.map((logItem: any, logIndex: number) => {
                  return (
                    <div
                      key={"logitem" + logIndex}
                      style={{ paddingTop: 10, paddingBottom: 10 }}
                    >
                      <div className="one-flex-line">
                        <div className="desc-name-box">
                          <Typography
                            className="mb-1rem  d-flex jc-start"
                            component="h1"
                            variant="h5"
                            sx={{
                              fontFamily: "Helvetica, sans-serif",
                              fontSize: "0.875rem",
                              fontWeight: ".875rem",
                              color: "#454745",
                            }}
                          >
                            Date updated
                          </Typography>

                          <Typography
                            className="mb-1rem  d-flex jc-start"
                            component="h1"
                            variant="h5"
                            sx={{
                              fontFamily: "Helvetica, sans-serif",
                              fontSize: "0.875rem",
                              fontWeight: ".875rem",
                              color: "#454745",
                            }}
                          >
                            <b>
                              {logItem && logItem.created_at
                                ? formatDate(logItem.created_at)
                                : ""}
                            </b>
                          </Typography>
                        </div>

                        <div className="desc-name-box">
                          <Typography
                            className="mb-1rem  d-flex jc-start"
                            component="h1"
                            variant="h5"
                            sx={{
                              fontFamily: "Helvetica, sans-serif",
                              fontSize: "0.875rem",
                              fontWeight: ".875rem",
                              color: "#454745",
                            }}
                          >
                            Status
                          </Typography>

                          <Typography
                            className="mb-1rem  d-flex jc-start"
                            component="h1"
                            variant="h5"
                            sx={{
                              fontFamily: "Helvetica, sans-serif",
                              fontSize: "0.875rem",
                              fontWeight: ".875rem",
                              color: "#454745",
                            }}
                          >
                            <b>{logItem?.status ?? ""}</b>
                          </Typography>
                        </div>
                      </div>

                      <div>
                        <Typography
                          className="mb-1rem  d-flex jc-start"
                          component="h1"
                          variant="h5"
                          sx={{
                            fontFamily: "Helvetica, sans-serif",
                            fontSize: "0.875rem",
                            fontWeight: ".875rem",
                            color: "#454745",
                          }}
                        >
                          Note
                        </Typography>

                        <Typography
                          className="mb-1rem  d-flex jc-start"
                          component="h1"
                          variant="h5"
                          sx={{
                            fontFamily: "Helvetica, sans-serif",
                            fontSize: "0.875rem",
                            fontWeight: ".875rem",
                            color: "#454745",
                          }}
                        >
                          {logItem?.note ?? ""}
                        </Typography>
                      </div>
                      <div className="horizontal-line-list mb-8 mb-2rem mt-2rem"></div>
                    </div>
                  );
                })}
            </div>
          </Box>
        </Box>
      )}
    </Box>
  );
}

export default InvestmentDetail;
