import { Box, Button, Typography } from "@mui/material";
import "./FiltersDrawer.scss";
import HighlightOffRoundedIcon from "@mui/icons-material/HighlightOffRounded";
import { useEffect, useState } from "react";
import api from "src/store/interceptors/api";
import i18next from "src/i18n";

interface FiltersProps {
  selectedFilters: any;
  handleSelectedFilters: (filters: any) => void;
}
function CurrencyFilters({
  selectedFilters,
  handleSelectedFilters,
}: FiltersProps) {
  const [currencies, setCurrencies] = useState<any>(null);
  const getInitialData = async () => {
    await api
      .get("/profile/balances")
      .then((response: any) => {
        setCurrencies(response.data);
      })
      .catch((error) => {
        console.error(error.message);
      });
  };

  const handleToggleCurrency = (currency: any) => {
    const newFilters = { ...selectedFilters };

    if (isCurrencySelected(currency)) {
      delete newFilters.currency;
    } else {
      newFilters.currency = { selectedCurrency: currency };
    }

    handleSelectedFilters(newFilters);
  };

  const isCurrencySelected = (currency: any) => {
    return (
      selectedFilters.currency?.selectedCurrency?.currency === currency.currency
    );
  };

  useEffect(() => {
    getInitialData();
  }, []);

  return (
    <Box>
      <Typography className="subBodies">{i18next.t("Currency")}</Typography>
      <Box className="btnTypesBox">
        {currencies &&
          currencies.length > 0 &&
          currencies.map((currency: any) => {
            return (
              <Button
                key={"currency" + currency.id}
                onClick={() => handleToggleCurrency(currency)}
                className={`buttonType ${
                  isCurrencySelected(currency) ? "buttonTypeActive" : ""
                }`}
                endIcon={
                  isCurrencySelected(currency) ? (
                    <HighlightOffRoundedIcon />
                  ) : null
                }
              >
                {currency.currency}
              </Button>
            );
          })}
      </Box>
    </Box>
  );
}

export default CurrencyFilters;
