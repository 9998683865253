import { Link, Box } from "@mui/material";

function FilePreview({ src, alt, width, type, style, file }: any) {
  const fileType: string =
    file &&
    ((file.mime && file.mime === "pdf") ||
      (file.type && file.type === "application/pdf"))
      ? "file"
      : "image";
  return fileType === "file" ? (
    <Box>
      <Link
        target="_blank"
        underline="hover"
        sx={{ textDecoration: "none", color: "#000" }}
        variant="body1"
        href={src}
        title={alt}
      >
        Preview File
      </Link>
    </Box>
  ) : (
    // eslint-disable-next-line jsx-a11y/img-redundant-alt
    <img src={src} width={width} alt={alt} style={style ?? {}} />
  );
}

export default FilePreview;
