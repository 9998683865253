import { Box, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import AccountBox from "src/components/shared/AccountBox/AccountBox";
import Tabs, { tabsClasses } from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import api from "src/store/interceptors/api";
import UserDetailsTabs from "../../UserDetailsTabs/UserDetailsTabs";
import { useNavigate } from "react-router-dom";

function UserBalance({ data }: any) {
  const [user, setUser] = useState(data);
  const navigate = useNavigate();
  const [loggedUser, setLoggedUser] = useState("");

  const handleRedirect = (id: any) => {
    navigate("/account-details/" + id);
  };

  const getLoggedUserRole = async () => {
    try {
      const response = await api.get(`me`);
      const loggedUserRole =
        response && response.data && response.data.data.role;
      if (loggedUserRole) {
        setLoggedUser(loggedUserRole);
      }
    } catch (exception) {
      console.log(exception);
    }
  };

  useEffect(() => {
    getLoggedUserRole();
  }, [loggedUser]);

  useEffect(() => {
    setUser(data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return (
    <Box className="recentBox">
      {!user ? (
        <Typography className="noDataText">No user found</Typography>
      ) : (
        <Box>
          <UserDetailsTabs user={user} />
          <Box sx={{ mt: 2 }}>
            {/* <div className="d-flex ai-center mb-3-rem">
              <Typography
                className="your-details-text d-flex jc-start"
                component="h1"
                variant="h5"
                sx={{
                  fontFamily: "Helvetica, sans-serif",
                  fontSize: "1.875rem",
                  textAlign: "center",
                  fontWeight: "600",
                }}
              >
                {user &&
                user.role &&
                (user.role === "Admin" || user.role === "Employee")
                  ? "Account details"
                  : "Your details"}
              </Typography>
            </div> */}
          </Box>

          <Box>
            <Typography
              className="your-details-text d-flex jc-start"
              component="h1"
              variant="h5"
              sx={{
                fontFamily: "Helvetica, sans-serif",
                fontSize: "1.875rem",
                fontWeight: "600",
                textAlign: "start",
              }}
            >
              Balance
            </Typography>
            {user && user.balances && user.balances.length > 0 ? (
              <Box style={{ marginTop: 20 }}>
                <Tabs
                  value={0}
                  TabIndicatorProps={{
                    style: { display: "none" },
                  }}
                  variant="scrollable"
                  scrollButtons
                  aria-label="visible arrows tabs example"
                  sx={{
                    padding: 0,
                    [`& .${tabsClasses.scrollButtons}`]: {
                      "&.Mui-disabled": {
                        opacity: 0.3,
                        backgroundColor: "#eaeaea",
                        color: "#9e9e9e",
                      },
                      backgroundColor: "#2A5182",
                      opacity: 1,
                      color: "#fff",
                    },
                  }}
                  TabScrollButtonProps={{
                    style: {
                      width: "32px",
                      height: "32px",
                      borderRadius: "50%",
                      margin: "auto 10px auto 10px",
                    },
                  }}
                >
                  {user &&
                    user.balances &&
                    user.balances.map((balanceItem: any, balanceKey: any) => (
                      <Tab
                        sx={{
                          padding: 0.75,
                        }}
                        key={"balance" + balanceKey}
                        disableRipple={true}
                        label={
                          <AccountBox
                          user={user}
                            currencyIcon={balanceItem?.currency?.code}
                            title={balanceItem?.currency?.code ?? ""}
                            content={balanceItem?.balance ?? "0,00"}
                            pendingBalance={balanceItem?.pending ?? 0}
                            onClick={() => handleRedirect(balanceItem.id)}
                          />
                        }
                      />
                    ))}
                </Tabs>
              </Box>
            ) : (
              <Box style={{ marginTop: 20 }}>
                <Typography
                  component="h5"
                  variant="h5"
                  className="welcome-text d-flex jc-start"
                  sx={{
                    fontFamily: "Helvetica, sans-serif",
                    fontSize: "1.2rem",
                    textAlign: "left",
                    color: "#000",
                  }}
                >
                  No balance!
                </Typography>
              </Box>
            )}
          </Box>
        </Box>
      )}
    </Box>
  );
}

export default UserBalance;
