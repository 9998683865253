import { FormControl, MenuItem, Select, Typography } from "@mui/material";
import { PureComponent } from "react";
import {
  BarChart,
  Bar,
  Rectangle,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import i18next from "src/i18n";

interface ChartBarProps {
  data: any;
  keyBars: any;
  title: string;
  rightTitle: string;
  initialThreeBoxesPeriod?: string; // New prop for the selected value
  handleThreeBoxesChange?: any;
}

export default class ChartBar extends PureComponent<ChartBarProps> {
  render() {
    const {
      data,
      keyBars,
      title,
      rightTitle,
      initialThreeBoxesPeriod,
      handleThreeBoxesChange,
    } = this.props;
    return (
      <div
        style={{
          height: "400px",
          width: "100%",
          backgroundColor: "rgb(255, 255, 255)",
          borderRadius: "16px",
          padding: "30px",
          boxShadow:
            "rgba(145, 158, 171, 0.2) 0px 0px 2px 0px, rgba(145, 158, 171, 0.12) 0px 12px 24px -4px",
        }}
      >
        <div className="d-flex jusifyContentSpaceBetween">
          <Typography
            sx={{
              fontFamily: "Inter, sans-serif",
              fontSize: "16px",
              fontWeight: 600,
              color: "rgb(33, 43, 54)",
            }}
            variant="subtitle1"
          >
            {title}
          </Typography>

          {rightTitle !== "select" ? (
            <Typography
              sx={{
                fontFamily: "Inter, sans-serif",
                fontSize: "16px",
                fontWeight: 600,
                color: "rgb(33, 43, 54)",
              }}
              variant="subtitle1"
            >
              {rightTitle}
            </Typography>
          ) : (
            <FormControl>
              <Select
                sx={{
                  height: "30px",
                  borderRadius: "16px",
                  backgroundColor: "rgb(244, 246, 248)",
                  fontSize: "14px",
                  marginBottom: "1rem",
                  color: "#2A5182",
                  border: "none",
                  "&:focus": {
                    outline: "none",
                    borderRadius: "8px",
                  },
                  "& .MuiOutlinedInput-notchedOutline": {
                    border: "none",
                  },
                  ".MuiSelect-select": {
                    borderRadius: "8px",
                  },
                }}
                value={initialThreeBoxesPeriod}
                onChange={handleThreeBoxesChange}
              >
                <MenuItem
                  sx={{ color: "#2A5182", fontSize: "14px" }}
                  value={"today"}
                >
                  {i18next.t("Today")}
                </MenuItem>
                <MenuItem
                  sx={{ color: "#2A5182", fontSize: "14px" }}
                  value={"last7Days"}
                >
                  {i18next.t("Last 7 Days")}
                </MenuItem>
                <MenuItem
                  sx={{ color: "#2A5182", fontSize: "14px" }}
                  value={"last30Days"}
                >
                  {i18next.t("Last 30 days")}
                </MenuItem>
                <MenuItem
                  sx={{ color: "#2A5182", fontSize: "14px" }}
                  value={"last12Months"}
                >
                  {i18next.t("Last 12 Months")}
                </MenuItem>
                <MenuItem
                  sx={{ color: "#2A5182", fontSize: "14px" }}
                  value={"lastTwoYears"}
                >
                  {i18next.t("Last Two Years")}
                </MenuItem>
              </Select>
            </FormControl>
          )}
        </div>

        <ResponsiveContainer>
          <BarChart
            // width={500}
            // height={300}
            data={data}
            margin={{
              top: 20,
              //   right: 30,
              //   left: 20,
              bottom: 20,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" />
            <YAxis />
            <Tooltip />
            <Legend />
            {keyBars &&
              keyBars.length > 0 &&
              keyBars.map((barItem: any, barKey: any) => {
                return (
                  <Bar
                    key={"Key Bar" + barKey}
                    dataKey={i18next.t(barItem.name)}
                    fill={barItem.fill}
                    animationBegin={0}
                    animationDuration={1000}
                    animationEasing="ease-out"
                    activeBar={
                      <Rectangle
                        fill={barItem.rectangleFill}
                        stroke={barItem.rectangleStroke}
                      />
                    }
                  />
                );
              })}
          </BarChart>
        </ResponsiveContainer>
      </div>
      //   </div>
    );
  }
}
