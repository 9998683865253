import { Box, Divider, Typography } from "@mui/material";
import UserCardItem from "./Cards/UserCardItem";
import { useNavigate } from "react-router-dom";
import "./UserList.scss";
import { useUser } from "src/hooks/useUser";
import i18next from "i18next";

interface Props {
  data: any;
  redirectPage?: any;
  hideNavigation?: any;
  onUserSelect?: any;
  style?: any;
  type?: any;
  selectedUserId?: any;
  total?: any;
  hideAddButton?:boolean;
}

function UserList({
  data,
  redirectPage,
  hideNavigation,
  style,
  type,
  onUserSelect,
  selectedUserId,
  hideAddButton,
  total,
}: Props) {
  const navigate = useNavigate();
  const user = useUser();

  const handleNavigate = () => {
    navigate("/admin/add-user");
  };

  return (
    <Box className="recentBox">
      {data && data.length === 0 ? (
        <Typography className="noDataText">
          {type && type === "accountTransfer" ? "" : "No users found"}
        </Typography>
      ) : (
        <Box>
          <div className="d-flex ai-center gap-24">
            <Typography className="recentText">{i18next.t("List of users")}</Typography>

            {user?.role === "Admin" && !hideAddButton && (
              <Typography
                onClick={handleNavigate}
                className="recentText ml-8 cp"
                style={{ color: "#ff0000 !important" }}
              >
                <p style={{ color: "#ff0000 !important" }}>{i18next.t("Add a new user")}</p>
              </Typography>
            )}
          </div>
          <Divider style={{ marginBottom: 8 }} />
          <Box className="allRecipientMain">
            {data.map((item: any, index: number) => (
              <UserCardItem
                key={"usercard" + index}
                data={item}
                type={type}
                index={index}
                onClick={() => (onUserSelect ? onUserSelect(item) : {})}
                isSelected={item.id === selectedUserId}
              />
            ))}
          </Box>
        </Box>
      )}
    </Box>
  );
}

export default UserList;
