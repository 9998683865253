import { createAsyncThunk } from "@reduxjs/toolkit";
import api from "../interceptors/api";

// Assuming you have defined response types for these functions in your types

const updateUserProfile = async (userData: any) => {
  userData = { ...userData, _method: "PATCH" };
  const response = await api.post(`/users/${userData.id}`, userData);
  return response.data as any;
};

const uploadUserPhoto = async (userId: string, file: any) => {
  const formData = new FormData();
  formData.append("image", file);
  formData.append("_method", "PATCH");

  try {
    const response = await api.post(`/users/${userId}`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return response.data as any;
  } catch (error) {
    console.error("Error uploading the photo: ", error);
  }
};

const registrationKYCUpload = async (
  verificationCode: any,
  file: any,
  photoType: string
) => {
  const formData = new FormData();

  // Determine the form data key based on the photo type
  let formDataKey;
  switch (photoType) {
    case "passport_id":
      formDataKey = "passport_file";
      break;
    case "id_card_front_id":
      formDataKey = "id_card_front_file";
      break;
    case "id_card_back_id":
      formDataKey = "id_card_back_file";
      break;
    case "personal_photo_id":
      formDataKey = "personal_photo_file";
      break;

    case "business_aml_id":
      formDataKey = "business_aml_file";
      break;
    case "business_company_registration_id":
      formDataKey = "business_company_registration_file";
      break;
    case "business_licence_id":
      formDataKey = "business_licence_file";
      break;
    case "business_address_id":
      formDataKey = "business_address_file";
      break;
    case "business_sof_id":
      formDataKey = "business_sof_file";
      break;
    case "business_ubo_id":
      formDataKey = "business_ubo_file";
      break;
    case "business_tin_id":
      formDataKey = "business_tin_file";
      break;

    case "scanned_face_photo":
      formDataKey = "scanned_face_photo";
      break;

    case "scanned_id_photo":
      formDataKey = "scanned_id_photo";
      break;

    case "scanned_complete_photo":
      formDataKey = "scanned_complete_photo";
      break;

    default:
      console.error("Invalid photo type");
      return;
  }

  formData.append(formDataKey, file);
  //formData.append("_method", "PATCH");

  try {
    const response = await api.post(
      `/kyc-upload/${verificationCode}`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error(`Error uploading the ${photoType}: `, error);
  }
};

const verificationUploadPhoto = async (
  userId: any,
  file: any,
  photoType: string
) => {
  const formData = new FormData();

  // Determine the form data key based on the photo type
  let formDataKey;
  switch (photoType) {
    case "passport_id":
      formDataKey = "passport_file";
      break;
    case "id_card_front_id":
      formDataKey = "id_card_front_file";
      break;
    case "id_card_back_id":
      formDataKey = "id_card_back_file";
      break;
    case "personal_photo_id":
      formDataKey = "personal_photo_file";
      break;
    default:
      console.error("Invalid photo type");
      return;
  }

  formData.append(formDataKey, file);
  formData.append("_method", "PATCH");

  try {
    const response = await api.post(`/users/${userId}`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return response.data;
  } catch (error) {
    console.error(`Error uploading the ${photoType}: `, error);
  }
};

const kycUploadFile = async (userId: any, file: any, photoType: string) => {
  const formData = new FormData();
  formData.append(photoType, file);
  formData.append("_method", "PATCH");

  try {
    const response = await api.post(`/users/${userId}`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return response.data;
  } catch (error) {
    console.error(`Error uploading the ${photoType}: `, error);
  }
};

const removeVerificationPhoto = async (id?: number) => {
  // Determine the API endpoint based on the photo type
  if (id && id > 0) {
    const apiEndpoint = `/remove-verification-file/` + id;

    try {
      const response = await api.put(apiEndpoint);
      return response.data;
    } catch (error) {
      console.error(`Error removing the file ${id}: `, error);
    }
  }
};
const adminRemoveVerificationPhoto = async (
  userId: string | undefined,
  id?: number
) => {
  if (userId) {
    if (id && id > 0) {
      const apiEndpoint = `/user/${userId}/remove-verification-file/` + id;

      try {
        const response = await api.put(apiEndpoint);
        return response.data;
      } catch (error) {
        console.error(`Error removing the file ${id}: `, error);
      }
    }
  } else {
    console.error("userId is undefined");
  }
};

export const removeProfilePhoto = createAsyncThunk(
  "user/removeProfilePhoto",
  async (User, thunkAPI) => {
    try {
      const response = await api.put(`/remove-profile-image`);
      return response.data;
    } catch (error) {
      console.error("Error removing the profile photo:", error);
      return thunkAPI.rejectWithValue("error");
    }
  }
);

const profileService = {
  updateUserProfile,
  uploadUserPhoto,
  removeProfilePhoto,
  verificationUploadPhoto,
  kycUploadFile,
  registrationKYCUpload,
  removeVerificationPhoto,
  adminRemoveVerificationPhoto,
};

export default profileService;
