import React from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Box } from "@mui/material";
import HeaderLanding from "../components/HeaderLanding/HeaderLanding";
import { useAppSelector } from "src/store";
import FooterLanding from "../components/FooterLanding/FooterLanding";
import TitleBackBox from "../components/TitleBackBox/TitleBackBox";
import HeadlineVvidgetSmall from "../components/HeadlineVvidget/HeadlineVvidgetSmall";
import i18next from "i18next";

const defaultTheme = createTheme();

const VictorumMarket: React.FC = () => {
  const { user } = useAppSelector((state) => state.auth);

  const navigateToVicTrade = (event: any) => {
    window.open("https://victorum-trade.com/", "_blank");
  };

  return (
    <div>
      <HeaderLanding user={user} />
      <div className="margintTopPagesMainCont">
        <ThemeProvider theme={defaultTheme}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              minHeight: "400px",
            }}
          >
            <Box className="titleHeadlineSmallBG">
              <Box className="landingPagesMainBox">
                <Box>
                  <TitleBackBox title={i18next.t("Victorum Market")} />
                </Box>
                <Box>
                  <HeadlineVvidgetSmall
                    headline={
                      i18next.t("Latest trends and best products - and maximum discounts for our clients")
                    }
                    subtitle={""}
                    smallText={i18next.t("Discover top trends and products")}
                    buttonText={i18next.t("Visit our market")}
                    onClickFunction={navigateToVicTrade}
                  />
                </Box>
              </Box>
            </Box>
          </Box>
        </ThemeProvider>
      </div>
      <Box sx={{ marginTop: "4rem" }}>
        <FooterLanding />
      </Box>
    </div>
  );
};

export default VictorumMarket;
