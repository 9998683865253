import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import "./Chat.scss";
import Nav from "src/components/layout/nav/Nav";
import { Box, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import UsersChatContainer from "../../../src/components/shared/Chat/UsersChatContainer";
import ChatContainer from "src/components/shared/Chat/ChatContainer";
import { useEffect, useState } from "react";
import { useAppSelector } from "src/store";
import api from "src/store/interceptors/api";
import LoadingIndicator from "src/components/shared/LoadingIndicator";
import SmsOutlinedIcon from "@mui/icons-material/SmsOutlined";
import moment from "moment";
import i18next from "i18next";

const defaultTheme = createTheme();

export default function ChatAdmin() {
  const { user } = useAppSelector((state) => state.auth);
  const navigate = useNavigate();

  const [conversations, setConversations] = useState<any[]>([]);
  const [selectedConversation, setSelectedConversation] = useState<any | null>(
    null
  );
  const [selectedConversationUser, setSelectedConversationUser] = useState<
    any | null
  >(null);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [searchValue, setSearchValue] = useState("");
  const [loading, setLoading] = useState(false);
  const [loadMore, setLoadMore] = useState(false);
  const [searchLoading, setSearchLoading] = useState(false);
  const [isSmallDevice, setIsSmallDevice] = useState(true);
  const [showChatUserList, setShowChatUserList] = useState(true);
  const [showChatMsgs, setShowChatMsgs] = useState(true);

  const formatTimestamp = (timestamp: string) => {
    const createdAtMoment = moment(timestamp);
    const now = moment();

    const diffInMinutes = now.diff(createdAtMoment, "minutes");
    const diffInHours = now.diff(createdAtMoment, "hours");

    if (diffInMinutes < 1) {
      return "Just now";
    } else if (diffInMinutes < 60) {
      return `${diffInMinutes} minutes ago`;
    } else if (diffInHours < 24) {
      return `${diffInHours} hours ago`;
    } else {
      return createdAtMoment.format("MMM D, YYYY [at] h:mm A");
    }
  };

  const getAllConversations = async (page = 1) => {
    try {
      let urlConversations = null;
      if (searchValue) {
        setSearchLoading(true);
        urlConversations = `/conversations?page=${page}&per_page=20&search=${searchValue}`;
      } else {
        urlConversations = `/conversations?page=${page}&per_page=20`;
      }

      try {
        const response = await api.get(urlConversations);
        if (response && response.data && response.data.data) {
          if (page === 1) {
            setConversations(response.data.data);
          } else {
            setConversations((prevData) => [
              ...prevData,
              ...response.data.data,
            ]);
          }
          setSearchLoading(false);
          setLoading(false);
          setPage(response.data.meta.current_page);
          setTotalPages(response.data.meta.last_page);
        }
      } catch (err) {
        console.error("Error fetching conversations:", err);
      } finally {
        setLoading(false);
        setLoadMore(false);
      }
    } catch (error) {
      setLoading(false);
      setLoadMore(false);
      setSearchLoading(false);
    }
  };

  const handleGetMoreConversations = async () => {
    if (totalPages && page < totalPages) {
      setLoadMore(true);
      await getAllConversations(page + 1);
      setLoadMore(false);
    }
  };

  const handleNavigate = () => {
    if (selectedConversation && isSmallDevice) {
      setSelectedConversation(null);
      setShowChatMsgs(false);
      setShowChatUserList(true);
    } else {
      navigate("/home");
    }
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(event.target.value);
    setPage(1);
  };

  const handleDisplayCheck = (smallDevice: any) => {
    if (!smallDevice) {
      setShowChatUserList(true);
      setShowChatMsgs(true);
    } else {
      setShowChatUserList(selectedConversation === null);
      setShowChatMsgs(selectedConversation !== null);
    }
  };
  const handleUserItemClick = (conversation: any, user: any) => {
    setSelectedConversation(conversation);
    setSelectedConversationUser(user);
    if (isSmallDevice) {
      setShowChatMsgs(true);
      setShowChatUserList(false);
    } else {
      setShowChatMsgs(true);
      setShowChatUserList(true);
    }
  };

  useEffect(() => {
    getAllConversations();

    const handleResize = () => {
      if (window.innerWidth >= 830) {
        setIsSmallDevice(false);
        handleDisplayCheck(false);
      } else {
        setIsSmallDevice(true);
        handleDisplayCheck(true);
      }
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, [searchValue]);

  return (
    <div>
      <Nav step={0} isStepperVisible={false} isProfileSetVisible={true} />
      <div className="content-container-profile">
        <ThemeProvider theme={defaultTheme}>
          <Container component="main">
            <Box className="transactionTitleAndIcon">
              <p
                className="back-button-icon circle-around-text-smaller helvetica mr-1-rem cp"
                onClick={handleNavigate}
              >
                <ArrowBackIcon className="muiIcons"/>
              </p>

              <Typography
                className="your-details-text d-flex jc-start pageTitleFontSize"
                component="h1"
                variant="h5"
                sx={{
                  fontFamily: "Helvetica, sans-serif",
                  fontWeight: "600",
                  textAlign: "start",
                }}
              >
                {i18next.t("Support")}
              </Typography>
            </Box>
            {loading ? (
              <LoadingIndicator />
            ) : (
              <Box className="ChatMain">
                <>
                  {showChatUserList && (
                    <UsersChatContainer
                      user={user}
                      handleSearchChange={handleSearchChange}
                      searchValue={searchValue}
                      conversations={conversations}
                      formatTimestamp={formatTimestamp}
                      onUserItemClick={handleUserItemClick}
                      searchLoading={searchLoading}
                      selectedConversation={selectedConversation}
                      selectedConversationUser={selectedConversationUser}
                      handleScroll={handleGetMoreConversations}
                      loadMore={loadMore}
                    />
                  )}
                  {showChatMsgs &&
                    (conversations ? (
                      <ChatContainer
                        conversations={conversations}
                        user={user}
                        selectedConversationUser={selectedConversationUser}
                        selectedConversation={selectedConversation}
                      />
                    ) : (
                      <Box className="noMessagesBox">
                        <SmsOutlinedIcon />
                        <Typography>You currently have no messages</Typography>
                      </Box>
                    ))}
                </>
              </Box>
            )}
          </Container>
        </ThemeProvider>
      </div>
    </div>
  );
}
