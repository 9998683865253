import React from "react";

interface Props {
  id: string;
  previousId: string | null;
  nextId: string | null;
  value: string;
  onValueChange: (id: string, e: any) => void;
  handleSubmit: (e: any) => void;
}

const OTPInput = ({
  id,
  previousId,
  nextId,
  value,
  onValueChange,
  handleSubmit,
}: Props) => {
  const handleKeyUp = (e: React.KeyboardEvent<HTMLInputElement>) => {
    const { key } = e;

    if ((key === "Backspace" || key === "ArrowLeft") && previousId) {
      const prev = document.getElementById(previousId) as HTMLInputElement;
      if (prev) {
        prev.focus();
      }
    } else if (key.match(/[0-9a-zA-Z]/) || key === "ArrowRight") {
      const next =
        nextId && (document.getElementById(nextId) as HTMLInputElement);
      if (next) {
        next.focus();
      } else {
        handleSubmit(e);
      }
    }
  };
  return (
    <input
      className="m-2 text-center form-control rounded"
      id={id}
      name={id}
      type="text"
      value={value}
      maxLength={1}
      onChange={(e) => onValueChange(id, e.target.value)}
      onKeyUp={handleKeyUp}
    />
  );
};

export default OTPInput;
