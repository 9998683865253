import { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useAppDispatch, useAppSelector } from "src/store";
import ContinueBackButtons from "../shared/ContinueBackButtons";
import FloatLabelInputFiled from "../shared/FloatLabelInputFiled";
import DatePickerInputFiled from "../shared/DatePickerInputFiled";
import CountrySelect from "../shared/CountrySelect/CountrySelect";
import { PERSONAL_ACCOUNT } from "../shared/constants";
import PhoneCountrySelect from "../shared/PhoneCountrySelect/PhoneCountrySelect";
import {
  registerUser,
  resetRegisterState,
} from "src/store/register/register_slice";
import moment from "moment";
import { toast } from "react-toastify";
import i18next from "i18next";
import CustomInput from "../shared/CustomInput/CustomInput";
interface Props {
  setStep: (value: number, formikValues: any) => void;
  step: number;
}

function PersonalAccountForm({ setStep, step }: Props) {
  const [formStep, setFormStep] = useState(1);
  const { isLoading, token, isRegistered, isError } = useAppSelector(
    (state) => state.register
  );
  const dispatch = useAppDispatch();

  const formik = useFormik({
    initialValues: {
      first_name: "",
      last_name: "",
      birthday: null,
      country: "",
      phone: "",
      country_code: "",
      address: "",
      city: "",
      postal_code: "",
    },
    onSubmit: (values: any) => {
      if (formStep === 3) {
        values.account_type = PERSONAL_ACCOUNT;
        if (typeof values.birthday === "string") {
          values.birthday = values.birthday || null;
        } else {
          values.birthday = moment(values.birthday.$d).format("YYYY-MM-DD");
        }
        values.phone = values.phone.toString();
        values.postal_code = values.postal_code.toString();
        dispatch(registerUser({ values, token }));
      }
    },
    validateOnChange: false,
    validateOnBlur: false,
    validationSchema: Yup.object({
      first_name: Yup.string().required(
        i18next.t("First name is require filed.")
      ),
      last_name: Yup.string().required(
        i18next.t("Last name is require filed.")
      ),
      birthday: Yup.string().required(
        i18next.t("Birthday name is require filed.")
      ),
      country: Yup.string().required(i18next.t("Country is require field.")),
      phone: Yup.string().required(
        i18next.t("Phone Number is required field.")
      ),
      address: Yup.string().required(i18next.t("Address is require field.")),
      city: Yup.string().required(i18next.t("City is require field.")),
      postal_code: Yup.string().required(
        i18next.t("Postal Code is require field.")
      ),
    }),
  });

  const handleCountrySelect = (value: any) => {
    formik.setFieldValue("country", value);
  };

  const handlePhoneCountryCodeSelect = (value: any) => {
    formik.setFieldValue("country_code", value);
  };

  useEffect(() => {
    if (isRegistered) {
      toast.success(i18next.t("Creating account - SUCCESS!"));
      setStep(step + 1, { ...formik.values, token: token });
      dispatch(resetRegisterState());
    }
  }, [isRegistered]);

  useEffect(() => {
    if (isError) {
      toast.error(i18next.t("Something went wrong!"));
    }
  }, [isError]);

  return (
    <>
      {formStep === 1 && (
        <div className="initial-screen d-flex ai-center jc-center fx-column ta-center content-container-home">
          <h3
            className="fs-1-625 fw-400 loginTitleFontSize"
            style={{ fontFamily: "Helvetica, sans-serif", fontWeight: 400 }}
          >
            {i18next.t("Personal Details")}
          </h3>
          <h3
            className="font-size-14 mt-16 fw-300 registration0875RemFontSize"
            style={{
              fontFamily: "Helvetica, sans-serif",
              textAlign: "start",
              marginBottom: "17px",
            }}
          >
            {i18next.t(
              "Please add your personal details, so we can create you secure and worldwide accepted payment gate!"
            )}
          </h3>

          <div className="mt-16 w-100">
            <CustomInput
              id="first_name"
              placeHolder={i18next.t("First name")}
              value={formik.values.first_name}
              onChangeText={(value) =>
                formik.setFieldValue("first_name", value)
              }
              isTouched={!!formik.touched.first_name || formik.submitCount > 0}
              errors={formik.errors.first_name}
              nextId="last_name"
              isRequired={true}
            />
          </div>

          <div className="mt-16 w-100">
            <CustomInput
              id="last_name"
              placeHolder={i18next.t("Last name")}
              value={formik.values.last_name}
              onChangeText={(value) => formik.setFieldValue("last_name", value)}
              isTouched={!!formik.touched.last_name || formik.submitCount > 0}
              errors={formik.errors.last_name}
              isRequired={true}
            />
          </div>

          <div className="mt-16 w-100">
            <DatePickerInputFiled
              required={true}
              isTouched={formik.touched.birthday}
              errors={formik.errors.birthday}
              value={formik.values.birthday}
              onChange={formik.setFieldValue}
              name="birthday"
              label={i18next.t("Birthday")}
            />
          </div>

          <ContinueBackButtons
            errors={["first_name", "last_name", "birthday"]}
            setStep={setFormStep}
            step={formStep}
            formik={formik}
            lastStep={3}
          />
        </div>
      )}

      {formStep === 2 && (
        <div className="initial-screen d-flex ai-center jc-center fx-column ta-center content-container-home">
          <h3
            className="fs-1-625 fw-400 loginTitleFontSize"
            style={{ fontFamily: "Helvetica, sans-serif", fontWeight: 400 }}
          >
            {i18next.t("Address Details")}
          </h3>
          <h3
            className="font-size-14 mt-16 fw-300 registration0875RemFontSize"
            style={{
              fontFamily: "Helvetica, sans-serif",
              marginBottom: "17px",
              textAlign: "start",
            }}
          >
            {i18next.t(
              "We are almost done! Now please add your address details, so your account can be finalized!"
            )}
          </h3>

          <div className="country-code-container-personal mt-16">
            <CountrySelect
              value={formik.values.country}
              isTouched={formik.touched.country}
              errors={formik.errors.country}
              onSelect={handleCountrySelect}
            />
          </div>

          <div className="mt-16 w-100">
            <CustomInput
              id="address"
              placeHolder={i18next.t("Address")}
              value={formik.values.address}
              onChangeText={(value) => formik.setFieldValue("address", value)}
              isTouched={!!formik.touched.address || formik.submitCount > 0}
              errors={formik.errors.address}
              nextId="city"
              isRequired={true}
            />
          </div>

          <div className="mt-16 w-100">
            <CustomInput
              id="city"
              placeHolder={i18next.t("City")}
              value={formik.values.city}
              onChangeText={(value) => formik.setFieldValue("city", value)}
              isTouched={!!formik.touched.city || formik.submitCount > 0}
              errors={formik.errors.city}
              nextId="postal_code"
              isRequired={true}
            />
          </div>

          <div className="mt-16 w-100">
            <CustomInput
              id="postal_code"
              placeHolder={i18next.t("Postal Code")}
              value={formik.values.postal_code}
              onChangeText={(value) =>
                formik.setFieldValue("postal_code", value)
              }
              isTouched={!!formik.touched.postal_code || formik.submitCount > 0}
              errors={formik.errors.postal_code}
              isRequired={true}
            />
          </div>

          <ContinueBackButtons
            errors={["address", "country", "city", "postal_code"]}
            setStep={setFormStep}
            step={formStep}
            formik={formik}
            lastStep={3}
          />
        </div>
      )}

      {formStep === 3 && (
        <div className="initial-screen d-flex ai-center jc-center fx-column ta-center content-container-home">
          {/* <h3
            className="fs-1-625 fw-400"
            style={{ fontFamily: "Helvetica, sans-serif", fontWeight: 400 }}
          >
            Phone information
          </h3> */}
          <h3
            className="fs-1-625 fw-400 loginTitleFontSize"
            style={{
              fontFamily: "Helvetica, sans-serif",
              fontWeight: 400,
              textAlign: "start",
              marginBottom: "17px",
            }}
          >
            {i18next.t(
              "Now let´s make your account safe by adding your mobile number!"
            )}
          </h3>

          <div className="w-100 d-flex flex-row jusifyContentSpaceBetween">
            <div className="mt-16 w-30">
              <PhoneCountrySelect
                value={formik.values.country_code}
                onSelect={handlePhoneCountryCodeSelect}
              />
            </div>

            <div className="mt-16 w-70M">
              <CustomInput
                id="phone"
                placeHolder={i18next.t("Phone Number")}
                value={formik.values.phone}
                onChangeText={(value) => formik.setFieldValue("phone", value)}
                isTouched={!!formik.touched.phone || formik.submitCount > 0}
                errors={formik.errors.phone}
                isRequired={true}
                type="number"
              />
            </div>
          </div>

          <ContinueBackButtons
            errors={["phone"]}
            setStep={setFormStep}
            step={formStep}
            formik={formik}
            lastStep={3}
          />
        </div>
      )}
    </>
  );
}

export default PersonalAccountForm;
