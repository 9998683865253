import {
  Box,
  Button,
  Checkbox,
  Divider,
  FormControlLabel,
  Typography,
} from "@mui/material";
import { useState } from "react";
import NotificationItem from "./Notification/Notification";
import "./NotificationsList.scss";
import { useTranslation } from "react-i18next";
import i18next from "i18next";


interface Props {
  data: any;
  user: any;
  type?: any;
  updateNotifications: (id?: number) => void;
  readAllNotifications?: () => void;
  notificationTitle?: string;
  showMarkAllAsRead?: boolean;
}

function NotificationsList({
  data,
  type,
  updateNotifications,
  readAllNotifications,
  user,
  notificationTitle = i18next.t("Notifications"),
  showMarkAllAsRead = false,
}: Props) {
  const [isChecked, setChecked] = useState(false);

  const handleOnChecked = () => {
    const statusChecked = !isChecked;
    setChecked(statusChecked);
  };

  return (
    <Box>
      {data && data?.length === 0 ? (
        <Typography className="noDataText">
          {i18next.t("No Notifications Found")}
        </Typography>
      ) : (
        <Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography
              className="oneRemFontSize"
              component="h1"
              variant="h5"
              sx={{
                fontFamily: "Helvetica, sans-serif",
                color: "#454745;",
                fontWeight: "500",
              }}
            >
              {notificationTitle}
            </Typography>
            {showMarkAllAsRead && (
              <Box sx={{ padding: 2 }}>
                <FormControlLabel
                  label={i18next.t("Mark All As Read")}
                  control={
                    <Checkbox
                      size="small"
                      onChange={handleOnChecked}
                      checked={isChecked}
                    />
                  }
                  style={{
                    color: "#454745",
                    marginRight: "0",
                  }}
                />
                {isChecked && (
                  <Button
                    sx={{ marginLeft: 2 }}
                    className="walletBtn"
                    onClick={readAllNotifications}
                  >
                    {i18next.t("Confirm")}
                  </Button>
                )}
              </Box>
            )}
          </Box>
          <Divider style={{ marginBottom: 8 }} />
          <Box>
            {data.map((item: any, index: any) => (
              <NotificationItem
                user={user}
                updateNotifications={updateNotifications}
                item={item}
                key={index}
              />
            ))}
          </Box>
        </Box>
      )}
    </Box>
  );
}

export default NotificationsList;
