import { Box, Typography } from "@mui/material";
import "../../pages/LandingPagesGlobal2.scss";
import { ReactComponent as VicPayLogoSvg } from "../../../../assets/images/vicpaylogo.svg";
const LogoTextVvidget2 = ({ text }: any) => {
  return (
    <>
      <Box className="logoContainer">
        <Box className="logoGradientBgContainer">
          <VicPayLogoSvg />
        </Box>
        <Box className="logoPayVvidget2">
          <Typography
            // variant="body1"
            paragraph
            className="textsLandingPage"
            sx={{ color: "#fff !important" }}
          >
            {text}
          </Typography>
        </Box>
      </Box>
    </>
  );
};

export default LogoTextVvidget2;
