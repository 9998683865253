import React from 'react';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import './PriorityIcon.scss'; // Import your CSS file for styling

const PriorityYellowIcon = () => {
  return (
    <div className="circle-container">
      <div className="yellow-circle">
        <PriorityHighIcon className="priority-icon muiIcons" />
      </div>
    </div>
  );
};

export default PriorityYellowIcon;
