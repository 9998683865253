import { useState } from "react";
import { Box, Button, Grid, Typography } from "@mui/material";
import api from "src/store/interceptors/api";
import ConfirmationDialog from "src/components/shared/ConfirmationDialog/ConfirmationDialog";
import i18next from "i18next";
import { toast } from "react-toastify";
import { ReactComponent as RefundIcon } from "../../assets/images/refund.svg";

function Refund({ transactionDetails, transactionId }: any) {
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const handleDialogClose = () => {
    setIsDialogOpen(false);
  };

  const handleOpenDialog = () => {
    setIsDialogOpen(true);
  };

  const handleDialogConfirm = async () => {
    try {
      const response = await api.post(`/transfer-return`, {
        transaction_id: transactionId,
      });
      if (
        response &&
        response.data &&
        response.data.status &&
        response.data.status === 200
      ) {
        toast.success(response.data.message);
        setIsDialogOpen(false);
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      } else {
        if (response && response.data && response.data.message)
          toast.error(response.data.message);
        else toast.error(i18next.t("An unexpected error occurred."));

        setIsDialogOpen(false);
      }
    } catch (error) {
      //toast.error(i18next.t("An unexpected error occurred."));
      setIsDialogOpen(false);
      console.error("API call failed", error);
    }
  };

  return (
    <Box>
      <ConfirmationDialog
        open={isDialogOpen}
        handleClose={handleDialogClose}
        handleConfirm={handleDialogConfirm}
        content={{
          title: i18next.t("Are you sure you'd like to refund"),
          body: i18next.t("Note that this action cannot be undone"),
          cancelText: i18next.t("Cancel"),
          confirmText: i18next.t("Refund"),
        }}
        className="custom-dialog"
      />
      <Box sx={{ marginBottom: "4rem" }}>
        <Box sx={{ mt: 2 }}>
          <Box className="personal-box">
            <Box className="transaction-item-details">
              <Typography
                className="your-details-text d-flex jc-start FontSize12"
                component="h1"
                variant="h5"
                sx={{
                  fontFamily: "Helvetica, sans-serif",
                  fontWeight: "400",
                  textAlign: "start",
                }}
              >
                {i18next.t("Transaction refund")}
              </Typography>
            </Box>
            <Grid
              container
              spacing={2}
              sx={{ padding: "18px" }}
              justifyContent="center"
              alignItems="center"
            >
              {transactionDetails?.is_refund === null ? (
                <>
                  <Grid item lg={8} md={8} xs={12}>
                    <Typography className="oneRemFontSize transaction-detail-label">
                      {transactionDetails?.amount ?? ""}{" "}
                      {transactionDetails?.currency?.code ?? ""}
                    </Typography>
                  </Grid>
                  <Grid item lg={4} md={4} xs={12}>
                    <Button
                      startIcon={<RefundIcon className="muiIcons" />}
                      fullWidth
                      onClick={handleOpenDialog}
                      variant="text"
                      className="actionButton oneRemFontSize btnsLoginHeight"
                    >
                      {i18next.t("Refund")}
                    </Button>
                  </Grid>
                </>
              ) : (
                <Grid item lg={12} md={12} xs={12}>
                  <Typography className="oneRemFontSize transaction-detail-label">
                    {i18next.t("This transaction is already refunded")}
                  </Typography>
                </Grid>
              )}
            </Grid>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default Refund;
