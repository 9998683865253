import { Box, Button } from "@mui/material";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "src/store";
import {
  registerUser,
  resetRegisterState,
} from "src/store/register/register_slice";
import * as Yup from "yup";
import { toast } from "react-toastify";
import i18next from "i18next";
import CustomInput from "../shared/CustomInput/CustomInput";

interface Props {
  setStep: (value: number) => void;
  step: number;
  uuid: any;
}
function FifthStep({ uuid }: Props) {
  const dispatch = useAppDispatch();
  const { token, isRegistered, isError } = useAppSelector(
    (state) => state.register
  );

  const [confirmError, setConfirmError] = useState(false);
  const formik = useFormik({
    initialValues: {
      password_confirmation: "",
      password: "",
    },
    onSubmit: (values: any) => {
      values.uuid = uuid;
      dispatch(registerUser({ values, token }));
    },
    validationSchema: Yup.object({
      password_confirmation: Yup.string()
        .required(i18next.t("Password confirmation is required."))
        .min(8),
      password: Yup.string().required(i18next.t("Password is required")).min(8),
    }),
  });

  useEffect(() => {
    if (isRegistered) {
      toast.success(i18next.t("Welcome to VicPay, enjoy!"));
      dispatch(resetRegisterState());
      setTimeout(() => {
        window.location.reload();
      }, 2000);
    }
  }, [isRegistered]);

  useEffect(() => {
    if (isError) {
      toast.error(i18next.t("Something went wrong!"));
    }
  }, [isError]);

  return (
    <Box className="initial-screen d-flex ai-center jc-center fx-column content-container-home">
      <h3
        className="fs-1-625 fw-400 mb-2rem loginTitleFontSize"
        style={{ fontFamily: "Helvetica, sans-serif", fontWeight: 400 }}
      >
        {i18next.t("Create your password")}
      </h3>

      <Box className="email-field d-flex fx-column mt-2rem">
        <Box className="mt-16 w-100">
          <CustomInput
            id="password"
            placeHolder={i18next.t("Password")}
            value={formik.values.password}
            onChangeText={(value) => formik.setFieldValue("password", value)}
            isPassword={true}
            isTouched={!!formik.touched.password || formik.submitCount > 0}
            errors={formik.errors.password}
            isRequired={true}
            nextId="password_confirmation"
          />
        </Box>

        <Box className="mt-16 w-100">
          <CustomInput
            id="password_confirmation"
            placeHolder={i18next.t("Password confirm")}
            value={formik.values.password_confirmation}
            onChangeText={(value) =>
              formik.setFieldValue("password_confirmation", value)
            }
            isPassword={true}
            isTouched={
              !!formik.touched.password_confirmation || formik.submitCount > 0
            }
            errors={formik.errors.password_confirmation}
            isRequired={true}
          />
        </Box>

        {confirmError && (
          <p className="form-error-message ">
            {i18next.t("Password must be the same")}
          </p>
        )}

        <Box className="d-flex ai-center jc-center mt-1-5">
          <h3
            className="fs-14 registration0875RemFontSize"
            style={{
              fontFamily: "Helvetica, sans-serif",
              fontWeight: 100,
            }}
          >
            {i18next.t("Password must contain")}{" "}
            <strong>{i18next.t("8 characters")}</strong>
          </h3>
        </Box>
      </Box>

      <Button
        type="submit"
        fullWidth
        variant="contained"
        className="btnsLoginHeight oneRemFontSize"
        sx={{
          color: "white",
          backgroundColor: "#2A5182",
          borderRadius: "24px",
          minWidth: "33rem",
          textTransform: "none",
          fontFamily: "Helvetica, sans-serif",
        }}
        onClick={(e: any) => {
          formik.handleSubmit(e);
        }}
      >
        {i18next.t("Continue")}
      </Button>
    </Box>
  );
}

export default FifthStep;
