import React from "react";
import Button from "@mui/material/Button";
import "./BackNextBtns.scss";
import { Box, Typography } from "@mui/material";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import ArrowCircleRightOutlinedIcon from "@mui/icons-material/ArrowCircleRightOutlined";

interface BackNextBtns {
  backText?: string;
  nextText?: string;
  isNextDisabled?: any;
  backType?: "button" | "submit" | "reset";
  nextType?: "button" | "submit" | "reset";
  onBackClick?: React.MouseEventHandler<HTMLButtonElement>;
  onNextClick?: React.MouseEventHandler<HTMLButtonElement>;
}

const BackNextBtns: React.FC<BackNextBtns> = ({
  backText = "Back",
  nextText = "Next",
  isNextDisabled = false,
  backType = "button",
  nextType = "button",
  onBackClick,
  onNextClick,
}) => {
  return (
    <Box className="w-100 d-flex ai-center jusifyContentSpaceBetween">
      <Button
        className="btnsLoginHeight oneRemFontSize backCustomButton"
        variant="contained"
        type={backType}
        onClick={onBackClick}
        startIcon={<KeyboardBackspaceIcon></KeyboardBackspaceIcon>}
      >
        <Typography className="oneRemFontSize backNextCustomFontF">
          {backText}
        </Typography>
      </Button>
      <Button
        className="btnsLoginHeight nextCustomButton"
        variant="contained"
        type={nextType}
        onClick={onNextClick}
        disabled={isNextDisabled}
        startIcon={
          <ArrowCircleRightOutlinedIcon></ArrowCircleRightOutlinedIcon>
        }
      >
        <Typography className="oneRemFontSize backNextCustomFontF">
          {nextText}
        </Typography>
      </Button>
    </Box>
  );
};

export default BackNextBtns;
