import React from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Box, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import HeaderLanding from "../components/HeaderLanding/HeaderLanding";
import { useAppSelector } from "src/store";
import FooterLanding from "../components/FooterLanding/FooterLanding";
import "./LandingPagesGlobal.scss";
import TitleBackBox from "../components/TitleBackBox/TitleBackBox";
import HeadlineVvidgetSmall from "../components/HeadlineVvidget/HeadlineVvidgetSmall";
import i18next from "i18next";

const defaultTheme = createTheme();

const Regulation: React.FC = () => {
  const { user } = useAppSelector((state) => state.auth);

  const navigate = useNavigate();

  const navigateToContactUs = (event: any) => {
    navigate("/aml-policy");
  };

  return (
    <div>
      <HeaderLanding user={user} />
      <div className="margintTopPagesMainCont">
        <ThemeProvider theme={defaultTheme}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Box className="titleHeadlineSmallBG">
              <Box className="landingPagesMainBox">
                <Box>
                  <TitleBackBox title={i18next.t("Regulation Authority")} />
                </Box>
                <Box>
                  <HeadlineVvidgetSmall
                    headline={i18next.t(
                      "A reliable service needs reliable regulation"
                    )}
                    subtitle={i18next.t(
                      "Our company complies with the 2019 FCA guidelines against Money Laundering and Terrorist Financing and also complies with the Terrorist Financing Act (PCMLTFA) and associated Regulations, as well as the requirements of other laws and regulations to the extent in which they relate to the Company’s operations"
                    )}
                    smallText={i18next.t("Regulations")}
                    buttonText={i18next.t("AML Policy")}
                    onClickFunction={navigateToContactUs}
                  />
                </Box>
              </Box>
            </Box>
            <Box className="landingPagesMainBox">
              <Box>
                <Typography
                  variant="h3"
                  sx={{
                    marginBottom: "1rem",
                  }}
                  className="headlineMiniLandingPages"
                >
                  {i18next.t(
                    "Regulatory Oversight: www.vicpayments.com is operated by Victorum Capital Services Limited Inc."
                  )}
                </Typography>
                <Typography
                  variant="body1"
                  paragraph
                  className="textsLandingPage"
                >
                  {i18next.t(
                    "All services excluding cryptocurrencies are handled as a tied registred agent of 3S Money Club Limited. 3S Money Club Limited is authorised by the Financial Conduct Authority under the Electronic Money Regulations 2011 for issuing of electronic money and the provision of payment services with FCA registration number 900918. 3S Money Club Limited is registered with The United Kingdom Information Commissioner's Office with ICO registration number ZA495485"
                  )}
                </Typography>
              </Box>
              <Box>
                <Typography
                  variant="h3"
                  sx={{ marginBottom: "1rem" }}
                  className="headlineMiniLandingPages"
                >
                  {i18next.t(
                    "Here the full contact details of the FCA, London"
                  )}
                </Typography>
                <Typography
                  variant="h3"
                  sx={{ marginBottom: "1rem" }}
                  className="headlineMiniLandingPages"
                >
                  {i18next.t("FCA Financial Conduct Authority")}
                </Typography>
                <Typography
                  variant="body1"
                  paragraph
                  className="textsLandingPage"
                  style={{ marginTop: "0.5rem" }}
                >
                  <span className="textsLandingsmallNoFontSize">
                    {i18next.t("Head Office")}
                    <br />
                    12 Endeavour Square
                    <br />
                    London E20 1JN
                    <br />
                    United Kingdom
                    <br />
                    0800 111 6768 {i18next.t("freephone")}
                    <br />
                    0300 500 8082 {i18next.t("from the UK")}
                    <br />
                    +44 207 066 1000 {i18next.t("from abroad")}
                  </span>
                </Typography>
              </Box>
              <Box>
                <Typography
                  variant="h3"
                  sx={{
                    marginBottom: "1rem",
                  }}
                  className="headlineMiniLandingPages"
                >
                  {i18next.t("The UK Financial Services Authority")}
                </Typography>
                <Typography
                  variant="body1"
                  paragraph
                  className="textsLandingPage"
                >
                  {i18next.t(
                    "The Financial Conduct Authority (FCA) is a financial regulatory body in the United Kingdom, which operates independently of the UK Government and is financed by charging fees to members of the financial services industry"
                  )}
                </Typography>
                <Typography
                  variant="body1"
                  paragraph
                  className="textsLandingPage"
                >
                  {i18next.t(
                    "It focuses on the regulation of conduct by both retail and wholesale financial services firms. Like its predecessor the FSA (Financial Services Authority), the FCA is structured as a company limited by guarantee"
                  )}
                </Typography>
                <Typography
                  variant="body1"
                  paragraph
                  className="textsLandingPage"
                >
                  {i18next.t(
                    "The FCA works alongside the Prudential Regulation Authority and the Financial Policy Committee to set regulatory requirements for the financial and insurance sector"
                  )}
                </Typography>
                <Typography
                  variant="body1"
                  paragraph
                  className="textsLandingPage"
                >
                  {i18next.t(
                    "So as you can see: we are giving our very best to ensure the maximum level of transparency and safety for each and every client"
                  )}
                </Typography>
              </Box>
              {/* <Box>
                <Typography
                  variant="h3"
                  sx={{
                    marginBottom: "1rem",
                  }}
                  className="headlineMiniLandingPages"
                >
                  {i18next.t("FINTRAC's Expansion under Bill C-25")}
                </Typography>
                <Typography
                  variant="body1"
                  paragraph
                  className="textsLandingPage"
                >
                  {i18next.t(
                    "FINTRAC's mandate was once again expanded in 2006 under the Bill C-25 to enhance the client identification, record-keeping and reporting measures, established a registration regime for money services businesses and foreign exchange dealers, and created new offences for not registering"
                  )}
                </Typography>
              </Box> */}
              {/* <Box sx={{ marginTop: "2rem" }}>
                <Typography
                  variant="body1"
                  paragraph
                  className="textsLandingPage"
                >
                  {i18next.t(
                    "FINTRAC monitors and collects information from regulated entities on"
                  )}
                </Typography>
                <Typography
                  variant="body1"
                  component="ul"
                  paragraph
                  sx={{ marginLeft: "22px" }}
                  className="textsLandingPage"
                >
                  <li className="textsLandingPage">
                    {i18next.t("Suspicious transactions")}
                  </li>
                  <li className="textsLandingPage">
                    {i18next.t("Suspected terrorist property")}
                  </li>
                  <li className="textsLandingPage">
                    {i18next.t("Large cash transactions")}
                  </li>
                  <li className="textsLandingPage">
                    {i18next.t(
                      "outgoing or Incoming International Electronic funds transfer over $10,000 CAD"
                    )}
                  </li>
                  <li className="textsLandingPage">
                    {i18next.t("Cross border currency reporting")}
                  </li>
                </Typography>
              </Box> */}
              <Box>
                {/* <Typography
                  variant="h3"
                  sx={{
                    marginBottom: "1rem",
                  }}
                  className="headlineMiniLandingPages"
                >
                  {i18next.t("FINTRAC's Commitment to Transparency and Safety")}
                </Typography>
                <Typography
                  variant="body1"
                  paragraph
                  className="textsLandingPage"
                >
                  {i18next.t(
                    "FINTRAC analysed approximately 20 million transactions in 2023 and has made ca. 2,000 disclosures to police, customs and other law enforcement organisations"
                  )}
                </Typography>
                <Typography
                  variant="body1"
                  paragraph
                  className="textsLandingPage"
                >
                  {i18next.t(
                    "The BoD of FINTRAC reports directly to the Parliament of Canada through the appointed Minister of Finance, so as you can see: we are giving our very best to ensure the maximum level of transparency and safety for each and every client"
                  )}
                </Typography> */}
                <Typography
                  variant="body1"
                  paragraph
                  className="textsLandingPage"
                >
                  {i18next.t("So")}
                  <b> {i18next.t("don´t waste time")}</b>,{" "}
                  <span
                    className="linksHover"
                    onClick={() => navigate("/registration")}
                  >
                    {i18next.t("click here")}
                  </span>{" "}
                  {i18next.t(
                    "and open your free VicPay account and start saving money on  transaction and currency exchange -"
                  )}{" "}
                  <b>{i18next.t("now")}</b>
                </Typography>
                <Typography
                  variant="body1"
                  paragraph
                  className="textsLandingPage"
                >
                  {i18next.t(
                    "In case of any open questions regarding the compliance and regulation process at VicPay, give us a call on +1 604-260-0738 daily from 10 AM to 6 PM or drop us a message to"
                  )}{" "}
                  <a href="mailto:info@vicpayments.com" className="linksHover">
                    info@vicpayments.com
                  </a>{" "}
                  {i18next.t("at anytime")}
                </Typography>
              </Box>
            </Box>
          </Box>
        </ThemeProvider>
      </div>
      <Box sx={{ marginTop: "4rem" }}>
        <FooterLanding />
      </Box>
    </div>
  );
};

export default Regulation;
