import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { useEffect, useState } from "react";
import Nav from "src/components/layout/nav/Nav";
import { toast } from "react-toastify";
import SearchIcon from "@mui/icons-material/Search";
import {
  Autocomplete,
  Box,
  Button,
  FormControlLabel,
  InputAdornment,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@mui/material";
import { useAppSelector } from "src/store";
import { useLocation, useNavigate } from "react-router-dom";
import api from "src/store/interceptors/api";
import Tabs, { tabsClasses } from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import AccountBox from "src/components/shared/AccountBox/AccountBox";
import { useFormik } from "formik";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import usersService from "src/store/users/users_services";
import LoadingIndicator from "src/components/shared/LoadingIndicator";
import UserList from "src/components/Users/UserList";
import i18next from "i18next";
import { PERSONAL_ACCOUNT } from "src/components/shared/constants";

const defaultTheme = createTheme();
const defaultCurrencies = ["EUR"];
export default function AccountTransfer() {
  const { user } = useAppSelector((state) => state.auth);
  const [balance, setBalance] = useState<any>(null);
  const [selectedBalanceAmount, setSelectedBalanceAmount] = useState<any>(null);
  const [currency, setCurrency] = useState("");
  const [usersData, setUsersData] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingBalance, setIsLoadingBalance] = useState(false);
  const [page, setPage] = useState(1);
  const [perPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [searchValue, setSearchValue] = useState("");
  const [purpose, setPurpose] = useState("");
  const [sendTime, setSendTime] = useState("now");

  const location = useLocation();
  const personal = user?.account_type === PERSONAL_ACCOUNT;

  const handleSendTime = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSendTime((event.target as HTMLInputElement).value);
  };

  const handleUserSelect = (userData: any) => {
    setSelectedUserId(userData.id);
  };

  const handleAmountChange = (event: any) => {
    const { value } = event.target;
    if (/^[0-9]*\.?[0-9]*$/.test(value)) {
      formik.handleChange(event);
    }
  };

  const handleSubmit = async () => {
    setIsLoading(true);
    if (!currency) {
      toast.error(i18next.t("Please select currency"));
      setIsLoading(false);
      return;
    }
    if (!selectedUserId) {
      toast.error(i18next.t("Please select user"));
      setIsLoading(false);
      return;
    }
    if (
      !formik.values.amount ||
      formik.values.amount <= "0" ||
      parseFloat(formik.values.amount) > parseFloat(selectedBalanceAmount)
    ) {
      toast.error(i18next.t("Please enter proper amount"));
      setIsLoading(false);
      return;
    }

    if (currency === "NGN" && Number(formik.values.amount) < 17000) {
      toast.error(i18next.t("The minimum amount you can send is 17000 NGN"));
      setIsLoading(false);
      return;
    }

    const payload = {
      currency: currency,
      user_id: selectedUserId,
      amount: formik.values.amount,
      purpose: purpose,
      blocked: sendTime === "blocked" ? true : false,
    };

    try {
      const response = await api.post("/transfer-balance", payload);
      navigate("/home");
      toast.success(i18next.t("Successfully transferred"));
    } catch (error) {
      console.error(error);
      toast.error(i18next.t("Something went wrong"));
    } finally {
      setIsLoading(false);
    }
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(event.target.value);
  };
  const handlePurposeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPurpose(event.target.value);
  };

  const [selectedUserId, setSelectedUserId] = useState(null);

  const handleGetUsers = async () => {
    setIsLoading(true);
    let urlFilter = null;
    if (searchValue) {
      urlFilter = `?page=${page}&per_page=${perPage}&search[companies.name]=${searchValue}&search[first_name]=${searchValue}&search[last_name]=${searchValue}&search[email]=${searchValue}&sort[verification_approved]=DESC`;
    } else {
      urlFilter = `?page=${page}&per_page=${perPage}&sort[verification_approved]=DESC`;
    }
    const response = await usersService.getUsersTransferList(urlFilter);
    const { data, meta } = response;
    setIsLoading(false);
    if (data) {
      setUsersData(data);
    }
    if (meta) {
      setTotalPages(meta?.last_page || 1);
    }
  };

  useEffect(() => {
    if (searchValue && searchValue.length > 3) handleGetUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchValue]);
  // users end

  const handleCurrencyChange = (event: any, newValue: any) => {
    setCurrency(newValue);
  };

  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      amount: "",
    },
    onSubmit: (values) => {},
  });

  const handleAccountBoxClick = (code: string, balance: string) => {
    if (balance === "0.00") {
      toast.error(
        i18next.t("No enough money in your {{code}} balance ", { code: code })
      );
      return;
    }
    setCurrency(code);
    setSelectedBalanceAmount(balance);
  };

  const getInitialData = async () => {
    setIsLoadingBalance(true);
    if (user && user.role && user.role === "Employee") navigate("/admin");
    await api
      .get("/user-balances")
      .then((response: any) => {
        if (response && response.data && response.data.data) {
          const resData = response.data.data;
          const balanceCurrencies = response.data.data
            ? response.data.data.map((item: any) => item.currency.code)
            : [];
          const missingCurrencies = defaultCurrencies.filter(
            (code) => !balanceCurrencies.includes(code)
          );
          missingCurrencies.map((item, index) => {
            resData.push({
              id: 0,
              balance: "0.00",
              user_id: user && user.id ? user.id : 0,
              currency: {
                id: 0,
                code: item,
                name: item,
                symbol: null,
                status: 1,
                exchange_rate: null,
                decimal_places: null,
                created_at: "2024-01-09T08:49:39.000000Z",
                updated_at: "2024-01-09T08:49:39.000000Z",
              },
            });
          });

          setBalance(resData);
        }
      })
      .catch((error) => {
        console.error(error.message);
      });
    setIsLoadingBalance(false);
  };

  useEffect(() => {
    getInitialData();
  }, []);

  useEffect(() => {
    if (location.state && location.state.initialSelectedCurrency && balance) {
      const initialCurrency = location.state.initialSelectedCurrency;
      const initialBalance = balance.find(
        (balanceItem: any) => balanceItem.currency.code === initialCurrency
      );

      if (initialBalance) {
        handleAccountBoxClick(initialCurrency, initialBalance.balance);
      }
    }
  }, [balance, location.state]);

  const handleNavigate = () => {
    navigate("/home");
  };

  return (
    <div>
      <Nav step={0} isStepperVisible={false} isProfileSetVisible={true} />
      <div className="main-admin-container">
        <div className="content-container-profile main-color">
          <ThemeProvider theme={defaultTheme}>
            <Container component="main">
              <div className="d-flex ai-center mb-3-rem">
                <p
                  className="back-button-icon circle-around-text-smaller helvetica mr-1-rem cp"
                  onClick={handleNavigate}
                >
                  <ArrowBackIcon className="muiIcons" />
                </p>

                <Typography
                  className="your-details-text d-flex jc-start pageTitleFontSize"
                  component="h1"
                  variant="h5"
                  sx={{
                    fontFamily: "Helvetica, sans-serif",
                    fontWeight: "600",
                    textAlign: "start",
                  }}
                >
                  {i18next.t("Account Transfer")}
                </Typography>
              </div>

              <div className="d-flex ai-center">
                <Typography
                  className="your-details-text d-flex jc-start pageTitleFontSize"
                  component="h1"
                  variant="h5"
                  sx={{
                    fontFamily: "Helvetica, sans-serif",
                    fontWeight: "600",
                    textAlign: "start",
                    marginLeft: "4rem",
                  }}
                >
                  {i18next.t("Select account")}
                </Typography>
              </div>

              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                {isLoadingBalance ? (
                  <LoadingIndicator />
                ) : balance && balance.length > 0 ? (
                  <Box style={{ marginTop: "1.5rem", marginBottom: "2.5rem" }}>
                    <Tabs
                      value={0}
                      TabIndicatorProps={{
                        style: { display: "none" },
                      }}
                      variant="scrollable"
                      scrollButtons
                      aria-label="visible arrows tabs example"
                      sx={{
                        padding: 0,
                        [`& .${tabsClasses.scrollButtons}`]: {
                          "&.Mui-disabled": {
                            opacity: 0.3,
                            backgroundColor: "#eaeaea",
                            color: "#9e9e9e",
                          },
                          backgroundColor: "#2A5182",
                          opacity: 1,
                          color: "#fff",
                        },
                      }}
                      TabScrollButtonProps={{
                        style: {
                          width: "32px",
                          height: "32px",
                          borderRadius: "50%",
                          margin: "auto 10px auto 10px",
                        },
                      }}
                    >
                      {balance.map((balanceItem: any, balanceKey: any) => (
                        <Tab
                          sx={{
                            padding: 0.75,
                          }}
                          key={"balance" + balanceKey}
                          disableRipple={true}
                          label={
                            <AccountBox
                              user={user}
                              currencyIcon={balanceItem?.currency?.code}
                              title={balanceItem?.currency?.code ?? ""}
                              content={balanceItem?.balance ?? "0,00"}
                              pendingBalance={balanceItem?.pending ?? 0}
                              isSelectedProp={
                                currency === balanceItem?.currency?.code
                              }
                              onClick={() =>
                                personal ? (
                                  handleAccountBoxClick(
                                    balanceItem?.currency?.code,
                                    balanceItem?.balance
                                  )
                                ) : (
                                  <></>
                                )
                              }
                            />
                          }
                        />
                      ))}
                    </Tabs>
                  </Box>
                ) : (
                  <Typography
                    component="h5"
                    variant="h5"
                    className="welcome-text d-flex jc-start loginSubtitleFontSize"
                    sx={{
                      fontFamily: "Helvetica, sans-serif",
                      textAlign: "left",
                    }}
                  >
                    Please ensure that your account is funded to enjoy our
                    services. If you have any questions or concerns, feel free
                    to contact our support team for assistance.
                  </Typography>
                )}

                {/* Transaction details start */}

                {currency && (
                  <div>
                    <div className="transaction-details-small-container ml-4rem mb-1-5rem loginTitleFontSize">
                      <Typography
                        component="h1"
                        variant="h5"
                        sx={{
                          fontFamily: "Helvetica, sans-serif",
                          fontWeight: "600",
                          color: "#454745",
                          marginBottom: "1rem",
                        }}
                      >
                        {i18next.t("Account transfer details")}
                      </Typography>

                      <div className="d-flex ai-center jusifyContentSpaceBetween mb-0-5rem">
                        <Typography
                          className="loginSubtitleFontSize"
                          sx={{
                            fontFamily: "Helvetica, sans-serif",
                            fontWeight: "200",
                            color: "#454745",
                            textAlign: "center",
                          }}
                        >
                          {i18next.t("Available balance")}{" "}
                          <strong>
                            {selectedBalanceAmount ? selectedBalanceAmount : ""}{" "}
                            {currency}
                          </strong>
                        </Typography>
                      </div>
                    </div>
                    {/* Transaction details end */}

                    <div className="w-100 ta-center">
                      <TextField
                        style={{ width: "30rem" }}
                        type="text"
                        name="amount"
                        placeholder={i18next.t("Enter amount")}
                        value={formik.values.amount}
                        onChange={handleAmountChange}
                        variant="outlined"
                        sx={{
                          "& .MuiOutlinedInput-root": {
                            paddingRight: "0px!important",
                          },
                        }}
                        InputProps={{
                          sx: {
                            borderRadius: "10px",
                            height: "48px",
                            fontSize: "16px",
                            "&:-internal-autofill-selected": {
                              backgroundColor: "transparent !important",
                            },
                            "@media (max-width:600px)": {
                              fontSize: "14px !important",
                            },
                          },
                          endAdornment: (
                            <InputAdornment position="end">
                              <Autocomplete
                                id="currency-select"
                                sx={{
                                  width: "11rem",
                                  fontSize: "16px",
                                  borderRadius: "10px",
                                  "& .MuiOutlinedInput-root": {
                                    border: "none",
                                  },
                                  "& .Mui-focused .MuiOutlinedInput-root": {
                                    border: "none",
                                  },
                                  "& .MuiOutlinedInput-notchedOutline": {
                                    border: "none",
                                  },
                                }}
                                options={[]}
                                disabled
                                value={currency}
                                aria-placeholder="Select"
                                onChange={handleCurrencyChange}
                                renderOption={(props, option) => (
                                  <Box
                                    component="li"
                                    {...props}
                                    className="d-flex ai-center mt-1-rem cp"
                                  >
                                    <p
                                      className="loginSubtitleFontSize"
                                      style={{ marginLeft: 10 }}
                                    >
                                      {currency}
                                    </p>
                                  </Box>
                                )}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    InputProps={{
                                      ...params.InputProps,
                                    }}
                                    sx={{
                                      "& .MuiInputBase-input": {
                                        fontSize: {
                                          xs: "14px",
                                          md: "16px",
                                        },
                                      },
                                    }}
                                  />
                                )}
                                clearIcon={null}
                              />
                            </InputAdornment>
                          ),
                        }}
                      />
                    </div>
                  </div>
                )}

                {/* users start */}
                {currency &&
                  formik.values.amount &&
                  parseFloat(formik.values.amount) > 0 && (
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <div className="d-flex ai-center mb-1-rem mt-2-5rem">
                        <Typography
                          className="your-details-text d-flex jc-start loginTitleFontSize"
                          component="h1"
                          variant="h5"
                          sx={{
                            fontFamily: "Helvetica, sans-serif",
                            // fontSize: "1.875rem",
                            textAlign: "center",
                            fontWeight: "600",
                          }}
                        >
                          {i18next.t("Select user")}
                        </Typography>
                      </div>
                      <div className="d-flex ai-center jusifyContentSpaceBetween mb-0-5rem loginSubtitleFontSize">
                        <Typography
                          // component="h1"
                          // variant="h5"
                          sx={{
                            fontFamily: "Helvetica, sans-serif",
                            // fontSize: "1rem",
                            fontWeight: "200",
                            color: "#454745",
                            textAlign: "center",
                          }}
                        >
                          {i18next.t(
                            "Please enter account email and select the user from the list"
                          )}
                        </Typography>
                      </div>
                      <div>
                        <Box>
                          <TextField
                            placeholder={i18next.t("Search by account email")}
                            style={{ width: "100%" }}
                            value={searchValue}
                            onChange={handleSearchChange}
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  <SearchIcon className="searchIcon" />
                                </InputAdornment>
                              ),
                              sx: {
                                borderRadius: "40px",
                                height: "48px",
                                fontSize: "16px",
                                "&:-internal-autofill-selected": {
                                  backgroundColor: "transparent !important",
                                },
                              },
                            }}
                          ></TextField>
                        </Box>
                      </div>
                      <div>
                        {isLoading ? (
                          <LoadingIndicator />
                        ) : (
                          <div>
                            <UserList
                              type="accountTransfer"
                              data={usersData}
                              onUserSelect={handleUserSelect}
                              hideAddButton={true}
                              selectedUserId={selectedUserId}
                            />
                          </div>
                        )}
                      </div>
                      {selectedUserId && (
                        <div>
                          <TextField
                            InputProps={{
                              rows: 4,
                              multiline: true,
                              inputComponent: "textarea",
                              style: {
                                borderRadius: "10px",
                                fontFamily: "Helvetica, sans-serif",
                                fontSize: "16px",
                                width: "100%",
                                resize: "none",
                                paddingTop: "1rem",
                                paddingLeft: "1rem",
                              },
                              sx: {
                                "@media (max-width:600px)": {
                                  fontSize: "14px !important",
                                },
                              },
                            }}
                            value={purpose}
                            onChange={handlePurposeChange}
                            aria-label="maximum height"
                            placeholder={i18next.t(
                              "Write the purpose of the account transfer"
                            )}
                            className="oneRemFontSize"
                            style={{
                              fontFamily: "Helvetica, sans-serif",
                              width: "100%",
                              resize: "none",
                              paddingTop: "1rem",
                              borderRadius: "10px",
                            }}
                          />

                          <div>
                            <RadioGroup
                              aria-labelledby="demo-controlled-radio-buttons-group"
                              name="controlled-radio-buttons-group"
                              value={sendTime}
                              onChange={handleSendTime}
                            >
                              <FormControlLabel
                                value="now"
                                control={<Radio />}
                                label={i18next.t("Send now")}
                                sx={{
                                  "& .MuiTypography-root": {
                                    fontSize: "16px",

                                    "@media (max-width:600px)": {
                                      fontSize: "14px !important",
                                    },
                                  },
                                }}
                              />
                              <FormControlLabel
                                value="blocked"
                                control={<Radio />}
                                label={i18next.t("Block amount")}
                                sx={{
                                  "& .MuiTypography-root": {
                                    fontSize: "16px",

                                    "@media (max-width:600px)": {
                                      fontSize: "14px !important",
                                    },
                                  },
                                }}
                              />
                            </RadioGroup>
                          </div>
                        </div>
                      )}
                    </Box>
                  )}
                {currency &&
                  formik.values.amount &&
                  parseFloat(formik.values.amount) > 0 && (
                    <Button
                      type="submit"
                      fullWidth
                      variant="contained"
                      className="continueBtn"
                      disabled={
                        isLoading ||
                        !selectedUserId ||
                        !currency ||
                        !formik.values.amount ||
                        formik.values.amount <= "0" ||
                        !purpose ||
                        !(purpose.length > 5) ||
                        parseFloat(
                          formik.values.amount?.toString().replace(/,/g, "")
                        ) <= 0 ||
                        parseFloat(
                          formik.values.amount?.toString().replace(/,/g, "")
                        ) > parseFloat(selectedBalanceAmount?.replace(/,/g, ""))
                      }
                      onClick={handleSubmit}
                    >
                      {i18next.t("Pay")}
                    </Button>
                  )}

                {/* users end */}
              </Box>
              {/* select account end */}
            </Container>
          </ThemeProvider>
        </div>
      </div>
    </div>
  );
}
