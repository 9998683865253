import {
  Avatar,
  Box,
  IconButton,
  LinearProgress,
  Typography,
} from "@mui/material";
import "../../../pages/Chat/Chat.scss";
import moment from "moment";
import { useEffect, useState } from "react";
import PDFModal from "../PdfModal/PdfModal";
import api from "src/store/interceptors/api";
import { toast } from "react-toastify";
import AreYouSureModal from "../AreYouSureModal/AreYouSureModal";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { debounce } from "lodash";
import i18next from "i18next";

export default function ConversationContainer({
  user,
  conversationMessages,
  conversations,
  selectedConversationUser,
  chatContainerRef,
  getConversationMessages,
  loadMore,
  handleScroll,
}: {
  user: any;
  conversations: any;
  conversationMessages: any;
  selectedConversationUser: any;
  chatContainerRef: React.RefObject<HTMLDivElement>;
  getConversationMessages: () => Promise<void>;
  loadMore: boolean;
  handleScroll: () => void;
}) {
  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
  const [deleteMessageId, setDeleteMessageId] = useState<number | null>(null);
  const handleDeleteMessageModal = (messageId: number) => {
    setDeleteMessageId(messageId);
    setDeleteModalOpen(true);
  };

  const handleDeleteMessage = async () => {
    try {
      if (deleteMessageId !== null) {
        await api.delete(`/messages/for-everyone/${deleteMessageId}`);
        setDeleteModalOpen(false);
        toast.success("Message is deleted");
        getConversationMessages();
      }
    } catch (error: any) {
      console.log("Error deleting message", error);
    }
  };
  const formatTimestamp = (timestamp: string) => {
    const createdAtMoment = moment(timestamp);
    const now = moment();

    const diffInMinutes = now.diff(createdAtMoment, "minutes");
    const diffInHours = now.diff(createdAtMoment, "hours");

    if (diffInMinutes < 1) {
      return "Just now";
    } else if (diffInMinutes < 60) {
      return `${diffInMinutes} minutes ago`;
    } else if (diffInHours < 24) {
      return `${diffInHours} hours ago`;
    } else {
      return createdAtMoment.format("MMM D, YYYY [at] h:mm A");
    }
  };

  // pdf start
  const [openModal, setOpenModal] = useState(false);
  const [selectedPdf, setSelectedPdf] = useState("");

  const handleOpenModal = (pdfUrl: any) => {
    setSelectedPdf(pdfUrl);
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };
  // pdf end

  const renderAttachment = (attachment: any) => {
    const fileExtension = attachment.file_name.split(".").pop()?.toLowerCase();
    const isImage =
      attachment.mime.startsWith("image/") ||
      fileExtension === "jpg" ||
      fileExtension === "jpeg" ||
      fileExtension === "png" ||
      fileExtension === "gif" ||
      fileExtension === "webp";

    if (isImage) {
      return (
        <img
          key={attachment.id}
          src={attachment.file_url}
          alt={attachment.file_name}
          style={{ maxWidth: "100%", maxHeight: "200px" }}
        />
      );
    } else if (fileExtension === "pdf") {
      return (
        <div key={attachment.id}>
          <Typography>{attachment.original_name}</Typography>
          <button
            style={{
              background: "none",
              border: "none",
              padding: 0,
              color: "#007bff",
              textDecoration: "underline",
              cursor: "pointer",
              fontSize: "0.65rem",
            }}
            onClick={() => handleOpenModal(attachment.file_url)}
            type="button"
          >
            View PDF attachment
          </button>
          <PDFModal
            open={openModal}
            handleClose={handleCloseModal}
            fileUrl={selectedPdf}
          />
        </div>
      );
    } else {
      return (
        <div>
          <Typography>{attachment.original_name}</Typography>
          <button
            style={{
              background: "none",
              border: "none",
              padding: 0,
              color: "#007bff",
              textDecoration: "underline",
              cursor: "pointer",
              fontSize: "0.65rem",
            }}
            key={attachment.id}
            onClick={() => window.open(attachment.file_url, "_blank")}
            type="button"
          >
            View document
          </button>
        </div>
      );
    }
  };

  useEffect(() => {
    scrollToBottom();
  }, [conversationMessages, chatContainerRef]);

  const scrollToBottom = () => {
    if (chatContainerRef.current) {
      const { scrollTop, scrollHeight } = chatContainerRef.current;

      chatContainerRef.current.scrollTop = scrollHeight;
    }
  };

  useEffect(() => {
    const handleContainerScroll = debounce(() => {
      const container = chatContainerRef.current;

      if (container) {
        const scrollTop = container.scrollTop;
        const isNearTop = scrollTop <= 50;
        const currentLoadMore = loadMore;

        if (isNearTop && !currentLoadMore) {
          handleScroll();
        }
      }
    }, 100);

    const currentRef = chatContainerRef.current;

    if (currentRef) {
      currentRef.addEventListener("scroll", handleContainerScroll);
      return () => {
        currentRef.removeEventListener("scroll", handleContainerScroll);
      };
    }
  }, [chatContainerRef, handleScroll, loadMore]);

  return (
    <>
      {loadMore && <LinearProgress />}
      <Box className="convoBox" ref={chatContainerRef}>
        {conversationMessages.length > 0
          ? conversationMessages
              .slice()
              .reverse()
              .map((message: any) => (
                <Box
                  key={"message" + message.id}
                  className={
                    message.user_id === selectedConversationUser?.id
                      ? "chatThemMain"
                      : "chatUsMain"
                  }
                >
                  {message.user_id === selectedConversationUser?.id && (
                    <Avatar
                      src={selectedConversationUser?.image?.file_url ?? null}
                      alt={"Avatar"}
                      sx={{
                        width: "32px",
                        height: "32px",
                        marginRight: "16px",
                      }}
                    ></Avatar>
                  )}
                  <Box
                    className={
                      message.user_id === selectedConversationUser?.id
                        ? "lastTimeAndMessageThem"
                        : "lastTimeAndMessageUs"
                    }
                  >
                    <Box>
                      {" "}
                      <Typography
                        className={
                          message.user_id === selectedConversationUser?.id
                            ? "lastTimeConvoThem"
                            : "lastTimeConvoUs"
                        }
                      >
                        {message.user_id === selectedConversationUser?.id
                          ? formatTimestamp(message.created_at)
                          : `${message.username}, ${formatTimestamp(
                              message.created_at
                            )}`}
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                      }}
                    >
                      {message.user_id !== selectedConversationUser?.id &&
                      user.role === "Admin" ? (
                        <IconButton
                          sx={{
                            marginTop: "-35px",
                            marginRight: "3px",
                          }}
                          color="error"
                          onClick={() => handleDeleteMessageModal(message.id)}
                          size="small"
                        >
                          <DeleteOutlineIcon
                            sx={{
                              width: "20px",
                              height: "20px",
                            }}
                          />
                        </IconButton>
                      ) : null}
                      <Box
                        className={
                          message.user_id === selectedConversationUser?.id
                            ? "chatThemBox"
                            : "chatUsBox"
                        }
                      >
                        <Typography
                          className={
                            message.user_id === selectedConversationUser?.id
                              ? "chatThemText"
                              : "chatUsText"
                          }
                        >
                          {message.message}
                        </Typography>
                        <Box
                          className={
                            message.user_id === selectedConversationUser?.id
                              ? "attachmentThemText"
                              : "attachmentUsText"
                          }
                        >
                          {message.attachments &&
                            message.attachments.length > 0 &&
                            message.attachments.map((attachment: any) =>
                              renderAttachment(attachment)
                            )}
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              ))
          : null}
        <AreYouSureModal
          open={isDeleteModalOpen}
          handleClose={() => setDeleteModalOpen(false)}
          handleYes={handleDeleteMessage}
          title={i18next.t("Are you sure you want to delete this message?")}
        />
      </Box>
    </>
  );
}
