import React from "react";
import { Box, Typography } from "@mui/material";
import "./Timeline.scss";
import clipboardCopy from "clipboard-copy";

interface TimelineItem {
  status: string;
  mainInfo: string;
  timeText: string;
  id: string;
  color?: string;
}

interface TimelineProps {
  title: string;
  timelineData: TimelineItem[];
}

const Timeline: React.FC<TimelineProps> = ({ title, timelineData }) => {
  const handleItemClick = (item: any) => {
    let path;
    switch (title) {
      case "Verification requests":
        path = `/admin/users/${item.id}/account-information`;
        break;
      case "Account requests":
        path = `/admin/users/${item.id}/account-requests`;
        break;
      case "Payment requests":
        path = `/balance-detail/${item.id}`;
        break;
      case "Transactions":
        path = `/transaction-details/${item.id}`;
        break;
      case "Verification codes":
        handleCopyToClipboard(item);
        return;
      case "Account Status / Created":
        path = `/admin/users/${item.id}/account-information`;
        break;
      case "Account Status / Has transactions":
        path = `/admin/users/${item.id}/account-information`;
        break;
      default:
       // console.error("Unknown item title");
        return;
    }

    // Open in a new tab
    window.open(`${window.location.origin}${path}`, "_blank");

    // Set item in local storage
    localStorage.setItem("from", "Actions");
  };

  function handleCopyToClipboard(dataToCopy: any) {
    if (dataToCopy) {
      const extractedNumber = dataToCopy?.mainInfo.match(/^\d+/)[0];
      clipboardCopy(extractedNumber);
      alert("Copied to clipboard: " + extractedNumber);
    }
  }

  return (
    <Box className="timelineMainBox">
      <Box className="timelineHeadBox">
        <Typography className="timelineHeadText">{title}</Typography>
      </Box>
      {timelineData.length > 0 ? (
        <Box className="timelineListBox">
          {timelineData.map((item, index) => (
            <Box
              key={index}
              className="timelineSingleItemBox cp"
              onClick={() => handleItemClick(item)}
            >
              <Box className="statusAndLine">
                <Box className={`status ${item?.status}`}></Box>
                <Box className="line"></Box>
              </Box>
              <Box className="timelineInfoBox">
                <Box>
                  <Typography className="timelineMainInfo">
                    {item.mainInfo}
                  </Typography>
                </Box>
                <Box>
                  <Typography className="timelineTimeText">
                    {item.timeText}
                  </Typography>
                </Box>
              </Box>
            </Box>
          ))}
        </Box>
      ) : (
        // Render this block if timelineData is empty
        <Box className="timelineEmptyDataBox">
          <Typography className="timelineNoDataText">
            There is no data available for this section.
          </Typography>
        </Box>
      )}
    </Box>
  );
};

export default Timeline;
