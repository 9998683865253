import React, { useEffect, useState } from "react";
import { Link, Box, Tabs, Tab, Typography } from "@mui/material";
import "./PaymentInstructions.scss";
import PriorityYellowIcon from "src/pages/Authentication/Registration/components/PriorityIcon";
import i18next from "i18next";

interface PaymentInstructionsProps {
  data: any | null; // Explicitly define the type as string or null
}
interface TabPanelProps {
  item: any;
  index: number;
  value: number;
  reference?: string | null;
}

function CustomTabPanel(props: TabPanelProps) {
  const { item, value, index, reference, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <div className="payment-instruction-tab">
            <Typography
              className="registration0875RemFontSize"
              sx={{
                pb: 3,
                fontFamily: "Helvetica, sans-serif",
                fontWeight: "300",
              }}
            >
              {i18next.t(item.description)}
            </Typography>

            {reference && (
              <Box key={"fieldItemIndexReference"} sx={{ pb: 3 }}>
                <Typography
                  className="registration0875RemFontSize"
                  sx={{
                    fontFamily: "Helvetica, sans-serif",
                    fontWeight: "300",
                  }}
                >
                  {i18next.t("Reference")}
                </Typography>
                <Typography
                  className="FontSize1075"
                  sx={{
                    fontFamily: "Helvetica, sans-serif",
                    fontWeight: "300",
                  }}
                >
                  {reference}
                </Typography>
              </Box>
            )}
            {item &&
              item.details &&
              item.details.length > 0 &&
              item.details.map((fieldItem: any, fieldItemIndex: number) => {
                return (
                  <Box key={"fieldItemIndex" + fieldItemIndex} sx={{ pb: 3 }}>
                    <Typography
                      className="registration0875RemFontSize"
                      sx={{
                        fontFamily: "Helvetica, sans-serif",
                        fontWeight: "300",
                      }}
                    >
                      {i18next.t(fieldItem.title)}
                    </Typography>
                    <Typography
                      className="FontSize1075"
                      sx={{
                        fontFamily: "Helvetica, sans-serif",
                        fontWeight: "300",
                      }}
                    >
                      {fieldItem.body}
                    </Typography>
                  </Box>
                );
              })}
          </div>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function prepareData(data: any) {
  let res: any = null;
  if (data?.currency?.code === "NGN") {
    const filtered = data.instructions.filter(
      (item: any) => item.id === 32635461
    );
    if (filtered.length > 0) {
      res = filtered[0].currency.filter(
        (item: any) => item.code === "EUR" || item.code === "GLOBAL"
      );
      if (res.length > 0) res = res[0];
    }
  } else {
    const filtered = data.instructions.filter(
      (item: any) => item.id === 32635460
    );
    if (filtered.length > 0) {
      res = filtered[0].currency.filter(
        (item: any) => item.code === "EUR" || item.code === "GLOBAL"
      );
      if (res.length > 0) res = res[0];
    }
  }

  return res;
}
const PaymentInstructions: React.FC<PaymentInstructionsProps> = ({ data }) => {
  const [filteredData, setFilteredData] = useState<any | null>(null);
  const [value, setValue] = useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const getInitialData = (obj: any) => {
    if (obj && obj.instructions && obj.currency.code) {
      setFilteredData(prepareData(obj));
    }
  };
  useEffect(() => {
    getInitialData(data);
  }, [data]);

  return (
    <Box>
      <Box>
        <Box>
          <Typography
            className="your-details-text d-flex jc-start pageTitleFontSize"
            component="h1"
            variant="h5"
            sx={{
              fontFamily: "Helvetica, sans-serif",
              fontWeight: "600",
              textAlign: "start",
            }}
          >
            {i18next.t("Pay by bank transfer")}
          </Typography>
          <Typography className="pageTextFontSize" sx={{ pt: 2, pb: 2 }}>
            {i18next.t(
              "The details to use depend on where your bank account’s registered."
            )}
          </Typography>
        </Box>

        <div className="box-warning-info mt-2rem ta-start">
          <PriorityYellowIcon />
          <div>
            <h3
              className="small-text mb-0-5rem fw-400 pageTextFontSize"
              style={{
                fontFamily: "Helvetica, sans-serif",
                fontWeight: 300,
                lineHeight: 1.5,
              }}
            >
              {i18next.t(
                "When paying through your bank, make sure to use the reference"
              )}{" "}
              <strong>{data?.reference ?? " / "}</strong>
            </h3>
          </div>
        </div>
        <Box>
          <Typography
            className="registration1375RemFontSize"
            sx={{
              pt: 2,
              pb: 2,
              fontFamily: "Helvetica, sans-serif",
              fontWeight: "400",
              textAlign: "start",
            }}
          >
            {i18next.t("Amount")}{" "}
            <strong>
              {data?.amount ?? ""}{" "}
              {data &&
              data.meta &&
              data.meta.amount &&
              data.meta.amount.currency
                ? data.meta.amount.currency
                : data && data.currency && data.currency.code
                ? data.currency.code
                : "EUR"}
            </strong>
          </Typography>
        </Box>
      </Box>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
        >
          {filteredData &&
            filteredData.receiveOptions &&
            filteredData.receiveOptions.map((item: any, key: number) => {
              return (
                <Tab
                  className="registration0875RemFontSize"
                  key={"tabitem" + key}
                  label={i18next.t(item.title)}
                  {...a11yProps(key)}
                />
              );
            })}
        </Tabs>
      </Box>
      {filteredData &&
        filteredData.receiveOptions &&
        filteredData.receiveOptions.map((item: any, key: number) => {
          return (
            <CustomTabPanel
              key={"CustomTabPanel" + key}
              value={value}
              index={key}
              item={item}
              reference={data.reference}
            />
          );
        })}
      {data && data.meta && data.meta.link && (
        <Box sx={{ pt: 4, pb: 4 }}>
          <Typography
            className="registration1375RemFontSize"
            sx={{
              pt: 2,
              pb: 2,
              fontFamily: "Helvetica, sans-serif",
              fontWeight: "400",
              textAlign: "start",
            }}
          >
            {i18next.t("More ways to pay")}
          </Typography>
          <Link
            target="_blank"
            underline="hover"
            sx={{ textDecoration: "none", color: "#000" }}
            variant="body1"
            href={data.meta.link}
          >
            {i18next.t("Pay with link")}
          </Link>
        </Box>
      )}
    </Box>
  );
};

export default PaymentInstructions;
