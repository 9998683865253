import React, { useState, useEffect } from "react";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Nav from "src/components/layout/nav/Nav";
import { Typography, Box } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import LoadingIndicator from "src/components/shared/LoadingIndicator";
import balanceServices from "src/store/balance/balance_services";
import CryptoDetail from "./components/Crypto/CryptoDetail";
import { useAppSelector } from "src/store";
import CryptoRequestAdminActions from "./components/CryptoRequestAdminActions";
const defaultTheme = createTheme();
const CryptoRequestDetailPage: React.FC = () => {
  const { user } = useAppSelector((state) => state.auth);
  const { cryptoRequestId }: any = useParams();
  const [cryptoRequestData, setCryptoRequestData] = useState<any>(null);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();

  const handleGetCryptoOrders = async () => {
    setIsLoading(true);

    const response = await balanceServices.getCryptoRequestById(
      cryptoRequestId
    );
    const { data } = response;
    setIsLoading(false);
    if (data) {
      setCryptoRequestData(data);
    }
  };

  const handleNavigate = () => {
    navigate(-1);
    //navigate("/transactions-list");
  };

  useEffect(() => {
    handleGetCryptoOrders();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <Nav step={0} isStepperVisible={false} isProfileSetVisible={true} />
      <div className="content-container-profile">
        <ThemeProvider theme={defaultTheme}>
          <Container component="main">
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <div className="d-flex ai-center mb-3-rem">
                <p
                  className="back-button-icon circle-around-text-smaller helvetica mr-1-rem cp"
                  onClick={handleNavigate}
                >
                  <ArrowBackIcon className="muiIcons"/>
                </p>
                <Typography
                  className="your-details-text d-flex jc-start pageTitleFontSize"
                  component="h1"
                  variant="h5"
                  sx={{
                    fontFamily: "Helvetica, sans-serif",
                    textAlign: "center",
                    fontWeight: "600",
                  }}
                >
                  Crypto
                </Typography>
              </div>
              <div>
                {isLoading ? (
                  <LoadingIndicator />
                ) : cryptoRequestData ? (
                  <div>
                    {user && user.role && (user.role === "Admin" || user.role === "Employee") && (
                    <CryptoRequestAdminActions
                      user={user}
                      data={cryptoRequestData}
                    />
                  )}
                    <CryptoDetail cryptoData={cryptoRequestData} />
                  </div>
                ) : (
                  <div>No data found!</div>
                )}
              </div>
            </Box>
          </Container>
        </ThemeProvider>
      </div>
    </div>
  );
};

export default CryptoRequestDetailPage;
