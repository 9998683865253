import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import "./BalanceDetails.scss";
import { useState, useEffect } from "react";
import Nav from "src/components/layout/nav/Nav";
import { Box, Button, Tooltip, Typography } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate, useParams } from "react-router-dom";
import api from "src/store/interceptors/api";
import AccountBox from "src/components/shared/AccountBox/AccountBox";
import { formatAccountNumber } from "src/utils/helpers";
import LoadingIndicator from "src/components/shared/LoadingIndicator";
import i18next from "i18next";
import CopyableTypography from "src/components/shared/CopyableTypography";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import useClipboard from "react-use-clipboard";

const fields: any = [
  { name: "account-name", label: "Account Name" },
  { name: "iban", label: "IBAN" },
  { name: "swift", label: "SWIFT" },
  { name: "bank-country", label: "Bank Country" },
  { name: "bank-name", label: "Bank Name" },
  { name: "branch-name", label: "Branch Name" },
  { name: "account-head", label: "Account Head" },
];

const defaultTheme = createTheme();
const BalanceDetails = () => {
  const [copyDetails, setCopyDetails] = useState<any>(null);
  const [isCopied, setCopied] = useClipboard(copyDetails ?? "");
  const { balanceId }: any = useParams();
  const [user, setUser] = useState<any>(null);
  const [balanceItem, setBalanceItem] = useState<any>(null);
  const [loadingMe, setLoadingMe] = useState(false);
  const [loadingBalance, setLoadingBalance] = useState(false);
  const [showTooltip, setShowTooltip] = useState<boolean>(false);

  const navigate = useNavigate();

  const handleNavigate = () => {
    navigate(-1);
  };

  const handleRedirectToUser = () => {
    if (
      balanceItem &&
      user &&
      (user.role === "Admin" || user.role === "Employee")
    ) {
      navigate("/admin/users/" + balanceItem.user_id + "/account-requests");
    }
  };

  const getMe = async () => {
    setLoadingMe(true);
    try {
      await api
        .get("/me")
        .then((response: any) => {
          if (response) {
            if (response && response.data && response.data.data) {
              setUser(response.data.data);
              getBalance();
            }
          }
        })
        .catch((error) => {});
    } catch (error) {
      console.log(error);
    }
    setLoadingMe(false);
  };

  const getBalance = async () => {
    setLoadingBalance(true);
    try {
      await api
        .get("/user-balances/" + balanceId)
        .then((response: any) => {
          if (response) {
            if (response && response.data && response.data.data) {
              setBalanceItem(response.data.data);
            }
          }
        })
        .catch((error) => {});
    } catch (error) {
      console.log(error);
    }
    setLoadingBalance(false);
  };

  const handleCopyAll = () => {
    setCopied();
    setShowTooltip(true);
    setTimeout(() => setShowTooltip(false), 2000);
  };

  useEffect(() => {
    getMe();
  }, []);
  useEffect(() => {
    if (balanceItem && balanceItem.meta && balanceItem.meta.length > 0) {
      const detailsToCopy = fields
        .filter((field: any) => !field.hidden)
        .map((field: any) => {
          const value = balanceItem.meta[0][field.name];
          return value ? `${field.label}: ${value}` : "";
        })
        .filter((line: any) => line !== "")
        .join("\n");

      setCopyDetails(detailsToCopy);
    } else {
      setCopyDetails("");
    }
  }, [balanceItem]);

  return (
    <Box>
      <Nav step={0} isStepperVisible={false} isProfileSetVisible={true} />
      <ThemeProvider theme={defaultTheme}>
        <Container component="main">
          {loadingBalance || loadingMe ? (
            <Box className="settingsMain">
              <LoadingIndicator />
            </Box>
          ) : (
            <Box className="settingsMain" sx={{ paddingBottom: 30 }}>
              {balanceItem && user && (
                <Box sx={{ flex: 1 }}>
                  <Typography
                    className="mb-1rem  d-flex jc-start FontSize1275"
                    component="h1"
                    variant="h5"
                    onClick={() => handleRedirectToUser()}
                    sx={{
                      justifyContent: "flex-end",
                      cursor: "pointer",
                      fontFamily: "Helvetica, sans-serif",
                      fontWeight: 600,
                      color: "#000000",
                    }}
                  >
                    {i18next.t("Account number")}:{" "}
                    {balanceItem && balanceItem.user_id
                      ? formatAccountNumber(balanceItem.user_id)
                      : ""}
                  </Typography>
                </Box>
              )}
              <div className="d-flex ai-center mb-3-rem">
                <p
                  className="back-button-icon circle-around-text-smaller helvetica mr-1-rem cp"
                  onClick={handleNavigate}
                >
                  <ArrowBackIcon className="muiIcons" />
                </p>

                <Typography
                  className="your-details-text d-flex jc-start pageTitleFontSize"
                  component="h1"
                  variant="h5"
                  sx={{
                    fontFamily: "Helvetica, sans-serif",
                    fontWeight: "600",
                    textAlign: "start",
                  }}
                >
                  {i18next.t("Account")}{" "}
                  {balanceItem ? balanceItem.currency.code : ""}
                </Typography>
              </div>

              <Box sx={{ mt: 2 }}>
                {balanceItem && (
                  <Box>
                    <AccountBox
                      user={user}
                      currencyIcon={balanceItem?.currency?.code}
                      title={balanceItem?.currency?.code ?? ""}
                      content={balanceItem?.balance ?? "0,00"}
                      pendingBalance={balanceItem?.pending ?? 0}
                    />
                  </Box>
                )}

                {balanceItem &&
                  balanceItem.provider &&
                  balanceItem.provider && (
                    <Box sx={{ mt: 2 }}>
                      <div className="payment-instruction-tab">
                        <Typography
                          className="FontSize12"
                          sx={{
                            pb: 3,
                            fontFamily: "Helvetica, sans-serif",
                            fontWeight: "300",
                          }}
                        >
                          {i18next.t("Account details")}
                        </Typography>

                        {fields &&
                          fields.map(
                            (detailsItem: any, detailsItemKey: any) => {
                              if (!detailsItem.hidden)
                                return (
                                  <Box
                                    key={"fieldItemIndex" + detailsItemKey}
                                    sx={{
                                      pb: 3,
                                      justifyContent: "space-between",
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                  >
                                    <Typography
                                      className="registration0875RemFontSize"
                                      sx={{
                                        fontFamily: "Helvetica, sans-serif",
                                        fontWeight: "300",
                                      }}
                                    >
                                      {i18next.t(detailsItem.label)}
                                    </Typography>
                                    {balanceItem &&
                                      balanceItem.meta &&
                                      balanceItem.meta.length > 0 && (
                                        <CopyableTypography
                                          value={
                                            balanceItem.meta[0][
                                              detailsItem.name
                                            ]
                                          }
                                        />
                                      )}
                                  </Box>
                                );
                            }
                          )}

                        <Tooltip
                          title={showTooltip ? i18next.t("COPIED!") : ""}
                          open={showTooltip}
                          arrow
                          placement="top"
                          classes={{
                            tooltip: "customTooltipAccDetails",
                            arrow: "customTooltipArrowACCDetails",
                          }}
                          PopperProps={{
                            sx: {
                              zIndex: 1,
                            },
                          }}
                        >
                          <span>
                            <Button
                              sx={{
                                color: "#000000",
                                backgroundColor: "transparent",
                                borderRadius: "24px",
                                boxShadow: "none",
                                border: "1px solid #000000",
                                fontWeight: 400,
                                padding: "6px 18px",
                                textTransform: "none",
                                fontFamily: "Helvetica, sans-serif",
                                "&:hover": {
                                  backgroundColor: "#2A5182",
                                  color: "white",
                                },
                              }}
                              onClick={handleCopyAll}
                              startIcon={
                                <ContentCopyIcon style={{ width: "20px" }} />
                              }
                            >
                              <Typography className="FontSize1075">
                                {i18next.t("Copy All")}
                              </Typography>
                            </Button>
                          </span>
                        </Tooltip>
                      </div>
                    </Box>
                  )}
              </Box>
            </Box>
          )}
        </Container>
      </ThemeProvider>
    </Box>
  );
};

export default BalanceDetails;
