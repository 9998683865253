import React, { ReactNode } from 'react';
import { useUser } from '../../hooks/useUser';
import Error404 from 'src/pages/Error/Error404';

interface RequireAdminProps {
  children: ReactNode;
}

const RequireAdmin: React.FC<RequireAdminProps> = ({ children }) => {
  const user = useUser();

  if (user?.role !== 'Admin' && user?.role !== 'Employee') {
     return <Error404 />;
  }
  return <>{children}</>;
};

export default RequireAdmin;
