import { Box, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import api from "src/store/interceptors/api";
import AccountRequestsTableList from "../../AccountRequestsList/AccountRequestsTableList";
import UserDetailsTabs from "../../UserDetailsTabs/UserDetailsTabs";

function UserAccountRequests({ data }: any) {
  const [user, setUser] = useState(data);
  const [loggedUser, setLoggedUser] = useState("");

  const getLoggedUserRole = async () => {
    try {
      const response = await api.get(`me`);
      const loggedUserRole =
        response && response.data && response.data.data.role;
      if (loggedUserRole) {
        setLoggedUser(loggedUserRole);
      }
    } catch (exception) {
      console.log(exception);
    }
  };

  useEffect(() => {
    getLoggedUserRole();
  }, [loggedUser]);

  useEffect(() => {
    setUser(data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return (
    <Box className="recentBox">
      {!user ? (
        <Typography className="noDataText">No user found</Typography>
      ) : (
        <Box>
          <UserDetailsTabs user={user} />
          <Box sx={{ mt: 2 }}>
            {/* <div className="d-flex ai-center mb-3-rem">
              <Typography
                className="your-details-text d-flex jc-start"
                component="h1"
                variant="h5"
                sx={{
                  fontFamily: "Helvetica, sans-serif",
                  fontSize: "1.875rem",
                  textAlign: "center",
                  fontWeight: "600",
                }}
              >
                {user &&
                user.role &&
                (user.role === "Admin" || user.role === "Employee")
                  ? "Account details"
                  : "Your details"}
              </Typography>
            </div> */}
          </Box>

          <AccountRequestsTableList
            style={{ marginTop: 34 }}
            hideNavigation={true}
            title="Account Requests"
            data={user?.balances ?? []}
          />
        </Box>
      )}
    </Box>
  );
}

export default UserAccountRequests;
