import React, { useState } from "react";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import { Button } from "@mui/material";
import "./PaymentForm.scss";
import i18next from "i18next";
interface PaymentFormProps {
  clientSecret: string | null; // Explicitly define the type as string or null
  onSuccess: (result: any) => void;
}

const cardElementOptions = {
  style: {
    base: {
      color: "#666",
      fontSize: "20px",
    },
    invalid: {
      color: "#fa755a",
      fontSize: "20px",
    },
  },
};
const PaymentForm: React.FC<PaymentFormProps> = ({
  clientSecret,
  onSuccess,
}) => {
  const stripe = useStripe();
  const elements = useElements();
  const [error, setError] = useState<string | null>(null);
  const [isSubmitting, setIsSubmitting] = useState(false); // New state variable

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsSubmitting(true); // Disable the button when submission starts

    if (!stripe || !elements || !clientSecret) {
      setIsSubmitting(false); // Re-enable the button if submission can't proceed
      return;
    }

    const card = elements.getElement(CardElement)!;

    const result = await stripe.confirmCardPayment(clientSecret, {
      payment_method: {
        card,
      },
    });

    if (result.error) {
      setError(result.error.message ?? "Error");
    } else {
      onSuccess(result);
    }

    setIsSubmitting(false); // Re-enable the button after submission
  };

  const handleCardDetailsChange = (event: any) => {
    // If there are errors in the CardElement input, set the error state
    if (event.error) {
      setError(event.error.message);
    } else {
      setError(null); // Reset the error state when the card details change
    }
  };
  

  return (
    <div
      id="stripe-wrapper"
      className="paymentMainForm"
      style={{ maxWidth: 400, padding: 20, margin: "0 auto" }}
    >
      <form onSubmit={handleSubmit}>
        <CardElement options={cardElementOptions} onChange={handleCardDetailsChange}/>
        {error && <div style={{ color: "red" }}>{error}</div>}
        <div className="d-flex ai-center jusifyContentSpaceBetween mt-8">
          <Button
            type="submit"
            fullWidth
            variant="contained"
            className="btnsLoginHeight oneRemFontSize"
            sx={{
              color: "white",
              backgroundColor: "#2A5182",
              borderRadius: "24px",
              textTransform: "none",
              marginTop: "0.5rem",
              marginBottom: "1rem",
              fontFamily: "Helvetica, sans-serif",
            }}
            disabled={isSubmitting}
          >
            {i18next.t("Pay")}
          </Button>
        </div>
      </form>
    </div>
  );
};

export default PaymentForm;
