import { useEffect, useState } from "react";
import { useFormik } from "formik";
import Button from "@mui/material/Button";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import "./Login.scss";
import { useNavigate } from "react-router-dom";
import Nav from "src/components/layout/nav/Nav";
import { useAppDispatch, useAppSelector } from "src/store";
import { loginUser, loginWallet } from "src/store/login/login_slice";
import Modal from "@mui/material/Modal";
import {
  hooks,
  walletConnectV2,
} from "src/services/Connectors/walletConnectV2";
import { URI_AVAILABLE } from "@web3-react/walletconnect-v2";
import LoadingIndicator from "src/components/shared/LoadingIndicator";
import ConfirmEmailModal from "src/components/shared/ConfirmEmailModal/ConfirmEmailModal";
import i18next from "i18next";
import * as Yup from "yup";
import CustomInput from "src/components/shared/CustomInput/CustomInput";

const styleModal = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

const defaultTheme = createTheme();

const { useAccounts, useIsActive } = hooks;

export default function SignIn() {
  const accounts = useAccounts();
  const isActive = useIsActive();
  const [walletLogin, setWalletLogin] = useState(false);

  const [isConfirmationModalOpen, setConfirmationModalOpen] = useState(false);
  const [isConfirmEmailModalOpen, setIsConfirmEmailModalOpen] = useState(false);

  const handleInit = async () => {
    await walletConnectV2.connectEagerly().catch((error) => {});
    await walletConnectV2.events.on(URI_AVAILABLE, (uri: string) => {});
  };

  const connectWallet = async () => {
    try {
      setWalletLogin(true);
      await handleInit();
      await walletConnectV2.resetState();
      await walletConnectV2.activate();
    } catch (exception) {}
  };

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { isSuccess, token } = useAppSelector((state) => state.auth);

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email(i18next.t("Invalid email address"))
        .required(i18next.t("Required")),
      password: Yup.string()
        .required(i18next.t("Required"))
        .min(8, i18next.t("Password must be at least 8 characters")),
    }),
    onSubmit: async (values) => {
      try {
        const response = await dispatch(loginUser(values));
        if (loginUser.fulfilled.match(response)) {
          if (response.payload?.action === "check_verification_code") {
            setIsConfirmEmailModalOpen(true);
          }
        }
      } catch (error) {
        console.log("Error submitting form:", error);
      }
    },
    validateOnChange: false,
    validateOnBlur: false,
  });

  const handleTroubleLoggingInClick = () => {
    navigate("/forget-password");
  };
  const handleCreateAnAccountClick = (event: any) => {
    navigate("/registration");
  };

  const handleCloseModal = () => {
    setIsConfirmEmailModalOpen(false);
  };

  const handleCancel = () => {
    setConfirmationModalOpen(false);
  };

  const onSignMessage = async () => {
    if (!walletConnectV2) {
      return;
    }

    try {
      setConfirmationModalOpen(true);
      const accounts: string[] | undefined =
        await walletConnectV2.provider?.request({
          method: "eth_accounts",
        });

      if (!accounts) {
        return;
      }

      const addressAccount = accounts[0];
      const message = "Victorum Finance Login";

      const signature = await walletConnectV2.provider?.request({
        method: "personal_sign",
        params: [message, addressAccount],
      });

      setConfirmationModalOpen(false);
      return {
        method: "sign message",
        signature: signature,
        address: addressAccount,
      };
    } catch (exception) {
      console.log("exception", exception);
      setConfirmationModalOpen(false);
    }
  };

  const handleSignatureMsg = async () => {
    const signResponse = await onSignMessage();
    if (signResponse && signResponse.signature) {
      handleWalletConnectAPI(signResponse.signature as string);
    }
  };

  const handleWalletConnectAPI = async (payloadAddress: string) => {
    if (accounts && accounts.length > 0) {
      const address = accounts[0];
      dispatch(
        loginWallet({ signature: payloadAddress, public_address: address })
      );
    }
  };

  useEffect(() => {
    if (walletLogin && isActive) {
      if (accounts && accounts.length > 0) {
        handleSignatureMsg();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accounts, walletLogin, isActive]);

  useEffect(() => {
    if (isSuccess || token) {
      window.location.href = "/home";
    }
  }, [isSuccess, token]);

  return (
    <>
      {!token && (
        <Box>
          <Nav step={0} isStepperVisible={false} />
          <Box className="content-container">
            <ThemeProvider theme={defaultTheme}>
              <Container component="main">
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <Typography
                    className="mb-1rem login-text ta-center loginTitleFontSize"
                    component="h1"
                    variant="h5"
                  >
                    {i18next.t("Log in")}
                  </Typography>

                  <>
                    <ConfirmEmailModal
                      open={isConfirmEmailModalOpen}
                      handleClose={handleCloseModal}
                      email={formik.values.email}
                      isEmailValid={!!formik.values.email.length}
                      password={formik.values.password}
                    />
                  </>

                  <Typography
                    component="h5"
                    variant="h5"
                    className="login-text loginSubtitleFontSize"
                    sx={{
                      fontFamily: "Helvetica, sans-serif",
                      textAlign: "center",
                    }}
                  >
                    {i18next.t(
                      "Pay securely and quickly worldwide - receive payments in different currencies at low cost"
                    )}
                  </Typography>
                  <Typography
                    component="h5"
                    variant="h5"
                    className="login-text loginSubtitleFontSize"
                    sx={{
                      fontFamily: "Helvetica, sans-serif",
                      marginTop: "15px",
                      textAlign: "center",
                    }}
                  >
                    {i18next.t(
                      "Securely log in to the VicPay platform using your email address and password or effortlessly access it through your existing wallet"
                    )}
                  </Typography>

                  <Box
                    component="form"
                    onSubmit={formik.handleSubmit}
                    noValidate
                    sx={{ mt: 3 }}
                  >
                    <CustomInput
                      id="email"
                      placeHolder={i18next.t("Email Address")}
                      value={formik.values.email}
                      onChangeText={(value) =>
                        formik.setFieldValue("email", value)
                      }
                      isTouched={formik.touched.email || formik.submitCount > 0}
                      errors={formik.errors.email}
                      nextId="password"
                      isRequired={true}
                    />
                    <CustomInput
                      id="password"
                      placeHolder={i18next.t("Password")}
                      value={formik.values.password}
                      onChangeText={(value) =>
                        formik.setFieldValue("password", value)
                      }
                      isPassword={true}
                      isTouched={
                        formik.touched.password || formik.submitCount > 0
                      }
                      errors={formik.errors.password}
                      isRequired={true}
                    />

                    <Button
                      type="submit"
                      fullWidth
                      variant="contained"
                      className="btnsLoginHeight oneRemFontSize"
                      sx={{
                        color: "white",
                        backgroundColor: "#2A5182",
                        borderRadius: "24px",
                        textTransform: "none",
                        marginTop: "0.5rem",
                        fontFamily: "Helvetica, sans-serif",
                      }}
                      disabled={!formik.values.email || !formik.values.password}
                    >
                      {i18next.t("Log In")}
                    </Button>
                    <Grid container style={{ textAlign: "center" }}>
                      <Grid item xs style={{ marginTop: "16px" }}>
                        <Link
                          variant="body2"
                          onClick={handleTroubleLoggingInClick}
                        >
                          <Typography
                            component="h5"
                            variant="h5"
                            className="login-text oneRemFontSize"
                            sx={{
                              fontFamily: "Helvetica, sans-serif",
                              textAlign: "start",
                              cursor: "pointer",
                            }}
                          >
                            {i18next.t("Trouble logging in")}
                          </Typography>
                        </Link>
                      </Grid>
                    </Grid>
                  </Box>
                </Box>
                <Box component="form" noValidate sx={{ mt: 6 }}>
                  <Box className="line-container">
                    <Box className="horizontal-line"></Box>
                    <span className="or oneRemFontSize">{i18next.t("or")}</span>
                    <Box className="horizontal-line"></Box>
                  </Box>

                  <Button
                    fullWidth
                    variant="contained"
                    onClick={connectWallet}
                    className="btnsLoginHeight oneRemFontSize"
                    sx={{
                      color: "white",
                      backgroundColor: "#2A5182",
                      borderRadius: "24px",
                      textTransform: "none",
                      marginTop: "0.5rem",
                      fontFamily: "Helvetica, sans-serif",
                    }}
                  >
                    {i18next.t("Wallet Connect")}
                  </Button>
                  <Modal
                    open={isConfirmationModalOpen}
                    onClose={handleCancel}
                    aria-labelledby="terms-modal-title"
                    aria-describedby="terms-modal-description"
                  >
                    <Box
                      sx={[
                        styleModal,
                        {
                          justifyContent: "center",
                          alignContent: "center",
                          textAlign: "center",
                        },
                      ]}
                    >
                      <Typography
                        component="h5"
                        variant="h5"
                        className="login-text"
                        sx={{
                          fontSize: "1.5rem",
                          textAlign: "center",
                        }}
                      >
                        {i18next.t("Please confirm the action on Your Wallet")}
                      </Typography>

                      <LoadingIndicator />

                      <Button
                        variant="contained"
                        onClick={handleCancel}
                        className="btnsLoginHeight oneRemFontSize"
                        sx={{
                          color: "#000000",
                          backgroundColor: "transparent",
                          borderRadius: "24px",
                          boxShadow: "none",
                          border: "1px solid #000000",
                          fontWeight: 600,
                          textTransform: "none",
                          marginTop: "8px",
                          fontFamily: "Helvetica, sans-serif",
                          "&:hover": {
                            backgroundColor: "#2A5182",
                            color: "white",
                          },
                        }}
                      >
                        {i18next.t("Cancel")}
                      </Button>
                    </Box>
                  </Modal>
                  <Button
                    fullWidth
                    variant="contained"
                    className="btnsLoginHeight oneRemFontSize"
                    onClick={handleCreateAnAccountClick}
                    sx={{
                      color: "#000000",
                      backgroundColor: "transparent",
                      borderRadius: "24px",
                      boxShadow: "none",
                      border: "1px solid #000000",
                      fontWeight: 600,
                      textTransform: "none",
                      marginTop: "8px",
                      fontFamily: "Helvetica, sans-serif",
                      "&:hover": {
                        backgroundColor: "#2A5182",
                        color: "white",
                      },
                    }}
                  >
                    {i18next.t("Create an Account")}
                  </Button>
                </Box>
              </Container>
            </ThemeProvider>
          </Box>
        </Box>
      )}
    </>
  );
}
