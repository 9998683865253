import React from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Box, Typography } from "@mui/material";
import HeaderLanding from "../components/HeaderLanding/HeaderLanding";
import { useAppSelector } from "src/store";
import FooterLanding from "../components/FooterLanding/FooterLanding";
import "./LandingPagesGlobal.scss";
import TitleBackBox from "../components/TitleBackBox/TitleBackBox";
import HeadlineVvidgetSmall from "../components/HeadlineVvidget/HeadlineVvidgetSmall";
import LogoTextVvidget from "../components/LogoTextVvidget/LogoTextVvidget";
import ImageWithText from "../components/ImageWithText/ImageWithText";
import paymentsImage from "../../../assets/images/payments.jpg";
import i18next from "i18next";

const defaultTheme = createTheme();

const AboutUs: React.FC = () => {
  const { user } = useAppSelector((state) => state.auth);

  const navigateToPartner = (event: any) => {
    window.open(
      "https://www.victorum-capital.com/kunden/#beteiligung",
      "_blank"
    );
  };

  return (
    <div>
      <HeaderLanding user={user} />
      <div className="margintTopPagesMainCont">
        <ThemeProvider theme={defaultTheme}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Box className="titleHeadlineSmallBG">
              <Box className="landingPagesMainBox">
                <Box>
                  <TitleBackBox title={i18next.t("About us")} />
                </Box>
                <Box>
                  <HeadlineVvidgetSmall
                    headline={i18next.t("Always one step ahead")}
                    subtitle={
                      i18next.t("VicPay is one of the most modern and innovative companies between FinTechs, NeoBanks and other modern participants in the international financial industry")
                    }
                    smallText={i18next.t("check our partner")}
                    buttonText={i18next.t("Our partner")}
                    onClickFunction={navigateToPartner}
                  />
                </Box>
              </Box>
            </Box>
            <Box className="landingPagesMainBox">
              <Box
                sx={{
                  marginBottom: "52px",
                  "@media(max-width: 1023px)": {
                    marginBottom: "76px",
                  },
                }}
              >
                <LogoTextVvidget
                  text={
                    i18next.t("With state-of-the-art IT systems, AI and innovative security technologies, we make global transactions with fiat and cryptocurrencies, currency conversion and the hedging of foreign currency risks simpler, cheaper - and above all: significantly faster than customers were previously used to")
                  }
                />
              </Box>
              <div>
                <ImageWithText
                  backgroundImage={paymentsImage}
                  noiseImage="/img/payments-noise.png"
                  title={i18next.t("Quality Over Quantity")}
                  text={i18next.t("Our aim is not always to be the provider that is the cheapest or the fastest - or the one with the most functions on its platform")}
                />
              </div>
              <Box
                sx={{
                  marginTop: "4rem",
                  marginBottom: "4rem",
                  display: "grid",
                  gridTemplateColumns: ["1fr", "1fr 1fr"],
                  gap: [2, 20],
                }}
              >
                <Box>
                  <Typography
                    variant="h3"
                    sx={{
                      marginBottom: "1rem",
                    }}
                    className="headlineMiniLandingPages"
                  >
                    {" "}
                    {i18next.t("Quality Over Discounts")}
                  </Typography>
                  <Typography
                    variant="body1"
                    paragraph
                    className="textsLandingPage"
                  >
                    {i18next.t("We are not a discount provider and do not want to become one")}
                  </Typography>
                </Box>
                <Box>
                  <Typography
                    variant="h3"
                    sx={{
                      marginBottom: "1rem",
                    }}
                    className="headlineMiniLandingPages"
                  >
                    {" "}
                    {i18next.t("Not Just for the Premium Segment")}
                  </Typography>
                  <Typography
                    variant="body1"
                    paragraph
                    className="textsLandingPage"
                  >
                    {i18next.t("It was and is not our business model to only serve the premium segment")}
                  </Typography>
                </Box>
              </Box>
              <Box>
                <Box sx={{ marginTop: "2rem" }}>
                  <Typography
                    variant="h3"
                    sx={{
                      marginBottom: "1rem",
                    }}
                    className="headlineMiniLandingPages"
                  >
                    {" "}
                    {i18next.t("Balanced Price and Performance")}
                  </Typography>
                  <Typography
                    variant="body1"
                    paragraph
                    className="textsLandingPage"
                  >
                    {i18next.t("It was clear to us from the outset that we wanted to offer a balanced relationship between price and performance, regardless of whether you work with us as a private or business customer")}
                  </Typography>
                </Box>
                <Box sx={{ marginTop: "2rem" }}>
                  <Typography
                    variant="h3"
                    sx={{
                      marginBottom: "1rem",
                    }}
                    className="headlineMiniLandingPages"
                  >
                    {" "}
                    {i18next.t("Simple and Understandable Fees")}
                  </Typography>
                  <Typography
                    variant="body1"
                    paragraph
                    className="textsLandingPage"
                  >
                    {i18next.t("One of our unique selling points is our simple and understandable fee structure, which makes it easy for both our private and business customers to choose us")}
                  </Typography>
                </Box>
                <Box sx={{ marginTop: "2rem" }}>
                  <Typography
                    variant="h3"
                    sx={{
                      marginBottom: "1rem",
                    }}
                    className="headlineMiniLandingPages"
                  >
                    {" "}
                    {i18next.t("Seamless Integration with VicPay Services")}
                  </Typography>
                  <Typography
                    variant="body1"
                    paragraph
                    className="textsLandingPage"
                  >
                    {i18next.t("In addition, our customers benefit from the smooth integration of VicPay with other services within the Victorum Group of Companies")}
                  </Typography>
                </Box>
                <Box sx={{ marginTop: "2rem" }}>
                  <Typography
                    variant="h3"
                    sx={{
                      marginBottom: "1rem",
                    }}
                    className="headlineMiniLandingPages"
                  >
                    {" "}
                    {i18next.t("Expanding into Underserved Markets")}
                  </Typography>
                  <Typography
                    variant="body1"
                    paragraph
                    className="textsLandingPage"
                  >
                    {i18next.t("The demographic changes worldwide are our incentive to grow in markets that many of our competitors are not paying full attention to today")}
                  </Typography>
                </Box>
                <Box sx={{ marginTop: "2rem" }}>
                  <Typography
                    variant="h3"
                    sx={{
                      marginBottom: "1rem",
                    }}
                    className="headlineMiniLandingPages"
                  >
                    {" "}
                    {i18next.t("Future Growth in Central and South America and Africa")}
                  </Typography>
                  <Typography
                    variant="body1"
                    paragraph
                    className="textsLandingPage"
                  >
                    {i18next.t("We are convinced that Central and South America and Africa will see the highest growth in the coming years - which is why VicPay is planning to successively open a total of six more locations in North, Central and South America by 2028")}
                  </Typography>
                </Box>
                <Box sx={{ marginTop: "2rem" }}>
                  <Typography
                    variant="h3"
                    sx={{
                      marginBottom: "1rem",
                    }}
                    className="headlineMiniLandingPages"
                  >
                    {" "}
                    {i18next.t("VicPay's Comprehensive Financial Ecosystem and Upcoming Innovations")}
                  </Typography>
                  <Typography
                    variant="body1"
                    paragraph
                    className="textsLandingPage"
                  >
                    {i18next.t("We have created an entire financial ecosystem for our international customers in which the possibilities could hardly be more extensive - and you can be sure of that")}
                  </Typography>
                  <Typography
                    variant="body1"
                    paragraph
                    className="textsLandingPage"
                  >
                    {i18next.t("The next innovation at VicPay will surprise you soon")}
                  </Typography>
                  <Typography
                    variant="body1"
                    paragraph
                    className="textsLandingPage"
                  >
                    {i18next.t("Curious to find out which innovation it is and how you can benefit from it? If you can't wait, we understand - just contact +1 604-260-0738 and we'll help")}
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>
        </ThemeProvider>
      </div>
      <Box sx={{ marginTop: "4rem" }}>
        <FooterLanding />
      </Box>
    </div>
  );
};

export default AboutUs;
