import { useEffect, useState } from "react";
import "./Banking.scss";
import api from "src/store/interceptors/api";
import {
  Box,
  Container,
  createTheme,
  ThemeProvider,
  Typography,
} from "@mui/material";
import Nav from "src/components/layout/nav/Nav";
import LoadingIndicator from "src/components/shared/LoadingIndicator";
import BankingNav from "./components/BankingNav";
import AccountConfiguration from "./components/AccountConfiguration";
import DocumentsStep from "./components/DocumentsStep";
import ReviewStep from "./components/ReviewStep";
import { useFormik } from "formik";
import i18next from "i18next";
import * as Yup from "yup";
import {
  acceptableProofOfId,
  collectedFor,
  companyFiles,
  personalVerification,
  proofOfAddress,
} from "src/utils/helpers";
import VerificationStep from "./components/VerificationStep";
import BankDetailsStep from "./components/BankDetailsStep";
import BalanceDisplay from "./components/BalanceDisplay";

const checkRequiredDocuments = (user: any, currentValues: any) => {
  const requiredDocuments = [
    ...personalVerification,
    ...acceptableProofOfId,
    ...collectedFor,
    ...companyFiles,
  ];

  const currentDocs = currentValues.documents || {};

  let missingDocuments = [];

  const hasPersonalId =
    (user.id_card_front_id || currentDocs.id_card_front_file) !== null &&
    (user.id_card_front_id || currentDocs.id_card_front_file) !== undefined &&
    (user.id_card_back_id || currentDocs.id_card_back_file) !== null &&
    (user.id_card_back_id || currentDocs.id_card_back_file) !== undefined;
  const hasPassport = (user.passport_id || currentDocs.passport_file) !== null;

  if (!hasPersonalId && !hasPassport) {
    missingDocuments.push(
      "Either a passport or a personal ID (front and back)"
    );
  }

  for (const doc of requiredDocuments) {
    if (
      doc.required &&
      !doc.file.includes("passport_file") &&
      !doc.file.includes("id_card_front_file") &&
      !doc.file.includes("id_card_back_file")
    ) {
      if (!user[doc.file] && !currentDocs[doc.file]) {
        missingDocuments.push(doc.title);
      }
    }
  }

  const hasProofOfAddress = proofOfAddress.some(
    (doc) => user[doc.file] || currentDocs[doc.file]
  );

  if (!hasProofOfAddress) {
    missingDocuments.push("At least one proof of address document");
  }

  if (missingDocuments.length > 0) {
    alert(
      `Please upload the following required documents:\n- ${missingDocuments.join(
        "\n- "
      )}`
    );
    return false;
  }

  return true;
};

const defaultTheme = createTheme();
function Banking() {
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState<any>(null);
  const [termsProvider, setTermsProvider] = useState<boolean>(false);
  const [activeStep, setActiveStep] = useState(0);

  const [initialFormikValues, setInitialFormikValues] = useState({
    iban_needs: "",
    pay_in_currency: [],
    volume_of_money_in: "",
    pay_out_currency: [],
    volume_of_money_out: "",
    verification_country_code: "",
    bussiness_activities: "",
    company_in_bussiness: "",
    holding_activities_1: "",
    holding_activities_2: "",
    trading_activities_1: "",
    trading_activities_2: "",
    prospect_aspect_1: "",
    prospect_aspect_2: "",
    it_marketing_1: "",
    it_marketing_2: "",
    it_marketing_3: "",
    intermediary_service_1: "",
    intermediary_service_2: "",
    consulting_1: "",
    consulting_2: "",
    investment_1: "",
    investment_2: "",
    investment_3: "",
    investment_4: "",
  });

  const getMe = async () => {
    setLoading(true);
    try {
      await api
        .get("/me")
        .then((response: any) => {
          if (response) {
            if (response && response.data && response.data.data) {
              const userData = response.data.data;
              setUser(userData);

              let pay_in_currency: any = [];
              let pay_out_currency: any = [];
              if (typeof userData.pay_in_currency === "string") {
                pay_in_currency = userData.pay_in_currency
                  .split(",")
                  .map((item: any) => item.trim());
              }
              if (typeof userData.pay_out_currency === "string") {
                pay_out_currency = userData.pay_out_currency
                  .split(",")
                  .map((item: any) => item.trim());
              }
              // Update initial form values based on the fetched user data
              setInitialFormikValues((prevValues) => ({
                ...prevValues,
                iban_needs: userData.iban_needs || "",
                pay_in_currency,
                volume_of_money_in: userData.volume_of_money_in || "",
                pay_out_currency,
                volume_of_money_out: userData.volume_of_money_out || "",
                verification_country_code:
                  userData.verification_country_code || "",
                bussiness_activities: userData.bussiness_activities || "",
                company_in_bussiness: userData.company_in_bussiness || "",
                holding_activities_1: userData.holding_activities_1 || "",
                holding_activities_2: userData.holding_activities_2 || "",
                trading_activities_1: userData.trading_activities_1 || "",
                trading_activities_2: userData.trading_activities_2 || "",
                prospect_aspect_1: userData.prospect_aspect_1 || "",
                prospect_aspect_2: userData.prospect_aspect_2 || "",
                it_marketing_1: userData.it_marketing_1 || "",
                it_marketing_2: userData.it_marketing_2 || "",
                it_marketing_3: userData.it_marketing_3 || "",
                intermediary_service_1: userData.intermediary_service_1 || "",
                intermediary_service_2: userData.intermediary_service_2 || "",
                consulting_1: userData.consulting_1 || "",
                consulting_2: userData.consulting_2 || "",
                investment_1: userData.investment_1 || "",
                investment_2: userData.investment_2 || "",
                investment_3: userData.investment_3 || "",
                investment_4: userData.investment_4 || "",
              }));

              if (userData.verification_provider_terms) {
                setTermsProvider(true);
              }

              if (userData.verification_requested) {
                setActiveStep(2);
              }

              if (userData.verification_step) {
                setActiveStep(parseInt(userData.verification_step) - 1);
              }
            }
          }
        })
        .catch((error) => {});
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  useEffect(() => {
    getMe();
  }, []);

  const formik = useFormik({
    initialValues: initialFormikValues,
    enableReinitialize: true,
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: async (values: any) => {
      if (activeStep === 0) {
        setActiveStep(activeStep + 1);
      } else if (activeStep === 1) {
        if (!user) {
          alert("Please try again or restart your session!");
          return;
        }
        if (!checkRequiredDocuments(user, values)) {
          return;
        }
        const { documents, ...excludedData } = values;

        if (excludedData.pay_in_currency) {
          excludedData.pay_in_currency =
            excludedData.pay_in_currency.join(", ");
        }

        if (excludedData.pay_out_currency) {
          excludedData.pay_out_currency =
            excludedData.pay_out_currency.join(", ");
        }

        const finalData: any = Object.fromEntries(
          Object.entries(excludedData).filter(
            ([key, value]) => value !== null && value !== undefined
          )
        );

        if (user && user.id) {
          finalData.id = user.id;

          const formData = new FormData();

          for (const finalDataKey in finalData) {
            if (finalData.hasOwnProperty(finalDataKey)) {
              //console.log('finaldata', finalData, 'finalDataKey',finalDataKey);
              formData.append(finalDataKey, finalData[finalDataKey]);
            }
          }
          if (values && values.documents) {
            for (const keyFile in values.documents) {
              if (values.documents.hasOwnProperty(keyFile)) {
                // console.log(keyFile);
                // console.log(values.documents, "docs");
                if (values.documents[keyFile] && values.documents[keyFile].file)
                  formData.append(keyFile, values.documents[keyFile].file);
              }
            }
          }

          //  console.log(values, 'values');
          //console.log(finalData, 'finalData');
          // ;;return;
          formData.append("verification_type", "onboarding");
          formData.append("_method", "PATCH");

          try {
            const response = await api.post(`/users/${user.id}`, formData, {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            });
            // console.log(response);

            if (!response) {
              alert("Please check the form!");
              return;
            } else {
              setActiveStep(2);
            }
          } catch (error) {
            console.error(`Error uploading: `, error);
          }
        } else {
          alert("Please try again!");
          return;
        }
        // console.log(finalData);
      }
    },
    validationSchema: Yup.object(
      activeStep === 0
        ? {
            iban_needs: Yup.string().required(
              i18next.t("This field is required")
            ),
            pay_in_currency: Yup.array()
              .required(i18next.t("This field is required"))
              .min(1, i18next.t("This field is required")),

            volume_of_money_in: Yup.string().required(
              i18next.t("This field is required")
            ),

            pay_out_currency: Yup.array()
              .required(i18next.t("This field is required"))
              .min(1, i18next.t("This field is required")),

            volume_of_money_out: Yup.string().required(
              i18next.t("This field is required")
            ),
          }
        : {}
    ),
  });

  return (
    <Box>
      {user && user.account_type === "business" ? (
        activeStep < 5 ? (
          <BankingNav activeStep={activeStep} />
        ) : (
          <Nav step={0} isStepperVisible={false} isProfileSetVisible={true} />
        )
      ) : (
        <Nav step={0} isStepperVisible={false} isProfileSetVisible={true} />
      )}
      <ThemeProvider theme={defaultTheme}>
        <Container component="main">
          {loading ? (
            <Box className="settingsMain">
              <LoadingIndicator />
            </Box>
          ) : user && user.account_type === "business" && activeStep < 5 ? (
            <Box className="animated-text moveInRight">
              {activeStep === 0 && (
                <AccountConfiguration
                  formik={formik}
                  setActiveStep={setActiveStep}
                />
              )}
              {activeStep === 1 && (
                <DocumentsStep
                  formik={formik}
                  user={user}
                  setActiveStep={setActiveStep}
                />
              )}
              {activeStep === 2 && <ReviewStep />}
              {activeStep === 3 && <VerificationStep />}
              {activeStep === 4 && (
                <BankDetailsStep termsProvider={termsProvider} />
              )}
            </Box>
          ) : (
            <Box className="animated-text moveInRight">
              {user ? (
                <BalanceDisplay user={user} />
              ) : (
                <Box style={{ marginTop: 20 }}>
                  <Typography
                    component="h5"
                    variant="h5"
                    className="welcome-text d-flex jc-start FontSize12"
                    sx={{
                      fontFamily: "Helvetica, sans-serif",
                      textAlign: "left",
                      color: "#000",
                    }}
                  >
                    {i18next.t("No balance")}
                  </Typography>
                </Box>
              )}
            </Box>
          )}
        </Container>
      </ThemeProvider>
    </Box>
  );
}

export default Banking;
