import { Avatar, Box, Typography } from "@mui/material";
import { ReactNode, useState } from "react";
import "./AccountBox.scss";
import CAD from "../../../assets/images/cadIcon.svg";
import USD from "../../../assets/images/usdIcon.svg";
import AUD from "../../../assets/images/audIcon.svg";
import GBP from "../../../assets/images/gbpIcon.svg";
import NGN from "../../../assets/images/ngnIcon.svg";
import EUR from "../../../assets/images/eurIcon.svg";
import TRY from "../../../assets/images/tryIcon.svg";
import RUB from "../../../assets/images/rubIcon.svg";
import INR from "../../../assets/images/inrIcon.svg";
import CNY from "../../../assets/images/cnyIcon.svg";
import transactionsService from "src/store/transactions/transactions_service";
import { NavigateFunction } from "react-router-dom";
import AddIcon from "@mui/icons-material/Add";
import MoveUpIcon from "@mui/icons-material/MoveUp";
import RemoveCircleOutlineOutlinedIcon from "@mui/icons-material/RemoveCircleOutlineOutlined";
import AreYouSureModal from "../AreYouSureModal/AreYouSureModal";
import { toast } from "react-toastify";
import api from "src/store/interceptors/api";
import InfoIcon from "@mui/icons-material/Info";
import i18next from "src/i18n";

interface AccountBoxProps {
  img?: ReactNode | null | undefined;
  currencyIcon?: string | null | undefined;
  title: string;
  content: string;
  pendingBalance?: string | null | undefined;
  onClick?: any;
  isSelectedProp?: boolean;
  bgColorSpecific?: string;
  textColor?: string;
  isSmaller?: boolean;
  balanceItem?: any;
  screen?: string;
  user?: any;
  navigate?: NavigateFunction;
}

const getCurrencyFlag = (currencyCode: any) => {
  if (currencyCode === "TRY") {
    return <img style={{ width: "100%" }} src={TRY} alt="TRY Flag" />;
  } else if (currencyCode === "EUR") {
    return <img style={{ width: "100%" }} src={EUR} alt="EUR Flag" />;
  } else if (currencyCode === "USD") {
    return <img style={{ width: "100%" }} src={USD} alt="USD Flag" />;
  } else if (currencyCode === "NGN") {
    return <img style={{ width: "100%" }} src={NGN} alt="NGN Flag" />;
  } else if (currencyCode === "AUD") {
    return <img style={{ width: "100%" }} src={AUD} alt="AUD Flag" />;
  } else if (currencyCode === "GBP") {
    return <img style={{ width: "100%" }} src={GBP} alt="GBP Flag" />;
  } else if (currencyCode === "CAD") {
    return <img style={{ width: "100%" }} src={CAD} alt="CAD Flag" />;
  } else if (currencyCode === "RUB") {
    return (
      <img src={RUB} alt="RUB Flag" style={{ width: "3rem", height: "3rem" }} />
    );
  } else if (currencyCode === "INR") {
    return (
      <img
        src={INR}
        alt="INR Flag"
        style={{ width: "3.3rem", height: "3.3rem" }}
      />
    );
  } else if (currencyCode === "CNY") {
    return (
      <img src={CNY} alt="CNY Flag" style={{ width: "3rem", height: "3rem" }} />
    );
  } else {
    return "";
  }
};
const AccountBox: React.FC<AccountBoxProps> = ({
  img,
  balanceItem,
  screen,
  title,
  content,
  currencyIcon,
  pendingBalance,
  onClick,
  isSelectedProp,
  bgColorSpecific,
  textColor,
  isSmaller,
  user,
  navigate,
}) => {
  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
  const handleDeleteRecipientModal = () => {
    setDeleteModalOpen(true);
  };
  const handleDeleteRecipient = async () => {
    try {
      await api.delete(`/user-balances/${balanceItem.id}`);
      setDeleteModalOpen(false);
      toast.success(i18next.t("Currency is deleted"));
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    } catch (error: any) {
      toast.error(i18next.t("Error deleting currency, contact support!"));
    }
  };

  const [isClicked, setIsClicked] = useState(false);

  let pendingValue =
    pendingBalance && parseFloat(pendingBalance) > 0
      ? transactionsService.formatNumberDecimal(pendingBalance)
      : null;

  let blockedValue =
    balanceItem && balanceItem.blocked && parseFloat(balanceItem.blocked) > 0
      ? transactionsService.formatNumberDecimal(balanceItem.blocked)
      : null;

  const handleOnClick = () => {
    if (screen && screen === "home") {
      setIsClicked(!isClicked);
    }
    if (onClick) onClick();
  };
  return (
    <Box
      onClick={handleOnClick}
      sx={{
        display: "flex",
        position: "relative",
        flexDirection: "column",
        justifyContent: "space-between",
        backgroundColor: isSelectedProp
          ? "#BDC3C7"
          : bgColorSpecific || "#16330014",

        width: "100%",
        height: "100%",
        borderRadius: 4,
        p: 2,
        transition: "transform 0.3s ease-in-out",
        "&:hover": {
          transform: "rotateX(10deg) rotateY(20deg)",
        },
      }}
      className={isSmaller ? "accountBoxMainSmall" : "accountBoxMain"}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
        }}
      >
        <Avatar
          sx={{
            marginRight: "8px",
            width: "3rem",
            height: "3rem",
            objectFit: "cover",
            backgroundColor: textColor || "#bdbdbd",
          }}
        >
          {currencyIcon ? getCurrencyFlag(currencyIcon) : img}{" "}
        </Avatar>
        <Typography
          className="registration1375RemFontSize"
          sx={{
            fontFamily: "Inter, sans-serif",
            fontWeight: 600,
            color: textColor || "#0e0f0c",
          }}
          variant="subtitle1"
        >
          {title}
        </Typography>
      </Box>
      {currencyIcon && (
        <Typography
          sx={{
            fontFamily: "Inter, sans-serif",
            fontSize: {
              xs: "17px",
              sm: "1.175rem", // Applied at widths of 600px and up
            },
            fontWeight: 400,
            color: "#0e0f0c",
            alignSelf: "flex-start",
            maxWidth: "224px",
            textOverflow: "ellipsis",
            overflow: "hidden",
            whiteSpace: "nowrap",
          }}
          variant="h6"
        >
          {i18next.t("Balance")}
        </Typography>
      )}
      <Typography
        sx={{
          fontFamily: "Inter, sans-serif",
          fontSize: {
            xs: "17px", // Applied at widths of 0px and up (up to 600px)
            sm: "1.375rem", // Applied at widths of 600px and up
          },
          fontWeight: 600,
          color: textColor || "#0e0f0c",
          alignSelf: "flex-start",
          maxWidth: "210px",
          textOverflow: "ellipsis",
          overflow: "hidden",
          whiteSpace: "nowrap",
        }}
        variant="h6"
      >
        {content}
      </Typography>

      {pendingValue && (
        <Typography
          sx={{
            fontFamily: "Inter, sans-serif",
            color: textColor || "#0e0f0c",
            fontSize: {
              xs: "11px", // Applied at widths of 0px and up (up to 600px)
              sm: "0.8rem", // Applied at widths of 600px and up
            },
          }}
          variant="caption"
        >
          {i18next.t("Pending")}: {pendingValue}
        </Typography>
      )}
      {blockedValue && (
        <Typography
          sx={{
            fontFamily: "Inter, sans-serif",
            color: textColor || "#0e0f0c",
            fontSize: {
              xs: "0.95rem", // Applied at widths of 0px and up (up to 600px)
              sm: "0.8rem", // Applied at widths of 600px and up
            },
          }}
          variant="caption"
        >
          {i18next.t("Blocked")}: {blockedValue}
        </Typography>
      )}
      {isClicked && (
        <Box className="addTransBox">
          <Box
            className="addTransferBtns"
            onClick={() => {
              if (navigate) {
                navigate("/account-details/" + balanceItem.id, {
                  state: {
                    balance: balanceItem,
                    currencyIcon,
                    initialSelectedCurrency: currencyIcon,
                  },
                });
              }
            }}
          >
            <InfoIcon style={{ verticalAlign: "middle" }} />{" "}
            {i18next.t("Details")}
          </Box>
          <Box
            className="addTransferBtns"
            onClick={() => {
              if (navigate) {
                navigate("/add-balance", {
                  state: {
                    currencyIcon,
                    initialSelectedCurrency: currencyIcon,
                  },
                });
              }
            }}
          >
            <AddIcon style={{ verticalAlign: "middle" }} />{" "}
            {i18next.t("Add Balance")}
          </Box>
          {user && user.account_type === "personal" && (
            <Box
              className="addTransferBtns"
              onClick={() => {
                if (navigate) {
                  navigate("/account-transfer", {
                    state: {
                      currencyIcon,
                      initialSelectedCurrency: currencyIcon,
                    },
                  });
                }
              }}
            >
              <MoveUpIcon style={{ verticalAlign: "middle" }} />{" "}
              {i18next.t("Account transfer")}
            </Box>
          )}
          {balanceItem &&
            balanceItem.balance &&
            parseFloat(balanceItem.balance) === 0 &&
            balanceItem.pending === 0 && (
              <Box
                className="removeTransferBtns"
                onClick={handleDeleteRecipientModal}
              >
                <RemoveCircleOutlineOutlinedIcon
                  style={{ verticalAlign: "middle" }}
                />{" "}
                {i18next.t("Remove currency")}
              </Box>
            )}
        </Box>
      )}
      <AreYouSureModal
        open={isDeleteModalOpen}
        handleClose={() => setDeleteModalOpen(false)}
        handleYes={handleDeleteRecipient}
        title={i18next.t("Are you sure you want to delete this currency?")}
      />
    </Box>
  );
};

export default AccountBox;
