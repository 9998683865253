import {
  Box,
  Button,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import i18next from "i18next";
import React, { useState, useEffect } from "react";
import {
  acceptableProofOfId,
  collectedFor,
  companyFiles,
  personalVerification,
  proofOfAddress,
} from "src/utils/helpers";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { VisuallyHiddenInput } from "src/components/shared/VisuallyHiddenInput";
import FilePreview from "src/components/shared/FilePreview";
import CountrySelect from "src/components/shared/CountrySelect/CountrySelect";
import ConfirmationOfSourceOfFunds from "./ConfirmationOfSourceOfFunds";

interface Props {
  user: any;
  setActiveStep: (value: number) => void;
  formik: any;
}
function DocumentsStep({ user, setActiveStep, formik }: Props) {
  const userProfileObject: any = { ...user };
  const [selectedFiles, setSelectedFiles] = useState<any>({
    passport_file: null,
    eu_identification_card: null,
    proof_of_address: null,
    company_registry_extract: null,
    shareholder_register: null,
    director_register: null,
    company_resolution: null,
    confirmation_client: null,
    anual_report: null,
    wealth_confirmation: null,
    ubo_file: null,
    authorised_signatories_file: null,
  });

  const [address, setAddress] = useState<any>(null);

  const handleFileUpload = async (
    event: React.ChangeEvent<HTMLInputElement>,
    kycItem: any
  ) => {
    if (event.target.files && event.target.files.length > 0) {
      const file = event.target.files[0];
      setSelectedFiles((prev: any) => ({
        ...prev,
        [kycItem.file]: { file: file, preview: URL.createObjectURL(file) },
      }));
    }
  };

  const handleCountrySelect = (value: any) => {
    formik.setFieldValue("verification_country_code", value);
  };

  useEffect(() => {
    formik.setFieldValue("documents", selectedFiles);
  }, [selectedFiles]);

  return (
    <Box className="settingsMain animated-text moveInRight">
      <Typography
        className="pageTitleFontSize"
        component="h1"
        variant="h5"
        sx={{
          fontFamily: "Helvetica, sans-serif",
          fontWeight: "600",
          color: "#0e0f0c",
          maxWidth: "500px",
          whiteSpace: "nowrap",
          textOverflow: "ellipsis",
          overflow: "hidden",
        }}
      >
        {i18next.t("Business description")}
      </Typography>

      <Box>
        <Box className="mb-big">
          <div style={{ paddingTop: 5, paddingBottom: 5 }}>
            <Typography className="labelsLocalIban">
              {i18next.t(
                "A detailed description of business activities including funds and goods flow overview"
              )}
            </Typography>
            <TextField
              minRows={3}
              style={{ width: "100%" }}
              multiline={true}
              value={formik.values.bussiness_activities}
              name="bussiness_activities"
              onChange={formik.handleChange}
              variant="outlined"
              sx={{
                "& .MuiOutlinedInput-root": {
                  paddingRight: "0px!important",
                },
              }}
              InputProps={{
                sx: {
                  borderRadius: "10px",
                  "@media (max-width: 600px)": {
                    fontSize: "14px",
                  },
                },
              }}
            />
          </div>

          <div style={{ paddingTop: 5, paddingBottom: 5 }}>
            <Typography className="labelsLocalIban">
              {i18next.t(
                "How long has the company been in business, and where is the business based and managed from?"
              )}
            </Typography>
            <TextField
              minRows={3}
              style={{ width: "100%" }}
              multiline={true}
              value={formik.values.company_in_bussiness}
              name="company_in_bussiness"
              onChange={formik.handleChange}
              variant="outlined"
              sx={{
                "& .MuiOutlinedInput-root": {
                  paddingRight: "0px!important",
                },
              }}
              InputProps={{
                sx: {
                  borderRadius: "10px",
                  "@media (max-width: 600px)": {
                    fontSize: "14px",
                  },
                },
              }}
            />
          </div>

          <div style={{ paddingTop: 5, paddingBottom: 5 }}>
            <Typography className="labelsLocalIban">
              {i18next.t(
                "In what countries is the company currently operating and planning to operate in future?"
              )}
            </Typography>

            <div
              className="country-code-container-personal mt-16"
              style={{ width: "100%" }}
            >
              <CountrySelect
                value={formik.values.verification_country_code}
                isTouched={formik.touched.verification_country_code}
                errors={formik.errors.verification_country_code}
                onSelect={handleCountrySelect}
                code={true}
              />
            </div>
          </div>
        </Box>

        <Box className="section">
          <Typography
            className="pageTitleFontSize"
            component="h1"
            variant="h5"
            sx={{
              fontFamily: "Helvetica, sans-serif",
              fontWeight: "600",
              color: "#0e0f0c",
              maxWidth: "500px",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
              overflow: "hidden",
              mb: "10px",
            }}
          >
            {i18next.t("Personal")}
          </Typography>

          <Box className="mb-big">
            <Typography
              sx={{
                fontFamily: "Helvetica, sans-serif",
                textAlign: "start",
              }}
            >
              {i18next.t(
                "Personal photo is required, passport or id card must be uploaded"
              )}
            </Typography>

            <div className="personal-box">
              {personalVerification.map((kycPersonalItem, kycPersonalIndex) => (
                <div
                  key={"kyc" + kycPersonalIndex}
                  className="one-flex-line"
                  style={{ paddingTop: 10, paddingBottom: 10 }}
                >
                  <div className="verification-box-full">
                    <Typography
                      className="mb-1rem  d-flex jc-start registration0875RemFontSize"
                      component="h1"
                      variant="h5"
                      sx={{
                        fontFamily: "Helvetica, sans-serif",
                        textDecoration: "underline",
                        cursor: "pointer",
                      }}
                    >
                      {kycPersonalItem.title}{" "}
                      {kycPersonalItem.required ? "*" : ""}
                    </Typography>

                    <Button
                      className="btnsLoginHeight oneRemFontSize"
                      sx={{
                        marginBottom: 2,
                        textTransform: "none",
                        "&:hover": {
                          backgroundColor: "#2A5182",
                          color: "white",
                        },
                      }}
                      component="label"
                      role={undefined}
                      variant="contained"
                      tabIndex={-1}
                      startIcon={<CloudUploadIcon className="muiIcons" />}
                    >
                      {userProfileObject[kycPersonalItem.file] &&
                      userProfileObject[kycPersonalItem.file].file_url
                        ? "Change file"
                        : "Upload file"}
                      <VisuallyHiddenInput
                        onChange={(e) => handleFileUpload(e, kycPersonalItem)}
                        type="file"
                      />
                    </Button>

                    {selectedFiles &&
                    selectedFiles[kycPersonalItem.file] &&
                    selectedFiles[kycPersonalItem.file].preview ? (
                      <FilePreview
                        src={selectedFiles[kycPersonalItem.file].preview}
                        width="90%"
                        file={selectedFiles[kycPersonalItem.file].file}
                        alt={kycPersonalItem.title}
                      />
                    ) : userProfileObject[kycPersonalItem.file]?.file_url ? (
                      <FilePreview
                        src={
                          selectedFiles[kycPersonalItem.file]?.preview ??
                          userProfileObject[kycPersonalItem.file].file_url
                        }
                        width="90%"
                        file={
                          selectedFiles[kycPersonalItem.file]?.file ??
                          userProfileObject[kycPersonalItem.file]
                        }
                        alt={kycPersonalItem.title}
                      />
                    ) : (
                      <Typography
                        className="mb-1rem  d-flex jc-start registration0875RemFontSize"
                        component="h1"
                        variant="h5"
                        sx={{
                          fontFamily: "Helvetica, sans-serif",
                          fontWeight: 400, // Adjusted this to a numerical value
                          color: "#454745",
                        }}
                      >
                        {"No " + kycPersonalItem.title}
                      </Typography>
                    )}
                  </div>
                </div>
              ))}
            </div>
          </Box>

          <Typography
            className="pageTitleFontSize"
            component="h1"
            variant="h5"
            sx={{
              fontFamily: "Helvetica, sans-serif",
              fontWeight: "600",
              color: "#0e0f0c",
              maxWidth: "500px",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
              overflow: "hidden",
              mb: "10px",
            }}
          >
            {i18next.t("KYC Documents")}
          </Typography>

          <Box className="mb-big">
            <Typography
              sx={{
                fontFamily: "Helvetica, sans-serif",
                textAlign: "start",
              }}
            >
              {i18next.t("Acceptable proof of ID:")}
            </Typography>

            <div className="personal-box">
              {acceptableProofOfId.map((kycPersonalItem, kycPersonalIndex) => (
                <div
                  key={"kyc" + kycPersonalIndex}
                  className="one-flex-line"
                  style={{ paddingTop: 10, paddingBottom: 10 }}
                >
                  <div className="verification-box-full">
                    <Typography
                      className="mb-1rem  d-flex jc-start registration0875RemFontSize"
                      component="h1"
                      variant="h5"
                      sx={{
                        fontFamily: "Helvetica, sans-serif",
                        textDecoration: "underline",
                        cursor: "pointer",
                      }}
                    >
                      {kycPersonalItem.title}{" "}
                      {kycPersonalItem.required ? "*" : ""}
                    </Typography>

                    <Button
                      className="btnsLoginHeight oneRemFontSize"
                      sx={{
                        marginBottom: 2,
                        textTransform: "none",
                        "&:hover": {
                          backgroundColor: "#2A5182",
                          color: "white",
                        },
                      }}
                      component="label"
                      role={undefined}
                      variant="contained"
                      tabIndex={-1}
                      startIcon={<CloudUploadIcon className="muiIcons" />}
                    >
                      {userProfileObject[kycPersonalItem.file] &&
                      userProfileObject[kycPersonalItem.file].file_url
                        ? "Change file"
                        : "Upload file"}
                      <VisuallyHiddenInput
                        onChange={(e) => handleFileUpload(e, kycPersonalItem)}
                        type="file"
                      />
                    </Button>

                    {selectedFiles &&
                    selectedFiles[kycPersonalItem.file] &&
                    selectedFiles[kycPersonalItem.file].preview ? (
                      <FilePreview
                        src={selectedFiles[kycPersonalItem.file].preview}
                        width="90%"
                        file={selectedFiles[kycPersonalItem.file].file}
                        alt={kycPersonalItem.title}
                      />
                    ) : userProfileObject[kycPersonalItem.file]?.file_url ? (
                      <FilePreview
                        src={
                          selectedFiles[kycPersonalItem.file]?.preview ??
                          userProfileObject[kycPersonalItem.file].file_url
                        }
                        width="90%"
                        file={
                          selectedFiles[kycPersonalItem.file]?.file ??
                          userProfileObject[kycPersonalItem.file]
                        }
                        alt={kycPersonalItem.title}
                      />
                    ) : (
                      <Typography
                        className="mb-1rem  d-flex jc-start registration0875RemFontSize"
                        component="h1"
                        variant="h5"
                        sx={{
                          fontFamily: "Helvetica, sans-serif",
                          fontWeight: 400, // Adjusted this to a numerical value
                          color: "#454745",
                        }}
                      >
                        {"No " + kycPersonalItem.title}
                      </Typography>
                    )}
                  </div>
                </div>
              ))}
            </div>
          </Box>

          <Box className="mb-big">
            <Typography
              sx={{
                fontFamily: "Helvetica, sans-serif",
                textAlign: "start",
              }}
            >
              {i18next.t("Collected for:")}
            </Typography>

            <div className="personal-box">
              {collectedFor.map((kycPersonalItem, kycPersonalIndex) => (
                <div
                  key={"kyc" + kycPersonalIndex}
                  className="one-flex-line"
                  style={{ paddingTop: 10, paddingBottom: 10 }}
                >
                  <div className="verification-box-full">
                    <Typography
                      className="mb-1rem  d-flex jc-start registration0875RemFontSize"
                      component="h1"
                      variant="h5"
                      sx={{
                        fontFamily: "Helvetica, sans-serif",
                        textDecoration: "underline",
                        cursor: "pointer",
                      }}
                    >
                      {kycPersonalItem.title}{" "}
                      {kycPersonalItem.required ? "*" : ""}
                    </Typography>

                    <Button
                      className="btnsLoginHeight oneRemFontSize"
                      sx={{
                        marginBottom: 2,
                        textTransform: "none",
                        "&:hover": {
                          backgroundColor: "#2A5182",
                          color: "white",
                        },
                      }}
                      component="label"
                      role={undefined}
                      variant="contained"
                      tabIndex={-1}
                      startIcon={<CloudUploadIcon className="muiIcons" />}
                    >
                      {userProfileObject[kycPersonalItem.file] &&
                      userProfileObject[kycPersonalItem.file].file_url
                        ? "Change file"
                        : "Upload file"}
                      <VisuallyHiddenInput
                        onChange={(e) => {
                          handleFileUpload(e, kycPersonalItem);
                          handleFileUpload(e, {
                            file: "proof_of_address",
                            id: "proof_of_address_id",
                          });
                        }}
                        type="file"
                      />
                    </Button>

                    {selectedFiles &&
                    selectedFiles[kycPersonalItem.file] &&
                    selectedFiles[kycPersonalItem.file].preview ? (
                      <FilePreview
                        src={selectedFiles[kycPersonalItem.file].preview}
                        width="90%"
                        file={selectedFiles[kycPersonalItem.file].file}
                        alt={kycPersonalItem.title}
                      />
                    ) : userProfileObject[kycPersonalItem.file]?.file_url ? (
                      <FilePreview
                        src={
                          selectedFiles[kycPersonalItem.file]?.preview ??
                          userProfileObject[kycPersonalItem.file].file_url
                        }
                        width="90%"
                        file={
                          selectedFiles[kycPersonalItem.file]?.file ??
                          userProfileObject[kycPersonalItem.file]
                        }
                        alt={kycPersonalItem.title}
                      />
                    ) : (
                      <Typography
                        className="mb-1rem  d-flex jc-start registration0875RemFontSize"
                        component="h1"
                        variant="h5"
                        sx={{
                          fontFamily: "Helvetica, sans-serif",
                          fontWeight: 400, // Adjusted this to a numerical value
                          color: "#454745",
                        }}
                      >
                        {"No " + kycPersonalItem.title}
                      </Typography>
                    )}
                  </div>
                </div>
              ))}
            </div>
          </Box>

          <Box className="mb-big">
            <Typography
              sx={{
                fontFamily: "Helvetica, sans-serif",
                textAlign: "start",
              }}
            >
              {i18next.t("Proof of address:")}
            </Typography>

            <div style={{ paddingTop: 5, paddingBottom: 5 }}>
              <Select
                labelId="currency"
                id="currency"
                name="currency"
                style={{ width: "100%" }}
                sx={{
                  borderRadius: "10px",
                  height: "48px",
                  marginBottom: "20px",
                  "& .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input":
                    {
                      padding: "16.5px 14px 16.5px 14px",
                    },
                  "@media (max-width: 600px)": {
                    height: "42px",
                    fontSize: "14px",
                  },
                }}
              >
                {proofOfAddress.map((menuItem: any, menuIndex: any) => (
                  <MenuItem
                    onClick={() => {
                      setAddress(menuItem);
                    }}
                    key={"currenct" + menuIndex}
                    value={menuItem}
                  >
                    {menuItem.title}
                  </MenuItem>
                ))}
              </Select>

              {address && (
                <div className="personal-box">
                  <div
                    className="one-flex-line"
                    style={{ paddingTop: 10, paddingBottom: 10 }}
                  >
                    <div className="verification-box-full">
                      <Typography
                        className="mb-1rem  d-flex jc-start registration0875RemFontSize"
                        component="h1"
                        variant="h5"
                        sx={{
                          fontFamily: "Helvetica, sans-serif",
                          textDecoration: "underline",
                          cursor: "pointer",
                        }}
                      >
                        {address.title}*
                      </Typography>
                      <Button
                        className="btnsLoginHeight oneRemFontSize"
                        sx={{
                          marginBottom: 2,
                          textTransform: "none",
                          "&:hover": {
                            backgroundColor: "#2A5182",
                            color: "white",
                          },
                        }}
                        component="label"
                        role={undefined}
                        variant="contained"
                        tabIndex={-1}
                        startIcon={<CloudUploadIcon className="muiIcons" />}
                      >
                        {userProfileObject[address.file]?.file_url &&
                        userProfileObject[address.file].file_url
                          ? "Change file"
                          : "Upload file"}
                        <VisuallyHiddenInput
                          onChange={(e) =>
                            handleFileUpload(e, {
                              file: address.file,
                              id: address.id,
                            })
                          }
                          type="file"
                        />
                      </Button>
                      {selectedFiles &&
                      selectedFiles[address.file] &&
                      selectedFiles[address.file].preview ? (
                        <FilePreview
                          src={selectedFiles[address.file].preview}
                          width="90%"
                          file={selectedFiles[address.file].file}
                          alt={address.title}
                        />
                      ) : userProfileObject[address.file]?.file_url ? (
                        <FilePreview
                          src={
                            selectedFiles[address.file]?.preview ??
                            userProfileObject[address.file].file_url
                          }
                          width="90%"
                          file={
                            selectedFiles[address.file]?.file ??
                            userProfileObject[address.file]
                          }
                          alt={address.title}
                        />
                      ) : (
                        <Typography
                          className="mb-1rem  d-flex jc-start registration0875RemFontSize"
                          component="h1"
                          variant="h5"
                          sx={{
                            fontFamily: "Helvetica, sans-serif",
                            fontWeight: 400, // Adjusted this to a numerical value
                            color: "#454745",
                          }}
                        >
                          {"No " + address.title}
                        </Typography>
                      )}
                    </div>
                  </div>
                </div>
              )}
            </div>
          </Box>

          <Box className="mb-big">
            <Typography
              sx={{
                fontFamily: "Helvetica, sans-serif",
                textAlign: "start",
              }}
            >
              {i18next.t("Company Documents")}
            </Typography>

            <div className="personal-box">
              {companyFiles.map((kycPersonalItem, kycPersonalIndex) => (
                <div
                  key={"kyc" + kycPersonalIndex}
                  className="one-flex-line"
                  style={{ paddingTop: 10, paddingBottom: 10 }}
                >
                  <div className="verification-box-full">
                    <Typography
                      className="mb-1rem  d-flex jc-start registration0875RemFontSize"
                      component="h1"
                      variant="h5"
                      sx={{
                        fontFamily: "Helvetica, sans-serif",
                        textDecoration: "underline",
                        cursor: "pointer",
                      }}
                    >
                      {kycPersonalItem.title}{" "}
                      {kycPersonalItem.required ? "*" : ""}
                    </Typography>

                    <Button
                      className="btnsLoginHeight oneRemFontSize"
                      sx={{
                        marginBottom: 2,
                        textTransform: "none",
                        "&:hover": {
                          backgroundColor: "#2A5182",
                          color: "white",
                        },
                      }}
                      component="label"
                      role={undefined}
                      variant="contained"
                      tabIndex={-1}
                      startIcon={<CloudUploadIcon className="muiIcons" />}
                    >
                      {userProfileObject[kycPersonalItem.file] &&
                      userProfileObject[kycPersonalItem.file].file_url
                        ? "Change file"
                        : "Upload file"}
                      <VisuallyHiddenInput
                        onChange={(e) => handleFileUpload(e, kycPersonalItem)}
                        type="file"
                      />
                    </Button>

                    {selectedFiles &&
                    selectedFiles[kycPersonalItem.file] &&
                    selectedFiles[kycPersonalItem.file].preview ? (
                      <FilePreview
                        src={selectedFiles[kycPersonalItem.file].preview}
                        width="90%"
                        file={selectedFiles[kycPersonalItem.file].file}
                        alt={kycPersonalItem.title}
                      />
                    ) : userProfileObject[kycPersonalItem.file]?.file_url ? (
                      <FilePreview
                        src={
                          selectedFiles[kycPersonalItem.file]?.preview ??
                          userProfileObject[kycPersonalItem.file].file_url
                        }
                        width="90%"
                        file={
                          selectedFiles[kycPersonalItem.file]?.file ??
                          userProfileObject[kycPersonalItem.file]
                        }
                        alt={kycPersonalItem.title}
                      />
                    ) : (
                      <Typography
                        className="mb-1rem  d-flex jc-start registration0875RemFontSize"
                        component="h1"
                        variant="h5"
                        sx={{
                          fontFamily: "Helvetica, sans-serif",
                          fontWeight: 400, // Adjusted this to a numerical value
                          color: "#454745",
                        }}
                      >
                        {"No " + kycPersonalItem.title}
                      </Typography>
                    )}
                  </div>
                </div>
              ))}
            </div>
          </Box>

          <Box>
            <ConfirmationOfSourceOfFunds formik={formik} />
          </Box>

          <Button
            type="submit"
            fullWidth
            variant="contained"
            className="continueBtn"
            onClick={(e) => {
              formik.handleSubmit(e);
              //setActiveStep(2);
            }}
          >
            {i18next.t("Continue")}
          </Button>
        </Box>
      </Box>
    </Box>
  );
}

export default DocumentsStep;
