import {
  Avatar,
  Box,
  LinearProgress,
  Tooltip,
  Typography,
  useMediaQuery,
} from "@mui/material";
import "../../../pages/Chat/Chat.scss";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import { useEffect, useRef } from "react";
import { debounce } from "lodash";

interface SingleUserChatProps {
  conversations: any;
  formatTimestamp: (timestamp: string) => string;
  onUserItemClick: (conversationId: any, user: any) => void;
  selectedConversationId: any;
  loadMore: boolean;
  selectedConversationUser: any;
  handleScroll: () => void;
}

export const SingleUserChatContainer: React.FC<SingleUserChatProps> = ({
  conversations,
  formatTimestamp,
  onUserItemClick,
  selectedConversationId,
  selectedConversationUser,
  handleScroll,
  loadMore,
}) => {
  const isWideScreen = useMediaQuery("(min-width: 829px)");
  const scrollContainerRef = useRef<HTMLDivElement | null>(null);

  const getConversationUserName = (conversation: any) => {
    if (!conversation || !conversation.user) return "";

    const { user } = conversation;

    if (user.account_type === "business" && user.company_name) {
      return user.company_name;
    }

    if (user.first_name && user.last_name) {
      return `${user.first_name} ${user.last_name}`;
    }

    return "";
  };

  useEffect(() => {
    const handleContainerScroll = () => {
      const container = scrollContainerRef.current;
      if (container) {
        const scrollTop = container.scrollTop;
        const scrollHeight = container.scrollHeight;
        const clientHeight = container.clientHeight;

        const buffer = 100;

        if (scrollTop + clientHeight >= scrollHeight - buffer && !loadMore) {
          handleScroll();
        }
      }
    };

    const debouncedHandleContainerScroll = debounce(handleContainerScroll, 100);
    const currentRef = scrollContainerRef.current;

    if (currentRef) {
      currentRef.addEventListener("scroll", debouncedHandleContainerScroll);
      return () => {
        currentRef.removeEventListener(
          "scroll",
          debouncedHandleContainerScroll
        );
      };
    }
  }, [scrollContainerRef, handleScroll, loadMore]);

  return (
    <Box sx={{ height: "100%", overflow: "auto" }} ref={scrollContainerRef}>
      {conversations.map((conversation: any) => (
        <Box
          className={
            selectedConversationId === conversation.id
              ? "activeUsersBox"
              : "usersBox"
          }
          key={"conversation" + conversation.id}
          onClick={() => onUserItemClick(conversation.id, conversation.user)}
        >
          <Box key={"conversation" + conversation.id} className="singleUser">
            <Box>
              {conversation &&
              conversation.user &&
              conversation.user.image !== null ? (
                <Avatar
                  src={
                    conversation.user?.image?.file_url
                      ? conversation.user.image.file_url
                      : null
                  }
                  alt={`${conversation?.last_message?.username ?? ""}`}
                  sx={{ width: "48px", height: "48px", objectFit: "cover" }}
                ></Avatar>
              ) : (
                <Avatar sx={{ width: "48px", height: "48px" }}></Avatar>
              )}
            </Box>
            <Box className="nameLastChatBox">
              <Typography className="userName">
                {getConversationUserName(conversation)}
              </Typography>
              <Typography className="lastMessage">
                {conversation?.last_message?.message ?? ""}
              </Typography>
              <Typography className="lastMessageAtMob">
                {conversation?.last_message?.created_at
                  ? formatTimestamp(conversation.last_message.created_at)
                  : ""}
              </Typography>
            </Box>
            <Box className="lastMessageAtBox">
              {isWideScreen ? (
                <Tooltip
                  title={
                    conversation?.last_message?.created_at
                      ? formatTimestamp(conversation.last_message.created_at)
                      : ""
                  }
                  arrow
                >
                  <Typography className="lastMessageAt">
                    {conversation?.last_message?.created_at
                      ? formatTimestamp(conversation.last_message.created_at)
                      : ""}
                  </Typography>
                </Tooltip>
              ) : (
                <Typography className="lastMessageAt">
                  {conversation?.last_message?.created_at
                    ? formatTimestamp(conversation.last_message.created_at)
                    : ""}
                </Typography>
              )}
              {conversation?.unread_messages > 0 && (
                <FiberManualRecordIcon
                  style={{
                    width: "12px",
                    height: "12px",
                    color: "#2a5182",
                  }}
                />
              )}
            </Box>
          </Box>
        </Box>
      ))}{" "}
      {loadMore && (
        <Box sx={{ width: "100%" }}>
          <LinearProgress />
        </Box>
      )}
    </Box>
  );
};
