import { useFormik } from "formik";
import { useState } from "react";
import * as Yup from "yup";
import { toast } from "react-toastify";
import api from "src/store/interceptors/api";
import { Box, Button, TextField, Typography } from "@mui/material";
import AreYouSureModal from "../shared/AreYouSureModal/AreYouSureModal";
import i18next from "src/i18n";

const ChangePassword = () => {
  const [isChangePasswordModal, setChangePasswordModal] = useState(false);

  const changePassword = useFormik({
    initialValues: {
      old_password: "",
      new_password: "",
      password_confirmation: "",
    },
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: async (data) => {
      try {
        await api.post("/change-password", data);
        toast.success(i18next.t("Password updated successfully."));
        changePassword.resetForm();
        setChangePasswordModal(false);
      } catch (error) {
        console.error(error);
        toast.error(i18next.t("Error updating password."));
      }
    },
    validationSchema: Yup.object({
      old_password: Yup.string().required(
        i18next.t("Old password is required")
      ),
      new_password: Yup.string()
        .min(8, i18next.t("Password must be at least 8 characters"))
        .required(i18next.t("New password is required")),
      password_confirmation: Yup.string()
        .oneOf([Yup.ref("new_password")], i18next.t("Passwords must match"))
        .required(i18next.t("Re-entering your new password is required")),
    }),
  });

  return (
    <Box className="personal-box">
      <Box className="changePassSecBox">
        <Typography className="changeYourPasswordText">
          {i18next.t("Change your password")}
        </Typography>
        <Box className="changePasswordFieldsBox">
          <TextField
            placeholder={i18next.t("Old Password")}
            name="old_password"
            type="password"
            onBlur={changePassword.handleBlur}
            value={changePassword.values.old_password}
            onChange={changePassword.handleChange}
            error={!!changePassword.errors.old_password}
            helperText={changePassword.errors.old_password}
            style={{ width: "100%" }}
            InputProps={{
              sx: {
                borderRadius: "10px",
                height: "48px",
                fontSize: "16px",
                "&:-internal-autofill-selected": {
                  backgroundColor: "transparent !important",
                },
                "@media (max-width:600px)": {
                  fontSize: "14px !important",
                },
              },
            }}
          />
          <TextField
            placeholder={i18next.t("New Password")}
            name="new_password"
            type="password"
            onBlur={changePassword.handleBlur}
            value={changePassword.values.new_password}
            onChange={changePassword.handleChange}
            error={!!changePassword.errors.new_password}
            helperText={changePassword.errors.new_password}
            style={{ width: "100%" }}
            InputProps={{
              sx: {
                borderRadius: "10px",
                height: "48px",
                fontSize: "16px",
                "&:-internal-autofill-selected": {
                  backgroundColor: "transparent !important",
                },
                "@media (max-width:600px)": {
                  fontSize: "14px !important",
                },
              },
            }}
          />
          <TextField
            placeholder={i18next.t("Confirm Password")}
            name="password_confirmation"
            type="password"
            onBlur={changePassword.handleBlur}
            value={changePassword.values.password_confirmation}
            onChange={changePassword.handleChange}
            error={!!changePassword.errors.password_confirmation}
            helperText={changePassword.errors.password_confirmation}
            style={{ width: "100%" }}
            InputProps={{
              sx: {
                borderRadius: "10px",
                height: "48px",
                fontSize: "16px",
                "&:-internal-autofill-selected": {
                  backgroundColor: "transparent !important",
                },
                "@media (max-width:600px)": {
                  fontSize: "14px !important",
                },
              },
            }}
          />
          {changePassword.values.old_password &&
            changePassword.values.new_password &&
            changePassword.values.password_confirmation && (
              <Button
                onClick={() => {
                  if (
                    changePassword.values.old_password &&
                    changePassword.values.new_password &&
                    changePassword.values.password_confirmation
                  ) {
                    setChangePasswordModal(true);
                  }
                }}
                className="changePassBtn"
                disabled={
                  !(
                    changePassword.values.old_password &&
                    changePassword.values.new_password &&
                    changePassword.values.password_confirmation
                  )
                }
              >
                <Typography className="changePassBtnText">
                  {i18next.t("Change password")}
                </Typography>
              </Button>
            )}
        </Box>
      </Box>
      <AreYouSureModal
        open={isChangePasswordModal}
        handleClose={() => setChangePasswordModal(false)}
        handleYes={() => {
          if (
            changePassword.values.old_password &&
            changePassword.values.new_password &&
            changePassword.values.password_confirmation
          ) {
            changePassword.handleSubmit();
            setChangePasswordModal(false);
          }
        }}
        title={i18next.t("Are you sure you want to change your password")}
      />
    </Box>
  );
};

export default ChangePassword;
