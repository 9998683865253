import { Avatar, Box, Checkbox, Typography } from "@mui/material";
import "./FiltersDrawer.scss";
import { useEffect, useState } from "react";
import recipientsService from "src/store/recipients/recipients_services";
import RecipientsFiltersDrawer from "./RecipientsFiltersDrawer";
import i18next from "src/i18n";

type Anchor = "top" | "left" | "bottom" | "right";

interface FiltersProps {
  selectedFilters: any;
  handleSelectedFilters: (filters: any) => void;
}
function RecipientFilters({
  selectedFilters,
  handleSelectedFilters,
}: FiltersProps) {
  const [isDrawerOpen, setIsDrawerOpen] = useState<Record<Anchor, boolean>>({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });
  const [savedUser, setSavedUser] = useState<any[]>([]);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(20);
  const [totalPages, setTotalPages] = useState(1);
  const [searchValue, setSearchValue] = useState("");
  const [loading, setLoading] = useState(false);

  const handleCheckbox = (
    event: React.ChangeEvent<HTMLInputElement>,
    object: any,
    type: string
  ) => {
    if (type === "recipient") {
      let filters: any = { ...selectedFilters };
      if (!filters) filters = {};
      if (!filters.recipients) filters.recipients = {};
      if (!filters.recipients.users) filters.recipients.users = [];

      const filterSavedUsers: any[] = [...savedUser];
      let filterRecipients = [...filters.recipients.users];
      const foundItem: any =
        filterSavedUsers && filterSavedUsers.length > 0
          ? filterSavedUsers.findIndex((item: any) => item.id === object.id)
          : -1;
      let blnSort = false;
      if (!filterRecipients.includes(object.id)) {
        filterRecipients.push(object.id);
        if (foundItem > -1) {
          filterSavedUsers[foundItem].selected = 1;
          blnSort = true;
        }
      } else {
        const index = filterRecipients.indexOf(object.id);
        if (index > -1) {
          filterRecipients.splice(index, 1);
          if (foundItem > -1) {
            filterSavedUsers[foundItem].selected = 0;
            blnSort = true;
          }
        }
      }
      filters.recipients.users = filterRecipients;
      handleSelectedFilters(filters);
      if (blnSort) {
        filterSavedUsers.sort((a, b) => b.selected - a.selected);
        setSavedUser(filterSavedUsers);
      }
    }
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(event.target.value);
    setPage(1);
  };

  const handleLoadMore = () => {
    setPage(1);
    setPerPage((prevPerPage: any) => prevPerPage * 2);
  };

  const handleGetRecipients = async () => {
    setLoading(true);
    let urlFilter = null;
    if (searchValue) {
      urlFilter = `?filter_transaction=true&page=${page}&per_page=${perPage}&search[meta]=${searchValue}`;
    } else {
      urlFilter = `?filter_transaction=true&page=${page}&per_page=${perPage}`;
    }
    const response = await recipientsService.getRecipients(urlFilter);
    const { data, meta } = response;
    setLoading(false);
    if (data) {
      setSavedUser(data);
    }
    if (meta) {
      setTotalPages(meta?.last_page || 1);
    }
  };

  useEffect(() => {
    handleGetRecipients();
  }, [searchValue, page, perPage]);

  const openDrawer = (anchor: Anchor) => {
    setIsDrawerOpen((prevDrawerState) => ({
      ...prevDrawerState,
      [anchor]: true,
    }));
  };

  const closeDrawer = (anchor: Anchor) => {
    setIsDrawerOpen((prevDrawerState) => ({
      ...prevDrawerState,
      [anchor]: false,
    }));
  };

  //console.log(selectedFilters, "filters");
  return (
    // savedUser.length > 0 ?
    <Box>
      <Box className="recipientsAndViewAll">
        <Typography className="subBodiesRecipients">
          {i18next.t("Recipient")}
        </Typography>
        <RecipientsFiltersDrawer
          handleCheckbox={handleCheckbox}
          isDrawerOpen={isDrawerOpen}
          openDrawer={openDrawer}
          selectedFilters={selectedFilters}
          handleSelectedFilters={handleSelectedFilters}
          closeDrawer={closeDrawer}
          data={savedUser}
          searchValue={searchValue}
          handleSearchChange={handleSearchChange}
          page={page}
          totalPages={totalPages}
          loading={loading}
          handleLoadMore={handleLoadMore}
        />
      </Box>
      {savedUser.slice(0, 3).map((recipient, key) => {
        const checked =
          selectedFilters &&
          selectedFilters.recipients &&
          selectedFilters.recipients.users &&
          selectedFilters.recipients.users.includes(recipient.id)
            ? true
            : false;
        return (
          <Box key={"Recipient" + key} style={{ marginTop: "8px" }}>
            <Box className="selectableBoxesHover">
              <Box className="imageDirectionsAndRadio">
                <Box className="imageDirectionAndText">
                  <Box className="imageDirectionBG">
                    <Avatar
                      style={{
                        width: "48px",
                        height: "48px",
                        backgroundColor: "#E5E8E2",
                      }}
                    >
                      {" "}
                      {recipient.account_holder && (
                        <>
                          <span className="spanAvatar">
                            {recipient.account_holder
                              .split(" ")
                              .map((namePart: any, index: any) =>
                                index < 2
                                  ? namePart.charAt(0).toUpperCase()
                                  : ""
                              )
                              .join("")}
                          </span>
                        </>
                      )}
                    </Avatar>
                  </Box>
                  <Box className="nameAndEndingBox">
                    <Typography className="nameText">
                      {recipient.account_holder}
                    </Typography>
                    <Box className="endingText">
                      {recipient.iban && recipient.type === "iban" && (
                        <Typography className="allRecipientEndingIn">
                          {recipient.currency} {i18next.t("account ending in")}{" "}
                          {recipient.iban.slice(-4)}
                        </Typography>
                      )}

                      {recipient.account_number && (
                        <Typography className="allRecipientEndingIn">
                          {recipient.currency} {i18next.t("account ending in")}{" "}
                          {recipient.account_number.slice(-4)}
                        </Typography>
                      )}
                    </Box>
                  </Box>
                </Box>
                <Checkbox
                  checked={checked}
                  onChange={(event) => {
                    handleCheckbox(event, recipient, "recipient");
                  }}
                  sx={{
                    "& .MuiSvgIcon-root": { fontSize: 22, color: "#2a5182" },
                  }}
                />
              </Box>
            </Box>
          </Box>
        );
      })}
    </Box>
  );
  //   : null
}

export default RecipientFilters;
