import React, { useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Drawer from "@mui/material/Drawer";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import "./FiltersDrawer.scss";
import CloseIcon from "@mui/icons-material/Close";
import { Typography } from "@mui/material";
import DateFilters from "./DateFilters";
import TypeFilters from "./TypeFilters";
import DirectionFilters from "./DirectionFilters";
import RecipientFilters from "./RecipientFilters";
import StatusFilters from "./StatusFilters";
import CurrencyFilters from "./CurrencyFilters";
import i18next from "src/i18n";

type Anchor = "top" | "left" | "bottom" | "right";

interface FiltersDrawerProps {
  isDrawerOpen: Record<Anchor, boolean>;
  openDrawer: (anchor: Anchor) => void;
  closeDrawer: (anchor: Anchor) => void;
  applyFilters: (filters?: any) => void;
  handleClearFilters: () => void;
  printPdf: () => void;
  searchValue?: any;
}

const TransactionFiltersDrawer: React.FC<FiltersDrawerProps> = ({
  isDrawerOpen,
  openDrawer,
  closeDrawer,
  applyFilters,
  printPdf,
  handleClearFilters,
  searchValue,
}) => {
  const [selectedFilters, setSelectedFilters] = useState<any>({
    direction: {
      selectedDirection: "All",
    },
  });

  const handleSelectedFilters = (filters: any) => {
    const newFilters = { ...filters };
    const onlyBalanceSelected =
      newFilters.types?.methods?.length === 1 &&
      newFilters?.types?.methods?.includes("Balance");

    if (onlyBalanceSelected) {
      newFilters.direction = {
        selectedDirection: filters?.direction?.selectedDirection,
      };
    } else {
      delete newFilters.direction;
    }
    setSelectedFilters(newFilters); // Update the state with the new filters
  };

  const applyFilter = (anchor: any) => {
    closeDrawer(anchor);
    applyFilters(selectedFilters);
  };
  const handleClearAll = () => {
    const defaultDirection = "All";
    const newFilters = {
      direction: {
        selectedDirection: defaultDirection,
      },
    };
    setSelectedFilters(newFilters);
    handleClearFilters();
  };

  const list = (anchor: Anchor) => (
    <Box className="mainDrawer">
      <Box style={{ marginTop: "80px", marginBottom: "90px" }}>
        <Box className="drawerHeader">
          <Box className="drawerHeaderItems">
            <Typography className="filtersText">
              {i18next.t("Filters")}
            </Typography>
            <Box
              style={{ cursor: "pointer" }}
              onClick={() => closeDrawer(anchor)}
            >
              <CloseIcon className="searchIconTransactionFilters" />
            </Box>
          </Box>
        </Box>
        <Box className="drawerBody">
          <DateFilters
            selectedFilters={selectedFilters}
            handleSelectedFilters={handleSelectedFilters}
          />
          <RecipientFilters
            selectedFilters={selectedFilters}
            handleSelectedFilters={handleSelectedFilters}
          />
          <CurrencyFilters
            selectedFilters={selectedFilters}
            handleSelectedFilters={handleSelectedFilters}
          />
          <TypeFilters
            selectedFilters={selectedFilters}
            handleSelectedFilters={handleSelectedFilters}
          />
          <DirectionFilters
            selectedFilters={selectedFilters}
            handleSelectedFilters={handleSelectedFilters}
          />
          <StatusFilters
            selectedFilters={selectedFilters}
            handleSelectedFilters={handleSelectedFilters}
          />
        </Box>
        <Box className="drawerFooter">
          <Box className="drawerFooterBtns">
            <Button className="clearAllBtn" onClick={() => handleClearAll()}>
              {i18next.t("Clear all")}
            </Button>
            <Button className="applyBtn" onClick={() => applyFilter(anchor)}>
              {i18next.t("Apply")}
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  );

  return (
    <div style={{ width: "100%" }}>
      <div className="print-pdf" style={{ width: "100%" }}>
        <Button
          variant="contained"
          className="filtersBtn"
          id="filters-btn"
          startIcon={<FilterAltIcon />}
          onClick={() => openDrawer("right")}
        >
          {i18next.t("Filters")}
        </Button>
        <Button
          variant="contained"
          className="filtersBtn"
          id="filters-btn"
          startIcon={<PictureAsPdfIcon />}
          onClick={() => printPdf()}
        >
          {i18next.t("Print")}
        </Button>
      </div>
      <Drawer
        anchor="right"
        open={isDrawerOpen["right"]}
        onClose={() => closeDrawer("right")}
      >
        {list("right")}
      </Drawer>
    </div>
  );
};

export default TransactionFiltersDrawer;
