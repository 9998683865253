import i18next from "i18next";
import "../../pages/LandingPage.scss";
import { Box, Typography } from "@mui/material";

function WhyVicPay() {
  return (
    <Box id="why-vicpay" className="whyChooseVicPayContainer">
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          height: "100%",
        }}
      >
        <Typography className="whyChooseVPText">{i18next.t("Why Choose VicPay")}</Typography>
        <Typography className="whyChooseVPDescText">
          {i18next.t("Unlock Worldwide Financial Opportunities with Customized Solutions and Expert Guidance")}
        </Typography>
      </Box>
      <Box className="whyChooseVPItemsMain">
        <Box className="whyChooseVPItem">
          <Box className="whyChooseVPItemInfo">
            <Box className="whyChooseVPFirst grow-vpitem"></Box>
            <Typography className="whyChooseVPDesc">{i18next.t("Real-time processing of cross-border payments")} 
            </Typography>
          </Box>
        </Box>
        <Box className="whyChooseVPItem">
          <Box className="whyChooseVPItemInfo">
            <Box className="whyChooseVPSec grow-vpitem"></Box>
            <Typography className="whyChooseVPDesc">
              {i18next.t("Support for multiple currencies")}
            </Typography>
          </Box>
        </Box>
        <Box className="whyChooseVPItem">
          <Box className="whyChooseVPItemInfo">
            <Box className="whyChooseVPThird grow-vpitem"></Box>
            <Typography className="whyChooseVPDesc">
              {i18next.t("Consensus-based validation of payment data")}
            </Typography>
          </Box>
        </Box>
        <Box className="whyChooseVPItem">
          <Box className="whyChooseVPItemInfo">
            <Box className="whyChooseVPFourth grow-vpitem"></Box>
            <Typography className="whyChooseVPDesc">
              {i18next.t("Automated Record-Keeping & Transaction Traceability")}
            </Typography>
          </Box>
        </Box>
        <Box className="whyChooseVPItem">
          <Box className="whyChooseVPItemInfo">
            <Box className="whyChooseVPFifth grow-vpitem"></Box>
            <Typography className="whyChooseVPDesc">
              {i18next.t("Smart contracts for payment automation")}
            </Typography>
          </Box>
        </Box>
        <Box className="whyChooseVPItem">
          <Box className="whyChooseVPItemInfo">
            <Box className="whyChooseVPSixth grow-vpitem"></Box>
            <Typography className="whyChooseVPDesc">
              {i18next.t("Comprehensive payment security")}
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default WhyVicPay;
