import i18next from "i18next";

export const vicPayCalculation = (amount: number): number => {
  let fee: number = 0;

  if (amount < 1000000) {
    fee = amount * 0.005; // 0.5%
  } else if (amount >= 1000000 && amount < 10000000) {
    fee = amount * 0.004; // 0.4%
  } else if (amount >= 10000000 && amount < 50000000) {
    fee = amount * 0.003; // 0.3%
  } else if (amount >= 50000000) {
    fee = amount * 0.002; // 0.2%
  }

  return fee;
};

export const sortRecipientFields = (fields: any[]): any[] => {
  const priorityOrder = [
    "iban",
    "account-number",
    "swift",
    "account-name",
    "bank-name",
    "bank-country",
  ]; // Define your priority order here
  const excludedFields = ["bank-name", "bank-country", "idtype2"]; // Define fields to be excluded here

  return fields
    .filter((field) => !excludedFields.includes(field.id))
    .sort((a, b) => {
      const indexA = priorityOrder.indexOf(a.id);
      const indexB = priorityOrder.indexOf(b.id);

      if (indexA === -1 && indexB === -1) {
        return 0; // Both fields are not in the priority list, keep original order
      } else if (indexA === -1) {
        return 1; // 'a' is not in the priority list, move it down
      } else if (indexB === -1) {
        return -1; // 'b' is not in the priority list, move it down
      } else {
        return indexA - indexB; // Sort by priority order
      }
    });
};

export const providerTranslation: any = {
  fname: "First Name",
  lname: "Last Name",
  country: "Country",
  bank_country: "Bank Country",
  "Company Name or First Name, Last Name": "Company name",
  address1: "Address 1",
  account_name: "Account name",
  bank_name: "Bank name",
};
export const translateProvider = (key: any) => {
  if (providerTranslation[key]) {
    return providerTranslation[key];
  }

  return key;
};
export const barOptions = (type: any) => {
  if (type === "user-registration") {
    return [
      {
        name: "registrations",
        fill: "rgb(0, 167, 111)",
        rectangleFill: "#0b573e",
        rectangleStroke: "#0b573e",
      },
    ];
  } else {
    return [
      {
        name: "income",
        fill: "rgb(0, 167, 111)",
        rectangleFill: "#0b573e",
        rectangleStroke: "#0b573e",
      },
      {
        name: "expense",
        fill: "rgb(255, 171, 0)",
        rectangleFill: "#731616",
        rectangleStroke: "#731616",
      },
      {
        name: "pending",
        fill: "rgb(120, 120, 120)",
        rectangleFill: "#5e5d5d",
        rectangleStroke: "#5e5d5d",
      },
    ];
  }
};

export const formatAccountNumber = (id: number) => {
  // Convert the userID to a string
  let userIDString = String(id);

  // Add leading zeros if necessary to make it 6 characters long
  let paddedUserID = userIDString.padStart(6, "0");

  // Add the prefix '100' in front
  let formattedUserID = "100" + paddedUserID;

  return formattedUserID;
};

export const formatAccountStatus = (status: string) => {
  if (status === "created") return "No transaction created";
  if (status === "transaction_created") return "Transactions created";
  if (status === "target_reached") return "Target limit is reached";
  return "No status";
};
export const formatParticipationNumber = (id: number) => {
  // Convert the userID to a string
  let userIDString = String(id);

  // Add leading zeros if necessary to make it 6 characters long
  let paddedUserID = userIDString.padStart(6, "0");

  // Add the prefix '100' in front
  let formattedUserID = "IP100" + paddedUserID;

  return formattedUserID;
};
export const formatInvestmentNumber = (id: number) => {
  // Convert the userID to a string
  let userIDString = String(id);

  // Add leading zeros if necessary to make it 6 characters long
  let paddedUserID = userIDString.padStart(6, "0");

  // Add the prefix '100' in front
  let formattedUserID = "IR100" + paddedUserID;

  return formattedUserID;
};
export const businessFiles = [
  {
    file: "business_aml_file",
    id: "business_aml_id",
    ref: "amlRef",
    title: "AML (Anti-Money Laundering) Compliance",
    required: true,
  },
  {
    file: "business_company_registration_file",
    id: "business_company_registration_id",
    ref: "companyRegistrationRef",
    title: "Company registration details",
    required: true,
  },
  {
    file: "business_licence_file",
    id: "business_licence_id",
    ref: "businessLicenceRef",
    title: "Business licenses",
    required: true,
  },
  {
    file: "business_address_file",
    id: "business_address_id",
    ref: "businessAddressRef",
    title: "Address Verification",
    required: true,
  },
  {
    file: "business_sof_file",
    id: "business_sof_id",
    ref: "sofRef",
    title: "Source of Funds (SOF) Verification",
    required: false,
  },
  {
    file: "business_ubo_file",
    id: "business_ubo_id",
    ref: "uboRef",
    title: "Ultimate Beneficial Ownership (UBO) Verification",
    required: false,
  },
  {
    file: "business_tin_file",
    id: "business_tin_id",
    ref: "tinRef",
    title: "Tax Identification Number (TIN)",
    required: true,
  },
];
export const personalFiles = [
  {
    file: "id_card_front_file",
    id: "id_card_front_id",
    ref: "idFrontPageRef",
    title: "ID Card Front Page Image",
    required: true,
  },
  {
    file: "id_card_back_file",
    id: "id_card_back_id",
    ref: "idBackPageRef",
    title: "ID Card Back Page Image",
    required: true,
  },
  {
    file: "passport_file",
    id: "passport_id",
    ref: "passportRef",
    title: "Passport Main Page Image",
    required: true,
  },
  {
    file: "personal_photo_file",
    id: "personal_photo_id",
    ref: "personalPhotoRef",
    title: "Personal Photo",
    required: true,
  },
];

export const scannedFiles = [
  {
    file: "scanned_face_photo",
    id: "scanned_face_photo",
    ref: "scannedFacePhotoRef",
    title: "Scanned Face Photo",
    required: true,
  },

  {
    file: "scanned_id_photo",
    id: "scanned_id_photo",
    ref: "scannedIdPhotoRef",
    title: "Scanned Id Photo",
    required: true,
  },

  {
    file: "scanned_complete_photo",
    id: "scanned_complete_photo",
    ref: "scannedCompletePhotoRef",
    title: "Scanned Complete Photo",
    required: true,
  },
];

export const personalVerification = [
  {
    file: "id_card_front_file",
    id: "id_card_front_id",
    ref: "idFrontPageRef",
    title: "ID Card Front Page Image",
    required: true,
  },
  {
    file: "id_card_back_file",
    id: "id_card_back_id",
    ref: "idBackPageRef",
    title: "ID Card Back Page Image",
    required: true,
  },
  {
    file: "passport_file",
    id: "passport_id",
    ref: "passportRef",
    title: "Passport Main Page Image",
    required: true,
  },
  {
    file: "personal_photo_file",
    id: "personal_photo_id",
    ref: "personalPhotoRef",
    title: "Personal Photo",
    required: true,
  },
];
export const acceptableProofOfId = [
  {
    file: "passport_file",
    id: "passport_id",
    ref: "passportRef",
    title: "Passport Image",
    required: false,
  },
  {
    file: "eu_identification_card",
    id: "eu_identification_id",
    ref: "euIdentificationCardRef",
    title: "EU Identification Card",
    required: false,
  },
];

export const collectedFor = [
  {
    file: "ubo_file",
    id: "ubo_id",
    ref: "uboRef",
    title: "Ultimate Beneficial Owners (UBOs) >25%",
    required: false,
  },
  {
    file: "director_file",
    id: "director_id",
    ref: "directorRef",
    title: "Director(s) (min. 2 where there are 2 or more)",
    required: false,
  },
  {
    file: "authorised_signatories_file",
    id: "authorised_signatories_id",
    ref: "authorisedSignatoriesRef",
    title: "Authorised Signatories",
    required: false,
  },
];

export const proofOfAddress = [
  {
    file: "utility_bill",
    id: "utility_bill_id",
    ref: "utilityBillRef",
    title: "Utility Bill (dated within the last 6 months)",
    required: false,
  },
  {
    file: "bank_statement",
    id: "bank_statement_id",
    ref: "bankStatementRef",
    title: "Bank Statement (dated within the last 6 months)",
    required: false,
  },
  {
    file: "uk_drivers_licence",
    id: "uk_drivers_licence_id",
    ref: "ukDriverLicenceRef",
    title: "UK Drivers Licence",
    required: false,
  },

  {
    file: "eu_identification_card_address",
    id: "eu_identification_address_id",
    ref: "euIdentificationCardAddressRef",
    title: "EU Identification Card (displaying residential address)",
    required: false,
  },

  {
    file: "chinese_card",
    id: "chinese_card_id",
    ref: "chineseCardRed",
    title: "Chinese National card for residents of China",
    required: false,
  },

  {
    file: "state_issued_correspondence",
    id: "state_issued_correspondence_id",
    ref: "StateIssuedCorrespondenceRef",
    title: "State Issued Correspondence (dated within the last 6 months)",
    required: false,
  },

  {
    file: "tax_statement",
    id: "tax_statement_id",
    ref: "TaxStatementRef",
    title: "Tax Statement",
    required: false,
  },
];

export const companyFiles = [
  {
    file: "company_registry_extract",
    id: "company_registry_extract_id",
    ref: "CompanyRegistryExtractRef",
    title:
      "Company Registry Extract (no older than 6 months) Articles of Association",
    required: true,
  },

  {
    file: "shareholder_register",
    id: "shareholder_register_id",
    ref: "ShareholderRegisterRef",
    title: "Shareholder Register",
    desc: "If unable to verify shareholding of >25% using the registry extract",
    required: false,
  },

  {
    file: "director_register",
    id: "director_register_id",
    ref: "DirectorRegisterRef",
    title: "Director Register",
    required: true,
  },

  {
    file: "company_resolution",
    id: "company_resolution_id",
    ref: "CompanyResolutionRef",
    title: "Company resolution",
    desc: "If more than one signatory",
    required: false,
  },

  {
    file: "confirmation_client",
    id: "confirmation_client_id",
    ref: "ConfirmationclientRef",
    title: "Confirmation from the client on the source of funds",
    required: true,
  },

  {
    file: "anual_report",
    id: "anual_report_id",
    ref: "AnnualReport",
    title: "Annual Report/Financial Statement for the recent financial year",
    required: true,
  },

  {
    file: "wealth_confirmation",
    id: "wealth_confirmation_id",
    ref: "wealthConfirmationRef",
    title:
      "Confirmation from the client on the source of wealth and evidence supporting the source of wealth",
    required: true,
  },
  {
    file: "business_aml_file",
    id: "business_aml_id",
    ref: "amlRef",
    title: "AML (Anti-Money Laundering) Compliance",
    required: true,
  },
];

export const addLeadingZeros = (number: number, length: number) => {
  let strNumber = number.toString();
  let zerosToAdd = length - strNumber.length;

  for (let i = 0; i < zerosToAdd; i++) {
    strNumber = "0" + strNumber;
  }

  return strNumber;
};

export const formatCreditCardNumber = (input: string) => {
  const cleanInput = input.replace(/\D/g, "");
  const groups = cleanInput.match(/.{1,4}/g);
  const formattedNumber = groups ? groups.join(" ") : "";
  return formattedNumber;
};

export const formatCVC = (input: number) => {
  let res = input > 1 ? input * 103 : input * 122;
  return res;
};

export const formatDate = (data: string) => {
  // Check if sectionName is a valid date
  const date = new Date(data);
  if (!isNaN(date.getTime())) {
    // It's a valid date, format it
    const currentLanguage = i18next.language || "en-US";
    return new Intl.DateTimeFormat(currentLanguage, {
      month: "long",
      day: "numeric",
      year: "numeric",
    }).format(date);
  } else {
    // Not a valid date, return as is
    return data;
  }
};

export const formatDateTime = (data: string) => {
  // Check if sectionName is a valid date
  const date = new Date(data);
  if (!isNaN(date.getTime())) {
    // It's a valid date, format it
    return new Intl.DateTimeFormat("en-US", {
      month: "long",
      day: "numeric",
      year: "numeric",
      hour: "2-digit",
      minute: "2-digit",
    }).format(date);
  } else {
    // Not a valid date, return as is
    return data;
  }
};

export const capitalizeWords = (inputString: string) => {
  const words = inputString?.split(" ");

  const capitalizedWords = words?.map((word) => {
    if (word?.length === 0) {
      return "";
    }
    return word?.charAt(0)?.toUpperCase() + word?.slice(1)?.toLowerCase();
  });
  return capitalizedWords?.join(" ");
};

export const truncateFileName = (fileName: string, maxLength: number) => {
  if (fileName.length <= maxLength) {
    return fileName;
  }

  const fileNameWithoutExtension = fileName.split(".").slice(0, -1).join(".");
  const fileExtension = fileName.split(".").pop();
  if (!fileExtension) return;
  const truncatedFileName = `${fileNameWithoutExtension.substring(
    0,
    maxLength - fileExtension.length - 1
  )}...${fileExtension}`;
  return truncatedFileName;
};

export const uuidGenerator = () => {
  return "10000000-1000-4000-8000-100000000000".replace(/[018]/g, (c: any) =>
    (
      c ^
      (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
    ).toString(16)
  );
};
