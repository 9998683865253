import { useLocation } from "react-router-dom";
import MobileNavigation from "../shared/MobileNavigation/MobileNavigation";
import "src/style/global.css";

const excludedPaths = [
  "/",
  "/landing",
  "/privacy-policy",
  "/copyright",
  "/contact-us",
  "/individual",
  "/business",
  "/multi-currency",
  "/open-account",
  "/download-app",
  "/contact-account-team",
  "/aml-policy",
  "/client-security",
  "/regulation",
  "/how-we-support-you",
  "/instant-transfers",
  "/crypto-fiat-currencies",
  "/reliable-app-interface",
  "/easy-account-funding",
  "/in-house-workshops",
  "/compliance-desk",
  "/license",
  "/safety-of-funds",
  "/payments",
  "/cryptos",
  "/mezzanine-capital",
  "/iban-accounts",
  "/visa-card",
  "/fees",
  "/security",
  "/about-us",
  "/victorum-group",
  "/solutions",
  "/we-hire",
  "/faq",
  "/media-press",
  "/complains",
  "/cookies-policy",
  "/banking",
  "/your-mastercard",
  "/victorum-market",
  "/online-news-room",
  "/declaration",
  "/news/:newsId",
];

const Wrapper = (props: any) => {
  const location = useLocation();
  const shouldHideMobileNav = location.pathname.startsWith("/news/")
    ? true
    : excludedPaths.includes(location.pathname)
    ? true
    : false;
  const containerStyles = shouldHideMobileNav ? {} : { paddingBottom: "10rem" };

  return (
    <div className="page-body">
      <div className="container-xl" style={containerStyles}>
        {props.children}
      </div>
      {!shouldHideMobileNav && <MobileNavigation />}
    </div>
  );
};

export default Wrapper;
