import { Box, Typography } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import "./TableList.scss";
import TableListItem from "./TableListItem";

type Anchor = "top" | "left" | "bottom" | "right";

function TableList({
  data,
  redirectPage,
  hideNavigation,
  style,
  title
}: any) {
  const [isDrawerOpen, setIsDrawerOpen] = useState<Record<Anchor, boolean>>({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const navigate = useNavigate();

  const handleNavigate = () => {
    navigate(redirectPage ? "/" + redirectPage : "/home");
  };

  const openDrawer = (anchor: Anchor) => {
    setIsDrawerOpen((prevDrawerState) => ({
      ...prevDrawerState,
      [anchor]: true,
    }));
  };

  const closeDrawer = (anchor: Anchor) => {
    setIsDrawerOpen((prevDrawerState) => ({
      ...prevDrawerState,
      [anchor]: false,
    }));
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
      }}
      style={style ?? {}}
    >
      <div className="d-flex ai-center mb-3-rem">
        <Box className="titleAndFilters">
          <Box className="transactionTitleAndIcon">
            {!hideNavigation && (
              <p
                className="back-button-icon circle-around-text-smaller helvetica mr-1-rem cp"
                onClick={handleNavigate}
              >
                <ArrowBackIcon className="muiIcons"/>
              </p>
            )}

            <Typography
              className="your-details-text d-flex jc-start pageTitleFontSize"
              component="h1"
              variant="h5"
              sx={{
                fontFamily: "Helvetica, sans-serif",
                fontWeight: "600",
                textAlign: "start",
              }}
            >
              {title}
            </Typography>
          </Box>
        </Box>
      </div>

      <div>
        {data && data.length === 0 ? (
          <Typography
            className="your-details-text d-flex jc-start underline"
            component="h1"
            variant="h5"
            sx={{
              fontFamily: "Helvetica, sans-serif",
              fontSize: "1.875rem",
              fontWeight: "200",
              textAlign: "start",
              marginBottom: "2rem",
            }}
          >
            No data to display!
          </Typography>
        ) : (
          data.map((item: any, index: number) => (
            <TableListItem item={item} index={index} />
          ))
        )}
      </div>
    </Box>
  );
}

export default TableList;
