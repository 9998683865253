import { Box, Typography } from "@mui/material";
import "../../pages/LandingPagesGlobal.scss";

const TitleTextVvidget = ({
  title,
  text,
  text2,
  text3,
  showBoxStyle = false,
}: any) => {
  return (
    <>
      <Box className={showBoxStyle ? "titleTextVvidgetBoxStyle" : ""}>
        <Box>
          <Typography
            variant="h3"
            sx={{
              marginBottom: "1rem",
              textAlign: "center",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
            className="headlineMiniLandingPages"
          >
            {" "}
            {title}
          </Typography>
        </Box>
        <Box>
          <Typography
            variant="body1"
            paragraph
            sx={{ maxWidth: "798px", margin: "auto", textAlign: "center" }}
            className="textsLandingPage"
          >
            {" "}
            {text}
          </Typography>
          <Typography
            variant="body1"
            paragraph
            sx={{ maxWidth: "798px", margin: "auto", textAlign: "center" }}
            className="textsLandingPage"
          >
            {" "}
            {text2}
          </Typography>
          <Typography
            variant="body1"
            paragraph
            sx={{ maxWidth: "798px", margin: "auto", textAlign: "center" }}
            className="textsLandingPage"
          >
            {" "}
            {text3}
          </Typography>
        </Box>
      </Box>
    </>
  );
};

export default TitleTextVvidget;
