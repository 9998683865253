import { Box, Typography } from "@mui/material";
import "./News.scss";
import ScheduleIcon from "@mui/icons-material/Schedule";
import CircleIcon from "@mui/icons-material/Circle";

interface Props {
  id: number;
  image: string;
  date: string;
  category: string;
  title: string;
  subtitle: string;
  text: string;
  onSelectCategory: (category: string) => void;
  onNewsClick: (id: number) => void;
}

const NewsBox: React.FC<Props> = ({
  id,
  image,
  date,
  category,
  title,
  subtitle,
  text,
  onSelectCategory,
  onNewsClick,
}) => {
  return (
    <Box className="newsSingleBox">
      <Box className="newsImageBox" onClick={() => onNewsClick(id)}>
        <img src={image} alt="News" className="newsImage" />
      </Box>
      <Box>
        <Box className="dateAndCategoryBox">
          <Box className="dateAndIconBox">
            <ScheduleIcon
              sx={{ width: "12px", height: "12px", color: "rgba(0,0,0,0.5)" }}
            />
            <Typography className="newsDateText">{date}</Typography>
          </Box>
          <CircleIcon
            sx={{ width: "8px", height: "8px", color: "rgba(0,0,0,0.5)" }}
          />
          <Typography
            className="newsCategoryText"
            onClick={() => onSelectCategory(category)}
          >
            {category}
          </Typography>
        </Box>
        <Box onClick={() => onNewsClick(id)}>
          <Typography className="newsTitle">{title}</Typography>
        </Box>
        <Box>
          <Typography className="newsText">{text}</Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default NewsBox;
