import React from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Box, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import HeaderLanding from "../components/HeaderLanding/HeaderLanding";
import { useAppSelector } from "src/store";
import FooterLanding from "../components/FooterLanding/FooterLanding";
import "./LandingPagesGlobal.scss";
import TitleBackBox from "../components/TitleBackBox/TitleBackBox";
import HeadlineVvidgetSmall from "../components/HeadlineVvidget/HeadlineVvidgetSmall";
import TitleTextVvidget from "../components/TitleTextVvidget/TitleTextVvidget";
import i18next from "i18next";
const defaultTheme = createTheme();
const IbanAccounts: React.FC = () => {
  const { user } = useAppSelector((state) => state.auth);

  const navigate = useNavigate();

  const navigateToContactUs = (event: any) => {
    navigate("/contact-us");
  };

  return (
    <div>
      <HeaderLanding user={user} />
      <div className="margintTopPagesMainCont">
        <ThemeProvider theme={defaultTheme}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Box className="titleHeadlineSmallBG">
              <Box className="landingPagesMainBox">
                <Box>
                  <TitleBackBox title={i18next.t("IBAN accounts")} />
                </Box>
                <Box>
                  <HeadlineVvidgetSmall
                    headline={i18next.t(
                      "Open a SEPA IBAN account for yourself or your business!"
                    )}
                    subtitle={
                      <>
                        {i18next.t("Forget traditional banks!")}
                        <br />{" "}
                        {i18next.t(
                          "Open your IBAN account easily, online and for free with VicPay!"
                        )}
                      </>
                    }
                    smallText={i18next.t("Request IBAN Account")}
                    buttonText={i18next.t("Contact Us")}
                    onClickFunction={navigateToContactUs}
                  />
                </Box>
              </Box>
            </Box>
            <Box className="landingPagesMainBox">
              <Box>
                <Typography
                  variant="h3"
                  sx={{
                    marginBottom: "1rem",
                  }}
                  className="headlineMiniLandingPages"
                >
                  {i18next.t("Streamline Payments with an IBAN Account")}
                </Typography>
                <Typography
                  variant="body1"
                  paragraph
                  className="textsLandingPage"
                >
                  {i18next.t(
                    "An IBAN account is a cheap and easy way to arrange payments, but it´s not easy to open IBAN accounts in case you aren´t resident of the European Community - and even being a resident applying for an IBAN account in a traditional bank might be time-consuming and means a lot of paperwork."
                  )}
                </Typography>
              </Box>
              <Box sx={{ marginTop: "4rem" }}>
                <Typography
                  variant="h3"
                  sx={{
                    marginBottom: "1rem",
                  }}
                  className="headlineMiniLandingPages"
                >
                  {i18next.t("Effortless IBAN Accounts with Extra Features")}
                </Typography>
                <Typography
                  variant="body1"
                  paragraph
                  className="textsLandingPage"
                >
                  {i18next.t(
                    "Since we are a fully regulated and licensed Electronic Money Institution we are able to open IBAN accounts quickly and without bureaucracy"
                  )}
                </Typography>
              </Box>
              <Box
                sx={{
                  marginTop: "4rem",
                  marginBottom: "4rem",
                  display: "grid",
                  gridTemplateColumns: ["1fr", "1fr 1fr", "1fr 1fr 1fr"],
                  gap: [2, 5],
                }}
              >
                <Box>
                  <TitleTextVvidget
                    showBoxStyle={true}
                    title={i18next.t("Discover IBAN Account Benefits")}
                    text={i18next.t(
                      "Experience the advantages of an IBAN accounts for you or your company"
                    )}
                  />
                </Box>
                <Box>
                  <TitleTextVvidget
                    showBoxStyle={true}
                    title={i18next.t("Send Multi-Currency Funds")}
                    text={i18next.t("Send funds in different currencies")}
                  />
                </Box>
                <Box>
                  <TitleTextVvidget
                    showBoxStyle={true}
                    title={i18next.t("Seamless SEPA Transfers")}
                    text={i18next.t(
                      "Make seamless SEPA transfers using your dedicated IBAN"
                    )}
                  />
                </Box>
                <Box>
                  <TitleTextVvidget
                    showBoxStyle={true}
                    title={i18next.t("Instant VicPay to IBAN Transfers")}
                    text={i18next.t(
                      "Send money instantly from your VicPay account to your IBAN account"
                    )}
                  />
                </Box>
                <Box>
                  <TitleTextVvidget
                    showBoxStyle={true}
                    title={i18next.t("Save and Schedule Transfers")}
                    text={i18next.t("Save and schedule regular transfers")}
                  />
                </Box>
              </Box>
              <Box>
                <TitleTextVvidget
                  title={
                    <>
                      {i18next.t("Your business grows?")} <br />
                      {i18next.t("Do you need more than one IBAN account?")}
                    </>
                  }
                  text={
                    <>
                      {i18next.t(
                        "No problem! With VicPay you can increase the amount of your IBAN accounts easily up to 10 accounts!"
                      )}{" "}
                      <br />
                      {i18next.t(
                        "You can easily connect your VicPay VISA card with your IBAN account to save even more time, money and effort on transactions!"
                      )}
                    </>
                  }
                />
              </Box>
              <Box sx={{ marginTop: "3rem" }}>
                <Typography
                  variant="h3"
                  sx={{
                    marginBottom: "1rem",
                  }}
                  className="headlineMiniLandingPages"
                >
                  {i18next.t("Want to know more details? Contact us!")}
                </Typography>
                <Typography
                  variant="body1"
                  paragraph
                  className="textsLandingPage"
                >
                  {i18next.t("Dial +1 604-260-0738 or")}{" "}
                  <span
                    style={{ marginLeft: "5px" }}
                    className="linksHover"
                    onClick={() => navigate("/contact-us")}
                  >
                    {i18next.t("click here")}
                  </span>{" "}
                  {i18next.t("for all contact options!")}
                </Typography>
              </Box>
            </Box>
          </Box>
        </ThemeProvider>
      </div>
      <Box sx={{ marginTop: "4rem" }}>
        <FooterLanding />
      </Box>
    </div>
  );
};

export default IbanAccounts;
