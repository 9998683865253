import { ThemeProvider, createTheme } from "@mui/material";
import { useEffect, useState } from "react";
import Crypto from "./Crypto";
import api from "src/store/interceptors/api";
import "./AmountStep.scss";
import { useNavigate } from "react-router-dom";
import Calculation from "./Calculation";

const defaultTheme = createTheme();

interface Props {
  quest: any;
  accountData: any;
  setStep: (value: number) => void;
  step: number;
  setQuest: (value: any) => void;
  transactionType: any;
  handleRequestVerification: (value: any) => void;
  navigate: ReturnType<typeof useNavigate>;
}

function AmountStep({
  quest,
  accountData,
  setStep,
  step,
  setQuest,
  handleRequestVerification,
  transactionType,
  navigate,
}: Props) {
  const [currencies, setCurrencies] = useState(null);
  const getInitialData = async () => {
    await api
      .get("/profile/balances")
      .then((response: any) => {
        setCurrencies(response.data);
      })
      .catch((error) => {
        console.error(error.message);
      });
  };

  useEffect(() => {
    getInitialData();
  }, []);

  const getAnimationClass = () => {
    return transactionType === "International" ? "moveInLeft" : "moveInRight";
  };
  return (
    <div className="amountMain">
      <ThemeProvider theme={defaultTheme}>
        <div
          className={`animated-text ${
            transactionType ? "content-visible" : ""
          } ${getAnimationClass()}`}
          key={transactionType}
        >
          {transactionType === "Crypto" && (
            <>
              {currencies && (
                <Crypto
                  navigate={navigate}
                  step={step}
                  setStep={handleRequestVerification}
                  setQuest={setQuest}
                  currencies={currencies}
                />
              )}
            </>
          )}
          {(transactionType === "International" ||
            transactionType === "Same Currency") && (
            <>
              {currencies && (
                <Calculation
                  navigate={navigate}
                  step={step}
                  setStep={setStep}
                  handleRequestVerification={handleRequestVerification}
                  setQuest={setQuest}
                  quest={quest}
                  accountData={accountData}
                  currencies={currencies}
                  transactionType={transactionType}
                />
              )}
            </>
          )}
        </div>
      </ThemeProvider>
    </div>
  );
}
export default AmountStep;
