import { useScrollTrigger } from "@mui/material";
import React, { useState, useEffect } from "react";

interface SlideAnimationProps {
  children: React.ReactNode;
  threshold?: number;
  direction?: "up" | "down" | "left" | "right";
}

const SlideAnimation: React.FC<SlideAnimationProps> = ({
  children,
  threshold,
  direction = "down",
}: SlideAnimationProps) => {
  const [effectApplied, setEffectApplied] = useState<boolean>(false);

  const trigger = useScrollTrigger({
    threshold,
  });

  const getTranslateValue = () => {
    switch (direction) {
      case "up":
        return `translateY(${effectApplied ? 0 : "-100%"})`;
      case "down":
        return `translateY(${effectApplied ? 0 : "100%"})`;
      case "left":
        return `translateX(${effectApplied ? 0 : "-100%"})`;
      case "right":
        return `translateX(${effectApplied ? 0 : "100%"})`;
      default:
        return "";
    }
  };

  useEffect(() => {
    if (trigger) {
      setEffectApplied(true);
    }
  }, [trigger]);

  return (
    <div
      style={{
        transform: getTranslateValue(),
        transition: "transform 0.4s ease-out",
        width: "100%",
      }}
    >
      {children}
    </div>
  );
};

export default SlideAnimation;
