import { Box, Typography } from "@mui/material";
import "../../pages/LandingPagesGlobal.scss";

const LogoTextVvidget = ({ text }: any) => {
  return (
    <>
      <Box
        sx={{
          padding: "112px 0 176px 0",
          "@media (max-width: 1023px)": {
            padding: "40px 0 80px 0",
          },
        }}
      >
        <Box className="logoPayVvidget"></Box>
        <Box>
          <Typography
            variant="body1"
            paragraph
            sx={{ maxWidth: "1028px", margin: "auto", textAlign: "center" }}
            className="textsLandingPage"
          >
            {" "}
            {text}
          </Typography>
        </Box>
      </Box>
    </>
  );
};

export default LogoTextVvidget;
