import React from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Box } from "@mui/material";
import HeaderLanding from "../components/HeaderLanding/HeaderLanding";
import { useAppSelector } from "src/store";
import FooterLanding from "../components/FooterLanding/FooterLanding";
import "./LandingPagesGlobal.scss";
import TitleBackBox from "../components/TitleBackBox/TitleBackBox";
import HeadlineVvidgetSmall from "../components/HeadlineVvidget/HeadlineVvidgetSmall";
import i18next from "i18next";

const defaultTheme = createTheme();

const VictorumGroup: React.FC = () => {
  const { user } = useAppSelector((state) => state.auth);

  const navigateToVicGroup = (event: any) => {
    window.open("https://victorum-group.com/", "_blank");
  };

  return (
    <div>
      <HeaderLanding user={user} />
      <div className="margintTopPagesMainCont">
        <ThemeProvider theme={defaultTheme}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Box className="titleHeadlineSmallBG">
              <Box className="landingPagesMainBox">
                <Box>
                  <TitleBackBox title={i18next.t("Victorum Group")} />
                </Box>
                <Box>
                  <HeadlineVvidgetSmall
                    headline={
                      i18next.t("We are part of a strong worldwide network with 32 branches in 17 countries")
                    }
                    subtitle={
                      i18next.t("Join Our Global Network, 32 Branches in 17 Countries")
                    }
                    smallText={i18next.t("Learn more about our global network here")}
                    buttonText={i18next.t("Visit Victorum Group")}
                    onClickFunction={navigateToVicGroup}
                  />
                </Box>
              </Box>
            </Box>
          </Box>
        </ThemeProvider>
      </div>
      <Box sx={{ marginTop: "4rem" }}>
        <FooterLanding />
      </Box>
    </div>
  );
};

export default VictorumGroup;
