import axios from "axios";
import i18next from "i18next";
import { toast } from "react-toastify";

const currentSession = () => {
  let bearerToken = null;
  const storageUser = localStorage.getItem("victorumuser") || null;
  const storageUserToken = localStorage.getItem("token") || null;

  if (storageUser) {
    const initialAuthState = JSON.parse(storageUser);
    bearerToken = initialAuthState.access_token ?? null;
  }

  if (storageUserToken) {
    bearerToken = storageUserToken;
  }

  return bearerToken;
};
const api = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}`,
  timeout: 150000,
});

// Request interceptor for adding the bearer token

api.interceptors.request.use(async (config: any) => {
  try {
    if (config.method !== "get") {
      document.body.classList.add("loading-indicator");
    }
    const jwtToken = currentSession();
    if (jwtToken) {
      return {
        ...config,
        headers: {
          ...config.headers,
          Authorization: `Bearer ${jwtToken}`,
        },
      };
    } else {
      return config;
    }
  } catch (_e) {
    return config;
  }
});

api.interceptors.response.use(
  function (response) {
    document.body.classList.remove("loading-indicator");
    return response;
  },
  function (error) {
    document.body.classList.remove("loading-indicator");
    if (error.response?.status === 401) {
      toast.error(i18next.t("Unauthorized. Please try again!"));

      setTimeout(() => {
        localStorage.removeItem("victorumuser");
        localStorage.removeItem("token");
        localStorage.removeItem("isModalShown");
        // window.location.href = "/login";
      }, 900);
    } else {
      //console.log(error.response);
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        toast.error(error.response.data.message);
      } else {
        if (
          error.response &&
          error.response.data &&
          error.response.data.error
        ) {
          toast.error(error.response.data.error);
        } else {
          toast.error(i18next.t("Something went wrong!"));
        }
      }
    }
    return Promise.reject(error);
  }
);

export default api;
