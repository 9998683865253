import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import "./Admin.scss";
import Nav from "src/components/layout/nav/Nav";
import { Box, Typography } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import UsersBulkMessages from "src/components/Users/BulkMessages/UsersBulkMessages";
import ActionsTab from "./Actions/Actions";
import AdminDashboard from "./AdminDashboard/AdminDashboard";

const defaultTheme = createTheme();

export default function Admin() {
  const navigate = useNavigate();
  const location = useLocation();

  const getTabName = () => {
    const path = location.pathname.split("/")[2];
    switch (path) {
      case "statistics":
        return "Statistics";
      case "actions":
        return "Actions";
      case "messages":
        return "Send Messages";
      default:
        return "Statistics";
    }
  };

  // tabs start
  const [selectedTab, setSelectedTab] = useState(getTabName());

  const handleTabClick = (tabName: string) => {
    const tabRoutes: any = {
      Statistics: "/admin/statistics",
      Actions: "/admin/actions",
      "Send Messages": "/admin/messages",
    };
    navigate(tabRoutes[tabName]);
  };
  // tabs end

  useEffect(() => {
    setSelectedTab(getTabName());
  }, [location.pathname]);

  const [selected, setSelected] = useState("Home");

  const handleNavigate = () => {
    navigate("/home");
  };

  return (
    <div>
      <Nav step={0} isStepperVisible={false} isProfileSetVisible={true} />

      <div className="main-admin-container">
        <div className="content-container-admin main-color">
          <ThemeProvider theme={defaultTheme}>
            <Container component="main">
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <div className="d-flex ai-center mb-3-rem">
                  <p
                    className="back-button-icon circle-around-text-smaller helvetica mr-1-rem cp"
                    onClick={handleNavigate}
                  >
                    <ArrowBackIcon className="muiIcons" />
                  </p>

                  <Typography
                    className="your-details-text d-flex jc-start pageTitleFontSize"
                    component="h1"
                    variant="h5"
                    sx={{
                      fontFamily: "Helvetica, sans-serif",
                      textAlign: "center",
                      fontWeight: "600",
                    }}
                  >
                    {selected === "Home" ? "Dashboard" : selected}
                  </Typography>
                </div>

                <div
                  className="d-flex ai-center"
                  style={{ marginBottom: "32px", marginLeft: "12px" }}
                >
                  <Typography
                    className="your-details-text d-flex jc-start oneRemFontSize"
                    component="h1"
                    variant="h5"
                    sx={{
                      fontFamily: "Helvetica, sans-serif",
                      textAlign: "center",
                      fontWeight: "600",
                      marginRight: "2rem",
                      color:
                        selectedTab === "Statistics" ? "#2A5182" : "inherit",
                      cursor: "pointer",
                      backgroundColor:
                        selectedTab === "Statistics" ? "#16330014;" : "none",
                      "&:hover": {
                        backgroundColor: "rgb(244, 246, 248)",
                      },
                      padding: "0.6rem",
                      borderRadius: "16px",
                    }}
                    onClick={() => handleTabClick("Statistics")}
                  >
                    Dashboard
                  </Typography>

                  <Typography
                    className="your-details-text d-flex jc-start oneRemFontSize"
                    component="h1"
                    variant="h5"
                    sx={{
                      fontFamily: "Helvetica, sans-serif",
                      textAlign: "center",
                      fontWeight: "600",
                      marginRight: "2rem",
                      color: selectedTab === "Actions" ? "#2A5182" : "inherit",
                      cursor: "pointer",
                      backgroundColor:
                        selectedTab === "Actions" ? "#16330014;" : "none",
                      padding: "0.6rem",
                      borderRadius: "16px",
                      "&:hover": {
                        backgroundColor: "rgb(244, 246, 248)",
                      },
                    }}
                    onClick={() => handleTabClick("Actions")}
                  >
                    Actions
                  </Typography>
                  <Typography
                    className="your-details-text d-flex jc-start oneRemFontSize"
                    component="h1"
                    variant="h5"
                    sx={{
                      fontFamily: "Helvetica, sans-serif",
                      textAlign: "center",
                      fontWeight: "600",
                      marginRight: "2rem",
                      color:
                        selectedTab === "Send Messages" ? "#2A5182" : "inherit",
                      cursor: "pointer",
                      backgroundColor:
                        selectedTab === "Send Messages" ? "#16330014;" : "none",
                      padding: "0.6rem",
                      borderRadius: "16px",
                      "&:hover": {
                        backgroundColor: "rgb(244, 246, 248)",
                      },
                    }}
                    onClick={() => handleTabClick("Send Messages")}
                  >
                    Send messages
                  </Typography>
                </div>
              </Box>

              {selectedTab === "Statistics" && <AdminDashboard />}
              {selectedTab === "Actions" && <ActionsTab />}
              {selectedTab === "Send Messages" && <UsersBulkMessages />}
            </Container>
          </ThemeProvider>
        </div>
      </div>
    </div>
  );
}
