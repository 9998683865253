import { Box } from "@mui/material";
import "./VideoParallax.scss";

const Video = require("../../../assets/videoParallax.mp4") as string;

export default function VideoParlax() {
  return (
    <Box className="parallaxVideo">
      <video autoPlay muted loop playsInline>
        <source src={Video} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
    </Box>
  );
}
