import {
  Avatar,
  Box,
  Button,
  Divider,
  InputAdornment,
  TextField,
  Typography,
  createTheme,
} from "@mui/material";
import "./RecipientStep.scss";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import api from "src/store/interceptors/api";
import { useEffect, useState } from "react";
import { ThemeProvider } from "@emotion/react";
import SearchIcon from "@mui/icons-material/Search";
import LoadingIndicator from "../shared/LoadingIndicator";
import i18next from "i18next";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

interface Props {
  setStep: (value: number) => void;
  step: number;
  quest: any;
  goToNextStep: any;
  setAccountData: (values: any) => void;
}

interface SavedUser {
  account_holder: string;
  currency: string;
  iban: string;
  type: string;
  reference_id: number;
  account_number: string;
  sort_code: string;
  meta?: any;
}

const theme = createTheme({
  components: {
    MuiInputBase: {
      styleOverrides: {
        input: {
          "&::placeholder": {
            fontSize: "16px",
            color: "#000000",
          },
        },
      },
    },
  },
});

function WhoAreYourSendingMoneyToStep({
  step,
  setStep,
  quest,
  setAccountData,
  goToNextStep,
}: Props) {
  const [savedUser, setSavedUser] = useState<SavedUser[]>([]);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(5);
  const [totalPages, setTotalPages] = useState(1);
  const [searchValue, setSearchValue] = useState("");
  const [loading, setLoading] = useState(false);

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(event.target.value);
    setPage(1);
  };

  const handleLoadMore = () => {
    setPage(1);
    setPerPage((prevPerPage: any) => prevPerPage * 2);
  };

  const getSavedUsers = async () => {
    try {
      setLoading(true);
      const response = await api.get(
        `/recipients?currency=${quest.targetCurrency}&page=${page}&per_page=${perPage}&search[account_holder]=${searchValue}`
      );
      const { data, meta } = response.data;
      setLoading(false);
      if (page === 1) {
        setSavedUser(data);
      } else {
        setSavedUser((prevData) => [...prevData, ...data]);
      }
      setTotalPages(meta?.last_page || 1);
    } catch (error) {
      setLoading(false);
    }
  };
  useEffect(() => {
    getSavedUsers();
  }, [searchValue, page, perPage]);

  const handleUserSelection = (selectedUser: SavedUser) => {
    if (selectedUser && selectedUser.meta) {
      setAccountData(selectedUser);
      setStep(step + 1);
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <div className="mainFirstSubStep">
        <Box className="firstSubStepBox">
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              width: "100%",
            }}
          >
            <p
              className="back-button-icon circle-around-text-smaller helvetica mr-1-rem cp"
              onClick={() => setStep(0)}
            >
              <ArrowBackIcon className="muiIcons" />
            </p>
            <Typography className="whoAreYouText">
              {i18next.t("Who are you sending money to?")}
            </Typography>
          </Box>
          <TextField
            placeholder={i18next.t("Name")}
            style={{ width: "100%", marginTop: "24px" }}
            value={searchValue}
            onChange={handleSearchChange}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon className="searchIcon" />
                </InputAdornment>
              ),
              sx: {
                borderRadius: "40px",
                height: "48px",
                fontSize: "16px",
                "&:-internal-autofill-selected": {
                  backgroundColor: "transparent !important",
                },
              },
            }}
          ></TextField>
          <Box className="addRecipientBox" onClick={goToNextStep}>
            <Box className="addRecipientInfo">
              <Box className="avatarAndAddText">
                <Avatar
                  style={{ width: 48, height: 48, backgroundColor: "#E5E8E2" }}
                ></Avatar>
                <Typography className="addRecipientText">
                  {i18next.t("Add a recipient")}
                </Typography>
              </Box>
              <Box>
                <NavigateNextIcon className="arrayRightIcon" />
              </Box>
            </Box>
          </Box>
          {loading ? (
            <LoadingIndicator />
          ) : (
            <Box className="recentBox">
              {savedUser.length === 0 ? (
                <Typography className="noDataText">
                  {i18next.t("No data")}
                </Typography>
              ) : (
                <Box>
                  <Typography className="recentText">
                    {i18next.t("All")}
                  </Typography>
                  <Divider style={{ marginBottom: 8 }} />
                  <Box className="allRecipientMain">
                    {savedUser.map((item, index) => (
                      <Box
                        key={index}
                        className="allRecipientSingle"
                        onClick={() => handleUserSelection(item)}
                      >
                        <Box className="allRecipientInfo">
                          <Box className="avatarAndNameEndingIn">
                            <Avatar
                              style={{
                                width: 48,
                                height: 48,
                                backgroundColor: "#E5E8E2",
                              }}
                            >
                              {item.account_holder && (
                                <>
                                  <span className="spanAvatar">
                                    {item.account_holder
                                      .split(" ")
                                      .map((namePart, index) =>
                                        index < 2
                                          ? namePart.charAt(0).toUpperCase()
                                          : ""
                                      )
                                      .join("")}
                                  </span>
                                </>
                              )}
                            </Avatar>
                            <Box className="nameAndEndingIn">
                              <Typography className="allRecipientName">
                                {item.account_holder}
                              </Typography>
                              {item.type === "iban" ? (
                                <Typography className="allRecipientEndingIn">
                                  {item.currency}{" "}
                                  {i18next.t("account ending in")}{" "}
                                  {item.iban ? item.iban.slice(-4) : "/"}
                                </Typography>
                              ) : (
                                <Typography className="allRecipientEndingIn">
                                  {item.currency}{" "}
                                  {i18next.t("account ending in")}{" "}
                                  {item.account_number
                                    ? item.account_number.slice(-4)
                                    : "/"}
                                </Typography>
                              )}
                            </Box>
                          </Box>
                          <Box>
                            <NavigateNextIcon className="arrayRightIcon" />
                          </Box>
                        </Box>
                      </Box>
                    ))}
                  </Box>
                </Box>
              )}
            </Box>
          )}
          {page < totalPages && !loading && (
            <Button
              className="loadMoreBtn"
              variant="outlined"
              onClick={handleLoadMore}
            >
              {i18next.t("Load More")}
            </Button>
          )}
        </Box>
      </div>
    </ThemeProvider>
  );
}

export default WhoAreYourSendingMoneyToStep;
