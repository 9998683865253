import { useFormik } from "formik";
import { useState } from "react";
import * as Yup from "yup";
import {
  Autocomplete,
  Box,
  Checkbox,
  FormControlLabel,
  TextField,
  Typography,
} from "@mui/material";
import "./AmountStep.scss";
import i18next from "i18next";
import BackNextBtns from "../shared/BackNextBtns/BackNextBtns";
import { useNavigate } from "react-router-dom";

interface Props {
  setStep: (value: number) => void;
  step: number;
  currencies: any;
  setQuest: (value: any) => void;
  navigate: ReturnType<typeof useNavigate>;
}

function SameCurrency({
  currencies,
  step,
  setStep,
  setQuest,
  navigate,
}: Props) {
  const [senderCurrency, setSenderCurrency] = useState(currencies[0]);
  const [errorMsg, setErrorMsg] = useState<string | null>(null);

  const handleSenderCurrencyChange = (event: any, newValue: any) => {
    setSenderCurrency(newValue ? newValue : null);
    setErrorMsg(null);

    formik.setFieldValue("senderCurrency", newValue.currency);
    formik.setFieldValue("recipientCurrency", newValue.currency);
  };

  const formik = useFormik({
    initialValues: {
      senderAmount: "",
      senderCurrency: senderCurrency.currency,
      recipientAmount: "",
      recipientCurrency: senderCurrency.currency,
      country_code: "",
    },
    onSubmit: async (values: any) => {
      setErrorMsg(null);
      setQuest({
        sourceCurrency: senderCurrency.currency,
        targetCurrency: senderCurrency.currency,
        formated: {
          sourceCurrency: senderCurrency.currency,
          targetCurrency: senderCurrency.currency,
        },
      });
      setStep(step + 1);
    },
    validationSchema: Yup.object({
      senderCurrency: Yup.string().required(
        i18next.t("Sender Currency is required.")
      ),
      recipientCurrency: Yup.string().required(
        i18next.t("Recepient currency is required.")
      ),
    }),
  });

  return (
    <>
      <div>
        <Typography className="labelsInternational">
          {i18next.t("From currency")}
        </Typography>
        <Autocomplete
          id="currency-select"
          sx={{
            marginBottom: "16px",
            "& .MuiOutlinedInput-root": {
              borderRadius: "10px",
            },
          }}
          options={currencies}
          value={senderCurrency}
          getOptionLabel={(option) => option.currency}
          onChange={handleSenderCurrencyChange}
          renderOption={(props, option) => (
            <Box
              component="li"
              {...props}
              className="d-flex ai-center mt-1-rem cp"
            >
              <p className="normal-text" style={{ marginLeft: 10 }}>
                {option.currency}
              </p>
            </Box>
          )}
          renderInput={(params) => <TextField {...params} />}
          clearIcon={null}
        />

        {errorMsg && <Typography className="ErrorMsg">{errorMsg}</Typography>}
        <div className="d-flex ai-center jusifyContentSpaceBetween mt-8">
          <BackNextBtns
            backText={i18next.t("Cancel")}
            nextText={i18next.t("Continue")}
            isNextDisabled={!formik.values.senderCurrency || errorMsg}
            backType="button"
            nextType="submit"
            onBackClick={() => navigate(-1)}
            onNextClick={(e: any) => formik.handleSubmit(e)}
          />
        </div>
      </div>
    </>
  );
}

export default SameCurrency;
