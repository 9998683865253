import React, { useCallback } from "react";
import {
  Button,
  TextField,
  Typography,
  Select,
  InputLabel,
  MenuItem,
  Box,
} from "@mui/material";
import { useFormik } from "formik";
import api from "src/store/interceptors/api";
import { toast } from "react-toastify";
import "./RecipientStep.scss";
import i18next from "i18next";
import { debounce } from "lodash";
import BackNextBtns from "../shared/BackNextBtns/BackNextBtns";
import { translateProvider } from "src/utils/helpers";
import { COUNTRIES } from "../shared/constants";

interface Props {
  setStep: (value: number) => void;
  step: number;
  quest: any;
  setAccountData: (values: any) => void;
  methodItem?: any;
  setSubStep: (value: number) => void;
}

function DynamicPaymentForm3S({
  step,
  setStep,
  quest,
  setAccountData,
  methodItem,
  setSubStep,
}: Props) {
  const formik = useFormik({
    enableReinitialize: false,
    initialValues: {
      ...methodItem.fieldsForm,
      currency: quest.targetCurrency,
      type: methodItem.type,
      profile: process.env.REACT_APP_WISE_PROFILE_ID,
      ownedByCustomer: true,
      legalType: "PRIVATE",
      accountDetailsProvider: null,
      validAccount: null,
    },
    onSubmit: async (values: any) => {
      const tmpValues = { ...values };
      let blnContinue: boolean = true;

      if (!values.validAccount) {
        alert(i18next.t("Please check the bank details!"));
        return true;
      }

      const { currency, type, ownedByCustomer, profile, ...detailsData } =
        tmpValues;

      /*
    Account name: Victorum Capital 1
IBAN: GB14 MOLU 0099 2800 0424 82
Bank SWIFT code: MOLUGB22
Bank name: 3S Money Club
Bank Institution Address: 45 Folgate St, London, E1 6GL
Bank country: United Kingdom
Currency: EUR
*/
      const payload = {
        currency: currency,
        currencySource:
          quest && quest.sourceCurrency ? quest.sourceCurrency : currency,
        profile: parseFloat(profile),
        legalEntityType: "PERSON",
        type: methodItem.name ?? type,
        ownedByCustomer: ownedByCustomer,
        details: { ...detailsData },
      };

      if (!blnContinue) {
        alert(i18next.t("Please fill the payment details!"));
        return true;
      }

      await api
        .post("/recipients", payload)
        .then((res) => {
          if (res.data?.errors) {
            const apiErrors = res.data.errors.reduce((acc: any, error: any) => {
              acc[error.path] = error.message;
              return acc;
            }, {});

            formik.setErrors(apiErrors);

            const numberOfKeys = Object.keys(apiErrors).length;
            if (numberOfKeys === 1) {
              const valuesArray = Object.values(apiErrors);
              const firstValue = valuesArray[0];
              toast.error(firstValue as string);
            }
          } else {
            //console.log('snimi account', res.data);
            let blnContinue = false;
            if (res.data && res.data.data) {
              setAccountData(res.data.data);
              blnContinue = true;
            } else {
              if (res.data) {
                setAccountData(res.data);
                blnContinue = true;
              }
            }
            if (blnContinue) setStep(step + 1);
          }
        })
        .catch((error: any) => {
          console.log("account err", error);
        });
    },
    validate: (values) => {
      const errors: any = {};
      Object.keys(values).forEach((key) => {
        if (!values[key]) {
          errors[key] = i18next.t("This field is required");
        }
      });

      if (values && !values?.validAccount) {
        errors.accountNumber = i18next.t("Bank details are not valid!");
      }
      if (values && values.address) {
        if (!errors.address) {
          errors.address = {};
        }

        if (!values?.address?.city) {
          errors.address.city = i18next.t("City is required");
        }
        if (!values?.address?.country) {
          errors.address.country = i18next.t("Country is required");
        }
        if (!values?.address?.firstLine) {
          errors.address.firstLine = i18next.t("First line is required");
        }
        if (!values?.address?.postCode) {
          errors.address.postCode = i18next.t("Post code is required");
        }

        if (errors.address) {
          if (
            Object.values(errors.address).length === 0 &&
            errors.address.constructor === Object
          ) {
            delete errors.address;
          }
        }
      }

      return errors;
    },
  });

  const validateIban = async (iban: string, e: any) => {
    try {
      const response = await api.get("/provider/bank-info/iban/" + iban);
      let blnValid = false;
      if (response) {
        if (response && response.data && response.data.ibanFormatValidation) {
          const ibanValidation = response.data;
          if (
            ibanValidation.ibanFormatValidation.isValidCountry &&
            ibanValidation.ibanFormatValidation.isValidFormat &&
            ibanValidation.ibanFormatValidation.isValidChecksum
          ) {
            blnValid = true;
            formik.setFieldValue("accountDetailsProvider", ibanValidation);
            formik.setFieldValue("validAccount", true);
            formik.setFieldError(e.target.name, "");
          }
        }

        if (!blnValid) {
          formik.setFieldError(e.target.name, "Invalid IBAN number");
          formik.setFieldValue("validAccount", false);
        }
        // handle valid IBAN (e.g., set a valid flag in formik state or display a success message)
      } else {
        // handle invalid IBAN (e.g., set an error message in formik state)
        formik.setFieldError(e.target.name, "Invalid IBAN number");
        formik.setFieldValue("validAccount", false);
      }
    } catch (error) {
      // handle API call error
      formik.setFieldError(e.target.name, "Error validating IBAN number");
      formik.setFieldValue("validAccount", false);
    }
  };

  const debouncedValidateIban = useCallback(
    debounce((iban, e) => {
      validateIban(iban, e);
    }, 500),
    []
  );

  const handleIbanChange = (e: any) => {
    formik.handleChange(e);
    const iban = e.target.value;
    if (iban.length >= 5) {
      debouncedValidateIban(iban, e);
    }
  };

  return (
    <div>
      {methodItem &&
        methodItem.fields &&
        methodItem.fields.map((fieldItem: any, fieldKey: number) => {
          if (fieldItem.required && fieldItem.required === "optional" && fieldItem.id !== 'account-number')
            return null;
          const fieldName: string = fieldItem.id;
          const isIbanField: boolean =
            fieldItem.ibanSearch ||
            fieldItem.key === "accountNumber" ||
            fieldItem.id === "iban" ||
            fieldItem.id === "account-number"
              ? true
              : false;

          if (fieldItem.id === "country") {
            return (
              <div
                style={{ paddingTop: 5, paddingBottom: 5 }}
                key={"fieldKey" + fieldKey + (methodItem.title ?? "")}
              >
                <InputLabel
                  id={fieldItem.key + "label"}
                  className="oneRemFontSize"
                  style={{
                    marginBottom: "8px",
                    marginTop: "8px",
                    color: "#000000",
                  }}
                >
                  {translateProvider(i18next.t(fieldItem.name))}
                </InputLabel>
                <Select
                  labelId={fieldName + "label"}
                  id={fieldName}
                  name={fieldName}
                  style={{ width: "100%" }}
                  sx={{
                    borderRadius: "10px",
                    height: "48px",
                    "& .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input":
                      {
                        padding: "16.5px 14px 16.5px 14px",
                      },
                    "@media (max-width: 600px)": {
                      height: "42px",
                      fontSize: "14px",
                    },
                  }}
                  error={
                    formik.touched[fieldName.split(".")[0]] &&
                    (formik.errors[fieldName.split(".")[0]] as any)?.[
                      fieldName.split(".")[1]
                    ]
                  }
                  defaultValue={
                    formik.initialValues &&
                    formik.initialValues[fieldName] !== undefined
                      ? formik.initialValues[fieldName]
                      : ""
                  }
                  onChange={formik.handleChange}
                >
                  {COUNTRIES.map(
                      (menuItem: any, menuIndex: any) => (
                        <MenuItem
                          key={"country" + fieldName + menuIndex}
                          value={menuItem.code}
                        >
                          {i18next.t(menuItem.label)}
                        </MenuItem>
                      )
                    )}
                </Select>

                {formik.touched[fieldName] && formik.errors[fieldName] && (
                  <Typography
                    className="amountErrorForm"
                    style={{ marginTop: 8 }}
                  >
                    {formik.errors[fieldName]!.toString()}
                  </Typography>
                )}
              </div>
            );
          } else if (
            fieldItem.type &&
            (fieldItem.type === "select" || fieldItem.type === "radio")
          ) {
            //console.log("fieldKey " + fieldKey + (methodItem.title ?? ""));
            return (
              <div
                style={{ paddingTop: 5, paddingBottom: 5 }}
                key={"fieldKey" + fieldKey + (methodItem.title ?? "")}
              >
                <InputLabel
                  id={fieldItem.key + "label"}
                  className="oneRemFontSize"
                  style={{
                    marginBottom: "8px",
                    marginTop: "8px",
                    color: "#000000",
                  }}
                >
                  {translateProvider(i18next.t(fieldItem.name))}
                </InputLabel>
                <Select
                  labelId={fieldName + "label"}
                  id={fieldName}
                  name={fieldName}
                  style={{ width: "100%" }}
                  sx={{
                    borderRadius: "10px",
                    height: "48px",
                    "& .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input":
                      {
                        padding: "16.5px 14px 16.5px 14px",
                      },
                    "@media (max-width: 600px)": {
                      height: "42px",
                      fontSize: "14px",
                    },
                  }}
                  error={
                    formik.touched[fieldName.split(".")[0]] &&
                    (formik.errors[fieldName.split(".")[0]] as any)?.[
                      fieldName.split(".")[1]
                    ]
                  }
                  defaultValue={
                    formik.initialValues &&
                    formik.initialValues[fieldName] !== undefined
                      ? formik.initialValues[fieldName]
                      : ""
                  }
                  // label={fieldItem.name}
                  onChange={formik.handleChange}
                >
                  {fieldItem.valuesAllowed &&
                    fieldItem.valuesAllowed.length > 0 &&
                    fieldItem.valuesAllowed.map(
                      (menuItem: any, menuIndex: any) => (
                        <MenuItem
                          key={"country" + fieldName + menuIndex}
                          value={menuItem.key}
                        >
                          {i18next.t(menuItem.name)}
                        </MenuItem>
                      )
                    )}
                </Select>

                {formik.touched[fieldName] && formik.errors[fieldName] && (
                  <Typography
                    className="amountErrorForm"
                    style={{ marginTop: 8 }}
                  >
                    {formik.errors[fieldName]!.toString()}
                  </Typography>
                )}
              </div>
            );
          } else {
            return (
              <div
                style={{ paddingTop: 5, paddingBottom: 5 }}
                key={"fieldKey" + fieldKey + (methodItem.title ?? "")}
              >
                <Typography className="labelsLocalIban">
                  {translateProvider(i18next.t(fieldItem.name))}
                </Typography>
                <TextField
                  style={{ width: "100%" }}
                  type="text"
                  placeholder={i18next.t(fieldItem.example) ?? ""}
                  error={
                    formik.touched[fieldName] &&
                    (formik.errors[fieldName] as any)?.[fieldName]
                  }
                  name={fieldName}
                  onChange={
                    isIbanField ? handleIbanChange : formik.handleChange
                  }
                  variant="outlined"
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      paddingRight: "0px!important",
                    },
                  }}
                  InputProps={{
                    sx: {
                      borderRadius: "10px",
                      height: "48px",
                      "@media (max-width: 600px)": {
                        fontSize: "14px",
                        height: "42px",
                      },
                    },
                  }}
                />

                {formik.touched[fieldName] && formik.errors[fieldName] && (
                  <Typography
                    className="amountErrorForm"
                    style={{ marginTop: 8 }}
                  >
                    {formik.errors[fieldName]!.toString()}
                  </Typography>
                )}

                {isIbanField &&
                  formik.values.validAccount &&
                  formik.values.accountDetailsProvider && (
                    <Box
                      sx={{
                        margin: "5px 0px",
                        background: "#fff",
                        border: "1px solid #ededed",
                        padding: "10px",
                        borderRadius: "5px",
                      }}
                    >
                      <Typography
                        className="labelsLocalIban"
                        style={{ fontWeight: "bold" }}
                      >
                        {i18next.t("Bank details")}
                      </Typography>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                        }}
                      >
                        <Typography className="labelsLocalIban">
                          {i18next.t("Bank name")}
                        </Typography>
                        <Typography className="labelsLocalIban">
                          {formik.values.accountDetailsProvider.bankName ??
                            " - "}
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                        }}
                      >
                        <Typography className="labelsLocalIban">
                          {i18next.t("Bank country")}
                        </Typography>
                        <Typography className="labelsLocalIban">
                          {formik.values.accountDetailsProvider.bankCountry
                            .name ?? " - "}
                        </Typography>
                      </Box>
                    </Box>
                  )}
              </div>
            );
          }
        })}

      <div className="d-flex ai-center jusifyContentSpaceBetween mt-8">
        <BackNextBtns
          backText={i18next.t("Back")}
          nextText={i18next.t("Continue")}
          isNextDisabled={
            !formik.isValid || formik.isSubmitting || !formik.dirty
          }
          backType="button"
          nextType="submit"
          onBackClick={() => setSubStep(0)}
          onNextClick={(e: any) => formik.handleSubmit(e)}
        />
      </div>
    </div>
  );
}

export default DynamicPaymentForm3S;
