import { Typography, Box, Divider } from "@mui/material";
import { useNavigate } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import i18next from "i18next";

const TitleBackBox = ({ title }: any) => {
  const navigate = useNavigate();

  const handleNavigate = () => {
    navigate(-1);
  };

  return (
    <>
      <Box
        sx={{
          marginBottom: "112px",
          "@media (max-width: 1023px)": {
            marginBottom: "84px",
          },
          "@media (max-width: 767px)": {
            marginBottom: "28px",
          },
        }}
      >
        <Box
          onClick={handleNavigate}
          sx={{
            display: "flex",
            gap: "8px",
            cursor: "pointer",
            width: "fit-content",
          }}
        >
          <ArrowBackIcon className="backIconLanding" />
          <Typography
            className="goBackText"
            sx={{
              "@media (max-width: 767px)": {
                display: "none",
              },
            }}
          >
            {i18next.t("Go back")}
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Typography variant="h1" className="titleLandingPages">
            {title}
          </Typography>
        </Box>
        <Divider
          sx={{
            background: "rgba(228,228,228, 0.5)",
            display: "none",
            "@media (max-width: 767px)": {
              display: "flex",
              marginTop: "40px",
            },
          }}
        />
      </Box>
    </>
  );
};

export default TitleBackBox;
