import Typography from "@mui/material/Typography";

const BranchDetailsMeta = ({ branchDetails, branchDetailsType }: any) => {
  if (!Array.isArray(branchDetails)) {
    return null;
  }

  return (
    <>
      {branchDetailsType && (
        <div className="keyAndValueMain">
          <div>
            <Typography className="mb-1rem d-flex jc-start keys registration0875RemFontSize" variant="h5">
              Type
            </Typography>
          </div>
          <div>
            <Typography className="mb-1rem d-flex jc-start values registration0875RemFontSize" variant="h5">
              {branchDetailsType}
            </Typography>
          </div>
        </div>
      )}
      {branchDetails.map((item: any, index: number) => (
        <div key={index} className="keyAndValueMain">
          <div>
            <Typography className="mb-1rem d-flex jc-start keys registration0875RemFontSize" variant="h5">
              {item.key}
            </Typography>
          </div>
          <div>
            <Typography className="mb-1rem d-flex jc-start values registration0875RemFontSize" variant="h5">
              {" "}
              {item.value}
            </Typography>
          </div>
        </div>
      ))}
    </>
  );
};

export default BranchDetailsMeta;
