import i18next from "i18next";
import {
  RegisterEmailResponse,
  VeifyPasswordResponse,
  VerifyEmailResponse,
} from "../../types/register.types";
import api from "../interceptors/api";
import { toast } from "react-toastify"; // Make sure to import toast from react-toastify

const registerEmail = async (data: any) => {
  const response = await api.post(`/auth/register`, data);
  return response.data as RegisterEmailResponse;
};

const verifyEmail = async (data: any) => {
  const response = await api.get(
    `/auth/verify-email/${data.code}?email=${data.email}`
  );
  return response.data as VerifyEmailResponse;
};

const verifyEmailAfterChange = async (data: any) => {
  try {
    const response = await api.get(
      `/auth/verify-new-email/${data.code}?email=${data.email}`
    );
    if (response.data.message === "Invalid Code.") {
      toast.error(i18next.t("Wrong code"));
      return response.data;
    }
    return response.data;
  } catch (error) {
    toast.error(i18next.t("Wrong code"));
    return Promise.reject(error);
  }
};

const registerUser = async (data: any) => {
  const response = await api.put(
    `/auth/register-steps/${data.token}`,
    data.values
  );
  return response.data as VeifyPasswordResponse;
};

const registerService = {
  registerEmail,
  verifyEmail,
  registerUser,
  verifyEmailAfterChange,
};

export default registerService;
