import { Box, Button, Typography, Avatar } from "@mui/material";
import { useEffect, useState } from "react";
import GppBadIcon from "@mui/icons-material/GppBad";
import VerifiedIcon from "@mui/icons-material/Verified";
import api from "src/store/interceptors/api";
import CheckCircleOutline from "@mui/icons-material/CheckCircleOutline";
import CancelIcon from "@mui/icons-material/Cancel";
import ConfirmationDialog from "src/components/shared/ConfirmationDialog/ConfirmationDialog";
import { useNavigate, useParams } from "react-router-dom";
import InsertCommentIcon from "@mui/icons-material/InsertComment";
import ChatDialog from "../../shared/ChatDialog/ChatDialog";
import AccountStatusAdminActions from "src/pages/Profile/AccountStatusAdminActions";
import { useUser } from "src/hooks/useUser";
import { toast } from "react-toastify";
import UserDetailsTabs from "../../UserDetailsTabs/UserDetailsTabs";

function UserAccountInformation({ data }: any) {
  const [user, setUser] = useState(data);
  const [loggedUser, setLoggedUser] = useState("");
  const [isDialogChatOpen, setIsDialogChatOpen] = useState(false);
  const [isDialogCancelOpen, setIsDialogCancelOpen] = useState(false);
  const [sendingMessage, setIsSendindMessage] = useState(false);
  const [fileUpload, setFileUpload] = useState<File[]>([]);

  const navigate = useNavigate();
  const currrentUser = useUser();
  const { userId } = useParams();

  const handleEditBusiness = (event: any) => {
    navigate(`/admin/users/${userId}/edit-business`, { state: { user } });
  };

  const handleEditPersonal = (event: any) => {
    navigate(`/admin/users/${userId}/edit-profile`, { state: { user } });
  };

  const handleDialogCancelClose = () => {
    setIsDialogCancelOpen(false);
  };

  const handleDialogCancelConfirm = (e: any) => {
    setIsDialogCancelOpen(false);
    handleCancel();
  };

  const handleDialogChatClose = () => {
    setIsDialogChatOpen(false);
  };

  const handleDialogChatConfirm = (e: any, message: any) => {
    handleSendMessage(message);
  };

  const handleFileSelect = (files: FileList | null) => {
    if (files && files.length > 0) {
      setFileUpload([files[0]]);
      const fileNameContainer = document.getElementById("selectedFileName");
      if (fileNameContainer) {
        fileNameContainer.innerText = files[0].name;
      }
    } else {
      setFileUpload([]);
      const fileNameContainer = document.getElementById("selectedFileName");
      if (fileNameContainer) {
        fileNameContainer.innerText = "";
      }
    }
  };

  const handleSendMessage = async (message: any) => {
    setIsSendindMessage(true);
    try {
      const formData = new FormData();
      if (fileUpload && fileUpload.length > 0) {
        for (let i = 0; i < fileUpload.length; i++) {
          formData.append(`attachments[${i}]`, fileUpload[i]);
        }
      }
      formData.append("message", message);
      formData.append("user_id", user.id);
      await api.post("/chat-request", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      toast.success("Message sent successfully!");
      setIsDialogChatOpen(false);
      setFileUpload([]);
    } catch (error) {
      console.log(error);
      setIsDialogChatOpen(false);
    } finally {
      setIsSendindMessage(false);
    }
  };

  const handleCancel = async () => {
    try {
      const res = await api.delete(`/users/${user.id}`);
      if (res) {
        //console.log(res);
        if (
          res &&
          res.data &&
          res.data.message &&
          res.data.message === "User deleted!"
        ) {
          alert("User deleted!");
          navigate(-1);
        } else {
          alert("There was a problem deleting the user.");
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleVerficiationRequest = async (status: string) => {
    if (user && user.id) {
      let valuesForBe: any = {};
      if (status === "approved") {
        valuesForBe.verification_3s_can_send = true;
        valuesForBe.verification_requested = true;
      } else {
        valuesForBe.verification_approved = false;
        valuesForBe.verification_requested = false;
        valuesForBe.verification_3s_can_send = false;
      }
      valuesForBe.id = user.id ?? null;
      const userData = { ...valuesForBe, _method: "PATCH" };
      const response = await api.post(`/users/${user.id}`, userData);
      if (response && response.data && response.data.data) {
        setUser(response.data.data);

        alert("Verification updated!");
        window.location.reload();
      }
    }
  };

  const handleSendDataToProvider = async (status: string) => {
    if (user && user.id) {
      let valuesForBe: any = {};
      valuesForBe.userId = user.id ?? null;
      const userData = { ...valuesForBe };
      const response = await api.post(
        `/admin/send-documents-provider`,
        userData
      );
      if (response && response.data && response.data.success) {
        alert("Data has been sent!");
        window.location.reload();
      } else {
        if (response && response.data && response.data.message)
          alert(response.data.message);
        else alert("Please try again!");
      }
    }
  };

  const getLoggedUserRole = async () => {
    try {
      const response = await api.get(`me`);
      const loggedUserRole =
        response && response.data && response.data.data.role;
      if (loggedUserRole) {
        setLoggedUser(loggedUserRole);
      }
    } catch (exception) {
      console.log(exception);
    }
  };
  const renderEditButton = () => {
    if (loggedUser === "Employee" && user && user.role !== "Admin") {
      return true;
    } else if (loggedUser === "Employee" && user && user.role === "Admin") {
      return false;
    }
    return true;
  };

  const getUserAbbreviatedName = (user: any) => {
    if (!user) return "";

    if (user.account_type === "business" && user.company_name) {
      return user.company_name.substring(0, 2).toUpperCase();
    }

    if (
      user.first_name &&
      user.first_name.length > 0 &&
      user.last_name &&
      user.last_name.length > 0
    ) {
      return (
        user.first_name.charAt(0).toUpperCase() +
        user.last_name.charAt(0).toUpperCase()
      );
    }

    return "";
  };

  useEffect(() => {
    getLoggedUserRole();
  }, [loggedUser]);

  useEffect(() => {
    setUser(data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return (
    <Box className="recentBox">
      {!user ? (
        <Typography className="noDataText">No user found</Typography>
      ) : (
        <Box>
          <ConfirmationDialog
            open={isDialogCancelOpen}
            handleClose={handleDialogCancelClose}
            handleConfirm={handleDialogCancelConfirm}
            content={{
              title: "Sure you'd like to delete this user?",
              body: "Note that this action cannot be undone.",
              cancelText: "No",
              confirmText: "Delete user",
            }}
            className="custom-dialog"
          />
          <ChatDialog
            open={isDialogChatOpen}
            handleClose={handleDialogChatClose}
            handleConfirm={handleDialogChatConfirm}
            loading={sendingMessage}
            showFileUpload={true}
            fileUpload={fileUpload}
            onFileSelect={handleFileSelect}
            content={{
              title: "Send message",
              body: "Note that this action cannot be undone.",
              cancelText: "No",
              confirmText: "Send message",
            }}
            className="custom-dialog"
          />
          {user &&
            user.id &&
            user.role !== "Admin" &&
            user.role !== "Employee" && (
              <Box sx={{ paddingBottom: 2 }}>
                <Button
                  variant="contained"
                  onClick={() => {
                    if (!isDialogChatOpen) setIsDialogChatOpen(true);
                  }}
                  className="oneRemFontSize"
                  sx={{
                    color: "#fff",
                    borderRadius: "24px",
                    boxShadow: "none",
                    fontWeight: 400,
                    textTransform: "none",
                    fontFamily: "Helvetica, sans-serif",
                    textIndent: 10,
                    "&:hover": {
                      backgroundColor: "#2A5182",
                      color: "white",
                    },
                  }}
                >
                  <InsertCommentIcon
                    style={{ color: "#ffffff" }}
                    className="muiIcons"
                  />{" "}
                  Send message
                </Button>
              </Box>
            )}
          <UserDetailsTabs user={user} />
          <Box sx={{ mt: 2 }}>
            {/* <div className="d-flex ai-center mb-3-rem">
              <Typography
                className="your-details-text d-flex jc-start"
                component="h1"
                variant="h5"
                sx={{
                  fontFamily: "Helvetica, sans-serif",
                  fontSize: "1.875rem",
                  textAlign: "center",
                  fontWeight: "600",
                }}
              >
                {user &&
                user.role &&
                (user.role === "Admin" || user.role === "Employee")
                  ? "Account details"
                  : "Your details"}
              </Typography>
            </div> */}

            {/* image start */}
            <div>
              {currrentUser &&
                currrentUser.role &&
                (currrentUser.role === "Admin" ||
                  currrentUser.role === "Employee") && (
                  <AccountStatusAdminActions
                    user={user}
                    data={{
                      status: user?.account_status || "Created",
                      created_at: user?.created_at,
                      updated_at: user?.updated_at,
                    }}
                  />
                )}
            </div>
            <div className="d-flex ai-center">
              {user?.image?.file_url ? (
                <Avatar
                  src={user.image?.file_url}
                  alt={`${user.first_name} ${user.last_name}`}
                  sx={{ width: "5rem", height: "5rem" }}
                />
              ) : (
                <p className="mr-8 circle-around-text-big helvetica big-text">
                  {getUserAbbreviatedName(user)}
                </p>
              )}
              <Typography
                className="mb-1rem d-flex jc-start underline registration0875RemFontSize"
                component="h1"
                variant="h5"
                sx={{
                  fontFamily: "Helvetica, sans-serif",
                  textAlign: "center",
                  marginLeft: "1rem",
                }}
              >
                {user?.image?.file_url ? "Account photo" : "No account photo"}
              </Typography>
            </div>

            {/* image end */}

            {user && user.account_type === "business" && (
              <Box sx={{ mt: 2 }}>
                <div className="d-flex jusifyContentSpaceBetween ai-center mb-1rem">
                  <Typography
                    className="d-flex jc-start loginTitleFontSize"
                    component="h1"
                    variant="h5"
                    sx={{
                      fontFamily: "Helvetica, sans-serif",
                      fontWeight: "600",
                    }}
                  >
                    Business information
                  </Typography>
                  {renderEditButton() && (
                    <Button
                      variant="contained"
                      onClick={handleEditBusiness}
                      className="btnsLoginHeight oneRemFontSize"
                      sx={{
                        color: "#000000",
                        backgroundColor: "transparent",
                        borderRadius: "24px",
                        boxShadow: "none",
                        border: "1px solid #000000",
                        fontWeight: 400,
                        textTransform: "none",
                        fontFamily: "Helvetica, sans-serif",
                        "&:hover": {
                          backgroundColor: "#2A5182",
                          color: "white",
                        },
                      }}
                    >
                      Edit business details
                    </Button>
                  )}
                </div>

                {user &&
                  user.account_type === "business" &&
                  user.companies &&
                  user.companies.length > 0 && (
                    <div className="personal-box">
                      <div className="one-flex-line">
                        <div className="desc-name-box">
                          <Typography
                            className="mb-1rem  d-flex jc-start registration0875RemFontSize"
                            component="h1"
                            variant="h5"
                            sx={{
                              fontFamily: "Helvetica, sans-serif",
                              fontWeight: ".875rem",
                              color: "#454745",
                            }}
                          >
                            Legal name
                          </Typography>

                          <Typography
                            className="mb-1rem  d-flex jc-start registration0875RemFontSize"
                            component="h1"
                            variant="h5"
                            sx={{
                              fontFamily: "Helvetica, sans-serif",
                              fontWeight: ".875rem",
                              color: "#454745",
                            }}
                          >
                            {user?.companies[0]?.name ?? "/"}
                          </Typography>
                        </div>

                        <div className="desc-name-box">
                          <Typography
                            className="mb-1rem  d-flex jc-start registration0875RemFontSize"
                            component="h1"
                            variant="h5"
                            sx={{
                              fontFamily: "Helvetica, sans-serif",
                              fontWeight: ".875rem",
                              color: "#454745",
                            }}
                          >
                            Registration number
                          </Typography>

                          <Typography
                            className="mb-1rem  d-flex jc-start registration0875RemFontSize"
                            component="h1"
                            variant="h5"
                            sx={{
                              fontFamily: "Helvetica, sans-serif",
                              fontWeight: ".875rem",
                              color: "#454745",
                            }}
                          >
                            {user?.companies[0].registration_number}
                          </Typography>
                        </div>
                      </div>

                      <div className="one-flex-line mt-16">
                        <div className="desc-name-box">
                          <Typography
                            className="mb-1rem  d-flex jc-start registration0875RemFontSize"
                            component="h1"
                            variant="h5"
                            sx={{
                              fontFamily: "Helvetica, sans-serif",
                              fontWeight: ".875rem",
                              color: "#454745",
                            }}
                          >
                            Date of incorporation
                          </Typography>

                          <Typography
                            className="mb-1rem  d-flex jc-start registration0875RemFontSize"
                            component="h1"
                            variant="h5"
                            sx={{
                              fontFamily: "Helvetica, sans-serif",
                              fontWeight: ".875rem",
                              color: "#454745",
                            }}
                          >
                            {user?.companies[0]?.incorporation_date}
                          </Typography>
                        </div>

                        <div className="desc-name-box">
                          <Typography
                            className="mb-1rem  d-flex jc-start registration0875RemFontSize"
                            component="h1"
                            variant="h5"
                            sx={{
                              fontFamily: "Helvetica, sans-serif",
                              fontWeight: ".875rem",
                              color: "#454745",
                            }}
                          >
                            Phone
                          </Typography>

                          <Typography
                            className="mb-1rem  d-flex jc-start registration0875RemFontSize"
                            component="h1"
                            variant="h5"
                            sx={{
                              fontFamily: "Helvetica, sans-serif",
                              fontWeight: ".875rem",
                              color: "#454745",
                            }}
                          >
                            {user?.companies[0].country_code ?? ""}{" "}
                            {user?.companies[0].phone ?? "/"}
                          </Typography>
                        </div>
                      </div>

                      <div className="one-flex-line mt-16">
                        <div className="desc-name-box">
                          <Typography
                            className="mb-1rem  d-flex jc-start registration0875RemFontSize"
                            component="h1"
                            variant="h5"
                            sx={{
                              fontFamily: "Helvetica, sans-serif",
                              fontWeight: ".875rem",
                              color: "#454745",
                            }}
                          >
                            Country
                          </Typography>

                          <Typography
                            className="mb-1rem  d-flex jc-start registration0875RemFontSize"
                            component="h1"
                            variant="h5"
                            sx={{
                              fontFamily: "Helvetica, sans-serif",
                              fontWeight: ".875rem",
                              color: "#454745",
                            }}
                          >
                            {user?.companies[0].country ?? "/"}
                          </Typography>
                        </div>
                        <div className="desc-name-box">
                          <Typography
                            className="mb-1rem  d-flex jc-start registration0875RemFontSize"
                            component="h1"
                            variant="h5"
                            sx={{
                              fontFamily: "Helvetica, sans-serif",
                              fontWeight: ".875rem",
                              color: "#454745",
                            }}
                          >
                            City
                          </Typography>

                          <Typography
                            className="mb-1rem  d-flex jc-start registration0875RemFontSize"
                            component="h1"
                            variant="h5"
                            sx={{
                              fontFamily: "Helvetica, sans-serif",
                              fontWeight: ".875rem",
                              color: "#454745",
                            }}
                          >
                            {user?.companies[0].city ?? "/"}
                          </Typography>
                        </div>
                      </div>
                      <div className="one-flex-line mt-16">
                        <div className="desc-name-box">
                          <Typography
                            className="mb-1rem  d-flex jc-start registration0875RemFontSize"
                            component="h1"
                            variant="h5"
                            sx={{
                              fontFamily: "Helvetica, sans-serif",
                              fontWeight: ".875rem",
                              color: "#454745",
                            }}
                          >
                            Address
                          </Typography>

                          <Typography
                            className="mb-1rem  d-flex jc-start registration0875RemFontSize"
                            component="h1"
                            variant="h5"
                            sx={{
                              fontFamily: "Helvetica, sans-serif",
                              fontWeight: ".875rem",
                              color: "#454745",
                            }}
                          >
                            {user?.companies[0].address ?? "/"}
                          </Typography>
                        </div>
                        <div className="desc-name-box">
                          <Typography
                            className="mb-1rem  d-flex jc-start registration0875RemFontSize"
                            component="h1"
                            variant="h5"
                            sx={{
                              fontFamily: "Helvetica, sans-serif",
                              fontWeight: ".875rem",
                              color: "#454745",
                            }}
                          >
                            Postal Code
                          </Typography>

                          <Typography
                            className="mb-1rem  d-flex jc-start registration0875RemFontSize"
                            component="h1"
                            variant="h5"
                            sx={{
                              fontFamily: "Helvetica, sans-serif",
                              fontWeight: ".875rem",
                              color: "#454745",
                            }}
                          >
                            {user?.companies[0].postal_code ?? "/"}
                          </Typography>
                        </div>
                      </div>
                      <div className="one-flex-line mt-16">
                        <div className="desc-name-box">
                          <Typography
                            className="mb-1rem  d-flex jc-start registration0875RemFontSize"
                            component="h1"
                            variant="h5"
                            sx={{
                              fontFamily: "Helvetica, sans-serif",
                              fontWeight: ".875rem",
                              color: "#454745",
                            }}
                          >
                            Web
                          </Typography>

                          <Typography
                            className="mb-1rem  d-flex jc-start registration0875RemFontSize"
                            component="h1"
                            variant="h5"
                            sx={{
                              fontFamily: "Helvetica, sans-serif",
                              fontWeight: ".875rem",
                              color: "#454745",
                            }}
                          >
                            {user?.companies[0].website ? (
                              <a
                                rel="noreferrer"
                                href={user?.companies[0].website}
                                target="_blank"
                              >
                                {user?.companies[0].website}
                              </a>
                            ) : (
                              "/"
                            )}
                          </Typography>
                        </div>
                        <div className="desc-name-box">
                          <Typography
                            className="mb-1rem  d-flex jc-start registration0875RemFontSize"
                            component="h1"
                            variant="h5"
                            sx={{
                              fontFamily: "Helvetica, sans-serif",
                              fontWeight: ".875rem",
                              color: "#454745",
                            }}
                          >
                            Number of employees
                          </Typography>

                          <Typography
                            className="mb-1rem  d-flex jc-start registration0875RemFontSize"
                            component="h1"
                            variant="h5"
                            sx={{
                              fontFamily: "Helvetica, sans-serif",
                              fontWeight: ".875rem",
                              color: "#454745",
                            }}
                          >
                            {user?.companies[0].number_of_employees ?? "/"}
                          </Typography>
                        </div>
                      </div>
                    </div>
                  )}
              </Box>
            )}

            <Box sx={{ mt: 2 }}>
              <div className="d-flex jusifyContentSpaceBetween ai-center mb-1rem">
                <Typography
                  className="d-flex jc-start loginTitleFontSize"
                  component="h1"
                  variant="h5"
                  sx={{
                    fontFamily: "Helvetica, sans-serif",
                    fontWeight: "600",
                  }}
                >
                  Personal information
                </Typography>
                {renderEditButton() && (
                  <Button
                    variant="contained"
                    onClick={handleEditPersonal}
                    className="btnsLoginHeight oneRemFontSize"
                    sx={{
                      color: "#000000",
                      backgroundColor: "transparent",
                      borderRadius: "24px",
                      boxShadow: "none",
                      border: "1px solid #000000",
                      fontWeight: 400,
                      textTransform: "none",
                      fontFamily: "Helvetica, sans-serif",
                      "&:hover": {
                        backgroundColor: "#2A5182",
                        color: "white",
                      },
                    }}
                  >
                    Edit personal details
                  </Button>
                )}
              </div>

              <div className="personal-box">
                <div className="one-flex-line">
                  <div className="desc-name-box">
                    <Typography
                      className="mb-1rem  d-flex jc-start registration0875RemFontSize"
                      component="h1"
                      variant="h5"
                      sx={{
                        fontFamily: "Helvetica, sans-serif",
                        fontWeight: ".875rem",
                        color: "#454745",
                      }}
                    >
                      Full legal first and middle names
                    </Typography>

                    <Typography
                      className="mb-1rem  d-flex jc-start registration0875RemFontSize"
                      component="h1"
                      variant="h5"
                      sx={{
                        fontFamily: "Helvetica, sans-serif",
                        fontWeight: ".875rem",
                        color: "#454745",
                      }}
                    >
                      {user?.first_name}
                    </Typography>
                  </div>

                  <div className="desc-name-box">
                    <Typography
                      className="mb-1rem  d-flex jc-start registration0875RemFontSize"
                      component="h1"
                      variant="h5"
                      sx={{
                        fontFamily: "Helvetica, sans-serif",
                        fontWeight: ".875rem",
                        color: "#454745",
                      }}
                    >
                      Full legal last name(s)
                    </Typography>

                    <Typography
                      className="mb-1rem  d-flex jc-start registration0875RemFontSize"
                      component="h1"
                      variant="h5"
                      sx={{
                        fontFamily: "Helvetica, sans-serif",
                        fontWeight: ".875rem",
                        color: "#454745",
                      }}
                    >
                      {user?.last_name}
                    </Typography>
                  </div>
                </div>

                <div className="one-flex-line mt-16">
                  <div className="desc-name-box">
                    <Typography
                      className="mb-1rem  d-flex jc-start registration0875RemFontSize"
                      component="h1"
                      variant="h5"
                      sx={{
                        fontFamily: "Helvetica, sans-serif",
                        fontWeight: ".875rem",
                        color: "#454745",
                      }}
                    >
                      Date of birth
                    </Typography>

                    <Typography
                      className="mb-1rem  d-flex jc-start registration0875RemFontSize"
                      component="h1"
                      variant="h5"
                      sx={{
                        fontFamily: "Helvetica, sans-serif",
                        fontWeight: ".875rem",
                        color: "#454745",
                      }}
                    >
                      {user?.birthday}
                    </Typography>
                  </div>

                  <div className="desc-name-box">
                    <Typography
                      className="mb-1rem  d-flex jc-start registration0875RemFontSize"
                      component="h1"
                      variant="h5"
                      sx={{
                        fontFamily: "Helvetica, sans-serif",
                        fontWeight: ".875rem",
                        color: "#454745",
                      }}
                    >
                      Phone
                    </Typography>

                    <Typography
                      className="mb-1rem  d-flex jc-start registration0875RemFontSize"
                      component="h1"
                      variant="h5"
                      sx={{
                        fontFamily: "Helvetica, sans-serif",
                        fontWeight: ".875rem",
                        color: "#454745",
                      }}
                    >
                      {user?.country_code ?? ""} {user?.phone ?? ""}
                    </Typography>
                  </div>
                </div>

                <div className="one-flex-line mt-16">
                  <div className="desc-name-box">
                    <Typography
                      className="mb-1rem  d-flex jc-start registration0875RemFontSize"
                      component="h1"
                      variant="h5"
                      sx={{
                        fontFamily: "Helvetica, sans-serif",
                        fontWeight: ".875rem",
                        color: "#454745",
                      }}
                    >
                      Email
                    </Typography>

                    <Typography
                      className="mb-1rem  d-flex jc-start registration0875RemFontSize"
                      component="h1"
                      variant="h5"
                      sx={{
                        fontFamily: "Helvetica, sans-serif",
                        fontWeight: ".875rem",
                        color: "#454745",
                      }}
                    >
                      {user?.email}
                    </Typography>
                  </div>
                  <div className="desc-name-box">
                    <Typography
                      className="mb-1rem  d-flex jc-start registration0875RemFontSize"
                      component="h1"
                      variant="h5"
                      sx={{
                        fontFamily: "Helvetica, sans-serif",
                        fontWeight: ".875rem",
                        color: "#454745",
                      }}
                    >
                      Account Verified{" "}
                    </Typography>

                    <Typography
                      className="mb-1rem  d-flex jc-start registration0875RemFontSize"
                      component="h1"
                      variant="h5"
                      sx={{
                        fontFamily: "Helvetica, sans-serif",
                        fontWeight: ".875rem",
                        color: "#454745",
                      }}
                    >
                      <div className={"d-flex ai-center"}>
                        {user && user.verification_approved
                          ? "Verified"
                          : "Not verified"}

                        {user && user.verification_approved ? (
                          <VerifiedIcon
                            style={{ color: "#00c943" }}
                            className="muiIcons"
                          />
                        ) : (
                          <GppBadIcon
                            style={{ color: "#c90014" }}
                            className="muiIcons"
                          />
                        )}
                      </div>
                    </Typography>
                  </div>
                </div>
              </div>

              <Typography
                className="mb-1rem  d-flex jc-start loginTitleFontSize"
                component="h1"
                variant="h5"
                sx={{
                  fontFamily: "Helvetica, sans-serif",
                  fontWeight: "600",
                  marginTop: "2rem",
                }}
              >
                Personal address
              </Typography>

              <div className="personal-box mb-big">
                <div className="one-flex-line">
                  <div className="desc-name-box">
                    <Typography
                      className="mb-1rem  d-flex jc-start registration0875RemFontSize"
                      component="h1"
                      variant="h5"
                      sx={{
                        fontFamily: "Helvetica, sans-serif",
                        fontWeight: ".875rem",
                        color: "#454745",
                      }}
                    >
                      Address
                    </Typography>

                    <Typography
                      className="mb-1rem  d-flex jc-start registration0875RemFontSize"
                      component="h1"
                      variant="h5"
                      sx={{
                        fontFamily: "Helvetica, sans-serif",
                        fontWeight: ".875rem",
                        color: "#454745",
                      }}
                    >
                      {user?.address ? user.address : "No address"}
                    </Typography>
                  </div>

                  <div className="desc-name-box">
                    <Typography
                      className="mb-1rem  d-flex jc-start registration0875RemFontSize"
                      component="h1"
                      variant="h5"
                      sx={{
                        fontFamily: "Helvetica, sans-serif",
                        fontWeight: ".875rem",
                        color: "#454745",
                      }}
                    >
                      City
                    </Typography>

                    <Typography
                      className="mb-1rem  d-flex jc-start registration0875RemFontSize"
                      component="h1"
                      variant="h5"
                      sx={{
                        fontFamily: "Helvetica, sans-serif",
                        fontWeight: ".875rem",
                        color: "#454745",
                      }}
                    >
                      {user?.city}
                    </Typography>
                  </div>
                </div>

                <div className="one-flex-line mt-16">
                  <div className="desc-name-box">
                    <Typography
                      className="mb-1rem  d-flex jc-start registration0875RemFontSize"
                      component="h1"
                      variant="h5"
                      sx={{
                        fontFamily: "Helvetica, sans-serif",
                        fontWeight: ".875rem",
                        color: "#454745",
                      }}
                    >
                      Postal Code
                    </Typography>

                    <Typography
                      className="mb-1rem  d-flex jc-start registration0875RemFontSize"
                      component="h1"
                      variant="h5"
                      sx={{
                        fontFamily: "Helvetica, sans-serif",
                        fontWeight: ".875rem",
                        color: "#454745",
                      }}
                    >
                      {user?.postal_code}
                    </Typography>
                  </div>

                  <div className="desc-name-box">
                    <Typography
                      className="mb-1rem  d-flex jc-start registration0875RemFontSize"
                      component="h1"
                      variant="h5"
                      sx={{
                        fontFamily: "Helvetica, sans-serif",
                        fontWeight: ".875rem",
                        color: "#454745",
                      }}
                    >
                      Country
                    </Typography>

                    <Typography
                      className="mb-1rem  d-flex jc-start registration0875RemFontSize"
                      component="h1"
                      variant="h5"
                      sx={{
                        fontFamily: "Helvetica, sans-serif",
                        fontWeight: ".875rem",
                        color: "#454745",
                      }}
                    >
                      {user?.country}
                    </Typography>
                  </div>
                </div>
              </div>
            </Box>
            {user && !user.verification_approved && (
              <div>
                <Typography
                  className="mb-1rem  d-flex jc-start loginTitleFontSize"
                  component="h1"
                  variant="h5"
                  sx={{
                    fontFamily: "Helvetica, sans-serif",
                    fontWeight: "600",
                    marginTop: "2rem",
                  }}
                >
                  Account status
                </Typography>

                <div style={{ padding: 40 }}>
                  <Typography
                    component="h1"
                    variant="h5"
                    className="registration0875RemFontSize"
                    sx={{
                      fontFamily: "Helvetica, sans-serif",
                      fontWeight: ".875rem",
                      color: "#454745",
                    }}
                  >
                    {user.verification_3s_can_send &&
                    user.verification_requested
                      ? "Waiting for approval from Business provider"
                      : user.verification_requested
                      ? "User requested account verification Please review all fields and compare with attached files and complete the review."
                      : "User account is not completed."}
                  </Typography>

                  {user &&
                  user.personal_photo_file &&
                  (user.passport_file ||
                    (user.id_card_back_file && user.id_card_front_file)) &&
                  user.email &&
                  user.first_name &&
                  user.last_name &&
                  user.country &&
                  user.verification_requested ? (
                    <div style={{ paddingTop: 30, paddingBottom: 30 }}>
                      {!user.verification_3s_can_send ? (
                        <Button
                          variant="contained"
                          className="oneRemFontSize btnsLoginHeight"
                          onClick={() => handleVerficiationRequest("approved")}
                          sx={{
                            color: "#fff",
                            borderRadius: "24px",
                            height: "2rem",
                            boxShadow: "none",
                            cursor: "pointer",
                            border: "1px solid #000000",
                            fontWeight: 400,
                            textTransform: "none",
                            fontFamily: "Helvetica, sans-serif",
                            "&:hover": {
                              backgroundColor: "#2A5182",
                              color: "white",
                            },
                          }}
                        >
                          Approve for Business partner review{" "}
                          <CheckCircleOutline
                            style={{ paddingLeft: 5, color: "#ffffff" }}
                            className="muiIcons"
                          />
                        </Button>
                      ) : (
                        <></>
                      )}

                      {!user.verification_3s_requested &&
                      user.verification_3s_can_send ? (
                        <Button
                          variant="contained"
                          className="oneRemFontSize btnsLoginHeight"
                          onClick={() => handleSendDataToProvider("approved")}
                          sx={{
                            color: "#fff",
                            borderRadius: "24px",
                            height: "2rem",
                            boxShadow: "none",
                            cursor: "pointer",
                            border: "1px solid #000000",
                            fontWeight: 400,
                            textTransform: "none",
                            fontFamily: "Helvetica, sans-serif",
                            "&:hover": {
                              backgroundColor: "#2A5182",
                              color: "white",
                            },
                          }}
                        >
                          Send to Business partner review{" "}
                          <CheckCircleOutline
                            style={{ paddingLeft: 5, color: "#ffffff" }}
                            className="muiIcons"
                          />
                        </Button>
                      ) : (
                        <></>
                      )}

                      {!user.verification_3s_approved &&
                      user.verification_3s_requested &&
                      user.verification_3s_can_send ? (
                        <Button
                          variant="contained"
                          className="oneRemFontSize btnsLoginHeight"
                          onClick={() => handleSendDataToProvider("approved")}
                          sx={{
                            color: "#fff",
                            borderRadius: "24px",
                            height: "2rem",
                            boxShadow: "none",
                            cursor: "pointer",
                            border: "1px solid #000000",
                            fontWeight: 400,
                            textTransform: "none",
                            fontFamily: "Helvetica, sans-serif",
                            "&:hover": {
                              backgroundColor: "#2A5182",
                              color: "white",
                            },
                          }}
                        >
                          Resend to Business partner review{" "}
                          <CheckCircleOutline
                            style={{ paddingLeft: 5, color: "#ffffff" }}
                            className="muiIcons"
                          />
                        </Button>
                      ) : (
                        <></>
                      )}
                      <Button
                        variant="contained"
                        className="oneRemFontSize btnsLoginHeight"
                        onClick={() => handleVerficiationRequest("rejected")}
                        sx={{
                          marginLeft: 5,
                          color: "#fff",
                          borderRadius: "24px",
                          boxShadow: "none",
                          cursor: "pointer",
                          border: "1px solid #000000",
                          backgroundColor: "#b80012",
                          fontWeight: 400,
                          textTransform: "none",
                          fontFamily: "Helvetica, sans-serif",
                          "&:hover": {
                            backgroundColor: "#7a0611",
                            color: "white",
                          },
                        }}
                      >
                        Reject verification{" "}
                        <CancelIcon
                          style={{ paddingLeft: 5, color: "#ffffff" }}
                          className="muiIcons"
                        />
                      </Button>
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            )}
            {user &&
              user.id &&
              user.role !== "Admin" &&
              user.role !== "Employee" && (
                <Box sx={{ paddingTop: 2, paddingBottom: 2 }}>
                  <Button
                    onClick={() => {
                      if (!isDialogCancelOpen) setIsDialogCancelOpen(true);
                    }}
                    fullWidth
                    variant="contained"
                    className="button-delete-danger btnsLoginHeight oneRemFontSize"
                    sx={{
                      borderRadius: "24px",
                      textTransform: "none",
                      marginTop: "0.5rem",
                      marginBottom: "1rem",
                      fontFamily: "Helvetica, sans-serif",
                    }}
                  >
                    Delete user
                  </Button>
                </Box>
              )}
          </Box>
        </Box>
      )}
    </Box>
  );
}

export default UserAccountInformation;
