import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Nav from "src/components/layout/nav/Nav";
import { Box, Typography, Button } from "@mui/material";
import { useNavigate } from "react-router-dom";

const defaultTheme = createTheme();

export default function Error404() {
  const navigate = useNavigate();

  const onButtonClick = () => {
    navigate("/home");
  };
  return (
    <div>
      <Nav step={0} isStepperVisible={false} isProfileSetVisible={true} />
      <div className="content-container-profile">
        <ThemeProvider theme={defaultTheme}>
          <Container component="main">
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Typography
                className="your-details-text d-flex jc-start"
                component="h1"
                variant="h5"
                sx={{
                  fontFamily: "Helvetica, sans-serif",
                  fontSize: "1.875rem",
                  fontWeight: "600",
                  textAlign: "start",
                  marginBottom: "2rem",
                }}
              >
                Something went wrong!
              </Typography>

              <Box sx={{ mt: 2 }}>
                <Button
                  type="submit"
                  variant="contained"
                  fullWidth
                  sx={{
                    color: "white",
                    backgroundColor: "#2A5182",
                    borderRadius: "24px",
                    height: "3rem",
                    textTransform: "none",
                    marginTop: "0.5rem",
                    fontFamily: "Helvetica, sans-serif",
                    fontSize: "0.9rem",
                  }}
                  onClick={onButtonClick}
                >
                  Go back
                </Button>
              </Box>
            </Box>
          </Container>
        </ThemeProvider>
      </div>
    </div>
  );
}
