import api from "../interceptors/api";

const getUsers = async (searchValue?: string | null) => {
  let url = `/users`;
  if (searchValue) {
    url += searchValue;
  }
  try {
    const response = await api.get(url);
    return response.data;
  } catch (error) {
    return null;
  }
};
const getUsersTransferList = async (searchValue?: string | null) => {
  let url = `/users-transfer-list`;
  if (searchValue) {
    url += searchValue;
  }
  const response = await api.get(url);
  return response.data;
};
const getUserById = async (userId: string) => {
  try {
    const response = await api.get(`/users/${userId}`);
    return response.data;
  } catch (error) {
    return null;
  }
};

const usersService = {
  getUsers,
  getUsersTransferList,
  getUserById,
};

export default usersService;
