import React from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Typography, Box } from "@mui/material";
import HeaderLanding from "../components/HeaderLanding/HeaderLanding";
import { useAppSelector } from "src/store";
import FooterLanding from "../components/FooterLanding/FooterLanding";
import TitleTextVvidget from "../components/TitleTextVvidget/TitleTextVvidget";
import ImageWithText from "../components/ImageWithText/ImageWithText";
import FeesImage from "../../../assets/images/landingFeesImage.jpg";
import LogoTextVvidget from "../components/LogoTextVvidget/LogoTextVvidget";
import TitleBackBox from "../components/TitleBackBox/TitleBackBox";
import { useNavigate } from "react-router-dom";
import HeadlineVvidgetSmall from "../components/HeadlineVvidget/HeadlineVvidgetSmall";
import i18next from "i18next";

const defaultTheme = createTheme();
const Fees: React.FC = () => {
  const { user } = useAppSelector((state) => state.auth);
  const navigate = useNavigate();
  const navigateToContact = (event: any) => {
    navigate("/contact-us");
  };

  return (
    <div>
      <HeaderLanding user={user} />
      <div className="margintTopPagesMainCont">
        <ThemeProvider theme={defaultTheme}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            {" "}
            <Box className="titleHeadlineSmallBG">
              <Box className="landingPagesMainBox">
                <Box>
                  <TitleBackBox title={i18next.t("Fees")} />
                </Box>
                <Box>
                  <HeadlineVvidgetSmall
                    headline={i18next.t(
                      "Faster, cheaper and better than the others"
                    )}
                    subtitle={i18next.t(
                      "VicPay is the partner for anyone who has had enough of slow branch banks and high fees!"
                    )}
                    smallText={i18next.t("In case of any questions")}
                    buttonText={i18next.t("Contact us")}
                    onClickFunction={navigateToContact}
                  />
                </Box>
              </Box>
            </Box>
            <Box className="landingPagesMainBox">
              <Box
                sx={{
                  marginTop: "1rem",
                  marginBottom: "4rem",
                  display: "grid",
                  gridTemplateColumns: ["1fr", "1fr 1fr"],
                  gap: [2, 5],
                  "@media(max-width: 600px)": {
                    marginBottom: "6rem",
                  },
                }}
              >
                <Box>
                  <Typography
                    variant="h3"
                    sx={{
                      marginBottom: "1rem",
                    }}
                    className="headlineMiniLandingPages"
                  >
                    {" "}
                    {i18next.t("VicPay's Transparency")}
                  </Typography>
                  <Typography
                    variant="body1"
                    paragraph
                    className="textsLandingPage"
                  >
                    {i18next.t(
                      "For you as a client this means that you’ll always know the fee for your transaction or payment process in advance"
                    )}
                  </Typography>
                </Box>
                <Box>
                  <Typography
                    variant="h3"
                    sx={{
                      marginBottom: "1rem",
                    }}
                    className="headlineMiniLandingPages"
                  >
                    {" "}
                    {i18next.t("VicPay for Everyone")}
                  </Typography>
                  <Typography
                    variant="body1"
                    paragraph
                    className="textsLandingPage"
                  >
                    {i18next.t(
                      "By the way: to keep things easy for our clients our fee schedule applies to individual and business accounts"
                    )}
                  </Typography>
                </Box>
              </Box>
              <Box>
                <ImageWithText
                  backgroundImage={FeesImage}
                  noiseImage="/img/payments-noise.png"
                  title={i18next.t("Transparent Pricing, No Surprises")}
                  text={i18next.t(
                    "This means that we offer fair and transparent pricing, no hidden fees, no unnecessary subscriptions and no maintenance fees"
                  )}
                />
              </Box>

              <Box
                sx={{
                  marginTop: "4rem",
                  marginBottom: "4rem",
                  display: "grid",
                  gridTemplateColumns: ["1fr", "1fr 1fr", "1fr 1fr 1fr"],
                  gap: [2, 5],
                }}
              >
                <Box>
                  <TitleTextVvidget
                    showBoxStyle={true}
                    title={i18next.t("Accounts")}
                    text={i18next.t(
                      "Account opening MultiCurrency Account, an IBAN account: 150 EUR"
                    )}
                  />
                </Box>
                <Box>
                  <TitleTextVvidget
                    showBoxStyle={true}
                    title={i18next.t("VISA Card")}
                    text={i18next.t("Getting the Victorum Visa card: -")}
                    text2={i18next.t("Yearly Fees: -")}
                  />
                </Box>
                <Box>
                  <TitleTextVvidget
                    showBoxStyle={true}
                    title={i18next.t("Maintenance")}
                    text={i18next.t(
                      "Monthly maintenance fee per client: 50 EUR"
                    )}
                  />
                </Box>
                <Box>
                  <TitleTextVvidget
                    showBoxStyle={true}
                    title={i18next.t("Sending money")}
                    text={i18next.t("Sending money: 0,20%")}
                    text2={i18next.t("SWIFT: 25 EUR")}
                    text3={i18next.t("Local: 7 EUR")}
                  />n
                </Box>
                <Box>
                  <TitleTextVvidget
                    showBoxStyle={true}
                    title={i18next.t("Receiving Money")}
                    text={i18next.t("Receiving money: 0,20%")}
                  />
                </Box>
                <Box>
                  <TitleTextVvidget
                    showBoxStyle={true}
                    title={i18next.t("Crypto Currencies")}
                    text={i18next.t("Receiving Crypto Currencies: 1.3%")}
                  />
                </Box>
              </Box>
              {/* <Box sx={{ marginTop: "2rem" }}>
                <Typography
                  variant="h3"
                  sx={{
                    marginBottom: "1rem",
                  }}
                  className="headlineMiniLandingPages"
                >
                  {" "}
                  Crypto Trading: VicPay's Flexibility
                </Typography>
                <Typography
                  variant="body1"
                  paragraph
                  className="textsLandingPage"
                >
                  Buy / Sell Crypto Currencies: dynamic spreads and variable
                  conditions
                </Typography>
              </Box>
              <Box sx={{ marginTop: "2rem" }}>
                <Typography
                  variant="h3"
                  sx={{
                    marginBottom: "1rem",
                  }}
                  className="headlineMiniLandingPages"
                >
                  {" "}
                  Compare & Choose: VicPay's Quality
                </Typography>
                <Typography
                  variant="body1"
                  paragraph
                  className="textsLandingPage"
                >
                  Check and compare yourself - there might be a cheaper offer
                  somewhere, but never offering our level of quality and
                  competence!
                </Typography>
              </Box> */}

              <Box>
                <LogoTextVvidget
                  text={
                    <>
                      {i18next.t(
                        "In case of any questions regarding our fees or any other account related questions, please do not hesitate to call us on +1 604-260-0738 or to send us a message to"
                      )}
                      <a
                        href="mailto:info@vicpayments.com"
                        className="linksHover"
                        style={{ marginLeft: "5px" }}
                      >
                        info@vicpayments.com!
                      </a>
                    </>
                  }
                />
              </Box>
            </Box>
          </Box>
        </ThemeProvider>
      </div>
      <Box sx={{ marginTop: "4rem" }}>
        <FooterLanding />
      </Box>
    </div>
  );
};

export default Fees;
