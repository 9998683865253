// eslint-disable-next-line import/no-anonymous-default-export
export default {
  networkId: {
    ethMainnet: 1,
    ethRinkeby: 4,
    ethSepolia: 11155111,
    ethKovan: 42,
    bscMainnet: 56,
    bscTestnet: 97,
  } as any,
  networkIdHex: {
    ethMainnet: "0x1",
    ethKovan: "0x2a",
    ethSepolia: "0xaa36a7",
    bscMainnet: "0x38",
    bscTestnet: "0x61",
  },
  networkType: {
    binance: "binance",
    ethereum: "ethereum",
  },
};
