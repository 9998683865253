import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { useNavigate, useParams } from "react-router-dom";
import Nav from "src/components/layout/nav/Nav";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import LoadingIndicator from "src/components/shared/LoadingIndicator";
import api from "src/store/interceptors/api";
import {
  Button,
  IconButton,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
} from "@mui/material";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import ControlPointOutlinedIcon from "@mui/icons-material/ControlPointOutlined";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";

const defaultTheme = createTheme();

interface DynamicField {
  id?: number;
  key: string;
  value: string;
  editable: boolean;
}

export default function EditInternationalDetails() {
  const { branchId }: any = useParams();
  const [branchDetails, setBranchDetails] = useState<any>(null);
  const [dynamicFields, setDynamicFields] = useState<DynamicField[]>([]);
  const [loading, setLoading] = useState(false);
  const [type, setType] = useState<string>("");

  const [modifiedType, setModifiedType] = useState<boolean>(false);

  const navigate = useNavigate();

  const handleNavigate = () => {
    navigate(-1);
  };

  const fetchBranchDetails = async () => {
    setLoading(true);
    try {
      const response = await api.get(`/branches/${branchId}`);
      setBranchDetails(response?.data?.data);

      if (response?.data?.data?.bank_details?.[1]?.type) {
        setType(response?.data?.data?.bank_details?.[1]?.type);
      }
      const bankDetails = response?.data?.data?.bank_details;

      if (bankDetails && bankDetails.length > 0) {
        const localBankDetail = bankDetails.find(
          (detail: any) => detail.type === "International"
        );

        if (localBankDetail) {
          setType(localBankDetail.type);

          const metadata = localBankDetail.bank_detail_meta;

          if (metadata) {
            const updatedFields = metadata.map((item: any) => ({
              id: item.id,
              key: item.key,
              value: item.value,
              editable: false,
            }));
            setDynamicFields(updatedFields);
          }
        }
      }
    } catch (error: any) {}
    setLoading(false);
  };

  const formik = useFormik({
    initialValues: {
      bank_details: [{ type: "", bank_detail_meta: [] }],
    },
    onSubmit: async (values: any) => {
      const newFields = dynamicFields.filter(
        (field) => field.editable && !field.id
      );
      const editedFields = dynamicFields.filter(
        (field) => field.editable && field.id
      );
      const bankDetails = values.bank_details.map((detail: any) => ({
        type: modifiedType
          ? values && values.bank_details[0] && values.bank_details[0].type
          : branchDetails &&
            branchDetails.bank_details[0] &&
            branchDetails.bank_details[0].type,
        bank_detail_meta: [
          ...detail.bank_detail_meta,
          ...dynamicFields.filter(
            (field) => field.key !== "" || field.value !== ""
          ),
        ],
      }));

      const updatedValues = {
        ...values,
        bank_details: bankDetails,
      };

      try {
        await Promise.all(
          newFields.map(async (field) => {
            await api.post("/bank-detail-metas", {
              bank_detail_id:
                branchDetails &&
                branchDetails.bank_details &&
                branchDetails.bank_details[0] &&
                branchDetails.bank_details[0].id,
              key: field.key,
              value: field.value,
            });
          })
        );

        await Promise.all(
          editedFields.map(async (field) => {
            await api.put(`/bank-detail-metas/${field.id}`, {
              key: field.key,
              value: field.value,
            });
          })
        );

        await api.put(`/bank-details/${branchDetails.bank_details[0].id}`, {
          type: updatedValues.bank_details[0].type,
        });

        toast.success("Bank details updated successfully");
        setTimeout(() => {
          navigate(-1);
        }, 1000);
      } catch (error) {
        toast.error("Failed to update bank details");
      }
    },
  });

  const handleAddField = () => {
    const newField = { key: "", value: "", editable: true };
    const updatedFields = [...dynamicFields, newField];
    setDynamicFields(updatedFields);
  };

  const handleTypeChange = (event: SelectChangeEvent) => {
    const selectedType = event.target.value;
    setType(selectedType);
    setModifiedType(true);
    formik.setFieldValue("bank_details", [
      { type: selectedType, bank_detail_meta: dynamicFields },
    ]);
  };

  const handleDeleteField = async (index: number) => {
    const metaIdToDelete = dynamicFields[index].id;
    if (metaIdToDelete) {
      try {
        await api.delete(`/bank-detail-metas/${metaIdToDelete}`);
        toast.success("Field deleted successfully");
      } catch (error) {
        toast.error("Failed to delete field");
        return;
      }
    }

    const updatedFields = dynamicFields.filter((_, i) => i !== index);
    setDynamicFields(updatedFields);
  };

  const handleEditField = (index: number) => {
    const updatedFields = [...dynamicFields];
    updatedFields[index] = { ...updatedFields[index], editable: true };
    setDynamicFields(updatedFields);
  };

  useEffect(() => {
    if (branchId) {
      fetchBranchDetails();
    }
  }, [branchId]);

  return (
    <>
      <div>
        <Nav step={0} isStepperVisible={false} />
        <div className="content-container contentContainerEditBankDetails">
          <ThemeProvider theme={defaultTheme}>
            <Container component="main">
              {loading ? (
                <LoadingIndicator />
              ) : (
                <>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <div className="d-flex ai-center">
                      <p
                        className="back-button-icon circle-around-text-smaller helvetica mr-1-rem cp"
                        onClick={handleNavigate}
                      >
                        <ArrowBackIcon className="muiIcons" />
                      </p>
                      <Typography
                        className="ta-center pageTitleFontSize"
                        component="h1"
                        variant="h5"
                        sx={{
                          fontFamily: "Helvetica, sans-serif",
                          fontWeight: "600",
                          color: "#0e0f0c",
                          maxWidth: "500px",
                          whiteSpace: "nowrap",
                          textOverflow: "ellipsis",
                          overflow: "hidden",
                        }}
                      >
                        Edit international details
                      </Typography>
                    </div>
                  </Box>
                  <form onSubmit={formik.handleSubmit}>
                    <Box sx={{ mt: "4rem" }}>
                      <div className="label-and-field mb-1rem">
                        <Typography
                          className="d-flex jc-start registration0875RemFontSize"
                          component="h1"
                          variant="h5"
                          sx={{
                            fontFamily: "Helvetica, sans-serif",
                            fontWeight: "300",
                            color: "#454745",
                            marginBottom: "8px",
                          }}
                        >
                          Type
                        </Typography>

                        <Select
                          id="type"
                          name="type"
                          placeholder="Select type"
                          value={type}
                          fullWidth
                          onChange={handleTypeChange}
                          sx={{
                            borderRadius: "10px",
                            height: "48px",
                            fontSize: "16px",
                            "&:-internal-autofill-selected": {
                              backgroundColor: "transparent !important",
                            },
                            "@media (max-width:600px)": {
                              fontSize: "14px !important",
                            },
                          }}
                        >
                          <MenuItem
                            value={"Local"}
                            disabled={
                              branchDetails &&
                              branchDetails.bank_details &&
                              branchDetails.bank_details.some(
                                (detail: any) => detail.type === "Local"
                              )
                            }
                          >
                            Local
                          </MenuItem>
                          <MenuItem
                            value={"International"}
                            disabled={
                              branchDetails &&
                              branchDetails.bank_details &&
                              branchDetails.bank_details.some(
                                (detail: any) => detail.type === "International"
                              )
                            }
                          >
                            International
                          </MenuItem>
                        </Select>
                      </div>
                      {dynamicFields.map((field, index) => (
                        <div key={index} className="addedFieldsBox">
                          <Box className="addedFieldsAndIconsBox">
                            <TextField
                              className="fieldwidth100"
                              placeholder={`Title (etc. IBAN)`}
                              value={field.key}
                              InputProps={{
                                sx: {
                                  borderRadius: "10px",
                                  height: "48px",
                                  fontSize: "16px",
                                  "&:-internal-autofill-selected": {
                                    backgroundColor: "transparent !important",
                                  },
                                  "@media (max-width:600px)": {
                                    fontSize: "14px !important",
                                  },
                                },
                              }}
                              onChange={(e) => {
                                const updatedFields = [...dynamicFields];
                                updatedFields[index].key = e.target.value;
                                setDynamicFields(updatedFields);
                              }}
                              disabled={!field.editable}
                            />
                            <TextField
                              placeholder={`Value (etc.DE75512108001245126199)`}
                              value={field.value}
                              className="fieldwidth100"
                              InputProps={{
                                sx: {
                                  borderRadius: "10px",
                                  height: "48px",
                                  fontSize: "16px",
                                  "&:-internal-autofill-selected": {
                                    backgroundColor: "transparent !important",
                                  },
                                  "@media (max-width:600px)": {
                                    fontSize: "14px !important",
                                  },
                                },
                              }}
                              onChange={(e) => {
                                const updatedFields = [...dynamicFields];
                                updatedFields[index].value = e.target.value;
                                setDynamicFields(updatedFields);
                              }}
                              disabled={!field.editable}
                            />
                          </Box>
                          <Box className="editDeleteIcons">
                            {field.editable ? null : (
                              <IconButton
                                onClick={() => handleEditField(index)}
                              >
                                <EditIcon
                                  className="muiIcons"
                                  style={{
                                    cursor: "pointer",
                                    color: "#2A5182",
                                  }}
                                />
                              </IconButton>
                            )}
                            <IconButton
                              onClick={() => handleDeleteField(index)}
                            >
                              <DeleteIcon
                                className="muiIcons"
                                style={{ cursor: "pointer" }}
                                color="error"
                              />
                            </IconButton>
                          </Box>
                        </div>
                      ))}
                      <div className="label-and-field mb-1rem">
                        <Button
                          className="addDetailsBtn btnsLoginHeight oneRemFontSize"
                          onClick={handleAddField}
                          startIcon={
                            <ControlPointOutlinedIcon className="muiIcons" />
                          }
                        >
                          Add Details
                        </Button>
                      </div>
                    </Box>
                    <Button
                      fullWidth
                      variant="contained"
                      type="submit"
                      className="btnsLoginHeight oneRemFontSize"
                      sx={{
                        color: "white",
                        backgroundColor: "#2A5182",
                        borderRadius: "24px",
                        textTransform: "none",
                        marginTop: "2rem",
                        fontFamily: "Helvetica, sans-serif",
                        marginBottom: "4rem",
                      }}
                    >
                      Save
                    </Button>
                  </form>
                </>
              )}
            </Container>
          </ThemeProvider>
        </div>
      </div>
    </>
  );
}
