import { Box, Typography } from "@mui/material";
import i18next from "i18next";
import React from "react";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
function VerificationStep() {
  return (
    <div className="content-container-profile main-color animated-text moveInRight">
      <Typography
        className="pageTitleFontSize"
        component="h1"
        variant="h5"
        sx={{
          fontFamily: "Helvetica, sans-serif",
          fontWeight: "600",
          color: "#0e0f0c",
          maxWidth: "500px",
          whiteSpace: "nowrap",
          textOverflow: "ellipsis",
          overflow: "hidden",
        }}
      >
        {i18next.t("Video identification")}
      </Typography>

      <Typography sx={{ mt: "20px", mb: "50px" }}>
        {i18next.t(
          "Video identification link is sent to your email. Please follow the email and proceed with video identification and terms approval."
        )}
        <br />
        <br />
      </Typography>
      <Box className="clock-section">
        <AccessTimeIcon />
        <Typography>{i18next.t("Video identification in process")}</Typography>
      </Box>
    </div>
  );
}

export default VerificationStep;
