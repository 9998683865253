import { Typography, Box, Button, Modal, IconButton } from "@mui/material";
import Logo from "src/assets/images/logoPay.png";
import { useNavigate } from "react-router-dom";
import "./HeaderAvatarIconButton.scss";
import CloseIcon from "@mui/icons-material/Close";

const HeaderAvatarModal = ({ user, onClose }: any) => {
  const navigate = useNavigate();

  return (
    <Modal
      open={true}
      onClose={onClose}
      BackdropProps={{
        sx: { backgroundColor: "rgba(0, 0, 0, 0.8)" },
      }}
    >
      <Box className="headerAvatarModalMain">
        <Box
          sx={{
            marginBottom: "20px",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <img src={Logo} alt="VicPay Logo" className="header-logo" />
          <IconButton onClick={onClose}>
            <CloseIcon
              style={{
                color: "rgb(11,15,20)",
                minWidth: "25px",
                maxWidth: "25px",
                minHeight: "25px",
                maxHeight: "25px",
              }}
            />
          </IconButton>
        </Box>
        <Box sx={{ marginBottom: "20px", marginRight: "8px" }}>
          <Typography className="headerAvatarMenuTextMob">
          Your partner in worldwide money and crypto payments!
          </Typography>
        </Box>
        <Box>
          {user && user.id ? (
            <Button
              onClick={(e) => {
                navigate("/home");
              }}
              className="headerAvatarMenuSignupMob"
            >
              My Account
            </Button>
          ) : (
            <>
              <Button
                className="headerAvatarMenuLoginMob"
                onClick={(e) => {
                  navigate("/login");
                  onClose();
                }}
              >
                Log in
              </Button>
              <Button
                className="headerAvatarMenuSignupMob"
                onClick={(e) => {
                  navigate("/registration");
                  onClose();
                }}
              >
                Sign up
              </Button>
            </>
          )}
        </Box>
      </Box>
    </Modal>
  );
};

export default HeaderAvatarModal;
