import { Box, Radio, Typography } from "@mui/material";
import "./FiltersDrawer.scss";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import All from "../../assets/images/all.png";
import { useEffect, useState } from "react";
import i18next from "src/i18n";

interface FiltersProps {
  selectedFilters: any;
  handleSelectedFilters: (filters: any) => void;
}
function DirectionFilters({
  selectedFilters,
  handleSelectedFilters,
}: FiltersProps) {
  const isBankSelected = selectedFilters?.types?.methods?.includes("Bank");
  const isCardSelected = selectedFilters?.types?.methods?.includes("Card");
  const isBalanceSelected =
    selectedFilters?.types?.methods?.includes("Balance");

  const [directionArray, setDirectionArray] = useState<string[]>([]);

  useEffect(() => {
    if (!isBankSelected && !isCardSelected && !isBalanceSelected) {
      handleToggleDirection("All");
      setDirectionArray(["all"]);
    } else if ((isBankSelected || isCardSelected) && !isBalanceSelected) {
      handleToggleDirection("in");
      setDirectionArray(["in"]);
    } else if (isBalanceSelected && !isBankSelected && !isCardSelected) {
      handleToggleDirection("All");
      setDirectionArray(["all", "in", "out"]);
    } else if (isBalanceSelected && (isBankSelected || isCardSelected)) {
      handleToggleDirection("All");
      setDirectionArray(["all"]);
    } else if (isBankSelected && isCardSelected && isBalanceSelected) {
      handleToggleDirection("All");
      setDirectionArray(["all"]);
    }
  }, [isBankSelected, isCardSelected, isBalanceSelected]);

  const [selectedDirection, setSelectedDirection] = useState<string | null>(
    selectedFilters.direction
      ? selectedFilters.direction.selectedDirection
      : null
  );

  const handleToggleDirection = (direction: string) => {
    const newFilters = {
      ...selectedFilters,
      direction: {
        ...selectedFilters.direction,
        selectedDirection: direction,
      },
    };

    setSelectedDirection(direction);
    handleSelectedFilters(newFilters);
    // }
  };

  // const selectedDirection = selectedFilters.direction
  //   ? selectedFilters.direction.selectedDirection
  //   : null;
  const radioStyles = {
    color: "#2a5182",
  };

  return (
    <Box>
      <Typography className="subBodies">{i18next.t("Direction")}</Typography>
      {directionArray.includes("all") && (
        <Box
          className="selectableBoxesHover"
          onClick={() => handleToggleDirection("All")}
        >
          <Box className="imageDirectionsAndRadio">
            <Box className="imageDirectionAndText">
              <Box className="imageDirectionBG">
                <img
                  style={{ objectFit: "cover" }}
                  alt="All"
                  className="allImage"
                  src={All}
                />
              </Box>
              <Typography className="directionText">
                {i18next.t("All")}
              </Typography>
            </Box>
            <Radio
              checked={selectedDirection === "All"}
              value="All"
              name="radio-buttons-direction"
              style={radioStyles}
              inputProps={{ "aria-label": "" }}
              sx={{
                "& .MuiSvgIcon-root": {
                  fontSize: 24,
                },
              }}
            />
          </Box>
        </Box>
      )}
      {/* {!isBankSelected && (
      <Box
        className="selectableBoxesHover"
        onClick={() => handleToggleDirection("balance_in")}
      >
        <Box className="imageDirectionsAndRadio">
          <Box className="imageDirectionAndText">
            <Box className="imageDirectionBG">
              <ArrowDownwardIcon className="searchIconTransactionFilters" />
            </Box>
            <Typography className="directionText">
              Account transfer received
            </Typography>
          </Box>
          <Radio
            checked={selectedDirection === "balance_in"}
            value="balance_in"
            style={radioStyles}
            name="radio-buttons-direction"
            inputProps={{ "aria-label": "" }}
            sx={{
              "& .MuiSvgIcon-root": {
                fontSize: 24,
              },
            }}
          />
        </Box>
      </Box>
      )} */}
      {directionArray.includes("in") && (
        <Box
          className="selectableBoxesHover"
          onClick={() => handleToggleDirection("in")}
        >
          <Box className="imageDirectionsAndRadio">
            <Box className="imageDirectionAndText">
              <Box className="imageDirectionBG">
                <ArrowUpwardIcon className="searchIconTransactionFilters" />
              </Box>
              <Typography className="directionText">
                {i18next.t("In")}
              </Typography>
            </Box>
            <Radio
              checked={selectedDirection === "in"}
              style={radioStyles}
              value="in"
              name="radio-buttons-direction"
              inputProps={{ "aria-label": "" }}
              sx={{
                "& .MuiSvgIcon-root": {
                  fontSize: 24,
                },
              }}
            />
          </Box>
        </Box>
      )}
      {directionArray.includes("out") && (
        <Box
          className="selectableBoxesHover"
          onClick={() => handleToggleDirection("out")}
        >
          <Box className="imageDirectionsAndRadio">
            <Box className="imageDirectionAndText">
              <Box className="imageDirectionBG">
                <ArrowDownwardIcon className="searchIconTransactionFilters" />
              </Box>
              <Typography className="directionText">
                {i18next.t("Out")}
              </Typography>
            </Box>
            <Radio
              checked={selectedDirection === "out"}
              style={radioStyles}
              value="out"
              name="radio-buttons-direction"
              inputProps={{ "aria-label": "" }}
              sx={{
                "& .MuiSvgIcon-root": {
                  fontSize: 24,
                },
              }}
            />
          </Box>
        </Box>
      )}
      {/* <Box
        className="selectableBoxesHover"
        onClick={() => handleToggleDirection("m_transfer_out")}
      >
         <Box className="imageDirectionsAndRadio">
          <Box className="imageDirectionAndText">
            <Box className="imageDirectionBG">
              <ArrowUpwardIcon className="searchIconTransactionFilters" />
            </Box>
            <Typography className="directionText">Transfer sent</Typography>
          </Box>
          <Radio
            checked={selectedDirection === "m_transfer_out"}
            style={radioStyles}
            value="m_transfer_out"
            name="radio-buttons-direction"
            inputProps={{ "aria-label": "" }}
            sx={{
              "& .MuiSvgIcon-root": {
                fontSize: 24,
              },
            }}
          />
        </Box> 
      </Box> */}
    </Box>
  );
}

export default DirectionFilters;
