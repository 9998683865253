import { useState } from "react";
import { MenuItem, Typography, Box, Divider } from "@mui/material";
import "./FooterMobileMenu.scss";
import { CSSTransition } from "react-transition-group";
import AddIcon from "@mui/icons-material/Add";
import { useLocation } from "react-router-dom";

const FooterMobileMenu = ({ triggerText, menuItems, smoothScrollTo }: any) => {
  const [showText, setShowText] = useState(false);

  const location = useLocation();

  const handleClick = () => {
    setShowText(!showText);
  };

  const triggerTextActiveClass =
    (location.pathname === "/about-us" ||
      location.pathname === "/solutions" ||
      location.pathname === "/security" ||
      location.pathname === "/victorum-group" ||
      location.pathname === "/we-hire" ||
      location.pathname === "/media-press" ||
      location.pathname === "/contact-us") &&
    triggerText === "Company"
      ? "nav-link-mobileFActive"
      : (location.pathname === "/faq" ||
          location.pathname === "/contact-us" ||
          location.pathname === "/security" ||
          location.pathname === "/safety-of-funds" ||
          location.pathname === "/regulation" ||
          location.pathname === "/download-app" ||
          location.pathname === "/complains") &&
        triggerText === "Support"
      ? "nav-link-mobileFActive"
      : (location.pathname === "/instant-transfers" ||
          location.pathname === "/cryptos" ||
          location.pathname === "/visa-card" ||
          location.pathname === "/mezzanine-capital" ||
          location.pathname === "/victorum-market" ||
          location.pathname === "/iban-accounts") &&
        triggerText === "Services"
      ? "nav-link-mobileFActive"
      : (location.pathname === "/copyright" ||
          location.pathname === "/fees" ||
          location.pathname === "/privacy-policy" ||
          location.pathname === "/cookies-policy" ||
          location.pathname === "/aml-policy" ||
          location.pathname === "/declaration") &&
        triggerText === "Legal & Fees"
      ? "nav-link-mobileFActive"
      : "nav-link-mobileF";

  return (
    <>
      <MenuItem
        style={{
          justifyContent: "flex-start",
          paddingLeft: "0px",
          paddingBottom: "16px",
          paddingTop: "16px",
        }}
        onClick={handleClick}
      >
        <Box className="menuItemTextAndIconBox">
          <Typography className={`nav-link-mobileF ${triggerTextActiveClass}`}>
            {triggerText}
          </Typography>
          <AddIcon
            className="plus_icon"
            sx={{
              transform: showText ? "rotate(45deg)" : "rotate(0deg)",
              transition: "transform 0.3s ease-in-out",
            }}
          />
        </Box>
      </MenuItem>
      <CSSTransition
        in={showText}
        classNames="headerMobileMenuSlide"
        timeout={300}
        unmountOnExit
      >
        <Box className="mobileMenuItemsBoxF">
          {menuItems.map((item: any, index: number) =>
            item.route ? (
              <Typography
                className={`mobileMenuItemsTextFS ${
                  location.pathname === item.route
                    ? "mobileMenuItemsTextFSActive"
                    : ""
                }`}
                key={index}
                onClick={() => {
                  smoothScrollTo(item.route);
                }}
              >
                {item.text}
              </Typography>
            ) : (
              <Typography className="mobileMenuItemsTextFS" key={index}>
                {item.text}
              </Typography>
            )
          )}
        </Box>
      </CSSTransition>
      <Divider style={{ backgroundColor: "#e4e4e4" }} />
    </>
  );
};

export default FooterMobileMenu;
