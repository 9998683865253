import { Box, Typography } from "@mui/material";
import React from "react";
import "./ImagesWithText.scss";
import i18next from "i18next";

function ImagesWithText() {
  return (
    <Box className="logoContainer">
      <Box className="logoPayVvidget2 logoPayVvidget3">
        <Box>
          <Typography className="textsLandingPageHeading">
            {i18next.t("Our partner")}
          </Typography>
          <Typography
            paragraph
            className="textsLandingPage"
            sx={{ color: "#fff !important" }}
          >
            {i18next.t(
              "Do you only want one partner for digital transfers, cryptocurrencies, and currency exchange"
            )}
            {i18next.t("Security and data protection are your top priorities")}
          </Typography>
        </Box>
      </Box>
      <Box className="backgroundBlankContainer payBgContainer">
        <Typography className="partnerHeading">{i18next.t("Secure Discounts Everywhere")}</Typography>

        <Typography className="partnerDesc">
          {i18next.t(
            "Enjoy discounts shopping online and offline and be sure that the highest measures of security are protecting your online account and your funds"
          )}
        </Typography>
      </Box>
    </Box>
  );
}

export default ImagesWithText;
