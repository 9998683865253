// ProtectedRoutes.js or ProtectedRoutes.tsx
import React from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { useAppSelector } from "src/store";
import InactivityTimeout from "../shared/InactivityTimeout/InactivityTimeout";
import { useUser } from "src/hooks/useUser";

const ProtectedRoutes = () => {
  const location = useLocation();
  const { token } = useAppSelector((state) => state.auth);

  const isLocalhost = window.location.hostname === "localhost";
  const user = useUser();

  return token ? (
    // isLocalhost ||
     user?.role === "Admin" || user?.role === "Employee" || location.pathname.startsWith('/banking') ? (
      <Outlet />
    ) : (
      <InactivityTimeout>
        <Outlet />
      </InactivityTimeout>
    )
  ) : (
    <Navigate to="/login" replace />
  );
};

export default ProtectedRoutes;
