import * as React from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { COUNTRIES } from "src/components/shared/constants";
import { useEffect } from "react";
import { Typography } from "@mui/material";
import "./CountrySelect.scss";
import i18next from "i18next";
import ErrorMessage from "../CustomInput/ErrorMessage";

interface Props {
  onSelect: any;
  isTouched: any;
  errors: any;
  value: any;
  disabled?: boolean;
  showTitle?: boolean;
  showLabel?: boolean;
  code?: boolean;
  isEnterpaymentStyle?: boolean
}

export default function CountrySelect({
  onSelect,
  isTouched,
  value,
  errors,
  disabled,
  showTitle = false,
  showLabel = true,
  code = false,
  isEnterpaymentStyle = false
}: Props) {
  const findCountryByLabel = (label: any) => {
    return COUNTRIES.find((country) => country.label === label);
  };

  const findCountryByCode = (code: any) => {
    return COUNTRIES.find((country) => country.code === code);
  };

  let foundCountry = findCountryByLabel(value);

  if (!foundCountry) {
    // If foundCountry is undefined or null
    foundCountry = findCountryByCode(value);
  }

  const [selectedCountry, setSelectedCountry] = React.useState(
    foundCountry ?? null
  );

  useEffect(() => {
    if (foundCountry) setSelectedCountry(foundCountry);
  }, [foundCountry]);

  return (
    <Box sx={{ width: "100%" }}>
      <Autocomplete
        id="country-select-demo"
        sx={{
          width: "100%",
          borderRadius: isEnterpaymentStyle ? "10px" : "16px",
        }}
        options={COUNTRIES}
        autoHighlight
        getOptionLabel={(option) => option.label}
        disabled={disabled}
        value={selectedCountry}
        onChange={(event, newValue) => {
          if (newValue) setSelectedCountry(newValue);
          onSelect(
            newValue && !code ? newValue.label : code ? newValue?.code : ""
          );
        }} // Pass the selected value to the parent
        renderOption={(props, option) => (
          <Box
            component="li"
            sx={{ "& > img": { mr: 2, flexShrink: 0, borderRadius: "10px" } }}
            {...props}
          >
            {option.label}
          </Box>
        )}
        renderInput={(params) => (
          <>
            {showTitle && (
              <Box>
                <Typography className="selectCountryLabel registration0875RemFontSize">
                  {i18next.t("Select a country")}
                </Typography>
              </Box>
            )}
            <Box className="customInputContainer">
              <Box
                className={`${isEnterpaymentStyle ? "customInputSubContainerEnterpayment" : "customInputSubContainer "} ${
                  errors
                    ? isEnterpaymentStyle ? "customInputSubContainerError customInputSubContainerErrorEnterpayment" : "customInputSubContainerError"
                    : "customInputSubContainer"
                }`}
              >
                <TextField
                  {...params}
                  placeholder={showLabel ? i18next.t("Choose Country") : ""}
                  value={value}
                  inputProps={{
                    ...params.inputProps,
                    autoComplete: "off", // disable autocomplete and autofill
                    borderRadius: isEnterpaymentStyle ? "10px" : "16px",
                    sx: {
                      "input:-webkit-autofill, input:-webkit-autofill:hover, input:-webkit-autofill:focus, input:-webkit-autofill:active":
                        {
                          borderRadius: isEnterpaymentStyle ? "10px !important" : "16px !important",
                          "-webkit-text-fill-color": "inherit",
                          "-webkit-box-shadow": errors
                            ? "0 0 0px 1000px #feedec inset !important"
                            : "0 0 0px 1000px #e0e3e7 inset !important",
                          backgroundColor: isEnterpaymentStyle ? "red !important" :  "#e0e3e7 !important",
                        },
                    },
                  }}
                  error={isTouched && errors !== null && errors !== undefined}
                  // helperText={isTouched && errors ? errors.toString() : ""}
                  sx={{
                    "& .MuiAutocomplete-input": {
                      padding: "0px 4px 0px 5px !important",
                    },
                    "& .MuiAutocomplete-inputRoot": {
                      paddingLeft: "16px !important",
                      paddingTop: "16px !important",
                      paddingBottom: "16px !important",
                      borderRadius: isEnterpaymentStyle ? "10px" : "16px",
                      height: "55px",
                      "& fieldset": {
                        border: "none",
                      },
                      "&:hover fieldset": {
                        border: "none",
                      },
                      "&.Mui-focused fieldset": {
                        border: "none",
                      },
                    },
                    "@media (max-width: 600px)": {
                      "& .MuiAutocomplete-inputRoot": {
                        fontSize: "16px",
                      },
                    },
                  }}
                />
              </Box>
            </Box>
          </>
        )}
      />
      {isTouched && errors && (
        <ErrorMessage style={{ marginLeft: 16 }} message={errors.toString()} />
      )}
    </Box>
  );
}
