import React from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Box, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import HeaderLanding from "../components/HeaderLanding/HeaderLanding";
import { useAppSelector } from "src/store";
import FooterLanding from "../components/FooterLanding/FooterLanding";
import "./LandingPagesGlobal.scss";
import TitleBackBox from "../components/TitleBackBox/TitleBackBox";
import HeadlineVvidgetSmall from "../components/HeadlineVvidget/HeadlineVvidgetSmall";
import LogoTextVvidget from "../components/LogoTextVvidget/LogoTextVvidget";
import ImageWithText from "../components/ImageWithText/ImageWithText";
import CryptoImg from "../../../assets/images/cryptoImg.jpg";
import TitleTextVvidget from "../components/TitleTextVvidget/TitleTextVvidget";
import i18next from "i18next";

const defaultTheme = createTheme();

const SafetyOfFunds: React.FC = () => {
  const { user } = useAppSelector((state) => state.auth);

  const navigate = useNavigate();

  const navigateToContactUs = (event: any) => {
    navigate("/contact-us");
  };

  return (
    <div>
      <HeaderLanding user={user} />
      <div className="margintTopPagesMainCont">
        <ThemeProvider theme={defaultTheme}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Box className="titleHeadlineSmallBG">
              <Box className="landingPagesMainBox">
                <Box>
                  <TitleBackBox title={i18next.t("Safety of funds")} />
                </Box>
                <Box>
                  <HeadlineVvidgetSmall
                    headline={
                      i18next.t("A safe harbour for your fiat and crypto currencies")
                    }
                    subtitle={
                      i18next.t("VicPay takes a proactive approach to client protection - far beyond the required regulation guidelines")
                    }
                    smallText={i18next.t("Contact our support team")}
                    buttonText={i18next.t("Contact Us")}
                    onClickFunction={navigateToContactUs}
                  />
                </Box>
              </Box>
            </Box>
            <Box className="landingPagesMainBox">
              <Box>
                <ImageWithText
                  backgroundImage={CryptoImg}
                  noiseImage="/img/payments-noise.png"
                  title={i18next.t("It´s actually not so difficult")}
                  text={i18next.t("stay transparent, professional and reliable - and clients will always return")}
                />
              </Box>

              <Box
                sx={{
                  paddingBottom: "8px",
                  "@media(max-width: 1200px)": {
                    paddingBottom: "0px",
                    marginTop: "112px",
                  },
                  "@media(max-width: 1023px)": {
                    marginTop: "40px",
                  },
                }}
              >
                <TitleTextVvidget
                  title={i18next.t("Secure Funding with VicPay")}
                  text={
                    i18next.t("Once you have funded your VicPay account, you can be sure that it is secured and protected, no matter under which market circumstances")
                  }
                />
              </Box>

              <Box>
                <LogoTextVvidget
                  text={
                    i18next.t("Our team attaches the highest degree of importance to the optimal protection and security of our clients using the latest system technology supported by AI systems")
                  }
                />
              </Box>
              <Box>
                <Typography
                  variant="h3"
                  sx={{
                    marginBottom: "1rem",
                  }}
                  className="headlineMiniLandingPages"
                >
                  {i18next.t("Secure Account Protection")}
                </Typography>
                <Typography
                  variant="body1"
                  paragraph
                  className="textsLandingPage"
                >
                  {i18next.t("At VicPay your account balance is protected by very strict security measures against any fraudulent access and VicPay is regulated by FINRA, the Canadian Financial Services Authority")}
                </Typography>
              </Box>
              <Box>
                <Typography
                  variant="h3"
                  sx={{
                    marginBottom: "1rem",
                  }}
                  className="headlineMiniLandingPages"
                >
                  {i18next.t("Continuous Security Enhancements at VicPay")}
                </Typography>
                <Typography
                  variant="body1"
                  paragraph
                  className="textsLandingPage"
                >
                  {i18next.t("Further we are continuously improving the technical security measures in addition to your login detailsO and our system monitors in real-time all activities and only makes changes after you have personally verified them based on some security questions and/or security codes")}
                </Typography>
              </Box>
              <Box>
                <Typography
                  variant="h3"
                  sx={{
                    marginBottom: "1rem",
                  }}
                  className="headlineMiniLandingPages"
                >
                  {i18next.t("Immediate Support")}
                </Typography>
                <Typography
                  variant="body1"
                  paragraph
                  className="textsLandingPage"
                >
                  {i18next.t("In case of doubt our dedicated security team gets in touch with you via video chat in order to verify unusual activities")}
                </Typography>
                <Typography
                  variant="body1"
                  paragraph
                  className="textsLandingPage"
                >
                  {i18next.t("In case of any questions regarding account and fund security, please do not hesitate to get in touch with our dedicated security team. Just dial +1 604-260-0738 or drop a message to")}
                  <a
                    href="mailto:info@vicpayments.com"
                    className="linksHover"
                    style={{ marginLeft: "5px" }}
                  >
                    info@vicpayments.com
                  </a>
                  !
                </Typography>
              </Box>
            </Box>
          </Box>
        </ThemeProvider>
      </div>
      <Box sx={{ marginTop: "4rem" }}>
        <FooterLanding />
      </Box>
    </div>
  );
};

export default SafetyOfFunds;
