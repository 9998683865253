import { Button, Typography } from "@mui/material";
import i18next from "i18next";
import React from "react";
import { useNavigate, useParams } from "react-router-dom";

interface Props {
  user: any;
  loggedUser: string;
  path: string
}

function UserBankingDetails({ user, loggedUser, path }: Props) {
 const navigate = useNavigate()
 const { userId } = useParams();

  const renderEditButton = () => {
    if (loggedUser === "Employee" && user && user.role !== "Admin") {
      return true;
    } else if (loggedUser === "Employee" && user && user.role === "Admin") {
      return false;
    }
    return true;
  };

  const handleEditPersonal = (event: any) => {
   if(path === 'admin'){
    navigate(`/admin/users/${userId}/edit-banking`, { state: { user } });
   }else{
    navigate(`/edit-banking`);

   }
  };
  return (
    <div>
      <div>
        <div className="d-flex jusifyContentSpaceBetween ai-center mb-1rem">
          <Typography
            className="mb-1rem  d-flex jc-start pageTitleFontSize"
            component="h1"
            variant="h5"
            sx={{
              fontFamily: "Helvetica, sans-serif",
              fontWeight: "600",
              marginTop: "2rem",
            }}
          >
            {i18next.t("Business Account")}
          </Typography>

          {renderEditButton() && (
            <Button
              variant="contained"
                onClick={handleEditPersonal}
              className="btnsLoginHeight oneRemFontSize"
              sx={{
                color: "#000000",
                backgroundColor: "transparent",
                borderRadius: "24px",
                boxShadow: "none",
                border: "1px solid #000000",
                fontWeight: 400,
                textTransform: "none",
                fontFamily: "Helvetica, sans-serif",
                "&:hover": {
                  backgroundColor: "#2A5182",
                  color: "white",
                },
              }}
            >
              {i18next.t("Edit")} {i18next.t("Business Account")}
            </Button>
          )}
        </div>

        <div className="personal-box">
          <div className="one-flex-line">
            <div className="desc-name-box">
              <Typography
                className="mb-1rem  d-flex jc-start registration0875RemFontSize"
                component="h1"
                variant="h5"
                sx={{
                  fontFamily: "Helvetica, sans-serif",
                  fontWeight: ".875rem",
                  color: "#454745",
                }}
              >
                {i18next.t("IBAN needs")}
              </Typography>

              <Typography
                className="mb-1rem  d-flex jc-start registration0875RemFontSize"
                component="h1"
                variant="h5"
                sx={{
                  fontFamily: "Helvetica, sans-serif",
                  fontWeight: ".875rem",
                  color: "#454745",
                }}
              >
                {user?.iban_needs ?? "-"}
              </Typography>
            </div>

            <div className="desc-name-box">
              <Typography
                className="mb-1rem  d-flex jc-start registration0875RemFontSize"
                component="h1"
                variant="h5"
                sx={{
                  fontFamily: "Helvetica, sans-serif",
                  fontWeight: ".875rem",
                  color: "#454745",
                }}
              >
                {i18next.t("Pay-out currencies")}
              </Typography>

              <Typography
                className="mb-1rem  d-flex jc-start registration0875RemFontSize"
                component="h1"
                variant="h5"
                sx={{
                  fontFamily: "Helvetica, sans-serif",
                  fontWeight: ".875rem",
                  color: "#454745",
                }}
              >
                {user?.pay_out_currency ?? "-"}
              </Typography>
            </div>
          </div>

          <div className="one-flex-line">
            <div className="desc-name-box">
              <Typography
                className="mb-1rem  d-flex jc-start registration0875RemFontSize"
                component="h1"
                variant="h5"
                sx={{
                  fontFamily: "Helvetica, sans-serif",
                  fontWeight: ".875rem",
                  color: "#454745",
                }}
              >
                {i18next.t("Pay-in currencies")}
              </Typography>

              <Typography
                className="mb-1rem  d-flex jc-start registration0875RemFontSize"
                component="h1"
                variant="h5"
                sx={{
                  fontFamily: "Helvetica, sans-serif",
                  fontWeight: ".875rem",
                  color: "#454745",
                }}
              >
                {user?.pay_in_currency ?? "-"}
              </Typography>
            </div>

            <div className="desc-name-box">
              <Typography
                className="mb-1rem  d-flex jc-start registration0875RemFontSize"
                component="h1"
                variant="h5"
                sx={{
                  fontFamily: "Helvetica, sans-serif",
                  fontWeight: ".875rem",
                  color: "#454745",
                }}
              >
                {i18next.t("Volume of money out")}
              </Typography>

              <Typography
                className="mb-1rem  d-flex jc-start registration0875RemFontSize"
                component="h1"
                variant="h5"
                sx={{
                  fontFamily: "Helvetica, sans-serif",
                  fontWeight: ".875rem",
                  color: "#454745",
                }}
              >
                {user?.volume_of_money_out ?? "-"}
              </Typography>
            </div>
          </div>

          <div className="one-flex-line">
            <div className="desc-name-box">
              <Typography
                className="mb-1rem  d-flex jc-start registration0875RemFontSize"
                component="h1"
                variant="h5"
                sx={{
                  fontFamily: "Helvetica, sans-serif",
                  fontWeight: ".875rem",
                  color: "#454745",
                }}
              >
                {i18next.t("Volume of money in")}
              </Typography>

              <Typography
                className="mb-1rem  d-flex jc-start registration0875RemFontSize"
                component="h1"
                variant="h5"
                sx={{
                  fontFamily: "Helvetica, sans-serif",
                  fontWeight: ".875rem",
                  color: "#454745",
                }}
              >
                {user?.volume_of_money_in ?? "-"}
              </Typography>
            </div>
          </div>
        </div>
      </div>

      <div>
        <Typography
          className="mb-1rem  d-flex jc-start pageTitleFontSize"
          component="h1"
          variant="h5"
          sx={{
            fontFamily: "Helvetica, sans-serif",
            fontWeight: "600",
            marginTop: "2rem",
          }}
        >
          {i18next.t("Business description")}
        </Typography>

        <div className="personal-box">
          <div className="">
            <Typography
              className="mb-1rem  d-flex jc-start registration0875RemFontSize"
              component="h1"
              variant="h5"
              sx={{
                fontFamily: "Helvetica, sans-serif",
                fontWeight: ".875rem",
                color: "#454745",
              }}
            >
              {i18next.t(
                "A detailed description of business activities including funds and goods flow overview"
              )}
            </Typography>

            <Typography
              className="mb-1rem  d-flex jc-start registration0875RemFontSize"
              component="h1"
              variant="h5"
              sx={{
                fontFamily: "Helvetica, sans-serif",
                fontWeight: ".875rem",
                color: "#454745",
              }}
            >
              {user?.bussiness_activities ?? "-"}
            </Typography>
          </div>

          <div className="">
            <Typography
              className="mb-1rem  d-flex jc-start registration0875RemFontSize"
              component="h1"
              variant="h5"
              sx={{
                fontFamily: "Helvetica, sans-serif",
                fontWeight: ".875rem",
                color: "#454745",
              }}
            >
              {i18next.t(
                "How long has the company been in business, and where is the business based and managed from?"
              )}
            </Typography>

            <Typography
              className="mb-1rem  d-flex jc-start registration0875RemFontSize"
              component="h1"
              variant="h5"
              sx={{
                fontFamily: "Helvetica, sans-serif",
                fontWeight: ".875rem",
                color: "#454745",
              }}
            >
              {user?.company_in_bussiness ?? "-"}
            </Typography>
          </div>

          <div className="">
            <Typography
              className="mb-1rem  d-flex jc-start registration0875RemFontSize"
              component="h1"
              variant="h5"
              sx={{
                fontFamily: "Helvetica, sans-serif",
                fontWeight: ".875rem",
                color: "#454745",
              }}
            >
              {i18next.t(
                "In what countries is the company currently operating and planning to operate in future?"
              )}
            </Typography>

            <Typography
              className="mb-1rem  d-flex jc-start registration0875RemFontSize"
              component="h1"
              variant="h5"
              sx={{
                fontFamily: "Helvetica, sans-serif",
                fontWeight: ".875rem",
                color: "#454745",
              }}
            >
              {user?.verification_country_code ?? "-"}
            </Typography>
          </div>
        </div>
      </div>

      <div>
        <Typography
          className="mb-1rem  d-flex jc-start pageTitleFontSize"
          component="h1"
          variant="h5"
          sx={{
            fontFamily: "Helvetica, sans-serif",
            fontWeight: "600",
            marginTop: "2rem",
          }}
        >
          {i18next.t("Holding activities")}
        </Typography>

        <div className="personal-box">
          <div className="">
            <Typography
              className="mb-1rem  d-flex jc-start registration0875RemFontSize"
              component="h1"
              variant="h5"
              sx={{
                fontFamily: "Helvetica, sans-serif",
                fontWeight: ".875rem",
                color: "#454745",
              }}
            >
              {i18next.t(
                "What assets does the company hold and where? – if there are multiple holding layers, please clarify the final operational level including geography and industry"
              )}
            </Typography>

            <Typography
              className="mb-1rem  d-flex jc-start registration0875RemFontSize"
              component="h1"
              variant="h5"
              sx={{
                fontFamily: "Helvetica, sans-serif",
                fontWeight: ".875rem",
                color: "#454745",
              }}
            >
              {user?.holding_activities_1 ?? "-"}
            </Typography>
          </div>

          <div className="">
            <Typography
              className="mb-1rem  d-flex jc-start registration0875RemFontSize"
              component="h1"
              variant="h5"
              sx={{
                fontFamily: "Helvetica, sans-serif",
                fontWeight: ".875rem",
                color: "#454745",
              }}
            >
              {i18next.t(
                "How long has the company been in business, and where is the business based and managed from?"
              )}
            </Typography>

            <Typography
              className="mb-1rem  d-flex jc-start registration0875RemFontSize"
              component="h1"
              variant="h5"
              sx={{
                fontFamily: "Helvetica, sans-serif",
                fontWeight: ".875rem",
                color: "#454745",
              }}
            >
              {user?.company_in_bussiness ?? "-"}
            </Typography>
          </div>

          <div className="">
            <Typography
              className="mb-1rem  d-flex jc-start registration0875RemFontSize"
              component="h1"
              variant="h5"
              sx={{
                fontFamily: "Helvetica, sans-serif",
                fontWeight: ".875rem",
                color: "#454745",
              }}
            >
              {i18next.t(
                "In what countries is the company currently operating and planning to operate in future?"
              )}
            </Typography>

            <Typography
              className="mb-1rem  d-flex jc-start registration0875RemFontSize"
              component="h1"
              variant="h5"
              sx={{
                fontFamily: "Helvetica, sans-serif",
                fontWeight: ".875rem",
                color: "#454745",
              }}
            >
              {user?.verification_country_code ?? "-"}
            </Typography>
          </div>
        </div>
      </div>

      <div>
        <Typography
          className="mb-1rem  d-flex jc-start pageTitleFontSize"
          component="h1"
          variant="h5"
          sx={{
            fontFamily: "Helvetica, sans-serif",
            fontWeight: "600",
            marginTop: "2rem",
          }}
        >
          {i18next.t("Trading Activities")}
        </Typography>

        <div className="personal-box">
          <div className="">
            <Typography
              className="mb-1rem  d-flex jc-start registration0875RemFontSize"
              component="h1"
              variant="h5"
              sx={{
                fontFamily: "Helvetica, sans-serif",
                fontWeight: ".875rem",
                color: "#454745",
              }}
            >
              {i18next.t(
                "Describe the delivery chain and how this is funded by the prospect."
              )}
            </Typography>

            <Typography
              className="mb-1rem  d-flex jc-start registration0875RemFontSize"
              component="h1"
              variant="h5"
              sx={{
                fontFamily: "Helvetica, sans-serif",
                fontWeight: ".875rem",
                color: "#454745",
              }}
            >
              {user?.trading_activities_1 ?? "-"}
            </Typography>
          </div>

          <div className="">
            <Typography
              className="mb-1rem  d-flex jc-start registration0875RemFontSize"
              component="h1"
              variant="h5"
              sx={{
                fontFamily: "Helvetica, sans-serif",
                fontWeight: ".875rem",
                color: "#454745",
              }}
            >
              {i18next.t(
                "Collect two forms of proof that would evidence the above statements such as invoices, contracts, bank statement, financial statement, etc"
              )}
            </Typography>

            <Typography
              className="mb-1rem  d-flex jc-start registration0875RemFontSize"
              component="h1"
              variant="h5"
              sx={{
                fontFamily: "Helvetica, sans-serif",
                fontWeight: ".875rem",
                color: "#454745",
              }}
            >
              {user?.trading_activities_2 ?? "-"}
            </Typography>
          </div>
        </div>
      </div>

      <div>
        <Typography
          className="mb-1rem  d-flex jc-start pageTitleFontSize"
          component="h1"
          variant="h5"
          sx={{
            fontFamily: "Helvetica, sans-serif",
            fontWeight: "600",
            marginTop: "2rem",
          }}
        >
          {i18next.t("For prospects acting as intermediaries")}
        </Typography>

        <div className="personal-box">
          <div className="">
            <Typography
              className="mb-1rem  d-flex jc-start registration0875RemFontSize"
              component="h1"
              variant="h5"
              sx={{
                fontFamily: "Helvetica, sans-serif",
                fontWeight: ".875rem",
                color: "#454745",
              }}
            >
              {i18next.t(
                "What is the role of the company and added value for the final recipient of goods?"
              )}
            </Typography>

            <Typography
              className="mb-1rem  d-flex jc-start registration0875RemFontSize"
              component="h1"
              variant="h5"
              sx={{
                fontFamily: "Helvetica, sans-serif",
                fontWeight: ".875rem",
                color: "#454745",
              }}
            >
              {user?.prospect_aspect_1 ?? "-"}
            </Typography>
          </div>

          <div className="">
            <Typography
              className="mb-1rem  d-flex jc-start registration0875RemFontSize"
              component="h1"
              variant="h5"
              sx={{
                fontFamily: "Helvetica, sans-serif",
                fontWeight: ".875rem",
                color: "#454745",
              }}
            >
              {i18next.t(
                "Collect a full chain of documents including invoices, contracts, customs documents, transportation for at least 2 orders"
              )}
            </Typography>

            <Typography
              className="mb-1rem  d-flex jc-start registration0875RemFontSize"
              component="h1"
              variant="h5"
              sx={{
                fontFamily: "Helvetica, sans-serif",
                fontWeight: ".875rem",
                color: "#454745",
              }}
            >
              {user?.prospect_aspect_2 ?? "-"}
            </Typography>
          </div>
        </div>
      </div>

      <div>
        <Typography
          className="mb-1rem  d-flex jc-start pageTitleFontSize"
          component="h1"
          variant="h5"
          sx={{
            fontFamily: "Helvetica, sans-serif",
            fontWeight: "600",
            marginTop: "2rem",
          }}
        >
          {i18next.t("IT/Marketing/Online Marketing/Legal Services")}
        </Typography>

        <div className="personal-box">
          <div className="">
            <Typography
              className="mb-1rem  d-flex jc-start registration0875RemFontSize"
              component="h1"
              variant="h5"
              sx={{
                fontFamily: "Helvetica, sans-serif",
                fontWeight: ".875rem",
                color: "#454745",
              }}
            >
              {i18next.t(
                "Detailed description of the services provided including industry and geography of the final clients"
              )}
            </Typography>

            <Typography
              className="mb-1rem  d-flex jc-start registration0875RemFontSize"
              component="h1"
              variant="h5"
              sx={{
                fontFamily: "Helvetica, sans-serif",
                fontWeight: ".875rem",
                color: "#454745",
              }}
            >
              {user?.it_marketing_1 ?? "-"}
            </Typography>
          </div>

          <div className="">
            <Typography
              className="mb-1rem  d-flex jc-start registration0875RemFontSize"
              component="h1"
              variant="h5"
              sx={{
                fontFamily: "Helvetica, sans-serif",
                fontWeight: ".875rem",
                color: "#454745",
              }}
            >
              {i18next.t(
                "How does the company provide the services (own employees, UBO on his own, outsourced third party suppliers, freelance contractors?)"
              )}
            </Typography>

            <Typography
              className="mb-1rem  d-flex jc-start registration0875RemFontSize"
              component="h1"
              variant="h5"
              sx={{
                fontFamily: "Helvetica, sans-serif",
                fontWeight: ".875rem",
                color: "#454745",
              }}
            >
              {user?.it_marketing_2 ?? "-"}
            </Typography>
          </div>

          <div className="">
            <Typography
              className="mb-1rem  d-flex jc-start registration0875RemFontSize"
              component="h1"
              variant="h5"
              sx={{
                fontFamily: "Helvetica, sans-serif",
                fontWeight: ".875rem",
                color: "#454745",
              }}
            >
              {i18next.t(
                "What documents does the prospect have to confirm the services were rendered to the client? (fee calculation, agreement, timesheets, act of acceptance, etc)"
              )}
            </Typography>

            <Typography
              className="mb-1rem  d-flex jc-start registration0875RemFontSize"
              component="h1"
              variant="h5"
              sx={{
                fontFamily: "Helvetica, sans-serif",
                fontWeight: ".875rem",
                color: "#454745",
              }}
            >
              {user?.it_marketing_3 ?? "-"}
            </Typography>
          </div>
        </div>
      </div>

      <div>
        <Typography
          className="mb-1rem  d-flex jc-start pageTitleFontSize"
          component="h1"
          variant="h5"
          sx={{
            fontFamily: "Helvetica, sans-serif",
            fontWeight: "600",
            marginTop: "2rem",
          }}
        >
          {i18next.t("For intermediary service providers")}
        </Typography>

        <div className="personal-box">
          <div className="">
            <Typography
              className="mb-1rem  d-flex jc-start registration0875RemFontSize"
              component="h1"
              variant="h5"
              sx={{
                fontFamily: "Helvetica, sans-serif",
                fontWeight: ".875rem",
                color: "#454745",
              }}
            >
              {i18next.t(
                "What is the final purpose of the service provision? (for example; the client provides marketing services for Dutch based advertising company that serves clients in gaming)."
              )}
            </Typography>

            <Typography
              className="mb-1rem  d-flex jc-start registration0875RemFontSize"
              component="h1"
              variant="h5"
              sx={{
                fontFamily: "Helvetica, sans-serif",
                fontWeight: ".875rem",
                color: "#454745",
              }}
            >
              {user?.intermediary_service_1 ?? "-"}
            </Typography>
          </div>

          <div className="">
            <Typography
              className="mb-1rem  d-flex jc-start registration0875RemFontSize"
              component="h1"
              variant="h5"
              sx={{
                fontFamily: "Helvetica, sans-serif",
                fontWeight: ".875rem",
                color: "#454745",
              }}
            >
              {i18next.t(
                "What documents does the prospect have to confirm the services were rendered to the client?"
              )}
            </Typography>

            <Typography
              className="mb-1rem  d-flex jc-start registration0875RemFontSize"
              component="h1"
              variant="h5"
              sx={{
                fontFamily: "Helvetica, sans-serif",
                fontWeight: ".875rem",
                color: "#454745",
              }}
            >
              {user?.intermediary_service_2 ?? "-"}
            </Typography>
          </div>
        </div>
      </div>

      <div>
        <Typography
          className="mb-1rem  d-flex jc-start pageTitleFontSize"
          component="h1"
          variant="h5"
          sx={{
            fontFamily: "Helvetica, sans-serif",
            fontWeight: "600",
            marginTop: "2rem",
          }}
        >
          {i18next.t("Consulting")}
        </Typography>

        <div className="personal-box">
          <div className="">
            <Typography
              className="mb-1rem  d-flex jc-start registration0875RemFontSize"
              component="h1"
              variant="h5"
              sx={{
                fontFamily: "Helvetica, sans-serif",
                fontWeight: ".875rem",
                color: "#454745",
              }}
            >
              {i18next.t("What industries are served?")}
            </Typography>

            <Typography
              className="mb-1rem  d-flex jc-start registration0875RemFontSize"
              component="h1"
              variant="h5"
              sx={{
                fontFamily: "Helvetica, sans-serif",
                fontWeight: ".875rem",
                color: "#454745",
              }}
            >
              {user?.consulting_1 ?? "-"}
            </Typography>
          </div>

          <div className="">
            <Typography
              className="mb-1rem  d-flex jc-start registration0875RemFontSize"
              component="h1"
              variant="h5"
              sx={{
                fontFamily: "Helvetica, sans-serif",
                fontWeight: ".875rem",
                color: "#454745",
              }}
            >
              {i18next.t(
                "What is the expertise of the service provider? What resources does the company have to provide the services?"
              )}
            </Typography>

            <Typography
              className="mb-1rem  d-flex jc-start registration0875RemFontSize"
              component="h1"
              variant="h5"
              sx={{
                fontFamily: "Helvetica, sans-serif",
                fontWeight: ".875rem",
                color: "#454745",
              }}
            >
              {user?.consulting_2 ?? "-"}
            </Typography>
          </div>
        </div>
      </div>

      <div>
        <Typography
          className="mb-1rem  d-flex jc-start pageTitleFontSize"
          component="h1"
          variant="h5"
          sx={{
            fontFamily: "Helvetica, sans-serif",
            fontWeight: "600",
            marginTop: "2rem",
          }}
        >
          {i18next.t("Investment companies")}
        </Typography>

        <div className="personal-box">
          <div className="">
            <Typography
              className="mb-1rem  d-flex jc-start registration0875RemFontSize"
              component="h1"
              variant="h5"
              sx={{
                fontFamily: "Helvetica, sans-serif",
                fontWeight: ".875rem",
                color: "#454745",
              }}
            >
              {i18next.t(
                "License, or explanation as to why a license is not required (with the link to relevant legislation)"
              )}
            </Typography>

            <Typography
              className="mb-1rem  d-flex jc-start registration0875RemFontSize"
              component="h1"
              variant="h5"
              sx={{
                fontFamily: "Helvetica, sans-serif",
                fontWeight: ".875rem",
                color: "#454745",
              }}
            >
              {user?.investment_1 ?? "-"}
            </Typography>
          </div>

          <div className="">
            <Typography
              className="mb-1rem  d-flex jc-start registration0875RemFontSize"
              component="h1"
              variant="h5"
              sx={{
                fontFamily: "Helvetica, sans-serif",
                fontWeight: ".875rem",
                color: "#454745",
              }}
            >
              {i18next.t(
                "Flow of funds – explanation of whether these are 1st or 3rd party funds?"
              )}
            </Typography>

            <Typography
              className="mb-1rem  d-flex jc-start registration0875RemFontSize"
              component="h1"
              variant="h5"
              sx={{
                fontFamily: "Helvetica, sans-serif",
                fontWeight: ".875rem",
                color: "#454745",
              }}
            >
              {user?.investment_2 ?? "-"}
            </Typography>
          </div>

          <div className="">
            <Typography
              className="mb-1rem  d-flex jc-start registration0875RemFontSize"
              component="h1"
              variant="h5"
              sx={{
                fontFamily: "Helvetica, sans-serif",
                fontWeight: ".875rem",
                color: "#454745",
              }}
            >
              {i18next.t(
                "The nature of the investments; where the funds are invested to and what is the purpose and how returns to investors are made"
              )}
            </Typography>

            <Typography
              className="mb-1rem  d-flex jc-start registration0875RemFontSize"
              component="h1"
              variant="h5"
              sx={{
                fontFamily: "Helvetica, sans-serif",
                fontWeight: ".875rem",
                color: "#454745",
              }}
            >
              {user?.investment_3 ?? "-"}
            </Typography>
          </div>

          <div className="">
            <Typography
              className="mb-1rem  d-flex jc-start registration0875RemFontSize"
              component="h1"
              variant="h5"
              sx={{
                fontFamily: "Helvetica, sans-serif",
                fontWeight: ".875rem",
                color: "#454745",
              }}
            >
              {i18next.t(
                "Explanation of AML/KYC policies that are in place to protect investors"
              )}
            </Typography>

            <Typography
              className="mb-1rem  d-flex jc-start registration0875RemFontSize"
              component="h1"
              variant="h5"
              sx={{
                fontFamily: "Helvetica, sans-serif",
                fontWeight: ".875rem",
                color: "#454745",
              }}
            >
              {user?.investment_4 ?? "-"}
            </Typography>
          </div>
        </div>
      </div>
    </div>
  );
}

export default UserBankingDetails;
