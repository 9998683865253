import { useEffect } from "react";
import "./Registration.scss";
import { useState } from "react";
import Nav from "src/components/layout/nav/Nav";
import { useSearchParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "src/store";
import {
  registerEmail,
  resetRegisterState,
  setToken,
} from "src/store/register/register_slice";
import { toast } from "react-toastify";
import FirstStep from "src/components/Registration/FirstStep";
import SecondStep from "src/components/Registration/SecondStep";
import ThirdStep from "src/components/Registration/ThirdStep";
import FourthStep from "src/components/Registration/FourthStep";
import {
  BUSINESS_ACCOUNT,
  PERSONAL_ACCOUNT,
} from "src/components/shared/constants";
import PersonalAccountForm from "src/components/Registration/PersonalAccountForm";
import FifthStep from "src/components/Registration/FifthStep";
import BussinesAccountForm from "src/components/Registration/BussinesAccountForm";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import OTPVerification from "src/components/shared/OTPVerification";
import KYCStep from "src/components/Registration/KYCStep";

const getPhone = (user: any) => {
  let phone = "";
  if (user.phone && user.country_code) {
    phone = user.country_code + "" + user.phone;
    if (phone[0] !== "+") phone = "+" + phone;
  }
  return phone;
};

export default function SignUp() {
  const dispatch = useAppDispatch();
  const { isSuccess, message, isLoading, isError, isVerifified, token } =
    useAppSelector((state) => state.register);

  const { token: authToken } = useAppSelector((state) => state.auth);

  const [step, setStep] = useState(0);
  const [accountType, setAccountType] = useState("");
  const [email, setEmail] = useState("");
  const [isEmailValid, setIsEmailValid] = useState(true);

  const [searchParams, setSearchParams] = useSearchParams();

  const handleSelectingAccountType = (value: string) => {
    setAccountType(value);
    setStep(step + 1);
  };

  const handleStepChange = (newStep: number) => {
    setStep(newStep);
  };

  const goBack = () => {
    if (step > 0) {
      setStep(step - 1);
    }
  };

  const validateEmail = (email: string) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleNextClick = () => {
    if (isEmailValid && email) {
      if (step === 0) {
        dispatch(registerEmail({ email }));
      }
    }
  };

  useEffect(() => {
    if (authToken) {
      window.location.href = "/banking";
    }
  }, [authToken]);

  useEffect(() => {
    if (isSuccess || isVerifified) {
      toast.success(message);
      dispatch(resetRegisterState());
      setStep(1);
    }
  }, [isSuccess, isVerifified]);

  useEffect(() => {
    if (isError || isVerifified === false) {
      toast.error(message);
      dispatch(resetRegisterState());
    }
  }, [isError, message, isVerifified]);

  useEffect(() => {
    if (
      (searchParams &&
        searchParams.get("token") &&
        searchParams.get("email-verification") === "success") ||
      token
    ) {
      if (searchParams.get("token") && !token) {
        dispatch(setToken(searchParams.get("token")));
      }
      setStep(2);
    }
  }, [searchParams, token]);

  const handleContinueClick = () => {
    setStep(step + 1);
  };

  const handleEmailChange = (newEmail: string) => {
    setEmail(newEmail);
    setIsEmailValid(validateEmail(newEmail));
    setIsVerified(false);
  };

  const [handleRequested, setHandleRequested] = useState(false);
  const [isVerified, setIsVerified] = useState(false);
  const [uuid, setUuid] = useState(null);

  const [phone, setPhone] = useState(null);
  const [userToken, setUserToken] = useState(null);

  const handleRequestVerification = async (
    stepValue: any,
    formikValues: any
  ) => {
    if (formikValues) {
      const phoneNumber: any = getPhone(formikValues);
      setPhone(phoneNumber);
      setUserToken(formikValues.token);
    }
    if (!handleRequested) {
      setHandleRequested(true);
    }
    setStep(5);
  };

  const handleVerification = (data: any) => {
    setIsVerified(true);
    setUuid(data.uuid);
    setHandleRequested(false);
    setStep(5);
  };

  return (
    <>
      {!authToken && (
        <div>
          {/*Current Navigation */}
          <Nav step={step} isStepperVisible={true} />

          <div className="content-container-registration-steps">
            {step > 0 && (
              <div onClick={goBack} className="back-icon">
                <ArrowBackIcon className="muiIcons" />
              </div>
            )}

            {/* initial screen start */}
            {step === 0 && (
              <FirstStep
                isEmailValid={isEmailValid}
                handleNextClick={handleNextClick}
                email={email}
                handleChange={handleEmailChange}
              />
            )}
            {/* initial screen end */}

            {/* confirm email screen start */}
            {step === 1 && (
              <SecondStep email={email} isEmailValid={isEmailValid} />
            )}
            {/* confirm email screen end */}

            {/* email confirmed start*/}
            {step === 2 && (
              <ThirdStep
                handleContinueClick={handleContinueClick}
                isEmailValid={isEmailValid}
              />
            )}
            {/* email confirmed end*/}

            {/* account type start*/}
            {step === 3 && (
              <FourthStep
                handleSelectingAccountType={handleSelectingAccountType}
              />
            )}
            {/* account type end*/}

            {/* accounts form */}
            {step === 4 && (
              <>
                {accountType === PERSONAL_ACCOUNT && (
                  <PersonalAccountForm
                    setStep={handleRequestVerification}
                    step={step}
                  />
                )}

                {accountType === BUSINESS_ACCOUNT && (
                  <BussinesAccountForm
                    setStep={handleRequestVerification}
                    step={step}
                  />
                )}
              </>
            )}

            {!isVerified && handleRequested && step === 5 && (
              <OTPVerification
                onSubmit={handleVerification}
                phoneNumber={phone}
                email={email}
                userToken={userToken}
              />
            )}
            {/* country select end*/}

            {isVerified && step === 5 && accountType === PERSONAL_ACCOUNT && (
              <KYCStep
                onStepChange={handleStepChange}
                userToken={userToken}
                accountType={accountType}
              />
            )}
            {/* KYC end */}

            {/* Password start*/}
            {isVerified && ((accountType === BUSINESS_ACCOUNT && step === 5) || ((accountType === PERSONAL_ACCOUNT && step === 6))) && (
              <FifthStep setStep={setStep} step={step} uuid={uuid} />
            )}
            {/* Password end*/}
          </div>
        </div>
      )}
    </>
  );
}
