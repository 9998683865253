import React, { useEffect, useState } from "react";
import { Typography } from "@mui/material";
import "./CryptoDetail.scss";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import QRCodeGenerator from "src/components/shared/QRCode/QRCode";
import api from "src/store/interceptors/api";
import clipboardCopy from "clipboard-copy";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import { useNavigate } from "react-router-dom";
import i18next from "i18next";

interface CryptoData {
  crypto_address: string;
  crypto_amount: number;
  crypto_uri: string;
  expires_in: any;
  order_amount: number;
  order_currency: string;
  exchange_rate: number;
}

const formatDate = (sectionName: string) => {
  // Check if sectionName is a valid date
  const date = new Date(sectionName);
  if (!isNaN(date.getTime())) {
    // It's a valid date, format it
    const currentLanguage = i18next.language || "en-US";
    return new Intl.DateTimeFormat(currentLanguage, {
      month: "long",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
    }).format(date);
  } else {
    // Not a valid date, return as is
    return sectionName;
  }
};

interface CryptoDataProps {
  cryptoData: any | null; // Explicitly define the type as string or null
}
const CryptoDetail: React.FC<CryptoDataProps> = ({ cryptoData }) => {
  //const [cryptoData, setCryptoData] = useState<CryptoData | null>(null);
  const navigate = useNavigate();

  /*
  const getInitialData = async () => {
    const mockResponse = {
      crypto_address:
        "tb1qfp58hzsvttejntucl0ea49fpkppfym4c75ar89cl0ea49fpkppfym4c75ar8cl0ea49fpkppfym4c75ar899",
      crypto_amount: 2.362e-5,
      crypto_uri:
        "bitcoin:tb1qfp58hzsvttejntucl0ea49fpkppfym4c75ar89?amount=0.00002362",
      expires_in: "24:43",
      order_amount: 1,
      order_currency: "USD",
      exchange_rate: 42330.58,
    };

    try {
      setCryptoData(mockResponse);
    } catch (error) {
      console.error(error);
    }
  };
  */

  const navigateToHome = () => {
    navigate("/home");
  };

  function handleCopyToClipboard(dataToCopy: any) {
    if (dataToCopy) {
      clipboardCopy(dataToCopy.toString());
      alert("Copied to clipboard: " + dataToCopy);
    }
  }

  /*
  useEffect(() => {
    getInitialData();
  }, []);
  */

  if (cryptoData)
    return (
      <div className="details-main-container">
        <div className="name">
          <Typography
            className="your-details-text-big pageTitleFontSize"
            component="h1"
            variant="h5"
            sx={{
              fontFamily: "Helvetica, sans-serif",
              fontWeight: "600",
            }}
          >
            {i18next.t("Crypto Payment Details")}
          </Typography>
        </div>

        <div className="amount-details">
          <div className="total">
            <Typography
              className="your-details-text d-flex jc-start FontSize12"
              variant="h5"
              sx={{
                fontFamily: "Helvetica, sans-serif",
                fontWeight: "600",
              }}
            >
              {i18next.t("Total")}
            </Typography>
            <Typography
              className="your-details-text FontSize12"
              variant="h5"
              sx={{
                fontFamily: "Helvetica, sans-serif",
              }}
            >
              <strong>{cryptoData?.order_amount}</strong>{" "}
              {cryptoData?.order_currency}
            </Typography>
          </div>

          <div className="amount">
            <Typography
              className="your-details-text d-flex jc-start FontSize12"
              variant="h5"
              sx={{
                fontFamily: "Helvetica, sans-serif",
                fontWeight: "600",
              }}
            >
              {i18next.t("Amount due")}
            </Typography>

            <div className="d-flex ai-center jusifyContentSpaceBetween mb-1rem">
              <Typography
                className="your-details-text d-flex jc-star FontSize12"
                variant="h5"
                sx={{
                  fontFamily: "Helvetica, sans-serif",
                }}
              >
                {cryptoData?.crypto_amount}
              </Typography>
              <div className="cp">
                <ContentCopyIcon
                  sx={{ height: "24px", color: "rgb(33, 43, 54)" }}
                  onClick={() =>
                    handleCopyToClipboard(cryptoData?.crypto_amount)
                  }
                />
              </div>
            </div>
            <div className="horizontal-line mb-"></div>
          </div>

          <div className="link">
            <Typography
              className="your-details-text FontSize12"
              variant="h5"
              sx={{
                fontFamily: "Helvetica, sans-serif",
                fontWeight: "600",
              }}
            >
              {i18next.t("Please send to wallet address")}
            </Typography>

            <div className="container-link">
              <div className="left-link-part">
                <Typography
                  className="your-details-text FontSize1275"
                  sx={{
                    fontFamily: "Helvetica, sans-serif",
                    fontWeight: "600",
                    color: "#2A5182",
                    textAlign: "center",
                    margin: "auto",
                  }}
                >
                  {cryptoData?.crypto_address}
                </Typography>
              </div>
              <div className="right-link-part cp">
                <ContentCopyIcon
                  sx={{ height: "18px", color: "rgb(33, 43, 54)" }}
                  onClick={() =>
                    handleCopyToClipboard(cryptoData?.crypto_address)
                  }
                />
              </div>
            </div>

            <Typography
              className="your-details-text FontSize12"
              variant="h5"
              sx={{
                fontFamily: "Helvetica, sans-serif",
                marginBottom: "1rem",
              }}
            >
              {i18next.t("or scan the QR code with your Wallet")}
            </Typography>

            <div className="container-qr-code">
              <QRCodeGenerator link={JSON.stringify(cryptoData?.crypto_uri)} />
            </div>

            <div className="qr-code-details">
              <Typography
                className="your-details-text cp"
                variant="h5"
                sx={{
                  fontFamily: "Helvetica, sans-serif",
                  fontSize: "12px",
                  textDecoration: "underline",
                  marginTop: "1rem",
                }}
              >
                {i18next.t("Facing issues? Click to scan simplified QR code.")}
              </Typography>
              <Typography
                className="your-details-text oneRemFontSize"
                variant="h5"
                sx={{
                  fontFamily: "Helvetica, sans-serif",
                  fontWeight: "600",
                  marginTop: "1rem",
                  color: "#2A5182",
                }}
              >
                {i18next.t("Detecting transaction")}
              </Typography>
              <Typography
                className="your-details-text cp"
                variant="h5"
                onClick={navigateToHome}
                sx={{
                  fontFamily: "Helvetica, sans-serif",
                  fontSize: "12px",
                  fontWeight: "400",
                  marginTop: "1rem",
                }}
              >
                {i18next.t("Cancel and return")}
              </Typography>
            </div>
          </div>
        </div>

        <div className="footer">
          {cryptoData?.meta && cryptoData.meta.expires_in && (
            <div className="d-flex ai-center">
              <Typography
                className="your-details-text d-flex jc-start"
                variant="h5"
                sx={{
                  fontFamily: "Helvetica, sans-serif",
                  fontSize: "12px",
                  fontWeight: "600",
                }}
              >
                <AccessTimeIcon sx={{ height: "18px", marginRight: "8px" }} />{" "}
                {i18next.t("Expiry date")}{" "}
                {formatDate(cryptoData.meta.expiry_date)}
              </Typography>
            </div>
          )}
          <Typography
            className="your-details-text d-flex jc-start"
            variant="h5"
            sx={{
              fontFamily: "Helvetica, sans-serif",
              fontSize: "12px",
              fontWeight: "600",
            }}
          >
            1 {cryptoData?.crypto_currency ?? "BTC"} ={" "}
            {cryptoData?.exchange_rate} {cryptoData?.target_currency}
          </Typography>
        </div>
      </div>
    );
  else
    return (
      <div className="details-main-container">
        <div className="name">
          <Typography
            className="your-details-text-big pageTitleFontSize"
            component="h1"
            variant="h5"
            sx={{
              fontFamily: "Helvetica, sans-serif",
              fontWeight: "600",
            }}
          >
            {i18next.t("Payment Details")}
          </Typography>
        </div>

        <div className="amount-details">
          <div className="link">
            <div className="qr-code-details">
              <Typography
                className="your-details-text cp"
                variant="h5"
                sx={{
                  fontFamily: "Helvetica, sans-serif",
                  fontSize: "12px",
                  textDecoration: "underline",
                  marginTop: "1rem",
                }}
              >
                {i18next.t(
                  "There was a problem with the request, please try again!"
                )}
              </Typography>
              <Typography
                className="your-details-text"
                variant="h5"
                sx={{
                  fontFamily: "Helvetica, sans-serif oneRemFontSize",
                  fontWeight: "600",
                  marginTop: "1rem",
                  color: "#2A5182",
                }}
              >
                {i18next.t("Detecting transaction")}
              </Typography>
              <Typography
                className="your-details-text cp"
                variant="h5"
                onClick={navigateToHome}
                sx={{
                  fontFamily: "Helvetica, sans-serif",
                  fontSize: "12px",
                  fontWeight: "400",
                  marginTop: "1rem",
                }}
              >
                {i18next.t("Cancel and return")}
              </Typography>
            </div>
          </div>
        </div>
      </div>
    );
};
export default CryptoDetail;
