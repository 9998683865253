import React from "react";
import "./Notification.scss";
import { Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import api from "src/store/interceptors/api";
import moment from "moment";
import NotificationsIcon from "@mui/icons-material/Notifications";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import i18next from "i18next";

interface NotificationProps {
  item: any;
  user: any;
  updateNotifications: (id?: number) => void;
}

const NotificationItem: React.FC<NotificationProps> = ({
  item,
  user,
  updateNotifications,
}) => {
  const navigate = useNavigate(); // Initialize the navigate

  const handleUpdateNotification = async (id: number) => {
    try {
      if (!item.is_read) {
        const res: any = await api.put("/notifications/mark-as-read/" + id);
        if (res && res.data.data) {
          updateNotifications(id);
        }
      }
    } catch (error) {
      console.log(error);
    }

    let blnAdmin = false;
    if (user && (user.role === "Admin" || user.role === "Employee")) {
      blnAdmin = true;
    }
    let redirectPath = "";
    if (item.notifable_type) {
      const data = item.notifable;
      if (data) {
        if (item.category === "VerificationRequested") {
          if (data.id) {
            if (blnAdmin) {
              redirectPath = `/admin/users/${data.id}/account-information`;
            }
          }
        } else if (item.category === "VerificationRejected") {
          if (data.id) {
            redirectPath = `/profile`;
          }
        } else if (item.category === "IBANRequest") {
          if (data.id) {
            redirectPath = `/account-details/${data.id}`;
          }
        } else if (item.category === "TransactionStatusUpdate") {
          if (data.id) {
            redirectPath = `/transaction-details/${data.uuid}`;
          }
        } else if (item.category === "TransactionCompleted") {
          if (data.id) {
            redirectPath = `/transaction-details/${data.uuid}`;
          }
        } else if (item.category === "TransactionRejected") {
          if (data.id) {
            redirectPath = `/transaction-details/${data.uuid}`;
          }
        } else if (item.category === "TransactionProcessing") {
          if (data.id) {
            redirectPath = `/transaction-details/${data.uuid}`;
          }
        } else if (item.category === "TransactionActionNeeded") {
          if (data.id) {
            redirectPath = `/transaction-details/${data.uuid}`;
          }
        } else if (item.category === "PaymentRequestUpdate") {
          if (data.id) {
            redirectPath = `/balance-detail/${data.id}`;
          }
        } else if (item.category === "PaymentRequestCompleted") {
          if (data.id) {
            redirectPath = `/balance-detail/${data.id}`;
          }
        } else if (item.category === "InvestmentParticipationCreated") {
          if (data.id) {
            if (blnAdmin) {
              redirectPath = `/admin/participations/${data.id}`;
            } else {
              redirectPath = `/participation-detail/${data.id}`;
            }
          }
        } else if (item.category === "InvestmentRequestCreated") {
          if (data.id) {
            if (blnAdmin) {
              redirectPath = `/admin/investments/${data.id}`;
            } else {
              redirectPath = `/investment-detail/${data.id}`;
            }
          }
        }
      }
    }

    if (redirectPath !== "") {
      navigate(redirectPath);
    }
  };

  const handleNotificationClick = () => {
    handleUpdateNotification(item.id);
  };

  const cssClass = !item.is_read ? "notification-unread" : "";

  return (
    <div
      className={`notification-container ${cssClass}`}
      onClick={handleNotificationClick}
    >
      <div>
        {item.is_read ? (
          <NotificationsIcon style={{ paddingTop: 10 }} className="muiIcons" />
        ) : (
          <NotificationsActiveIcon
            style={{ color: "#2A5182", paddingTop: 10 }}
            className="muiIcons"
          />
        )}
      </div>
      <div className="left">
        <Typography
          className="FontSize1075"
          component="h1"
          variant="h5"
          sx={{
            fontFamily: "Helvetica, sans-serif",
            color: "#000000;",
            margttom: "0.6rem",
            fontWeight: "500",
          }}
        >
          {item.data && item.data.title ? i18next.t(item.data.title) : i18next.t("Notification")}
        </Typography>
        <Typography
          component="h1"
          variant="h5"
          className="registration0875RemFontSize"
          sx={{
            fontFamily: "Helvetica, sans-serif",
            fontWeight: "300",
            color: "#454745",
          }}
        >
          {item.data && item.data.body ? i18next.t(item.data.body) : i18next.t("New Notification")}
        </Typography>
      </div>

      <div className="right">
        <Typography
          className="registration0875RemFontSize"
          component="h1"
          variant="h5"
          sx={{
            fontFamily: "Helvetica, sans-serif",
            fontWeight: "200",
            color: "#454745",
          }}
        >
          {item.created_at
            ? moment(item.created_at).format("DD/MM/YYYY HH:mm")
            : ""}
        </Typography>
      </div>
    </div>
  );
};

export default NotificationItem;
